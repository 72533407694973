import type { Guide } from '../../types';

import aborts from './aborts';
import chc from './chc';
import { decisionSupports } from './decisionSupport';
import { lowDosePills, popPills } from './pills';
import pop from './pop';
import wantBrand from './wantBrand';

export default (async args => {
  const { ask, decisionSupport, significant } = args;

  let doctorChoose = false;
  let skinPatch = false;
  let lowDose = false;

  const wantQuestion = {
    type: 'choice',
    label: 'Do you know what kind of contraceptive you would like to try?',
    options: {
      combined:
        'Combined Pill (usually taken for 21 days followed a 7 day break)',
      progesterone:
        'Progesterone Only "Mini" pill (taken everyday without a break)',
      knowName: 'I know the name of the pill, but not the type',
      doctorChoose: 'Let the doctor choose a pill for me',
      skinPatch: 'Skin Patch (Evra)',
      vaginalRing: 'Vaginal Ring (NuvaRing)',
      injectionImplantCoil:
        'Injection, Implant, Coil (IUD), or hormonal coil (Mirena / IUS)',
      dontKnow: `I don't know what I want`,
    },
  };
  const want = await ask(wantQuestion);
  significant.push({
    ...wantQuestion,
    value: want,
    warn: ['injectionImplantCoil', 'dontKnow', 'doctorChoose'],
  });
  if (want === 'doctorChoose') {
    doctorChoose = true;
    decisionSupport.push(decisionSupports.startContraceptiveSendInfo);
  } else if (want === 'skinPatch') {
    skinPatch = true;
    decisionSupport.push(decisionSupports.startContraceptiveSkinPatch);
  } else if (want === 'vaginalRing') {
    decisionSupport.push(decisionSupports.startContraceptiveVaginalRing);
  } else if (want === 'injectionImplantCoil') {
    return { abort: aborts.unavailable };
  } else if (want === 'dontKnow') {
    return { abort: aborts.unsure };
  } else {
    const { brandName } = await wantBrand({
      ...args,
      progesterone: want === 'progesterone',
    });

    if (brandName === 'none') {
      decisionSupport.push(
        decisionSupports.startContraceptive('Contraceptive pill')
      );
    } else if (brandName !== undefined) {
      decisionSupport.push(decisionSupports.startContraceptive(brandName));
    } else {
      doctorChoose = true;
      decisionSupport.push(decisionSupports.startContraceptiveDoctorChoose);
    }

    if (popPills.includes(brandName)) {
      return await pop(args);
    }

    lowDose = lowDosePills.includes(brandName);
  }

  return await chc({
    ...args,
    doctorChoose,
    skinPatch,
    lowDose,
  });
}: Guide);

const decisionSupport = {
  early: {
    label: 'Medication requested early',
    color: 'black',
  },
  lost: {
    label: 'Lost repeat medication',
    color: 'black',
  },
  ranOut: {
    label: 'Repeat prescription requested early: used more than expected',
    color: 'black',
  },
  asthma: {
    label: 'Asthma Annual Review',
    color: 'black',
  },
  diabetes: {
    label: 'Diabetic Annual Review',
    color: 'black',
  },
  mentalHealth: {
    label:
      'Mental health medication can only be prescribed if there is an up to date mental health review.',
    color: 'red',
  },
  adhd: {
    label: 'Check ADHD medication. Do NOT prescribe if this is a CD.',
    color: 'red',
  },
  medicationReview: {
    label: 'Medication Review. Consider',
    description: `
- Tests and monitoring required – Bloods / Imaging
- New evidence or guidelines
- Simplifcation or switches
    `,
    color: 'black',
  },
  controlledDrugs: {
    label: 'Do NOT prescribe CD1-4 drugs',
    color: 'black',
  },
  checkSpecialistLetter: {
    label: 'Check notes for specialist letter re: acute medication',
    color: 'black',
  },
  checkAcuteMedication: {
    label: 'Check acute medication in notes',
    color: 'black',
  },
};

// $FlowFixMe
Object.keys(decisionSupport).forEach(id => (decisionSupport[id].id = id));

export default decisionSupport;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';

import { _t } from '../../i18n';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import {
  type Question as QuestionType,
  type Result as ResultType,
} from '../../guides/types';

import {
  fetchDraft,
  setDraftValue,
  popDraft,
  pushDraft,
} from '../../state/drafts/actions';

import {
  getQuestion,
  getResult,
  getValue,
  isFirst,
} from '../../state/drafts/selectors';

import colors from '../theme/colors';
import { type ThemeWidth } from '../theme/types';

import BackgroundColor from '../components/BackgroundColor';
import Body from '../components/Body';
import Close from '../components/Close';
import FadeTransition from '../components/FadeTransition';
import Fixed from '../components/Fixed';
import Frame from '../components/Frame';
import Header from '../components/Header';
import Logo from '../components/Logo';
import PreviousLink from '../components/PreviousLink';
import ScrollIndicator from '../components/ScrollIndicator';

import Question from './question/Question';
import GuideLayout from './GuideLayout';
import CancelModal from './modals/CancelModal';

import { withLocaleByCountry } from '../locale';

type Props = {
  country: string,
  guideId: string,
  question?: QuestionType,
  result?: ResultType,
  value?: *,
  first: boolean,
  fetchDraft: typeof fetchDraft,
  setDraftValue: typeof setDraftValue,
  popDraft: typeof popDraft,
  pushDraft: typeof pushDraft,
  width: ThemeWidth,
};

const useStyles = makeStyles(theme => ({
  cancelFixed: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  logo: { marginBottom: theme.spacing(3) },
}));

function Guide(props: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  const { country, guideId, width } = props;

  // Styles
  const classes = useStyles();

  // Animations
  const transitionRef = useRef(null);
  const transition = async () =>
    transitionRef.current && transitionRef.current.transition();

  // Redux hooks
  const question = useSelector(state => {
    return getQuestion(state, guideId);
  });

  const result = useSelector(state => {
    return getResult(state, guideId);
  });

  const value = useSelector(state => {
    return getValue(state, guideId);
  });

  const first = useSelector(state => {
    return isFirst(state, guideId);
  });

  const dispatch = useDispatch();

  // Navigation funcs
  const cancel = () => {
    navigate(`/${country}/guides`);
  };

  const update = value => {
    dispatch(setDraftValue(guideId, value));
  };

  const previous = async () => {
    await transition();
    await dispatch(popDraft(guideId));
  };

  const done = async value => {
    if (value !== undefined) {
      update(value);
    }
    await transition();
    await dispatch(pushDraft(guideId));
  };

  const redirectIf = useCallback(() => {
    if (result) {
      navigate(result.abort ? `${guideId}/abort` : `${guideId}/result`, {
        guideId,
      });
    }
  }, [result, guideId]);

  useEffect(() => {
    redirectIf();
  }, [props, redirectIf]);

  useEffect(() => {
    dispatch(fetchDraft(guideId));
  }, [guideId, result, dispatch]);

  if (!question) return null;

  const frame = () => (
    <Frame>
      <Logo className={classes.logo} />

      <Question
        key={question.ref}
        value={value}
        question={question}
        update={update}
        done={done}
      />

      <ScrollIndicator />
    </Frame>
  );

  const previousLink = () => (
    <PreviousLink
      label={_t('guides.previousQuestion')}
      onClick={previous}
      className={classes.previousLink}
    />
  );

  return (
    <GuideLayout guideId={guideId} country={country}>
      <Body>
        {isWidthDown('xs', width) ? (
          <>
            <Header>
              {!first && (
                <PreviousLink
                  label={_t('guides.previousQuestion')}
                  onClick={previous}
                  className={classes.previousLink}
                />
              )}
            </Header>

            <FadeTransition ref={transitionRef}>{frame()}</FadeTransition>

            <Fixed horizontal="right" vertical="top" spacing>
              <Close
                onClick={() => setModalOpen(true)}
                className={classes.cancelFixed}
              />
            </Fixed>
          </>
        ) : (
          <>
            <FadeTransition ref={transitionRef}>
              <Header>
                {first ? <div /> : previousLink()}

                <Close onClick={() => setModalOpen(true)} />
              </Header>

              {frame()}
            </FadeTransition>

            <BackgroundColor color={colors.grey_100} />
          </>
        )}
      </Body>

      <CancelModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        cancel={cancel}
      />
    </GuideLayout>
  );
}

export default withWidth()(withLocaleByCountry()(Guide));

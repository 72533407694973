import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { _t } from '../../../i18n';

import computeGuide from '../../../common/computeGuide';

import { type Patient } from '../../../guides/types';

import { moveDraft } from '../../../state/drafts/actions';
import { getDraft } from '../../../state/drafts/selectors';
import { type Draft } from '../../../state/drafts/types';

import { getGuide, getGuideMeta } from '../../../state/guides';

import { getPatient } from '../../../state/patients/selectors';

import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

import Key from '../../components/Key';

class JumpButton extends React.Component<
  {
    guideId: string,
    draft: Draft,
    patient: Patient,
    moveDraft: typeof moveDraft,
    classes: { [string]: string },
    className?: string,
  },
  {
    refs: string[],
  }
> {
  state = {
    refs: this.props.draft.visited || [],
  };

  computeRefs = async () => {
    const {
      guideId,
      draft: { values = {} },
      patient,
    } = this.props;

    const guide = getGuide(guideId);
    const { blacklisted } = getGuideMeta(guideId);

    if (blacklisted) return;

    const { visited: refs } = await computeGuide(guide, patient, values);

    this.setState({ refs });
  };

  componentDidUpdate(prevProps) {
    const { draft: { visited: prevVisited = [] } = {} } = prevProps;
    const { draft: { visited = [] } = {} } = this.props;

    if (!isEqual(prevVisited, visited)) {
      this.computeRefs();
    }
  }

  componentDidMount() {
    this.computeRefs();
  }

  render() {
    const {
      guideId,
      draft: { visited = [] },
      moveDraft,
      classes,
      className,
    } = this.props;
    const { refs = [] } = this.state;

    if (visited.length === refs.length) return null;

    const fn = () => moveDraft(guideId, refs);

    return (
      <Button
        variant="outlined"
        onClick={fn}
        className={classNames(classes.root, className)}
      >
        {_t('guides.goto.last')}

        <Key character="F" onPress={fn} className={classes.key} />
      </Button>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {},

  key: {
    marginLeft: spacing(),
  },
}))(
  connect(
    (state, { guideId }) => ({
      draft: getDraft(state, guideId) || {},
      patient: getPatient(state),
    }),
    { moveDraft }
  )(JumpButton)
);

import type { Export } from '../../types';

export default (exports: Export[]) => {
  exports.push({
    id: 'presentingComplaint',
    value: 'Covid-19 Test, Patient reviewed',
  });
  exports.push({ id: 'snomedCode', value: '763288003 Patient review' });
  exports.push({
    id: 'investigationsRequested',
    value: 'None',
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Advice given',
      'Referred to GP',
      'Urgent care recommended',
      'Emergency care recommended',
    ],
  });
  exports.push({
    id: 'actionNeeded.options',
    value: ['None'],
  });
};

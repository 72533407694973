import enGB from './translations/enGB';
import svSE from './translations/svSE';

const translations = { 'en-GB': enGB, 'sv-SE': svSE };

let currentLocale;

export const localeByCountry = {
  se: 'sv-SE',
  uk: 'en-GB',
};

export const setLocale = (locale: string) => (currentLocale = locale);

export const _tWithLocale = (
  key: string,
  locale: string,
  params?: { [string]: * } = {}
) =>
  Object.keys(params).reduce(
    (out, key) => out.replace(`{${key}}`, params[key]),
    (translations[locale] && translations[locale][key]) || key
  );

export const _t = (key: string, params?: { [string]: * }) =>
  _tWithLocale(key, currentLocale, params);

// @flow

import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';

export default (async args => {
  const { ask } = args;
  const eChoice = createExtendedChoice(args);

  await eChoice({
    id: 'coronaVirusScreen.contactWithOthersChild',
    label:
      'Has your child come into contact with anyone with suspected or confirmed Coronavirus?',
    clinicLabel: 'Recent contact with coronavirus',
    options: [
      {
        value: 'yes',
        warn: true,
      },
      {
        value: 'no',
      },
    ],
  });

  await ask.choice({
    description: `
If your child has a high temperature or a new, continuous cough, your child and anyone that they live with should not leave their home.

To protect others, do not go to places like a GP surgery or pharmacy. 

**If your child is struggling to breath, or feeling very unwell, do not wait for online care.**  Call 111 for urgent medical care. For emergencies call 999.
`,
    options: ['I understand'],
  });

  return {};
}: Guide);

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 880,
  lg: 1160,
  xl: 1441,
};

// mobile-first, works like theme.breakpoints.up('sm'):
export const mq = {
  xs: `@media(min-width: ${breakpoints.xs}px)`,
  sm: `@media(min-width: ${breakpoints.sm}px)`,
  md: `@media(min-width: ${breakpoints.md}px)`,
  lg: `@media(min-width: ${breakpoints.lg}px)`,
  xl: `@media(min-width: ${breakpoints.xl}px)`,

  // special media queries, keep them in here too?
  isHoverable: '@media(hover: hover) and (pointer: fine)',
};

export default {
  adultKubStructuralAbnormalitiesGp: {
    description: `
Unfortunately, we cannot treat you online.

**When you have a structural abnormality of your kidneys, ureters or bladder, it's best if you see a GP face to face**. This is because you may need to **be examined or give a urine sample to send to the lab**.

**Please book an appointment with a GP**.

If your GP surgery is closed and you **need immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultUrinaryCatheterGp: {
    description: `
Unfortunately, we cannot treat you online.

**When you use a urinary catheter, it's best if you see a GP face to face**. This is because you may need to **have your catheter checked or give a urine sample to send to the lab**.

**Please book an appointment with a GP**.

If your GP surgery is closed and you **need immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultVomitingAndUrineInfectionUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

**You need urgent care — this is because I am worried that your condition is severe.**

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**.
Please quote **'vomiting and urine infection'** when you book this appointment.

If you **cannot reach our support team, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultSevereBackPainAndUrineInfectionUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

**You need urgent care — this is because I am worried that your condition is severe.**

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**.
Please quote **'severe back pain and urine infection'** when you book this appointment.

If you **cannot reach our support team, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultUncontrollableShakingAndUrineInfectionUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

**You need urgent care — this is because I am worried that your condition is severe.**

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**.
Please quote **'uncontrollable shaking and urine infection'** when you book this appointment.

If you **cannot reach our support team, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
};

// @flow

import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import createExtendedNumber from '../common/extendedNumber';
import createExtendedMultipleChoiceWithOther from '../common/extendedMultipleChoiceWithOther';

import includedSegment from './includedSegment';
import ds from './decisionSupport';
import aborts from './aborts';

export default (async args => {
  let { scores, significant, ask } = args;
  console.log('scores when before any questions are parsed: ', scores.getAll());
  const bumpWarningScore = () => {
    scores.increase('warning');
  };

  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);
  const eNumber = createExtendedNumber(args);
  const eChoiceWithOther = createExtendedMultipleChoiceWithOther(args);

  const twoPics = await ask.upload(`Two pics`, {
    min: 2,
    max: 2,
  });
  if (twoPics && twoPics.length > 0) {
    significant.push({
      type: 'upload',
      label: 'Two pics',
      value: twoPics,
    });
  }

  const mandatoryVideo = await ask.upload(`One mandatory video`, {
    max: 1,
    mimeTypes: 'video/mp4,video/webm,video/quicktime',
    description: `
  You can use a mobile phone to record the video.

  Rest assured, your video will be kept confidential and will only be added to your medical record.
      `,
  });
  if (mandatoryVideo && mandatoryVideo.length > 0) {
    significant.push({
      type: 'upload',
      label: 'Mandatory Video',
      value: mandatoryVideo,
    });
  }

  const optionalVideo = await ask.upload(`Optional video`, {
    optional: true,
    mimeTypes: 'video/mp4,video/webm,video/quicktime',
    description: `
  You can use a mobile phone to record the video.

  Rest assured, your video will be kept confidential and will only be added to your medical record.
      `,
  });
  if (optionalVideo && optionalVideo.length > 0) {
    significant.push({
      type: 'upload',
      label: 'Optional Video',
      value: optionalVideo,
    });
  }

  const exampleChoice = await eChoice({
    // same parameters as ask (but options will be ignored)
    id: 'exampleGuide.exampleQuestion',
    label: 'Question label',
    description: 'Question description',

    // additional parameters to make life easier for guide creators
    isMultipleChoice: true,
    clinicLabel: 'The question label shown to the doctor',
    options: [
      {
        label: 'Uninteresting answer (will not show up in significant)',
        value: 'hideme',
        hide: true,
      },
      {
        label: 'Ordinary answer (will show up in significant with black text)',
        value: 'insignificant',
        keyFinding: 'Present this ordinary answer in prefilled summary',
      },
      {
        label: 'Warning answer (will show up in significant with red text)',
        value: 'imawarning',
        keyFinding: 'Present this warning in prefilled summary',
        warn: true,
      },
      {
        label: 'This answer will trigger a decision support',
        value: 'itriggerds',
        keyFinding:
          'Present this decision support triggered answer in prefilled summary',
        ds: ds.exampleDS,
      },
      {
        label:
          'This answer will create an urgent case (top in case list in clinic)',
        value: 'imurgent',
        urgent: true,
      },
      {
        label: 'This anwer uses onSelected to bumps warning score',
        value: 'onSelectedExample',
        onSelected: bumpWarningScore,
      },
      {
        label: 'This anwer triages out the user with an abort message',
        value: 'abortthis',
        abort: aborts.exampleAbort,
      },
    ],
  });

  // passing abort upwards still needs to be taken care of (but not what kind of abort since it's specified in the options)
  if (exampleChoice.abort !== undefined) {
    return exampleChoice;
  }

  // eChoice will automatically select binary or ternary question types if
  // only the option values are present and are 'yes', 'no', 'unknown'
  await eChoice({
    id: 'exampleGuide.exampleBinary',
    label: 'Question label',
    description: 'Question description',
    clinicLabel: 'The question label shown to the doctor',
    options: [{ value: 'yes' }, { value: 'no', hide: true }],
  });

  const includedSegmentResult = await includedSegment(args);
  if (includedSegmentResult.abort) {
    return includedSegmentResult;
  }

  await eText({
    label: 'Question shown to patient',
    clinicLabel: 'Question shown to doctor',
    warn: true,
  });

  await eNumber({
    label: 'Number between 1 and 10',
    clinicLabel: 'The question label shown to the doctor',
    min: 1,
    max: 10,
  });

  // The eChoiceWithOther helper wraps up the logic to ask one or more multiple
  // choice questions with an 'other' free text component.
  // It collates all of the questions into a single multiple choice result in the doctor clinic view
  await eChoiceWithOther({
    multipleChoiceQuestions: {
      id: 'someId',
      label: 'Multiple choice with other',
      description: 'More than one can be selected',
      options: [
        { label: 'Option 1', value: 'o1' },
        { label: 'Option 2', value: 'o2' },
        { label: 'Option 3', value: 'o3' },
        { label: 'Other', value: 'other' },
      ],
    },
    otherQuestion: {
      otherValue: 'other',
      id: 'someIdOther',
      label: 'Multiple choice with other text entry',
    },
    clinicLabel: 'The question label shown to the doctor',
    optional: true,
  });

  console.log('scores when all questions are parsed: ', scores.getAll());
  return {};
}: Guide);

const aborts = {
  suicidal: {
    description: `Eftersom du har tankar på att ta ditt liv och även försökt ta ditt liv tidigare, passar inte digital vård. Du bör vända dig till närmaste psykiatriska akutmottagning. Om du inte vet var denna finns, ring 1177 så talar de om var du hittar den.

Ring alltid 112 vid behov av omedelbar hjälp.

Vi beklagar att vi inte kan hjälpa dig.
      `,
  },
};

// $FlowFixMe
Object.keys(aborts).forEach(id => (aborts[id].id = id));

export default aborts;

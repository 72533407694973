import type { GuideWithInAndOut } from '../../types';

import { cocpPills, popPills } from './pills';

export default (async ({ ask, significant, progesterone }) => {
  const knowBrandQuestion = {
    type: 'choice',
    label: 'Is there a particular brand you were after?',
    options: {
      yes: 'Yes - I know the name of the pill I want',
      no: 'No - please choose the best pill for me',
    },
  };
  const knowBrand = await ask(knowBrandQuestion);

  let brandName;

  if (knowBrand === 'yes') {
    if (!progesterone) {
      const question = {
        type: 'choice',
        label: 'Are any of these the pill you want?',
        options: [
          ...cocpPills,
          {
            label: 'None of the above',
            value: 'none',
          },
        ],
      };
      brandName = await ask(question);
      if (brandName !== 'none') {
        significant.push({ ...question, value: brandName });
      }

      if (['Yasmin', 'Cilest'].includes(brandName)) {
        const confirmBrandQuestion = {
          type: 'choice',
          label:
            'The pill you have chosen, carries a higher risk of serious side effects such as blood clots (DVT), heart attack and stroke. Do you want continue?',
          options: {
            yes: 'Yes - I want to continue with my choice',
            no: 'No - let the doctor choose a lower risk pill for me',
          },
        };
        const confirmBrand = await ask(confirmBrandQuestion);

        if (confirmBrand === 'no') {
          significant.push({ ...confirmBrandQuestion, value: confirmBrand });
          return {};
        }
      }
    }

    if (brandName === undefined || brandName === 'none') {
      const question = {
        type: 'choice',
        label: 'Are any of these the pill you want?',
        options: [
          ...popPills,
          {
            label: 'None of the above',
            value: 'none',
          },
        ],
      };
      brandName = await ask(question);
      if (brandName !== 'none') {
        significant.push({ ...question, value: brandName });
      }
    }

    if (brandName === 'none') {
      const question = {
        type: 'text',
        label: 'Please tell us the name of the pill you would like to try',
      };
      significant.push({
        ...question,
        value: await ask(question),
        warn: true,
      });
    }
  } else {
    significant.push({
      ...knowBrandQuestion,
      value: knowBrand,
    });
  }

  return { brandName };
}: GuideWithInAndOut<
  {
    progesterone: boolean,
  },
  {
    brandName?: string,
  }
>);

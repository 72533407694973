import * as React from 'react';
import classNames from 'classnames';

import colors from '../theme/colors';

import { type Option } from '../../guides/types';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

import Next from '../components/Next';

const chevronRightIconSource = require('../../images/icons/ic_chevron_right_grey.svg');
const checkIconSource = require('../../images/icons/ic_check_red.svg');

type Props = {
  value?: *,
  options: Option[],
  update: (?*) => *,
  done: (?*) => *,
};

const ChoiceWithButtons = withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginLeft: spacing(10),
    [down('xs')]: {
      marginLeft: spacing(5),
    },
  },

  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginRight: -spacing(3),
  },

  button: {
    color: colors.red_500,
    justifyContent: 'space-between',
    textAlign: 'left',
    minHeight: 64,
    padding: `0px ${spacing(3)}px`,
    borderRadius: 0,
    borderBottom: `1px solid ${colors.grey_300}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  label: {
    color: colors.off_black,
    fontWeight: 500,
    minWidth: 160,
  },

  labelShort: {
    minWidth: 80,
  },

  chevron: {
    width: 8,
    marginLeft: spacing(3),
  },

  check: {
    width: 16,
    marginLeft: spacing(2),
  },
}))(
  class ChoiceWithButtons extends React.Component<
    Props & { classes: { [string]: string } }
  > {
    kp = event => {
      const { charCode } = event;
      const { options } = this.props;
      const number = charCode >= 49 && charCode <= 57 && charCode - 48;

      if (!!number && number <= options.length) {
        this.props.done(this.props.options[number - 1].value);
      }
    };

    componentDidMount() {
      window.document.addEventListener('keypress', this.kp);
    }

    componentWillUnmount() {
      window.document.removeEventListener('keypress', this.kp);
    }

    render() {
      const { value, options = [], done, classes } = this.props;

      const short = !options.find(o => o.label.length > 5);

      return (
        <div className={classNames(classes.root)}>
          {options.length === 1 ? (
            <Next
              label={options[0].label}
              onClick={() => done(options[0].value)}
            />
          ) : (
            <div className={classes.buttons}>
              {options.map(option => {
                const selected = option.value === value;

                return (
                  <Button
                    key={option.value}
                    fullWidth
                    onClick={() => done(option.value)}
                    className={classNames(
                      classes.button,
                      selected && classes.buttonSelected
                    )}
                  >
                    <Typography
                      className={classNames(
                        classes.label,
                        short && classes.labelShort
                      )}
                    >
                      {option.label}
                    </Typography>

                    {selected ? (
                      <img
                        alt="check"
                        src={checkIconSource}
                        className={classes.check}
                      />
                    ) : (
                      <img
                        alt="chevron"
                        src={chevronRightIconSource}
                        className={classes.chevron}
                      />
                    )}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      );
    }
  }
);

const radioIconOff = require('../../images/icons/ic_radio_off.svg');
const radioIconOn = require('../../images/icons/ic_radio_on.svg');

const ChoiceWithNext = withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  options: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: -spacing(),
    marginBottom: -spacing(),
    marginRight: -spacing(3),
  },

  option: {
    width: `calc(100% - ${spacing(8) + 5}px)`,
    marginLeft: spacing(8),
    [down('xs')]: {
      width: `calc(100% - ${spacing(5) + 5}px)`,
      marginLeft: spacing(5),
    },
    paddingLeft: 5,
  },

  label: {
    flex: 1,
    paddingTop: spacing(2.5),
    paddingBottom: spacing(2.5),
    paddingRight: spacing(3),
    borderBottom: `1px solid ${colors.grey_300}`,
    fontWeight: 500,
    minWidth: 160,
  },

  labelShort: {
    minWidth: 80,
  },

  labelLast: {
    borderBottom: 'none',
  },

  radio: {
    marginRight: spacing(),
  },

  radioIcon: {
    width: 28,
    height: 28,
  },

  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: spacing(3),
  },
}))(
  class ChoiceWithNext extends React.Component<
    Props & { classes: { [string]: string } }
  > {
    render() {
      const { value, options = [], update, done, classes } = this.props;

      const short = !options.find(o => o.label.length > 5);

      return (
        <div className={classes.root}>
          <RadioGroup
            className={classes.options}
            value={JSON.stringify(value) || ''}
            onChange={(event, value) => update(JSON.parse(value))}
          >
            {options.map(({ value: v, label }, i) => {
              const optionValue = v !== undefined ? v : label;

              return (
                <FormControlLabel
                  key={optionValue}
                  value={JSON.stringify(optionValue)}
                  control={
                    <Radio
                      color="primary"
                      icon={
                        <img
                          alt="off"
                          src={radioIconOff}
                          className={classes.radioIcon}
                        />
                      }
                      checkedIcon={
                        <img
                          alt="on"
                          src={radioIconOn}
                          className={classes.radioIcon}
                        />
                      }
                      className={classes.radio}
                    />
                  }
                  label={label}
                  className={classes.option}
                  classes={{
                    label: classNames(
                      classes.label,
                      short && classes.labelShort,
                      i === options.length - 1 && classes.labelLast
                    ),
                  }}
                />
              );
            })}
          </RadioGroup>

          <div className={classes.buttons}>
            <Next disabled={value === undefined} onClick={() => done()} />
          </div>
        </div>
      );
    }
  }
);

export default (props: Props) =>
  props.options && props.options.length > 5 ? (
    <ChoiceWithNext {...props} />
  ) : (
    <ChoiceWithButtons {...props} />
  );

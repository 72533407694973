import healthProfile from '../healthProfile';

import common from '../common';
const types = {
  binary: 'binary',
  tertiary: 'tertiary',
  choice: 'choice',
  multipleChoice: 'multipleChoice',
  text: 'text',
  upload: 'upload',
};

function binary(answer) {
  if (answer === 'yes') {
    return true;
  }
  return false;
}

const description = 'Flera svarsalternativ möjliga.';

export default async args => {
  const {
    ask,
    // patient: { gender, age },
    // decisionSupport,
    significant,
  } = args;

  const started = {
    type: 'choice',
    label: 'När började besvären?',
    clinicLabel: 'Besvären började',
    options: [
      'För mindre än 14 dagar sedan',
      'För 14 dagar – 3 månader sedan',
      'För mer än 3 månader sedan',
    ],
  };

  const startedA = await ask(started);
  significant.push({ ...started, label: started.clinicLabel, value: startedA });

  const location = {
    type: 'multipleChoice',
    label: 'Var sitter dina besvär',
    clinicLabel: 'Besvären sitter på',
    options: [
      {
        value: 'rightHand',
        label: 'Höger hand',
      },
      {
        value: 'leftHand',
        label: 'Vänster hand',
      },
      {
        value: 'rightFoot',
        label: 'Höger fot',
      },
      {
        value: 'leftFoot',
        label: 'Vänster fot',
      },
    ],
  };

  const locationAnswer = await ask(location);

  significant.push({
    ...location,
    label: location.clinicLabel,
    value: locationAnswer,
  });

  if (locationAnswer.includes('rightHand')) {
    const rightHand = {
      id: 'rightHand',
      type: 'choice',
      label: 'Hur många fingernaglar är drabbade på höger hand?',
      clinicLabel: 'Antal drabbade fingernaglar höger hand',
      options: [1, 2, 3, 4, 5],
    };
    const rightHandAnswer = await ask(rightHand);
    significant.push({
      ...rightHand,
      label: rightHand.clinicLabel,
      value: rightHandAnswer,
    });
  }

  if (locationAnswer.includes('leftHand')) {
    const leftHand = {
      id: 'leftHand',
      type: 'choice',
      label: 'Hur många fingernaglar är drabbade på vänster hand?',
      clinicLabel: 'Antal drabbade fingernaglar vänster hand',
      options: [1, 2, 3, 4, 5],
    };
    const leftHandAnswer = await ask(leftHand);
    significant.push({
      ...leftHand,
      label: leftHand.clinicLabel,
      value: leftHandAnswer,
    });
  }

  if (locationAnswer.includes('rightFoot')) {
    const rightFoot = {
      id: 'rightFoot',
      type: 'choice',
      label: 'Hur många tånaglar är drabbade på höger fot?',
      clinicLabel: 'Antal drabbade tånaglar höger fot',
      options: [1, 2, 3, 4, 5],
    };
    const rightFootAnswer = await ask(rightFoot);
    significant.push({
      ...rightFoot,
      label: rightFoot.clinicLabel,
      value: rightFootAnswer,
    });
  }

  if (locationAnswer.includes('leftFoot')) {
    const leftFoot = {
      id: 'leftFoot',
      type: 'choice',
      label: 'Hur många tånaglar är drabbade på vänster fot?',
      clinicLabel: 'Antal drabbade tånaglar vänster fot',
      options: [1, 2, 3, 4, 5],
    };
    const leftFootAnswer = await ask(leftFoot);
    significant.push({
      ...leftFoot,
      label: leftFoot.clinicLabel,
      value: leftFootAnswer,
    });
  }

  const injured = {
    id: 'injured',
    type: 'binary',
    label:
      'Har du slagit i eller på något annat sätt skadat de drabbade naglarna i samband med att dina besvär började?',
    clinicLabel: 'Slagit i/skadat drabbade naglarna',
  };
  const injuredAnswer = await ask(injured);
  significant.push({
    ...injured,
    label: injured.clinicLabel,
    value: injuredAnswer,
  });

  // Add photo tips later
  const photos = await ask.upload(
    `Bifoga bilder på dina drabbade naglar; en närbild för varje drabbad nagel och en översiktsbild på handen/foten där även de friska naglarna syns.`,
    {
      description: `
      Det går bra att ta bilderna med din mobilkamera, men undvik att använda dess zoom-funktion. Försök ta en så detaljerad närbild som möjligt i bra belysning.
    `,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Foton',
    value: photos,
  });

  const describe = {
    id: 'describe',
    type: types.multipleChoice,
    label: 'Hur skulle du beskriva dina nagelproblem?',
    clinicLabel: 'Nagelproblem',
    options: [
      {
        value: 'cosmetic',
        label: 'De är kosmetiska',
      },
      {
        value: 'painful',
        label: 'Jag har ont kring den drabbade nageln/de drabbade naglarna',
      },
      {
        value: 'other',
        label: 'Annat',
      },
    ],
  };

  const describeAnswer = await ask(describe);
  significant.push({
    ...describe,
    label: describe.clinicLabel,
    value: describeAnswer,
    options: [
      {
        value: 'cosmetic',
        label: 'Kosmetiska', // Short version of answer
      },
      {
        value: 'painful',
        label: 'Smärta', // Short version of answer
      },
      {
        value: 'other',
        label: 'Annat',
      },
    ],
  });

  if (describeAnswer.includes('other')) {
    const describeOther = {
      id: 'describeOther',
      type: types.text,
      label: 'Vilket annat problem har du med dina naglar?',
      clinicLabel: 'Annat problem med nageln/naglarna',
    };
    const describeOtherAnswer = await ask(describeOther);
    significant.push({
      ...describeOther,
      label: describeOther.clinicLabel,
      value: describeOtherAnswer,
    });
  }

  const otherDiseases = {
    id: 'otherDiseases',
    type: types.multipleChoice,
    label: 'Har du något av följande?',
    description: 'Flera svarsalternativ möjliga.',
    clinicLabel: 'Har',
    optional: true,
    options: [
      {
        value: 'psoriasis',
        label: 'Psoriasis',
      },
      {
        value: 'arthritis',
        label: 'Inflammatorisk ledsjukdom (artrit)',
      },
    ],
  };

  const otherDiseasesAnswer = await ask(otherDiseases);

  significant.push({
    ...otherDiseases,
    description: '',
    label: otherDiseases.clinicLabel,
    value: otherDiseasesAnswer,
  });

  const previousTreatment = {
    id: 'previousTreatment',
    type: 'binary',
    label: 'Har du försökt behandla dina besvär under den senaste månaden?',
    clinicLabel: 'Behandlat besvären själv nyligen',
  };
  const previousTreatmentAnswer = await ask(previousTreatment);
  significant.push({
    ...previousTreatment,
    label: previousTreatment.clinicLabel,
    value: previousTreatmentAnswer,
  });

  if (binary(previousTreatmentAnswer)) {
    const medication = {
      id: 'medication',
      type: types.multipleChoice,
      label: 'Vilka läkemedel har du provat under den senaste månaden?',
      description,
      clinicLabel: 'Provade läkemedel',
      options: [
        {
          value: 'canesten',
          label: 'Canesten, Daktar eller Pevaryl',
        },
        {
          value: 'loceryl',
          label: 'Loceryl, Amorolfin eller Finail',
        },
        {
          value: 'onytec',
          label: 'Onytec',
        },
        {
          value: 'lamisilCream',
          label: 'Lamisil/Terbinafin kräm',
        },
        {
          value: 'lamisilPill',
          label: 'Lamisil/Terbinafin tablett',
        },
        {
          value: 'other',
          label: 'Annat',
        },
      ],
    };

    const medicationAnswer = await ask(medication);

    significant.push({
      ...medication,
      description: '',
      label: medication.clinicLabel,
      value: medicationAnswer,
    });

    if (medicationAnswer.includes('other')) {
      const medicationOther = {
        id: 'medicationOther',
        type: 'text',
        label: 'Vilka andra läkemedel har du provat under den senaste månaden?',
        clinicLabel: 'Andra provade läkemedel',
      };

      const medicationAnswer = await ask(medicationOther);
      significant.push({
        ...medicationOther,
        label: medicationOther.clinicLabel,
        value: medicationAnswer,
      });
    }
  }

  // Here we import questions that are common for ~95% of the guides
  await common(args);

  const otherMedicalInfo = {
    id: 'otherMedicalInfo',
    type: types.text,
    label: 'Övrig medicinsk information',
    optional: true,
    description:
      'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
    clinicLabel: 'Övrigt',
  };

  const otherMedicalInfoAnswer = await ask(otherMedicalInfo);
  significant.push({
    ...otherMedicalInfo,
    description: '',
    label: otherMedicalInfo.clinicLabel,
    value: otherMedicalInfoAnswer,
  });

  await healthProfile(args);

  return {};
};

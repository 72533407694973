export default {
  adultNoSickNoteNeeded: {
    description: `
**You do not need to see a GP to be signed fit to go back to work.**

Some employers have their own policy that requires employees to obtain medical evidence that they are fit for work. If this is the case, your employer should help you arrange this privately with a GP or occupational health specialist. A doctor cannot issue a fit note for this purpose.

If you have more questions, then [follow this link.](https://www.nhs.uk/common-health-questions/caring-carers-and-long-term-conditions/can-i-go-back-to-work-before-the-end-date-on-my-fit-note/)

Thank you for using Docly.
`,
  },
  adultSelfCertifyFor7Days: {
    description: `
**If you're off work for sickness for 7 days or less, your employer should not ask for medical evidence that you've been ill.**

Instead they can ask you to confirm that you've been ill. You can do this by filling in a form yourself when you return to work. This is called self-certification.

If you are still not better within 7 days then please return to Docly and we can help you get a sick note. 

Thank you for using Docly.
`,
  },
};

import * as React from 'react';

import colors from '../theme/colors';

import Fab from '@material-ui/core/Fab';

import withStyles from '@material-ui/core/styles/withStyles';

const arrowRightIconSource = require('../../images/icons/ic_arrow_right.svg');

class ScrollIndicator extends React.Component<
  {
    offset?: number,
    maxWidth?: number,
    classes: { [string]: string },
  },
  { show: boolean }
> {
  state = {
    show: false,
  };

  poller: *;

  onScroll = () => {
    const { offset } = this.props;

    const { scrollTop, scrollHeight } =
      document.scrollingElement || document.body || {};
    const { clientHeight: windowHeight } = document.documentElement || {};

    this.setState({
      show:
        scrollHeight - windowHeight - scrollTop >
        (offset !== undefined ? offset : 160),
    });
  };

  scrollDown = () => {
    const el = document.scrollingElement || document.body;
    const { clientHeight: windowHeight } = document.documentElement || {};

    if (el) el.scrollTop = el.scrollTop + windowHeight / 3;
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
    this.onScroll();
    this.poller = setInterval(this.onScroll, 200);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
    clearInterval(this.poller);
  }

  render() {
    const { maxWidth, classes } = this.props;
    const { show } = this.state;

    return (
      show && (
        <div className={classes.root}>
          <div
            className={classes.gradient}
            style={{ maxWidth: maxWidth !== undefined ? maxWidth : 800 }}
          >
            <Fab className={classes.fab} onClick={this.scrollDown}>
              <img
                alt="icon"
                src={arrowRightIconSource}
                className={classes.icon}
              />
            </Fab>
          </div>
        </div>
      )
    );
  }
}

export default withStyles(({ spacing, breakpoints: { up } }) => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  gradient: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    background:
      'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.001))',
  },

  fab: {
    backgroundColor: colors.grey_100,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    margin: spacing(3),
    [up('sm')]: {
      marginLeft: spacing(10),
      marginRight: spacing(10),
    },
  },

  icon: {
    marginTop: 2,
    height: 18,
    width: 18,
    transform: 'rotate(90deg)',
  },
}))(ScrollIndicator);

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const smoking = {
    type: 'choice',
    id: 'healthCheck.tobacco.smoking',
    label: 'Är du rökare?',
    options: [
      { value: 'yes', label: 'Ja' },
      { value: 'previously', label: 'Före detta' },
      { value: 'no', label: 'Nej' },
    ],
  };

  const smokingAnswer = await ask(smoking);

  significant.push({
    ...smoking,
    label: 'Rökare',
    value: smokingAnswer,
  });

  if (smokingAnswer === 'yes') {
    const smokingAmount = {
      type: 'number',
      id: 'healthCheck.tobacco.smokingAmount',
      label: 'Hur många cigaretter röker du/dag?',
      description: 'Ange antal.',
    };

    const smokingAmountAnswer = await ask(smokingAmount);

    significant.push({
      ...smokingAmount,
      label: 'Antal cigaretter/dag',
      description: '',
      value: smokingAmountAnswer,
    });
  }

  if (smokingAnswer === 'previously') {
    const smokingCessation = {
      type: 'number',
      id: 'healthCheck.tobacco.smokingCessation',
      label: 'För hur många år sedan slutade du röka?',
      description: 'Ange antal år sedan.',
    };

    const smokingCessationAnswer = await ask(smokingCessation);

    significant.push({
      ...smokingCessation,
      label: 'Slutade röka för',
      description: '',
      value: smokingCessationAnswer + ' år sedan',
    });
  }

  const snus = {
    type: 'choice',
    id: 'healthCheck.tobacco.snus',
    label: 'Är du snusare?',
    options: [
      { value: 'yes', label: 'Ja' },
      { value: 'previously', label: 'Före detta' },
      { value: 'no', label: 'Nej' },
    ],
  };

  const snusAnswer = await ask(snus);

  significant.push({
    ...snus,
    label: 'Snusare',
    value: snusAnswer,
  });

  if (snusAnswer === 'yes') {
    const snusAmount = {
      type: 'number',
      id: 'healthCheck.tobacco.snusAmount',
      label: 'Hur många dosor snusar du/vecka?',
      description: 'Ange antal.',
    };

    const snusAmountAnswer = await ask(snusAmount);

    significant.push({
      ...snusAmount,
      label: 'Antal dosor/vecka',
      description: '',
      value: snusAmountAnswer,
    });
  }

  if (snusAnswer === 'previously') {
    const snusCessation = {
      type: 'number',
      id: 'healthCheck.tobacco.snusCessation',
      label: 'För hur många år sedan snusa du röka?',
      description: 'Ange antal år sedan.',
    };

    const snusCessationAnswer = await ask(snusCessation);

    significant.push({
      ...snusCessation,
      label: 'Slutade snusa för',
      description: '',
      value: snusCessationAnswer + ' år sedan',
    });
  }

  return {};
}: Guide);

import type { Guide } from '../../../types';

export default (async args => {
  const {
    ask,
    exports,
    patient: { gender },
    significant,
  } = args;

  let medicationCurrentFemaleAnswer;
  let medicationCurrentMaleAnswer;

  if (gender === 'female') {
    const medicationCurrentFemale = {
      type: 'binary',
      id: 'medication.current.anyFemale',
      label: 'Använder du några läkemedel för tillfället?',
      description:
        'Om du använder hormonella preventivmedel bör även detta uppges.',
      expires: 180,
    };

    medicationCurrentFemaleAnswer = await ask(medicationCurrentFemale);

    significant.push({
      ...medicationCurrentFemale,
      label:
        'Tar läkemedel för tillfället (inklusive ev. hormonella preventivmedel)',
      description: '',
      value: medicationCurrentFemaleAnswer,
    });
  }

  if (gender === 'male') {
    const medicationCurrentMale = {
      type: 'binary',
      id: 'medication.current.anyMale',
      label: 'Använder du några läkemedel för tillfället?',
      expires: 180,
    };

    medicationCurrentMaleAnswer = await ask(medicationCurrentMale);

    significant.push({
      ...medicationCurrentMale,
      label: 'Tar läkemedel för tillfället',
      value: medicationCurrentMaleAnswer,
    });
  }

  if (
    medicationCurrentMaleAnswer === 'yes' ||
    medicationCurrentFemaleAnswer === 'yes'
  ) {
    const medicationCurrentWhat = {
      type: 'text',
      id: 'medication.current.what',
      label: 'Vilka läkemedel tar du?',
      description:
        'Ange läkemedel, styrka och dos, t.ex. Levaxin, 100 mikrogram, 1 tablett 1 gång/dag. Om du vet av vilken anledning du tar läkemedlet, ange detta.',
      expires: 180,
    };

    const medicationCurrentWhatAnswer = await ask(medicationCurrentWhat);

    significant.push({
      ...medicationCurrentWhat,
      label: 'Beskrivning av läkemedel',
      description: '',
      value: medicationCurrentWhatAnswer,
    });

    const medicationPackaged = {
      type: 'binary',
      id: 'medication.packaged',
      label:
        'Får du dina läkemedel förberedda i små plastpåsar (en påse per doseringstillfälle), märkta med personuppgifter, vilka läkemedel påsen innehåller samt datum och tid för när dessa ska tas?',
      description:
        '![medicationPackaged](https://storage.googleapis.com/mindoktor-assets/formulary/dose_packaged_meds.png)',
      expires: 180,
    };

    const medicationPackagedAnswer = await ask(medicationPackaged);

    significant.push({
      ...medicationPackaged,
      label: 'Har dosförpackade läkemedel',
      description: '',
      value: medicationPackagedAnswer,
      warn: ['yes'],
    });

    if (medicationPackagedAnswer === 'yes') {
      exports.push({
        id: 'prescriptionWarnings',
        value: ['**Har dosförpackade läkemedel** \nJa'],
      });
    }

    const medicationDeliveryHelp = {
      type: 'binary',
      id: 'medication.deliveryHelp.match',
      label:
        'Får du särskild hjälp med utdelning av dina läkemedel av hemtjänst, vårdcentral, mottagning eller liknande?',
      expires: 180,
    };

    const medicationDeliveryHelpAnswer = await ask(medicationDeliveryHelp);

    significant.push({
      ...medicationDeliveryHelp,
      label: 'Får hjälp med utdelning av läkemedel',
      description: '',
      value: medicationDeliveryHelpAnswer,
      warn: ['yes'],
    });

    if (medicationDeliveryHelpAnswer === 'yes') {
      const medicationDeliveryHelpWhat = {
        type: 'text',
        id: 'medication.deliveryHelp.what',
        label: 'Beskriv vilken typ av hjälp med utdelning du får.',
        expires: 180,
      };

      const medicationDeliveryHelpWhatAnswer = await ask(
        medicationDeliveryHelpWhat
      );

      significant.push({
        ...medicationDeliveryHelpWhat,
        label: 'Typ av hjälp med utdelning av läkemedel',
        value: medicationDeliveryHelpWhatAnswer,
      });
    }
  }

  return {};
}: Guide);

export default {
  'guides.back': 'Tillbaka',
  'guides.previousQuestion': 'Föregående fråga',
  'guides.choose': 'Välj en guide',
  'guides.clear': 'Rensa svar',
  'guides.switch': 'Byt guide',
  'guides.random': 'Slumpa svar',
  'guides.source': 'Visa källa',
  'guides.saveTest': 'Spara test',
  'guides.goto.question': 'Gå till fråga',
  'guides.goto.result': 'Resultat',
  'guides.goto.last': 'Hoppa framåt',
  'guides.decisionSupport.more': 'Fler beslutsstöd',
  'guides.cancelModal.title': 'Vill du verkligen avsluta?',
  'guides.cancelModal.body':
    'Ditt ärende kommer att sparas som ett utkast som du kan slutföra senare.',
  'guides.cancelModal.cancel': 'Avbryt',
  'guides.cancelModal.confirm': 'Avsluta',

  'guides.title': 'Guider',
  'guides.title.acne': 'Akne',
  'guides.title.allergy': 'Allergi',
  'guides.title.asthma': 'Astma',
  'guides.title.backpain': 'Ryggbesvär',
  'guides.title.borrelia': 'Borrelia',
  'guides.title.bugBite': 'Insektsbett och -stick',
  'guides.title.chlamydia': 'Klamydia',
  'guides.title.conjunctivitis': 'Ögoninflammation',
  'guides.title.contraception': 'Preventivmedel',
  'guides.title.cough': 'Hosta',
  'guides.title.surveyCaseClosed': 'Uppföljning vid stängt ärende',
  'guides.title.dieticianAdvice': 'Bedömning av dietist',
  'guides.title.dieticianRevisit': 'Återbesök dietist',
  'guides.title.eczema': 'Exem & hudutslag',
  'guides.title.gastricProblemsLower': 'Magbesvär - nedre',
  'guides.title.gastricProblemsUpper': 'Magbesvär - övre',
  'guides.title.headache': 'Huvudvärk',
  'guides.title.healthCheck': 'Hälsokollen',
  'guides.title.herpes': 'Herpes',
  'guides.title.kioskBloodpressure': 'Blodtryck (kiosk)',
  'guides.title.mentalHealth': 'Stress, oro & depression',
  'guides.title.onychomycosis': 'Nagelsvamp',
  'guides.title.physio': 'Sjukgymnastik / Fysioterapi',
  'guides.title.pms': 'Premenstruella besvär',
  'guides.title.prescriptionRenewal': 'Receptförnyelse',
  'guides.title.psychologyAdvice': 'Bedömning av psykolog',
  'guides.title.psychologyAdviceChild': 'Bedömning av psykolog (barn)',
  'guides.title.tonsillitis': 'Ont i halsen',
  'guides.title.uti': 'Urinvägsbesvär',
  'guides.title.vaccination': 'Vaccination',
  'guides.title.vaccinationAdvice': 'Vaccinationsrådgivning',
  'guides.title.dummy': 'Dummy',
  'guides.title.genericDemoGuide': 'Generic Demo Guide',

  'modules.intro':
    'Moduler är testbara block av frågor som används i guider men är inte tillgängliga fristående externt',
  'modules.title': 'Moduler',
  'modules.title.moduleAlcohol': 'Alkohol',
  'modules.title.moduleAllergyAntibiotics': 'Allergi - antibiotika/penicillin',
  'modules.title.moduleAllergyMedications': 'Allergi - läkemedel',
  'modules.title.moduleALlergyOther': 'Allergi - övrigt',
  'modules.title.moduleAnamnesis': 'Sjukdomsanamnes',
  'modules.title.moduleBodyMeasures': 'Längd, vikt och BMI',
  'modules.title.moduleHeredity': 'Hereditet',
  'modules.title.moduleMedications': 'Läkemedel',
  'modules.title.moduleNaturalMedications': 'Naturläkemedel',
  'modules.title.moduleOccupation': 'Sysselsättning',
  'modules.title.moduleOther': 'Övrigt',
  'modules.title.moduleSmoking': 'Rökning',

  'input.yes': 'Ja',
  'input.no': 'Nej',
  'input.unknown': 'Vet ej',
  'input.upload': 'Ladda upp',
  'input.upload.skip': 'Hoppa över',
  'input.unanswered': 'Obsesvarad',
  'input.placeholder': 'Ditt svar',
  'input.noneOfTheAbove': 'Inget av ovanstående',
  'input.next': 'Nästa',
  'input.unit': 'Ange {unit}',
  'input.noPhoto': 'Inget foto uppladdat',

  'errors.required': 'Måste besvaras',
  'errors.min': 'Svaret är för lågt',
  'errors.max': 'Svaret är för högt',

  'patient.title': 'Patient',
  'patient.gender': 'Kön',
  'patient.female': 'Kvinna',
  'patient.male': 'Man',
  'patient.age': 'Ålder',
  'patient.years': 'år',
  'patient.cancel': 'Avbryt',
  'patient.save': 'Spara',
  'patient.domain': 'Domän',
};

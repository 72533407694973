import type { Guide } from '../../types';

import ds from './decisionSupport';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const {
    ask,
    decisionSupport,
    exports,
    patient: { gender },
    significant,
  } = args;

  let acneTreatmentAbxDurationAnswer = [];
  let acneContinueTreatmentAnswer;
  let acneCurrentTreatmentAnswer;
  let acneCurrentTreatmentSatisfactionAnswer;

  const acnePreviouslyDiagnosed = {
    type: 'binary',
    id: 'acnePreviouslyDiagnosed',
    label: 'Has your child been given a diagnosis of acne?',
  };

  const acnePreviouslyDiagnosedAnswer = await ask(acnePreviouslyDiagnosed);

  if (acnePreviouslyDiagnosedAnswer === 'no') {
    significant.push({
      ...acnePreviouslyDiagnosed,
      label: 'Previously diagnosed of acne',
      value: acnePreviouslyDiagnosedAnswer,
      warn: ['no'],
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Previously given diagnosis of acne',
      no: 'Not previously been given a diagnosis of acne',
    }[acnePreviouslyDiagnosedAnswer],
  });

  const acneDuration = {
    type: 'choice',
    id: 'acneDuration',
    label: 'How long has your child had acne?',
    options: [
      { label: 'Less than a month', value: 'lessThanOneMonth' },
      { label: '1–12 months', value: 'oneToTwelveMonths' },
      { label: 'More than 12 months', value: 'moreThanTwelveMonths' },
    ],
  };

  const acneDurationAnswer = await ask(acneDuration);

  significant.push({
    ...acneDuration,
    label: 'Duration of acne',
    value: acneDurationAnswer,
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      lessThanOneMonth: 'Acne duration: less than a month',
      oneToTwelveMonths: 'Acne duration: 1–12 months',
      moreThanTwelveMonths: 'Acne duration: more than 12 months',
    }[acneDurationAnswer],
  });

  const acneLocation = {
    type: 'multipleChoice',
    id: 'acneLocation',
    label: "Where is your child's acne?",
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Face', value: 'face' },
      { label: 'Neck', value: 'neck' },
      { label: 'Chest', value: 'chest' },
      { label: 'Back', value: 'back' },
      { label: 'Other', value: 'other' },
    ],
  };

  const acneLocationAnswer = await ask(acneLocation);

  if (acneLocationAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...acneLocation,
      label: 'Location of acne',
      description: '',
      value: acneLocationAnswer.filter(answer => answer !== 'other'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      face: 'Acne on face',
      neck: 'Acne on neck',
      chest: 'Acne on chest',
      back: 'Acne on back',
    }[acneLocationAnswer],
  });

  if (acneLocationAnswer.includes('other')) {
    const acneLocationOther = {
      type: 'text',
      id: 'acneLocationOther',
      label: 'Please tell us where the acne is.',
    };

    const acneLocationOtherAnswer = await ask(acneLocationOther);

    significant.push({
      ...acneLocationOther,
      label: 'Location of acne',
      value: acneLocationOtherAnswer,
    });
  }

  const acneDescription = {
    type: 'multipleChoice',
    id: 'acneDescription',
    label: "How would you describe your child's acne?",
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Small bumps', value: 'smallBumps' },
      { label: 'Inflammed red spots', value: 'redSpots' },
      { label: 'Whiteheads', value: 'whiteheads' },
      { label: 'Blackheads', value: 'blackheads' },
      {
        label: 'Deep tender lumps',
        value: 'deepTenderLumps',
      },
      { label: 'Scarring', value: 'scarring' },
    ],
  };

  const acneDescriptionAnswer = await ask(acneDescription);

  significant.push({
    ...acneDescription,
    label: 'Description of acne',
    description: '',
    value: acneDescriptionAnswer,
    warn:
      acneDescriptionAnswer.includes('deepTenderLumps') ||
      acneDescriptionAnswer.includes('scarring'),
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      smallBumps: 'Acne: Small bumps',
      redSpots: 'Acne: Inflamed red spots',
      whiteheads: 'Acne: Whiteheads',
      blackheads: 'Acne: Blackheads',
      deepTenderLumps: 'Acne: Deep tender lumps under the skin',
      scarring: 'Acne: Scarring',
    }[acneDescriptionAnswer],
  });

  if (acneDescriptionAnswer.includes('scarring')) {
    decisionSupport.push(ds.scarringAcne);
  }

  const acneCausingPsychologicalDistress = {
    type: 'binary',
    id: 'acneCausingPsychologicalDistress',
    label:
      "Do you think your child's acne might be causing them to feel anxious or depressed?",
  };

  const acneCausingPsychologicalDistressAnswer = await ask(
    acneCausingPsychologicalDistress
  );

  if (acneCausingPsychologicalDistressAnswer === 'no') {
    significant.push({
      ...acneCausingPsychologicalDistress,
      label: 'Psychological distress',
      value: acneCausingPsychologicalDistressAnswer,
    });
  }

  if (acneCausingPsychologicalDistressAnswer === 'yes') {
    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Acne causing psychological distress',
      }[acneCausingPsychologicalDistressAnswer],
    });

    decisionSupport.push(ds.distressPsychological);

    const acneImpactOnLife = {
      type: 'text',
      id: 'acneImpactOnLife',
      label: 'Please tell us how the acne is impacting on their life.',
    };

    const acneImpactOnLifeAnswer = await ask(acneImpactOnLife);

    significant.push({
      ...acneImpactOnLife,
      label: 'Psychological distress, description',
      value: acneImpactOnLifeAnswer,
    });
  }

  const acneCurrentTreatment = {
    type: 'binary',
    id: 'acneCurrentTreatment',
    label: 'Is your child currently using acne treatment?',
  };

  acneCurrentTreatmentAnswer = await ask(acneCurrentTreatment);

  if (acneCurrentTreatmentAnswer === 'no') {
    significant.push({
      ...acneCurrentTreatment,
      label: 'Current acne treatment',
      value: acneCurrentTreatmentAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Currently using acne treatment ',
      no: 'Not currently using acne treatment',
    }[acneCurrentTreatmentAnswer],
  });

  if (acneCurrentTreatmentAnswer === 'yes') {
    let options = [
      { label: 'Gel or cream', value: 'gelOrCream' },
      { label: 'Antibiotic tablets', value: 'abx' },
      { label: 'Other', value: 'other' },
    ];

    if (gender === 'female') {
      options.splice(options.length - 1, 0, {
        label: 'Contraceptive pill',
        value: 'contraceptivePill',
      });
    }

    const acneCurrentTreatmentWhat = {
      type: 'multipleChoice',
      id: 'acneCurrentTreatmentWhat',
      label: 'What treatment are they using?',
      description: 'More than one answer can be selected.',
      options,
    };

    const acneCurrentTreatmentWhatAnswer = await ask(acneCurrentTreatmentWhat);

    if (
      acneCurrentTreatmentWhatAnswer.every(
        answer => answer === 'contraceptivePill'
      )
    ) {
      significant.push({
        ...acneCurrentTreatmentWhat,
        label: 'Type of treatment',
        description: '',
        value: acneCurrentTreatmentWhatAnswer.filter(
          answer => answer === 'contraceptivePill'
        ),
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        gelOrCream: 'Acne treatment - Gel or cream',
        abx: 'Acne treatment - Antibiotic tablets',
        contraceptivePill: 'Acne treatment - Contraceptive pill',
      }[acneCurrentTreatmentWhatAnswer],
    });

    if (acneCurrentTreatmentWhatAnswer.includes('gelOrCream')) {
      const acneTreatmentWhatGelOrCream = {
        type: 'text',
        id: 'acneTreatmentWhatGelOrCream',
        label: 'What is the name of the gel or cream they are using?',
      };

      const acneTreatmentWhatGelOrCreamAnswer = await ask(
        acneTreatmentWhatGelOrCream
      );

      significant.push({
        ...acneTreatmentWhatGelOrCream,
        label: 'Current acne treatment',
        value: acneTreatmentWhatGelOrCreamAnswer,
      });
    }

    if (acneCurrentTreatmentWhatAnswer.includes('abx')) {
      const acneTreatmentWhatAbx = {
        type: 'text',
        id: 'acneTreatmentWhatAbx',
        label: 'What antibiotics are they taking?',
      };

      const acneTreatmentWhatAbxAnswer = await ask(acneTreatmentWhatAbx);

      significant.push({
        ...acneTreatmentWhatAbx,
        label: 'Current acne treatment',
        value: acneTreatmentWhatAbxAnswer,
      });

      const acneTreatmentAbxDuration = {
        type: 'choice',
        id: 'acneTreatmentAbxDuration',
        label: 'How long have they been taking antibiotics continuously?',
        options: [
          { label: 'Less than a month', value: 'lessThanOneMonth' },
          { label: '1–3 months', value: 'oneToThreeMonths' },
          { label: '3–6 months', value: 'threeToSixMonths' },
          { label: '6–12 months', value: 'sixToTwelveMonths' },
          { label: 'More than 12 months', value: 'moreThanTwelveMonths' },
        ],
      };

      acneTreatmentAbxDurationAnswer = await ask(acneTreatmentAbxDuration);

      if (!acneTreatmentAbxDurationAnswer.includes('moreThanTwelveMonths')) {
        significant.push({
          ...acneTreatmentAbxDuration,
          label: 'Duration of antibiotic use',
          value: acneTreatmentAbxDurationAnswer,
        });
      }

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          lessThanOneMonth: 'Using antibiotics for less than a month',
          oneToThreeMonths: 'Antibiotics for 1-3 months',
          threeToSixMonths: 'Antibiotics for 3-6 months',
          sixToTwelveMonths: 'Antibiotics for 6–12 months',
          moreThanTwelveMonths: 'Antibiotics for more than 12 months',
        }[acneTreatmentAbxDurationAnswer],
      });

      if (acneTreatmentAbxDurationAnswer.includes('moreThanTwelveMonths')) {
        const acneTreatmentAbxLongDuration = {
          type: 'text',
          id: 'acneTreatmentAbxLongDuration',
          label:
            'How long exactly have they been taking antibiotics continuously?',
        };

        const acneTreatmentAbxLongDurationAnswer = await ask(
          acneTreatmentAbxLongDuration
        );

        significant.push({
          ...acneTreatmentAbxLongDuration,
          label: 'Duration of antibiotic use',
          value: acneTreatmentAbxLongDurationAnswer,
          warn: true,
        });
      }

      if (!acneTreatmentAbxDurationAnswer.includes('lessThanOneMonth')) {
        const LFTcheck = {
          type: 'binary',
          id: 'LFTcheck',
          label: 'In the last 6 months, has your child had a blood test?',
        };

        const LFTcheckAnswer = await ask(LFTcheck);

        significant.push({
          ...LFTcheck,
          label: 'Blood test in last 6 months',
          value: LFTcheckAnswer,
          warn: ['no'],
        });

        if (LFTcheckAnswer === 'yes') {
          decisionSupport.push(ds.bloodTests);
        } else if (LFTcheckAnswer === 'no') {
          decisionSupport.push(ds.noBloodTests);
        }
      }
    }

    if (acneCurrentTreatmentWhatAnswer.includes('other')) {
      const acneTreatmentOther = {
        type: 'text',
        id: 'acneTreatmentOther',
        label: 'What other acne treatments are they using?',
      };

      const acneTreatmentOtherAnswer = await ask(acneTreatmentOther);

      significant.push({
        ...acneTreatmentOther,
        label: 'Current acne treatment',
        value: acneTreatmentOtherAnswer,
      });
    }

    const acneCurrentTreatmentSatisfaction = {
      type: 'binary',
      id: 'acneCurrentTreatmentSatisfaction',
      label: 'Is the current treatment working?',
    };

    acneCurrentTreatmentSatisfactionAnswer = await ask(
      acneCurrentTreatmentSatisfaction
    );

    if (acneCurrentTreatmentSatisfactionAnswer === 'no') {
      significant.push({
        ...acneCurrentTreatmentSatisfaction,
        label: 'Current treatment working',
        value: acneCurrentTreatmentSatisfactionAnswer,
        warn: ['no'],
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Current acne treatment working well',
        no: 'Current acne treatment not working',
      }[acneCurrentTreatmentSatisfactionAnswer],
    });

    if (acneCurrentTreatmentSatisfactionAnswer === 'yes') {
      const acneContinueTreatment = {
        type: 'binary',
        id: 'acneContinueTreatment',
        label: 'Do you want to continue this treatment for your child?',
      };

      acneContinueTreatmentAnswer = await ask(acneContinueTreatment);

      significant.push({
        ...acneContinueTreatment,
        label: 'Do you want to continue current acne treatment?',
        value: acneContinueTreatmentAnswer,
        warn: ['no'],
      });

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          yes: 'Wants to continue on current acne treatment',
          no: 'Does not want to continue on current acne treatment',
        }[acneContinueTreatmentAnswer],
      });

      if (acneContinueTreatmentAnswer === 'no') {
        const acneContinueNotTreatmentWhy = {
          type: 'text',
          id: 'acneContinueNotTreatmentWhy',
          label:
            'Please can you explain why you do not want to continue this treatment?',
        };

        const acneContinueNotTreatmentWhyAnswer = await ask(
          acneContinueNotTreatmentWhy
        );

        significant.push({
          ...acneContinueNotTreatmentWhy,
          label: 'Reason why doesnt want to continue acne treatment',
          value: acneContinueNotTreatmentWhyAnswer,
        });
      }
    }

    const acnePastTreatment = {
      type: 'binary',
      id: 'acnePastTreatment',
      label: 'Has your child had any other treatment for acne in the past?',
    };

    const acnePastTreatmentAnswer = await ask(acnePastTreatment);

    if (acnePastTreatmentAnswer === 'no') {
      significant.push({
        ...acnePastTreatment,
        label: 'Past treatment for acne',
        value: acnePastTreatmentAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Has had previous acne treatment',
        no: 'No previous acne treatment',
      }[acnePastTreatmentAnswer],
    });

    if (acnePastTreatmentAnswer === 'yes') {
      let options = [
        { label: 'Gel or cream', value: 'gelOrCream' },
        { label: 'Antibiotic tablets', value: 'abx' },
        { label: 'Other', value: 'other' },
      ];

      if (gender === 'female') {
        options.splice(options.length - 1, 0, {
          label: 'Contraceptive pill',
          value: 'contraceptivePill',
        });

        const acnePastTreatmentWhat = {
          type: 'multipleChoice',
          id: 'acnePastTreatmentWhat',
          label: 'Please tell us what treatment they previously had.',
          description: 'More than one answer can be selected.',
          options,
        };

        const acnePastTreatmentWhatAnswer = await ask(acnePastTreatmentWhat);

        significant.push({
          ...acnePastTreatmentWhat,
          label: 'Previous treatment for acne',
          description: '',
          value: acnePastTreatmentWhatAnswer,
        });

        addExportIfValueExists(exports, {
          id: 'keyFindings',
          value: {
            gelOrCream: 'Previously used gel / cream for acne',
            abx: 'Previously on oral antibiotics for acne',
            contraceptivePill: 'Previously used COCP for acne',
          }[acnePastTreatmentWhatAnswer],
        });

        if (acnePastTreatmentWhatAnswer.includes('other')) {
          const acnePastTreatmentOther = {
            type: 'text',
            id: 'acnePastTreatmentOther',
            label:
              'Please tell us specifically what other treatment you previously had.',
          };

          const acnePastTreatmentOtherAnswer = await ask(
            acnePastTreatmentOther
          );

          significant.push({
            ...acnePastTreatmentOther,
            label: 'Past treatment, other',
            value: acnePastTreatmentOtherAnswer,
          });
        }
      }
    }
  }

  if (
    acneContinueTreatmentAnswer === 'no' ||
    acneCurrentTreatmentAnswer === 'no' ||
    acneCurrentTreatmentSatisfactionAnswer === 'no'
  ) {
    let options = [
      { label: 'Gel or cream', value: 'gelOrCream' },
      { label: 'Antibiotic tablets', value: 'abx' },
      { label: 'No preference', value: 'noPreference' },
    ];

    if (gender === 'female') {
      options.splice(options.length - 1, 0, {
        label: 'Contraceptive pill',
        value: 'contraceptivePill',
      });

      const acneTreatmentPreference = {
        type: 'choice',
        id: 'acneTreatmentPreference',
        label:
          'Is there a particular kind of treatment you want for your acne?',
        options,
      };

      const acneTreatmentPreferenceAnswer = await ask(acneTreatmentPreference);

      significant.push({
        ...acneTreatmentPreference,
        label: 'Preferred acne treatment option',
        value: acneTreatmentPreferenceAnswer,
      });

      if (acneTreatmentPreferenceAnswer === 'contraceptivePill') {
        decisionSupport.push(ds.COCP);
      }

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          gelOrCream: 'Would prefer cream for acne',
          abx: 'Would prefer antibiotic tablets for acne',
          contraceptivePill: 'Would prefer contraceptive pill',
          noPreference: 'No preference for acne treatment option',
        }[acneTreatmentPreferenceAnswer],
      });
    }
  }

  const photoAcne = await ask.upload(
    `We'd like you to upload a photo of your child's acne to help the doctor with their diagnosis.`,
    {
      description: `
          You can use a mobile phone camera to take the photo.\n\nRest assured, your photos will be kept confidential and will only be added to your medical record.
        `,
      optional: true,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photoAcne,
  });

  return {};
}: Guide);

import type { Guide } from '../../types';
import triage from './triage';
import aborts from './abort';
import summaryExports from './summaryExports';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

export default (async args => {
  const { exports, significant, ask } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  summaryExports(exports);

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  const reason = await eChoice({
    id: 'returnVisitChild.reasonForReturn',
    label: 'Why are you visiting the doctor today?',
    description: 'More than one answer can be selected',
    clinicLabel: 'Reason for return',
    isMultipleChoice: true,
    options: [
      {
        value: 'notGettingBetter',
        label: 'Not getting better',
        keyFinding: 'No improvement in symptoms',
        warn: true,
      },
      {
        value: 'gettingWorse',
        label: 'Symptoms are worse',
        keyFinding: 'Worsening symptoms',
        warn: true,
      },
      {
        value: 'newSymptoms',
        label: 'New symptoms',
        keyFinding: 'New symptoms',
        warn: true,
      },
      {
        value: 'needAntibiotics',
        label: 'I think my child needs antibiotics',
        keyFinding: 'Thinks child needs antibiotics',
        warn: true,
      },
      {
        value: 'askAQuestion',
        label: 'Ask a question',
        keyFinding: 'Has a question',
      },
      {
        value: 'appointmentIssue',
        label: 'Appointment issue',
        keyFinding: 'Appointment issue',
      },
      {
        value: 'feedback',
        label: 'Give feedback',
        keyFinding: 'Wants to leave feedback',
      },
    ],
  });
  // Only abort if only complaint was selected.
  if (
    Array.isArray(reason) &&
    reason.length === 1 &&
    reason[0] === 'feedback'
  ) {
    return { abort: aborts.zenDesk };
  }

  await eText({
    id: 'returnVisitChild.moreInfo',
    label: 'Please tell us more.',
    clinicLabel: 'Reason for return',
  });

  const photo = await ask.upload(
    `
You can now upload a photo of your child's problem (e.g. sore throat or skin rash) if you feel this will help the doctor. 

You do not need to do this if you don't think it is needed.
`,
    {
      id: 'returnVisitChild.upload',
      optional: true,
      description: `
You can use a mobile phone camera to take the photo.

For best results please use good lighting.

Rest assured, your photos will be kept confidential and will only be added to your medical record.
    `,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photo,
  });

  await ask.choice({
    description: `
We will aim to reply within the next 2 hours.  

If your child becomes more unwell whilst you are waiting, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.
`,
    options: ['I understand'],
  });

  return {};
}: Guide);

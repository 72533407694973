import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import ds from './decisionSupport';

export default (async args => {
  const { scores } = args;

  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);
  const bumpWarningScore = () => {
    scores.increase('warning');
  };
  const bumpAirwaysScore = () => {
    scores.increase('airways');
  };

  await eChoice({
    id: 'coughChild.familyhistoryofatopyorTB',
    label:
      'Does anyone in the immediate family (parents or siblings) have any of the following conditions?',
    clinicLabel: 'Family history of atopy or TB',
    optional: true,
    isMultipleChoice: true,
    options: [
      {
        label: 'Hayfever',
        value: 'Hayfever',
        keyFinding: 'FH of hayfever',
        onSelected: bumpAirwaysScore,
      },
      {
        label: 'Asthma',
        value: 'Asthma',
        keyFinding: 'FH of asthma',
        onSelected: bumpAirwaysScore,
      },
      {
        label: 'Eczema',
        value: 'Eczema',
        keyFinding: 'FH of eczema',
        onSelected: bumpAirwaysScore,
      },
    ],
  });

  const recentforeigntravel = await eChoice({
    id: 'coughChild.recentforeigntravel',
    label: 'Has your child travelled abroad in the last year?',
    clinicLabel: 'Recent foreign travel',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Recent foreign travel',
        hide: true,
      },
      { label: 'No', value: 'no', keyFinding: 'No recent foreign travel' },
    ],
  });

  if (recentforeigntravel === 'yes') {
    await eText({
      id: 'coughChild.destinationofrecentforeigntravel',
      label: 'What country did your child visit?',
      clinicLabel: 'Destination of recent foreign travel',
    });
  }

  const tb = await eChoice({
    id: 'coughChild.recentcontactwithTB',
    label: 'Has your child recently had contact with anyone who has TB?',
    clinicLabel: 'Recent contact with TB',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Recent contact with TB',
        hide: true,
        onSelected: bumpWarningScore,
      },
      { label: 'No', value: 'no', keyFinding: 'No recent contact with TB' },
    ],
  });

  if (tb === 'yes') {
    await eText({
      label: 'Please tell us more about their contact with TB',
      clinicLabel: 'Recent contact with TB',
      warn: true,
    });
  }

  await eChoice({
    id: 'coughChild.growth',
    label: 'Is your child growing normally?',
    clinicLabel: 'Growth',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Growing normally',
      },
      {
        label: 'No, they are putting on too much weight',
        value: 'Mychildisputtingontoomuchweight',
        keyFinding: 'Putting on too much weight',
        warn: true,
      },
      {
        label: 'No, they are not putting on weight',
        value: 'Mychildisnotputtingonanyweight',
        keyFinding: 'Not putting on weight',
        warn: true,
        onSelected: bumpWarningScore,
      },
      {
        label: 'No, they are losing weight',
        value: 'Mychildislosingweight',
        keyFinding: 'Losing weight',
        warn: true,
        ds: ds.loosingWeight,
        onSelected: bumpWarningScore,
      },
    ],
  });

  await eChoice({
    id: 'coughChild.nightsweats',
    label: 'Does your child have night sweats?',
    clinicLabel: 'Night sweats',
    options: [
      {
        label: `Yes, I need to change my child's clothes because they are drenched.`,
        value: 'yesDrenching',
        keyFinding: 'Drenching night sweats',
        onSelected: bumpWarningScore,
        ds: ds.nightsweats,
        warn: true,
      },
      {
        label: `Yes, I do not need to change my child's clothes.`,
        value: 'yesNotDrenching',
        keyFinding: 'Not drenching night sweats',
        warn: true,
      },

      { label: 'No', value: 'no', keyFinding: 'No night sweats' },
    ],
  });

  await eChoice({
    id: 'coughChild.hayfeversymptoms',
    label: 'Does your child get symptoms of hayfever?',
    description: 'E.g. runny nose, runny eyes, sneezing, tickly throat',
    clinicLabel: 'Hayfever symptoms',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Hayfever symptoms' },
      { label: 'No', value: 'no', keyFinding: 'No hayfever symptoms' },
    ],
  });

  await eChoice({
    id: 'coughChild.soundslikeclearingthroat',
    label: 'Does it sound like your child is trying to clear their throat?',
    clinicLabel: 'Sounds like clearing throat',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Sounds like clearing throat',
        ds: ds.clearThroat,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Doesnt sound like clearing throat',
      },
    ],
  });

  const tic = await eChoice({
    id: 'coughChild.tic',
    label: 'Does your child have a tic?',
    description: 'E.g. wrinkling of nose, blinking, grunting, sniffing.',
    clinicLabel: 'Tic',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Has a tic', hide: true },
      { label: 'No', value: 'no', keyFinding: 'No tic' },
    ],
  });

  if (tic === 'yes') {
    await eText({
      id: 'coughChild.descriptionoftic',
      label: 'Please describe the tic',
      clinicLabel: 'Description of tic',
    });
  }

  await eChoice({
    id: 'coughChild.householdmould',
    label: 'Does your house have mould?',
    clinicLabel: 'Household mould',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Household mould' },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'No household mould',
        hide: true,
      },
    ],
  });

  await eChoice({
    id: 'coughChild.pets',
    label: 'Do you have pets?',
    clinicLabel: 'Pets',
    options: [
      {
        label: 'Yes, a furry pet (e.g. a dog, cat, rabbit etc)',
        value: 'Furry',
        keyFinding: 'Furry pet',
      },
      {
        label: 'Yes, a bird (e.g. parrot)',
        value: 'Bird',
        keyFinding: 'Bird pet',
      },
      {
        label: 'Yes, fish or reptile',
        value: 'FishReptile',
        keyFinding: 'Fish or reptile pet',
        hide: true,
      },
      { label: 'No', value: 'no', keyFinding: 'No pets', hide: true },
    ],
  });

  return {};
}: Guide);

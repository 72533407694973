import { type Action } from '../types';

import { type State, DRAFTS_UPDATE } from './types';

export default (state: State = { byId: {} }, action: Action): State => {
  const { type, payload = {} } = action;

  switch (type) {
    case DRAFTS_UPDATE: {
      const { id, draft } = payload;

      return { ...state, byId: { ...state.byId, [id]: draft } };
    }

    default:
      return state;
  }
};

// @flow

import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import triage from './triage';
import pain from './pain';
import shortOfBreath from './shortOfBreath';
import abort from '../common/commonAborts';
import ds from './decisionSupport';
import createExtendedText from '../common/extendedText';
import { coronaSeason } from '../common/consts';
import moduleEndMessage from '../modules/endMessage';
import modulePharmacy from '../modules/pharmacy';
import moduleAlcohol from '../modules/alcohol';
import moduleAlternateMedication from '../modules/alternateMedication';
import moduleSmoking from '../modules/smoking';
import moduleAllergies from '../modules/allergies';
import moduleBmi from '../modules/bmi';
import describeProblem from './describeProblem';
import summaryExports from './summaryExports';
import { ensureArray } from '../common/arrayUtils';
import collateDecisionSupport from '../common/collateDecisionSupport';

export default (async args => {
  const { significant, ask, exports, decisionSupport } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  summaryExports(exports);

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  await ask.info({
    description:
      'We will now ask you a few questions about your current problem.',
  });

  await eText({
    id: 'otherProblems.whatIsYourProblem',
    label: `What is your problem today?`,
    description: 'Please give us much detail as possible',
  });

  const newProblem = await eChoice({
    id: 'otherProblems.newProblem',
    label: `Is this a new problem?`,
    clinicLabel: 'New problem',
    options: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No, but it comes and goes',
        value: 'returned',
      },
      {
        label: 'No, but it has changed',
        value: 'changed',
      },
      {
        label: 'No, but it has worsened',
        value: 'worsened',
      },
    ],
  });

  switch (newProblem) {
    case 'yes':
      await eText({
        id: 'otherProblems.whenProblemStarted',
        label: `When did the problem start?`,
        clinicLabel: 'When did the problem start',
      });
      break;
    case 'returned':
      await eText({
        id: 'otherProblems.whenProblemStartedThisTime',
        label: `When did the problem start this time?`,
        clinicLabel: 'When did the problem start this time',
      });
      break;
    case 'changed':
      await eText({
        id: 'otherProblems.whenProblemChanged',
        label: `When did the problem change?`,
        clinicLabel: 'When did the problem change',
      });
      break;
    case 'worsened':
      await eText({
        id: 'otherProblems.whenProblemWorsened',
        label: `When did the problem worsen?`,
        clinicLabel: 'When did the problem worsen',
      });
      break;
    default:
  }

  const havePain = await eChoice({
    id: 'otherProblems.havePain',
    label: `Is your problem due to pain or discomfort?`,
    clinicLabel: 'Pain',
    options: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  });
  if (havePain === 'yes') {
    const { abort: painAbort } = await pain(args);
    if (painAbort) {
      return { abort: painAbort };
    }
  }

  const shortnessOfBreath = await eChoice({
    id: 'otherProblems.shortnessOfBreath',
    label: `Do you feel short of breath?`,
    clinicLabel: 'Shortness of breath',
    options: [
      {
        value: 'yes',
        keyFinding: 'Shortness of breath',
      },
      {
        value: 'no',
      },
    ],
  });
  if (shortnessOfBreath === 'yes') {
    const { abort: shortnessOfBreathAbort } = await shortOfBreath(args);
    if (shortnessOfBreathAbort) {
      return { abort: shortnessOfBreathAbort };
    }
  }

  const fever = await eChoice({
    id: 'otherProblems.fever',
    label: `Have you had a high temperature?`,
    clinicLabel: 'Fever in past 24 hours',
    options: [
      {
        label: 'Yes, in the last 24 hours',
        value: 'last24Hours',
        keyFinding: 'Fever in the past 24 hours',
        warn: true,
      },
      {
        label: 'Yes, 1–7 days ago',
        value: 'last7Days',
        keyFinding: 'Fever in the last 7 days',
      },
      {
        label: 'Yes, 1–4 weeks ago',
        value: '1To4WeeksAgo',
        keyFinding: 'Fever 1–4 weeks ago',
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'No fever',
      },
    ],
  });

  if (['last24Hours', 'last7Days'].includes(fever)) {
    const sepsisSymptoms = await eChoice({
      id: 'otherProblems.sepsisSymptoms',
      label: `Have you had any of the following symptoms in the past 24 hours?`,
      clinicLabel: 'Sepsis symptoms',
      isMultipleChoice: true,
      optional: true,
      options: [
        {
          label: 'Rash',
          value: 'rash',
          keyFinding: 'Rash in past 24 hours',
          warn: true,
          abort: abort.adultSepsisAe,
        },
        {
          label: 'Neck stiffness',
          value: 'neckStiffness',
          keyFinding: 'Neck stiffness in past 24 hours',
          warn: true,
          abort: abort.adultSepsisAe,
        },
        {
          label: 'Sensitivity to bright lights',
          value: 'photophobia',
          keyFinding: 'Photophobia in past 24 hours',
          warn: true,
          abort: abort.adultSepsisAe,
        },
        {
          label: 'Shaking uncontrollably',
          value: 'rigors',
          keyFinding: 'Rigors in past 24 hours',
          warn: true,
          abort: abort.adultSepsisAe,
        },
      ],
    });
    if (sepsisSymptoms.abort) {
      return { abort: sepsisSymptoms.abort };
    }
  }

  const fainted = await eChoice({
    id: 'otherProblems.fainted',
    label: `In the past 24 hours have you fainted, or almost fainted?`,
    clinicLabel: 'Syncope or presyncope',
    options: [
      {
        value: 'yes',
        warn: true,
        urgent: true,
        keyFinding: 'Syncope in past 24 hours',
        ds: ds.pvConsultSyncopePresycnope,
        abort: coronaSeason ? undefined : abort.adultSyncopeUrgent,
      },
      {
        value: 'no',
      },
    ],
  });
  if (fainted.abort) {
    return { abort: fainted.abort };
  }

  await describeProblem(args);

  const soughtHelpAlready = await eChoice({
    id: 'otherProblems.soughtHelpAlready',
    label: `Have you spoken to a doctor previously about today's problem?`,
    clinicLabel: 'Sought help already',
    options: [
      {
        value: 'yes',
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  if (newProblem !== 'yes' || soughtHelpAlready === 'yes') {
    await eChoice({
      id: 'otherProblems.previousInvestigations',
      label: `Have you had any tests or scans for the problem before?`,
      description: 'E.g. blood tests, x-rays or CT-scans.',
      clinicLabel: 'Previous investigations',
      options: [
        {
          value: 'yes',
          ds: ds.previousInvestigations,
        },
        {
          value: 'no',
          hide: true,
          ds: ds.previousDoctorSeen,
        },
      ],
    });
  }

  const uploadPhoto = await eChoice({
    id: 'otherProblems.photoUpload',
    label: `Do you want to upload a photo of your problem?`,
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (uploadPhoto === 'yes') {
    const photos = await ask.upload(
      `Please take 2 pictures of your problem. Attach one close-up and one overview picture.`,
      {
        description: `
You can use a mobile phone camera to take the photo — you can ask someone to take the photo for you, or you can do it yourself by activating the selfie-mode.

Rest assured, your photos will be kept confidential and will only be added to your medical record.
    `,
      }
    );
    significant.push({
      type: 'upload',
      label: 'Photo/s',
      value: photos,
    });
  }

  await eChoice({
    id: 'otherProblems.howRespond',
    label: `If we need to ask more questions, how would you like us to do this?`,
    clinicLabel: 'Followup preference',
    options: [
      {
        label: 'Message',
        value: 'message',
        warn: true,
      },
      {
        label: 'Phone call',
        value: 'phone',
        warn: true,
      },
      {
        label: 'Video call',
        value: 'video',
        warn: true,
      },
    ],
  });

  await ask.info({
    description:
      'We are now going to ask you some general questions about your health...',
  });

  await moduleBmi(args);
  await moduleSmoking(args);
  await moduleAlcohol(args);
  await moduleAlternateMedication(args);
  await moduleAllergies(args);

  const pmh = await eChoice({
    id: 'otherProblems.pmh',
    label: `Do you have any of the following medical conditions?`,
    description: 'More than one answer can be selected.',
    clinicLabel: 'Past medical history',
    expires: 365,
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: "Asthma / COPD (smoker's lungs)",
        value: 'copd',
        warn: true,
        ds: ds.reviewPmh,
      },
      {
        label: 'Heart attack or angina',
        value: 'heartAttackOrAngina',
        warn: true,
        ds: ds.reviewPmh,
      },
      {
        label: 'Irregular heart beat (Atrial Fibrillation)',
        value: 'atrialFibrillation',
        warn: true,
        ds: ds.reviewPmh,
      },
      {
        label: 'Blood clots to the legs or lung (DVT or PE)',
        value: 'DVTorPE',
        warn: true,
        ds: ds.reviewPmh,
      },
      {
        label: 'Stroke or TIA (mini stroke)',
        value: 'strokeOrTIA',
        warn: true,
        ds: ds.reviewPmh,
      },
      {
        label: 'Diabetes',
        value: 'diabetes',
        warn: true,
        ds: ds.reviewPmh,
      },
      {
        label: 'Epilepsy',
        value: 'epilepsy',
        warn: true,
        ds: ds.reviewPmh,
      },
      {
        label: 'Other medical condition',
        value: 'other',
        hide: true,
        ds: ds.reviewPmh,
      },
    ],
  });
  if (ensureArray(pmh).includes('other')) {
    await eText({
      id: 'otherProblems.pmhOther',
      label: `Please list the other medical conditions you suffer from`,
      clinicLabel: 'Past medical history',
      warn: true,
    });
  }

  await modulePharmacy(args);
  await moduleEndMessage(args);

  collateDecisionSupport(decisionSupport);

  return {};
}: Guide);

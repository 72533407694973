import type { Guide } from '../../types';

import pregnantQuestion from '../questions/pregnant';
import breastfeedingQuestion from '../questions/breastfeeding';

export default (async ({ ask, patient: { gender, age }, decisionSupport }) => {
  if (gender === 'female' && age < 55) {
    const pregnant = await ask(pregnantQuestion());
    const breastfeeding = await ask(breastfeedingQuestion());
    if (pregnant === 'yes') {
      decisionSupport.push({
        id: 'pregnant',
        label: 'Reject',
        description: 'Pregnant. Use "Pregnancy" message',
      });
    }
    if (breastfeeding === 'yes') {
      decisionSupport.push({
        id: 'breastfeeding',
        label: 'Reject',
        description: 'Breastfeeding. Use "Breastfeeding" message',
      });
    }
  }
  return {};
}: Guide);

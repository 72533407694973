import type { Guide } from '../../../types';

export default (async args => {
  const {
    ask,
    significant,
    patient: { gender },
  } = args;

  /* AUDIT - Alcohol Use Disorders Identification Test
  Please note that the scoring system differs between male and female.
  */

  const frequency = await ask.choice({
    id: 'audit.frequency',
    label: 'Hur ofta dricker du alkohol?',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: '1 gång i månaden eller mer sällan' },
      { value: '2', label: '2–4 gånger i månaden' },
      { value: '3', label: '2–3 gånger i veckan' },
      { value: '4', label: '4 gånger/vecka eller mer' },
    ],
  });

  // TODO: Add image url to question - image needs to be uploaded to google storage
  const amount = await ask.choice({
    id: 'audit.amount',
    label:
      'Hur många ”glas” (se exempel) dricker du en typisk dag då du dricker alkohol?',
    // description: '![alcohol](ADD URL)',
    options: [
      { value: '0', label: '1–2' },
      { value: '1', label: '3–4' },
      { value: '2', label: '5–6' },
      { value: '3', label: '7–9' },
      { value: '4', label: '10 eller fler' },
    ],
  });

  const amountFrequency = await ask.choice({
    id: 'audit.amountFrequency',
    label:
      'Hur ofta dricker du sex sådana ”glas” eller mer vid samma tillfälle?',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: 'Mer sällan än en gång i månaden' },
      { value: '2', label: 'Varje månad' },
      { value: '3', label: 'Varje vecka' },
      { value: '4', label: 'Dagligen eller nästan varje dag' },
    ],
  });

  const cantStopDrinking = await ask.choice({
    id: 'audit.cantStopDrinking',
    label:
      'Hur ofta under det senaste året har du inte kunnat sluta dricka sedan du börjat?',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: 'Mer sällan än en gång i månaden' },
      { value: '2', label: 'Varje månad' },
      { value: '3', label: 'Varje vecka' },
      { value: '4', label: 'Dagligen eller nästan varje dag' },
    ],
  });

  const avoidActivities = await ask.choice({
    id: 'audit.avoidActivities',
    label:
      'Hur ofta under det senaste året har du låtit bli att göra något som du borde för att du drack?',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: 'Mer sällan än en gång i månaden' },
      { value: '2', label: 'Varje månad' },
      { value: '3', label: 'Varje vecka' },
      { value: '4', label: 'Dagligen eller nästan varje dag' },
    ],
  });

  const hairOfTheDog = await ask.choice({
    id: 'audit.hairOfTheDog',
    label:
      'Hur ofta under senaste året har du behövt en ”drink” på morgonen efter mycket drickande dagen innan?',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: 'Mer sällan än en gång i månaden' },
      { value: '2', label: 'Varje månad' },
      { value: '3', label: 'Varje vecka' },
      { value: '4', label: 'Dagligen eller nästan varje dag' },
    ],
  });

  const guilt = await ask.choice({
    id: 'audit.guilt',
    label:
      'Hur ofta under det senaste året har du haft skuldkänslor eller samvetsförebråelser på grund av ditt drickande?',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: 'Mer sällan än en gång i månaden' },
      { value: '2', label: 'Varje månad' },
      { value: '3', label: 'Varje vecka' },
      { value: '4', label: 'Dagligen eller nästan varje dag' },
    ],
  });

  const memoryLoss = await ask.choice({
    id: 'audit.memoryLoss',
    label:
      'Hur ofta under det senaste året har du druckit så att du dagen efter inte kommit ihåg vad du sagt eller gjort?',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: 'Mer sällan än en gång i månaden' },
      { value: '2', label: 'Varje månad' },
      { value: '3', label: 'Varje vecka' },
      { value: '4', label: 'Dagligen eller nästan varje dag' },
    ],
  });

  const hurt = await ask.choice({
    id: 'audit.hurt',
    label: 'Har du eller någon annan blivit skadad på grund av ditt drickande?',
    options: [
      { value: '0', label: 'Nej' },
      { value: '2', label: 'Ja, men inte under det senaste året' },
      { value: '4', label: 'Ja, under det senaste året' },
    ],
  });

  const worriedRelatives = await ask.choice({
    id: 'audit.worriedRelatives',
    label:
      'Har en släkting eller vän, en läkare (eller någon annan inom sjukvården) oroat sig över ditt drickande eller antytt att du borde minska på det?',
    options: [
      { value: '0', label: 'Nej' },
      { value: '2', label: 'Ja, men inte under det senaste året' },
      { value: '4', label: 'Ja, under det senaste året' },
    ],
  });

  // Put all answers into an array
  const auditArray = [
    frequency,
    amount,
    amountFrequency,
    cantStopDrinking,
    avoidActivities,
    hairOfTheDog,
    guilt,
    memoryLoss,
    hurt,
    worriedRelatives,
  ];

  // Transform array values from string to integer values and then calculate the sum
  // of all values in array, stored in the variable auditScore
  const auditScore = auditArray
    .map(value => parseInt(value))
    .reduce((total, value) => total + value);

  // Depending on gender and score, push different information to significant (Clinic view)
  if (gender === 'male') {
    if (auditScore < 8) {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore,
      });
    } else if (auditScore < 16) {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore + ' - riskbruk',
      });
    } else if (auditScore < 20) {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore + ' - missbruk',
      });
    } else {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore + ' - beroende',
      });
    }
  } else if (gender === 'female') {
    if (auditScore < 6) {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore,
      });
    } else if (auditScore < 14) {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore + ' - riskbruk',
      });
    } else if (auditScore < 18) {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore + ' - missbruk',
      });
    } else {
      significant.push({
        type: 'text',
        label: 'AUDIT score',
        value: auditScore + ' - beroende',
      });
    }
  }

  return {};
}: Guide);

import type { Guide } from '../../types';

import aborts from './aborts';
import chestPain from './chestPain';
import stomachPain from './stomachPain';
// import vomiting from './vomiting';

export default (async args => {
  const { ask, significant } = args;

  await ask.info('We are now going to ask about your symptoms...');

  const symptomsQuestion = {
    type: 'multipleChoice',
    options: {
      stomachPain: 'Stomach pain or discomfort',
      heartburnOrAcidReflux: 'Heartburn or acid reflux',
      regurgitation: 'Regurgitation (bringing up acid or food)',
      nausea: 'Nausea',
      // vomiting: 'Vomiting',
      chestPain: 'Chest Pain',
    },
  };
  const symptoms = await ask({
    ...symptomsQuestion,
    id: 'symptoms',
    label: 'What are your symptoms?',
    description: 'More than one answer can be selected',
    optional: true,
  });

  // TODO: properly fix section headline
  significant.push({
    type: 'text',
    value: 'History of presenting complaint'.toUpperCase(),
  });

  significant.push({
    ...symptomsQuestion,
    label: 'Presenting complaint',
    value: symptoms,
    warn: values =>
      !values.length ||
      // values.includes('vomiting') ||
      values.includes('chestPain'),
  });
  if (!symptoms.length) {
    return { abort: aborts.notIndigestion };
  }

  if (symptoms.includes('stomachPain')) {
    const { abort: stomachPainAbort } = await stomachPain(args);
    if (stomachPainAbort) {
      return { abort: stomachPainAbort };
    }
  }

  if (symptoms.includes('chestPain')) {
    const { abort: chestPainAbort } = await chestPain(args);
    if (chestPainAbort) {
      return { abort: chestPainAbort };
    }
  }

  // if (symptoms.includes('vomiting')) {
  //   const { abort: vomitingAbort } = await vomiting(args);
  //   if (vomitingAbort) {
  //     return { abort: vomitingAbort };
  //   }
  // }

  const vomitedWithinLastWeek = await ask({
    label: 'Have you vomited within the last 7 days?',
  });
  if (vomitedWithinLastWeek === 'yes') {
    return { abort: aborts.vomiting };
  }
  return {};
}: Guide);

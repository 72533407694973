import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';
import createExtendedText from '../../common/extendedText';
import { ensureArray, matchOne } from '../../common/arrayUtils';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const haveAllergies = await eChoice({
    id: 'moduleAllergies.haveAllergies',
    label: 'Do you have any allergies?',
    description: 'E.g. Medication, food, animals, plants.',
    clinicLabel: 'Allergies',
    expires: 365,
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
      },
    ],
  });

  if (haveAllergies === 'yes') {
    const allergicTo = await eChoice({
      id: 'moduleAllergies.allergicTo',
      label: 'What are you allergic to?',
      description: 'More than one answer can be selected.',
      clinicLabel: 'Allergies',
      expires: 365,
      isMultipleChoice: true,
      options: [
        {
          label: 'Penicillin',
          value: 'penicillin',
          keyFinding: 'Penicillin allergy',
          warn: true,
        },
        {
          label: 'Other medications',
          value: 'otherMeds',
          hide: true,
        },
        {
          label: 'Lactose',
          value: 'lactose',
          hide: true,
        },
        {
          label: 'Gluten',
          value: 'gluten',
          hide: true,
        },
        {
          label: 'Nuts',
          value: 'nuts',
        },
        {
          label: 'Pollen / Hayfever',
          value: 'pollen',
          hide: true,
        },
        {
          label: 'Animals',
          value: 'animals',
          hide: true,
        },
        {
          label: 'House dust',
          value: 'dust',
          hide: true,
        },
        {
          label: 'Other',
          value: 'other',
          hide: true,
        },
      ],
    });

    if (matchOne(ensureArray(allergicTo), ['lactose'])) {
      await eChoice({
        id: 'moduleAllergies.lactoseAccept',
        description:
          'Many medications contain a small amount of lactose, this is unlikely to cause you any problems unless your lactose allergy is severe. \n' +
          '**Do you accept responsibility for checking if any medications we prescribe contain lactose and the effects this may have?**',
        // description: 'What are you allergic to?',
        options: [
          {
            label: 'Yes, I accept',
            value: 'accept',
            hide: true,
          },
        ],
      });
    }
    if (matchOne(ensureArray(allergicTo), ['otherMeds'])) {
      await eText({
        id: 'moduleAllergies.otherMeds',
        label: 'Please tell us what other medications you are allergic to.',
        clinicLabel: 'Medication allergies',
        expires: 365,
        warn: true,
      });
    }
    if (matchOne(ensureArray(allergicTo), ['other'])) {
      await eText({
        id: 'moduleAllergies.other',
        label: 'Please tell us what other allergies you have.',
        clinicLabel: 'Other allergies',
        expires: 365,
        warn: true,
      });
    }
  }

  return {};
}: Guide);

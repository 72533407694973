export default {
  postpartum: {
    description: `
Unfortunately, we cannot treat you online if you have had a baby in the past 6 weeks. 

This is because at this time in your life, it's best if you see a GP face to face and are examined.

**Please book an appointment with a GP**.

If your GP surgery is closed and you **need immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  majorSurgery: {
    description: `
Unfortunately, we cannot treat you online.

**When you have had surgery in the past four weeks, it's best if you see a GP face to face**. This is because you may need to be examined.

**Please book an appointment with a GP**.

If your GP surgery is closed and you **need immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  emergency: {
    description: `
**You need emergency care — this is because I am worried that your condition is severe.**

Please **go to A&E or call 999 immediately.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  sepsis: {
    description: `
Unfortunately, we cannot treat you online. 

**A cough with any of these symptoms may mean that you are seriously unwell and need emergency care:**

- **a new rash**
- **abdominal pain**
- **diarrhoea**
- **vomiting**
- **severe muscle pain**
- **a stiff neck**

Please **go to A&E or call 999 immediately**. Thank you for using Docly. We hope you feel better soon.
    `,
  },
  coughGreaterThan3Weeks: {
    description: `
Unfortunately, we cannot treat you online.

**When you have had a cough that has lasted more than three weeks, it's best if you see a GP face to face**. This is because you may need to **be examined**.

**Please book an appointment with a GP**.

If your GP surgery is closed and you **need immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  unsuitableForOnlineCare: {
    description: `
Unfortunately, we cannot treat you online. 

**It's best if you see a GP face to face. This is because you may need to be examined or have some blood tests.**

**Please book an appointment with a GP**.
 
If your GP surgery is closed and you **need immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
};

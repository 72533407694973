import * as React from 'react';

import colors from '../theme/colors';

import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

class Link extends React.Component<{
  href?: string,
  onClick?: () => *,
  children: React.Node,
  classes: { [string]: string },
  className?: string,
}> {
  onClick = (event: *) => {
    event.preventDefault();

    const { href, onClick } = this.props;

    onClick && onClick();

    if (href !== undefined) {
      window.location = href;
    }
  };

  render() {
    const { href, children, classes, className } = this.props;

    return (
      <Typography
        component="a"
        href={href}
        onClick={this.onClick}
        classes={classes}
        className={className}
      >
        {children}
      </Typography>
    );
  }
}

export default withStyles(() => ({
  root: {
    display: 'inline',
    color: colors.red_500,
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))(Link);

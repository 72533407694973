import type { Guide } from '../../types';

export default (async args => {
  const {
    ask,
    patient: { age },
  } = args;

  let understand;
  if (age >= 10) {
    understand = await ask.choice({
      id: 'childconcent.understand',
      description:
        'Is your child able to understand and make a balanced decision about treatments recommended by their doctor?',
      options: { yes: 'Yes', no: 'No' },
    });

    if (understand === 'yes') {
      await ask.choice({
        id: 'childconcent.understandYes',
        description: `
When using Docly, you must:

1. Ask your child to agree to you seeking healthcare on their behalf. 
2. Have your child ready to take part if needed.
3. Explain any advice or treatments to your child, and check that they understand and agree.
4. Message Docly with any questions or concerns that your child has.`,
        options: { yes: 'I understand' },
      });
    }
  }

  if (understand === 'no' || age < 10) {
    await ask.choice({
      id: 'childconcent.understandYes',
      description: `
Please try your best to:
- explain any advice or treatments recommended by Docly to your child
- include your child in decisions about their health
`,
      options: { yes: 'I understand' },
    });
  }
  return {};
}: Guide);

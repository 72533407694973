import transforms from './transforms';
import {
  type Node,
  type Question,
  type Evaluator,
  type Option,
  type Max,
} from './types';
import { isQuestion, htmlToMd } from './utils';

// Hard coded for SE as prescription warnings isn't currently relevant for UK.
const getDisplayValue = (
  type: string,
  options?: Option[],
  unit?: string,
  max?: Max,
  value: *
): ?(string | string[]) => {
  if (type === 'info' || type === 'comment') {
    return undefined;
  }

  if (
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && !value.length)
  ) {
    return 'Obesvarad';
  }

  switch (type) {
    case 'binary':
      return {
        yes: 'Ja',
        no: 'Nej',
      }[value];

    case 'choice': {
      const option = options && options.find(option => option.value === value);
      return option ? String(option.label) : value;
    }

    case 'multipleChoice':
      return value.map(v => {
        const option = options && options.find(option => option.value === v);
        return option ? option.label : v;
      });

    case 'number':
      return `${value} ${unit || ''}`;

    case 'range':
      return `${Math.round(value * 10) / 10}/${
        max !== undefined ? max.value : 10
      }`;

    case 'tertiary':
      return {
        yes: 'Ja',
        no: 'Nej',
        unknown: 'Vet ej',
      }[value];

    default:
      return String(value);
  }
};

export const getPrescriptionWarnings = (
  view: Node,
  evaluator: Evaluator,
  questions: Question[],
  variables: Node[],
  answers: { [string]: * }
): string[] => {
  const warnings = [];

  const process = node => {
    const { id, type, condition, children } = node;

    if (condition !== undefined && !evaluator(condition)) {
      return;
    }

    if (id !== undefined) {
      let question: ?Question;

      if (type === 'question') {
        question = questions.find(q => q.id === id);
      } else if (type === 'variable') {
        // $FlowFixMe
        question = variables.find(v => v.id === id);
      } else if (isQuestion(node)) {
        // $FlowFixMe
        question = node;
      }

      if (!question) {
        return;
      }

      let value = answers[id];

      if (type === 'variable') {
        value = transforms(question)(evaluator(question.expression));
      }

      const { label, description, expression, template } = node;

      if (expression !== undefined) {
        value = transforms(node)(evaluator(expression));
        if (value === undefined) return;
      }

      if (template !== undefined) {
        value = transforms(node)(evaluator(template));
        if (value === undefined) return;
      }

      if (!['comment', 'info'].includes(type)) {
        if (['upload', 'multipleChoice']) {
          if (value === undefined || value.length === 0) {
            return;
          }
        } else if (value === undefined) {
          return;
        }
      }

      const options =
        node.options &&
        node.options.filter(
          ({ condition }) => condition === undefined || !!evaluator(condition)
        );
      const unit = node.unit;
      const max = node.max;
      const displayValue = getDisplayValue(
        question.type,
        options,
        unit,
        max,
        value
      );

      const exists = v => v !== '' && v !== null && v !== undefined;

      const out = [];
      if (exists(label)) out.push(label);
      if (exists(description)) out.push(htmlToMd(description));
      if (exists(displayValue)) {
        out.push(
          (Array.isArray(displayValue) ? displayValue : [displayValue])
            .map(v => `**${String(v)}**`)
            .join('  \n')
        );
      }

      warnings.push(out.join('  \n'));
    } else if (children) {
      children.forEach(process);
    }
  };

  process(view);

  return warnings;
};

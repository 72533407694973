import * as React from 'react';

import { setLocale, localeByCountry } from '../../i18n';

export const withLocaleByCountry = () => {
  return (Component: *) => {
    return (props: *) => {
      setLocale(localeByCountry[props && props.country] || localeByCountry.uk);

      return <Component {...props} />;
    };
  };
};

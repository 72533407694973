import type { Export } from '../../types';

// Add all possible options here in alphabetical order.
// Don't duplicate if they are almost the same

export const diagnosisOpts = {
  anaemia: 'Anaemia',
  bloodResultsAbnormal: 'Blood results abnormal',
  bloodResultsNormal: 'Blood results normal',
  covid19Excluded: 'Covid-19 excluded by laboratory testing',
  covid19Confirmed: 'Covid-19 confirmed by laboratory testing',
  covid19Inconclusive: 'Covid-19 testing inconclusive',
  diabetes: 'Diabetes',
  other: 'Other',
  preDiabetes: 'Pre-diabetes',
  raisedCholesterol: 'Raised cholesterol',
  vitaminDDeficiency: 'Vitamin D deficiency',
};

export const investigationsRequestedOpts = {
  none: 'None',
  covid19Pcr: 'Covid-19 RT-PCR Test (nasal swab)',
};

export const prescribedOpts = {
  none: 'None',
  medication: 'Prescribed medication',
};

export const outcomeOpts = {
  adviceGiven: 'Advice given',
  investigationsRequested: 'Investigations requested',
  medicationAndAdvice: 'Medication prescribed and advice provided',
  routineGpAppt: 'Routine GP appointment',
  selfCare: 'Self-care',
  urgentGpAppt: 'Urgent GP appointment',
};

export const actionNeededOpts = {
  none: 'None',
};

export type JournalData = {
  presentingComplaint: string,
  snomedCode: string,
  diagnosis?: string,
  diagnosisOptions?: string[],
  investigationsRequested?: string,
  investigationsRequestedOptions?: string[],
  prescribed?: string,
  prescribedOptions?: string[],
  outcome?: string,
  outcomeOptions?: string[],
  actionNeeded?: string,
  actionNeededOptions?: string[],
};

const validateValue = (
  value: string,
  optionsMap: {
    [name: string]: string,
  }
) => {
  if (!Object.values(optionsMap).includes(value)) {
    throw new Error(
      `The value ${value} is not present in the list of possible values. We are maintaining this list in one place to reduce duplicates that are almost but not exactly the same.`
    );
  }
};

const validateOptions = (
  values: string[],
  optionsMap: {
    [name: string]: string,
  }
) => values.forEach(value => validateValue(value, optionsMap));

export default (
  exports: Export[],
  {
    presentingComplaint,
    snomedCode,
    diagnosis,
    diagnosisOptions,
    investigationsRequested,
    investigationsRequestedOptions,
    prescribed,
    prescribedOptions,
    outcome,
    outcomeOptions,
    actionNeeded,
    actionNeededOptions,
  }: JournalData
) => {
  exports.push({ id: 'presentingComplaint', value: presentingComplaint });
  exports.push({ id: 'snomedCode', value: snomedCode });
  if (diagnosis !== undefined) {
    validateValue(diagnosis, diagnosisOpts);
    exports.push({ id: 'diagnosis', value: diagnosis });
  }
  if (diagnosisOptions !== undefined) {
    validateOptions(diagnosisOptions, diagnosisOpts);
    exports.push({ id: 'diagnosis.options', value: diagnosisOptions });
  }
  if (investigationsRequested !== undefined) {
    validateValue(investigationsRequested, investigationsRequestedOpts);
    exports.push({
      id: 'investigationsRequested',
      value: investigationsRequested,
    });
  }
  if (investigationsRequestedOptions !== undefined) {
    validateOptions(
      investigationsRequestedOptions,
      investigationsRequestedOpts
    );
    exports.push({
      id: 'investigationsRequested.options',
      value: investigationsRequestedOptions,
    });
  }
  if (prescribed !== undefined) {
    validateValue(prescribed, prescribedOpts);
    exports.push({ id: 'prescribed', value: prescribed });
  }
  if (prescribedOptions !== undefined) {
    validateOptions(prescribedOptions, prescribedOpts);
    exports.push({ id: 'prescribed.options', value: prescribedOptions });
  }
  if (outcome !== undefined) {
    validateValue(outcome, outcomeOpts);
    exports.push({ id: 'outcome', value: outcome });
  }
  if (outcomeOptions !== undefined) {
    validateOptions(outcomeOptions, outcomeOpts);
    exports.push({ id: 'outcome.options', value: outcomeOptions });
  }
  if (actionNeeded !== undefined) {
    validateValue(actionNeeded, actionNeededOpts);
    exports.push({ id: 'actionNeeded', value: actionNeeded });
  }
  if (actionNeededOptions !== undefined) {
    validateOptions(actionNeededOptions, actionNeededOpts);
    exports.push({ id: 'actionNeeded.options', value: actionNeededOptions });
  }
};

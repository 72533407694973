export default {
  se: {
    mentalHealth: {
      'mentalHealth.screen': 'no',
    },
  },
  uk: {
    acidReflux: {
      'triage.pregnant': 'no',
      'triage.breastfeeding': 'no',
      'triage.immuneCondition': 'no',
      'triage.conditions': [],
      'triage.stomachCancer': 'no',
      'triage.severeStomachOrChestPain': 'no',
      'triage.severeHeartburnOrReflux': 'no',
      'triage.jaundice': 'no',
      'triage.vomitedBlood': 'no',
    },
    contraception: {
      'triage.type': 'pillPathOrRing',
      'triage.pregnant': 'no',
      'triage.breastfeeding': 'no',
      'triage.recentBaby': 'no',
      'triage.vaginalBleeding': 'no',
      'triage.breastCancer': 'no',
      'triage.recentSexWithoutProtection': 'no',
      'triage.reason': ['stopMeGettingPregnant'],
      'chc.highBloodPressure': 'no',
      'chc.migrainesWithAura': 'no',
      'chc.majorSurgery': 'no',
      'chc.mobility': 'none',
      'chc.gallstones': 'no',
      'chc.sickeCell': 'no',
      'chc.bloodClot': 'no',
      'chc.bloodClotFamily': 'no',
      'chc.heartAttackFamily': 'no',
      'height.unit': 'metric',
      'weight.unit': 'metric',
    },
    cough: {
      'triage.pregnant': 'no',
      'triage.breastfeeding': 'no',
      'triage.recentBaby': 'no',
      'triage.majorSurgery': 'no',
    },
    prescriptionRenewal: {
      'triage.pregnant': 'no',
      'triage.breastfeeding': 'no',
    },
    tonsillitis: {
      'triage.pregnant': 'no',
      'triage.breastfeeding': 'no',
      'triage.conditions': [],
    },
  },
};

export default {
  currentSymptoms: {
    id: 'currentSymptoms',
    label: 'Current Symptoms',
    color: 'black',
    description: '',
  },
  symptomsResolved: {
    id: 'symptomsResolved',
    label: 'Symptoms Resolved',
    color: 'black',
    description: '',
  },
  testAndTraceNeededSymptomatic: {
    id: 'testAndTraceNeededSymptomatic',
    label: 'Covid Swab Test',
    color: 'red',
    description: 'Inform patient to use NHS test and trace',
  },
  testAndTraceNeededCovidContact: {
    id: 'testAndTraceNeededCovidContact',
    label: 'Covid contact',
    color: 'red',
    description: 'Inform patient to use NHS test and trace',
  },
};

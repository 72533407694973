import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  await eText({
    id: 'coughChild.medicationtriedforcough',
    label: 'Which medication did they try?',
    clinicLabel: 'Medication tried for cough',
  });

  await eChoice({
    id: 'coughChild.didmedicationhelpthecough',
    label: "Did it help your child's cough?",
    clinicLabel: 'Did medication help the cough',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Medication helped cough' },
      { label: 'No', value: 'no', keyFinding: 'Medication didnt help cough' },
    ],
  });
  return {};
}: Guide);

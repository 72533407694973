import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import ds from './decisionSupport';

export const keyFindingLabelSputumYellowGreen = 'Yellow/green sputum';
export const keyFindingLabelSputumBrown = 'Brown Sputum';

export default (async args => {
  const { decisionSupport } = args;
  const eChoice = createExtendedChoice(args);

  const colourofsputum = await eChoice({
    id: 'coughChild.colourofsputum',
    label: 'What does the phlegm or mucus look like?',
    clinicLabel: 'Sputum appearance',
    options: [
      {
        label: 'Clear or white',
        value: 'Clearorwhite',
        keyFinding: 'Clear/white sputum',
      },
      {
        label: 'Yellow or green',
        value: 'Yelloworgreen',
        keyFinding: keyFindingLabelSputumYellowGreen,
        warn: true,
      },
      {
        label: 'Brown',
        value: 'Brown',
        keyFinding: keyFindingLabelSputumBrown,
        warn: true,
      },
      {
        label: 'Blood or blood stained',
        value: 'Bloodorbloodstained',
        keyFinding: 'Blood or blood stained',
        warn: true,
        urgent: true,
      },
    ],
  });

  if (colourofsputum === 'Blood or blood stained') {
    decisionSupport.push(ds.haemoptysis);
  }

  return {};
}: Guide);

import { type Abort } from '../types';

import {
  type Abort as FormularyAbort,
  type Node,
  type Evaluator,
} from './types';
import { htmlToMd } from './utils';

export const getAbort = (
  formularyAbort?: FormularyAbort,
  variables: Node[],
  evaluator: Evaluator
): ?Abort => {
  if (formularyAbort) {
    if (evaluator(formularyAbort.condition)) {
      return {
        description: htmlToMd(formularyAbort.description),
      };
    }
  }

  for (let i = 0; i < variables.length; i++) {
    const { id, type, expression } = variables[i];
    if (type === 'abort' && evaluator(expression)) {
      return {
        id,
        label: id,
      };
    }
  }

  return undefined;
};

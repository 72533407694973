import type { GuideWithOut } from '../../types';

import ds from './decisionSupport';
import { maxExpiry } from '../common/consts';

export default (async args => {
  const { ask, decisionSupport, significant } = args;

  const repeat = await ask(
    'Are you asking for a medication that is on your normal repeat prescription list?',
    {
      id: 'repeat.match',
      description:
        'We will ask you about medications that are not on your repeat prescription later.',
    }
  );
  const want = repeat === 'yes';
  let needReview = false;
  if (want) {
    // TODO: a bit of a special hack to display a header. Be warned!
    significant.push({
      type: 'text',
      value: 'Repeat medication'.toUpperCase(),
    });

    const wantUpload = await ask.choice(
      'Do you want to upload a photo of your repeat prescription, with the medication that you need marked?',
      {
        id: 'repeat.wantUpload',
        options: {
          yes: 'Yes, I will upload a photo',
          no: 'No, I will type my medication list',
        },
      }
    );
    if (wantUpload === 'yes') {
      const photos = await ask.upload(
        'Upload one or more photos of your repeat prescription. Make sure you mark the medication that you need.',
        {
          id: 'repeat.photos',
          description: `
The medication you want must be clearly marked, and your name, address and date of birth must be visible.
          `,
        }
      );
      significant.push({
        type: 'upload',
        label: 'Photos of repeat prescriptions',
        value: photos,
      });
    } else {
      const medications = await ask.text(
        'List the medication you need, including strength and dose.',
        {
          id: 'repeat.text',
          description:
            'E.g. Levothyroxine 100 micrograms, 1 tablet once daily.',
          expires: maxExpiry,
        }
      );
      significant.push({
        type: 'text',
        label: 'Repeat medications needed',
        value: medications,
      });
    }

    const early = await ask(
      'Are you asking for any of your medications earlier than normal?',
      { id: 'repeat.early' }
    );
    if (early === 'yes') {
      const options = {
        holiday: `I'm going on holiday`,
        lost: 'I lost my medication',
        used: 'I ran out because I needed to use more than expected',
        sync: 'I want to get all my repeat medications in sync',
        other: 'Other',
      };
      const why = await ask.choice(
        'Please tell us why are you asking for medications earlier than normal?',
        { id: 'repeat.early.why', options }
      );
      if (why === 'holiday') {
        decisionSupport.push(ds.early);
      } else if (why === 'lost') {
        decisionSupport.push(ds.lost);
      } else if (why === 'used') {
        decisionSupport.push(ds.ranOut);
      } else if (why === 'other') {
        decisionSupport.push(ds.early);
      }

      if (why === 'other') {
        significant.push({
          type: 'text',
          label: 'Reason for early request of repeat prescription',
          value: await ask.text('Please give details'),
          warn: true,
        });
      } else {
        significant.push({
          type: 'choice',
          label: 'Reason for early request of repeat prescription',
          options,
          value: why,
          warn: ['lost', 'used'],
        });
      }
    }

    const overdue = await ask.tertiary(
      'Is a medication review overdue for any of the medications you are asking for?',
      {
        id: 'repeat.review.overdue',
        description: `Don't worry if it is, in most cases we can do this online.`,
      }
    );
    if (overdue === 'yes') {
      needReview = true;
    } else if (overdue === 'unknown') {
      if (
        (await ask('Do you have a copy of the repeat order slip?', {
          id: 'repeat.review.slip',
        })) === 'no'
      ) {
        needReview = true;
      } else {
        const options = {
          withinMonth: 'Within the next month',
          '1To12Months': '1-12 months',
          overdue: 'It is overdue',
          notFound: `I can't see a review date`,
        };
        const date = await ask.choice({
          id: 'repeat.review.due',
          description: `
# The review date can usually be found at the bottom of the repeat order slip.

# If you can see it, when is your medication review due?
          `,
          options,
        });
        if (date !== 'notFound') {
          significant.push({
            type: 'choice',
            label: 'When is medication review due?',
            options,
            value: date,
          });
        }

        if (date !== '1To12Months') {
          needReview = true;
        }
      }
    }
  }

  return { decisionSupport, significant, want, needReview };
}: GuideWithOut<{ want: boolean, needReview: boolean }>);

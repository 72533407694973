import type { Guide } from '../../types';

import healthProfile from '../partials/healthProfile';

import pharmacy from '../modules/pharmacy';

import informedConsent from '../questions/informedConsent';

import ds from './decisionSupport';
import exam from './exam';
import hcp from './hcp';
import heartburn from './heartburn';
import ice from './ice';
import symptoms from './symptoms';
import triage from './triage';
import twoWeekWait from './twoWeekWait';

export default (async args => {
  const {
    ask,
    patient: { age },
    decisionSupport,
  } = args;

  if (age >= 55) {
    decisionSupport.push(ds.over55);
  }

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  const { abort: symptomsAbort } = await symptoms(args);
  if (symptomsAbort) {
    return { abort: symptomsAbort };
  }

  // TODO: add vomited

  await heartburn(args);

  await hcp(args);

  const { abort: examAbort } = await exam(args);
  if (examAbort) {
    return { abort: examAbort };
  }

  const { score: twoWeekWaitScore } = await twoWeekWait(args);

  const { concernedAboutCancer } = await ice(args);

  if (twoWeekWaitScore === 0 && concernedAboutCancer) {
    decisionSupport.push(ds.concernedAboutCancer);
  }
  // TODO: can we change parts of the healthProfile partials
  await healthProfile(args);

  await pharmacy(args);

  await ask(informedConsent);

  return {};
}: Guide);

export const questionTypes = {
  binary: true,
  choice: true,
  info: true,
  multipleChoice: true,
  number: true,
  range: true,
  tertiary: true,
  text: true,
  upload: true,
};

export type Condition = boolean | number | string | string[] | ((*) => *);

export type Limit = {
  value: number,
  label?: string,
};

export type RawOption = {
  value: string | number,
  label?: string,
};

export type RawQuestion = $Exact<{
  id?: string,
  type?: $Keys<typeof questionTypes>,
  label?: string,
  description?: string,
  min?: number | Limit,
  max?: number | Limit,
  unit?: string,
  warn?: Condition,
  options?: (string | void | RawOption)[] | { [string]: string },
  optional?: boolean,
  expires?: number,
  mimeTypes?: string, // for the file upload, ex: 'image/*,video/*'
}>;

export type Option = {
  value: string | number,
  label: string,
};

export type Question = $Exact<{
  id?: string,
  ref: string,
  type: $Keys<typeof questionTypes>,
  label?: string,
  description?: string,
  min?: Limit,
  max?: Limit,
  unit?: string,
  warn?: Condition,
  options?: Option[],
  optional?: boolean,
  expires?: number,
  mimeTypes?: string, // for the file upload, ex: 'image/*,video/*'
}>;

export type SimpleAnswer = {
  ref: string,
  value: *,
};

export type RawAnswer = $Exact<{
  id?: string,
  type?: $Keys<typeof questionTypes>,
  label?: string,
  description?: string,
  min?: number | Limit,
  max?: number | Limit,
  unit?: string,
  warn?: Condition,
  options?: (string | void | RawOption)[] | { [string]: string },
  expires?: number,
  optional?: boolean,
  mimeTypes?: string, // for the file upload, ex: 'image/*,video/*'
  value: *,
  mimeTypes?: string, // for the file upload, ex: 'image/*,video/*'
}>;

export type Answer = $Exact<{
  id?: string,
  ref: string,
  type: $Keys<typeof questionTypes>,
  label?: string,
  description?: string,
  min?: Limit,
  max?: Limit,
  unit?: string,
  warn?: boolean,
  options?: Option[],
  expires?: number,
  optional?: boolean,
  mimeTypes?: string, // for the file upload, ex: 'image/*,video/*'
  value: *,
}>;

type AskFunction<T> = (
  label: string | RawQuestion,
  question?: RawQuestion
) => Promise<T>;

export type Ask = {
  (label: string | RawQuestion, question?: RawQuestion): Promise<*>,
  binary: AskFunction<'yes' | 'no'>,
  choice: AskFunction<string>,
  info: AskFunction<'yes'>,
  multipleChoice: AskFunction<string[]>,
  number: AskFunction<number>,
  range: AskFunction<number>,
  tertiary: AskFunction<'yes' | 'no' | 'unknown'>,
  text: AskFunction<string>,
  upload: AskFunction<(string | number)[]>,
};

export type Patient = $Exact<{
  gender: 'male' | 'female',
  age: number,
  domain: string,
}>;

export type DecisionSupport = $Exact<{
  id?: string,
  label?: string,
  description?: string,
  color?: 'black' | 'red' | 'yellow' | 'green',
  priority?: number,
}>;

type ExportType<K, V> = {
  id: K,
  value: V,
};

export type Export =
  | ExportType<'presentingComplaint', string>
  | ExportType<'snomedCode', string>
  | ExportType<'diagnosis', string>
  | ExportType<'diagnosis.options', string[]>
  | ExportType<'keyFindings', string>
  | ExportType<'investigationsRequested', string>
  | ExportType<'investigationsRequested.options', string[]>
  | ExportType<'prescribed', string>
  | ExportType<'prescribed.options', string[]>
  | ExportType<'outcome', string>
  | ExportType<'outcome.options', string[]>
  | ExportType<'actionNeeded', string>
  | ExportType<'actionNeeded.options', string[]>
  | ExportType<'prescriptionWarnings', string[]>
  | ExportType<'urgent', boolean>;

export type Scores = {
  scores: { [string]: number },
  get: string => number,
  set: (string, number) => void,
  increase: (string, ?number) => void,
  decrease: (string, ?number) => void,
  getAll: () => { [string]: number },
};

export type Abort = $Exact<{
  id?: string,
  label?: string,
  description?: string,
}>;

type Arguments = {
  ask: Ask,
  patient: Patient,
  significant: RawAnswer[],
  decisionSupport: DecisionSupport[],
  exports: Export[],
  scores: Scores,
};

type ArgumentsWithIn<X> = X & Arguments;

export type Result = $Exact<{
  answers: Answer[],
  significant: Answer[],
  decisionSupport: DecisionSupport[],
  exports: Export[],
  abort?: Abort,
  scores: Scores,
}>;

type Return = { abort?: Abort };

type ReturnWithOut<Y> = Y & Return;

export type GuideWithInAndOut<X, Y> = (
  ArgumentsWithIn<X>
) => Promise<ReturnWithOut<Y>>;

export type GuideWithIn<X> = (ArgumentsWithIn<X>) => Promise<$Exact<Return>>;

export type GuideWithOut<Y> = ($Exact<Arguments>) => Promise<ReturnWithOut<Y>>;

export type Guide = ($Exact<Arguments>) => Promise<$Exact<Return>>;

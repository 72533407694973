import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { _t } from '../../../i18n';

import { randomizeDraft } from '../../../state/drafts/actions';

import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

import Key from '../../components/Key';

class RandomizeButton extends React.Component<{
  guideId: string,
  randomizeDraft: typeof randomizeDraft,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { guideId, randomizeDraft, classes, className } = this.props;

    const fn = () => randomizeDraft(guideId);

    return (
      <Button
        variant="outlined"
        onClick={fn}
        className={classNames(classes.root, className)}
      >
        {_t('guides.random')}

        <Key character="R" onPress={fn} className={classes.key} />
      </Button>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {},

  key: {
    marginLeft: spacing(),
  },
}))(
  connect(
    undefined,
    { randomizeDraft }
  )(RandomizeButton)
);

import * as React from 'react';
import classNames from 'classnames';

import { _t } from '../../../i18n';

import colors from '../../theme/colors';

import groupDecisionSupport from '../../../common/groupDecisionSupport';

import { type DecisionSupport as DecisionSupportType } from '../../../guides/types';

import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import withStyles from '@material-ui/core/styles/withStyles';

import { AnswerMarkdown } from './Answer';

const decisionSupportColors = {
  red: colors.red_500,
  yellow: colors.orange_500,
  green: colors.green_500,
  black: colors.off_black,
};

const Dot = withStyles(() => ({
  root: {
    height: 16,
    width: 16,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: colors.off_black,
    borderRadius: 99,
  },
}))(({ color, classes, className }) => (
  <div
    className={classNames(classes.root, className)}
    style={{ backgroundColor: decisionSupportColors[color] }}
  />
));

const DecisionSupportItem = withStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },

  bold: { fontWeight: 500 },

  dot: {
    marginRight: 10,
  },

  body: {
    flex: 1,
  },
}))(({ label, description, color, classes }) => (
  <div className={classes.root}>
    <Dot color={color} className={classes.dot} />
    <div className={classes.body}>
      {!!label && <Typography className={classes.bold}>{label}</Typography>}
      {!!description && <AnswerMarkdown>{description}</AnswerMarkdown>}
    </div>
  </div>
));

class DecisionSupport extends React.Component<
  {
    decisionSupport?: DecisionSupportType[],
    classes: { [string]: string },
  },
  {
    expanded: boolean,
  }
> {
  state = {
    expanded: false,
  };

  toggleExpanded = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const { decisionSupport = [], classes } = this.props;
    const { expanded } = this.state;

    const { important, extra } = groupDecisionSupport(decisionSupport);

    return (
      <div className={classes.root}>
        {important.map(({ label, description, color }) => (
          <DecisionSupportItem
            key={`${label || ''}${description || ''}`}
            label={label}
            description={description}
            color={color}
          />
        ))}
        {!!extra.length && (
          <>
            <Button className={classes.button} onClick={this.toggleExpanded}>
              <div className={classes.dots}>
                {Array.from(
                  new Set(extra.map(item => item.color || 'black'))
                ).map(color => (
                  <Dot key={color} color={color} className={classes.dot} />
                ))}
              </div>
              {_t('guides.decisionSupport.more')}
              <ExpandMoreIcon
                className={classes.expansionIcon}
                style={{
                  transform: expanded ? 'rotate(180deg)' : undefined,
                }}
              />
            </Button>

            <ExpansionPanel
              square
              expanded={!!expanded}
              className={classes.expansionPanel}
            >
              <ExpansionPanelSummary
                className={classes.expansionPanelSummary}
              />
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                {extra.map(({ label, description, color }) => (
                  <DecisionSupportItem
                    key={`${label || ''}${description || ''}`}
                    label={label}
                    description={description}
                    color={color}
                  />
                ))}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    backgroundColor: colors.yellow_warning,
    margin: `${spacing(3)}px -2000px ${spacing(3)}px -2000px`,
    padding: `${spacing(3)}px 2000px ${spacing(3)}px 2000px`,
    width: '100%',
    [down('xs')]: {
      margin: `${spacing(1.5)}px -${spacing(3)}px ${spacing(3)}px -${spacing(
        3
      )}px`,
      padding: spacing(3),
    },
  },

  title: {
    marginBottom: spacing(),
  },

  button: {
    paddingLeft: spacing(1.5),
    paddingRight: spacing(1.5),
    marginLeft: spacing(-1.5),
  },

  dots: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: spacing(),
  },

  dot: {
    marginRight: spacing(0.5),
    '&:last-child': {
      marginRight: 0,
    },
  },

  expansionPanel: {
    padding: 0,
    margin: 0,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:before': {
      backgroundColor: 'transparent',
    },
  },

  expansionPanelSummary: {
    display: 'none',
  },

  expansionPanelDetails: {
    display: 'block',
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
  },

  expansionIcon: {
    marginLeft: spacing(),
  },
}))(DecisionSupport);

import { type RawAnswer } from '../types';

import transforms from './transforms';
import { type Node, type Question, type Evaluator } from './types';
import { toGuideQuestion, isQuestion, htmlToMd } from './utils';

export const getSignificant = (
  view: Node,
  evaluator: Evaluator,
  questions: Question[],
  variables: Node[],
  answers: { [string]: * }
): RawAnswer[] => {
  const significant = [];

  const process = node => {
    const { id, type, condition, children } = node;

    if (condition !== undefined && !evaluator(condition)) {
      return;
    }

    if (id !== undefined) {
      let question: ?Question;

      if (type === 'question') {
        question = questions.find(q => q.id === id);
      } else if (type === 'variable') {
        // $FlowFixMe
        question = variables.find(v => v.id === id);
      } else if (isQuestion(node)) {
        // $FlowFixMe
        question = node;
      }

      if (!question) {
        return;
      }

      let value = answers[id];

      if (type === 'variable') {
        value = transforms(question)(evaluator(question.expression));
      }

      const { label, description, warning, expression, template } = node;

      if (expression !== undefined) {
        value = transforms(node)(evaluator(expression));
        if (value === undefined) return;
      }

      if (template !== undefined) {
        value = transforms(node)(evaluator(template));
        if (value === undefined) return;
      }

      const warn = warning !== undefined && !!evaluator(warning);

      if (!['comment', 'info'].includes(type)) {
        if (['upload', 'multipleChoice']) {
          if (value === undefined || value.length === 0) {
            return;
          }
        } else if (value === undefined) {
          return;
        }
      }

      significant.push({
        ...toGuideQuestion(
          question,
          node.options &&
            node.options.filter(
              ({ condition }) =>
                condition === undefined || !!evaluator(condition)
            )
        ),
        type: type === 'comment' ? 'info' : question.type,
        label,
        description: htmlToMd(description),
        warn,
        value,
      });
    } else if (children) {
      children.forEach(process);
    }
  };

  process(view);

  return significant;
};

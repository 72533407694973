import type { Guide } from '../../types';

import aborts from './aborts';

export default (async ({ ask, patient: { gender, age }, significant }) => {
  await ask.info({
    description:
      'Before we begin, we need to ask a few questions to make sure it is safe to treat you online...',
  });

  if (gender === 'female' && age < 55) {
    const pregnant = await ask('Are you pregnant?', {
      id: 'triage.pregnant',
    });
    if (pregnant === 'yes') {
      significant.push({
        label: 'Pregnant',
        value: pregnant,
        warn: true,
      });
      return { abort: aborts.pregnantOrBreastfeeding };
    }

    const breastfeeding = await ask('Are you breastfeeding?', {
      id: 'triage.breastfeeding',
    });
    if (breastfeeding === 'yes') {
      significant.push({
        label: 'Breastfeeding',
        value: breastfeeding,
        warn: true,
      });
      return { abort: aborts.pregnantOrBreastfeeding };
    }
  }

  const conditionsQuestion = {
    id: 'triage.conditions',
    type: 'multipleChoice',
    options: {
      takingMedication:
        'I am taking medication that affects my immune system, e.g. methotrexate, oral steroids',
      chemotherapy: `I'm having chemotherapy`,
      cancer: `I have cancer`,
      immuneCondition:
        'I have a condition that affects my immune system, e.g. dialysis, HIV, other immune conditions',
    },
    optional: true,
  };
  const conditions = await ask({
    ...conditionsQuestion,
    label:
      'Do you have a condition, or are you taking medication, that affects your immune system?',
    description: 'More than one answer can be selected.',
  });
  if (conditions.length > 0) {
    significant.push({
      ...conditionsQuestion,
      label: 'Immunosuppression',
      value: conditions,
      warn: true,
    });
    return { abort: aborts.immunosuppressed };
  }

  return {};
}: Guide);

// @flow
import type { DecisionSupport } from '../guides/types';

const colorPriority = {
  default: 1,
  black: 1,
  red: 2,
  yellow: 3,
  green: 4,
};

function groupBy(items: DecisionSupport[], criteria: string) {
  return items.reduce(function(result, item) {
    const key = item[criteria];
    if (!result.hasOwnProperty(key)) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
}

const mergeItemsByLabel = (allItems: DecisionSupport[]): DecisionSupport[] => {
  const groupedItems = groupBy(allItems, 'label');
  const result = [];
  for (const grp in groupedItems) {
    const items = [...groupedItems[grp]];
    // Note: just a shallow copy, needs to change if we add
    // any "deep" properties (Arrays or Objects) to the DecisionSupport type
    const item = { ...items[0] };

    // convert description to unordered lists if more than one item
    if (items.length > 1) {
      item.description = items
        .map(({ description }) => `- ${description}`)
        .join('\n');
    }
    result.push(item);
  }
  return result;
};

const compareDecisionSupport = (a: DecisionSupport, b: DecisionSupport) => {
  const colorDiff =
    ((a.color !== undefined && colorPriority[a.color]) ||
      colorPriority.default) -
    ((b.color !== undefined && colorPriority[b.color]) ||
      colorPriority.default);

  if (colorDiff !== 0) {
    return colorDiff;
  }

  return (a.priority || 0) - (b.priority || 0);
};

export default (
  allItems: DecisionSupport[]
): {
  important: DecisionSupport[],
  extra: DecisionSupport[],
} => {
  // merge items with the same label + sort them by priority:
  const items = mergeItemsByLabel(allItems).sort(compareDecisionSupport);

  const firstRedOrYellowIndex = items.findIndex(
    ({ color }) => color === 'red' || color === 'yellow'
  );
  const importantCount =
    firstRedOrYellowIndex !== -1 ? firstRedOrYellowIndex + 1 : items.length;

  return {
    important: items.slice(0, importantCount),
    extra: items.slice(importantCount),
  };
};

import createExtendedChoice from '../common/extendedChoice';
import createExtendedMultipleChoiceWithOther from '../common/extendedMultipleChoiceWithOther';
import createExtendedText from '../common/extendedText';

import ds from './decisionSupport';
import type { GuideWithIn } from '../../types';
import { ensureArray } from '../common/arrayUtils';

export default (async args => {
  const { actScore } = args;
  const eChoice = createExtendedChoice(args);
  const eChoiceWithOther = createExtendedMultipleChoiceWithOther(args);
  const eText = createExtendedText(args);

  const prescribedPreventer = await eChoice({
    id: 'asthmaReview.medication.prescribedPreventer',
    label: `Have you been prescribed a preventer inhaler (steroid or combination)?`,
    description:
      'This is an inhaler you have been prescribed to use regularly even if your symptoms are well controlled. ',
    clinicLabel: 'Patient prescribed preventer',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        ds: actScore < 25 ? ds.medicationConsiderPrescribingInhaler : undefined,
      },
    ],
  });

  if (prescribedPreventer === 'yes') {
    await eText({
      id: 'asthmaReview.medication.whichPreventer',
      label: `Which preventer inhaler do you use?`,
      description:
        'Please include the name, dose, and how often you use the inhaler',
      clinicLabel: 'Name of preventer inhaler',
    });

    await eChoice({
      id: 'asthmaReview.medication.usingASpacerDevice',
      label: `Do you use an aerochamber or spacer device?`,
      description:
        'This is a tube that allows you to use the inhaler more effectively.',
      clinicLabel: 'Using a spacer device',
      options: [
        {
          value: 'yes',
        },
        {
          value: 'no',
          ds:
            actScore < 25
              ? ds.medicationConsiderPrescribingAerochamber
              : undefined,
        },
      ],
    });

    const preventerUsedRegularly = await eChoice({
      id: 'asthmaReview.medication.preventerUsedRegularly',
      label: `Do you use your preventer inhaler regularly?`,
      clinicLabel: 'Compliant with preventer',
      options: [
        {
          value: 'yes',
          keyFinding: 'Compliant with preventer inhaler',
        },
        {
          value: 'no',
          keyFinding: 'Not compliant with preventer inhaler',
          ds:
            actScore < 25 ? ds.medicationNotUsingPreventerRegularly : undefined,
        },
      ],
    });

    if (preventerUsedRegularly === 'no') {
      const reasonPreventerNotUsedRegularly = ensureArray(
        await eChoiceWithOther({
          multipleChoiceQuestions: {
            id: 'asthmaReview.medication.reasonPreventerNotUsedRegularly',
            label: 'Why do you not use your preventer inhaler regularly?',
            description: 'More than one can be selected',
            options: [
              { label: "I don't need it", value: 'dontNeed' },
              { label: 'I get side effects', value: 'sideEffects' },
              { label: 'I forget to use it', value: 'forget' },
              { label: "I don't know how to use it", value: 'dontKnowHow' },
              { label: 'Other', value: 'other' },
            ],
          },
          otherQuestion: {
            otherValue: 'other',
            id: 'asthmaReview.medication.reasonPreventerNotUsedRegularlyOther',
            label:
              'What is the other reason you do not take your preventer inhaler regularly?',
          },
          clinicLabel: 'Why not compliant with preventer',
          optional: true,
        })
      );

      if (reasonPreventerNotUsedRegularly.includes('sideEffects')) {
        await eText({
          id: 'asthmaReview.medication.preventerSideEffects',
          label: 'What side effects do you get from your inhaler?',
          clinicLabel: 'Side effects from preventer',
        });

        await eChoice({
          id: 'asthmaReview.medication.preventerSideEffectsContinue',
          label: `If there is no better option to help your asthma would you consider continuing despite the side effects?`,
          clinicLabel: 'Would continue preventer despite side effects',
          options: [
            {
              value: 'yes',
            },
            {
              value: 'no',
            },
          ],
        });
      }
    }
  }

  await eChoice({
    id: 'asthmaReview.medication.preventerUse',
    description: `**9 out of 10 people do not use their inhalers properly, so it is important that you are using your inhaler correctly. We will need a clinician to check your inhaler technique.**`,
    options: [
      {
        label: 'I understand',
        value: 'understand',
        hide: true,
      },
    ],
  });

  const otherMedication = await eChoice({
    id: 'asthmaReview.medication.otherMedication',
    label: `Do you use any other medication for your asthma?`,
    clinicLabel: 'Other asthma medication used',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
      },
    ],
  });

  if (otherMedication === 'yes') {
    await eText({
      id: 'asthmaReview.medication.otherMedicationDescription',
      label: 'Which other asthma medication do you use?',
      description:
        'Please include the name, dose, and how often you use the medication',
      clinicLabel: 'Other asthma medication used',
    });

    const otherMedicationUsedRegularly = await eChoice({
      id: 'asthmaReview.medication.otherMedicationUsedRegularly',
      label: `Do you use your other asthma medication regularly?`,
      clinicLabel: 'Compliant with other asthma medication',
      options: [
        {
          value: 'yes',
        },
        {
          value: 'no',
        },
      ],
    });

    if (otherMedicationUsedRegularly === 'no') {
      const whyOtherMedicationNotUsedRegularly = ensureArray(
        await eChoiceWithOther({
          multipleChoiceQuestions: {
            id: 'asthmaReview.medication.whyOtherMedicationNotUsedRegularly',
            label: 'Why do you not use your other asthma medication regularly?',
            description: 'More than one can be selected',
            options: [
              {
                label: "I don't need it",
                value: 'dontNeed',
              },
              {
                label: 'I get side effects',
                value: 'sideAffects',
              },
              {
                label: 'I forget to use it',
                value: 'forget',
              },
              {
                label: "I don't know how to use it",
                value: 'dontKnowHow',
              },
              {
                label: 'Other',
                value: 'other',
              },
            ],
          },
          otherQuestion: {
            otherValue: 'other',
            id:
              'asthmaReview.medication.whyOtherMedicationNotUsedRegularlyOther',
            label:
              'What is the other reason you do not take your other asthma medication regularly?',
          },
          clinicLabel: 'Why not compliant with other asthma medication',
        })
      );

      if (whyOtherMedicationNotUsedRegularly.includes('sideAffects')) {
        await eText({
          id:
            'asthmaReview.medication.whyOtherMedicationNotUsedRegularlySideAffects',
          label:
            'What side effects do you get from your other asthma medication?',
          clinicLabel: 'Side effects of other asthma medication',
        });

        await eChoice({
          id:
            'asthmaReview.medication.whyOtherMedicationNotUsedRegularlySideAffectsContinue',
          label: `If there is no better option to help your asthma would you consider continuing despite the side effects?`,
          clinicLabel:
            'Would continue other asthma medication despite side effects',
          options: [
            {
              value: 'yes',
            },
            {
              value: 'no',
            },
          ],
        });
      }
    }
  }

  return {};
}: GuideWithIn<{
  actScore: number,
}>);

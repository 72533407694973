import { type DecisionSupport } from '../../types';

const decisionSupport: { [string]: DecisionSupport } = {
  over55: {
    color: 'black',
    label: 'Age 55',
    description:
      'Use Referral for urgent blood test if no FBC within last 6 weeks.',
  },
  previousStomachBacteria: {
    color: 'black',
    label: 'Previous H Pylori',
    description: 'Review previous treatments',
  },
  previousStomachUlcer: {
    color: 'black',
    label: 'Previous stomach ulcer',
    description: 'Review previous treatments and triggers',
  },
  previousOesophagitis: {
    color: 'black',
    label: 'Previous oesophagitis',
    description: 'Review previous treatments and triggers',
  },
  medicationTriggers: {
    color: 'black',
    label: 'Medication triggers',
    description:
      'Review medication, consider reducing or stopping (if possible) drugs that may cause or exacerbate symptoms',
  },
  noPreviousDyspepsiaMedication: {
    color: 'black',
    label: 'No previous dyspepsia medication',
    description: 'Confirm no previous treatments',
  },
  previousOsteoporosis: {
    color: 'black',
    label: 'Previous osteoporosis',
    description: 'Check osteoporosis treatment',
  },
  onClopidogrel: {
    color: 'black',
    label: 'Patient on clopidogrel',
    description:
      'If PPI needed ask patient to make routine GP appointment to discuss treatment options',
  },
  onDigoxin: {
    color: 'black',
    label: 'Patient on digoxin',
    description:
      'If PPI needed ask patient to make routine GP appointment to discuss treatment options',
  },
  onWarfarin: {
    color: 'black',
    label: 'Patient on warfarin',
    description:
      'If PPI needed ask patient to make routine GP appointment to discuss treatment options',
  },

  twoWeekWait: {
    color: 'red',
    label: 'Reject Urgent GP appointment',
    description: '2WW Red Flag',
  },
  tiredness: {
    color: 'red',
    label: 'Reject Urgent GP appointment',
    description: 'Tiredness',
  },
  weightLoss: {
    color: 'red',
    label: 'Reject Urgent GP appointment',
    description: 'Weight loss',
  },
  paleStools: {
    color: 'red',
    label: 'Arrange routine appointment',
    description: 'Pale stools',
  },
  blackStoolsTakingIron: {
    color: 'red',
    label: 'Arrange routine appointment',
    description: 'Black stools taking iron',
  },
  decreasedAppetite: {
    color: 'red',
    label: 'Reject Urgent GP appointment',
    description: 'Decrease Appetite',
  },
  earlySatiety: {
    color: 'red',
    label: 'Reject Urgent GP appointment',
    description: 'Early Satiety',
  },
  bloating: {
    color: 'red',
    label: 'Arrange routine appointment',
    description: 'Bloating',
  },
  concernedAboutCancer: {
    color: 'black',
    label: 'Patient concerned about cancer',
    description: 'Use Cancer Concern Template',
  },
};

Object.keys(decisionSupport).forEach(id => (decisionSupport[id].id = id));

export default decisionSupport;

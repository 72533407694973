export default {
  id: 'acne',
  version: '5-uk',
  type: 'formulary',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          version: '34-acne',
          type: 'choice',
          options: [
            {
              value: 'lessThanMonth',
              label: 'Less than a month',
            },
            {
              value: 'oneToTwelveMonths',
              label: '1–12 months',
            },
            {
              value: 'moreThanTwelveMonths',
              label: 'More than 12 months',
            },
          ],
          validations: {
            required: true,
          },
          label: 'How long have you had acne?',
          index: '0.0.0',
        },
        {
          id: 'acne.where',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'face',
              label: 'Face',
            },
            {
              value: 'neck',
              label: 'Neck',
            },
            {
              value: 'chest',
              label: 'Chest',
            },
            {
              value: 'back',
              label: 'Back',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Where is your acne?',
          index: '0.0.1',
        },
        {
          id: 'acne.whereOther',
          version: '1',
          type: 'text',
          condition: "contains(get('answers.acne.where'), 'other')",
          validations: {
            required: true,
          },
          label: 'Please tell us where your acne is located.',
          index: '0.0.2',
        },
        {
          id: 'acne.describe',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'smallBumps',
              label: 'Small bumps',
            },
            {
              value: 'redSpots',
              label: 'Inflamed red spots',
            },
            {
              value: 'whiteheads',
              label: 'Whiteheads',
            },
            {
              value: 'blackheads',
              label: 'Blackheads',
            },
            {
              value: 'tenderLumps',
              label: 'Deep tender lumps under the skin',
            },
          ],
          validations: {
            required: true,
          },
          label: 'How would you describe your acne?',
          description: 'More than one answer can be selected.',
          index: '0.0.3',
        },
        {
          id: 'acne.scars.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Do your spots leave scars when they heal?',
          index: '0.0.4',
        },
        {
          id: 'acne.causingAnxiety.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Do you think your acne might be causing you anxiety or depression that affects your day to day life?',
          index: '0.0.5',
        },
        {
          id: 'acne.causingAnxiety.describe',
          version: '1',
          type: 'text',
          condition: "get('answers.acne.causingAnxiety.match') === 'yes'",
          validations: {
            required: true,
          },
          label: "Please tell us how it's impacting your life.",
          index: '0.0.6',
        },
        {
          id: 'acne.causingAnxiety.impact',
          version: '1',
          type: 'range',
          min: {
            value: 0,
            label: 'Not at all',
          },
          max: {
            value: 10,
            label: 'Very high impact',
          },
          extras: {
            vas: true,
            invert: false,
          },
          condition: "get('answers.acne.causingAnxiety.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'How much is your acne impacting on your life?',
          index: '0.0.7',
        },
        {
          id: 'acne.current.treatment.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Are you using any treatment for your acne at the moment?',
          index: '0.0.8',
        },
        {
          id: 'acne.current.treatment.what',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'gelCream',
              label: 'Gel or cream',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotic tablets',
            },
            {
              value: 'contraception',
              label: 'Contraceptive pill',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.acne.current.treatment.match') === 'yes'",
          label: 'What treatment are you using?',
          description: 'More than one answer can be selected.',
          index: '0.0.9',
        },
        {
          id: 'acne.current.treatment.whatGelCream',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.acne.current.treatment.what'), 'gelCream')",
          validations: {
            required: true,
          },
          label: 'What gel or cream are you using?',
          index: '0.0.10',
        },
        {
          id: 'acne.current.treatment.whatAntibiotics',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.acne.current.treatment.what'), 'antibiotics')",
          validations: {
            required: true,
          },
          label: 'What antibiotics are you taking?',
          index: '0.0.11',
        },
        {
          id: 'medication.antibiotics.current.duration',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'lessThanMonth',
              label: 'Less than a month',
            },
            {
              value: 'oneToThreeMonths',
              label: '1–3 months',
            },
            {
              value: 'threeToSixMonths',
              label: '3–6 months',
            },
            {
              value: 'sixToTwelveMonths',
              label: '6–12 months',
            },
            {
              value: 'moreThanTwelveMonths',
              label: 'More than 12 months',
            },
          ],
          condition:
            "contains(get('answers.acne.current.treatment.what'), 'antibiotics')",
          validations: {
            required: true,
          },
          label: 'How long have you been taking antiboitics without a break?',
          index: '0.0.12',
        },
        {
          id: 'medication.antibiotics.current.durationOther',
          version: '1',
          type: 'text',
          condition:
            "get('answers.medication.antibiotics.current.duration') === 'moreThanTwelveMonths'",
          validations: {
            required: true,
          },
          label: 'How long have you been taking antibiotics continuously?',
          index: '0.0.13',
        },
        {
          id: 'acne.current.treatment.whatOther',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.acne.current.treatment.what'), 'other')",
          validations: {
            required: true,
          },
          label:
            'Please tell us what other treatments you are using for your acne.',
          index: '0.0.14',
        },
        {
          id: 'health.bloodTest.sixMonths.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'In the last 6 months, have you had a blood test to check your blood count, liver and kidney function?',
          index: '0.0.15',
        },
        {
          id: 'health.bloodTest.sixMonths.resultsNormal',
          version: '1',
          type: 'tertiary',
          condition:
            "get('answers.health.bloodTest.sixMonths.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Were your results normal?',
          index: '0.0.16',
        },
        {
          id: 'acne.current.treatment.successful',
          version: '1',
          type: 'binary',
          condition: "get('answers.acne.current.treatment.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Is the treatment working?',
          index: '0.0.17',
        },
        {
          id: 'acne.current.treatment.wantToContinue',
          version: '1',
          type: 'binary',
          condition: "get('answers.acne.current.treatment.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Do you want to continue this treatment?',
          index: '0.0.18',
        },
        {
          id: 'acne.previous.treatment.match',
          version: '2',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Have you had any treatment for your acne in the past?',
          index: '0.0.19',
        },
        {
          id: 'acne.previous.treatment.what',
          version: '3',
          type: 'multipleChoice',
          options: [
            {
              value: 'gelCream',
              label: 'Gel or cream',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotic tablets',
            },
            {
              value: 'contraception',
              label: 'Contraceptive pill',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.acne.previous.treatment.match') === 'yes'",
          label: 'Please tell us what treatment you previously had.',
          index: '0.0.20',
        },
        {
          id: 'acne.previous.treatment.other',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.acne.previous.treatment.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Please tell us what other treatment you previously had.',
          index: '0.0.21',
        },
        {
          id: 'acne.previous.treatment.successful',
          version: '1',
          type: 'binary',
          condition: "get('answers.acne.previous.treatment.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Did the treatment work?',
          index: '0.0.22',
        },
        {
          id: 'acne.current.treatment.preference',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'gelCream',
              label: 'Gel or cream',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotic tablets',
            },
            {
              value: 'noPreference',
              label: 'No preference',
            },
          ],
          condition:
            "get('answers.acne.current.treatment.match') === 'no' || get('answers.acne.current.treatment.wantToContinue') === 'no'",
          validations: {
            required: true,
          },
          label:
            'Is there a particular kind of treatment you want for your acne?',
          index: '0.0.23',
        },
        {
          id: 'acne.current.treatment.canAcceptContraception',
          version: '1',
          type: 'binary',
          condition: "get('answers.predefined.gender') === 'female'",
          validations: {
            required: true,
          },
          label:
            'The contraceptive pill can be a very good treatment for acne. Is this an option you would consider?',
          index: '0.0.24',
        },
        {
          id: 'acne.otherSkinCondition.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Do you have any other skin conditions?',
          description:
            'E.g. eczema, perioral dermatitis, rosacea or a photosensitive rash.',
          index: '0.0.25',
        },
        {
          id: 'acne.otherSkinCondition.what',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'eczema',
              label: 'Eczema',
            },
            {
              value: 'periodalDermatitis',
              label: 'Perioral dermatitis',
            },
            {
              value: 'rosacea',
              label: 'Rosacea',
            },
            {
              value: 'lightSensitiveRash',
              label: 'Photosensitive (light sensitive) rash',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.acne.otherSkinCondition.match') === 'yes'",
          label: 'What other skin conditions do you have?',
          description: 'More than one answer can be selected.',
          index: '0.0.26',
        },
        {
          id: 'acne.otherSkinCondition.whatOther',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.acne.otherSkinCondition.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'What other skin conditions do you have?',
          index: '0.0.27',
        },
        {
          id: 'acne.otherSkinCondition.where',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'faceNeck',
              label: 'Face or neck',
            },
            {
              value: 'scalp',
              label: 'Scalp',
            },
            {
              value: 'chestStomachBackButtocks',
              label: 'Chest, stomach, back or buttocks',
            },
            {
              value: 'armsHandsLegsFeet',
              label: 'Arms, hands, legs or feet',
            },
            {
              value: 'genitalsAnal',
              label: 'Genitals or anal region',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.acne.otherSkinCondition.match') === 'yes'",
          label: 'Which parts of your body does it affect?',
          description: 'More than one can be selected.',
          index: '0.0.28',
        },
      ],
      index: '0.0',
    },
    {
      type: 'section',
      condition:
        "get('answers.acne.current.treatment.canAcceptContraception') === 'yes'",
      children: [
        {
          id: 'anticonception.toldNotToTake.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            "Have you ever been told that you shouldn't take the contraceptive pill?",
          index: '0.1.0',
        },
        {
          id: 'anticonception.toldNotToTake.why',
          version: '1',
          type: 'text',
          condition:
            "get('answers.anticonception.toldNotToTake.match') === 'yes'",
          validations: {
            required: true,
          },
          label:
            "Why were you told that you shouldn't use the contraceptive pill?",
          index: '0.1.1',
        },
        {
          id: 'anticonception.usingPill.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Are you currently taking the contraceptive pill?',
          index: '0.1.2',
        },
        {
          id: 'anticonception.usingPill.what',
          version: '1',
          type: 'text',
          condition: "get('answers.anticonception.usingPill.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Which one are you taking?',
          index: '0.1.3',
        },
        {
          id: 'reproduction.recentlyInLabor',
          version: '4',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Have you had a baby in the last 6 weeks?',
          index: '0.1.4',
        },
        {
          id: 'reproduction.bleedingBetweenPeriods.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Do you get any bleeding between periods or after sex?',
          index: '0.1.5',
        },
        {
          id: 'reproduction.bleedingBetweenPeriods.doctor.match',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.reproduction.bleedingBetweenPeriods.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Have you seen a doctor about this?',
          index: '0.1.6',
        },
        {
          id: 'reproduction.bleedingBetweenPeriods.doctor.diagnosis',
          version: '1',
          type: 'text',
          condition:
            "get('answers.reproduction.bleedingBetweenPeriods.doctor.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'What did the doctor say?',
          index: '0.1.7',
        },
        {
          id: 'health.breastCancerRisk.match',
          version: '2',
          type: 'choice',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
            {
              value: 'unsure',
              label: 'I am unsure',
            },
          ],
          validations: {
            required: true,
          },
          label:
            'Have you been told that you are at high risk of breast cancer, or that you carry the breast cancer gene?',
          index: '0.1.8',
        },
        {
          id: 'health.bloodPressureHigh.duringPregnancy.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Have you had high blood pressure while pregnant, even if your blood pressure is normal now?',
          index: '0.1.9',
        },
        {
          id: 'health.migraineWithAura',
          version: '3',
          type: 'tertiary',
          validations: {
            required: true,
          },
          label:
            'Have you ever suffered from severe headaches or migraines with aura?',
          description:
            'E.g. visual symptoms, numbness, tingling, speech difficulties, weakness of limbs or face.',
          index: '0.1.10',
        },
        {
          id: 'health.migraineSymptoms.other',
          version: '1',
          type: 'text',
          condition: "get('answers.health.migraineWithAura') === 'unknown'",
          validations: {
            required: true,
          },
          label: 'Please describe the symptoms you get with your migraines',
          index: '0.1.11',
        },
        {
          id: 'health.operation.recentOrSoon.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Have you had major surgery in the past 3 months, or are you about to have major surgery?',
          index: '0.1.12',
        },
        {
          id: 'health.operation.recentOrSoon.what',
          version: '1',
          type: 'text',
          condition:
            "get('answers.health.operation.recentOrSoon.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Please tell us what surgery you have had or plan to have.',
          index: '0.1.13',
        },
        {
          id: 'health.mobility.problems.what',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'none',
              label: 'None',
            },
            {
              value: 'walkingAid',
              label: 'Walking aid needed',
            },
            {
              value: 'wheelchair',
              label: 'Wheelchair user',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Do you have any problems with your mobility?',
          index: '0.1.14',
        },
        {
          id: 'health.mobility.problems.other',
          version: '1',
          type: 'text',
          condition:
            "any(get('answers.health.mobility.problems.what'), 'walkingAid', 'other')",
          validations: {
            required: true,
          },
          label: 'Please describe your mobility problem.',
          index: '0.1.15',
        },
        {
          id: 'health.sickleCellDisease.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Do you have sickle cell disease?',
          description: 'Not sickle cell trait.',
          index: '0.1.16',
        },
        {
          id: 'health.clotRiskHigh.match',
          version: '2',
          type: 'choice',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'yesHaveHad',
              label: 'Yes - I have had a blood clot before',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
          validations: {
            required: true,
          },
          label:
            'Do you have a condition that increases the risk of blood clots, or have you had them previously?',
          index: '0.1.17',
        },
        {
          id: 'health.clotRelatives',
          version: '3',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Has anyone in your biological family ever had a blood clot in their lungs or legs?',
          index: '0.1.18',
        },
        {
          id: 'health.clotRelativesWho',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'parent',
              label: 'Parent',
            },
            {
              value: 'brotherOrSister',
              label: 'Brother or sister',
            },
            {
              value: 'sonOrDaughter',
              label: 'Son or daughter',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.health.clotRelatives') === 'yes'",
          label: 'Who had the blood clot?',
          description: 'More than one answer can be selected.',
          index: '0.1.19',
        },
        {
          id: 'health.clotRelativesWhoOther',
          version: '2',
          type: 'text',
          condition:
            "contains(get('answers.health.clotRelativesWho'), 'other')",
          validations: {
            required: true,
          },
          label: 'Who else had the blood clot?',
          index: '0.1.20',
        },
        {
          id: 'health.relativesCardiovascular',
          version: '2-under55',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Has anyone in your immediate biological family had a heart attack or stroke under the age of 55?',
          index: '0.1.21',
        },
        {
          id: 'health.relativesCardiovascularWho',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'mumSister',
              label: 'A mum or sister',
            },
            {
              value: 'dadBrother',
              label: 'A dad or brother',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.health.relativesCardiovascular') === 'yes'",
          label: 'Who had the heart attack or stroke?',
          index: '0.1.22',
        },
        {
          id: 'health.relativesCardiovascularUnder45',
          version: '1',
          type: 'binary',
          condition:
            "contains(get('answers.health.relativesCardiovascularWho'), 'dadBrother')",
          validations: {
            required: true,
          },
          label:
            'Was your dad or brother under the age of 45 when this happened?',
          index: '0.1.23',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 55",
          validations: {
            required: true,
          },
          label: 'Are you pregnant?',
          index: '0.2.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 55",
          validations: {
            required: true,
          },
          label: 'Are you breastfeeding?',
          index: '0.2.1',
        },
        {
          id: 'acne.pictures',
          version: '1',
          type: 'upload',
          validations: {
            required: true,
            min: 2,
          },
          extras: {
            instructions: ['distance', 'lighting', 'closeup', 'selfie'],
          },
          label:
            'You need to upload photos of your acne. Attach one close-up and one overview picture.',
          description:
            '<p>\n  You can use a mobile phone camera to take the photo — you can ask someone to take the photo for you, or you can do it yourself by activating the selfie-mode.\n</p>\n<p>\n  Rest assured, your photos will be kept confidential and will only be added to your medical record.\n</p>',
          index: '0.2.2',
        },
        {
          id: 'unit.height',
          type: 'choice',
          version: '1',
          expires: 99999,
          options: [
            {
              value: 'metric',
              label: 'Centimeters',
            },
            {
              value: 'imperial',
              label: 'Feet and inches',
            },
          ],
          validations: {
            required: true,
          },
          label: 'How tall are you?',
          description: 'Please choose either cm or feet',
          index: '0.2.3',
        },
        {
          id: 'height',
          type: 'number',
          version: '2-uk',
          unit: 'cm',
          validations: {
            required: true,
            min: 50,
            max: 300,
          },
          expires: 99999,
          condition: "get('answers.unit.height') === 'metric'",
          label: 'How tall are you?',
          index: '0.2.4',
        },
        {
          id: 'heightImperial.feet',
          type: 'number',
          version: '2',
          expires: 99999,
          validations: {
            required: true,
            min: 1,
            max: 9,
          },
          condition: "get('answers.unit.height') === 'imperial'",
          label: 'How tall are you?',
          description:
            "It's essential that we receive the correct information regarding your height in feet and inches. For this reason, we ask you to enter your height over two screens. So if, for example, you are 5 foot 10 inches in height, you enter 5 on the first screen and 10 on the second screen.",
          unit: 'feet',
          index: '0.2.5',
        },
        {
          id: 'heightImperial.inches',
          type: 'number',
          version: '1',
          expires: 99999,
          validations: {
            required: true,
            min: 0,
            max: 11,
          },
          condition: "get('answers.unit.height') === 'imperial'",
          label: 'How tall are you?',
          unit: 'inches',
          index: '0.2.6',
        },
        {
          id: 'unit.weight',
          type: 'choice',
          version: '2',
          expires: 0,
          options: [
            {
              value: 'metric',
              label: 'Kilograms',
            },
            {
              value: 'imperial',
              label: 'Stones and pounds',
            },
          ],
          validations: {
            required: true,
          },
          label: 'How much do you weigh?',
          description: 'Please choose either kg or stones and pounds.',
          index: '0.2.7',
        },
        {
          id: 'weight',
          type: 'number',
          version: '2',
          unit: 'kg',
          validations: {
            required: true,
            min: 20,
            max: 700,
          },
          expires: 0,
          condition: "get('answers.unit.weight') === 'metric'",
          label: 'How much do you weigh?',
          index: '0.2.8',
        },
        {
          id: 'weightImperial.stones',
          type: 'number',
          version: '2',
          expires: 0,
          validations: {
            required: true,
            min: 2,
            max: 80,
          },
          condition: "get('answers.unit.weight') === 'imperial'",
          label: 'How much do you weigh?',
          description:
            "It's essential that we receive the correct information regarding your weight in stones and pounds. For this reason, we ask you to enter your weight over two screens. So if, for example, you weigh 10 stone 5 pounds, you enter 10 on the first screen and 5 on the second screen.",
          unit: 'stones',
          index: '0.2.9',
        },
        {
          id: 'weightImperial.pounds',
          type: 'number',
          version: '1',
          expires: 0,
          validations: {
            required: true,
            min: 0,
            max: 13,
          },
          condition: "get('answers.unit.weight') === 'imperial'",
          label: 'How much do you weigh?',
          unit: 'pounds',
          index: '0.2.10',
        },
        {
          id: 'smoking.match',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'lessThan10',
              label: 'Yes - less than 10 times a day',
            },
            {
              value: 'moreThan10',
              label: 'Yes - more than 10 times a day',
            },
            {
              value: 'sometimes',
              label: 'Sometimes',
            },
            {
              value: 'previously',
              label: 'Ex-smoker',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
          expires: 365,
          validations: {
            required: true,
          },
          label: 'Do you smoke?',
          description: 'E.g. cigarettes, roll-ups, vaping.',
          index: '0.2.11',
        },
        {
          id: 'smoking.stopped',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'lessThanYear',
              label: 'Less than a year ago',
            },
            {
              value: 'yearOrMore',
              label: 'A year ago or more',
            },
          ],
          expires: 365,
          condition: "get('answers.smoking.match') === 'previously'",
          validations: {
            required: true,
          },
          label: 'When did you stop smoking?',
          index: '0.2.12',
        },
        {
          id: 'drugs.recreational.any',
          version: '2',
          type: 'binary',
          expires: 365,
          validations: {
            required: true,
          },
          label: 'Have you used any recreational drugs in the past year?',
          description: 'E.g. Cannabis, Cocaine',
          index: '0.2.13',
        },
        {
          id: 'drugs.recreational.match',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'cannabis',
              label: 'Cannabis',
            },
            {
              value: 'cocaine',
              label: 'Cocaine',
            },
            {
              value: 'ecstasy',
              label: 'Ecstasy',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          expires: 365,
          validations: {
            required: true,
          },
          condition: "get('answers.drugs.recreational.any') === 'yes'",
          label: 'Please tell us which drugs you have used.',
          description: 'More than one answer can be selected.',
          index: '0.2.14',
        },
        {
          id: 'drugs.recreational.other',
          version: '1',
          type: 'text',
          expires: 365,
          condition:
            "contains(get('answers.drugs.recreational.match'), 'other')",
          validations: {
            required: true,
          },
          label: 'Please tell us which other drugs you have used.',
          index: '0.2.15',
        },
        {
          id: 'alcohol.amount.estimate',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'moreThan14',
              label: 'More than 14 units a week',
            },
            {
              value: '14OrLess',
              label: '14 units or less a week',
            },
            {
              value: 'none',
              label: "I don't drink alcohol",
            },
          ],
          expires: 365,
          validations: {
            required: true,
          },
          label: 'How many alcohol units do you drink in a week?',
          description:
            'Small glass of wine = 2 units, large glass of wine = 3 units, pint beer or cider = 2 units, pint of strong beer or cider = 3 units, shot of spirit = 1 unit.',
          index: '0.2.16',
        },
        {
          id: 'alcohol.amount.exact',
          type: 'text',
          version: '1',
          expires: 365,
          condition: "get('answers.alcohol.amount.estimate') === 'moreThan14'",
          validations: {
            required: true,
          },
          label: 'Please tell us how many units you drink a week.',
          description:
            'Small glass of wine = 2 units, large glass of wine = 3 units, pint beer or cider = 2 units, pint of strong beer or cider = 3 units, shot of spirit = 1 unit.',
          index: '0.2.17',
        },
        {
          id: 'health.issues.what',
          version: '2-uk',
          type: 'multipleChoice',
          options: [
            {
              value: 'asthma',
              label: 'Asthma',
            },
            {
              value: 'copdOrSmokersLungs',
              label: 'COPD or "smoker\'s lungs"',
            },
            {
              value: 'diabetes',
              label: 'Diabetes',
            },
            {
              value: 'bloodpreassure',
              label: 'High blood pressure',
            },
            {
              value: 'cardiovascular',
              label: 'Heart disease',
            },
            {
              value: 'strokeOrTia',
              label: 'Stroke or TIA (mini stroke)',
            },
            {
              value: 'clotsLegsOrLung',
              label: 'Blood clots to the legs or lung (DVT or PE)',
            },
            {
              value: 'cancer',
              label: 'Cancer',
            },
            {
              value: 'epilepsy',
              label: 'Epilepsy',
            },
            {
              value: 'kidney',
              label: 'Kidney disease',
            },
            {
              value: 'liver',
              label: 'Liver disease',
            },
            {
              value: 'blooddisease',
              label: 'Blood-borne virus (HIV, Hepatitis B, Hepatitis C)',
            },
            {
              value: 'other',
              label: 'Other medical condition',
            },
          ],
          expires: 365,
          validations: {
            required: false,
          },
          label:
            'What diseases or health issues do you currently have, or have you had previously?',
          description: 'More than one answer can be selected.',
          index: '0.2.18',
        },
        {
          id: 'health.cardiovascular.what',
          type: 'composite',
          version: '1-uk',
          children: [
            {
              id: 'health.cardiovascular.what.what',
              type: 'multipleChoice',
              options: [
                {
                  value: 'infarction',
                  label: 'Heart attack',
                },
                {
                  value: 'congestiveHeart',
                  label: 'Heart failure',
                },
                {
                  value: 'anginaPectoris',
                  label: 'Angina',
                },
                {
                  value: 'atrialFibrillation',
                  label: 'Atrial fibrillation',
                },
                {
                  value: 'heartValve',
                  label: 'Heart valve disorder',
                },
                {
                  value: 'cardiomyopathy',
                  label: 'Cardiomyopathy',
                },
                {
                  value: 'other',
                  label: 'Other',
                },
              ],
              validations: {
                required: true,
              },
              expires: 365,
              relativeId: 'what',
              version: '1-uk',
              label: 'What kind of heart disease have you been diagnosed with?',
              description: 'More than one answer can be selected.',
              index: '0.2.19.0',
            },
            {
              id: 'health.cardiovascular.what.other',
              type: 'text',
              condition:
                "contains(get('answers.health.cardiovascular.what.what'), 'other')",
              expires: 365,
              relativeId: 'other',
              version: '1-uk',
              validations: {
                required: true,
              },
              label:
                'Please tell us what other heart disease you have been diagnosed with?',
              index: '0.2.19.1',
            },
          ],
          condition:
            "contains(get('answers.health.issues.what'), 'cardiovascular')",
          extras: {},
          index: '0.2.19',
        },
        {
          id: 'health.diabetes.type',
          version: '1-uk',
          type: 'multipleChoice',
          options: [
            {
              value: 'type1',
              label: 'Diabetes type 1',
            },
            {
              value: 'type2',
              label: 'Diabetes type 2',
            },
            {
              value: 'preDiabetes',
              label: 'Pre-diabetes (diet controlled)',
            },
            {
              value: 'diabetesInPregnancy',
              label: 'Diabetes in pregnancy (gestational)',
              condition: "get('answers.predefined.gender') === 'female'",
            },
          ],
          expires: 365,
          validations: {
            required: true,
          },
          condition: "contains(get('answers.health.issues.what'), 'diabetes')",
          label: 'What type of diabetes do you have?',
          description: 'More than one answer can be selected.',
          index: '0.2.20',
        },
        {
          id: 'health.cancerWhat',
          type: 'text',
          expires: 365,
          version: '1-uk',
          condition: "contains(get('answers.health.issues.what'), 'cancer')",
          validations: {
            required: true,
          },
          label:
            'What type of cancer do you currently have, or have you had previously?',
          index: '0.2.21',
        },
        {
          id: 'health.cancerRemission',
          type: 'binary',
          expires: 365,
          version: '1',
          condition: "contains(get('answers.health.issues.what'), 'cancer')",
          validations: {
            required: true,
          },
          label: 'Is your cancer in remission?',
          description:
            'I.e. you have been given the all clear and no longer require any treatment.',
          index: '0.2.22',
        },
        {
          id: 'kidneys.diseaseType',
          type: 'text',
          version: '2',
          expires: 365,
          condition: "contains(get('answers.health.issues.what'), 'kidney')",
          validations: {
            required: true,
          },
          label:
            'What type of kidney disease do you currently have, or have you had previously?',
          index: '0.2.23',
        },
        {
          id: 'kidneys.treatment',
          type: 'text',
          version: '1',
          expires: 365,
          condition: "contains(get('answers.health.issues.what'), 'kidney')",
          validations: {
            required: true,
          },
          label: 'How has your kidney disease been treated?',
          index: '0.2.24',
        },
        {
          id: 'health.liverDiseases.what',
          version: '2',
          type: 'text',
          expires: 365,
          condition: "contains(get('answers.health.issues.what'), 'liver')",
          validations: {
            required: true,
          },
          label:
            'What type of liver disease do you currently have, or have you had previously?',
          index: '0.2.25',
        },
        {
          id: 'health.liverDiseases.treatment',
          version: '1',
          type: 'text',
          expires: 365,
          condition: "contains(get('answers.health.issues.what'), 'liver')",
          validations: {
            required: true,
          },
          label: 'How has you liver disease been treated?',
          index: '0.2.26',
        },
        {
          id: 'health.bloodDisease.what',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'hepB',
              label: 'Hepatitis B',
            },
            {
              value: 'hepC',
              label: 'Hepatitis C',
            },
            {
              value: 'hiv',
              label: 'HIV',
            },
          ],
          validations: {
            required: true,
          },
          expires: 365,
          condition:
            "contains(get('answers.health.issues.what'), 'blooddisease')",
          label:
            'What blood-borne virus do you have, or have you had previously?',
          description: 'More than one answer can be selected.',
          index: '0.2.27',
        },
        {
          id: 'health.issues.other',
          version: '1',
          type: 'text',
          expires: 365,
          condition: "contains(get('answers.health.issues.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Please list the other medical conditions you suffer from.',
          index: '0.2.28',
        },
        {
          id: 'health.operation.match',
          type: 'binary',
          version: '1-uk',
          expires: 365,
          validations: {
            required: true,
          },
          label: 'Have you undergone any major surgery?',
          index: '0.2.29',
        },
        {
          id: 'health.operation.what',
          type: 'text',
          version: '1-uk',
          expires: 365,
          condition: "get('answers.health.operation.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'What type of major surgery was it?',
          index: '0.2.30',
        },
        {
          id: 'medication.any',
          type: 'binary',
          version: '1',
          expires: 180,
          condition: "get('answers.predefined.gender') !== 'female'",
          validations: {
            required: true,
          },
          label:
            'Are you using any medications or natural remedies at the moment?',
          index: '0.2.31',
        },
        {
          id: 'medication.anyFemale',
          type: 'binary',
          version: '1',
          expires: 180,
          condition: "get('answers.predefined.gender') === 'female'",
          validations: {
            required: true,
          },
          label:
            'Are you using any medications or natural remedies at the moment?',
          description:
            'If you are using a hormonal contraceptive or HRT, this should be included.',
          index: '0.2.32',
        },
        {
          id: 'medication.what',
          type: 'text',
          version: '1',
          expires: 180,
          condition:
            "get('answers.medication.anyFemale') === 'yes' || get('answers.medication.any') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Please list all your medications / natural remedies below.',
          description:
            'Include the name, strength and dose, and the reason you are taking it. E.g. Levothyroxine, 100 micrograms, 1 tablet once daily for an underactive thyroid.',
          index: '0.2.33',
        },
        {
          id: 'allergy.any',
          type: 'binary',
          version: '1',
          expires: 99999,
          validations: {
            required: true,
          },
          label: 'Do you have any allergies?',
          description: 'E.g. Medication, food, animals, plants.',
          index: '0.2.34',
        },
        {
          id: 'allergy.what.match',
          type: 'multipleChoice',
          version: '2',
          expires: 99999,
          options: [
            {
              value: 'penicillin',
              label: 'Penicillin',
            },
            {
              value: 'otherMedications',
              label: 'Other medications',
            },
            {
              value: 'lactose',
              label: 'Lactose',
            },
            {
              value: 'gluten',
              label: 'Gluten',
            },
            {
              value: 'nuts',
              label: 'Nuts',
            },
            {
              value: 'pollenHayfever',
              label: 'Pollen / Hayfever',
            },
            {
              value: 'animals',
              label: 'Animals',
            },
            {
              value: 'houseDust',
              label: 'House dust',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
          condition: "get('answers.allergy.any') === 'yes'",
          validations: {
            required: false,
          },
          label: 'What are you allergic to?',
          description: 'More than one answer can be selected.',
          index: '0.2.35',
        },
        {
          id: 'allergy.lactose.responsibility',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'yes',
              label: 'Yes, I accept',
            },
          ],
          validations: {
            required: true,
          },
          condition: "contains(get('answers.allergy.what.match'), 'lactose')",
          label:
            'Do you accept responsibility for checking if any medications we prescribe contain lactose and the effects this may have?',
          prologue:
            'Many medications contain a small amount of lactose, this is unlikely to cause you any problems unless your lactose allergy is severe.',
          index: '0.2.36',
        },
        {
          id: 'allergy.what.otherMedications',
          type: 'text',
          version: '1',
          expires: 99999,
          condition:
            "contains(get('answers.allergy.what.match'), 'otherMedications')",
          validations: {
            required: true,
          },
          label: 'Please tell us what other medications you are allergic to.',
          index: '0.2.37',
        },
        {
          id: 'allergy.what.other',
          type: 'text',
          version: '1',
          expires: 99999,
          condition: "contains(get('answers.allergy.what.match'), 'other')",
          validations: {
            required: true,
          },
          label: 'Please tell us what other allergies you have.',
          index: '0.2.38',
        },
        {
          id: 'otherChoice',
          version: '2-uk',
          type: 'binary',
          expires: 365,
          validations: {
            required: true,
          },
          label:
            'Is there anything else about your general state of health that the doctor should know?',
          index: '0.2.39',
        },
        {
          id: 'other',
          version: '3-uk',
          type: 'text',
          expires: 365,
          condition: "get('answers.otherChoice') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'What else about your general state of health would you like to tell the doctor?',
          index: '0.2.40',
        },
      ],
      index: '0.2',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.3',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.4',
    },
  ],
  variables: [
    {
      id: 'acne.height.metric',
      type: 'number',
      unit: 'cm',
      expression:
        "get('answers.unit.height') === 'metric' ? get('answers.height') : ( get('answers.unit.height') === 'imperial' ? ((get('answers.heightImperial.feet') * 30.48) + (get('answers.heightImperial.inches') * 2.54)) : 0 )",
      relativeId: 'height.metric',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'acne.weight.metric',
      type: 'number',
      unit: 'kg',
      expression:
        "get('answers.unit.weight') === 'metric' ? get('answers.weight') : ( get('answers.unit.weight') === 'imperial' ? ((get('answers.weightImperial.stones') * 6.35029) + (get('answers.weightImperial.pounds') * 0.453592)) : 0 )",
      relativeId: 'weight.metric',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'acne.bmi',
      type: 'number',
      expression:
        "(get('variables.acne.height.metric') < 1 ? 1 : get('variables.acne.weight.metric') * (get('variables.acne.height.metric') < 50 ? 1 : 10000) / (get('variables.acne.height.metric') * get('variables.acne.height.metric')))",
      relativeId: 'bmi',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Duration',
          description: '',
          options: [
            {
              value: 'lessThanMonth',
              label: 'Less than a month',
            },
            {
              value: 'oneToTwelveMonths',
              label: '1–12 months',
            },
            {
              value: 'moreThanTwelveMonths',
              label: 'More than 12 months',
            },
          ],
        },
        {
          id: 'acne.where',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Location',
          description: '',
          options: [
            {
              value: 'face',
              label: 'Face',
            },
            {
              value: 'neck',
              label: 'Neck',
            },
            {
              value: 'chest',
              label: 'Chest',
            },
            {
              value: 'back',
              label: 'Back',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'acne.whereOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Location, other',
          description: '',
        },
        {
          id: 'acne.describe',
          warning: "contains(get('answers.acne.describe'), 'tenderLumps')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Description of Acne',
          description: '',
          options: [
            {
              value: 'smallBumps',
              label: 'Small bumps',
            },
            {
              value: 'redSpots',
              label: 'Inflamed red spots',
            },
            {
              value: 'whiteheads',
              label: 'Whiteheads',
            },
            {
              value: 'blackheads',
              label: 'Blackheads',
            },
            {
              value: 'tenderLumps',
              label: 'Deep tender lumps under the skin',
            },
          ],
        },
        {
          id: 'acne.scars.match',
          warning: "get('answers.acne.scars.match') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Scarring Acne',
          description: '',
        },
        {
          id: 'acne.causingAnxiety.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Psychological distress',
          description: '',
        },
        {
          id: 'acne.causingAnxiety.describe',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Psychological distress, description',
          description: '',
        },
        {
          id: 'acne.causingAnxiety.impact',
          warning: "get('answers.acne.causingAnxiety.impact') > 5.5",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Psychological distress, rating',
          description: '',
          min: {
            value: 0,
            label: 'Not at all',
          },
          max: {
            value: 10,
            label: 'Very high impact',
          },
        },
        {
          id: 'acne.current.treatment.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Current acne treatment',
          description: '',
        },
        {
          id: 'acne.current.treatment.what',
          warning:
            "contains(get('answers.acne.current.treatment.what'), 'other')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of treatment',
          description: '',
          options: [
            {
              value: 'gelCream',
              label: 'Gel or cream',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotic tablets',
            },
            {
              value: 'contraception',
              label: 'Contraceptive pill',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'acne.current.treatment.whatGelCream',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Treatment, gel or cream',
          description: '',
        },
        {
          id: 'acne.current.treatment.whatAntibiotics',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Antibiotics, type',
          description: '',
        },
        {
          id: 'medication.antibiotics.current.duration',
          warning:
            "get('answers.medication.antibiotics.current.duration') === 'moreThanTwelveMonths'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Antibiotics, duration',
          description: '',
          options: [
            {
              value: 'lessThanMonth',
              label: 'Less than a month',
            },
            {
              value: 'oneToThreeMonths',
              label: '1–3 months',
            },
            {
              value: 'threeToSixMonths',
              label: '3–6 months',
            },
            {
              value: 'sixToTwelveMonths',
              label: '6–12 months',
            },
            {
              value: 'moreThanTwelveMonths',
              label: 'More than 12 months',
            },
          ],
        },
        {
          id: 'medication.antibiotics.current.durationOther',
          warning: 'true',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Details',
          description: '',
        },
        {
          id: 'acne.current.treatment.whatOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of treatment',
          description: '',
        },
        {
          id: 'health.bloodTest.sixMonths.match',
          warning: "get('answers.health.bloodTest.sixMonths.match') === 'no'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'LFT check last 6 monts',
          description: '',
        },
        {
          id: 'health.bloodTest.sixMonths.resultsNormal',
          warning:
            "any(get('answers.health.bloodTest.sixMonths.resultsNormal'), 'no', 'unknown')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Normal LFT check',
          description: '',
        },
        {
          id: 'acne.current.treatment.successful',
          warning: "get('answers.acne.current.treatment.successful') === 'no'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Satisfied with current acne treatment?',
          description: '',
        },
        {
          id: 'acne.current.treatment.wantToContinue',
          warning:
            "get('answers.acne.current.treatment.wantToContinue') === 'no'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Do you want to continue?',
          description: '',
        },
        {
          id: 'acne.previous.treatment.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Past treatment for acne',
          description: '',
        },
        {
          id: 'acne.previous.treatment.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Past treatment, details',
          description: '',
          options: [
            {
              value: 'gelCream',
              label: 'Gel or cream',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotic tablets',
            },
            {
              value: 'contraception',
              label: 'Contraceptive pill',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'acne.previous.treatment.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Past treatment, other',
          description: '',
        },
        {
          id: 'acne.previous.treatment.successful',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Satisfied with previous acne treatment?',
          description: '',
        },
        {
          id: 'acne.current.treatment.preference',
          type: 'question',
          version: '1',
          extras: {},
          label: 'What treatment do you want?',
          description: '',
          options: [
            {
              value: 'gelCream',
              label: 'Gel or cream',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotic tablets',
            },
            {
              value: 'noPreference',
              label: 'No preference',
            },
          ],
        },
        {
          id: 'acne.current.treatment.canAcceptContraception',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Would you consider COCP?',
          description: '',
        },
        {
          id: 'acne.otherSkinCondition.match',
          warning: "get('answers.acne.otherSkinCondition.match') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Other skin conditions',
          description: '',
        },
        {
          id: 'acne.otherSkinCondition.what',
          warning:
            "contains(get('answers.acne.otherSkinCondition.what'), 'eczema') || contains(get('answers.acne.otherSkinCondition.what'), 'periodalDermatitis') || contains(get('answers.acne.otherSkinCondition.what'), 'rosacea') || contains(get('answers.acne.otherSkinCondition.what'), 'lightSensitiveRash')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of other skin conditions',
          description: '',
          options: [
            {
              value: 'eczema',
              label: 'Eczema',
            },
            {
              value: 'periodalDermatitis',
              label: 'Perioral dermatitis',
            },
            {
              value: 'rosacea',
              label: 'Rosacea',
            },
            {
              value: 'lightSensitiveRash',
              label: 'Photosensitive (light sensitive) rash',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'acne.otherSkinCondition.whatOther',
          warning: 'true',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of other skin conditions',
          description: '',
        },
        {
          id: 'acne.otherSkinCondition.where',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Location of other skin conditions',
          description: '',
          options: [
            {
              value: 'faceNeck',
              label: 'Face or neck',
            },
            {
              value: 'scalp',
              label: 'Scalp',
            },
            {
              value: 'chestStomachBackButtocks',
              label: 'Chest, stomach, back or buttocks',
            },
            {
              value: 'armsHandsLegsFeet',
              label: 'Arms, hands, legs or feet',
            },
            {
              value: 'genitalsAnal',
              label: 'Genitals or anal region',
            },
          ],
        },
        {
          id: 'anticonception.toldNotToTake.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Told not to take contraceptive pill?',
          description: '',
        },
        {
          id: 'anticonception.toldNotToTake.why',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Details',
          description: '',
        },
        {
          id: 'anticonception.usingPill.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Currently taking contraceptive pill',
          description: '',
        },
        {
          id: 'anticonception.usingPill.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Which one?',
          description: '',
        },
        {
          id: 'reproduction.recentlyInLabor',
          type: 'question',
          version: '1',
          extras: {},
          label: '≤ 6 weeks postpartum',
          description: '',
        },
        {
          id: 'reproduction.bleedingBetweenPeriods.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Post coital or intermenstrual bleeding',
          description: '',
        },
        {
          id: 'reproduction.bleedingBetweenPeriods.doctor.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Seen a doctor',
          description: '',
        },
        {
          id: 'reproduction.bleedingBetweenPeriods.doctor.diagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Details',
          description: '',
        },
        {
          id: 'health.breastCancerRisk.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Increased risk of breast cancer',
          description: '',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
            {
              value: 'unsure',
              label: 'I am unsure',
            },
          ],
        },
        {
          id: 'health.bloodPressureHigh.duringPregnancy.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gestational hypertension',
          description: '',
        },
        {
          id: 'health.migraineWithAura',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Migraines with aura',
          description: '',
        },
        {
          id: 'health.migraineSymptoms.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Migraines with aura, description',
          description: '',
        },
        {
          id: 'health.operation.recentOrSoon.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Recent or imminent surgery',
          description: '',
        },
        {
          id: 'health.operation.recentOrSoon.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Recent or imminent surgery, description',
          description: '',
        },
        {
          id: 'health.mobility.problems.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Mobility problem',
          description: '',
          options: [
            {
              value: 'none',
              label: 'None',
            },
            {
              value: 'walkingAid',
              label: 'Walking aid needed',
            },
            {
              value: 'wheelchair',
              label: 'Wheelchair user',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'health.mobility.problems.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Details',
          description: '',
        },
        {
          id: 'health.sickleCellDisease.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sickle cell disease',
          description: '',
        },
        {
          id: 'health.clotRiskHigh.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Thrombophilia',
          description: '',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'yesHaveHad',
              label: 'Yes - I have had a blood clot before',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
        {
          id: 'health.clotRelatives',
          type: 'question',
          version: '1',
          extras: {},
          label: 'FH of VTE',
          description: '',
        },
        {
          id: 'health.clotRelativesWho',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Family member with VTE',
          description: '',
          options: [
            {
              value: 'parent',
              label: 'Parent',
            },
            {
              value: 'brotherOrSister',
              label: 'Brother or sister',
            },
            {
              value: 'sonOrDaughter',
              label: 'Son or daughter',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'health.clotRelativesWhoOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Family member with VTE, other',
          description: '',
        },
        {
          id: 'health.relativesCardiovascular',
          type: 'question',
          version: '1',
          extras: {},
          label: 'FH of MI or CVA < 55 years',
          description: '',
        },
        {
          id: 'health.relativesCardiovascularWho',
          type: 'question',
          version: '1',
          extras: {},
          label: 'FH of MI or CVA < 55 years',
          description: '',
          options: [
            {
              value: 'mumSister',
              label: 'A mum or sister',
            },
            {
              value: 'dadBrother',
              label: 'A dad or brother',
            },
          ],
        },
        {
          id: 'health.relativesCardiovascularUnder45',
          type: 'question',
          version: '1',
          extras: {},
          label: 'FH of MI or CVA Male < 45 years',
          description: '',
        },
        {
          id: 'reproduction.pregnant',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Pregnant',
          description: '',
        },
        {
          id: 'reproduction.breastfeeding',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Breastfeeding',
          description: '',
        },
        {
          id: 'clinic.height',
          type: 'text',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Height',
          template:
            "get('answers.unit.height') === 'metric' ? get('answers.height') + ' cm' : ( get('answers.unit.height') === 'imperial' ? get('answers.heightImperial.feet') + ' feet' + (get('answers.heightImperial.inches') ? ' ' + get('answers.heightImperial.inches') + ' inches' : '') : '' )",
        },
        {
          id: 'clinic.weight',
          type: 'text',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Weight',
          template:
            "get('answers.unit.weight') === 'metric' ? get('answers.weight') + ' kg' : ( get('answers.unit.weight') === 'imperial' ? get('answers.weightImperial.stones') + ' stones' + (get('answers.weightImperial.pounds') ? ' ' + get('answers.weightImperial.pounds') + ' pounds' : '') : '' )",
        },
        {
          id: 'clinic.bmitext',
          type: 'text',
          version: '1',
          validations: {
            required: true,
          },
          label: 'BMI',
          template:
            "((!get('variables.acne.bmi') || get('variables.acne.bmi') < 2) ? '-' : (get('variables.acne.bmi')).toFixed(1))",
        },
        {
          id: 'smoking.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smoking, history',
          description: '',
          options: [
            {
              value: 'lessThan10',
              label: 'Yes - less than 10 times a day',
            },
            {
              value: 'moreThan10',
              label: 'Yes - more than 10 times a day',
            },
            {
              value: 'sometimes',
              label: 'Sometimes',
            },
            {
              value: 'previously',
              label: 'Ex-smoker',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
        {
          id: 'smoking.stopped',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ex-Smoker, stop date',
          description: '',
          options: [
            {
              value: 'lessThanYear',
              label: 'Less than a year ago',
            },
            {
              value: 'yearOrMore',
              label: 'A year ago or more',
            },
          ],
        },
        {
          id: 'drugs.recreational.any',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Recreational drugs',
          description: '',
        },
        {
          id: 'drugs.recreational.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Recreational drugs',
          description: '',
          options: [
            {
              value: 'cannabis',
              label: 'Cannabis',
            },
            {
              value: 'cocaine',
              label: 'Cocaine',
            },
            {
              value: 'ecstasy',
              label: 'Ecstasy',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'drugs.recreational.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Recreational drugs, other',
          description: '',
        },
        {
          id: 'alcohol.amount.estimate',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Alcohol',
          description: '',
          options: [
            {
              value: 'moreThan14',
              label: 'More than 14 units a week',
            },
            {
              value: '14OrLess',
              label: '14 units or less a week',
            },
            {
              value: 'none',
              label: "I don't drink alcohol",
            },
          ],
        },
        {
          id: 'alcohol.amount.exact',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Alcohol units / week',
          description: '',
        },
        {
          id: 'health.issues.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Past medical history',
          description: '',
          options: [
            {
              value: 'asthma',
              label: 'Asthma',
            },
            {
              value: 'copdOrSmokersLungs',
              label: 'COPD or "smoker\'s lungs"',
            },
            {
              value: 'diabetes',
              label: 'Diabetes',
            },
            {
              value: 'bloodpreassure',
              label: 'High blood pressure',
            },
            {
              value: 'cardiovascular',
              label: 'Heart disease',
            },
            {
              value: 'strokeOrTia',
              label: 'Stroke or TIA (mini stroke)',
            },
            {
              value: 'clotsLegsOrLung',
              label: 'Blood clots to the legs or lung (DVT or PE)',
            },
            {
              value: 'cancer',
              label: 'Cancer',
            },
            {
              value: 'epilepsy',
              label: 'Epilepsy',
            },
            {
              value: 'kidney',
              label: 'Kidney disease',
            },
            {
              value: 'liver',
              label: 'Liver disease',
            },
            {
              value: 'blooddisease',
              label: 'Blood-borne virus (HIV, Hepatitis B, Hepatitis C)',
            },
            {
              value: 'other',
              label: 'Other medical condition',
            },
          ],
        },
        {
          id: 'health.cardiovascular.what.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Heart disease',
          description: '',
          options: [
            {
              value: 'infarction',
              label: 'Heart attack',
            },
            {
              value: 'congestiveHeart',
              label: 'Heart failure',
            },
            {
              value: 'anginaPectoris',
              label: 'Angina',
            },
            {
              value: 'atrialFibrillation',
              label: 'Atrial fibrillation',
            },
            {
              value: 'heartValve',
              label: 'Heart valve disorder',
            },
            {
              value: 'cardiomyopathy',
              label: 'Cardiomyopathy',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'health.cardiovascular.what.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Heart disease, other',
          description: '',
        },
        {
          id: 'health.diabetes.type',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of diabetes',
          description: '',
          options: [
            {
              value: 'type1',
              label: 'Diabetes type 1',
            },
            {
              value: 'type2',
              label: 'Diabetes type 2',
            },
            {
              value: 'preDiabetes',
              label: 'Pre-diabetes (diet controlled)',
            },
            {
              value: 'diabetesInPregnancy',
              label: 'Diabetes in pregnancy (gestational)',
              condition: "get('answers.predefined.gender') === 'female'",
            },
          ],
        },
        {
          id: 'health.cancerWhat',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of cancer',
          description: '',
        },
        {
          id: 'health.cancerRemission',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Cancer in remission',
          description: '',
        },
        {
          id: 'kidneys.diseaseType',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kidney disease',
          description: '',
        },
        {
          id: 'kidneys.treatment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kidney disease, treatment',
          description: '',
        },
        {
          id: 'health.liverDiseases.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Liver disease',
          description: '',
        },
        {
          id: 'health.liverDiseases.treatment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Liver disease, treatment',
          description: '',
        },
        {
          id: 'health.bloodDisease.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Blood-borne virus',
          description: '',
          options: [
            {
              value: 'hepB',
              label: 'Hepatitis B',
            },
            {
              value: 'hepC',
              label: 'Hepatitis C',
            },
            {
              value: 'hiv',
              label: 'HIV',
            },
          ],
        },
        {
          id: 'health.issues.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Other medical condition',
          description: '',
        },
        {
          id: 'health.operation.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Major surgery',
          description: '',
        },
        {
          id: 'health.operation.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of major surgery',
          description: '',
        },
        {
          id: 'medication.any',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Medications / Natural Remedies',
          description: '',
        },
        {
          id: 'medication.anyFemale',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Medications / Natural Remedies',
          description: '',
        },
        {
          id: 'medication.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Medications / Natural Remedies',
          description: '',
        },
        {
          id: 'allergy.any',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Allergies',
          description: '',
        },
        {
          id: 'allergy.what.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Type of allergy',
          description: '',
          options: [
            {
              value: 'penicillin',
              label: 'Penicillin',
            },
            {
              value: 'otherMedications',
              label: 'Other medications',
            },
            {
              value: 'lactose',
              label: 'Lactose',
            },
            {
              value: 'gluten',
              label: 'Gluten',
            },
            {
              value: 'nuts',
              label: 'Nuts',
            },
            {
              value: 'pollenHayfever',
              label: 'Pollen / Hayfever',
            },
            {
              value: 'animals',
              label: 'Animals',
            },
            {
              value: 'houseDust',
              label: 'House dust',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        },
        {
          id: 'allergy.lactose.responsibility',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Do you accept responsibility for checking if any medications we prescribe contain lactose and the effects this may have?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Yes, I accept',
            },
          ],
        },
        {
          id: 'allergy.what.otherMedications',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Other medication allergies',
          description: '',
        },
        {
          id: 'allergy.what.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Other allergies',
          description: '',
        },
        {
          id: 'otherChoice',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Other general health information',
          description: '',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Other general health information — description',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
    decisionSupport: {
      children: [
        {
          id: 'scarringAcne',
          type: 'comment',
          condition: "get('answers.acne.scars.match') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Scarring acne',
          description: '',
        },
        {
          id: 'causingAnxiety',
          type: 'comment',
          condition: "get('answers.acne.causingAnxiety.match') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Psychological distress',
          description: '',
        },
        {
          id: 'canAcceptContraception',
          type: 'comment',
          condition:
            "get('answers.acne.current.treatment.canAcceptContraception') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'For COCP BMI and BP needed',
          description: '',
        },
        {
          id: 'toldNotToTake',
          type: 'comment',
          condition:
            "get('answers.anticonception.toldNotToTake.match') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: '',
        },
        {
          id: 'recentlyInLabor',
          type: 'comment',
          condition: "get('answers.reproduction.recentlyInLabor') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: '≤ 6 weeks postpartum',
        },
        {
          id: 'bleedingBetweenPeriods',
          type: 'comment',
          condition:
            "get('answers.reproduction.bleedingBetweenPeriods.match') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Bleeding between periods or after sex',
          description: '',
        },
        {
          id: 'breastCancerRiskYes',
          type: 'comment',
          condition: "get('answers.health.breastCancerRisk.match') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'Risk of breast cancer',
        },
        {
          id: 'breastCancerRiskUnsure',
          type: 'comment',
          condition:
            "get('answers.health.breastCancerRisk.match') === 'unsure'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Clarify if patient carry breastcancer gene',
          description: 'Patient unsure of breast cancer heredity',
        },
        {
          id: 'bloodPressureHigh',
          type: 'comment',
          condition:
            "get('answers.health.bloodPressureHigh.duringPregnancy.match') === 'yes'",
          extras: {
            color: 'green',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Doctor to decide re: COCP',
          description: 'Gestational hypertension',
        },
        {
          id: 'migraineWithAuraYes',
          type: 'comment',
          condition: "get('answers.health.migraineWithAura') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'Migraine with Aura',
        },
        {
          id: 'migraineWithAuraUnknown',
          type: 'comment',
          condition: "get('answers.health.migraineWithAura') === 'unknown'",
          extras: {
            color: 'green',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Doctor to decide re: COCP',
          description: 'Possible migraine with aura',
        },
        {
          id: 'majorSurgery',
          type: 'comment',
          condition:
            "get('answers.health.operation.recentOrSoon.match') === 'yes'",
          extras: {
            color: 'green',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Doctor to decide re: COCP',
          description: 'Major surgery',
        },
        {
          id: 'mobilityProblems',
          type: 'comment',
          condition:
            "contains(get('answers.health.mobility.problems.what'), 'walkingAid') || contains(get('answers.health.mobility.problems.what'), 'other')",
          extras: {
            color: 'green',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Doctor to decide re: COCP',
          description: 'Mobility Problems',
        },
        {
          id: 'thrombophilia',
          type: 'comment',
          condition:
            "contains(get('answers.health.mobility.problems.what'), 'wheelchair') || any(get('answers.health.clotRiskHigh.match'), 'yes', 'yesHaveHad') || contains(get('answers.health.clotRelativesWho'), 'parent') || contains(get('answers.health.clotRelativesWho'), 'brotherOrSister') || contains(get('answers.health.clotRelativesWho'), 'sonOrDaughter')",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'Thrombophilia',
        },
        {
          id: 'sickleCellDisease',
          type: 'comment',
          condition: "get('answers.health.sickleCellDisease.match') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'Sickle Cell Disease',
        },
        {
          id: 'bloodClotOther',
          type: 'comment',
          condition:
            "contains(get('answers.health.clotRelativesWho'), 'other')",
          extras: {
            color: 'green',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Doctor to decide re: COCP',
          description: 'FH of VTE',
        },
        {
          id: 'fhMiCva',
          type: 'comment',
          condition:
            "contains(get('answers.health.relativesCardiovascularWho'), 'mumSister') || get('answers.health.relativesCardiovascularUnder45') === 'yes'",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'FH of MI / CVA',
        },
        {
          id: 'pregnant',
          type: 'comment',
          condition: "get('answers.reproduction.pregnant') === 'yes'",
          extras: {
            color: 'red',
            priority: 1,
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Reject',
          description: 'Pregnant. Use message "Pregnancy".',
        },
        {
          id: 'breastfeeding',
          type: 'comment',
          condition: "get('answers.reproduction.breastfeeding') === 'yes'",
          extras: {
            color: 'red',
            priority: 2,
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Reject',
          description: 'Breastfeeding. Use message "Breastfeeding".',
        },
        {
          id: 'bmiLow',
          type: 'comment',
          condition:
            "get('variables.acne.bmi') > 1.1 && get('variables.acne.bmi') <= 17.5",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'BMI ≤ 17.5',
          description: 'Use message "Low BMI"',
        },
        {
          id: 'bmiHigh',
          type: 'comment',
          condition: "get('variables.acne.bmi') >= 34",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'BMI ≥ 34',
        },
        {
          id: 'recentSmokerOver35',
          type: 'comment',
          condition:
            "( any(get('answers.smoking.match'), 'lessThan10', 'moreThan10', 'sometimes') || get('answers.smoking.stopped') === 'lessThanYear' ) && get('answers.predefined.age') >= 35",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'Age ≥ 35 + Smoker',
        },
        {
          id: 'recentSmokerObese',
          type: 'comment',
          condition:
            "( any(get('answers.smoking.match'), 'lessThan10', 'moreThan10', 'sometimes') || get('answers.smoking.stopped') === 'lessThanYear' ) && get('variables.acne.bmi') >= 30",
          extras: {
            color: 'black',
          },
          version: '1',
          validations: {
            required: true,
          },
          label: 'Not for COCP',
          description: 'BMI ≥ 30 + Smoker',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
    journalEntry: {
      children: [
        {
          id: 'contactReason',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.contactReason',
          description: 'Acne',
        },
        {
          id: 'snomed',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.icd.main',
          description: '11381005 Acne',
        },
        {
          id: 'durationLessThanMonth',
          type: 'comment',
          condition: "get('answers.started') === 'lessThanMonth'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Duration: Less than a month',
        },
        {
          id: 'durationOneToTwelveMonths',
          type: 'comment',
          condition: "get('answers.started') === 'oneToTwelveMonths'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Duration: 1–12 months',
        },
        {
          id: 'durationMoreThanTwelveMonths',
          type: 'comment',
          condition: "get('answers.started') === 'moreThanTwelveMonths'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Duration: More than12 months',
        },
        {
          id: 'locationFace',
          type: 'comment',
          condition: "contains(get('answers.acne.where'), 'face')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Location: Face',
        },
        {
          id: 'locationNeck',
          type: 'comment',
          condition: "contains(get('answers.acne.where'), 'neck')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Location: Neck',
        },
        {
          id: 'locationChest',
          type: 'comment',
          condition: "contains(get('answers.acne.where'), 'chest')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Location: Chest',
        },
        {
          id: 'locationBack',
          type: 'comment',
          condition: "contains(get('answers.acne.where'), 'back')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Location: Back',
        },
        {
          id: 'locationOther',
          type: 'comment',
          condition: "contains(get('answers.acne.where'), 'other')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Location: Other',
        },
        {
          id: 'describeSmallBumps',
          type: 'comment',
          condition: "contains(get('answers.acne.describe'), 'smallBumps')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Description: Small bumps',
        },
        {
          id: 'describeRedSpots',
          type: 'comment',
          condition: "contains(get('answers.acne.describe'), 'redSpots')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Description: Inflamed red spots',
        },
        {
          id: 'describeWhiteheads',
          type: 'comment',
          condition: "contains(get('answers.acne.describe'), 'whiteheads')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Description: Whiteheads',
        },
        {
          id: 'describeBlackheads',
          type: 'comment',
          condition: "contains(get('answers.acne.describe'), 'blackheads')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Description: Blackheads',
        },
        {
          id: 'describeTenderLumps',
          type: 'comment',
          condition: "contains(get('answers.acne.describe'), 'tenderLumps')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Description: Deep tender lumps under the skin',
        },
        {
          id: 'scarringAcne',
          type: 'comment',
          condition: "get('answers.acne.scars.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Scarring acne',
        },
        {
          id: 'causingAnxiety',
          type: 'comment',
          condition: "get('answers.acne.causingAnxiety.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Psychological distress',
        },
        {
          id: 'impact',
          type: 'text',
          condition: "get('answers.acne.causingAnxiety.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          template:
            "'Impact 0-10: ' + ( get('answers.acne.causingAnxiety.impact') === undefined ? '-' : (get('answers.acne.causingAnxiety.impact')).toFixed(0) )",
        },
        {
          id: 'antibioticsMoreThanTwelveMonths',
          type: 'comment',
          condition:
            "get('answers.medication.antibiotics.current.duration') === 'moreThanTwelveMonths'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Taking antibiotics without a break > 12 months',
        },
        {
          id: 'lftNormal',
          type: 'comment',
          condition:
            "get('answers.health.bloodTest.sixMonths.resultsNormal') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Normal LFT check last 6 monts',
        },
        {
          id: 'lftNotNormal',
          type: 'comment',
          condition:
            "get('answers.health.bloodTest.sixMonths.resultsNormal') === 'no'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Not normal LFT check last 6 monts',
        },
        {
          id: 'otherSkinConditionMatch',
          type: 'comment',
          condition: "get('answers.acne.otherSkinCondition.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Other skin condition',
        },
        {
          id: 'otherSkinConditionWhatEczema',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.what'), 'eczema')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Type of other skin condition: Eczema',
        },
        {
          id: 'otherSkinConditionWhatPeriodalDermatilis',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.what'), 'periodalDermatitis')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Type of other skin condition: Perioral dermatitis',
        },
        {
          id: 'otherSkinConditionWhatRosacea',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.what'), 'rosacea')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Type of other skin condition: Rosacea',
        },
        {
          id: 'otherSkinConditionWhatLightSensitiveRash',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.what'), 'lightSensitiveRash')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Type of other skin condition: Photosensitive rash',
        },
        {
          id: 'otherSkinConditionWhereFaceNeck',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.where'), 'faceNeck')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Location of other skin condition: Face or neck',
        },
        {
          id: 'otherSkinConditionWhereScalp',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.where'), 'scalp')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Location of other skin condition: Scalp',
        },
        {
          id: 'otherSkinConditionWhereChestStomachBackButtocks',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.where'), 'chestStomachBackButtocks')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description:
            'Location of other skin condition: Chest, stomach, back or buttocks',
        },
        {
          id: 'otherSkinConditionWhereArmsHandsLegsFeet',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.where'), 'armsHandsLegsFeet')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description:
            'Location of other skin condition: Arms, hands, legs of feet',
        },
        {
          id: 'otherSkinConditionWhereGenitalsAnal',
          type: 'comment',
          condition:
            "contains(get('answers.acne.otherSkinCondition.where'), 'genitalsAnal')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description:
            'Location of other skin condition: Genitals or anal region',
        },
        {
          id: 'toldNotToTake',
          type: 'comment',
          condition:
            "get('answers.anticonception.toldNotToTake.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Told not to take contraceptive pill',
        },
        {
          id: 'usingPill',
          type: 'comment',
          condition: "get('answers.anticonception.usingPill.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Currently using contraceptive pill',
        },
        {
          id: 'recentlyInLabor',
          type: 'comment',
          condition: "get('answers.reproduction.recentlyInLabor') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: '≤ 6 weeks postpartum',
        },
        {
          id: 'bleedingBetweenPeriods',
          type: 'comment',
          condition:
            "get('answers.reproduction.bleedingBetweenPeriods.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Post coital or intermenstrual bleeding',
        },
        {
          id: 'breastCancerRisk',
          type: 'comment',
          condition: "get('answers.health.breastCancerRisk.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Increased risk of breast cancer',
        },
        {
          id: 'bloodPressureHigh',
          type: 'comment',
          condition:
            "get('answers.health.bloodPressureHigh.duringPregnancy.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Gestational hypertension',
        },
        {
          id: 'migraineWithAura',
          type: 'comment',
          condition: "get('answers.health.migraineWithAura') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Migraines with aura',
        },
        {
          id: 'operationRecentOrSoon',
          type: 'comment',
          condition:
            "get('answers.health.operation.recentOrSoon.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Recent or imminent surgery',
        },
        {
          id: 'mobilityProblemsWhatWalkingAid',
          type: 'comment',
          condition:
            "get('answers.health.mobility.problems.what') === 'walkingAid'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Walking aid needed',
        },
        {
          id: 'mobilityProblemsWhatWheelchair',
          type: 'comment',
          condition:
            "get('answers.health.mobility.problems.what') === 'wheelchair'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Wheelchair user',
        },
        {
          id: 'mobilityProblemsWhatOther',
          type: 'comment',
          condition: "get('answers.health.mobility.problems.what') === 'other'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Mobility problem, other',
        },
        {
          id: 'sickleCellDisease',
          type: 'comment',
          condition: "get('answers.health.sickleCellDisease.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Sickle cell disease',
        },
        {
          id: 'clotRiskHigh',
          type: 'comment',
          condition: "get('answers.health.clotRiskHigh.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Thrombophilia',
        },
        {
          id: 'clotRelatives',
          type: 'comment',
          condition: "get('answers.health.clotRelatives') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'FH of VTE',
        },
        {
          id: 'clotRelativesWhoParent',
          type: 'comment',
          condition:
            "contains(get('answers.health.clotRelativesWho'), 'parent')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Parent with VTE',
        },
        {
          id: 'clotRelativesWhoBrotherOrSister',
          type: 'comment',
          condition:
            "contains(get('answers.health.clotRelativesWho'), 'brotherOrSister')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Brother or sister with VTE',
        },
        {
          id: 'clotRelativesWhoSonOrDaughter',
          type: 'comment',
          condition:
            "contains(get('answers.health.clotRelativesWho'), 'sonOrDaughter')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Son or daughter with VTE',
        },
        {
          id: 'relativesCardiovascular',
          type: 'comment',
          condition:
            "contains(get('answers.health.relativesCardiovascularWho'), 'mumSister') || contains(get('answers.health.relativesCardiovascularWho'), 'dadBrother')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'FH of MI or CVA < 55 years',
        },
        {
          id: 'relativesCardiovascularMaleUnder45',
          type: 'comment',
          condition:
            "get('answers.health.relativesCardiovascularUnder45') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'FH of MI or CVA Male < 45 years',
        },
        {
          id: 'pregnant',
          type: 'comment',
          condition: "get('answers.reproduction.pregnant') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Pregnant',
        },
        {
          id: 'breastfeeding',
          type: 'comment',
          condition: "get('answers.reproduction.breastfeeding') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.keyFindings',
          description: 'Breastfeeding',
        },
        {
          id: 'investigationsNone',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.investigationsRequested',
          description: 'None',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
    journalEntryTemplates: {
      children: [
        {
          id: 'diagnosisMild',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.diagnosis',
          description: 'Mild Acne',
        },
        {
          id: 'diagnosisModerate',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.diagnosis',
          description: 'Moderate Acne',
        },
        {
          id: 'diagnosisSevere',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.diagnosis',
          description: 'Severe Acne',
        },
        {
          id: 'diagnosisScarring',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.diagnosis',
          description: 'Scarring Acne',
        },
        {
          id: 'diagnosisRosacea',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.diagnosis',
          description: 'Rosacea',
        },
        {
          id: 'prescribedIsotrex',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.prescribed',
          description: 'Isotrex (Isotretinoin) Gel 0.05%',
        },
        {
          id: 'prescribedDuacGel',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.prescribed',
          description: 'Duac Gel (benzoyl peroxide 3% + clindamycin 1%)',
        },
        {
          id: 'prescribedOxytetracycline',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.prescribed',
          description: 'Oxytetracycline 500mg bd',
        },
        {
          id: 'prescribedLymecycline',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.prescribed',
          description: 'Lymecycline 408mg OD',
        },
        {
          id: 'prescribedDianette',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.prescribed',
          description: 'Dianette',
        },
        {
          id: 'prescribedYasmin',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.prescribed',
          description: 'Yasmin',
        },
        {
          id: 'outcomePrescriptionReferredToGP',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.outcome',
          description: 'Referred to GP',
        },
        {
          id: 'outcomePrescriptionReferredToDermatologist',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.outcome',
          description: 'Referred to dermatologist',
        },
        {
          id: 'outcomePrescriptionAndAdvice',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.outcome',
          description: 'Prescription and advice',
        },
        {
          id: 'actionNone',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'journal.actionNeeded',
          description: 'None',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Acne',
  index: '0',
};

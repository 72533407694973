import * as React from 'react';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  spacing: {
    margin: spacing(2),
    [down('xs')]: { margin: spacing() },
  },
}))(({ vertical, horizontal, spacing, children, classes, className }) => (
  <div
    className={classNames(classes.root, spacing && classes.spacing, className)}
    style={{
      top: vertical === 'top' || vertical === 'center' ? 0 : undefined,
      bottom: vertical === 'bottom' || vertical === 'center' ? 0 : undefined,
      left: horizontal === 'left' || horizontal === 'center' ? 0 : undefined,
      right: horizontal === 'right' || horizontal === 'center' ? 0 : undefined,
    }}
  >
    {children}
  </div>
));

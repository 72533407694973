import computeGuide from '../../common/computeGuide';

import type { Action, AsyncAction, ThunkAction } from '../types';

import { getGuide, getGuideMeta } from '../guides';

import { getPatient } from '../patients/selectors';

import { createInquisitor, setInquisitor, getInquisitor } from './inquisitor';
import { getDraft, getQuestion } from './selectors';
import { DRAFTS_UPDATE, type Draft } from './types';

export const updateDraft = (id: string, draft: Draft): Action => ({
  type: DRAFTS_UPDATE,
  payload: { id, draft },
});

export const setDraftValue = (id: string, value: *): ThunkAction => (
  dispatch,
  getState
) => {
  const question = getQuestion(getState(), id);

  if (!question) throw new Error('no question');

  const { visited = [], values = {} } = getDraft(getState(), id) || {};

  dispatch(
    updateDraft(id, {
      visited,
      values: { ...values, [question.ref]: value },
    })
  );
};

export const popDraft = (id: string): AsyncAction => async (
  dispatch,
  getState
) => {
  const inquisitor = getInquisitor(id);

  if (!inquisitor) throw new Error('no inquisitor');

  const { visited = [], values = {} } = getDraft(getState(), id) || {};

  if (!visited.length) throw new Error('no visited');

  dispatch(
    updateDraft(
      id,
      await inquisitor.start({
        visited: visited.slice(0, visited.length - 1),
        values,
      })
    )
  );
};

export const pushDraft = (id: string): AsyncAction => async (
  dispatch,
  getState
) => {
  const inquisitor = getInquisitor(id);

  if (!inquisitor) throw new Error('no inquisitor');

  const question = getQuestion(getState(), id);

  if (!question) throw new Error('no question');

  const { visited = [], values = {} } = getDraft(getState(), id) || {};

  const value = values[question.ref];

  if (value === undefined) {
    throw new Error('no value for question: ' + JSON.stringify(question));
  }

  await inquisitor.push(value);

  dispatch(updateDraft(id, { visited: [...visited, question.ref], values }));
};

export const clearDraft = (id: string): AsyncAction => async dispatch => {
  const inquisitor = getInquisitor(id);

  if (!inquisitor) throw new Error('no inquisitor');

  dispatch(updateDraft(id, await inquisitor.start()));
};

export const moveDraft = (id: string, visited: string[]): AsyncAction => async (
  dispatch,
  getState
) => {
  const inquisitor = getInquisitor(id);

  if (!inquisitor) throw new Error('no inquisitor');

  const { values } = getDraft(getState(), id) || {};

  dispatch(updateDraft(id, await inquisitor.start({ visited, values })));
};

export const randomizeDraft = (id: string): AsyncAction => async (
  dispatch,
  getState
) => {
  const inquisitor = getInquisitor(id);

  if (!inquisitor) throw new Error('no inquisitor');

  const guide = getGuide(id);
  const meta = getGuideMeta(id);
  const patient = getPatient(getState());

  const { visited, answers = [] } = await computeGuide(
    guide,
    patient,
    {},
    meta.preset,
    {
      randomize: true,
    }
  );

  const values = {};
  answers.forEach(({ ref, value }) => (values[ref] = value));

  dispatch(updateDraft(id, await inquisitor.start({ visited, values })));
};

export const fetchDraft = (id: string): AsyncAction => async (
  dispatch,
  getState
) => {
  let inquisitor = getInquisitor(id);
  let draft = getDraft(getState(), id);

  if (!inquisitor) {
    const guide = getGuide(id);
    const patient = getPatient(getState());

    inquisitor = createInquisitor(guide, patient);
    draft = await inquisitor.start(draft);

    setInquisitor(id, inquisitor);

    await dispatch(updateDraft(id, draft));
  }

  return { draft };
};

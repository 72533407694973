import type { Export } from '../../types';

export default (exports: Export[]) => {
  exports.push({ id: 'presentingComplaint', value: 'Cough' });
  exports.push({ id: 'snomedCode', value: '49727002 Cough' });
  exports.push({
    id: 'diagnosis.options',
    value: [
      'Acute Cough (< 3 weeks)',
      'Subacute Cough (3–8 weeks)',
      'Chronic Cough (>8 weeks)',
    ],
  });
  exports.push({
    id: 'investigationsRequested.options',
    value: ['None'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Self care',
      'Referred to GP',
      'Urgent care recommended',
      'Emergency care recommended',
    ],
  });
  exports.push({
    id: 'actionNeeded.options',
    value: [
      'Examine and assess for lower respiratory tract infection',
      'Assess for causes of long lasting cough',
      'None',
    ],
  });
};

import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import classNames from 'classnames';
import Answer from '../result/Answer';
import Close from '../../components/Close';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPatient } from '../../../state/patients/selectors';
import { getDraft } from '../../../state/drafts/selectors';
import { moveDraft } from '../../../state/drafts/actions';
import { getGuide, getGuideMeta } from '../../../state/guides';
import computeGuide from '../../../common/computeGuide';
import { _t } from '../../../i18n';
import colors from '../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, breakpoints: { down } }) => ({
  title: {
    padding: `${spacing(2)}px ${spacing(3)}px`,
  },

  item: {
    display: 'block',
    height: 'auto',
    padding: `${spacing(2)}px ${spacing(3)}px`,
    borderRadius: 0,
    textAlign: 'left',
  },

  itemCurrent: {
    backgroundColor: `${colors.red_500}20`,
  },

  itemResult: {
    fontWeight: '500',
  },

  close: {
    position: 'absolute',
    top: spacing(2),
    right: spacing(2),
    [down('xs')]: {
      top: spacing(),
      right: spacing(),
    },
  },
}));

export default ({
  guideId,
  open,
  onRequestClose,
  variant,
  ...rest
}: {
  guideId: string,
  open: boolean,
  onRequestClose: () => void,
  variant: 'permanent' | 'persistent' | 'temporary',
}) => {
  const [answers, setAnswers] = useState([]);
  const [result, setResult] = useState(undefined);
  const [anchorRef, setAnchorRef] = useState(null);

  const dispatch = useDispatch();
  const patient = useSelector(getPatient);
  const draft = useSelector(store => getDraft(store, guideId));

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const guide = getGuide(guideId);
      const { blacklisted } = getGuideMeta(guideId);

      if (blacklisted) return;

      const { answers, result } = await computeGuide(
        guide,
        patient,
        draft.values || {}
      );

      setAnswers(answers);
      setResult(result);
    })();
  }, [draft.visited, draft.values, guideId, patient]);

  useEffect(() => {
    if (open && anchorRef) {
      anchorRef.scrollIntoView({ block: 'center' });
    }
  }, [open, anchorRef]);

  const refs = answers.map(a => a.ref);

  const currentRef =
    draft.visited.length !== answers.length &&
    refs.slice(draft.visited.length)[0];

  const close = () => onRequestClose && onRequestClose();

  return (
    <Drawer open={open} onClose={close} variant={variant} {...rest}>
      <Typography variant="h2" className={classes.title}>
        {_t('guides.goto.question')}
      </Typography>

      <List>
        {answers.map(answer => (
          <ListItem
            ref={
              answer.ref === currentRef ? ref => setAnchorRef(ref) : undefined
            }
            key={answer.ref}
            button
            className={classNames(
              classes.item,
              answer.ref === currentRef && classes.itemCurrent
            )}
            onClick={() => {
              dispatch(
                moveDraft(guideId, refs.slice(0, refs.indexOf(answer.ref)))
              );
              if (variant === 'temporary') {
                close();
              }
            }}
          >
            <Answer answer={answer} />
          </ListItem>
        ))}

        {result && (
          <ListItem
            ref={!currentRef ? ref => setAnchorRef(ref) : undefined}
            button
            className={classNames(
              classes.item,
              !currentRef && classes.itemCurrent
            )}
            onClick={() => {
              dispatch(moveDraft(guideId, refs));
              if (variant === 'temporary') {
                close();
              }
            }}
          >
            <Typography className={classes.itemResult}>
              {_t('guides.goto.result')}
            </Typography>
          </ListItem>
        )}
      </List>

      <Close onClick={close} className={classes.close} />
    </Drawer>
  );
};

import * as React from 'react';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: `calc(100% - ${spacing(6)}px)`,
    maxWidth: 600,
    margin: '0 auto',
    padding: `${spacing(12)}px ${spacing(3)}px`,
    [down('xs')]: {
      paddingTop: spacing(10),
      paddingBottom: spacing(3),
    },
  },
}))(({ children, classes, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
));

import type { Guide } from './../../types';

import moduleEndMessage from '../modules/endMessage';

import ds from './decisionSupport';
import commonAborts from '../common/commonAborts';
import aborts from './aborts';
import triage from './triage';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import summaryExports from './summaryExports';
import unwellOutOfHoursWarning from '../partials/unwellOutOfHoursWarning';
import collateDecisionSupport from '../common/collateDecisionSupport';
import { ensureArray, matchOne } from '../common/arrayUtils';

import moduleSmoking from '../modules/smoking';
import moduleAlcohol from '../modules/alcohol';
import moduleAlternateMedication from '../modules/alternateMedication';
import moduleAllergies from '../modules/allergies';
import modulePharmacy from '../modules/pharmacy';

export default (async args => {
  const { ask, decisionSupport, significant, scores, exports, patient } = args;

  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const bumpLutsScore = () => {
    scores.increase('luts');
  };

  summaryExports(exports);

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  await ask.info({
    description: 'We are now going to ask about your urinary symptoms...',
  });

  const haveCurrentSymptoms = await eChoice({
    id: 'uti.haveCurrentSymptoms',
    label: 'Do you have symptoms of urinary tract infection right now?',
    clinicLabel: 'Do you have symptoms of a UTI right now?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        hide: true,
      },
      {
        label: "I'm not sure",
        value: 'notSure',
        hide: true,
      },
      {
        label:
          "No, I'm looking for treatment to use in case of a future infection",
        value: 'futureInfection',
        warn: true,
        abort: commonAborts.adultAntibioticsForFutureUseGp,
      },
    ],
  });
  if (haveCurrentSymptoms.abort) {
    return { abort: haveCurrentSymptoms.abort };
  }

  const durationOfSymptoms = await eChoice({
    id: 'uti.durationOfSymptoms',
    label: 'When did your urine problem start?',
    clinicLabel: 'Duration of symptoms',
    options: [
      {
        label: 'Less than 2 days ago',
        value: 'lessThan2Days',
        warn: true,
      },
      {
        label: '2–7 days ago',
        value: '2to7days',
      },
      {
        label: 'More than a week ago',
        value: 'moreThan1Week',
        warn: true,
      },
    ],
  });

  if (durationOfSymptoms === 'moreThan1Week') {
    await eText({
      id: 'uti.durationOfSymptomsDetail',
      label:
        'Please tell us exactly how long you have had symptoms and describe what has happened to you in that time.',
      clinicLabel: 'Description of course of illness',
    });
  }

  await eChoice({
    id: 'uti.dysuria',
    label: 'Do you have any pain or burning when you pass urine?',
    clinicLabel: 'Dysuria',
    options: [
      {
        value: 'yes',
        onSelected: bumpLutsScore,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  await eChoice({
    id: 'uti.urgency',
    label:
      'Do you feel like you need to pass urine urgently (even if little comes out)?',
    clinicLabel: 'Urgency',
    options: [
      {
        value: 'yes',
        onSelected: bumpLutsScore,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  await eChoice({
    id: 'uti.frequency',
    label: 'Do you need to pass urine more often than normal?',
    clinicLabel: 'Frequency',
    options: [
      {
        value: 'yes',
        onSelected: bumpLutsScore,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  await eChoice({
    id: 'uti.polyuria',
    label: 'Are you passing a greater amount of urine than normal?',
    clinicLabel: 'Polyuria',
    options: [
      {
        value: 'yes',
        onSelected: bumpLutsScore,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  await eChoice({
    id: 'uti.lowerAbdominalPain',
    label: 'Do you have any pain or discomfort in your lower abdomen?',
    clinicLabel: 'Lower abdominal pain',
    options: [
      {
        value: 'yes',
        onSelected: bumpLutsScore,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  const fever = await eChoice({
    id: 'uti.fever',
    label: 'Since you became unwell, have you had a temperature?',
    clinicLabel: 'Temperature',
    options: [
      {
        value: 'yes',
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  let feverGt38;
  if (fever === 'yes') {
    feverGt38 = await eChoice({
      id: 'uti.feverGt38',
      label: 'Was your temperature 38°C or more?',
      clinicLabel: 'Temperature 38°C or more',
      options: [
        {
          value: 'yes',
          warn: true,
          urgent: true,
          ds: ds.rejectHighFever,
          keyFinding: 'T ≥ 38°C',
        },
        {
          value: 'no',
          hide: true,
        },
        {
          value: 'unknown',
          hide: true,
        },
      ],
    });
    if (feverGt38 === 'yes') {
      await unwellOutOfHoursWarning({
        ...args,
        id: 'uit.whenFeverGt38',
        label:
          'If you have a very high temperature, this could be a sign of a serious infection.',
      });
    }
  }

  const haematuria = await eChoice({
    id: 'uti.haematuria',
    label: 'Can you see any blood in your urine?',
    clinicLabel: 'Haematuria',
    options: [
      {
        label: 'Yes - when I wipe there is blood on the tissue',
        value: 'yesWipe',
        warn: true,
        ds: ds.rejectHaematuria,
        keyFinding: 'Blood in urine',
      },
      {
        label: 'Yes - my urine is red like blood',
        value: 'yesUrine',
        warn: true,
        abort: commonAborts.adultBloodInUrineUrgent,
      },
      {
        label: "Yes, but I'm on my period",
        value: 'yesPeriod',
        hide: true,
      },
      {
        label: 'No',
        value: 'no',
        hide: true,
      },
    ],
  });
  if (haematuria.abort) {
    return { abort: haematuria.abort };
  }
  if (haematuria === 'yesWipe' && feverGt38 === 'yes') {
    return { abort: commonAborts.adultBloodInUrineAndHighTempUrgent };
  }

  const rigors = await eChoice({
    id: 'uti.rigors',
    label: 'Have you had any uncontrollable shaking?',
    clinicLabel: 'Rigors',
    options: [
      {
        value: 'yes',
        warn: true,
        keyFinding: 'Rigors',
        abort: aborts.adultUncontrollableShakingAndUrineInfectionUrgent,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (rigors.abort) {
    return { abort: rigors.abort };
  }

  const backFlankPain = await eChoice({
    id: 'uti.backFlankPain ',
    label:
      'Since you became unwell, have you developed severe pain in your mid-back (near your ribs) or side?',
    clinicLabel: 'Back / Flank Pain',
    options: [
      {
        value: 'yes',
        warn: true,
        keyFinding: 'Back / Flank Pain',
        abort: aborts.adultSevereBackPainAndUrineInfectionUrgent,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (backFlankPain.abort) {
    return { abort: backFlankPain.abort };
  }

  const vomiting = await eChoice({
    id: 'uti.vomiting',
    label: 'Since your symptoms started, have you vomited?',
    clinicLabel: 'Vomiting',
    options: [
      {
        value: 'yes',
        warn: true,
        abort: aborts.adultVomitingAndUrineInfectionUrgent,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (vomiting.abort) {
    return { abort: vomiting.abort };
  }

  const vaginalSymptoms = await eChoice({
    id: 'uti.vaginalSymptoms',
    label: 'Do you have any vaginal symptoms?',
    clinicLabel: 'Vaginal symptoms',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (vaginalSymptoms === 'yes') {
    await eChoice({
      id: 'uti.whatVaginalSymptoms',
      label: 'What vaginal symptoms do you have?',
      clinicLabel: 'Vaginal symptoms',
      isMultipleChoice: true,
      optional: true,
      options: [
        {
          label: 'My vagina hurts only when I pass urine',
          value: 'onlyWhenPassUrine',
          hide: true,
        },
        {
          label: 'My vagina is itchy',
          value: 'itch',
          keyFinding: 'Vaginal itch',
          warn: true,
          ds: ds.vaginalSymptoms,
        },
        {
          label: 'I have a new vaginal discharge',
          value: 'discharge',
          keyFinding: 'Vaginal discharge ',
          warn: true,
          ds: ds.vaginalSymptoms,
        },
        {
          label: 'My vagina is sore all the time',
          value: 'soreness',
          keyFinding: 'Vaginal soreness',
          warn: true,
          ds: ds.vaginalSymptoms,
        },
      ],
    });
  }

  const genitalBlisters = await eChoice({
    id: 'uti.genitalBlisters',
    label: 'Have you developed any blisters in the genital area?',
    clinicLabel: 'Genital Blisters',
    options: [
      {
        value: 'yes',
        warn: true,
        ds: ds.rejectGenitalBlisters,
        keyFinding: 'Genital blisters',
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (genitalBlisters === 'yes') {
    const photo = await ask.upload(
      `We'd like you to upload a photo of the area where you have blisters to help your doctor with their diagnosis.`,
      {
        id: 'uti.upload',
        description: `
You can use a mobile phone camera to take the photo — put it in selfie-mode, find a place with good lighting, and try and take a clear, close-up photo of the affected area.

Rest assured, your photos will be kept confidential and will only be added to your medical record.
    `,
      }
    );
    significant.push({
      type: 'upload',
      label: 'Photo/s',
      value: photo,
    });
  }

  const antibioticsForUti = await eChoice({
    id: 'uti.antibioticsForUti',
    label:
      'Have you been treated with antibiotics for a urinary tract infection in the past 12 months?',
    clinicLabel: 'Used antibiotics for UTI during the past 12 months',
    options: [
      {
        value: 'yes',
        keyFinding: "UTI's treated with antibiotics in past 12 months",
        hide: true,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (antibioticsForUti === 'yes') {
    const antibioticsForUtiHowMany = await eChoice({
      id: 'uti.antibioticsForUtiHowMany',
      label:
        'How many times have you been treated with antibiotics for a urinary tract infection in the past 12 months?',
      clinicLabel: "UTI's treated with antibiotics in past 12 months",
      options: [
        {
          label: '1',
          value: '1',
          hide: true,
        },
        {
          label: '2',
          value: '2',
          hide: true,
        },
        {
          label: '3',
          value: '3',
          warn: true,
          ds: ds.rejectUrineCultureNeeded,
        },
        {
          label: 'More than 3',
          value: 'moreThan3',
          warn: true,
          ds: ds.rejectUrineCultureNeeded,
        },
      ],
    });

    let antibioticsForUti2InSixMonths;
    if (antibioticsForUtiHowMany === '2') {
      antibioticsForUti2InSixMonths = await eChoice({
        id: 'uti.antibioticsForUti2InSixMonths',
        label:
          'Were both these antibiotic treatments taken in the past 6 months?',
        clinicLabel: '2x UTIs treated with antibiotics in the past 6 months',
        options: [
          {
            value: 'yes',
            warn: true,
            keyFinding: '2x UTIs treated with antibiotics in the past 6 months',
            ds: ds.rejectUrineCultureNeeded,
          },
          {
            value: 'no',
            hide: true,
          },
        ],
      });
    }

    if (
      antibioticsForUtiHowMany === '1' ||
      antibioticsForUti2InSixMonths === 'no'
    ) {
      await eChoice({
        id: 'uti.antibioticsForUtiInLastTwoMonths',
        label:
          'Were you treated with antibiotics for a urinary tract infection in the past 2 months?',
        clinicLabel: 'UTI treated with antibiotics in the past 2 months',
        options: [
          {
            value: 'yes',
            warn: true,
            keyFinding: 'UTI treated with antibiotics in the past 2 months',
            ds: ds.rejectUrineCultureNeeded,
          },
          {
            value: 'no',
            hide: true,
          },
        ],
      });
    }
  }

  const utiSpread = await eChoice({
    id: 'uti.utiSpread',
    label:
      'Have you ever had a urinary tract infection that spread to your kidneys or needed hospital treatment?',
    description: 'More than one answer can be selected.',
    clinicLabel: 'Previous Pyelonephritis',
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'Spread to my kidneys (Pyelonephritis)',
        value: 'pyelonephritis',
        keyFinding: 'Previous Pyelonephritis',
        warn: true,
      },
      {
        label: 'Treated in A&E ',
        value: 'treatedInAE',
      },
      {
        label: 'Had to stay in hospital overnight',
        value: 'hospitalOvernight',
        warn: true,
      },
    ],
  });
  if (
    matchOne(ensureArray(utiSpread), ['pyelonephritis', 'hospitalOvernight'])
  ) {
    const utiSpreadWhen = await eChoice({
      id: 'uti.utiSpreadWhen',
      label: 'When was this?',
      options: [
        {
          label: 'Within the last 12 months',
          value: 'withinLast12Months',
          warn: true,
          ds: ds.rejectUpperUtiLast12MonthsUrineCultureNeeded,
        },
        {
          label: 'More than 12 months ago',
          value: 'moreThan12Months',
          ds: ds.previousUpperUti,
        },
      ],
    });
    if (utiSpreadWhen === 'moreThan12Months') {
      await eText({
        id: 'uti.utiSpreadWhenOver12MonthsAgo',
        label: 'Please can you tell us:',
        description: `
When was this?
        
What treatment were you given?
        
Did you have any tests or scans?

What did they show?
        `,
        clinicLabel: 'Further details',
      });
    } else if (utiSpreadWhen === 'withinLast12Months') {
      await unwellOutOfHoursWarning({
        ...args,
        id: 'uit.WhenSpread',
        label:
          'You have had a serious urine infection before. It could happen again...',
        description: `
**See a doctor urgently if you have:**
- **Severe symptoms** 
- **Back pain**
- **Fever**
- **Vomiting**  

**Outside of normal working hours, please call 111, go to an urgent care centre or A&E.**
        `,
      });
    }
  }

  // TODO move to own file
  await ask.info({
    description:
      'We are now going to ask you some general questions about your health...',
  });

  await moduleSmoking(args);
  await moduleAlcohol(args);
  await moduleAlternateMedication(args);
  await moduleAllergies(args);

  const medicalConditions = await eChoice({
    id: 'uti.medicalConditions',
    label: 'Do you have any of the following medical conditions?',
    description: 'More than one answer can be selected.',
    clinicLabel: 'Past medical history',
    isMultipleChoice: true,
    optional: true,
    expires: 365,
    options: [
      {
        label: 'Diabetes',
        value: 'diabetes',
        hide: true,
        ds: ds.reviewPMH,
      },
      {
        label: 'Cancer',
        value: 'cancer',
        warn: true,
        ds: ds.reviewPMH,
      },
      {
        label: 'Kidney disease',
        value: 'kidneyDisease',
        warn: true,
        ds: ds.reviewPMH,
      },
      {
        label: 'Liver disease',
        value: 'liverDisease',
        warn: true,
        ds: ds.reviewPMH,
      },
      {
        label: 'Other medical condition',
        value: 'other',
        hide: true,
        ds: ds.reviewPMH,
      },
    ],
  });

  if (matchOne(ensureArray(medicalConditions), ['diabetes'])) {
    const options = [
      {
        label: 'Diabetes type 1',
        value: 'type1',
        warn: true,
      },
      {
        label: 'Diabetes type 2',
        value: 'type2',
        warn: true,
      },
      {
        label: 'Pre-diabetes (diet controlled)',
        value: 'pre',
        warn: true,
      },
    ];
    if (patient.gender === 'female') {
      options.push({
        label: 'Diabetes in pregnancy (gestational)',
        value: 'pregnancy',
        warn: true,
      });
    }
    await eChoice({
      id: 'uti.typeOfDiabetes',
      label: 'What type of diabetes do you have?',
      description: 'More than one answer can be selected.',
      clinicLabel: 'Past medical history',
      isMultipleChoice: true,
      options,
      expires: 365,
    });
  }

  if (matchOne(ensureArray(medicalConditions), ['other'])) {
    await eText({
      id: 'uti.medicalConditionsOther',
      label: 'Please list the other medical conditions you suffer from.',
      clinicLabel: 'Past medical history',
      expires: 365,
      warn: true,
    });
  }

  await modulePharmacy(args);
  await moduleEndMessage(args);

  if (scores.get('luts') >= 3) {
    decisionSupport.push(ds.luts3OrMore);
    exports.push({
      id: 'keyFindings',
      value: 'LUTS ≥ 3',
    });
  } else {
    decisionSupport.push(ds.lutsLessThan3);
    exports.push({
      id: 'keyFindings',
      value: 'LUTS < 3',
    });
  }

  switch (durationOfSymptoms) {
    case 'lessThan2Days':
      if (scores.get('luts') >= 3) {
        if (fever === 'yes') {
          if (feverGt38 === 'yes') {
            decisionSupport.push(ds.treatWithAntibioticsLessThan2Days);
          } else {
            decisionSupport.push(ds.treatDelayedAntibioticsLessThan2Days);
          }
        } else {
          decisionSupport.push(ds.treatDelayedAntibioticsLessThan2Days);
        }
      } else {
        decisionSupport.push(ds.treatWithAdviceOnly);
      }
      break;
    case '2to7days':
      if (scores.get('luts') >= 3) {
        decisionSupport.push(ds.treatWithAntibiotics2To7Days);
      } else {
        decisionSupport.push(ds.rejectUrineDipstickNeeded);
      }
      break;
    case 'moreThan1Week':
      if (scores.get('luts') >= 3) {
        decisionSupport.push(ds.treatWithAntibioticsOver7Days);
      } else {
        decisionSupport.push(ds.rejectUrineDipstickNeeded);
      }
      break;
    default:
      throw new Error(
        `Unknown answer to duration ${
          typeof durationOfSymptoms === 'string' ? durationOfSymptoms : ''
        }`
      );
  }

  collateDecisionSupport(decisionSupport);

  return {};
}: Guide);

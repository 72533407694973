import createExtendedChoice from '../common/extendedChoice';

import type { GuideWithOut } from '../../types';
import ds from './decisionSupport';

export default (async args => {
  const { scores, decisionSupport } = args;
  const eChoice = createExtendedChoice(args);

  const bumpActScore = value => () => {
    scores.increase('act', value);
  };

  await eChoice({
    id: 'asthmaReview.act.impactOnSchoolWork',
    label: `During the past 4 weeks, how much of the time has your asthma kept you from getting as much done at work, school or home?`,
    clinicLabel: 'ACT - Asthma impact on school/work',
    options: [
      {
        label: 'All of the time',
        value: 'allOfTheTime',
        keyFinding: 'ACT - Asthma interferes with school/work all of the time',
        onSelected: bumpActScore(1),
        urgent: true,
        warn: true,
        ds: ds.actAsthmaInterferesAllOfTheTime,
      },
      {
        label: 'Most of the time',
        value: 'mostOfTheTime',
        keyFinding: 'ACT - Asthma interferes with school/work most of the time',
        onSelected: bumpActScore(2),
      },
      {
        label: 'Some of the time',
        value: 'someOfTheTime',
        keyFinding: 'ACT - Asthma interferes with school/work some of the time',
        onSelected: bumpActScore(3),
      },
      {
        label: 'A little of the time',
        value: 'aLittleOfTheTime',
        keyFinding:
          'ACT - Asthma interferes with school/work a little of the time',
        onSelected: bumpActScore(4),
      },
      {
        label: 'None of the time',
        value: 'noneOfTheTime',
        keyFinding: 'ACT - Asthma interferes with school/work none of the time',
        onSelected: bumpActScore(5),
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.act.shortnessOfBreath',
    label: `During the past 4 weeks, how often have you had shortness of breath?`,
    clinicLabel: 'ACT - SOB in the past 4 weeks',
    options: [
      {
        label: 'More than once a day',
        value: 'moreThanOnceADay',
        keyFinding: 'ACT - SOB more than once a day',
        onSelected: bumpActScore(1),
      },
      {
        label: 'Once a day',
        value: 'onceADay',
        keyFinding: 'ACT - SOB once a day',
        onSelected: bumpActScore(2),
      },
      {
        label: '3 to 6 times a week',
        value: '3To6TimesAWeek',
        keyFinding: 'ACT - SOB 3 to 6 times a week',
        onSelected: bumpActScore(3),
      },
      {
        label: 'Once or twice a week',
        value: 'onceOrTwiceAWeek',
        keyFinding: 'ACT - SOB once or twice a week',
        onSelected: bumpActScore(4),
      },
      {
        label: 'Not at all',
        value: 'notAtAll',
        keyFinding: 'ACT - No SOB at all',
        onSelected: bumpActScore(5),
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.act.wokenAtNight',
    label: `During the past 4 weeks, how often did your asthma symptoms (wheezing, coughing, shortness of breath, chest tightness or pain) woken you up at night or earlier than usual in the morning?`,
    clinicLabel: 'ACT - Asthma symptoms waking at night/early morning',
    options: [
      {
        label: '4 or more nights a week',
        value: '4OrMoreAWeek',
        keyFinding:
          'ACT - Asthma symptoms waking at night/early morning 4 or more nights a week',
        onSelected: bumpActScore(1),
      },
      {
        label: '2 to 3 nights a week',
        value: '2To3AWeek',
        keyFinding:
          'ACT - Asthma symptoms waking at night/early morning 2 to 3 nights a week',
        onSelected: bumpActScore(2),
      },
      {
        label: 'Once a week',
        value: 'onceAWeek',
        keyFinding:
          'ACT - Asthma symptoms waking at night/early morning once a week',
        onSelected: bumpActScore(3),
      },
      {
        label: 'Once or twice',
        value: 'onceOrTwice',
        keyFinding:
          'ACT - Asthma symptoms waking at night/early morning once or twice a month',
        onSelected: bumpActScore(4),
      },
      {
        label: 'Not at all',
        value: 'notAtAll',
        keyFinding: 'ACT - Asthma symptoms not waking at night/early morning',
        onSelected: bumpActScore(5),
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.act.rescueInhalerUse',
    label: `During the past 4 weeks, how often have you used your rescue inhaler (usually salbutamol/blue colour)?`,
    clinicLabel: 'ACT - Reliever inhaler use in the past 4 weeks',
    options: [
      {
        label: '3 or more times a day',
        value: '3OrMoreTimesADay',
        keyFinding: 'ACT - Used reliever more than 3 times a day',
        onSelected: bumpActScore(1),
      },
      {
        label: 'Once or twice a day',
        value: 'onceOrTwiceADay',
        keyFinding: 'ACT - Used reliever once or twice a day',
        onSelected: bumpActScore(2),
      },
      {
        label: '2 or 3 times per week',
        value: '2Or3TimesPerWeek',
        keyFinding: 'ACT - Used reliever 2 or 3 times a week',
        onSelected: bumpActScore(3),
      },
      {
        label: 'Once a week or less',
        value: 'onceAWeekOrLess',
        keyFinding: 'ACT - Used reliever once a week or less',
        onSelected: bumpActScore(4),
      },
      {
        label: 'Not at all',
        value: 'notAtAll',
        keyFinding: 'ACT - Not used reliever',
        onSelected: bumpActScore(5),
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.act.controlRating',
    label: `How would you rate your asthma control during the past 4 weeks?`,
    clinicLabel: 'ACT - Patient asthma control rating',
    options: [
      {
        label: 'Not controlled at all',
        value: 'notControlled',
        keyFinding: 'ACT - Patient rates asthma as not controlled at all',
        onSelected: bumpActScore(1),
      },
      {
        label: 'Poorly controlled',
        value: 'poorlyControlled',
        keyFinding: 'ACT - Patient rates asthma as poorly controlled',
        onSelected: bumpActScore(2),
      },
      {
        label: 'Somewhat controlled',
        value: 'somewhatControlled',
        keyFinding: 'ACT - Patient rates asthma as somewhat controlled',
        onSelected: bumpActScore(3),
      },
      {
        label: 'Well controlled',
        value: 'wellControlled',
        keyFinding: 'ACT - Patient rates asthma as well controlled',
        onSelected: bumpActScore(4),
      },
      {
        label: 'Completely controlled',
        value: 'completelyControlled',
        keyFinding: 'ACT - Patient rates asthma as completely controlled',
        onSelected: bumpActScore(5),
      },
    ],
  });

  const actScore = scores.get('act');
  if (actScore === 25) {
    decisionSupport.push(ds.actGoodControl);
  } else if (actScore >= 20) {
    decisionSupport.push(ds.actModerateControl);
  } else {
    decisionSupport.push(ds.actPoorControl);
  }

  return { actScore };
}: GuideWithOut<{
  actScore: number,
}>);

import * as React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

import Link from './Link';

const renderers = (classes, className) => ({
  root: ({ children }) => (
    <div className={classNames(classes.root, className)}>{children}</div>
  ),

  paragraph: ({ children }) => (
    <Typography className={classNames(classes.typography, classes.paragraph)}>
      {children}
    </Typography>
  ),

  list: ({ children }) => (
    <ul className={classNames(classes.paragraph, classes.list)}>{children}</ul>
  ),

  listItem: ({ children }) => (
    <li>
      <Typography className={classes.typography}>{children}</Typography>
    </li>
  ),

  heading: ({ children, level }) => (
    <Typography variant={level === 1 ? 'h2' : 'h3'} className={classes.heading}>
      {children}
    </Typography>
  ),

  link: props => (
    <Link {...props} className={classNames(classes.typography, classes.link)} />
  ),

  strong: props => <span {...props} className={classes.bold} />,
});

export default withStyles(({ spacing }) => ({
  root: {},

  bold: {
    fontWeight: 500,
  },

  paragraph: {
    margin: 0,
    marginBottom: spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  list: {
    paddingLeft: 18,
  },

  heading: {
    marginBottom: spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  typography: {},

  link: {
    textDecoration: 'underline',
  },
}))(({ className, classes, children }) => (
  <ReactMarkdown source={children} renderers={renderers(classes, className)} />
));

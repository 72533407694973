import type { Guide } from '../../types';

import ds from './decisionSupport';
import aborts from './aborts';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const {
    ask,
    decisionSupport,
    exports,
    patient: { age },
    significant,
  } = args;

  let eczemaSteroidCreamsFrequencyAnswer = [];
  let eczemaSteroidCreamsDurationAnswer = [];

  const eczemaPreviouslyDiagnosed = {
    type: 'binary',
    id: 'eczemaPreviouslyDiagnosed',
    label: 'Has your child been given a diagnosis of eczema?',
  };

  const eczemaPreviouslyDiagnosedAnswer = await ask(eczemaPreviouslyDiagnosed);

  significant.push({
    ...eczemaPreviouslyDiagnosed,
    label: 'Previously diagnosed',
    value: eczemaPreviouslyDiagnosedAnswer,
    warn: ['no'],
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Previous diagnosis of eczema',
      no: 'No previous diagnosis of eczema',
    }[eczemaPreviouslyDiagnosedAnswer],
  });

  const eczemaReasonForConsultation = {
    type: 'multipleChoice',
    id: 'eczemaReasonForConsultation',
    label: "How can we help with your child's rash?",
    description: 'More than one answer can be selected.',
    options: [
      {
        label: 'They need more of their medication',
        value: 'prescriptionRenewal',
      },
      { label: 'Their rash is not improving', value: 'notImproving' },
      {
        label: 'Their rash looks worse',
        value: 'worse',
      },
      { label: 'Their rash looks infected', value: 'infected' },
      { label: 'Other', value: 'other' },
    ],
  };

  const eczemaReasonForConsultationAnswer = await ask(
    eczemaReasonForConsultation
  );
  if (!eczemaReasonForConsultationAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...eczemaReasonForConsultation,
      label: 'Reason for consultation',
      description: '',
      value: eczemaReasonForConsultationAnswer.filter(
        answer => answer !== 'other'
      ),
      warn:
        eczemaReasonForConsultationAnswer.includes('notImproving') ||
        eczemaReasonForConsultationAnswer.includes('worse') ||
        eczemaReasonForConsultationAnswer.includes('infected'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      prescriptionRenewal: 'Parent requesting more medication',
      notImproving: 'Parents reports rash is not improving',
      rashChangedOrWorsened: 'Parent concerned rash looks worse',
      infected: 'Parent concerned rash looks infected',
      other: 'Other',
    }[eczemaReasonForConsultationAnswer],
  });

  if (eczemaReasonForConsultationAnswer.includes('infected')) {
    decisionSupport.push(ds.infectedRash);
  }

  if (eczemaReasonForConsultationAnswer.includes('prescriptionRenewal')) {
    const eczemaPrescriptionRenewalWhat = {
      type: 'text',
      id: 'eczemaPrescriptionRenewalWhat',
      label: 'Please list the medication that they need.',
    };

    const eczemaPrescriptionRenewalWhatAnswer = await ask(
      eczemaPrescriptionRenewalWhat
    );

    significant.push({
      ...eczemaPrescriptionRenewalWhat,
      label: 'Medication requested',
      value: eczemaPrescriptionRenewalWhatAnswer,
    });
  }

  if (eczemaReasonForConsultationAnswer.includes('other')) {
    const eczemaReasonForConsultationOther = {
      type: 'text',
      id: 'eczemaReasonForConsultationOther',
      label: "Please tell us how else we can help with your child's rash.",
    };

    const eczemaReasonForConsultationOtherAnswer = await ask(
      eczemaReasonForConsultationOther
    );

    significant.push({
      ...eczemaReasonForConsultationOther,
      label: 'Other reason for consultation',
      value: eczemaReasonForConsultationOtherAnswer,
    });
  }

  if (eczemaReasonForConsultationAnswer.includes('worse')) {
    const eczemaWorsenedOrChangedDuration = {
      type: 'choice',
      id: 'eczemaWorsenedOrChangedDuration',
      label: 'When did their rash become worse?',
      options: [
        { label: 'Less than 24 hours ago', value: 'lessThan24Hours' },
        { label: '1–2 days ', value: 'oneToTwoDays' },
        { label: '3–7 days ', value: 'threeToSevenDays' },
        { label: '1–2 weeks ', value: 'oneToTwoWeeks' },
        { label: 'More than 2 weeks ', value: 'moreThanTwoWeeks' },
      ],
    };

    const eczemaWorsenedOrChangedDurationAnswer = await ask(
      eczemaWorsenedOrChangedDuration
    );

    if (eczemaWorsenedOrChangedDurationAnswer !== 'moreThanTwoWeeks') {
      significant.push({
        ...eczemaWorsenedOrChangedDuration,
        label: 'When did the rash worsen',
        value: eczemaWorsenedOrChangedDurationAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        lessThan24Hours: 'Rash worsened less than 24 hours ago',
        oneToTwoDays: 'Rash worsened 1–2 days ago',
        threeToSevenDays: 'Rash worsened 3–7 days ago',
        oneToTwoWeeks: 'Rash worsened 1–2 weeks ago',
        moreThanTwoWeeks: 'Rash worsened more than 2 weeks ago',
      }[eczemaWorsenedOrChangedDurationAnswer],
    });

    if (eczemaWorsenedOrChangedDurationAnswer.includes('moreThanTwoWeeks')) {
      const eczemaWorsenedOrChangedLongDuration = {
        type: 'text',
        id: 'eczemaWorsenedOrChangedLongDuration',
        label: 'When did their rash become worse?',
      };

      const eczemaWorsenedOrChangedLongDurationAnswer = await ask(
        eczemaWorsenedOrChangedLongDuration
      );

      significant.push({
        ...eczemaWorsenedOrChangedLongDuration,
        label: 'When did the rash worsen',
        value: eczemaWorsenedOrChangedLongDurationAnswer,
      });
    }
  }

  const eczemaLocation = {
    type: 'multipleChoice',
    id: 'eczemaLocation',
    label: "Where on the child's body is the rash?",
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Face or neck', value: 'faceOrNeck' },
      { label: 'Scalp', value: 'scalp' },
      {
        label: 'Chest, stomach, back or buttocks',
        value: 'chestStomachBackOrButtocks',
      },
      {
        label: 'Arms, hands, legs or feet',
        value: 'armsHandsLegsOrFeet',
      },
      { label: 'Genitals or bottom', value: 'genitalsOrBottom' },
    ],
  };

  const eczemaLocationAnswer = await ask(eczemaLocation);

  significant.push({
    ...eczemaLocation,
    label: 'Location of rash',
    description: '',
    value: eczemaLocationAnswer,
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      faceOrNeck: 'Rash on face or neck',
      scalp: 'Rash on scalp',
      chestStomachBackOrButtocks: 'Rash on chest, stomach or back',
      armsHandsLegsOrFeet: 'Rash on arms, hands, legs or feet',
      genitalsOrBottom: 'Rash on genitals or anal region',
    }[eczemaLocationAnswer],
  });

  if (eczemaLocationAnswer.includes('faceOrNeck')) {
    decisionSupport.push(ds.eczemaFace);
  }
  if (eczemaLocationAnswer.includes('genitalsOrButtocks')) {
    decisionSupport.push(ds.eczemaGenitalsOrButtocks);
  }

  const eczemaDescription = {
    type: 'multipleChoice',
    id: 'eczemaDescription',
    label: 'Describe their rash.',
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Red patches', value: 'redPatches' },
      { label: 'Flaky', value: 'flaky' },
      { label: 'Raised', value: 'raised' },
      { label: 'Itchy', value: 'itchy' },
    ],
    optional: true,
  };

  const eczemaDescriptionAnswer = await ask(eczemaDescription);

  if (eczemaDescriptionAnswer.length !== 0) {
    significant.push({
      ...eczemaDescription,
      label: 'Description of rash',
      description: '',
      value: eczemaDescriptionAnswer,
    });

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        redPatches: 'Rash is patchy and red',
        flaky: 'Rash is flaky',
        raised: 'Rash is raised',
        itchy: 'Rash is itchy',
      }[eczemaDescriptionAnswer],
    });
  }

  const eczemaInfectedDescription = {
    type: 'multipleChoice',
    id: 'eczemaInfectedDescription',
    label: 'Does their rash show any of the following?',
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Weeping', value: 'weeping' },
      { label: 'Bleeding', value: 'bleeding' },
      { label: 'Blisters or boils', value: 'blistersOrBoils' },
    ],
    optional: true,
  };

  const eczemaInfectedDescriptionAnswer = await ask(eczemaInfectedDescription);

  if (eczemaInfectedDescriptionAnswer.length === 0) {
    significant.push({
      ...eczemaInfectedDescription,
      label: 'Potential infected eczema or cellulitis/ abscess',
      description: '',
      value: ['No'],
    });
  }

  if (eczemaInfectedDescriptionAnswer.length !== 0) {
    significant.push({
      ...eczemaInfectedDescription,
      label: 'Potential infected eczema or cellulitis/ abscess',
      description: '',
      value: eczemaInfectedDescriptionAnswer,
      warn: true,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      weeping: 'Rash is weeping',
      bleeding: 'Rash is bleeding',
      blistersOrBoils: 'Rash has blisters or boils',
    }[eczemaInfectedDescriptionAnswer],
  });

  if (eczemaInfectedDescriptionAnswer.length !== 0) {
    return { abort: aborts.potentialCellulitis };
  }

  const eczemaOwnDescription = {
    type: 'text',
    id: 'eczemaOwnDescription',
    label: 'Please describe the rash in your own words.',
  };

  const eczemaOwnDescriptionAnswer = await ask(eczemaOwnDescription);

  significant.push({
    ...eczemaOwnDescription,
    label: 'Rash description',
    value: eczemaOwnDescriptionAnswer,
  });

  const eczemaCurrentTreatment = {
    type: 'binary',
    id: 'eczemaCurrentTreatment',
    label: "Are you using anything to treat your child's rash at the moment?",
  };

  const eczemaCurrentTreatmentAnswer = await ask(eczemaCurrentTreatment);

  if (eczemaCurrentTreatmentAnswer === 'no') {
    significant.push({
      ...eczemaCurrentTreatment,
      label: 'Current treatment for rash',
      value: eczemaCurrentTreatmentAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Current treatment for rash',
      no: 'No current treatment for rash',
    }[eczemaCurrentTreatmentAnswer],
  });

  if (eczemaCurrentTreatmentAnswer === 'yes') {
    const eczemaCurrentTreatmentWhat = {
      type: 'multipleChoice',
      id: 'eczemaCurrentTreatmentWhat',
      label: 'Which of the treatments are they using right now?',
      description: 'More than one answer can be selected.',
      options: [
        {
          label:
            'Emollients e.g. Aveeno, Diprobase cream, Zerobase cream, E45 cream',
          value: 'emollients',
        },
        { label: 'Steroid creams', value: 'steroidCreams' },
        { label: 'Antibiotics (syrup or tablets)', value: 'abx' },
        { label: 'Other ', value: 'other' },
      ],
    };

    const eczemaCurrentTreatmentWhatAnswer = await ask(
      eczemaCurrentTreatmentWhat
    );

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        emollients: 'Using emollients',
        steroidCreams: 'Using steroid cream',
        abx: 'Using antibiotics',
      }[eczemaCurrentTreatmentWhatAnswer],
    });

    if (eczemaCurrentTreatmentWhatAnswer.includes('abx') && age < 3) {
      return { abort: aborts.eczema };
    } else if (eczemaCurrentTreatmentWhatAnswer.includes('abx')) {
      decisionSupport.push(ds.alreadyOnAntibiotics);
    }

    if (eczemaCurrentTreatmentWhatAnswer.includes('emollients')) {
      const eczemaEmollientsWhat = {
        type: 'text',
        id: 'eczemaEmollientsWhat',
        label: 'Which emollient (moisturiser) are they currently using?',
      };

      const eczemaEmollientsWhatAnswer = await ask(eczemaEmollientsWhat);

      significant.push({
        ...eczemaEmollientsWhat,
        label: 'Emollient currrently using',
        value: eczemaEmollientsWhatAnswer,
      });

      const eczemaEmollientsAsSoapSubstitute = {
        type: 'binary',
        id: 'eczemaEmollientsAsSoapSubstitute',
        label: 'Do they use the emollient as a soap substitute?',
      };

      const eczemaEmollientsAsSoapSubstituteAnswer = await ask(
        eczemaEmollientsAsSoapSubstitute
      );

      significant.push({
        ...eczemaEmollientsAsSoapSubstitute,
        label: 'Use emollient as soap substitute',
        value: eczemaEmollientsAsSoapSubstituteAnswer,
      });

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          yes: 'Uses emollient as soap subsititute',
          no: 'Does not use emollient as soap subsititute',
        }[eczemaEmollientsAsSoapSubstituteAnswer],
      });

      const eczemaEmollientsFrequency = {
        type: 'choice',
        id: 'eczemaEmollientsFrequency',
        label: 'How often do they use their emollient?',
        options: [
          { label: 'A few times a day', value: 'fewTimesADay' },
          { label: 'Once a day', value: 'onceADay' },
          { label: 'Every other day', value: 'everyOtherDay' },
          { label: 'A few times a week', value: 'fewTimesAWeek' },
        ],
      };

      const eczemaEmollientsFrequencyAnswer = await ask(
        eczemaEmollientsFrequency
      );

      significant.push({
        ...eczemaEmollientsFrequency,
        label: 'Current emollient use',
        value: eczemaEmollientsFrequencyAnswer,
      });

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          fewTimesADay: 'Emolient use: a few times a week',
          onceADay: 'Emollient use: once a day',
          everyOtherDay: 'Emollient use: every other day',
          fewTimesAWeek: 'Emollient use: a few times a week',
        }[eczemaEmollientsFrequencyAnswer],
      });

      const eczemaEmollientsSatisfiedWithTreatment = {
        type: 'binary',
        id: 'eczemaEmollientsSatisfiedWithTreatment',
        label: 'Is the emollient helping?',
      };

      const eczemaEmollientsSatisfiedWithTreatmentAnswer = await ask(
        eczemaEmollientsSatisfiedWithTreatment
      );

      if (eczemaEmollientsSatisfiedWithTreatmentAnswer === 'yes') {
        significant.push({
          ...eczemaEmollientsSatisfiedWithTreatment,
          label: 'Current emollient satisfactory',
          value: eczemaEmollientsSatisfiedWithTreatmentAnswer,
        });
      }

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          yes: 'Emollient is helping',
          no: 'Emollient is not helping',
        }[eczemaEmollientsSatisfiedWithTreatmentAnswer],
      });

      if (eczemaEmollientsSatisfiedWithTreatmentAnswer === 'no') {
        const eczemaEmollientChoice = {
          type: 'choice',
          id: 'eczemaEmollientChoice',
          label:
            'Would you like to continue with the emollient you are currently using or try a different one?',
          description:
            "Emollients are an important part of rash treatment.\nWe may need to use other creams for your child's rash, but we are likey to advise you to continue using an emollient.\n\n",
          options: [
            {
              label: "Yes, i'd like a different emollient",
              value: 'yes',
            },
            {
              label: "No, i'll stick to the emollient I have",
              value: 'no',
            },
          ],
        };

        const eczemaEmollientChoiceAnswer = await ask(eczemaEmollientChoice);

        significant.push({
          ...eczemaEmollientChoice,
          label: 'Would like to try a different emollient',
          description: '',
          value: eczemaEmollientChoiceAnswer,
        });

        addExportIfValueExists(exports, {
          id: 'keyFindings',
          value: {
            yes: 'Would like to try a different emollient',
            no: 'Will continue same emollient',
          }[eczemaEmollientChoiceAnswer],
        });

        if (eczemaEmollientChoiceAnswer === 'yes') {
          const eczemaEmollientSpecificRequest = {
            type: 'choice',
            id: 'eczemaEmollientSpecificRequest',
            label: 'Is there a specific emollient you would like to try?',
            options: [
              {
                label: 'Yes, I know which one I would like to try',
                value: 'yes',
              },
              {
                label: "No, I'd like the doctor to choose for me",
                value: 'no',
              },
            ],
          };

          const eczemaEmollientSpecificRequestAnswer = await ask(
            eczemaEmollientSpecificRequest
          );

          if (eczemaEmollientSpecificRequestAnswer === 'no') {
            significant.push({
              ...eczemaEmollientSpecificRequest,
              label: 'Would like to chose a specific emollient',
              value: eczemaEmollientSpecificRequestAnswer,
            });

            decisionSupport.push(ds.chooseEmollient);
          }

          addExportIfValueExists(exports, {
            id: 'keyFindings',
            value: {
              yes: 'Would like to try a specific emolient',
              no: 'Would like the doctor to choose emollient',
            }[eczemaEmollientSpecificRequestAnswer],
          });

          if (eczemaEmollientSpecificRequestAnswer === 'yes') {
            const eczemaEmollientChoiceWhat = {
              type: 'text',
              id: 'eczemaEmollientChoiceWhat',
              label:
                'Please tell us the name of the emollient you would like to have.',
            };

            const eczemaEmollientChoiceWhatAnswer = await ask(
              eczemaEmollientChoiceWhat
            );

            significant.push({
              ...eczemaEmollientChoiceWhat,
              label: 'Specific emolient preferred',
              value: eczemaEmollientChoiceWhatAnswer,
            });

            decisionSupport.push(ds.prescribeEmollient);
          }
        }
      }
    }

    if (eczemaCurrentTreatmentWhatAnswer.includes('steroidCreams')) {
      const eczemaSteroidCreamsWhat = {
        type: 'multipleChoice',
        id: 'eczemaSteroidCreamsWhat',
        label: 'Are they using any of these steroid creams?',
        description: 'More than one answer can be selected.',
        options: [
          {
            label: 'Hydrocortisone 0.05%',
            value: 'hydrocortisoneWeak',
          },
          { label: 'Hydrocortisone 1%', value: 'hydrocortisoneMiddle' },
          { label: 'Hydrocortisone 2%', value: 'hydrocortisoneStrong' },
          { label: 'Betnovate RD 0.025%', value: 'betnovateRD' },
          {
            label: 'Eumovate (clobetasone butyrate 0.05%)',
            value: 'eumovate',
          },
          {
            label: 'Betnovate (betamethasone valerate 0.1%)',
            value: 'betamethasoneValerate',
          },
          {
            label: 'Diprosone (betamethasone dipropionate 0.05%) ',
            value: 'diprosone',
          },
          { label: 'Other', value: 'other' },
        ],
      };

      const eczemaSteroidCreamsWhatAnswer = await ask(eczemaSteroidCreamsWhat);

      if (!eczemaSteroidCreamsWhatAnswer.every(answer => answer === 'other')) {
        significant.push({
          ...eczemaSteroidCreamsWhat,
          label: 'Current steroid treatment',
          description: '',
          value: eczemaSteroidCreamsWhatAnswer.filter(
            answer => answer !== 'other'
          ),
          warn:
            eczemaSteroidCreamsWhatAnswer.includes('eumovate') ||
            eczemaSteroidCreamsWhatAnswer.includes('betamethasoneValerate') ||
            eczemaSteroidCreamsWhatAnswer.includes('diprosone'),
        });
      }

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          hydrocortisoneWeak: 'Currently using: Hydrocortisone 0.05%',
          hydrocortisoneMiddle: 'Currently using: Hydrocortisone 1%',
          hydrocortisoneStrong: 'Currently using: Hydrocortisone 2%',
          betnovateRD: 'Currently using: Betnovate RD 0.025%',
          eumovate: 'Currently using: Eumovate (clobetasone butyrate 0.05%)',
          betamethasoneValerate:
            'Currently using: Betnovate (betamethasone valerate 0.1%)',
          diprosone:
            'Currently using: Diprosone (betamethasone dipropionate 0.05%) ',
          other: 'Currently using: Other steroid',
        }[eczemaSteroidCreamsWhatAnswer],
      });

      if (
        (eczemaSteroidCreamsWhatAnswer.includes('eumovate') ||
          eczemaSteroidCreamsWhatAnswer.includes('betamethasoneValerate')) &&
        age < 11
      ) {
        decisionSupport.push(ds.potentSteroidPresently);
      }

      if (eczemaSteroidCreamsWhatAnswer.includes('diprosone')) {
        decisionSupport.push(ds.potentSteroidPresently);
      }

      if (eczemaSteroidCreamsWhatAnswer.includes('other')) {
        const eczemaSteroidCreamsOther = {
          type: 'text',
          id: 'eczemaSteroidCreamsOther',
          label: 'Please tell us the name of the steroid cream they are using.',
        };

        const eczemaSteroidCreamsOtherAnswer = await ask(
          eczemaSteroidCreamsOther
        );

        significant.push({
          ...eczemaSteroidCreamsOther,
          label: 'Current steroid treatment',
          value: eczemaSteroidCreamsOtherAnswer,
        });
      }

      const eczemaSteroidCreamsFrequency = {
        type: 'choice',
        label: 'How often are they using their steroid cream?',
        options: [
          {
            label: 'Three to four times a day',
            value: 'threeToFourTimesADay',
          },
          { label: 'Twice a day', value: 'twiceADay' },
          { label: 'Once a day', value: 'onceADay' },
          { label: 'Once a week', value: 'onceAWeek' },
          {
            label: 'Two to three times a week',
            value: 'twoToThreeTimesAWeek',
          },
        ],
      };

      eczemaSteroidCreamsFrequencyAnswer = await ask(
        eczemaSteroidCreamsFrequency
      );

      significant.push({
        ...eczemaSteroidCreamsFrequency,
        label: 'Current steroid frequency of use',
        value: eczemaSteroidCreamsFrequencyAnswer,
        warn: eczemaSteroidCreamsFrequencyAnswer.includes(
          'threeToFourTimesADay'
        ),
      });

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          threeToFourTimesADay: 'Steroid use: 3–4 times a day',
          twiceADay: 'Steroid use: 2–3 times a day',
          onceADay: 'Steroid use: Once a day',
          onceAWeek: 'Steroid use: Once a week',
          twoToThreeTimesAWeek: 'Steroid use: 2–3 times a week',
        }[eczemaSteroidCreamsFrequencyAnswer],
      });

      if (
        eczemaSteroidCreamsFrequencyAnswer.includes('threeToFourTimesADay') ||
        eczemaSteroidCreamsFrequencyAnswer.includes('twiceADay') ||
        eczemaSteroidCreamsFrequencyAnswer.includes('onceADay')
      ) {
        const eczemaSteroidCreamsDuration = {
          type: 'choice',
          id: 'eczemaSteroidCreamsDuration',
          label: 'How long have they used this steroid cream continuously for?',
          description: 'This means every day without a break.',
          options: [
            { label: 'One to two days', value: 'oneToTwoDays' },
            { label: 'Three to six days', value: 'threeToSixDays' },
            { label: 'A week', value: 'week' },
            { label: 'Two to three weeks', value: 'twoToThreeWeeks' },
            { label: 'More than one month', value: 'moreThanAMonth' },
          ],
        };

        eczemaSteroidCreamsDurationAnswer = await ask(
          eczemaSteroidCreamsDuration
        );

        significant.push({
          ...eczemaSteroidCreamsDuration,
          label: 'Current steroid continuous duration',
          description: '',
          value: eczemaSteroidCreamsDurationAnswer,
          warn: eczemaSteroidCreamsDurationAnswer.includes('moreThanAMonth'),
        });

        addExportIfValueExists(exports, {
          id: 'keyFindings',
          value: {
            oneToTwoDays: 'Continuous steroid use: 1-2 days',
            threeToSixDays: 'Continuous steroid use: 3-6 days',
            week: 'Continuous steroid use: a week',
            twoToThreeWeeks: 'Continuous steroid use: 2-3 weeks',
            moreThanAMonth: 'Continuous steroid use: more than one month',
          }[eczemaSteroidCreamsDurationAnswer],
        });

        if (
          eczemaSteroidCreamsDurationAnswer.includes('moreThanAMonth') ||
          eczemaSteroidCreamsFrequencyAnswer.includes('threeToFourTimesADay')
        ) {
          decisionSupport.push(ds.potentialSteroidOveruse);
        }
      }
    }

    if (eczemaCurrentTreatmentWhatAnswer.includes('abx')) {
      const eczemaCurrentTreatmentAbx = {
        type: 'text',
        id: 'eczemaCurrentTreatmentAbx',
        label: 'Please tell us the which antibiotic your child is using.',
      };

      const eczemaCurrentTreatmentAbxAnswer = await ask(
        eczemaCurrentTreatmentAbx
      );

      significant.push({
        ...eczemaCurrentTreatmentAbx,
        label: 'Current oral antibiotic use',
        value: eczemaCurrentTreatmentAbxAnswer,
      });
    }

    if (eczemaCurrentTreatmentWhatAnswer.includes('other')) {
      const eczemaCurrentTreatmentOther = {
        type: 'text',
        id: 'eczemaCurrentTreatmentOther',
        label: 'Please list any other treatments they are using.',
        description:
          'Include the name, how often they use it, and how long they have been using it for.',
      };

      const eczemaCurrentTreatmentOtherAnswer = await ask(
        eczemaCurrentTreatmentOther
      );

      significant.push({
        ...eczemaCurrentTreatmentOther,
        label: 'Current treatment for rash',
        description: '',
        value: eczemaCurrentTreatmentOtherAnswer,
      });
    }
  }

  const eczemaPastTreatment = {
    type: 'multipleChoice',
    id: 'eczemaCurrentTreatmentWhat',
    label:
      "Have you used any of these treatments previously for your child's rash?",
    description: 'More than one answer can be selected.',
    options: [
      {
        label:
          'Emollients e.g. Aveeno, Diprobase cream, zerobase cream, E45 cream',
        value: 'emollients',
      },
      { label: 'Steroid creams', value: 'steroidCreams' },
      { label: 'Antibiotics', value: 'abx' },
      { label: 'Other ', value: 'other' },
    ],
    optional: true,
  };

  const eczemaPastTreatmentAnswer = await ask(eczemaPastTreatment);

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      emollients: 'Previously used Emollients',
      steroidCreams: 'Previously used Steroid creams',
      abx: 'Previously used Antibiotics',
    }[eczemaPastTreatmentAnswer],
  });

  if (eczemaPastTreatmentAnswer.length !== 0) {
    const eczemaPastTreatmentWhat = {
      type: 'text',
      id: 'eczemaPastTreatmentWhat',
      label:
        "Please tell us about which treatments you have previously used for your child's rash.",
      description: 'Include if they helped or not.',
    };

    const eczemaPastTreatmentWhatAnswer = await ask(eczemaPastTreatmentWhat);

    significant.push({
      ...eczemaPastTreatmentWhat,
      label: 'Previous rash treatments',
      description: '',
      value: eczemaPastTreatmentWhatAnswer,
    });
  }

  if (
    eczemaPastTreatmentAnswer.includes('steroidCreams') ||
    eczemaSteroidCreamsFrequencyAnswer.includes('threeToFourTimesADay') ||
    eczemaSteroidCreamsDurationAnswer.includes('moreThanAMonth')
  ) {
    const eczemaSkinChangesSteroidOveruse = {
      type: 'multipleChoice',
      id: 'eczemaSkinChangesSteroidOveruse',
      label: 'Does your child have any of these skin changes?',
      description: 'More than one answer can be selected',
      options: [
        {
          label: 'Thinner/fragile skin',
          value: 'thinnerOrFragileSkin',
        },
        { label: 'Easy bruising ', value: 'easyBruising' },
        {
          label: 'Light patches of skin',
          value: 'lighterSkinPatches',
        },
        {
          label: 'Stretchmarks',
          value: 'stretchmarks',
        },
      ],
      optional: true,
    };

    const eczemaSkinChangesSteroidOveruseAnswer = await ask(
      eczemaSkinChangesSteroidOveruse
    );

    significant.push({
      ...eczemaSkinChangesSteroidOveruse,
      label: 'Signs of steroid overuse',
      description: '',
      value: eczemaSkinChangesSteroidOveruseAnswer,
    });

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        thinnerOrFragileSkin: 'Signs of steroid overuse:Thin / Fragile skin',
        easyBruising: 'Signs of steroid overuse: Easy bruising',
        lighterSkinPatches: 'Signs of steroid overuse: Hypopigmentation',
        stretchmarks: 'Signs of steroid overuse: Stretch marks',
      }[eczemaSkinChangesSteroidOveruseAnswer],
    });

    if (eczemaSkinChangesSteroidOveruseAnswer.length !== 0) {
      decisionSupport.push(ds.signsOfSteroidOveruse);
    }
  }

  const photoEczema = await ask.upload(
    `We'd like you to upload a photo of your child's skin rash to help the doctor with their diagnosis.`,
    {
      description: `
          You can use a mobile phone camera to take the photo.\n\nRest assured, your photos will be kept confidential and will only be added to your medical record.
        `,
      optional: true,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photoEczema,
  });

  return {};
}: Guide);

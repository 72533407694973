export default {
  actAsthmaInterferesAllOfTheTime: {
    id: 'actAsthmaInterferesAllOfTheTime',
    label: 'Consider video or phone call ',
    color: 'red',
    description: 'Asthma interferes with school/work all of the time.',
  },
  actGoodControl: {
    id: 'actGoodControl',
    label: 'ACT 25 (Good control)',
    color: 'black',
    description: 'Consider stepping down treatment.',
  },
  actModerateControl: {
    id: 'actModerateControl',
    label: 'ACT 20-24 (Moderate control)',
    color: 'black',
    description:
      'Review compliance and technique. Consider stepping up treatment and/or review in 3 months',
  },
  actPoorControl: {
    id: 'actPoorControl',
    label: 'ACT less than 20 (Poor control)',
    color: 'black',
    description:
      'Review compliance and technique. Consider stepping up treatment and/or review in 1 month',
  },
  considerReferralToRespiratory: {
    id: 'considerReferralToRespiratory',
    label: 'Review clinical notes',
    color: 'black',
    description: 'Consider referral to respiratory as 2 or more exacerbations',
  },
  extendedPreviousItuAdmission: {
    id: 'extendedPreviousItuAdmission',
    label: 'Review clinical notes',
    color: 'black',
    description:
      'Previous ITU admission, patient should be under secondary care.',
  },
  extendedOralSteroidUseInLastYear: {
    id: 'extendedOralSteroidUseInLastYear',
    label: 'Review clinical notes',
    color: 'black',
    description: 'Use of oral steroid in the past year',
  },
  extendedNebuliserUseInLastYear: {
    id: 'extendedNebuliserUseInLastYear',
    label: 'Review clinical notes',
    color: 'black',
    description: 'Use of nebuliser in the past year',
  },
  extendedHospitalInLastYear: {
    id: 'extendedHospitalInLastYear',
    label: 'Review clinical notes',
    color: 'black',
    description: 'Hospital attendance due to asthma in the last year',
  },
  extendedPeakFlowReview: {
    id: 'extendedPeakFlowReview',
    label: 'Review clinical notes',
    color: 'black',
    description: 'If no peak flow in last year use no peak flow meter template',
  },
  extendedDeclinedFlu: {
    id: 'extendedDeclinedFlu',
    label: 'Update clinical record',
    color: 'black',
    description: 'Add "flu vaccine declined" to clinical notes',
  },
  medicationConsiderPrescribingInhaler: {
    id: 'medicationConsiderPrescribingInhaler',
    label: 'Review clinical notes',
    color: 'black',
    description:
      'Review medication history, consider prescribing ICS as ACT less than 25',
  },
  medicationConsiderPrescribingAerochamber: {
    id: 'medicationConsiderPrescribingAerochamber',
    label: 'Discuss medication',
    color: 'black',
    description: 'Consider prescribing aerochamber to improve asthma control',
  },
  medicationNotUsingPreventerRegularly: {
    id: 'medicationNotUsingPreventerRegularly',
    label: 'Discuss medication',
    color: 'black',
    description: 'Not using preventer regularly',
  },
  hayfeverUseOtcAntihistamine: {
    id: 'hayfeverUseOtcAntihistamine',
    label: 'Discuss medication',
    color: 'black',
    description: 'Use OTC antihistamine template',
  },
  hayfeverUseOtcNasalSpray: {
    id: 'hayfeverUseOtcNasalSpray',
    label: 'Discuss medication',
    color: 'black',
    description: 'Use OTC steroid nasal spray template',
  },
};

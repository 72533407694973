import type { Guide } from '../../types';

export default (async ({ ask, significant }) => {
  const label =
    'Are there any contraceptive pills that you have already tried that you do not want again?';
  const alreadyTried = await ask(label);

  if (alreadyTried === 'yes') {
    significant.push({ label, value: alreadyTried, warn: true });

    const question = {
      type: 'text',
      label: `Which ones don't you want and why?`,
      expires: 365,
    };
    significant.push({
      ...question,
      value: await ask(question),
      warn: true,
    });
  }

  return {};
}: Guide);

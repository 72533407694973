export default {
  id: 'informedConsent',
  type: 'multipleChoice',
  description: `
**Do you confirm that you have:**
- answered honestly
- given the doctor accurate, up-to-date information about your health and condition

**You will message the doctor that treats you with any questions if you need advice about:**
- the service
- treatment or a medication's effectiveness or potential side effects

You understand that it's important your GP is made aware of any medications or advice you receive from Docly.

You acknowledge that when consenting to treatment and seeking care, you have the capacity to act appropriately on the information you receive.
  `,
  options: ['Yes, I confirm the above is true'],
};

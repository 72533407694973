import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import ds from './decisionSupport';
import aborts from './aborts';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);
  const {
    patient: { age },
    decisionSupport,
  } = args;

  // const durationAbort = age < 5 ? aborts.SmallChildFever : undefined;

  const durOff = {
    id: 'coughChild.durationoffever',
    label: 'How many days has your child had a fever?',
    clinicLabel: 'Duration of fever',
    options: [
      { label: '1-2 days', value: '1-2days', keyFinding: 'Fever for 1-2 days' },
      { label: '2-4 days', value: '2-4days', keyFinding: 'Fever for 2-4 days' },
      {
        label: '5 or more days',
        value: '5ormoredays',
        keyFinding: 'Fever for more than 5 days',
        hide: true,
      },
    ],
  };
  const durationoffever = await eChoice(durOff);

  if (durationoffever === '5ormoredays') {
    if (age < 5) {
      return {
        abort: aborts.SmallChildFever,
      };
    }
    decisionSupport.push(ds.feverMoreThan5Days);

    await eText({
      id: 'coughChild.durationoffever',
      label: 'How many days has your child had a fever?',
      clinicLabel: 'Duration of fever',
    });
  }

  const triedantipyretics = await eChoice({
    id: 'coughChild.triedantipyretics',
    label:
      'Have you given your child any paracetamol (Calpol) or ibuprofen (Neurofen) in the last 24 hours?',
    clinicLabel: 'Tried antipyretics',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Tried antipyretics',
        hide: true,
      },
      { label: 'No', value: 'no', keyFinding: 'Not tried antipyretics' },
    ],
  });

  if (triedantipyretics === 'yes') {
    const didantipyreticshelp = await eChoice({
      id: 'coughChild.didantipyreticshelp',
      label: 'Did the medicine help with the fever?',
      clinicLabel: 'Did antipyretics help',
      options: [
        {
          label: 'Yes, it helped to settle the fever. ',
          value: 'Yes,ithelpedtosettlethefever.',
          keyFinding: 'Fever settled with antipyretic',
        },
        {
          label: 'Yes, the fever settled, but it returned within a few hours.',
          value: 'yesbutreturned',
          keyFinding: 'Fever settled with antipyretic but returned',
        },
        {
          label: 'No, it did not help at all. ',
          value: 'No,itdidnothelpatall.',
          keyFinding: 'Antipyretic did not help',
          warn: true,
          urgent: true,
          abort: aborts.Sepsis,
        },
      ],
    });

    if (didantipyreticshelp.abort) {
      return didantipyreticshelp;
    }
  }

  if (durationoffever === '5ormoredays') {
  }

  /*
  CHANGE LOGIC: IF '5 or more days' selected when asked 'How long has your child had a fever' AND if selected 'No, it did not help at all' when asked Did the medicine help with the fever? Then TRIAGE OUT FEVER URGENT GP APPOINTMENT message
  */
  return {};
}: Guide);

import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import ds from './decisionSupport';
import { union } from '../common/arrayUtils';

export const keyFindingLabelBreathingChangedMorethan4weeksago =
  'Breathing changed more than 4 weeks ago';

export default (async args => {
  const { scores } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  /*
  await ask.info({
    label: 'Difficulty in breathing can be a sign of a serious illness.',
    description: `
Your child needs urgent medical attention if they have difficulty in breathing and are: 
- Very unwell
- Feverish
- Having blue fingers or lips
- Not passing urine every 4 hours

If it is outside of normal working hours, or it is the weekend, please go to to A&E or dial 999.`,
  });
*/

  /*
  const strugglingtobreathnow = await eChoice({
    id: 'coughChild.strugglingtobreathnow',
    label: 'Is your child struggling to breathe now?',
    clinicLabel: 'Struggling to breath now?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'DIB now',
        warn: true,
        abort: aborts.SevereDifficultyBreathing,
      },
      { label: 'No', value: 'no', keyFinding: 'No DIB now' },
    ],
  });

  if (strugglingtobreathnow.abort) {
    return strugglingtobreathnow;
  }
  */

  const breathingchanged = await eChoice({
    id: 'coughChild.breathingchanged',
    label: "When did your child's breathing change?",
    clinicLabel: 'Breathing changed',
    options: [
      {
        label: 'Less than 24 hours ago',
        value: 'Lessthan24hoursago',
        keyFinding: 'Breathing changed <24 hours',
        warn: true,
        urgent: true,
      },
      {
        label: '1–7 days ago',
        value: '1–7daysago',
        keyFinding: 'Breathing changed 1–7 days ago',
        warn: true,
        urgent: true,
      },
      {
        label: '1–4 weeks ago',
        value: '1–4weeksago',
        keyFinding: 'Breathing changed 1–4 weeks ago',
      },
      {
        label: 'More than 4 weeks ago',
        value: 'Morethan4weeksago',
        keyFinding: keyFindingLabelBreathingChangedMorethan4weeksago,
        hide: true,
      },
    ],
  });

  if (breathingchanged === 'Morethan4weeksago') {
    await eText({
      id: 'coughChild.breathingchanged',
      label: "When exactly did your child's breathing change?",
      clinicLabel: 'Breathing changed',
    });
  }

  const whenischildstrugglingtobreath = await eChoice({
    id: 'coughChild.whenischildstrugglingtobreath',
    label: 'When is your child struggling to breath?',
    clinicLabel: 'When is child struggling to breath',
    isMultipleChoice: true,
    options: [
      {
        label: 'Morning time',
        value: 'Inthemorning',
        keyFinding: 'morning',
        warn: true,
      },
      {
        label: 'Evening time',
        value: 'Intheevening',
        keyFinding: 'evening',
        warn: true,
      },
      {
        label: 'Running around / exercising',
        value: 'exertion',
        keyFinding: 'On exertion',
        warn: true,
      },
      {
        label: 'During a coughing attack',
        value: 'Whenhavingcoughingattacks',
        keyFinding: 'During coughing bouts',
        warn: true,
      },
      { label: 'Other', value: 'Other', hide: true },
    ],
  });

  if (Array.isArray(whenischildstrugglingtobreath)) {
    scores.increase(
      'airways',
      union(whenischildstrugglingtobreath, ['morning', 'evening', 'exertion'])
        .length
    );

    if (whenischildstrugglingtobreath.includes('Other')) {
      await eText({
        id: 'coughChild.whenischildstrugglingtobreath',
        label: 'When is your child struggling to breathe?',
        clinicLabel: 'When is child struggling to breath',
        warn: true,
      });
    }
  }

  await eChoice({
    id: 'coughChild.breathingaffectedbyblockednose',
    label: 'Is your child struggling to breath because of a blocked nose?',
    clinicLabel: 'Breathing affected by blocked nose',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Breathing affected by blocked nose',
        ds: ds.blockedNose,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Breathing not affected by blocked nose',
      },
    ],
  });

  return {};
}: Guide);

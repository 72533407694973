import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import ds from './decisionSupport';
import aborts from './aborts';
import activityLevelChild from '../partials/triage/activityLevelChild';
import moduleCoronaScreening from '../modules/coronaScreening';

export const keyFindingLabelVomitingNotKeepFluids =
  'Vomiting and unable to keep fluids down';
export const keyFindingLabelVomitingKeepFluids =
  'Vomiting but able to keep fluids down';

export default (async args => {
  const {
    ask,
    patient: { age },
    decisionSupport,
    scores,
  } = args;

  const eChoice = createExtendedChoice(args);
  const bumpWarningScore = () => {
    scores.increase('warning');
  };

  await ask.info(
    'Before we begin, we need to ask a few questions to make sure it is safe to treat your child online...'
  );

  const coronaVirusResult = await moduleCoronaScreening(args);
  if (coronaVirusResult.abort) {
    return coronaVirusResult;
  }

  /*
  if (gender === 'female' && age > 13) {
    const pregnant = await eChoice({
      id: 'coughChild.pregnant',
      label: 'Is your child pregnant or breastfeeding?',
      clinicLabel: 'Pregnant',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          keyFinding: 'Pregnant or breastfeeding',
          warn: true,
          urgent: true,
          abort: aborts.pregnantOrBreastfeedingChild,
        },
        {
          label: 'No',
          value: 'no',
          keyFinding: 'Not pregnant or breastfeeding',
          hide: true,
        },
      ],
    });
    if (pregnant.abort) {
      return pregnant;
    }

    const breastfeeding = await eChoice({
      id: 'coughChild.breastfeeding',
      label: 'Is your child breastfeeding?',
      clinicLabel: 'Breastfeeding',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          keyFinding: 'Breastfeeding',
          warn: true,
          abort: aborts.pregnantOrBreastfeedingChild,
        },
        {
          label: 'No',
          value: 'no',
          keyFinding: 'Not breastfeeding',
          hide: true,
        },
      ],
    });
    if (breastfeeding.abort) {
      return breastfeeding;
    }
  }

  const immunecondition = await eChoice({
    id: 'coughChild.immunecondition',
    label:
      'Has your child been diagnosed with a condition, or are they taking medication that affects their immune system?',
    description: `E.g. Steroids, current cancer, chemotherapy, renal dialysis, a spleen that doesn't work, HIV, organ transplant`,
    clinicLabel: 'Immune condition',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Immune condition',
        warn: true,
        abort: aborts.ImmunosuppressedChild,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'No immune condition',
        hide: true,
      },
    ],
  });
  if (immunecondition.abort) {
    return immunecondition;
  }
*/

  const PMH = await eChoice({
    id: 'coughChild.PMH',
    label: 'Does your child have any of the following conditions?',
    clinicLabel: 'PMH',
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'Asthma',
        value: 'Asthma',
        keyFinding: 'Asthma',
        warn: true,
        abort: aborts.AsthmaChild,
      },
      {
        label: 'Previous or current cancer',
        value: 'Previousorcurrentcancer',
        keyFinding: 'Previous or current cancer',
        warn: true,
        abort: aborts.PMHChild,
      },
      {
        label: 'Cerebral Palsy',
        value: 'CerebralPalsy',
        keyFinding: 'Cerebral Palsy',
        warn: true,
        abort: aborts.PMHChild,
      },
      {
        label: 'Heart disease',
        value: 'Heartdisease',
        keyFinding: 'Heart disease',
        warn: true,
        abort: aborts.PMHChild,
      },
      {
        label: 'Cystic fibrosis',
        value: 'Cysticfibrosis',
        keyFinding: 'Cystic fibrosis',
        warn: true,
        abort: aborts.PMHChild,
      },
      {
        label: 'Kidney or liver disease',
        value: 'Kidneyorliverdisease',
        keyFinding: 'Kidney or liver disease',
        warn: true,
        abort: aborts.PMHChild,
      },
      {
        label: 'Sickle cell anaemia',
        value: 'Sicklecellanaemia',
        keyFinding: 'Sickle cell anaemia',
        warn: true,
        abort: aborts.PMHChild,
      },
    ],
  });
  if (PMH.abort) {
    return PMH;
  }

  const currentbreathing = await eChoice({
    id: 'coughChild.currentbreathing',
    label: "What is your child's breathing like right now?",
    clinicLabel: 'Current breathing',
    isMultipleChoice: true,
    options: [
      {
        label: 'Normal',
        value: 'normal',
        keyFinding: 'Normal breathing',
      },
      {
        label: 'Very fast',
        value: 'veryfast',
        keyFinding: 'Tachypnoeic',
        warn: true,
        abort: aborts.SevereDifficultyBreathing,
      },
      {
        label: 'Wheezing (making a whistling noise then they breathe)',
        value: 'wheezing',
        keyFinding: 'Wheeze',
        warn: true,
        abort: aborts.SevereDifficultyBreathing,
      },
      {
        label: 'Struggling to breathe',
        value: 'strugglingtobreathe',
        keyFinding: 'DIB',
        warn: true,
        abort: aborts.SevereDifficultyBreathing,
      },
      {
        label: 'Breathless when talking',
        value: 'breathlesswhentalking',
        keyFinding: 'Breathless when talking',
        warn: true,
        abort: aborts.SevereDifficultyBreathing,
      },
    ],
  });
  if (currentbreathing.abort) {
    return currentbreathing;
  }

  const respiratorydistressfeatures = await eChoice({
    id: 'coughChild.respiratorydistressfeatures',
    label: 'Does your child have any of the following?',
    clinicLabel: 'Respiratory distress features',
    optional: true,
    isMultipleChoice: true, // TODO: Not according to styrdoc but I think it's missed there, check with Nick
    options: [
      {
        label: 'Grunting',
        value: 'Grunting',
        keyFinding: 'Grunting',
        warn: true,
        abort: aborts.SevereDifficultyBreathing,
      },
      {
        label: 'Sucking in at ribcage',
        value: 'Suckinginatribcage',
        keyFinding: 'Intercostal recession',
        warn: true,
        abort: aborts.SevereDifficultyBreathing,
      },
    ],
  });

  if (respiratorydistressfeatures.abort) {
    return respiratorydistressfeatures;
  }

  let signsofsepsisOptions = [
    {
      label: 'A new rash which does not fade when rolling a glass over it',
      value: 'Anewrashwhichdoesnotfadewhenrollingaglassoverit',
      keyFinding: 'Non blanching rash',
      warn: true,
      abort: aborts.Sepsis,
    },
    {
      label: 'Neck stiffness',
      value: 'Neckstiffness',
      keyFinding: 'Neck stiffness',
      warn: true,
      abort: aborts.Sepsis,
    },
    {
      label: 'Sensitivity to bright lights',
      value: 'Sensitivitytobrightlights',
      keyFinding: 'Photosensitivity',
      warn: true,
      abort: aborts.Sepsis,
    },
    {
      label: 'Fainting episode or almost fainted',
      value: 'Faintingepisodeoralmostfainted',
      keyFinding: 'Fainted or almost fainted',
      warn: true,
      abort: aborts.Sepsis,
    },
    {
      label: 'Lip or tongue swelling',
      value: 'liportongueswelling',
      keyFinding: 'Lip or tongue swelling',
      warn: true,
      abort: aborts.AnaphylaxisChild,
    },
  ];

  if (age < 0.25) {
    signsofsepsisOptions.push({
      label:
        'A high fever (more than 38 degrees) which is not coming down with medication',
      value: 'Ahighfever(morethan38degrees)whichisnotcomingdownwithmedication',
      keyFinding: 'Temp > 38 degrees',
      warn: true,
      abort: aborts.Sepsis,
    });
  } else if (age < 0.5) {
    signsofsepsisOptions.push({
      label:
        'A high fever (more than 39 degrees) which is not coming down with medication',
      value: 'Ahighfever(morethan39degrees)whichisnotcomingdownwithmedication',
      keyFinding: 'Temp > 39 degrees',
      warn: true,
      abort: aborts.Sepsis,
    });
  }

  const signsofsepsis = await eChoice({
    id: 'coughChild.signsofsepsis',
    label: 'Does your child have any of the following?',
    clinicLabel: 'Signs of sepsis',
    isMultipleChoice: true,
    optional: true,
    options: signsofsepsisOptions,
  });
  if (signsofsepsis.abort) {
    return signsofsepsis;
  }

  const skincolour = await eChoice({
    id: 'coughChild.skincolour',
    label: `What does your child's skin look like?`,
    clinicLabel: 'Skin colour',
    options: [
      { label: 'Normal', value: 'Normal', keyFinding: 'Normal skin' },
      {
        label: 'Pale',
        value: 'Pale',
        keyFinding: 'Pale skin',
        warn: true,
      },
      {
        label: 'Blotchy (mottled)',
        value: 'Blotchy(mottled)',
        keyFinding: 'Mottled skin',
        warn: true,
        abort: aborts.Sepsis,
      },
      {
        label: 'Grey',
        value: 'Grey',
        keyFinding: 'Grey skin',
        warn: true,
        abort: aborts.Sepsis,
      },
      {
        label: 'Blue',
        value: 'Blue',
        keyFinding: 'Blue skin',
        warn: true,
        abort: aborts.Sepsis,
      },
    ],
  });
  if (skincolour.abort) {
    return skincolour;
  }

  if (skincolour === 'Pale') {
    if (age < 5) {
      decisionSupport.push(ds.paleskinSmallChild);
    }
    /*
    await eChoice({
      id: 'coughChild.normallyhaspaleskin',
      label: 'Does your child normally have pale skin?',
      clinicLabel: 'Normally has pale skin',
      options: [
        { label: 'Yes', value: 'yes', keyFinding: 'Normally has pale skin' },
        {
          label: 'No',
          value: 'no',
          keyFinding: 'Doesnt normally have pale skin',
          warn: true,
          urgent: true,
        },
      ],
    });
    */
  }

  const activityLevelChildResult = await activityLevelChild(args);
  if (activityLevelChildResult.abort) {
    return activityLevelChildResult;
  }

  const vomitingandunabletokeepfluidsdown = await eChoice({
    id: 'coughChild.vomitingandunabletokeepfluidsdown',
    label: 'Is your child vomiting?',
    clinicLabel: 'Vomiting and unable to keep fluids down',
    options: [
      {
        label: 'Yes, and they are unable to keep fluids down',
        value: 'yesUnableKeepFluids',
        keyFinding: keyFindingLabelVomitingNotKeepFluids,
        warn: true,
        abort: aborts.Sepsis,
      },
      {
        label: 'Yes, but they are able to keep fluids down. ',
        value: 'yesButAbleKeepFluids',
        keyFinding: keyFindingLabelVomitingKeepFluids,
        onSelected: bumpWarningScore,
      },
      { label: 'No', value: 'no', keyFinding: 'Able to keep fluids down' },
    ],
  });

  if (vomitingandunabletokeepfluidsdown.abort) {
    return vomitingandunabletokeepfluidsdown;
  }

  await ask.choice({
    label: 'Vomiting can be sign of a serious illness',
    description: `
Your child needs urgent medical attention if they are vomiting and are:
- Very unwell
- Feeding/drinking less than half of normal
- Feverish
- Not breathing normally
- Not passing urine every 4 hours

If it is outside of normal working hours, or it is the weekend, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.
`,
    options: [{ label: 'I understand', value: 'yes' }],
  });

  await eChoice({
    id: 'coughChild.diarrhoea',
    label: 'Does your child have diarrhoea?',
    clinicLabel: 'Diarrhoea',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Diarrhoea',
        warn: true,
        urgent: true,
        ds: ds.diarrhoea,
      },
      { label: 'No', value: 'no', keyFinding: 'No diarrhoea' },
    ],
  });

  const lastpassedurine = await eChoice({
    id: 'coughChild.lastpassedurine',
    label: 'When did they last wee?',
    clinicLabel: 'Last passed urine',
    options: [
      {
        label: 'Less than 4 hours ago',
        value: 'Lessthan4hoursago',
        keyFinding: 'Last passed urine < 4hours ago',
      },
      {
        label: 'Between 4 to 8 hours ago',
        value: 'Between4to8hoursago',
        keyFinding: 'Passed urine 4-8 hours ago',
        urgent: true,
        ds: ds.dehydration,
      },
      {
        label: 'More than 8 hours ago',
        value: 'Morethan8hoursago',
        keyFinding: 'Passed urine > 8 hours ago',
        warn: true,
        abort: aborts.DehydrationChild,
      },
    ],
  });

  if (lastpassedurine.abort) {
    return lastpassedurine;
  }

  const fluidintakeinlast24hours = await eChoice({
    id: 'coughChild.fluidintakeinlast24hours',
    label: 'How much has your child drank in the last 24 hours?',
    clinicLabel: 'Fluid intake in last 24 hours',
    options: [
      {
        label: 'Normal or slightly less then normal',
        value: 'Normalorslightlyless',
        keyFinding: 'Normal or slightly less oral intake in last 24 hours',
      },
      {
        label: 'Less than half of normal',
        value: 'Lessthanhalfofnormal',
        keyFinding: 'Oral intake less than half of normal',
        warn: true,
        abort: aborts.DehydrationChild,
      },
      {
        label: 'Nothing in the last 24 hours',
        value: 'Nothinginthelast24hours',
        keyFinding: 'No oral intake in last 24 hours',
        warn: true,
        abort: aborts.DehydrationChild,
      },
    ],
  });

  if (fluidintakeinlast24hours.abort) {
    return fluidintakeinlast24hours;
  }

  await ask.choice({
    label: `Dehydration can be a sign of a serious illness`,
    description: `
Your child needs urgent medical attention if they are not drinking and are:
- Very unwell
- Vomiting and not able to keep fluids down
- Feverish
- Not passing urine every 4 hours

If it is outside of normal working hours, or if it is the weekend, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.
`,
    options: [{ label: 'I understand', value: 'agree' }],
  });

  const potentialswallowedforeignbody = await eChoice({
    id: 'coughChild.potentialswallowedforeignbody',
    label:
      'Could your child have swallowed something which is making them cough?',
    clinicLabel: 'Potential swallowed foreign body',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Possible foreign body swallowed',
        warn: true,
        abort: aborts.FOB,
      },
      { label: 'No', value: 'no', keyFinding: 'No foreign body swallowed' },
    ],
  });

  if (potentialswallowedforeignbody.abort) {
    return potentialswallowedforeignbody;
  }
  return {};
}: Guide);

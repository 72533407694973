import type { Guide } from '../../types';

import triage from './triage';

export default (async args => {
  const { ask, significant } = args;

  const problemDescribe = {
    type: 'text',
    id: 'problem',
    label: 'Vad har du för besvär eller vad önskar du hjälp med?',
  };
  const problemAnswer = await ask(problemDescribe);

  significant.push({
    ...problemDescribe,
    label: 'Vilka besvär, önskar hjälp med',
    value: problemAnswer,
  });

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) return { abort: triageAbort };

  const healthProblems = await ask({
    label: 'Har du några hälsoproblem eller sjukdomar?',
    description: 'T.ex. hjärtfel, diabetes, astma.',
    id: 'healthProblems',
  });

  significant.push({
    label: 'Har kända hälsoproblem eller sjukdomar',
    value: healthProblems,
  });

  if (healthProblems === 'yes') {
    const healthProblemsWhich = await ask.text({
      label: 'Ange dina hälsoproblem eller sjukdomar.',
      id: 'healthProblemsWhich',
    });

    significant.push({
      type: 'text',
      label: 'Beskrivning av kända hälsoproblem eller sjukdomar',
      value: healthProblemsWhich,
    });
  }

  const medicationAny = await ask({
    label: 'Använder du några läkemedel för tillfället?',
    id: 'medicationAny',
  });

  significant.push({
    label: 'Tar läkemedel för tillfället',
    value: medicationAny,
  });

  if (medicationAny === 'yes') {
    const medicationWhich = await ask.text({
      label: 'Vilka läkemedel tar du?',
      description:
        'Ange läkemedel, styrka och dos, t.ex. Levaxin, 100 mikrogram, 1 tablett 1 gång/dag. Om du vet av vilken anledning du tar läkemedlet, ange detta.',
      id: 'medicationWhich',
    });

    significant.push({
      type: 'text',
      label: 'Beskrivning av läkemedel',
      value: medicationWhich,
    });
  }

  const otherCareContact = await ask({
    label: 'Har du någon annan pågående vårdkontakt?',
    id: 'otherCareContact',
  });

  significant.push({
    label: 'Pågående vårdkontakt',
    value: otherCareContact,
  });

  if (otherCareContact === 'yes') {
    const careContactDescription = await ask.text({
      label: 'Beskriv din pågående vårdkontakt',
      id: 'careContactDescription',
    });

    significant.push({
      type: 'text',
      label: 'Pågående vårdkontakt - Beskrivning',
      value: careContactDescription,
    });
  }

  const other = await ask.text({
    label: 'Finns det något ytterligare som du vill att psykologen ska veta?',
    id: 'other',
    optional: true,
  });

  significant.push({
    type: 'text',
    label: 'Övrig information',
    value: other,
  });

  await ask.info(``, {
    description: `
Du har nu besvarat frågor som ger psykologen information om vad du önskar hjälp med.

Om du upplever att du försämras innan du får kontakt bör du söka vård där du bor. Är du osäker på vart du bör vända dig, ring sjukvårdsrådgivningen, telnr. 1177.

Ring alltid 112 om du behöver omedelbar hjälp. 
    `,
    optional: true,
    id: 'infoText',
  });

  return {};
}: Guide);

// @flow
export const union = (
  valuesA: Array<mixed>,
  valuesB: Array<mixed>
): Array<mixed> => valuesA.filter(val => valuesB.includes(val));
export const matchOne = (
  valuesA: Array<mixed>,
  valuesB: Array<mixed>
): boolean => union(valuesA, valuesB).length >= 1;
export const matchAll = (
  valuesA: Array<mixed>,
  valuesB: Array<mixed>
): boolean =>
  union(valuesA, valuesB).length === Math.min(valuesA.length, valuesB.length);
export const matchAnyOtherThan = (
  valuesA: Array<mixed>,
  valuesB: Array<mixed>
): boolean => valuesA.filter(val => !valuesB.includes(val)).length >= 1;
// $FlowFixMe Array vs $ReadOnlyArray https://github.com/facebook/flow/issues/7685
export const ensureArray = (value: mixed | Array<mixed>): Array<mixed> =>
  Array.isArray(value) ? value : [value];

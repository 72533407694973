import type { Guide } from '../../types';

import aborts from './aborts';
import ds from './decisionSupport';

export default (async ({ ask, decisionSupport, significant, exports }) => {
  const keyFinding = value => exports.push({ id: 'keyFindings', value });
  decisionSupport.push(ds.medicationReview);

  await ask.choice({
    label:
      'We will now do a medication review. This should take less than 5 minutes.',
    options: ['Continue'],
  });

  // TODO: a bit of a special hack to display a header. Be warned!
  significant.push({
    type: 'text',
    value: 'Medication review'.toUpperCase(),
  });

  const amountOptions = {
    '1To4': '1 - 4',
    '5OrMore': '5 or more',
  };
  const amount = await ask.choice(
    "How many repeat medications from your child's repeat list are you requesting today?",
    { id: 'review.amount', options: amountOptions }
  );
  if (amount === '5OrMore') {
    significant.push({
      type: 'choice',
      label: 'Number of repeat medications needed',
      options: amountOptions,
      value: amount,
      warn: true,
    });
    return { abort: aborts.polypharmacy };
  }

  const knowWhyQuestion = {
    type: 'choice',
    options: {
      yesAll: 'Yes, all of them.',
      yesSome: 'Yes, some of them.',
      no: 'No',
    },
  };
  const knowWhy = await ask({
    ...knowWhyQuestion,
    id: 'review.knowWhy',
    label: 'Do you know why your child is using these medications?',
  });
  significant.push({
    ...knowWhyQuestion,
    label: 'Does patient know why they are taking repeat medication?',
    value: knowWhy,
    warn: 'no',
  });
  if (['yesAll', 'yesSome'].includes(knowWhy)) {
    const whyOptions = {
      mentalHealth: 'Mental Health',
      asthma: 'Asthma',
      allergy: 'Allergy condition e.g. hayfever',
      heartburn: 'Heartburn/Indigestion',
      eczema: 'Skin Condition e.g. eczema',
      digestive: 'Digestive problems e.g. constipation, IBS',
      diabetes: 'Diabetes',
      adhd: 'ADHD',
      epilepsy: 'Epilepsy',
      other: 'Other',
    };
    const why = await ask.multipleChoice(
      'Does your child use the medications for any of these reasons?',
      {
        id: 'review.why1',
        description: 'More than one answer can be selected.',
        options: whyOptions,
        optional: true,
      }
    );
    if (why.includes('mentalHealth')) {
      decisionSupport.push(ds.mentalHealth);
    }
    if (why.includes('asthma')) {
      decisionSupport.push(ds.asthma);
    }
    if (why.includes('diabetes')) {
      decisionSupport.push(ds.diabetes);
    }
    if (why.includes('adhd')) {
      decisionSupport.push(ds.adhd);
    }
    why.forEach(value => keyFinding(whyOptions[value]));

    const value = [...why].filter(key => key !== 'other');
    if (value) {
      significant.push({
        type: 'multipleChoice',
        label: 'Reason for taking repeat medication',
        options: { ...whyOptions },
        value,
        warn: value.includes('mentalHealth'),
      });
    }

    if (why.includes('other')) {
      significant.push({
        type: 'text',
        label: 'Reason for taking repeat medication',
        value: await ask.text('Please give details.', {
          id: 'review.why.other',
        }),
      });
    }
  }

  if (['yesSome', 'no'].includes(knowWhy)) {
    const description = `
# You mentioned you are unsure about the reasons why your child is taking some medications.

# Please list those medications here.
    `;
    significant.push({
      type: 'text',
      description: 'Pt is unsure why they are taking these medications',
      value: await ask.text({ id: 'review.unsure.list', description }),
    });
  }

  const matchLabel = 'Does your child have any problems with their medication?';
  const match = await ask(matchLabel);
  if (match === 'no') {
    significant.push({
      label: matchLabel,
      value: match,
    });
  }

  if (match === 'yes') {
    const problemsOptions = {
      doNotWant: "Doesn't want to take/use it",
      sideEffects: 'Side effects',
      doesntWork: `It doesn't work`,
      difficultSwallow: 'Difficult to swallow',
      useTooOften: 'Have to use too often',
      forget: 'We often forget to give the medication / take the medication.',
      other: 'Other',
    };
    const problems = await ask.multipleChoice(
      'Please tell us about the problems.',
      {
        id: 'review.problems',
        description: 'More than one answer can be selected.',
        options: problemsOptions,
      }
    );
    if (problems.includes('other')) {
      significant.push({
        id: 'review.problems.other',
        type: 'text',
        label: 'Details of other problem with medication',
        value: await ask.text('Please specify.'),
        warn: true,
      });
    } else {
      significant.push({
        type: 'multipleChoice',
        label: 'Do you have any problems with your medication?',
        options: problemsOptions,
        value: problems,
        warn:
          problems.includes('difficultSwallow') ||
          problems.includes('useTooOften'),
      });
    }
    if (problems.includes('sideEffects')) {
      significant.push({
        id: 'review.problems.sideEffects',
        type: 'text',
        label: 'Side effects?',
        value: await ask.text(
          'Please list the medication and the side effect.'
        ),
        warn: true,
      });

      const options = {
        yes: 'Yes',
        yesReduced:
          'Yes, if it is possible to reduce the dose or change the frequency that they take the medication',
        noOther: 'No, we want to try something else',
        no: 'No, we dont want medication',
      };
      const proceed = await ask.choice(
        'Is your child willing to continue taking this medication despite the side effects?',
        { id: 'review.problems.sideEffects.continue', options }
      );
      significant.push({
        type: 'choice',
        label: 'Is the patient willing to continue taking the medication?',
        options,
        value: proceed,
        warn: ['yesReduced', 'noOther', 'no'],
      });

      if (proceed === 'noOther') {
        const matchQuestion = {
          type: 'choice',
          options: {
            yes: 'Yes',
            no: 'No',
            doctorChoose: 'Let the doctor choose for us',
          },
        };
        const match = await ask({
          ...matchQuestion,
          label:
            'Do you know the name of the medication you would like to try?',
        });
        if (match === 'yes') {
          significant.push({
            type: 'text',
            label: 'Would patient want to try alternative?',
            value: await ask.text(
              'Please let us know what you would like to try instead.',
              {
                id: 'review.medication.wantToTry',
              }
            ),
            warn: true,
          });
        } else {
          significant.push({
            ...matchQuestion,
            label: 'Want to try alternative medication?',
            value: match,
          });
        }
      }
    }
    if (problems.includes('doesntWork')) {
      const whyOptions = {
        notImproved: `Condition has not improved`,
        symptomsRemain: `Symptoms have improved but they haven't gone away.`,
        alternative: 'I have heard of a better alternative',
        other: 'Other',
      };
      const why = await ask.multipleChoice(
        `Why do you think the medication doesn't work?`,
        {
          id: 'review.doesntWork.why',
          options: whyOptions,
          description: 'More than one answer can be selected.',
        }
      );
      significant.push({
        type: 'multipleChoice',
        label: 'Why does the pt think the medication does not work.',
        options: whyOptions,
        value: why,
        warn: ['notImproved', 'symptomsRemain'],
      });
      if (why.includes('other')) {
        significant.push({
          type: 'text',
          label: 'Reason why repeat medication does not work',
          value: await ask.text(
            'What is the other reason why the medication does not work?',
            {
              id: 'review.doesntWork.why.other',
            }
          ),
          warn: true,
        });
      }
      if (
        ['notImproved', 'symptomsRemain'].filter(key => why.includes(key))
          .length > 0
      ) {
        significant.push({
          type: 'text',
          label: `Patient's ongoing symptoms`,
          value: await ask.text(
            `Tell us about the symptoms that have not gone away.`,
            {
              id: 'review.ongoingSymptoms',
            }
          ),
          warn: true,
        });

        const photoNeeded = await ask(
          `Do you need to show the doctor a photo to better explain the symptoms?`
        );
        if (photoNeeded === 'yes') {
          const photos = await ask.upload('Please upload at least one photo.', {
            id: 'review.ongoingSymptoms.photos',
            description:
              "Rest assured, your child's photos will be kept confidential and will only be added to your child's medical record.",
          });
          significant.push({
            type: 'upload',
            label: 'Photo from pt regarding their ongoing symptoms',
            value: photos,
          });
        }
      }
      if (why.includes('alternative')) {
        significant.push({
          type: 'text',
          label: 'Medication alternative pt prefers',
          value: await ask.text(
            'Tell us about the alternative you would prefer.',
            {
              id: 'review.medication.alternative',
            }
          ),
          warn: true,
        });
      }
    }
    if (problems.includes('forget')) {
      significant.push({
        type: 'text',
        label: 'Reasons for forgetting to take medication',
        value: await ask.text(
          'Why does your child forget to take their medication?',
          {
            id: 'review.forget.why',
          }
        ),
        warn: true,
      });
    }
  }

  await ask.choice({
    label: `Thank you for providing us with your child's medication requirement. The information will be reviewed by a Docly GP and we will be in touch shortly.`,
    options: ['Continue'],
  });

  return {};
}: Guide);

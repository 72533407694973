const sorry = 'Unfortunately, we cannot treat your child online.';
const thanks = 'Thank you for using Docly.';
const getBetter = ' We hope your child feels better soon.';
const bookGP = '**Please book an appointment with a GP.**';
const urgent = 'Please **take your child to A&E or call 999 immediately**.';
const callSupport = 'Please call our support team on 020 3995 4945';
const urgentAppointment = '**urgent GP appointment**';
const immediateHelp =
  'If your child needs immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E.';

export default {
  pmh: {
    description: `
  ${sorry}

  **It is best for your child to see a GP face to face due to their medical illness.**
    
  ${bookGP} 
    
  If your GP surgery is closed and you need immediate help, call 111 for urgent medical care.
  For emergencies call 999 or go to A&E.
    
  ${thanks}
    `,
  },

  sepsis: {
    description: `
  ${sorry}

  **Your child needs emergency care — this is because they may have a serious infection.**
    
  ${urgent}
    
  ${thanks} ${getBetter}
    `,
  },

  fever: {
    description: `
  ${sorry}

  **Your child needs to be seen urgently — this is because they have a fever**
    
  ${callSupport} and ask for an ${urgentAppointment}.

  If your child has any of the symptoms below, then please call 111 for ugent medical care. For emergencies call 999 or go to A&E.

  - A red, sore, rash which is spreading
  - Feeding/drinking less than half of normal
  - Vomiting and not able to keep fluids down
  - Not breathing normally
  - Not passing urine every 4 hours
  - Very unwell


  ${thanks} ${getBetter}
    `,
  },

  anaphylaxis: {
    description: `
  ${sorry}

  **Your child needs emergency care — this is because they may be having an allergic reaction.**
    
  ${urgent}
    
  ${thanks} ${getBetter}
    `,
  },

  dehydrationUrgent: {
    description: `
  ${sorry}

  **Your child needs to be seen urgently — this is because they may be dehydrated.**
    
  ${callSupport} and ask for an ${urgentAppointment}. Please quote 'possible dehydration' when booking this appointment.
    
  ${immediateHelp}
    
  ${thanks} ${getBetter}
    `,
  },

  dehydration: {
    description: `
  ${sorry}

  **Your child needs emergency care — this is because they may be dehydrated.**
    
  ${urgent}
    
  ${thanks} ${getBetter}
    `,
  },

  feverUrgent: {
    description: `
  ${sorry}

  **Your child needs to be seen urgently — this is because they have a fever**
    
  ${callSupport} and ask for an ${urgentAppointment}. Please quote 'fever' when booking this appointment.
    
  ${immediateHelp}
    
  ${thanks} ${getBetter}
    `,
  },

  potentialCellulitis: {
    description: `
  ${sorry}

  **Your child needs to be seen urgently — this is because they may have a serious infection**
    
  ${callSupport} and ask for an ${urgentAppointment}. Please quote 'potential skin infection' when booking this appointment.
    
  ${immediateHelp}
    
  ${thanks} ${getBetter}
    `,
  },

  nappyRashInfection: {
    description: `
  Your child needs to be seen **urgently** as their **nappy rash may be infected**

  ${callSupport} and ask for an ${urgentAppointment}. Please quote 'potential infected nappy rash' when booking this appointment.
    
  ${immediateHelp}
    
  ${thanks}
    `,
  },

  eczema: {
    description: `
  Your child needs to be seen by the GP to be examined.

  ${callSupport} and ask for an ${urgentAppointment}.
    
  ${immediateHelp}
    
  ${thanks}
    `,
  },

  potentialImpetigo: {
    description: `
  Your child needs to be seen **urgently** as they may have a **skin infection**

  ${callSupport} and ask for an ${urgentAppointment}.
   
  ${immediateHelp}
  
  ${thanks}
    `,
  },
};

const decisionSupport = {
  isCyclic: {
    label: 'Överväg behandling',
    description:
      'Patientens besvär är premenstruella men symptomkriterier för PMDS uppfylls ej. Behandling mot PMDS kan dock övervägas.',
    color: 'black',
  },
  noPMDS: {
    label: 'Ej PMDS',
    description:
      'Patientens besvär är ej premenstruella och patienten uppfyller ej symptomkriterier för PMDS. Överväg annan fysisk eller psykisk sjukdom',
    color: 'black',
  },
  isPMDS: {
    label: 'Sannolikt PMDS',
    description: 'Patientens symptom talar för PMDS.',
    color: 'black',
  },
};

// $FlowFixMe
Object.keys(decisionSupport).forEach(id => (decisionSupport[id].id = id));

export default decisionSupport;

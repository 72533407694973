import type { Guide } from './../../types';

import summaryExports from './summaryExports';
import createExtendedText from '../common/extendedText';
import createExtendedChoice from '../common/extendedChoice';

// TODO this is obsolete - it needs removing (an in the db etc)
export default (async args => {
  const { ask, exports } = args;

  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  summaryExports(exports);

  await ask.choice({
    description: `
This service is for advice about your test and the result only.
If you need help with symptoms, or are unwell, please call your GP. 

**Call 111 for urgent care, or 999 for emergencies.**
`,
    options: ['I understand'],
  });

  await eText({
    id: 'coronaTestReturnVisit.howCanWeHelp',
    label: 'How can we help?',
  });

  await eChoice({
    id: 'coronaTestReturnVisit.answerPreference',
    label: 'How would you prefer to get your answer?',
    options: [
      {
        label: 'Message (written)',
        value: 'written',
      },
      {
        label: 'Phone call',
        value: 'phone',
      },
      {
        label: 'Video call',
        value: 'video',
      },
    ],
  });

  return {};
}: Guide);

import React, { useEffect, useRef } from 'react';

// $FlowFixMe
import useSWR from 'swr';

// $FlowFixMe
import hljs from 'highlight.js';
// $FlowFixMe
import js from 'highlight.js/lib/languages/javascript';
// $FlowFixMe
import 'highlight.js/styles/night-owl.css';

import { makeStyles } from '@material-ui/core/styles';

const _fetch = async (...args) => {
  const res = await fetch(...args);
  return await res.text();
};

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '60px auto',
    backgroundColor: '#011627',
    color: 'rgb(110, 120, 136)',
  },
  lineNumbersWrapper: {
    padding: '0.5em',
    textAlign: 'right',
  },
  lineNumbers: {
    display: 'block',
    fontSize: 14,
  },
  pre: {
    margin: 0,
    paddiing: 0,
    fontSize: 14,
  },
});

function Source(props: *) {
  const codeRef = useRef(null);
  const root =
    process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL : '';

  const { data, error } = useSWR(
    `${root || ''}/static/sources/${props.guideId}.inq`,
    _fetch
  );

  useEffect(() => {
    hljs.registerLanguage('javascript', js);
  }, []);

  useEffect(() => {
    if (data) {
      hljs.highlightBlock(codeRef.current);
    }
  }, [data]);

  const classes = useStyles(props);

  if (error) return <div>Failed to load source.</div>;
  if (!data) return null;

  return (
    <div className={classes.root}>
      <div className={classes.lineNumbersWrapper}>
        {data.split('\n').map((line, i) => {
          return <code className={classes.lineNumbers}>{i}</code>;
        })}
      </div>
      <pre className={classes.pre}>
        <code ref={codeRef} className="javascript">
          {data}
        </code>
      </pre>
    </div>
  );
}

export default Source;

import { type Action } from '../types';

import { type State, PATIENTS_UPDATE } from './types';

export default (
  state: State = { patient: { gender: 'female', age: 30, domain: '' } },
  action: Action
): State => {
  const { type, payload = {} } = action;

  switch (type) {
    case PATIENTS_UPDATE: {
      const { patient } = payload;

      return { ...state, patient };
    }

    default:
      return state;
  }
};

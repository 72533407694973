import type { Guide } from '../../types';

import aborts from './aborts';

export default (async args => {
  const { ask, significant } = args;

  await ask.choice({
    description: `
**Chest pain can be a sign of a serious illness.**

You need urgent medical attention if you:

- Feel very unwell
- Are struggling to breathe
- Feel faint or clammy

If it is outside of normal working hours, or it is the weekend, please go to to A&E or dial 999.
  `,
    options: ['I understand'],
  });

  const whenQuestion = {
    type: 'choice',
    options: {
      now: 'I have the pain now',
      lessThan24Hours: 'Less than 24 hours ago',
      moreThan24Hours: 'More than 24 hours ago',
    },
  };

  const when = await ask({
    ...whenQuestion,
    label: 'You mentioned you had chest pain, when did this last happen?',
  });

  if (when === 'now') {
    // TODO: abort chest pain NOW
    return { abort: aborts.acuteChestPainNow };
  }

  significant.push({
    ...whenQuestion,
    label: 'Chest Pain last there',
    value: when,
    warn: ['now', 'lessThan24Hours'],
  });

  const descriptorsQuestion = {
    type: 'multipleChoice',
    options: {
      burningSensation: 'Burning sensation',
      sharpOrStabbingPain: 'Sharp or stabbing pain',
      tight: 'Tight — like someone squeezing or sitting on me',
      weightOnChest: 'Like a weight on my chest',
      spasmInChest: 'Like a spasm in my chest',
      movedIntoNeckOrJaw: 'It moves into my neck or jaw',
      movesIntoArmOrShoulder: 'It moves into my arm or to my shoulder tip',
      tearingBackpain: 'A tearing pain going to my back',
    },
  };
  const descriptors = await ask({
    ...descriptorsQuestion,
    label: 'What does your chest pain feel like?',
    description: 'More than one answer can be selected',
  });
  significant.push({
    ...descriptorsQuestion,
    label: 'Type of chest pain',
    value: descriptors,
    warn: [
      'tight',
      'weightOnChest',
      'spasmInChest',
      'movedIntoNeckOrJaw',
      'movesIntoArmOrShoulder',
      'tearingBackpain:',
      'sharpOrStabbingPain',
    ],
  });

  const acuteChestPainScore = [
    'tight',
    'weightOnChest',
    'spasmInChest',
    'movedIntoNeckOrJaw',
    'movesIntoArmOrShoulder',
    'tearingBackpain:',
  ].reduce((total, key) => total + (descriptors.includes(key) ? 1 : 0), 0);
  if (acuteChestPainScore >= 1) {
    if (when === 'lessThan24Hours') {
      return { abort: aborts.acuteChestPain };
    } else if (when === 'moreThan24Hours') {
      return { abort: aborts.chestPain };
    }
  }

  const severityQuestion = {
    type: 'choice',
    options: {
      '1': '1 - Very mild pain, able to carry out normal day to day activities',
      '2':
        '2 - Mild pain, able to get on with my day, but with some difficulty',
      '3':
        '3 - Moderate pain, which is distracting, and makes day to day activities difficult',
      '4':
        '4 - Severe pain, need to lie down or sleep, cannot carry out normal activities',
      '5': '5 - Extreme pain, worst pain imaginable, unable to function',
    },
  };
  const severity = await ask({
    ...severityQuestion,
    label: 'How bad is your chest pain at its worst?',
  });
  significant.push({
    ...severityQuestion,
    label: 'Chest pain severity',
    value: severity,
    warn: ['3', '4', '5'],
  });
  if (severity === '4' || severity === '5') {
    return { abort: aborts.chestPain };
  }

  return {};
}: Guide);

import type { Guide } from '../../types';

import aborts from './aborts';
import ds from './decisionSupport';

export default (async args => {
  const { ask, significant, decisionSupport } = args;

  const frequencyQuestion = {
    type: 'choice',
    options: {
      hourly: 'Every few hours',
      daily: 'Once or twice a day',
      weekly: 'Once or twice a week',
      monthly: 'Once or twice a month',
    },
  };
  const frequency = await ask({
    ...frequencyQuestion,
    label: 'How often do you get your symptoms?',
  });
  significant.push({
    ...frequencyQuestion,
    label: 'Frequency of symptoms',
    value: frequency,
    warn: 'hourly',
  });

  const durationQuestion = {
    type: 'choice',
    options: {
      seconds: 'A few seconds',
      minutes: 'A few minutes',
      hours: 'A few hours',
      mostOfDay: 'Most of the day',
      longerThanDay: 'Longer than a day',
    },
  };
  const duration = await ask({
    ...durationQuestion,
    label: 'How long do your symptoms usually last?',
  });
  significant.push({
    ...durationQuestion,
    label: 'Duration of symptoms',
    value: duration,
    warn: 'longerThanDay',
  });

  const worseningFactorsQuestion = {
    type: 'multipleChoice',
    options: {
      foodOrDrink: 'After food or drink',
      takingMedication: 'After taking medication',
      lieDownOrSleep: 'When I lie down or sleep',
      bendForward: 'When I bend forward',
      exercise: 'During Exercise',
      largeOrRichMeal: 'After a large or rich meal',
      smokingOrAlcohol: 'After drinking alcohol or smoking',
      other: 'Other',
    },
  };
  const worseningFactors = await ask({
    ...worseningFactorsQuestion,
    label: 'When do you usually get your symptoms?',
    description: 'More than one answer can be selected',
  });
  if (
    worseningFactors.includes('lieDownOrSleep') ||
    worseningFactors.includes('bendForward') ||
    worseningFactors.includes('exercise') ||
    worseningFactors.includes('largeOrRichMeal') ||
    worseningFactors.includes('smokingOrAlcohol')
  ) {
    significant.push({
      ...worseningFactorsQuestion,
      label: 'Triggers of symptoms',
      value: worseningFactors,
      warn: 'longerThanDay',
    });
  }
  //   TODO: replace "Other" above with the result of the below "other" answer
  if (worseningFactors.includes('other')) {
    significant.push({
      type: 'text',
      label: 'Other triggers of symptoms',
      value: await ask.text(
        'Please tell us when you usually get your symptoms.'
      ),
    });
  }
  if (worseningFactors.includes('exercise')) {
    const exertionalSymptomsQuestion = {
      type: 'multipleChoice',
      options: {
        chestTightness: 'Chest tightness',
        shortnessOfBreath: 'Shortness of breath',
        dizziness: 'Dizziness',
        clammyOrSweaty: 'Feeling clammy or sweaty',
        sickNauseatedVomiting: 'Feeling sick, nauseated or vomiting',
        painNeckArmShoulders: 'Pain in your neck, arm or shoulders',
        noOtherSymptoms: 'No other symptoms',
      },
    };
    const exertionalSymptoms = await ask({
      ...exertionalSymptomsQuestion,
      label: 'Do you get any of these symptoms when you exercise?',
      description: 'More than one answer can be selected',
    });
    significant.push({
      ...exertionalSymptomsQuestion,
      // label: 'Exertional symptoms',
      label: 'Angina Red flag symptoms',
      value: exertionalSymptoms,
      warn: [
        'chestTightness',
        'shortnessOfBreath',
        'dizziness',
        'clammyOrSweaty',
        'sickNauseatedVomiting',
        'painNeckArmShoulders',
      ],
    });
    if (
      exertionalSymptoms.filter(key => key !== 'noOtherSymptoms').length > 0
    ) {
      return { abort: aborts.exercise };
    }
  }
  if (worseningFactors.includes('takingMedication')) {
    significant.push({
      type: 'text',
      label: 'Exacerbating medication',
      value: await ask.text(
        'Please tell us what medication makes your symptoms worse.'
      ),
      warn: true,
    });
    decisionSupport.push(ds.medicationTriggers);
  }

  const lifestyleChangesQuestion = {
    type: 'choice',
    options: {
      yesAndHelping: 'Yes, and it has helped my symptoms',
      yesNotHelping: 'Yes, and it has not helped my symptoms',
      no: 'No',
    },
  };
  const lifestyleChanges = await ask({
    ...lifestyleChangesQuestion,
    label: 'Have you made any changes to your lifestyle?',
  });
  significant.push({
    ...lifestyleChangesQuestion,
    label: 'Lifestyle changes made',
    value: lifestyleChanges,
  });

  const medicationQuestion = {
    type: 'multipleChoice',
    options: {
      renniesGavisconPeptacAcidex: 'Rennies, Gaviscon, Peptac or Acidex',
      ranitidine: 'Ranitidine (Zantac)',
      omeprazole: 'Omeprazole (Losec)',
      lansoprazole: 'Lansoprazole',
      pantoprazole: 'Pantoprazole (Pantoloc Control)',
      esomeprazole: 'Esomeprazole (Nexium)',
      antibiotics: 'Antibiotics (Triple therapy)',
      other: 'Other',
      none: 'No',
    },
  };
  const medication = await ask({
    ...medicationQuestion,
    label: 'Have you tried any medication to help with your symptoms?',
    description: 'More than one can be selected',
  });
  if (medication.filter(key => key !== 'other' && key !== 'none').length > 0) {
    significant.push({
      ...medicationQuestion,
      label: 'Medication tried previously',
      value: medication,
    });
  }
  if (medication.includes('none')) {
    // TODO: check whether pt. checked any meds _and_ the "no" option.
    decisionSupport.push(ds.noPreviousDyspepsiaMedication);
  }
  if (medication.includes('other')) {
    significant.push({
      type: 'text',
      label: 'Other medication tried previously',
      value: await ask.text('What other medications have you tried?'),
    });
  }
  const helpedQuestion = {
    type: 'choice',
    options: {
      helped: 'It helped with my symptoms',
      didntHelp: `It didn't help with symptoms`,
    },
  };
  if (medication.includes('ranitidine')) {
    const helped = await ask({
      ...helpedQuestion,
      label: 'What effect did the Ranitidine (Zantac) have?',
    });
    significant.push({
      ...helpedQuestion,
      label: 'Effect of Ranitidine',
      value: helped,
    });
  }
  if (medication.includes('omeprazole')) {
    const helped = await ask({
      ...helpedQuestion,
      label: 'What effect did the Omeprazole (Losec) have?',
    });
    significant.push({
      ...helpedQuestion,
      label: 'Effect of Omeprazole',
      value: helped,
    });
  }
  if (medication.includes('lansoprazole')) {
    const helped = await ask({
      ...helpedQuestion,
      label: 'What effect did the Lansoprazole have?',
    });
    significant.push({
      ...helpedQuestion,
      label: 'Effect of Lansoprazole',
      value: helped,
    });
  }
  if (medication.includes('pantoprazole')) {
    const helped = await ask({
      ...helpedQuestion,
      label: 'What effect did the Pantoprazole have?',
    });
    significant.push({
      ...helpedQuestion,
      label: 'Effect of Pantoprazole',
      value: helped,
    });
  }
  if (medication.includes('esomeprazole')) {
    const helped = await ask({
      ...helpedQuestion,
      label: 'What effect did the Esomeprazole have?',
    });
    significant.push({
      ...helpedQuestion,
      label: 'Effect of Esomeprazole',
      value: helped,
    });
  }
  if (medication.includes('antibiotics')) {
    const helped = await ask({
      ...helpedQuestion,
      label: 'What effect did the Antibiotics (triple therapy) have?',
    });
    significant.push({
      ...helpedQuestion,
      label: 'Effect of Triple Therapy',
      value: helped,
    });
  }

  const osteoporosis = await ask('Do you have osteoporosis (thin bones)?');
  significant.push({
    label: 'Osteoporosis',
    value: osteoporosis,
    warn: 'yes',
  });
  if (osteoporosis === 'yes') {
    decisionSupport.push(ds.previousOsteoporosis);
  }

  const otherMedicationQuestion = {
    type: 'multipleChoice',
    options: {
      clopidogrel: 'Clopidogrel (Plavix)',
      digoxin: 'Digoxin',
      warfarin: 'Warfarin',
    },
    optional: true,
  };
  const otherMedication = await ask({
    ...otherMedicationQuestion,
    label: 'Do you take any of the following medication?',
    description: 'More than one answer can be selected',
    optional: true,
  });
  if (otherMedication.length > 0) {
    significant.push({
      ...otherMedicationQuestion,
      label: 'Medications interacting with PPI',
      value: otherMedication,
      warn: true,
    });
  }
  if (otherMedication.includes('clopidogrel')) {
    decisionSupport.push(ds.onClopidogrel);
  }
  if (otherMedication.includes('digoxin')) {
    decisionSupport.push(ds.onDigoxin);
  }
  if (otherMedication.includes('warfarin')) {
    decisionSupport.push(ds.onWarfarin);
  }

  const penicillinAllergy = await ask('Are you allergic to penicillin?');
  significant.push({
    label: 'Penicillin Allergy',
    value: penicillinAllergy,
    warn: 'yes',
  });

  const antibioticsQuestion = {
    type: 'multipleChoice',
    options: {
      clarithromycin: 'Clarithromycin',
      metronidazole: 'Metronidazole',
      levofloxacin: 'Levofloxacin',
    },
    optional: true,
  };
  const antibiotics = await ask({
    ...antibioticsQuestion,
    label: 'Have you taken any of the following antibiotics in the last year?',
    description: 'More than one answer can be selected',
    optional: true,
  });
  if (antibiotics.length > 0) {
    significant.push({
      ...antibioticsQuestion,
      label: 'Previous antibiotic used in the last year',
      value: antibiotics,
      warn: true,
    });
  }

  return {};
}: Guide);

export default {
  breastfeeding: {
    id: 'breastfeeding',
    label: 'Breastfeeding',
    description: '',
    color: 'black',
  },
  previousKubStructuralDisease: {
    id: 'previousKubStructuralDisease',
    label: '?Previous KUB structural disease',
    description: '',
    color: 'black',
  },
  vaginalSymptoms: {
    id: 'vaginalSymptoms',
    label: 'Vaginal Symptoms',
    description: '',
    color: 'black',
  },
  previousUpperUti: {
    id: 'previousUpperUti',
    label: 'Previous Upper UTI',
    description: '',
    color: 'black',
  },
  lutsLessThan3: {
    id: 'lutsLessThan3',
    label: 'LUTS < 3',
    description: 'Diagnostic criteria for UTI not met',
    color: 'black',
  },
  luts3OrMore: {
    id: 'luts3OrMore',
    label: 'LUTS ≥ 3',
    description: 'UTI likely',
    color: 'black',
  },
  reviewPMH: {
    id: 'reviewPMH',
    label: 'Review PMH in clinical notes',
    description: '',
    color: 'black',
  },
  rejectHighFever: {
    id: 'rejectHighFever',
    label: 'Reject - Urgent Appointment',
    description: 'T ≥ 38°C ?Upper UTI',
    color: 'red',
  },
  rejectHaematuria: {
    id: 'rejectHaematuria',
    label: 'Reject - Urgent Appointment',
    description: 'Haematuria',
    color: 'red',
  },
  rejectGenitalBlisters: {
    id: 'rejectGenitalBlisters',
    label: 'Reject - Refer to Sexual Health Clinic',
    description: 'Genital Blisters',
    color: 'red',
  },
  rejectUrineCultureNeeded: {
    id: 'rejectUrineCultureNeeded',
    label: 'Reject - Urgent Appointment',
    description: 'Recurrent UTI - Urine culture needed',
    color: 'red',
  },
  rejectUpperUtiLast12MonthsUrineCultureNeeded: {
    id: 'rejectUpperUtiLast12MonthsUrineCultureNeeded',
    label: 'Reject - Urgent Appointment',
    description: 'Upper UTI in last 12 months - Urine culture needed',
    color: 'red',
  },
  rejectUrineDipstickNeeded: {
    id: 'rejectUrineDipstickNeeded',
    label: 'Reject - Urgent Appointment',
    description: 'LUTS < 3 Urine dipstick needed',
    color: 'red',
  },
  treatDelayedAntibioticsLessThan2Days: {
    id: 'treatDelayedAntibioticsLessThan2Days',
    label: 'Delayed prescription for antibiotics',
    description: 'Symptoms < 2 days',
    color: 'yellow',
  },
  treatWithAntibioticsLessThan2Days: {
    id: 'treatWithAntibioticsLessThan2Days',
    label: 'Treat with antibiotics',
    description: 'Symptoms < 2 days + T 38 C',
    color: 'yellow',
  },
  treatWithAdviceOnly: {
    id: 'treatWithAdviceOnly',
    label: 'Advice only',
    description: 'Symptoms < 2 days + LUTS < 3',
    color: 'yellow',
  },
  treatWithAntibiotics2To7Days: {
    id: 'treatWithAntibiotics2To7Days',
    label: 'Treat with antibiotics',
    description: 'Symptoms 2 - 7 days',
    color: 'yellow',
  },
  treatWithAntibioticsOver7Days: {
    id: 'treatWithAntibioticsOver7Days',
    label: '?Treat with antibiotics',
    description: 'Symptoms > 7 days',
    color: 'yellow',
  },
};

import * as React from 'react';

import { _t } from '../../i18n';

import Text from './Text';

export default class Number extends React.Component<{
  value?: *,
  error?: string,
  unit?: string,
  update: (?*) => *,
  done: (?*) => *,
}> {
  update = (value: *) => {
    const { update } = this.props;

    update(isNaN(parseInt(value, 10)) ? undefined : parseInt(value, 10));
  };

  render() {
    const { value, error, unit, done } = this.props;

    return (
      <Text
        {...{
          type: 'number',
          placeholder:
            unit !== undefined ? _t('input.unit', { unit }) : undefined,
          multiline: false,
          value,
          error,
          update: this.update,
          done,
        }}
      />
    );
  }
}

import * as React from 'react';
import classNames from 'classnames';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

const arrowIconSource = require('../../images/icons/ic_arrow_right_white.svg');

class Next extends React.Component<{
  label?: string,
  disabled?: boolean,
  onClick: () => *,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { label, onClick, disabled, classes, className } = this.props;

    return (
      <div className={classNames(classes.root, className)}>
        {label !== undefined && (
          <Typography onClick={onClick} className={classes.label}>
            {label}
          </Typography>
        )}

        <Fab color="primary" disabled={disabled} onClick={onClick}>
          <img alt="icon" src={arrowIconSource} className={classes.icon} />
        </Fab>
      </div>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  label: {
    fontWeight: 500,
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingRight: spacing(1.5),
    cursor: 'pointer',
  },

  icon: {
    height: 20,
  },
}))(Next);

import type { Guide } from '../../types';

export default (async ({ ask, significant }) => {
  const sideEffects = await ask(
    'Has your contraceptive caused any serious or unacceptable side effects?'
  );
  if (sideEffects === 'yes') {
    significant.push({
      label: 'Side effects?',
      value: sideEffects,
      warn: true,
    });

    significant.push({
      type: 'text',
      label: 'Side effects, description',
      value: await ask.text('What were they?'),
    });
  }

  return {};
}: Guide);

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const medicationNaturopathic = {
    type: 'binary',
    id: 'medication.naturopathic',
    label: 'Använder du några natur- eller hälsokostpreparat för tillfället?',
    expires: 180,
  };

  const medicationNaturopathicAnswer = await ask(medicationNaturopathic);

  significant.push({
    ...medicationNaturopathic,
    label: 'Använder natur- eller hälsokostpreparat för tillfället',
    value: medicationNaturopathicAnswer,
  });

  if (medicationNaturopathicAnswer === 'yes') {
    const medicationNaturopathicWhat = {
      type: 'text',
      id: 'medication.naturopathic.what',
      label: 'Vilka natur- eller hälsokostpreparat tar du?',
      expires: 180,
    };

    const medicationNaturopathicWhatAnswer = await ask(
      medicationNaturopathicWhat
    );

    significant.push({
      ...medicationNaturopathicWhat,
      label: 'Vilka natur- eller hälsokostpreparat',
      value: medicationNaturopathicWhatAnswer,
    });
  }

  return {};
}: Guide);

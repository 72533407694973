export default {
  id: 'physio',
  type: 'formulary',
  version: '4',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '18-oneYear',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: '3monthsTo12Months',
              label: 'För 3–12 månader sedan',
            },
            {
              value: 'overOneYear',
              label: 'För mer än 1 år sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började dina besvär?',
          index: '0.0.0',
        },
        {
          id: 'startedSpecific',
          version: '1',
          type: 'text',
          validations: {
            required: true,
            max: 250,
          },
          condition: "get('answers.started') === 'overOneYear'",
          label: 'För hur länge sedan började dina besvär?',
          index: '0.0.1',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'orthopedics.bodyParts.problems',
          version: '2',
          type: 'multipleChoice',
          validations: {
            required: true,
          },
          options: [
            {
              value: 'head',
              label: 'Huvud',
            },
            {
              value: 'nape',
              label: 'Nacke',
            },
            {
              value: 'thoracicSpine',
              label:
                'Bröstrygg (mellan skulderbladen och/eller mitten av ryggen)',
            },
            {
              value: 'lumbarSpine',
              label: 'Ländrygg/Ryggslut',
            },
            {
              value: 'shoulderRight',
              label: 'Axel (höger)',
            },
            {
              value: 'shoulderLeft',
              label: 'Axel (vänster)',
            },
            {
              value: 'upperArmRight',
              label: 'Överarm (höger)',
            },
            {
              value: 'upperArmLeft',
              label: 'Överarm (vänster)',
            },
            {
              value: 'elbowRight',
              label: 'Armbåge (höger)',
            },
            {
              value: 'elbowLeft',
              label: 'Armbåge (vänster)',
            },
            {
              value: 'forearmRight',
              label: 'Underarm (höger)',
            },
            {
              value: 'forearmLeft',
              label: 'Underarm (vänster)',
            },
            {
              value: 'wristRight',
              label: 'Handled (höger)',
            },
            {
              value: 'wristLeft',
              label: 'Handled (vänster)',
            },
            {
              value: 'handRight',
              label: 'Hand (höger)',
            },
            {
              value: 'handLeft',
              label: 'Hand (vänster)',
            },
            {
              value: 'fingersRight',
              label: 'Finger (höger hand)',
            },
            {
              value: 'fingersLeft',
              label: 'Finger (vänster hand)',
            },
            {
              value: 'hipRight',
              label: 'Höft (höger)',
            },
            {
              value: 'hipLeft',
              label: 'Höft (vänster)',
            },
            {
              value: 'groinRight',
              label: 'Ljumske (höger)',
            },
            {
              value: 'groinLeft',
              label: 'Ljumske (vänster)',
            },
            {
              value: 'buttockRight',
              label: 'Skinka (höger)',
            },
            {
              value: 'buttockLeft',
              label: 'Skinka (vänster)',
            },
            {
              value: 'thighRight',
              label: 'Lår (höger)',
            },
            {
              value: 'thighLeft',
              label: 'Lår (vänster)',
            },
            {
              value: 'kneeRight',
              label: 'Knä (höger)',
            },
            {
              value: 'kneeLeft',
              label: 'Knä (vänster)',
            },
            {
              value: 'shankRight',
              label: 'Underben (höger)',
            },
            {
              value: 'shankLeft',
              label: 'Underben (vänster)',
            },
            {
              value: 'ankleRight',
              label: 'Fotled (höger)',
            },
            {
              value: 'ankleLeft',
              label: 'Fotled (vänster)',
            },
            {
              value: 'footRight',
              label: 'Fot (höger)',
            },
            {
              value: 'footLeft',
              label: 'Fot (vänster)',
            },
            {
              value: 'toesRight',
              label: 'Tå (höger fot)',
            },
            {
              value: 'toesLeft',
              label: 'Tå (vänster fot)',
            },
          ],
          label: 'I vilka kroppsdelar har du besvär?',
          description:
            'Flera svarsalternativ möjliga. Om det område du har besvär i inte finns med i listan, välj det som ligger närmast.',
          index: '0.1.0',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'orthopedics.bodyParts.help',
          version: '2',
          type: 'choice',
          options: [
            {
              value: 'head',
              label: 'Huvud',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'head')",
            },
            {
              value: 'nape',
              label: 'Nacke',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'nape')",
            },
            {
              value: 'thoracicSpine',
              label:
                'Bröstrygg (mellan skulderbladen och/eller mitten av ryggen)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'thoracicSpine')",
            },
            {
              value: 'lumbarSpine',
              label: 'Ländrygg/Ryggslut',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'lumbarSpine')",
            },
            {
              value: 'shoulderRight',
              label: 'Axel (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shoulderRight')",
            },
            {
              value: 'shoulderLeft',
              label: 'Axel (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shoulderLeft')",
            },
            {
              value: 'upperArmRight',
              label: 'Överarm (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'upperArmRight')",
            },
            {
              value: 'upperArmLeft',
              label: 'Överarm (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'upperArmLeft')",
            },
            {
              value: 'elbowRight',
              label: 'Armbåge (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'elbowRight')",
            },
            {
              value: 'elbowLeft',
              label: 'Armbåge (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'elbowLeft')",
            },
            {
              value: 'forearmRight',
              label: 'Underarm (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'forearmRight')",
            },
            {
              value: 'forearmLeft',
              label: 'Underarm (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'forearmLeft')",
            },
            {
              value: 'wristRight',
              label: 'Handled (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'wristRight')",
            },
            {
              value: 'wristLeft',
              label: 'Handled (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'wristLeft')",
            },
            {
              value: 'handRight',
              label: 'Hand (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'handRight')",
            },
            {
              value: 'handLeft',
              label: 'Hand (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'handLeft')",
            },
            {
              value: 'fingersRight',
              label: 'Finger (höger hand)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'fingersRight')",
            },
            {
              value: 'fingersLeft',
              label: 'Finger (vänster hand)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'fingersLeft')",
            },
            {
              value: 'hipRight',
              label: 'Höft (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'hipRight')",
            },
            {
              value: 'hipLeft',
              label: 'Höft (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'hipLeft')",
            },
            {
              value: 'groinRight',
              label: 'Ljumske (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'groinRight')",
            },
            {
              value: 'groinLeft',
              label: 'Ljumske (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'groinLeft')",
            },
            {
              value: 'buttockRight',
              label: 'Skinka (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'buttockRight')",
            },
            {
              value: 'buttockLeft',
              label: 'Skinka (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'buttockLeft')",
            },
            {
              value: 'thighRight',
              label: 'Lår (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'thighRight')",
            },
            {
              value: 'thighLeft',
              label: 'Lår (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'thighLeft')",
            },
            {
              value: 'kneeRight',
              label: 'Knä (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'kneeRight')",
            },
            {
              value: 'kneeLeft',
              label: 'Knä (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'kneeLeft')",
            },
            {
              value: 'shankRight',
              label: 'Underben (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shankRight')",
            },
            {
              value: 'shankLeft',
              label: 'Underben (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shankLeft')",
            },
            {
              value: 'ankleRight',
              label: 'Fotled (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'ankleRight')",
            },
            {
              value: 'ankleLeft',
              label: 'Fotled (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'ankleLeft')",
            },
            {
              value: 'footRight',
              label: 'Fot (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'footRight')",
            },
            {
              value: 'footLeft',
              label: 'Fot (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'footLeft')",
            },
            {
              value: 'toesRight',
              label: 'Tå (höger fot)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'toesRight')",
            },
            {
              value: 'toesLeft',
              label: 'Tå (vänster fot)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'toesLeft')",
            },
          ],
          condition:
            "get('answers.orthopedics.bodyParts.problems') && (get('answers.orthopedics.bodyParts.problems')).length > 1",
          validations: {
            required: true,
          },
          label: 'Ange i vilken kroppsdel ditt huvudsakliga besvär sitter.',
          description:
            'Vi ber dig att ha denna kroppsdel i åtanke när du besvarar resten av frågorna.',
          index: '0.2.0',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'lumbago.relatedToLift',
          type: 'binary',
          version: '3',
          condition:
            "contains([ 'nape', 'thoracicSpine', 'lumbarSpine' ], get('variables.physio.chosenBodyPart'))",
          validations: {
            required: true,
          },
          label: 'Började besvären i samband med lyft och/eller vridning?',
          index: '0.3.0',
        },
        {
          id: 'lumbago.relatedToAccident',
          type: 'binary',
          version: '2',
          condition: "get('answers.lumbago.relatedToLift') !== 'yes'",
          validations: {
            required: true,
          },
          label: 'Började besvären efter fall, slag, skada eller liknande?',
          index: '0.3.1',
        },
        {
          id: 'problem.describeCause',
          version: '1',
          type: 'text',
          validations: {
            required: true,
            max: 250,
          },
          condition:
            "get('answers.lumbago.relatedToLift') === 'yes' || get('answers.lumbago.relatedToAccident') === 'yes'",
          label: 'Beskriv kortfattat vad som hände.',
          index: '0.3.2',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'joints.symptoms',
          type: 'multipleChoice',
          version: '4',
          validations: {
            required: true,
          },
          options: [
            {
              value: 'pain',
              label: 'Smärta',
            },
            {
              value: 'stiffness',
              label: 'Stelhet',
            },
            {
              value: 'weakness',
              label: 'Svaghet',
            },
            {
              value: 'swelling',
              label: 'Svullnad',
            },
            {
              value: 'blushing',
              label: 'Rodnad',
            },
            {
              value: 'numb',
              label: 'Domning/stickning',
            },
            {
              value: 'hot',
              label: 'Leden känns varm',
            },
            {
              value: 'crack',
              label: 'Leden knäpper eller knakar',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          label: 'Vilka besvär har du?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.4.0',
        },
        {
          id: 'problem.symptoms.current',
          type: 'text',
          version: '1',
          condition: "contains(get('answers.joints.symptoms'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilka besvär har du?',
          index: '0.4.1',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.painDescription',
          type: 'multipleChoice',
          version: '5',
          options: [
            {
              value: 'stabbing',
              label: 'Huggande',
            },
            {
              value: 'throbbing',
              label: 'Bultande',
            },
            {
              value: 'dull',
              label: 'Molande',
            },
            {
              value: 'burning',
              label: 'Brännande',
            },
            {
              value: 'tingling',
              label: 'Stickande',
            },
            {
              value: 'cutting',
              label: 'Skärande',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "contains(get('answers.joints.symptoms'), 'pain')",
          label: 'Hur skulle du beskriva smärtan?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.5.0',
        },
        {
          id: 'health.painDescriptionText',
          type: 'text',
          version: '1',
          condition: "contains(get('answers.health.painDescription'), 'other')",
          validations: {
            required: true,
          },
          label: 'Hur skulle du beskriva smärtan?',
          index: '0.5.1',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.painEvaluationWorst',
          type: 'range',
          version: '3',
          min: {
            value: 0,
            label: 'Ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'Värsta tänkbara smärta',
          },
          extras: {
            vas: true,
            invert: false,
          },
          condition: "contains(get('answers.joints.symptoms'), 'pain')",
          validations: {
            required: true,
          },
          label: 'Hur ont gör det när det gör som mest ont?',
          description: 'Gör en uppskattning och markera på skalan.',
          index: '0.6.0',
        },
        {
          id: 'health.painEvaluationNotTriggered',
          type: 'range',
          version: '1',
          min: {
            value: 0,
            label: 'Ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'Värsta tänkbara smärta',
          },
          extras: {
            vas: true,
            invert: false,
          },
          condition: "contains(get('answers.joints.symptoms'), 'pain')",
          validations: {
            required: true,
          },
          label: 'Hur ont har du när du inte triggar dina besvär?',
          description: 'Gör en uppskattning och markera på skalan.',
          index: '0.6.1',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'orthopedics.painWhen.lessWhenStill',
          version: '1',
          type: 'binary',
          condition: "contains(get('answers.joints.symptoms'), 'pain')",
          validations: {
            required: true,
          },
          label: 'Har du betydligt mindre ont när du är stilla?',
          index: '0.7.0',
        },
        {
          id: 'orthopedics.painWhen.varying',
          version: '1',
          type: 'binary',
          condition: "contains(get('answers.joints.symptoms'), 'pain')",
          validations: {
            required: true,
          },
          label: 'Varierar din smärta över dygnet?',
          index: '0.7.1',
        },
        {
          id: 'lumbago.timeOfDay',
          type: 'multipleChoice',
          version: '2',
          options: [
            {
              value: 'morning',
              label: 'På morgonen',
            },
            {
              value: 'day',
              label: 'På dagen',
            },
            {
              value: 'evening',
              label: 'På kvällen',
            },
            {
              value: 'night',
              label: 'På natten',
            },
          ],
          condition: "get('answers.orthopedics.painWhen.varying') === 'yes'",
          validations: {
            required: false,
          },
          label: 'När på dygnet är din smärta som värst?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.7.2',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'lumbago.wakeUpFromPain',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Störs din sömn av dina besvär?',
          index: '0.8.0',
        },
        {
          id: 'orthopedics.painWhen.pressuring',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotReach',
              label: 'Jag kommer inte åt att undersöka pga smärta/orörlighet.',
            },
          ],
          validations: {
            required: true,
          },
          label:
            'Gör det ont om du trycker med fingrarna på området där du har dina besvär?',
          index: '0.8.1',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'orthopedics.stiff.how',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'morning',
              label: 'Jag känner mig stel när jag vaknar på morgonen.',
            },
            {
              value: 'stiffAlways',
              label: 'Jag känner mig stel hela tiden.',
            },
          ],
          condition: "contains(get('answers.joints.symptoms'), 'stiffness')",
          validations: {
            required: true,
          },
          label: 'Hur yttrar sig din stelhet?',
          index: '0.9.0',
        },
        {
          id: 'orthopedics.weak.how',
          version: '1',
          type: 'text',
          condition: "contains(get('answers.joints.symptoms'), 'weakness')",
          validations: {
            required: true,
          },
          label: 'Hur yttrar sig din svaghet?',
          description:
            'Berätta t.ex. när du känner av svagheten och hur du upplever skillnaden mellan höger och vänster (om relevant).',
          index: '0.9.1',
        },
      ],
      version: '1',
      index: '0.9',
    },
    {
      type: 'section',
      children: [
        {
          id: 'lumbago.limitedMatch',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Är det någon särskild rörelse eller aktivitet som du har svårt att utföra på grund av dina besvär?',
          index: '0.10.0',
        },
        {
          id: 'lumbago.limited',
          type: 'text',
          version: '2',
          condition: "get('answers.lumbago.limitedMatch') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Beskriv vilken rörelse eller aktivitet du har svårt att utföra.',
          index: '0.10.1',
        },
      ],
      version: '1',
      index: '0.10',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.video',
          type: 'upload',
          version: '1-physio',
          types: 'video/mp4,video/webm,video/quicktime',
          validations: {
            required: false,
            max: 1,
          },
          label:
            'Om du tror att det kan vara till hjälp för fysioterapeuten har du här möjlighet att ladda upp en kort video (max 20 sekunder) som visar vilka besvär du har.',
          description:
            'Observera att även filmen är en del av din journal och omfattas av sekretess.',
          index: '0.11.0',
        },
      ],
      version: '1',
      index: '0.11',
    },
    {
      type: 'section',
      children: [
        {
          id: 'orthopedics.swollen.where',
          version: '2',
          type: 'text',
          condition: "contains(get('answers.joints.symptoms'), 'swelling')",
          validations: {
            required: true,
          },
          label: 'Var sitter svullnaden?',
          index: '0.12.0',
        },
        {
          id: 'orthopedics.pictures.swollen',
          version: '3',
          type: 'upload',
          validations: {
            required: false,
          },
          condition: "contains(get('answers.joints.symptoms'), 'swelling')",
          label:
            'Ta gärna en bild som visar svullnaden eller peka ut var den brukar uppstå.',
          index: '0.12.1',
        },
        {
          id: 'orthopedics.swollen.when',
          version: '2',
          type: 'text',
          condition: "contains(get('answers.joints.symptoms'), 'swelling')",
          validations: {
            required: true,
          },
          label: 'När uppstår svullnaden?',
          description:
            'T.ex. en timme efter aktivitet, under tiden jag springer',
          index: '0.12.2',
        },
      ],
      version: '1',
      index: '0.12',
    },
    {
      type: 'section',
      children: [
        {
          id: 'orthopedics.repeatedActivity.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Upplever du att dina besvär är kopplade till någon form av upprepad aktivitet, såsom för mycket stillasittande eller enformiga arbetsuppgifter?',
          index: '0.13.0',
        },
        {
          id: 'orthopedics.repeatedActivity.what',
          version: '1',
          type: 'text',
          condition:
            "get('answers.orthopedics.repeatedActivity.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Beskriv vilken form av upprepad aktivitet du ägnat dig åt.',
          index: '0.13.1',
        },
      ],
      version: '1',
      index: '0.13',
    },
    {
      type: 'section',
      children: [
        {
          id: 'exercise.frequency',
          type: 'choice',
          version: '5',
          options: [
            {
              value: 'never',
              label: 'Aldrig',
            },
            {
              value: 'lessThanWeekly',
              label: 'Mindre än 1 gång i veckan',
            },
            {
              value: 'oneToThreeTimesAWeek',
              label: '1–3 gånger i veckan',
            },
            {
              value: 'moreThan3TimesAWeek',
              label: 'Mer än 3 gånger i veckan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Hur ofta ägnar du dig åt pulshöjande, fysisk aktivitet?',
          description:
            'T.ex. raska promenader, löpning, gympa, cykling, dans eller motsvarande',
          index: '0.14.0',
        },
        {
          id: 'exercise.type',
          version: '3',
          type: 'text',
          condition:
            "!(get('answers.exercise.frequency') === 'never' || get('answers.exercise.frequency') === undefined)",
          validations: {
            required: true,
          },
          label: 'Vilken typ av pulshöjande, fysisk aktivitet ägnar du dig åt?',
          index: '0.14.1',
        },
        {
          id: 'exercise.worsenedBy',
          type: 'binary',
          version: '2',
          condition:
            "!(get('answers.exercise.frequency') === 'never' || get('answers.exercise.frequency') === undefined)",
          validations: {
            required: true,
          },
          label:
            'Upplever du att dina besvär har försämrats av din fysiska aktivitet?',
          index: '0.14.2',
        },
      ],
      version: '1',
      index: '0.14',
    },
    {
      type: 'section',
      condition:
        "contains(['head', 'nape', 'thoracicSpine'], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.bodyParts.pain.arms.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, smärtan strålar ut i höger arm.',
                },
                {
                  value: 'left',
                  label: 'Ja, smärtan strålar ut i vänster arm.',
                },
                {
                  value: 'both',
                  label: 'Ja, smärtan strålar ut i båda armarna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'pain')",
              validations: {
                required: true,
              },
              label: 'Strålar smärtan ut i armen/armarna?',
              index: '0.15.0.0',
            },
            {
              id: 'orthopedics.bodyParts.numb.arms.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft domningar i armen/armarna alternativt ända ut i handen/händerna?',
              index: '0.15.0.1',
            },
            {
              id: 'orthopedics.bodyParts.numb.arms.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.numb.arms.match'))",
              validations: {
                required: true,
              },
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft domningar.',
              index: '0.15.0.2',
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft stickningar i armen/armarna alternativt ända ut i handen/händerna?',
              index: '0.15.0.3',
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.tingling.arms.match'))",
              validations: {
                required: true,
              },
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft stickningar.',
              index: '0.15.0.4',
            },
          ],
          version: '1',
          index: '0.15.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.position.lessProblem.head.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva lindring av dina besvär?',
              index: '0.15.1.0',
            },
            {
              id: 'orthopedics.position.lessProblem.head.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.lessProblem.head.match') === 'yes'",
              validations: {
                required: true,
              },
              label:
                'Beskriv vilken position för huvudet/nacken som lindrar dina besvär.',
              index: '0.15.1.1',
            },
            {
              id: 'orthopedics.position.moreProblem.head.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva försämring av dina besvär?',
              index: '0.15.1.2',
            },
            {
              id: 'orthopedics.position.moreProblem.head.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.moreProblem.head.match') === 'yes'",
              validations: {
                required: true,
              },
              label:
                'Beskriv vilken position för huvudet/nacken som försämrar dina besvär.',
              index: '0.15.1.3',
            },
          ],
          version: '1',
          index: '0.15.1',
        },
      ],
      version: '1',
      index: '0.15',
    },
    {
      type: 'section',
      condition:
        "contains(['head', 'nape'], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.nape.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra fem korta övningar.\n  </p>\n\n  <p>\n    Börja varje övning ståendes med fötterna höftbrett isär och titta rakt fram. Låt armarna hänga längs sidorna. Detta är startposition.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.16.0.0',
            },
            {
              id: 'physio.test.nape.bendHeadForward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 1 Gjorde det ont?',
              clip: '6_fysio_nacke_1',
              index: '0.16.0.1',
            },
            {
              id: 'physio.test.nape.bendHeadForward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.nape.bendHeadForward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.16.0.2',
            },
            {
              id: 'physio.test.nape.bendHeadForward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.nape.bendHeadForward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.16.0.3',
            },
          ],
          version: '1',
          index: '0.16.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.nape.bendHeadBackward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 2 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj huvudet bakåt. Återgå till startposition.\n  </p>',
              index: '0.16.1.0',
            },
            {
              id: 'physio.test.nape.bendHeadBackward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.nape.bendHeadBackward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.16.1.1',
            },
            {
              id: 'physio.test.nape.bendHeadBackward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.nape.bendHeadBackward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.16.1.2',
            },
          ],
          version: '1',
          index: '0.16.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.nape.leanHead.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, åt båda hållen',
                },
                {
                  value: 'right',
                  label: 'Endast åt höger',
                },
                {
                  value: 'left',
                  label: 'Endast åt vänster',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 3 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Vinkla huvudet åt sidan så att örat närmar sig axeln. Återgå till startposition. Upprepa på andra sidan.\n  </p>',
              index: '0.16.2.0',
            },
            {
              id: 'physio.test.nape.leanHead.pain.match',
              version: '1',
              type: 'binary',
              condition:
                "get('answers.physio.test.nape.leanHead.couldTest') === 'both'",
              validations: {
                required: true,
              },
              label: 'Övning 3 Gjorde det ont?',
              index: '0.16.2.1',
            },
            {
              id: 'physio.test.nape.leanHead.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.nape.leanHead.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.16.2.2',
            },
            {
              id: 'physio.test.nape.leanHead.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.nape.leanHead.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.16.2.3',
            },
          ],
          version: '1',
          index: '0.16.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.nape.turnHead.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, åt båda hållen',
                },
                {
                  value: 'right',
                  label: 'Endast åt höger',
                },
                {
                  value: 'left',
                  label: 'Endast åt vänster',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 4 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_4.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_4.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_4.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_4.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Vrid huvudet åt sidan. Återgå till startposition. Upprepa på andra sidan.\n  </p>',
              index: '0.16.3.0',
            },
            {
              id: 'physio.test.nape.turnHead.pain.match',
              version: '1',
              type: 'binary',
              condition:
                "get('answers.physio.test.nape.turnHead.couldTest') === 'both'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              index: '0.16.3.1',
            },
            {
              id: 'physio.test.nape.turnHead.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.nape.turnHead.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.16.3.2',
            },
            {
              id: 'physio.test.nape.turnHead.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.nape.turnHead.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.16.3.3',
            },
          ],
          version: '1',
          index: '0.16.3',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.nape.handsAboveHead.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, med båda armarna',
                },
                {
                  value: 'right',
                  label: 'Endast med höger arm',
                },
                {
                  value: 'left',
                  label: 'Endast med vänster arm',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 5 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_5.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_5.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_5.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_5.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Lyft båda armarna utåt sidorna och låt dem mötas ovanför huvudet.\n  </p>',
              index: '0.16.4.0',
            },
            {
              id: 'physio.test.nape.handsAboveHead.pain.match',
              version: '1',
              type: 'binary',
              condition:
                "get('answers.physio.test.nape.handsAboveHead.couldTest') === 'both'",
              validations: {
                required: true,
              },
              label: 'Övning 5 Gjorde det ont?',
              index: '0.16.4.1',
            },
            {
              id: 'physio.test.nape.handsAboveHead.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.nape.handsAboveHead.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 5 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.16.4.2',
            },
            {
              id: 'physio.test.nape.handsAboveHead.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.nape.handsAboveHead.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.16.4.3',
            },
          ],
          version: '1',
          index: '0.16.4',
        },
      ],
      version: '1',
      index: '0.16',
    },
    {
      type: 'section',
      condition:
        "contains(['thoracicSpine'], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.thoracicSpine.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra fyra korta övningar.\n  </p>\n\n  <p>\n    Börja varje övning ståendes med fötterna höftbrett isär och titta rakt fram. Låt armarna hänga längs sidorna. Detta är startposition.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.17.0.0',
            },
            {
              id: 'physio.test.thoracicSpine.bendTorsoForward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 1 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj överkroppen framåt/nedåt så långt det går. Återgå till startposition.\n  </p>',
              index: '0.17.0.1',
            },
            {
              id: 'physio.test.thoracicSpine.bendTorsoForward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.thoracicSpine.bendTorsoForward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.17.0.2',
            },
            {
              id:
                'physio.test.thoracicSpine.bendTorsoForward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.thoracicSpine.bendTorsoForward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.17.0.3',
            },
          ],
          version: '1',
          index: '0.17.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.thoracicSpine.bendTorsoBackward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 2 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj överkroppen bakåt så långt det går. Återgå till startposition.\n  </p>',
              index: '0.17.1.0',
            },
            {
              id:
                'physio.test.thoracicSpine.bendTorsoBackward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.thoracicSpine.bendTorsoBackward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.17.1.1',
            },
            {
              id:
                'physio.test.thoracicSpine.bendTorsoBackward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.thoracicSpine.bendTorsoBackward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.17.1.2',
            },
          ],
          version: '1',
          index: '0.17.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.thoracicSpine.leanTorso.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, åt båda hållen',
                },
                {
                  value: 'right',
                  label: 'Endast åt höger',
                },
                {
                  value: 'left',
                  label: 'Endast åt vänster',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 3 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj överkroppen åt sidan. Återgå till startposition. Upprepa på andra sidan.\n  </p>',
              index: '0.17.2.0',
            },
            {
              id: 'physio.test.thoracicSpine.leanTorso.pain.match',
              version: '1',
              type: 'binary',
              condition:
                "get('answers.physio.test.thoracicSpine.leanTorso.couldTest') === 'both'",
              validations: {
                required: true,
              },
              label: 'Övning 3 Gjorde det ont?',
              index: '0.17.2.1',
            },
            {
              id: 'physio.test.thoracicSpine.leanTorso.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.thoracicSpine.leanTorso.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.17.2.2',
            },
            {
              id: 'physio.test.thoracicSpine.leanTorso.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.thoracicSpine.leanTorso.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.17.2.3',
            },
          ],
          version: '1',
          index: '0.17.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.thoracicSpine.turnTorso.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, åt båda hållen',
                },
                {
                  value: 'right',
                  label: 'Endast åt höger',
                },
                {
                  value: 'left',
                  label: 'Endast åt vänster',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 4 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Vrid överkroppen åt sidan. Återgå till startposition. Upprepa på andra sidan.\n  </p>',
              index: '0.17.3.0',
            },
            {
              id: 'physio.test.thoracicSpine.turnTorso.pain.match',
              version: '1',
              type: 'binary',
              condition:
                "get('answers.physio.test.thoracicSpine.turnTorso.couldTest') === 'both'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              index: '0.17.3.1',
            },
            {
              id: 'physio.test.thoracicSpine.turnTorso.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.thoracicSpine.turnTorso.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.17.3.2',
            },
            {
              id: 'physio.test.thoracicSpine.turnTorso.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.thoracicSpine.turnTorso.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.17.3.3',
            },
          ],
          version: '1',
          index: '0.17.3',
        },
      ],
      version: '1',
      index: '0.17',
    },
    {
      type: 'section',
      condition:
        "contains(['lumbarSpine'], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.bodyParts.pain.legs.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, smärtan strålar ut i höger ben.',
                },
                {
                  value: 'left',
                  label: 'Ja, smärtan strålar ut i vänster ben.',
                },
                {
                  value: 'both',
                  label: 'Ja, smärtan strålar ut i båda benen.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'pain')",
              validations: {
                required: true,
              },
              label: 'Strålar smärtan ut i benet/benen?',
              index: '0.18.0.0',
            },
            {
              id: 'orthopedics.bodyParts.pain.legs.howFarRight',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'aboveKnee',
                  label: 'Det slutar stråla ovanför knät.',
                },
                {
                  value: 'belowKnee',
                  label: 'Det strålar även under knät eller ner i foten.',
                },
              ],
              condition:
                "contains(['right', 'both'], get('answers.orthopedics.bodyParts.pain.legs.match'))",
              validations: {
                required: true,
              },
              label: 'Hur långt ut i höger ben strålar det?',
              index: '0.18.0.1',
            },
            {
              id: 'orthopedics.bodyParts.pain.legs.howFarLeft',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'aboveKnee',
                  label: 'Det slutar stråla ovanför knät.',
                },
                {
                  value: 'belowKnee',
                  label: 'Det strålar även under knät eller ner i foten.',
                },
              ],
              condition:
                "contains(['left', 'both'], get('answers.orthopedics.bodyParts.pain.legs.match'))",
              validations: {
                required: true,
              },
              label: 'Hur långt ut i vänster ben strålar det?',
              index: '0.18.0.2',
            },
          ],
          version: '1',
          index: '0.18.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.bodyParts.numb.legs.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft domningar i benet/benen alternativt ända ut i foten/fötterna?',
              index: '0.18.1.0',
            },
            {
              id: 'orthopedics.bodyParts.numb.legs.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.numb.legs.match'))",
              validations: {
                required: true,
              },
              label: 'Beskriv var i benet eller foten/tårna du haft domningar.',
              index: '0.18.1.1',
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft stickningar i benet/benen alternativt ända ut i foten/fötterna?',
              index: '0.18.1.2',
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.tingling.legs.match'))",
              validations: {
                required: true,
              },
              label:
                'Beskriv var i benet eller foten/tårna du haft stickningar.',
              index: '0.18.1.3',
            },
          ],
          version: '1',
          index: '0.18.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'health.numbnessPelvicArea',
              type: 'binary',
              version: '1',
              validations: {
                required: true,
              },
              label:
                'Har du domningar i området kring ändtarmen eller könsorganen?',
              index: '0.18.2.0',
            },
            {
              id: 'health.peeingIssues',
              type: 'binary',
              version: '1',
              validations: {
                required: true,
              },
              label:
                'Händer det att du kissar på dig, eller inte känner när du är kissnödig?',
              index: '0.18.2.1',
            },
            {
              id: 'lumbago.troublePeeingOrPooping',
              version: '2',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Har du haft svårt att kissa/bajsa i samband med dina besvär?',
              index: '0.18.2.2',
            },
          ],
          version: '1',
          index: '0.18.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'problem.constantOrChanging',
              version: '2',
              type: 'choice',
              options: [
                {
                  value: 'constant',
                  label: 'De har varit i princip oförändrade.',
                },
                {
                  value: 'comesAndGoes',
                  label: 'De har kommit och gått.',
                },
                {
                  value: 'growingWorse',
                  label: 'De har stadigt blivit värre.',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Hur har besvären yttrat sig sedan debuten?',
              description: 'Välj det alternativ som stämmer bäst.',
              index: '0.18.3.0',
            },
          ],
          version: '1',
          index: '0.18.3',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.position.lessProblem.general.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra kroppsposition uppleva lindring av dina besvär?',
              index: '0.18.4.0',
            },
            {
              id: 'orthopedics.position.lessProblem.general.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.lessProblem.general.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Beskriv vilken kroppsposition som lindrar dina besvär.',
              index: '0.18.4.1',
            },
            {
              id: 'orthopedics.position.moreProblem.general.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra kroppsposition uppleva försämring av dina besvär?',
              index: '0.18.4.2',
            },
            {
              id: 'orthopedics.position.moreProblem.general.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.moreProblem.general.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Beskriv vilken kroppsposition som försämrar dina besvär.',
              index: '0.18.4.3',
            },
          ],
          version: '1',
          index: '0.18.4',
        },
        {
          type: 'section',
          children: [
            {
              id: 'cough.morePain',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Gör det betydligt mer ont när du hostar/nyser?',
              index: '0.18.5.0',
            },
          ],
          version: '1',
          index: '0.18.5',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra åtta korta övningar.\n  </p>\n\n  <p>\n    Börja varje övning ståendes med fötterna höftbrett isär och titta rakt fram. Låt armarna hänga längs sidorna. Detta är startposition.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.18.6.0',
            },
            {
              id: 'physio.test.lumbarSpine.bendHeadForward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 1 Gjorde det ont i ländryggen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/6_fysio_nacke_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj fram huvudet. Återgå till startposition.\n  </p>',
              index: '0.18.6.1',
            },
            {
              id: 'physio.test.lumbarSpine.bendHeadForward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.bendHeadForward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.18.6.2',
            },
            {
              id:
                'physio.test.lumbarSpine.bendHeadForward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.bendHeadForward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.18.6.3',
            },
          ],
          version: '1',
          index: '0.18.6',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.bendTorsoForward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 2 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj överkroppen framåt/nedåt så långt det går. Återgå till startposition.\n  </p>',
              index: '0.18.7.0',
            },
            {
              id: 'physio.test.lumbarSpine.bendTorsoForward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.bendTorsoForward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.18.7.1',
            },
            {
              id:
                'physio.test.lumbarSpine.bendTorsoForward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.bendTorsoForward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.18.7.2',
            },
          ],
          version: '1',
          index: '0.18.7',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.bendTorsoBackward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 3 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj överkroppen bakåt så långt det går. Återgå till startposition.\n  </p>',
              index: '0.18.8.0',
            },
            {
              id: 'physio.test.lumbarSpine.bendTorsoBackward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.bendTorsoBackward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.18.8.1',
            },
            {
              id:
                'physio.test.lumbarSpine.bendTorsoBackward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.bendTorsoBackward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.18.8.2',
            },
          ],
          version: '1',
          index: '0.18.8',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.leanTorso.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, åt båda hållen',
                },
                {
                  value: 'right',
                  label: 'Endast åt höger',
                },
                {
                  value: 'left',
                  label: 'Endast åt vänster',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 4 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Böj överkroppen åt sidan. Återgå till startposition. Upprepa på andra sidan.\n  </p>',
              index: '0.18.9.0',
            },
            {
              id: 'physio.test.lumbarSpine.leanTorso.pain.match',
              version: '1',
              type: 'binary',
              condition:
                "get('answers.physio.test.lumbarSpine.leanTorso.couldTest') === 'both'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              index: '0.18.9.1',
            },
            {
              id: 'physio.test.lumbarSpine.leanTorso.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.leanTorso.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.18.9.2',
            },
            {
              id: 'physio.test.lumbarSpine.leanTorso.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.lumbarSpine.leanTorso.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.18.9.3',
            },
          ],
          version: '1',
          index: '0.18.9',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.turnTorso.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, åt båda hållen',
                },
                {
                  value: 'right',
                  label: 'Endast åt höger',
                },
                {
                  value: 'left',
                  label: 'Endast åt vänster',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 5 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/7_fysio_brostrygg_4.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Vrid överkroppen åt sidan. Återgå till startposition. Upprepa på andra sidan.\n  </p>',
              index: '0.18.10.0',
            },
            {
              id: 'physio.test.lumbarSpine.turnTorso.pain.match',
              version: '1',
              type: 'binary',
              condition:
                "get('answers.physio.test.lumbarSpine.turnTorso.couldTest') === 'both'",
              validations: {
                required: true,
              },
              label: 'Övning 5 Gjorde det ont?',
              index: '0.18.10.1',
            },
            {
              id: 'physio.test.lumbarSpine.turnTorso.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.turnTorso.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 5 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.18.10.2',
            },
            {
              id: 'physio.test.lumbarSpine.turnTorso.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.lumbarSpine.turnTorso.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.18.10.3',
            },
          ],
          version: '1',
          index: '0.18.10',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.standOnOneLeg.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, med båda fötterna',
                },
                {
                  value: 'right',
                  label: 'Endast med höger fot',
                },
                {
                  value: 'left',
                  label: 'Endast med vänster fot',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 6 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan.\n  </p>',
              index: '0.18.11.0',
            },
            {
              id:
                'physio.test.lumbarSpine.standOnOneLeg.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.lumbarSpine.standOnOneLeg.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 6 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.18.11.1',
            },
          ],
          version: '1',
          index: '0.18.11',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.walkOnHeels.couldTest',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 7 Kunde du genomföra övningen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande.\n  </p>',
              index: '0.18.12.0',
            },
            {
              id: 'physio.test.lumbarSpine.walkOnHeels.problemWhere',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'På höger sida',
                },
                {
                  value: 'left',
                  label: 'På vänster sida',
                },
                {
                  value: 'both',
                  label: 'På båda sidor',
                },
              ],
              condition:
                "contains(['no'], get('answers.physio.test.lumbarSpine.walkOnHeels.couldTest'))",
              validations: {
                required: true,
              },
              label: 'Övning 7 På vilken sida har du problem?',
              index: '0.18.12.1',
            },
          ],
          version: '1',
          index: '0.18.12',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lumbarSpine.stretchLegs.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 8 Gjorde det ont i ländryggen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/8_fysio_landrygg_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/8_fysio_landrygg_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/8_fysio_landrygg_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/8_fysio_landrygg_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Sitt på en stol eller ett bord, helst så att du ej når golvet med fötterna. Lägg händerna bakom rumpan. Försök att slappna av helt i ryggen och "säcka ihop". Glöm ej att fälla ner huvudet. Sträck ut ena benet och böj sedan tillbaka det igen. Upprepa på andra sidan.\n  </p>',
              index: '0.18.13.0',
            },
            {
              id: 'physio.test.lumbarSpine.stretchLegs.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.stretchLegs.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 8 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.18.13.1',
            },
            {
              id: 'physio.test.lumbarSpine.stretchLegs.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lumbarSpine.stretchLegs.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 8 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.18.13.2',
            },
          ],
          version: '1',
          index: '0.18.13',
        },
      ],
      version: '1',
      index: '0.18',
    },
    {
      type: 'section',
      condition:
        "contains([ 'shoulderRight', 'shoulderLeft', 'upperArmRight', 'upperArmLeft', 'elbowRight', 'elbowLeft', 'forearmRight', 'forearmLeft', 'wristRight', 'wristLeft', 'handRight', 'handLeft', 'fingersRight', 'fingersLeft' ], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'joints.concentration',
              version: '4',
              type: 'choice',
              options: [
                {
                  value: 'concentrated',
                  label: 'Smärtan är koncentrerad till en viss punkt.',
                },
                {
                  value: 'spreading',
                  label: 'Jag känner av smärtan i ett större område.',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'pain')",
              validations: {
                required: true,
              },
              label:
                'Är smärtan koncentrerad till en viss punkt eller känner du av smärtan i ett större område?',
              index: '0.19.0.0',
            },
            {
              id: 'orthopedics.bodyParts.fingers.which',
              version: '1',
              type: 'text',
              condition:
                "contains([ 'fingersRight', 'fingersLeft' ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label: 'I vilka fingrar har du besvär?',
              index: '0.19.0.1',
            },
          ],
          version: '1',
          index: '0.19.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.bodyParts.numb.arms.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft domningar i armen/armarna alternativt ända ut i handen/händerna?',
              index: '0.19.1.0',
            },
            {
              id: 'orthopedics.bodyParts.numb.arms.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.numb.arms.match'))",
              validations: {
                required: true,
              },
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft domningar.',
              index: '0.19.1.1',
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft stickningar i armen/armarna alternativt ända ut i handen/händerna?',
              index: '0.19.1.2',
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.tingling.arms.match'))",
              validations: {
                required: true,
              },
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft stickningar.',
              index: '0.19.1.3',
            },
          ],
          version: '1',
          index: '0.19.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.position.lessProblem.head.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva lindring av dina besvär?',
              index: '0.19.2.0',
            },
            {
              id: 'orthopedics.position.lessProblem.head.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.lessProblem.head.match') === 'yes'",
              validations: {
                required: true,
              },
              label:
                'Beskriv vilken position för huvudet/nacken som lindrar dina besvär.',
              index: '0.19.2.1',
            },
            {
              id: 'orthopedics.position.moreProblem.head.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva försämring av dina besvär?',
              index: '0.19.2.2',
            },
            {
              id: 'orthopedics.position.moreProblem.head.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.moreProblem.head.match') === 'yes'",
              validations: {
                required: true,
              },
              label:
                'Beskriv vilken position för huvudet/nacken som försämrar dina besvär.',
              index: '0.19.2.3',
            },
          ],
          version: '1',
          index: '0.19.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.canLayOnSide',
              version: '2',
              type: 'binary',
              condition:
                "contains([ 'shoulderRight', 'shoulderLeft', 'upperArmRight', 'upperArmLeft' ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label: 'Kan du ligga på den sida som du har dina besvär?',
              index: '0.19.3.0',
            },
            {
              id: 'orthopedics.bodyParts.elbow.where',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'upperside',
                  label: 'På ovansidan',
                },
                {
                  value: 'underside',
                  label: 'På undersidan',
                },
                {
                  value: 'outside',
                  label: 'På utsidan',
                },
                {
                  value: 'inside',
                  label: 'I armvecket',
                },
              ],
              condition:
                "contains([ 'elbowRight', 'elbowLeft' ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label: 'Var i armbågen upplever du besvär?',
              description:
                'Lägg armen på ett bord med handflatan mot bordsskivan.',
              index: '0.19.3.1',
            },
            {
              id: 'orthopedics.bodyParts.forearm.where',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'upperside',
                  label: 'På ovansidan',
                },
                {
                  value: 'underside',
                  label: 'På undersidan',
                },
                {
                  value: 'outside',
                  label: 'På utsidan',
                },
                {
                  value: 'inside',
                  label: 'På insidan',
                },
              ],
              condition:
                "contains([ 'forearmRight', 'forearmLeft', ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label: 'På vilken sida av underarmen upplever du besvären?',
              description:
                'Lägg armen på ett bord med handflatan mot bordsskivan.',
              index: '0.19.3.2',
            },
            {
              id: 'joints.elbowSwollen',
              version: '1',
              type: 'binary',
              condition:
                "contains([ 'elbowRight', 'elbowLeft' ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label:
                'Har armbågen varit svullen någon gång i samband med dina nuvarande besvär?',
              index: '0.19.3.3',
            },
            {
              id: 'joints.elbowLocked',
              version: '2',
              type: 'binary',
              condition:
                "contains([ 'elbowRight', 'elbowLeft' ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label:
                'Har armbågen fastnat i ett läge/låst sig någon gång i samband med dina nuvarande besvär?',
              index: '0.19.3.4',
            },
          ],
          version: '1',
          index: '0.19.3',
        },
      ],
      version: '1',
      index: '0.19',
    },
    {
      type: 'section',
      condition:
        "contains([ 'shoulderRight', 'shoulderLeft', 'upperArmRight', 'upperArmLeft' ], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.shoulderUpperArm.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra fyra korta övningar.\n  </p>\n\n  <p>\n    Du behöver enbart göra övningarna på den sida av kroppen där du har dina besvär.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.20.0.0',
            },
            {
              id: 'physio.test.shoulderUpperArm.armForward.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 1 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå upp. Lyft armen rakt fram, hela vägen upp över huvudet, och fäll sedan ner armen igen.\n  </p>',
              index: '0.20.0.1',
            },
            {
              id: 'physio.test.shoulderUpperArm.armForward.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.armForward.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.20.0.2',
            },
            {
              id:
                'physio.test.shoulderUpperArm.armForward.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.armForward.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.20.0.3',
            },
          ],
          version: '1',
          index: '0.20.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.shoulderUpperArm.armOut.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 2 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå upp. Lyft armen utåt sidan, hela vägen upp över huvudet, och fäll sedan ner armen igen.\n  </p>',
              index: '0.20.1.0',
            },
            {
              id: 'physio.test.shoulderUpperArm.armOut.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.armOut.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.20.1.1',
            },
            {
              id: 'physio.test.shoulderUpperArm.armOut.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.armOut.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.20.1.2',
            },
          ],
          version: '1',
          index: '0.20.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.shoulderUpperArm.wristToWall.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 3 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_3_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_3_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_3_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_3_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå upp med sidan några centimeter från en vägg och pressa handleden (ej handryggen), med rak arm, mot väggen. \n  </p>',
              index: '0.20.2.0',
            },
            {
              id: 'physio.test.shoulderUpperArm.wristToWall.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.wristToWall.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.20.2.1',
            },
            {
              id:
                'physio.test.shoulderUpperArm.wristToWall.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.wristToWall.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.20.2.2',
            },
          ],
          version: '1',
          index: '0.20.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.shoulderUpperArm.wristToWall90.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_4.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_4.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_4.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/1_fysio_axeloverarm_4.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå upp med sidan några centimeter från en vägg. Böj armen 90 grader i armbågen och pressa handleden mot väggen, samtidigt som du håller armbågen tätt mot kroppen.\n  </p>',
              index: '0.20.3.0',
            },
            {
              id: 'physio.test.shoulderUpperArm.wristToWall90.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.wristToWall90.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.20.3.1',
            },
            {
              id:
                'physio.test.shoulderUpperArm.wristToWall90.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.shoulderUpperArm.wristToWall90.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.20.3.2',
            },
          ],
          version: '1',
          index: '0.20.3',
        },
      ],
      version: '1',
      index: '0.20',
    },
    {
      type: 'section',
      condition:
        "contains([ 'elbowRight', 'elbowLeft', ], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.elbow.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra fem korta övningar.\n  </p>\n\n  <p>\n    Du behöver enbart göra övningarna på den sida av kroppen där du har dina besvär.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.21.0.0',
            },
            {
              id: 'physio.test.elbow.bend.couldBend',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 1 Kunde du böja armen hela vägen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Håll handflatan öppen, armen nära kroppen och böj i armbågen. Sträck sedan ut armen igen.\n  </p>',
              index: '0.21.0.1',
            },
            {
              id: 'physio.test.elbow.bend.couldStraighten',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 2 Kunde du sträcka ut armen rakt?',
              index: '0.21.0.2',
            },
          ],
          version: '1',
          index: '0.21.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.elbow.turnHand.withoutPain',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 3 Kunde du genomföra rörelsen utan smärta?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Vinkla armen i 90 grader. Håll armbågen mot kroppen. Vrid handflatan upp mot taket och ned mot golvet. Håll armen i övrigt så stilla som möjligt.\n  </p>',
              index: '0.21.1.0',
            },
          ],
          version: '1',
          index: '0.21.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.elbow.pressOnHand.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Lägg armen på ett bord eller liknande, så att handen sticker ut över kanten. Arm och hand ska bilda en rak linje. Pressa ena handen mot den smärtande handens handrygg, och håll emot med armen som du har besvär i.\n  </p>',
              index: '0.21.2.0',
            },
            {
              id: 'physio.test.elbow.pressOnHand.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.elbow.pressOnHand.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.21.2.1',
            },
            {
              id: 'physio.test.elbow.pressOnHand.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.elbow.pressOnHand.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.21.2.2',
            },
          ],
          version: '1',
          index: '0.21.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.elbow.pressOnMiddleFinger.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 5 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_4.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_4.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_4.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/2_fysio_armbage_4.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Lägg armen på ett bord eller liknande, så att handen sticker ut över kanten. Arm och hand ska bilda en rak linje. Pressa ena handen mot den smärtande handens långfinger, och håll emot med långfingret.\n  </p>',
              index: '0.21.3.0',
            },
            {
              id: 'physio.test.elbow.pressOnMiddleFinger.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.elbow.pressOnMiddleFinger.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 5 Beskriv var och på vilket sätt det gjorde ont.',
              index: '0.21.3.1',
            },
            {
              id:
                'physio.test.elbow.pressOnMiddleFinger.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.elbow.pressOnMiddleFinger.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.21.3.2',
            },
          ],
          version: '1',
          index: '0.21.3',
        },
      ],
      version: '1',
      index: '0.21',
    },
    {
      type: 'section',
      condition:
        "contains([ 'hipRight', 'hipLeft', 'groinRight', 'groinLeft', 'buttockRight', 'buttockLeft', 'thighRight', 'thighLeft', 'kneeRight', 'kneeLeft', 'shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft', 'toesRight', 'toesLeft' ], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.painWhere.lowerBody',
              version: '1',
              type: 'multipleChoice',
              options: [
                {
                  value: 'thighFront',
                  label: 'På framsidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'thighBack',
                  label: 'På baksidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'thighInside',
                  label: 'På insidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'thighOutside',
                  label: 'På utsidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeInternal',
                  label: 'Inuti knät',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeInside',
                  label: 'På insidan av knät (mot det andra benet till)',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeOutside',
                  label: 'På utsidan av knät',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeHollow',
                  label: 'I knävecket',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeCap',
                  label: 'På knäskålen',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeCapAbove',
                  label: 'Ovanför knäskålen',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeCapBelow',
                  label: 'Nedanför knäskålen',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegInternal',
                  label: 'Inuti underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegFront',
                  label: 'På framsidan av underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegInside',
                  label: 'På insidan av underbenet (mot det andra benet till)',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegOutside',
                  label: 'På utsidan av underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegBack',
                  label: 'På baksidan av underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'ankleFront',
                  label: 'På framsidan av fotleden',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'ankleOutside',
                  label: 'På utsidan av ankeln',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'ankleInside',
                  label: 'På insidan av ankeln',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'achillesTendon',
                  label: 'I hälsenan',
                  condition:
                    "contains(['shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'heel',
                  label: 'I hälen',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'footUnder',
                  label: 'Under foten',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "contains(get('answers.joints.symptoms'), 'pain') && contains([ 'thighRight', 'thighLeft', 'kneeRight', 'kneeLeft', 'shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft' ], get('variables.physio.chosenBodyPart'))",
              label: 'Var, mer exakt, är smärtan lokaliserad?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.22.0.0',
            },
            {
              id: 'orthopedics.painWhere.other',
              version: '1',
              type: 'text',
              condition:
                "contains(get('answers.orthopedics.painWhere.lowerBody'), 'other')",
              validations: {
                required: true,
              },
              label: 'Beskriv var smärtan sitter.',
              index: '0.22.0.1',
            },
            {
              id: 'orthopedics.bodyParts.toes.which',
              version: '1',
              type: 'text',
              condition:
                "contains(['toesRight', 'toesLeft'], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label: 'I vilka tår har du besvär?',
              index: '0.22.0.2',
            },
          ],
          version: '1',
          index: '0.22.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.bodyParts.numb.legs.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft domningar i benet/benen alternativt ända ut i foten/fötterna?',
              index: '0.22.1.0',
            },
            {
              id: 'orthopedics.bodyParts.numb.legs.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.numb.legs.match'))",
              validations: {
                required: true,
              },
              label: 'Beskriv var i benet eller foten/tårna du haft domningar.',
              index: '0.22.1.1',
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'numb')",
              validations: {
                required: true,
              },
              label:
                'Har du sedan besvären började haft stickningar i benet/benen alternativt ända ut i foten/fötterna?',
              index: '0.22.1.2',
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.where',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'both'], get('answers.orthopedics.bodyParts.tingling.legs.match'))",
              validations: {
                required: true,
              },
              label:
                'Beskriv var i benet eller foten/tårna du haft stickningar.',
              index: '0.22.1.3',
            },
          ],
          version: '1',
          index: '0.22.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'health.numbnessPelvicArea',
              type: 'binary',
              version: '1',
              validations: {
                required: true,
              },
              label:
                'Har du domningar i området kring ändtarmen eller könsorganen?',
              index: '0.22.2.0',
            },
            {
              id: 'health.peeingIssues',
              type: 'binary',
              version: '1',
              validations: {
                required: true,
              },
              label:
                'Händer det att du kissar på dig, eller inte känner när du är kissnödig?',
              index: '0.22.2.1',
            },
            {
              id: 'lumbago.troublePeeingOrPooping',
              version: '2',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Har du haft svårt att kissa/bajsa i samband med dina besvär?',
              index: '0.22.2.2',
            },
          ],
          version: '1',
          index: '0.22.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.lower.problemBothSides',
              version: '2',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Har dina besvär vid något tillfälle uppträtt på motsatt sida eller i båda benen samtidigt?',
              index: '0.22.3.0',
            },
            {
              id: 'orthopedics.position.lessProblem.general.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra kroppsposition uppleva lindring av dina besvär?',
              index: '0.22.3.1',
            },
            {
              id: 'orthopedics.position.lessProblem.general.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.lessProblem.general.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Beskriv vilken kroppsposition som lindrar dina besvär.',
              index: '0.22.3.2',
            },
            {
              id: 'orthopedics.position.moreProblem.general.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label:
                'Kan du genom att ändra kroppsposition uppleva försämring av dina besvär?',
              index: '0.22.3.3',
            },
            {
              id: 'orthopedics.position.moreProblem.general.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.position.moreProblem.general.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Beskriv vilken kroppsposition som försämrar dina besvär.',
              index: '0.22.3.4',
            },
          ],
          version: '1',
          index: '0.22.3',
        },
        {
          type: 'section',
          children: [
            {
              id: 'joints.walkingDistanceBeforePain',
              version: '3',
              type: 'choice',
              options: [
                {
                  value: 'lessThanHundred',
                  label: 'Mindre än 100 m',
                },
                {
                  value: 'hundredToThousand',
                  label: '100–1000 m',
                },
                {
                  value: 'moreThanThousand',
                  label: 'Mer än 1000 m',
                },
              ],
              condition: "contains(get('answers.joints.symptoms'), 'pain')",
              validations: {
                required: true,
              },
              label: 'Hur långt kan du gå innan du får ont?',
              index: '0.22.4.0',
            },
          ],
          version: '1',
          index: '0.22.4',
        },
        {
          type: 'section',
          condition:
            "contains([ 'kneeRight', 'kneeLeft' ], get('variables.physio.chosenBodyPart'))",
          children: [
            {
              type: 'section',
              children: [
                {
                  id: 'orthopedics.painWhen.knee',
                  version: '1',
                  type: 'choice',
                  options: [
                    {
                      value: 'motion',
                      label: 'I rörelse',
                    },
                    {
                      value: 'rest',
                      label: 'I vila',
                    },
                    {
                      value: 'both',
                      label: 'I både rörelse och vila',
                    },
                  ],
                  condition: "contains(get('answers.joints.symptoms'), 'pain')",
                  validations: {
                    required: true,
                  },
                  label: 'När smärtar ditt knä?',
                  index: '0.22.5.0.0',
                },
                {
                  id: 'joints.unstableKnee',
                  type: 'binary',
                  version: '1',
                  validations: {
                    required: true,
                  },
                  label: 'Känns ditt knä instabilt?',
                  index: '0.22.5.0.1',
                },
                {
                  id: 'joints.unstableKneeWhen',
                  version: '3',
                  type: 'multipleChoice',
                  options: [
                    {
                      value: 'crouching',
                      label: 'När jag böjer mig ner/sätter mig på huk',
                    },
                    {
                      value: 'normalWalking',
                      label: 'När jag går som vanligt',
                    },
                    {
                      value: 'stairWalking',
                      label: 'När jag går i trappor',
                    },
                    {
                      value: 'other',
                      label: 'Annat',
                    },
                  ],
                  validations: {
                    required: true,
                  },
                  condition: "get('answers.joints.unstableKnee') === 'yes'",
                  label: 'Vid vilken aktivitet/rörelse känns knät instabilt?',
                  description: 'Flera svarsalternativ möjliga.',
                  index: '0.22.5.0.2',
                },
                {
                  id: 'joints.unstableKneeWhenOther',
                  version: '1',
                  type: 'text',
                  condition:
                    "contains(get('answers.joints.unstableKneeWhen'), 'other')",
                  validations: {
                    required: true,
                  },
                  label:
                    'Vid vilken annan aktivitet/rörelse känns knät instabilt?',
                  index: '0.22.5.0.3',
                },
              ],
              version: '1',
              index: '0.22.5.0',
            },
            {
              type: 'section',
              children: [
                {
                  id: 'joints.bentKneeStuck',
                  version: '3',
                  type: 'binary',
                  validations: {
                    required: true,
                  },
                  label: 'Händer det att knät låser sig i böjt läge?',
                  index: '0.22.5.1.0',
                },
                {
                  id: 'orthopedics.bodyParts.warmerOrColder.knee',
                  version: '1',
                  type: 'binary',
                  validations: {
                    required: true,
                  },
                  label: 'Känns ditt knä varmt, jämfört med ditt andra knä?',
                  index: '0.22.5.1.1',
                },
              ],
              version: '1',
              index: '0.22.5.1',
            },
          ],
          version: '1',
          index: '0.22.5',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.bodyParts.warmerOrColder.lowerLegFoot',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'warmer',
                  label: 'Det känns varmare',
                },
                {
                  value: 'colder',
                  label: 'Det känns kallare',
                },
                {
                  value: 'no',
                  label: 'Inget av ovanstående',
                },
              ],
              condition:
                "contains([ 'shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft', 'toesRight', 'toesLeft' ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label:
                'Känns det underben/den fot som besvärar dig varmare eller kallare än det andra underbenet?',
              index: '0.22.6.0',
            },
            {
              id: 'joints.unstableFoot',
              type: 'binary',
              version: '1',
              condition:
                "contains([ 'ankleRight', 'ankleLeft', 'footRight', 'footLeft' ], get('variables.physio.chosenBodyPart'))",
              validations: {
                required: true,
              },
              label: 'Känns din fot instabil?',
              index: '0.22.6.1',
            },
            {
              id: 'joints.unstableFootWhen',
              type: 'text',
              version: '1',
              condition: "get('answers.joints.unstableFoot') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Vid vilken aktivitet/rörelse känns foten instabil?',
              index: '0.22.6.2',
            },
          ],
          version: '1',
          index: '0.22.6',
        },
        {
          type: 'section',
          children: [
            {
              id: 'orthopedics.shoes.changeMatch',
              version: '1',
              type: 'binary',
              condition:
                "contains([ 'within24hours', 'last2to4days', 'last5to14days', '2weeksTo3Months' ], get('answers.started'))",
              validations: {
                required: true,
              },
              label:
                'Har du bytt skor eller förändrat något gällande vilken typ av skor du använt den senaste tiden?',
              index: '0.22.7.0',
            },
            {
              id: 'orthopedics.shoes.changeWhat',
              version: '1',
              type: 'text',
              condition:
                "get('answers.orthopedics.shoes.changeMatch') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Beskriv förändringen.',
              index: '0.22.7.1',
            },
          ],
          version: '1',
          index: '0.22.7',
        },
      ],
      version: '1',
      index: '0.22',
    },
    {
      type: 'section',
      condition:
        "contains([ 'hipRight', 'hipLeft', 'groinRight', 'groinLeft', 'buttockRight', 'buttockLeft', 'thighRight', 'thighLeft' ], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.hipToThigh.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra sex korta övningar.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.23.0.0',
            },
            {
              id: 'physio.test.hipToThigh.squat.blocked.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 1 Tog det emot någonstans?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Gå ner på huk och res dig upp igen. Det går bra att ta stöd av en vägg eller liknande.\n  </p>',
              index: '0.23.0.1',
            },
            {
              id: 'physio.test.hipToThigh.squat.blocked.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.hipToThigh.squat.blocked.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 1 Beskriv var i rörelsen det tog emot.',
              index: '0.23.0.2',
            },
            {
              id: 'physio.test.hipToThigh.squat.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.hipToThigh.squat.blocked.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.23.0.3',
            },
          ],
          version: '1',
          index: '0.23.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.hipToThigh.standOnOneLeg.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, med båda fötterna',
                },
                {
                  value: 'right',
                  label: 'Endast med höger fot',
                },
                {
                  value: 'left',
                  label: 'Endast med vänster fot',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 2 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan.\n  </p>',
              index: '0.23.1.0',
            },
            {
              id: 'physio.test.hipToThigh.standOnOneLeg.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.hipToThigh.standOnOneLeg.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.23.1.1',
            },
          ],
          version: '1',
          index: '0.23.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.hipToThigh.walkOnHeels.couldTest',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 3 Kunde du genomföra övningen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande.\n  </p>',
              index: '0.23.2.0',
            },
            {
              id: 'physio.test.hipToThigh.walkOnHeels.problemWhere',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'På höger sida',
                },
                {
                  value: 'left',
                  label: 'På vänster sida',
                },
                {
                  value: 'both',
                  label: 'På båda sidor',
                },
              ],
              condition:
                "contains(['no'], get('answers.physio.test.hipToThigh.walkOnHeels.couldTest'))",
              validations: {
                required: true,
              },
              label: 'Övning 3 På vilken sida har du problem?',
              index: '0.23.2.1',
            },
          ],
          version: '1',
          index: '0.23.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.hipToThigh.bendKnee.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Du behöver enbart göra övningen på den sida av kroppen där du har dina besvär. Lägg dig på mage. Böj i knät så att fotsulan pekar mot taket. Fäll ut benet åt sidan och tillbaka igen.\n  </p>',
              index: '0.23.3.0',
            },
            {
              id: 'physio.test.hipToThigh.bendKnee.pain.where',
              version: '1',
              type: 'multipleChoice',
              options: [
                {
                  value: 'hip',
                  label: 'I höften',
                },
                {
                  value: 'thighFront',
                  label: 'På framsidan av låret',
                },
                {
                  value: 'thighBack',
                  label: 'På baksidan av låret',
                },
                {
                  value: 'thighOutside',
                  label: 'På utsidan av låret',
                },
                {
                  value: 'groin',
                  label: 'I ljumsken',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "get('answers.physio.test.hipToThigh.bendKnee.pain.match') === 'yes'",
              label: 'Övning 4 Var gjorde det ont?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.23.3.1',
            },
            {
              id: 'physio.test.hipToThigh.bendKnee.pain.whereOther',
              version: '1',
              type: 'text',
              condition:
                "contains(get('answers.physio.test.hipToThigh.bendKnee.pain.where'), 'other')",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var det gjorde ont.',
              index: '0.23.3.2',
            },
            {
              id: 'physio.test.hipToThigh.bendKnee.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.hipToThigh.bendKnee.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.23.3.3',
            },
          ],
          version: '1',
          index: '0.23.3',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.hipToThigh.legToSide.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 5 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Du behöver enbart göra övningen på den sida av kroppen där du har dina besvär. Lägg dig på rygg. Böj på benen. Fäll ut benet åt sidan och tillbaka igen.\n  </p>',
              index: '0.23.4.0',
            },
            {
              id: 'physio.test.hipToThigh.legToSide.pain.where',
              version: '1',
              type: 'multipleChoice',
              options: [
                {
                  value: 'hip',
                  label: 'I höften',
                },
                {
                  value: 'thighFront',
                  label: 'På framsidan av låret',
                },
                {
                  value: 'thighBack',
                  label: 'På baksidan av låret',
                },
                {
                  value: 'thighOutside',
                  label: 'På utsidan av låret',
                },
                {
                  value: 'groin',
                  label: 'I ljumsken',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "get('answers.physio.test.hipToThigh.legToSide.pain.match') === 'yes'",
              label: 'Övning 5 Var gjorde det ont?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.23.4.1',
            },
            {
              id: 'physio.test.hipToThigh.legToSide.pain.whereOther',
              version: '1',
              type: 'text',
              condition:
                "contains(get('answers.physio.test.hipToThigh.legToSide.pain.where'), 'other')",
              validations: {
                required: true,
              },
              label: 'Övning 5 Beskriv var det gjorde ont.',
              index: '0.23.4.2',
            },
            {
              id: 'physio.test.hipToThigh.legToSide.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.hipToThigh.legToSide.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.23.4.3',
            },
          ],
          version: '1',
          index: '0.23.4',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.hipToThigh.kneeUp.pain.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 6 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Du behöver enbart göra övningen på den sida av kroppen där du har dina besvär. Lägg dig på rygg. Dra ena knät upp mot magen. Hjälp till med händerna.\n  </p>',
              index: '0.23.5.0',
            },
            {
              id: 'physio.test.hipToThigh.kneeUp.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.hipToThigh.kneeUp.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 6 Beskriv var det gjorde ont.',
              index: '0.23.5.1',
            },
            {
              id: 'physio.test.hipToThigh.kneeUp.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.hipToThigh.kneeUp.pain.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 6 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.23.5.2',
            },
          ],
          version: '1',
          index: '0.23.5',
        },
      ],
      version: '1',
      index: '0.23',
    },
    {
      type: 'section',
      condition:
        "contains([ 'kneeRight', 'kneeLeft' ], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.knee.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra fem korta övningar.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.24.0.0',
            },
            {
              id: 'physio.test.knee.squat.blocked.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 1 Tog det emot någonstans?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Gå ner på huk och res dig upp igen. Det går bra att ta stöd av en vägg eller liknande.\n  </p>',
              index: '0.24.0.1',
            },
            {
              id: 'physio.test.knee.squat.blocked.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.knee.squat.blocked.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 1 Beskriv var i rörelsen det tog emot.',
              index: '0.24.0.2',
            },
            {
              id: 'physio.test.knee.squat.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.knee.squat.blocked.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.24.0.3',
            },
          ],
          version: '1',
          index: '0.24.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.knee.standOnOneLeg.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, med båda fötterna',
                },
                {
                  value: 'right',
                  label: 'Endast med höger fot',
                },
                {
                  value: 'left',
                  label: 'Endast med vänster fot',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 2 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan.\n  </p>',
              index: '0.24.1.0',
            },
            {
              id: 'physio.test.knee.standOnOneLeg.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.knee.standOnOneLeg.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.24.1.1',
            },
          ],
          version: '1',
          index: '0.24.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.knee.walkOnHeels.couldTest',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 3 Kunde du genomföra övningen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande.\n  </p>',
              index: '0.24.2.0',
            },
            {
              id: 'physio.test.knee.walkOnHeels.problemWhere',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'På höger sida',
                },
                {
                  value: 'left',
                  label: 'På vänster sida',
                },
                {
                  value: 'both',
                  label: 'På båda sidor',
                },
              ],
              condition:
                "contains(['no'], get('answers.physio.test.knee.walkOnHeels.couldTest'))",
              validations: {
                required: true,
              },
              label: 'Övning 3 På vilken sida har du problem?',
              index: '0.24.2.1',
            },
          ],
          version: '1',
          index: '0.24.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.knee.stretch.equalAmount',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 4 Kunde du sträcka lika mycket på båda sidor?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_4.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_4.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_4.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_4.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Ligg på rygg med benen utsträckta. Lägg en smal kudde eller hoprullad handduk under knäna. Sträck ut i knäna så mycket du kan på båda benen.\n  </p>',
              index: '0.24.3.0',
            },
            {
              id: 'physio.test.knee.stretch.pain.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              index: '0.24.3.1',
            },
            {
              id: 'physio.test.knee.stretch.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.knee.stretch.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var det gjorde ont.',
              index: '0.24.3.2',
            },
          ],
          version: '1',
          index: '0.24.3',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.knee.heelUp.equalAmount',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 5 Kunde du böja lika mycket på båda sidor?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/4_fysio_lar_5.jpg">\n  </video>\n  <p>\n    Ligg på rygg med benen utsträckta.<br>\n    Börja med det ben som du inte har ont i: Böj knät så mycket du kan så att hälen närmar sig rumpan. Hjälp till med händerna.<br>\n    Gör samma sak med det ben som du har ont i.\n  </p>',
              index: '0.24.4.0',
            },
            {
              id: 'physio.test.knee.heelUp.pain.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 5 Gjorde det ont?',
              index: '0.24.4.1',
            },
            {
              id: 'physio.test.knee.heelUp.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.knee.heelUp.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 5 Beskriv var det gjorde ont.',
              index: '0.24.4.2',
            },
          ],
          version: '1',
          index: '0.24.4',
        },
      ],
      version: '1',
      index: '0.24',
    },
    {
      type: 'section',
      condition:
        "contains([ 'shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft', 'toesRight', 'toesLeft' ], get('variables.physio.chosenBodyPart'))",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lowerLegToToes.info',
              version: '1',
              type: 'info',
              validations: {
                required: false,
              },
              description:
                '  <p>\n    Du ska nu få genomföra fem korta övningar.\n  </p>\n\n  <p>\n    Notera om dina besvär förändras på något vis under rörelsen och i så fall i vilken position.\n  </p>',
              index: '0.25.0.0',
            },
            {
              id: 'physio.test.lowerLegToToes.squat.blocked.match',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'cannotTest',
                  label: 'Jag kunde inte genomföra rörelsen',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 1 Tog det emot någonstans?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_1.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Gå ner på huk och res dig upp igen. Det går bra att ta stöd av en vägg eller liknande.\n  </p>',
              index: '0.25.0.1',
            },
            {
              id: 'physio.test.lowerLegToToes.squat.blocked.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lowerLegToToes.squat.blocked.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 1 Beskriv var i rörelsen det tog emot.',
              index: '0.25.0.2',
            },
            {
              id: 'physio.test.lowerLegToToes.squat.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lowerLegToToes.squat.blocked.match') === 'cannotTest'",
              validations: {
                required: true,
              },
              label:
                'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.25.0.3',
            },
          ],
          version: '1',
          index: '0.25.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lowerLegToToes.standOnOneLeg.couldTest',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'both',
                  label: 'Ja, med båda fötterna',
                },
                {
                  value: 'right',
                  label: 'Endast med höger fot',
                },
                {
                  value: 'left',
                  label: 'Endast med vänster fot',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 2 Kunde du genomföra rörelsen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan.\n  </p>',
              index: '0.25.1.0',
            },
            {
              id:
                'physio.test.lowerLegToToes.standOnOneLeg.cannotTest.description',
              version: '1',
              type: 'text',
              condition:
                "contains(['right', 'left', 'no'], get('answers.physio.test.lowerLegToToes.standOnOneLeg.couldTest'))",
              validations: {
                required: true,
              },
              label:
                'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
              index: '0.25.1.1',
            },
          ],
          version: '1',
          index: '0.25.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lowerLegToToes.walkOnHeels.couldTest',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 3 Kunde du genomföra övningen?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/3_fysio_ljumske_3.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande.\n  </p>',
              index: '0.25.2.0',
            },
            {
              id: 'physio.test.lowerLegToToes.walkOnHeels.problemWhere',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'right',
                  label: 'På höger sida',
                },
                {
                  value: 'left',
                  label: 'På vänster sida',
                },
                {
                  value: 'both',
                  label: 'På båda sidor',
                },
              ],
              condition:
                "contains(['no'], get('answers.physio.test.lowerLegToToes.walkOnHeels.couldTest'))",
              validations: {
                required: true,
              },
              label: 'Övning 3 På vilken sida har du problem?',
              index: '0.25.2.1',
            },
          ],
          version: '1',
          index: '0.25.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lowerLegToToes.leanFeet.equalAmountBend',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'moreRight',
                  label: 'Nej, jag kunde böja mer på höger.',
                },
                {
                  value: 'moreLeft',
                  label: 'Nej, jag kunde böja mer på vänster.',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 4 Kunde du böja uppåt lika mycket på båda sidor?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_2_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_2_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_2_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_2_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Sitt på golvet med en tjock kudde eller liknande under underbenen, så att hälarna inte rör golvet. Vinkla fötterna mot dig respektive bort från dig.\n  </p>',
              index: '0.25.3.0',
            },
            {
              id: 'physio.test.lowerLegToToes.leanFeet.painBend.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 4 Gjorde det ont?',
              index: '0.25.3.1',
            },
            {
              id: 'physio.test.lowerLegToToes.leanFeet.painBend.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lowerLegToToes.leanFeet.painBend.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 4 Beskriv var det gjorde ont.',
              index: '0.25.3.2',
            },
            {
              id: 'physio.test.lowerLegToToes.leanFeet.equalAmountStretch',
              version: '1',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'moreRight',
                  label: 'Nej, jag kunde sträcka ut mer på höger.',
                },
                {
                  value: 'moreLeft',
                  label: 'Nej, jag kunde sträcka ut mer på vänster.',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Övning 5 Kunde du sträcka ut lika mycket på båda sidor?',
              index: '0.25.3.3',
            },
            {
              id: 'physio.test.lowerLegToToes.leanFeet.painStretch.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 5 Gjorde det ont?',
              index: '0.25.3.4',
            },
            {
              id: 'physio.test.lowerLegToToes.leanFeet.painStretch.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lowerLegToToes.leanFeet.painStretch.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 5 Beskriv var det gjorde ont.',
              index: '0.25.3.5',
            },
          ],
          version: '1',
          index: '0.25.3',
        },
        {
          type: 'section',
          children: [
            {
              id: 'physio.test.lowerLegToToes.footOnKnee.pain.match',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Övning 6 Gjorde det ont?',
              prologue:
                '  <video width="640" height="360" controls autoplay muted loop poster="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_3_2.jpg">\n    <!-- MP4 must be first for iPad! -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_3_2.mp4" type="video/mp4"><!-- Safari / iOS, IE9 -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_3_2.webm" type="video/webm"><!-- Chrome10+, Ffx4+, Opera10.6+ -->\n    <source src="https://storage.googleapis.com/mindoktor-assets/formulary/physio-videos/5_fysio_underben_3_2.ogv" type="video/ogg"><!-- Firefox3.6+ / Opera 10.5+ -->\n  </video>\n  <p>\n    Sätt dig på en stol. Lägg upp foten på det ben som besvärar dig på motsatt knä. Håll fast hälen med ena handen och sträck med andra handen ut undersidan av foten. Du behöver enbart göra övningen på den sida av kroppen där du har dina besvär.\n  </p>',
              index: '0.25.4.0',
            },
            {
              id: 'physio.test.lowerLegToToes.footOnKnee.pain.description',
              version: '1',
              type: 'text',
              condition:
                "get('answers.physio.test.lowerLegToToes.footOnKnee.pain.match') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Övning 6 Beskriv var det gjorde ont.',
              index: '0.25.4.1',
            },
          ],
          version: '1',
          index: '0.25.4',
        },
      ],
      version: '1',
      index: '0.25',
    },
    {
      type: 'section',
      children: [
        {
          id: 'physio.video.walking',
          version: '1',
          type: 'upload',
          types: 'video/mp4,video/webm,video/quicktime',
          validations: {
            required: false,
            max: 1,
          },
          condition:
            "contains([ 'hipRight', 'hipLeft', 'groinRight', 'groinLeft', 'buttockRight', 'buttockLeft', 'thighRight', 'thighLeft', 'kneeRight', 'kneeLeft', 'shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft', 'toesRight', 'toesLeft' ], get('variables.physio.chosenBodyPart'))",
          label:
            'Om du har möjlighet, filma gärna när du går ca 15 steg, fram och tillbaka två gånger.',
          index: '0.26.0',
        },
      ],
      index: '0.26',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.fever',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du feber (över 38°C)?',
          index: '0.27.0',
        },
        {
          id: 'lumbago.weightLossOrIllness',
          type: 'binary',
          version: '3',
          validations: {
            required: true,
          },
          label:
            'Har du ofrivilligt gått ner i vikt eller känt dig allmänt sjuk tiden före dina besvär?',
          index: '0.27.1',
        },
      ],
      version: '1',
      index: '0.27',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.cancer',
          version: '2',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Har du, eller har du tidigare haft, cancer?',
          index: '0.28.0',
        },
        {
          id: 'health.cortisone',
          version: '3',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Blir du regelbundet behandlad med kortison i tablett- eller sprutform?',
          index: '0.28.1',
        },
      ],
      version: '1',
      index: '0.28',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.previousTreatment.xray.match',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du blivit röntgad på grund av dina besvär?',
          index: '0.29.0',
        },
        {
          id: 'problem.previousTreatment.xray.what',
          version: '1',
          type: 'multipleChoice',
          validations: {
            required: true,
          },
          options: [
            {
              value: 'xray',
              label: 'Slätröntgen ("vanlig" röntgen)',
            },
            {
              value: 'ct',
              label: 'Datortomografi/CT (skiktröntgen)',
            },
            {
              value: 'mr',
              label: 'MR/Magnetkamera',
            },
          ],
          condition:
            "get('answers.problem.previousTreatment.xray.match') === 'yes'",
          label: 'Vilken röntgenundersökning gjordes?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.29.1',
        },
        {
          id: 'problem.previousTreatment.xray.result',
          version: '1',
          type: 'text',
          condition:
            "get('answers.problem.previousTreatment.xray.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vad visade röntgen?',
          index: '0.29.2',
        },
      ],
      version: '1',
      index: '0.29',
    },
    {
      type: 'section',
      children: [
        {
          id: 'joints.previousPhysiotherapyMatch',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Har du provat sjukgymnastik/fysioterapi eller arbetsterapi för dina besvär?',
          index: '0.30.0',
        },
        {
          id: 'joints.previousPhysiotherapyFollowUp',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'better',
              label: 'Jag upplevde förbättring.',
            },
            {
              value: 'noChange',
              label: 'Jag upplevde ingen förbättring.',
            },
            {
              value: 'aborted',
              label: 'Jag fullföljde inte behandlingen.',
            },
          ],
          condition:
            "get('answers.joints.previousPhysiotherapyMatch') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Hur fungerade sjukgymnastiken/fysioterapin eller arbetsterapin?',
          index: '0.30.1',
        },
      ],
      version: '1',
      index: '0.30',
    },
    {
      type: 'section',
      children: [
        {
          id: 'joints.previousSurgeryMatch',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Har du någon gång blivit opererad i den kroppsdel där du har dina nuvarande besvär?',
          index: '0.31.0',
        },
        {
          id: 'joints.previousSurgeryWhat',
          version: '1',
          type: 'text',
          condition: "get('answers.joints.previousSurgeryMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilken operation gjordes?',
          index: '0.31.1',
        },
        {
          id: 'joints.previousSurgeryWhen',
          version: '1',
          type: 'text',
          condition: "get('answers.joints.previousSurgeryMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'När gjordes operationen?',
          index: '0.31.2',
        },
      ],
      version: '1',
      index: '0.31',
    },
    {
      type: 'section',
      children: [
        {
          id: 'medication.tried',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du provat några läkemedel mot dina besvär?',
          index: '0.32.0',
        },
        {
          id: 'joints.medication.what',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'acetylsalicylic',
              label: 'Acetylsalicylsyra (t.ex. Treo, Aspirin)',
            },
            {
              value: 'antiInflammatory',
              label:
                'Antiinflammatoriska läkemedel (t.ex. Ipren, Ibuprofen, Voltaren, Diklofenak, Orudis, Naproxen, Pronaxen)',
            },
            {
              value: 'chlorzoxazone',
              label: 'Klorzoxazon (Paraflex)',
            },
            {
              value: 'cortisoneInjection',
              label: 'Kortisoninjektion',
            },
            {
              value: 'cortisonePill',
              label: 'Kortisontabletter (Betapred, Prednisolon)',
            },
            {
              value: 'morphine',
              label: 'Morfinpreparat (t.ex. Dolcontin, Oxycontin, Oxynorm)',
            },
            {
              value: 'paracetamol',
              label: 'Paracetamol (t.ex. Alvedon, Panodil, Pamol)',
            },
            {
              value: 'paracetamolCodeine',
              label: 'Paracetamol/Kodein (t.ex. Citodon, Panocod)',
            },
            {
              value: 'tramadol',
              label: 'Tramadol (t.ex. Tradolan, Nobligan)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.medication.tried') === 'yes'",
          label: 'Vilka läkemedel har du provat?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.32.1',
        },
        {
          id: 'joints.medication.other',
          type: 'text',
          version: '1',
          condition: "contains(get('answers.joints.medication.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilket annat läkemedel har du provat?',
          index: '0.32.2',
        },
        {
          id: 'joints.medication.effect',
          type: 'text',
          version: '1',
          condition: "get('answers.medication.tried') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur fungerade läkemedlen?',
          index: '0.32.3',
        },
      ],
      version: '1',
      index: '0.32',
    },
    {
      type: 'section',
      children: [
        {
          id: 'joints.previousAid',
          type: 'composite',
          version: '2',
          children: [
            {
              id: 'joints.previousAid.match',
              type: 'binary',
              relativeId: 'match',
              version: '2',
              validations: {
                required: true,
              },
              label:
                'Har du provat någon form av hjälpmedel för att lindra besvären?',
              description: 'T.ex. skoinlägg, korsett, stödbandage.',
              index: '0.33.0.0',
            },
            {
              id: 'joints.previousAid.what',
              type: 'multipleChoice',
              options: [
                {
                  value: 'insole',
                  label: 'Skoinlägg',
                },
                {
                  value: 'corset',
                  label: 'Korsett',
                },
                {
                  value: 'supporter',
                  label: 'Stödbandage',
                },
                {
                  value: 'rail',
                  label: 'Stödskena/Ortos',
                },
                {
                  value: 'crutches',
                  label: 'Kryckor',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition: "get('answers.joints.previousAid.match') === 'yes'",
              relativeId: 'what',
              version: '2',
              label: 'Vilka hjälpmedel har du provat?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.33.0.1',
            },
            {
              id: 'joints.previousAid.other',
              type: 'text',
              condition:
                "contains(get('answers.joints.previousAid.what'), 'other')",
              relativeId: 'other',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Vilket annat hjälpmedel har du provat?',
              index: '0.33.0.2',
            },
            {
              id: 'joints.previousAid.effect',
              type: 'text',
              condition: "get('answers.joints.previousAid.match') === 'yes'",
              relativeId: 'effect',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Hur fungerade hjälpmedlen?',
              index: '0.33.0.3',
            },
          ],
          extras: {},
          index: '0.33.0',
        },
      ],
      version: '1',
      index: '0.33',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.34.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.34.1',
        },
      ],
      version: '1',
      index: '0.34',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          index: '0.35.0',
        },
        {
          id: 'reproduction.week',
          type: 'number',
          version: '2',
          validations: {
            required: true,
            min: 1,
            max: 45,
          },
          condition: "get('answers.reproduction.pregnant') === 'yes'",
          label: 'Ange graviditetsvecka.',
          hint: 'Anges i siffror.',
          index: '0.35.1',
        },
        {
          id: 'reproduction.recentlyInLabor',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du fött barn under det senaste året?',
          warning: "get('answers.reproduction.recentlyInLabor') === 'yes'",
          index: '0.35.2',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          index: '0.35.3',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.35',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.36.0',
        },
      ],
      index: '0.36',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.37',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.38',
    },
  ],
  variables: [
    {
      id: 'physio.chosenBodyPart',
      type: 'text',
      expression:
        "get('answers.orthopedics.bodyParts.help') || ( get('answers.orthopedics.bodyParts.problems') && get('answers.orthopedics.bodyParts.problems')[0] )",
      relativeId: 'chosenBodyPart',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilket är ditt huvudsakliga besvär?',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'När började dina besvär?',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: '3monthsTo12Months',
              label: 'För 3–12 månader sedan',
            },
            {
              value: 'overOneYear',
              label: 'För mer än 1 år sedan',
            },
          ],
        },
        {
          id: 'startedSpecific',
          type: 'question',
          version: '1',
          extras: {},
          label: 'För hur länge sedan började dina besvär?',
        },
        {
          id: 'orthopedics.bodyParts.problems',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'I vilka kroppsdelar har du besvär?',
          options: [
            {
              value: 'head',
              label: 'Huvud',
            },
            {
              value: 'nape',
              label: 'Nacke',
            },
            {
              value: 'thoracicSpine',
              label:
                'Bröstrygg (mellan skulderbladen och/eller mitten av ryggen)',
            },
            {
              value: 'lumbarSpine',
              label: 'Ländrygg/Ryggslut',
            },
            {
              value: 'shoulderRight',
              label: 'Axel (höger)',
            },
            {
              value: 'shoulderLeft',
              label: 'Axel (vänster)',
            },
            {
              value: 'upperArmRight',
              label: 'Överarm (höger)',
            },
            {
              value: 'upperArmLeft',
              label: 'Överarm (vänster)',
            },
            {
              value: 'elbowRight',
              label: 'Armbåge (höger)',
            },
            {
              value: 'elbowLeft',
              label: 'Armbåge (vänster)',
            },
            {
              value: 'forearmRight',
              label: 'Underarm (höger)',
            },
            {
              value: 'forearmLeft',
              label: 'Underarm (vänster)',
            },
            {
              value: 'wristRight',
              label: 'Handled (höger)',
            },
            {
              value: 'wristLeft',
              label: 'Handled (vänster)',
            },
            {
              value: 'handRight',
              label: 'Hand (höger)',
            },
            {
              value: 'handLeft',
              label: 'Hand (vänster)',
            },
            {
              value: 'fingersRight',
              label: 'Finger (höger hand)',
            },
            {
              value: 'fingersLeft',
              label: 'Finger (vänster hand)',
            },
            {
              value: 'hipRight',
              label: 'Höft (höger)',
            },
            {
              value: 'hipLeft',
              label: 'Höft (vänster)',
            },
            {
              value: 'groinRight',
              label: 'Ljumske (höger)',
            },
            {
              value: 'groinLeft',
              label: 'Ljumske (vänster)',
            },
            {
              value: 'buttockRight',
              label: 'Skinka (höger)',
            },
            {
              value: 'buttockLeft',
              label: 'Skinka (vänster)',
            },
            {
              value: 'thighRight',
              label: 'Lår (höger)',
            },
            {
              value: 'thighLeft',
              label: 'Lår (vänster)',
            },
            {
              value: 'kneeRight',
              label: 'Knä (höger)',
            },
            {
              value: 'kneeLeft',
              label: 'Knä (vänster)',
            },
            {
              value: 'shankRight',
              label: 'Underben (höger)',
            },
            {
              value: 'shankLeft',
              label: 'Underben (vänster)',
            },
            {
              value: 'ankleRight',
              label: 'Fotled (höger)',
            },
            {
              value: 'ankleLeft',
              label: 'Fotled (vänster)',
            },
            {
              value: 'footRight',
              label: 'Fot (höger)',
            },
            {
              value: 'footLeft',
              label: 'Fot (vänster)',
            },
            {
              value: 'toesRight',
              label: 'Tå (höger fot)',
            },
            {
              value: 'toesLeft',
              label: 'Tå (vänster fot)',
            },
          ],
        },
        {
          id: 'orthopedics.bodyParts.help',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Ange i vilken kroppsdel ditt huvudsakliga besvär sitter.',
          options: [
            {
              value: 'head',
              label: 'Huvud',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'head')",
            },
            {
              value: 'nape',
              label: 'Nacke',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'nape')",
            },
            {
              value: 'thoracicSpine',
              label:
                'Bröstrygg (mellan skulderbladen och/eller mitten av ryggen)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'thoracicSpine')",
            },
            {
              value: 'lumbarSpine',
              label: 'Ländrygg/Ryggslut',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'lumbarSpine')",
            },
            {
              value: 'shoulderRight',
              label: 'Axel (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shoulderRight')",
            },
            {
              value: 'shoulderLeft',
              label: 'Axel (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shoulderLeft')",
            },
            {
              value: 'upperArmRight',
              label: 'Överarm (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'upperArmRight')",
            },
            {
              value: 'upperArmLeft',
              label: 'Överarm (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'upperArmLeft')",
            },
            {
              value: 'elbowRight',
              label: 'Armbåge (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'elbowRight')",
            },
            {
              value: 'elbowLeft',
              label: 'Armbåge (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'elbowLeft')",
            },
            {
              value: 'forearmRight',
              label: 'Underarm (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'forearmRight')",
            },
            {
              value: 'forearmLeft',
              label: 'Underarm (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'forearmLeft')",
            },
            {
              value: 'wristRight',
              label: 'Handled (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'wristRight')",
            },
            {
              value: 'wristLeft',
              label: 'Handled (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'wristLeft')",
            },
            {
              value: 'handRight',
              label: 'Hand (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'handRight')",
            },
            {
              value: 'handLeft',
              label: 'Hand (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'handLeft')",
            },
            {
              value: 'fingersRight',
              label: 'Finger (höger hand)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'fingersRight')",
            },
            {
              value: 'fingersLeft',
              label: 'Finger (vänster hand)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'fingersLeft')",
            },
            {
              value: 'hipRight',
              label: 'Höft (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'hipRight')",
            },
            {
              value: 'hipLeft',
              label: 'Höft (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'hipLeft')",
            },
            {
              value: 'groinRight',
              label: 'Ljumske (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'groinRight')",
            },
            {
              value: 'groinLeft',
              label: 'Ljumske (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'groinLeft')",
            },
            {
              value: 'buttockRight',
              label: 'Skinka (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'buttockRight')",
            },
            {
              value: 'buttockLeft',
              label: 'Skinka (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'buttockLeft')",
            },
            {
              value: 'thighRight',
              label: 'Lår (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'thighRight')",
            },
            {
              value: 'thighLeft',
              label: 'Lår (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'thighLeft')",
            },
            {
              value: 'kneeRight',
              label: 'Knä (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'kneeRight')",
            },
            {
              value: 'kneeLeft',
              label: 'Knä (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'kneeLeft')",
            },
            {
              value: 'shankRight',
              label: 'Underben (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shankRight')",
            },
            {
              value: 'shankLeft',
              label: 'Underben (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'shankLeft')",
            },
            {
              value: 'ankleRight',
              label: 'Fotled (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'ankleRight')",
            },
            {
              value: 'ankleLeft',
              label: 'Fotled (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'ankleLeft')",
            },
            {
              value: 'footRight',
              label: 'Fot (höger)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'footRight')",
            },
            {
              value: 'footLeft',
              label: 'Fot (vänster)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'footLeft')",
            },
            {
              value: 'toesRight',
              label: 'Tå (höger fot)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'toesRight')",
            },
            {
              value: 'toesLeft',
              label: 'Tå (vänster fot)',
              condition:
                "contains(get('answers.orthopedics.bodyParts.problems'), 'toesLeft')",
            },
          ],
        },
        {
          id: 'lumbago.relatedToLift',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Började besvären i samband med lyft och/eller vridning?',
        },
        {
          id: 'lumbago.relatedToAccident',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Började besvären efter fall, slag, skada eller liknande?',
        },
        {
          id: 'problem.describeCause',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Beskriv kortfattat vad som hände.',
        },
        {
          id: 'joints.symptoms',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Vilka besvär har du?',
          options: [
            {
              value: 'pain',
              label: 'Smärta',
            },
            {
              value: 'stiffness',
              label: 'Stelhet',
            },
            {
              value: 'weakness',
              label: 'Svaghet',
            },
            {
              value: 'swelling',
              label: 'Svullnad',
            },
            {
              value: 'blushing',
              label: 'Rodnad',
            },
            {
              value: 'numb',
              label: 'Domning/stickning',
            },
            {
              value: 'hot',
              label: 'Leden känns varm',
            },
            {
              value: 'crack',
              label: 'Leden knäpper eller knakar',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'problem.symptoms.current',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Vilka besvär har du?',
        },
        {
          id: 'health.painDescription',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Hur skulle du beskriva smärtan?',
          options: [
            {
              value: 'stabbing',
              label: 'Huggande',
            },
            {
              value: 'throbbing',
              label: 'Bultande',
            },
            {
              value: 'dull',
              label: 'Molande',
            },
            {
              value: 'burning',
              label: 'Brännande',
            },
            {
              value: 'tingling',
              label: 'Stickande',
            },
            {
              value: 'cutting',
              label: 'Skärande',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'health.painDescriptionText',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Hur skulle du beskriva smärtan?',
        },
        {
          id: 'health.painEvaluationWorst',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hur ont gör det när det gör som mest ont? Skala 0–10',
          description: '',
          min: {
            value: 0,
            label: 'Ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'Värsta tänkbara smärta',
          },
        },
        {
          id: 'health.painEvaluationNotTriggered',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hur ont har du när du inte triggar dina besvär? Skala 0–10',
          description: '',
          min: {
            value: 0,
            label: 'Ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'Värsta tänkbara smärta',
          },
        },
        {
          id: 'orthopedics.painWhen.lessWhenStill',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har du betydligt mindre ont när du är stilla?',
        },
        {
          id: 'orthopedics.painWhen.varying',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Varierar din smärta över dygnet?',
        },
        {
          id: 'lumbago.timeOfDay',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'När på dygnet är din smärta som värst?',
          options: [
            {
              value: 'morning',
              label: 'På morgonen',
            },
            {
              value: 'day',
              label: 'På dagen',
            },
            {
              value: 'evening',
              label: 'På kvällen',
            },
            {
              value: 'night',
              label: 'På natten',
            },
          ],
        },
        {
          id: 'lumbago.wakeUpFromPain',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Störs din sömn av dina besvär?',
        },
        {
          id: 'orthopedics.painWhen.pressuring',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Gör det ont om du trycker med fingrarna på området där du har dina besvär?',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotReach',
              label: 'Jag kommer inte åt att undersöka pga smärta/orörlighet.',
            },
          ],
        },
        {
          id: 'orthopedics.stiff.how',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hur yttrar sig din stelhet?',
          options: [
            {
              value: 'morning',
              label: 'Jag känner mig stel när jag vaknar på morgonen.',
            },
            {
              value: 'stiffAlways',
              label: 'Jag känner mig stel hela tiden.',
            },
          ],
        },
        {
          id: 'orthopedics.weak.how',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Hur yttrar sig din svaghet?',
        },
        {
          id: 'lumbago.limitedMatch',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Är det någon särskild rörelse eller aktivitet som du har svårt att utföra på grund av dina besvär?',
        },
        {
          id: 'lumbago.limited',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Beskriv vilken rörelse eller aktivitet du har svårt att utföra.',
        },
        {
          id: 'orthopedics.swollen.where',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Var sitter svullnaden?',
        },
        {
          id: 'orthopedics.swollen.when',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'När uppstår svullnaden?',
        },
        {
          id: 'orthopedics.repeatedActivity.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Upplever du att dina besvär är kopplade till någon form av upprepad aktivitet, såsom för mycket stillasittande eller enformiga arbetsuppgifter?',
        },
        {
          id: 'orthopedics.repeatedActivity.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Beskriv vilken form av upprepad aktivitet du ägnat dig åt.',
        },
        {
          id: 'exercise.frequency',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hur ofta ägnar du dig åt pulshöjande, fysisk aktivitet?',
          description:
            'T.ex. raska promenader, löpning, gympa, cykling, dans eller motsvarande',
          options: [
            {
              value: 'never',
              label: 'Aldrig',
            },
            {
              value: 'lessThanWeekly',
              label: 'Mindre än 1 gång i veckan',
            },
            {
              value: 'oneToThreeTimesAWeek',
              label: '1–3 gånger i veckan',
            },
            {
              value: 'moreThan3TimesAWeek',
              label: 'Mer än 3 gånger i veckan',
            },
          ],
        },
        {
          id: 'exercise.type',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken typ av pulshöjande, fysisk aktivitet ägnar du dig åt?',
        },
        {
          id: 'exercise.worsenedBy',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Upplever du att dina besvär har försämrats av din fysiska aktivitet?',
        },
        {
          type: 'section',
          condition:
            "contains(['head', 'nape', 'thoracicSpine'], get('variables.physio.chosenBodyPart'))",
          children: [
            {
              id: 'orthopedics.bodyParts.pain.arms.match',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Strålar smärtan ut i armen/armarna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, smärtan strålar ut i höger arm.',
                },
                {
                  value: 'left',
                  label: 'Ja, smärtan strålar ut i vänster arm.',
                },
                {
                  value: 'both',
                  label: 'Ja, smärtan strålar ut i båda armarna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.numb.arms.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft domningar i armen/armarna alternativt ända ut i handen/händerna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.numb.arms.where',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft domningar.',
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft stickningar i armen/armarna alternativt ända ut i handen/händerna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.where',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft stickningar.',
            },
            {
              id: 'orthopedics.position.lessProblem.head.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva lindring av dina besvär?',
            },
            {
              id: 'orthopedics.position.lessProblem.head.description',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv vilken position för huvudet/nacken som lindrar dina besvär.',
            },
            {
              id: 'orthopedics.position.moreProblem.head.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva försämring av dina besvär?',
            },
            {
              id: 'orthopedics.position.moreProblem.head.description',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv vilken position för huvudet/nacken som försämrar dina besvär.',
            },
          ],
          version: '1',
        },
        {
          type: 'section',
          condition:
            "contains(['lumbarSpine'], get('variables.physio.chosenBodyPart'))",
          children: [
            {
              id: 'orthopedics.bodyParts.pain.legs.match',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Strålar smärtan ut i benet/benen?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, smärtan strålar ut i höger ben.',
                },
                {
                  value: 'left',
                  label: 'Ja, smärtan strålar ut i vänster ben.',
                },
                {
                  value: 'both',
                  label: 'Ja, smärtan strålar ut i båda benen.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.pain.legs.howFarRight',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Hur långt ut i höger ben strålar det?',
              options: [
                {
                  value: 'aboveKnee',
                  label: 'Det slutar stråla ovanför knät.',
                },
                {
                  value: 'belowKnee',
                  label: 'Det strålar även under knät eller ner i foten.',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.pain.legs.howFarLeft',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Hur långt ut i vänster ben strålar det?',
              options: [
                {
                  value: 'aboveKnee',
                  label: 'Det slutar stråla ovanför knät.',
                },
                {
                  value: 'belowKnee',
                  label: 'Det strålar även under knät eller ner i foten.',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.numb.legs.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft domningar i benet/benen alternativt ända ut i foten/fötterna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.numb.legs.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv var i benet eller foten/tårna du haft domningar.',
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft stickningar i benet/benen alternativt ända ut i foten/fötterna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.where',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv var i benet eller foten/tårna du haft stickningar.',
            },
            {
              id: 'health.numbnessPelvicArea',
              warning: "get('answers.health.numbnessPelvicArea') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du domningar i området kring ändtarmen eller könsorganen?',
            },
            {
              id: 'health.peeingIssues',
              warning: "get('answers.health.peeingIssues') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Händer det att du kissar på dig, eller inte känner när du är kissnödig?',
            },
            {
              id: 'lumbago.troublePeeingOrPooping',
              warning:
                "get('answers.lumbago.troublePeeingOrPooping') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du haft svårt att kissa/bajsa i samband med dina besvär?',
            },
            {
              id: 'problem.constantOrChanging',
              type: 'question',
              version: '1',
              extras: {},
              description: '',
              label: 'Hur har besvären yttrat sig sedan debuten?',
              options: [
                {
                  value: 'constant',
                  label: 'De har varit i princip oförändrade.',
                },
                {
                  value: 'comesAndGoes',
                  label: 'De har kommit och gått.',
                },
                {
                  value: 'growingWorse',
                  label: 'De har stadigt blivit värre.',
                },
              ],
            },
            {
              id: 'orthopedics.position.lessProblem.general.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra kroppsposition uppleva lindring av dina besvär?',
            },
            {
              id: 'orthopedics.position.lessProblem.general.description',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv vilken kroppsposition som lindrar dina besvär.',
            },
            {
              id: 'orthopedics.position.moreProblem.general.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra kroppsposition uppleva försämring av dina besvär?',
            },
            {
              id: 'orthopedics.position.moreProblem.general.description',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv vilken kroppsposition som försämrar dina besvär.',
            },
            {
              id: 'cough.morePain',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Gör det betydligt mer ont när du hostar/nyser?',
            },
          ],
          version: '1',
        },
        {
          type: 'section',
          condition:
            "contains([ 'shoulderRight', 'shoulderLeft', 'upperArmRight', 'upperArmLeft', 'elbowRight', 'elbowLeft', 'forearmRight', 'forearmLeft', 'wristRight', 'wristLeft', 'handRight', 'handLeft', 'fingersRight', 'fingersLeft' ], get('variables.physio.chosenBodyPart'))",
          children: [
            {
              id: 'joints.concentration',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Är smärtan koncentrerad till en viss punkt eller känner du av smärtan i ett större område?',
              options: [
                {
                  value: 'concentrated',
                  label: 'Smärtan är koncentrerad till en viss punkt.',
                },
                {
                  value: 'spreading',
                  label: 'Jag känner av smärtan i ett större område.',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.fingers.which',
              type: 'question',
              version: '1',
              extras: {},
              label: 'I vilka fingrar har du besvär?',
            },
            {
              id: 'orthopedics.bodyParts.numb.arms.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft domningar i armen/armarna alternativt ända ut i handen/händerna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.numb.arms.where',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft domningar.',
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft stickningar i armen/armarna alternativt ända ut i handen/händerna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.tingling.arms.where',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv var i armen eller händerna/fingrarna du haft stickningar.',
            },
            {
              id: 'orthopedics.position.lessProblem.head.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva lindring av dina besvär?',
            },
            {
              id: 'orthopedics.position.lessProblem.head.description',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv vilken position för huvudet/nacken som lindrar dina besvär.',
            },
            {
              id: 'orthopedics.position.moreProblem.head.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra huvudets/nackens position uppleva försämring av dina besvär?',
            },
            {
              id: 'orthopedics.position.moreProblem.head.description',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv vilken position för huvudet/nacken som försämrar dina besvär.',
            },
            {
              id: 'orthopedics.canLayOnSide',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Kan du ligga på den sida som du har dina besvär?',
            },
            {
              id: 'orthopedics.bodyParts.elbow.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Var i armbågen upplever du besvär?',
              description:
                'Lägg armen på ett bord med handflatan mot bordsskivan.',
              options: [
                {
                  value: 'upperside',
                  label: 'På ovansidan',
                },
                {
                  value: 'underside',
                  label: 'På undersidan',
                },
                {
                  value: 'outside',
                  label: 'På utsidan',
                },
                {
                  value: 'inside',
                  label: 'I armvecket',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.forearm.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'På vilken sida av underarmen upplever du besvären?',
              description:
                'Lägg armen på ett bord med handflatan mot bordsskivan.',
              options: [
                {
                  value: 'upperside',
                  label: 'På ovansidan',
                },
                {
                  value: 'underside',
                  label: 'På undersidan',
                },
                {
                  value: 'outside',
                  label: 'På utsidan',
                },
                {
                  value: 'inside',
                  label: 'På insidan',
                },
              ],
            },
            {
              id: 'joints.elbowSwollen',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har armbågen varit svullen någon gång i samband med dina nuvarande besvär?',
            },
            {
              id: 'joints.elbowLocked',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har armbågen fastnat i ett läge/låst sig någon gång i samband med dina nuvarande besvär?',
            },
          ],
          version: '1',
        },
        {
          type: 'section',
          condition:
            "contains([ 'hipRight', 'hipLeft', 'groinRight', 'groinLeft', 'buttockRight', 'buttockLeft', 'thighRight', 'thighLeft', 'kneeRight', 'kneeLeft', 'shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft', 'toesRight', 'toesLeft' ], get('variables.physio.chosenBodyPart'))",
          children: [
            {
              id: 'orthopedics.painWhere.lowerBody',
              type: 'question',
              version: '1',
              extras: {},
              description: '',
              label: 'Var, mer exakt, är smärtan lokaliserad?',
              options: [
                {
                  value: 'thighFront',
                  label: 'På framsidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'thighBack',
                  label: 'På baksidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'thighInside',
                  label: 'På insidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'thighOutside',
                  label: 'På utsidan av låret',
                  condition:
                    "contains(['thighRight', 'thighLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeInternal',
                  label: 'Inuti knät',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeInside',
                  label: 'På insidan av knät (mot det andra benet till)',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeOutside',
                  label: 'På utsidan av knät',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeHollow',
                  label: 'I knävecket',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeCap',
                  label: 'På knäskålen',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeCapAbove',
                  label: 'Ovanför knäskålen',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'kneeCapBelow',
                  label: 'Nedanför knäskålen',
                  condition:
                    "contains(['kneeRight', 'kneeLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegInternal',
                  label: 'Inuti underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegFront',
                  label: 'På framsidan av underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegInside',
                  label: 'På insidan av underbenet (mot det andra benet till)',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegOutside',
                  label: 'På utsidan av underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'lowerLegBack',
                  label: 'På baksidan av underbenet',
                  condition:
                    "contains(['shankRight', 'shankLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'ankleFront',
                  label: 'På framsidan av fotleden',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'ankleOutside',
                  label: 'På utsidan av ankeln',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'ankleInside',
                  label: 'På insidan av ankeln',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'achillesTendon',
                  label: 'I hälsenan',
                  condition:
                    "contains(['shankRight', 'shankLeft', 'ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'heel',
                  label: 'I hälen',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'footUnder',
                  label: 'Under foten',
                  condition:
                    "contains(['ankleRight', 'ankleLeft', 'footRight', 'footLeft'], get('variables.physio.chosenBodyPart'))",
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
            },
            {
              id: 'orthopedics.painWhere.other',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv var smärtan sitter.',
            },
            {
              id: 'orthopedics.bodyParts.toes.which',
              type: 'question',
              version: '1',
              extras: {},
              label: 'I vilka tår har du besvär?',
            },
            {
              id: 'orthopedics.bodyParts.numb.legs.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft domningar i benet/benen alternativt ända ut i foten/fötterna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.numb.legs.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv var i benet eller foten/tårna du haft domningar.',
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du sedan besvären började haft stickningar i benet/benen alternativt ända ut i foten/fötterna?',
              options: [
                {
                  value: 'right',
                  label: 'Ja, på höger sida.',
                },
                {
                  value: 'left',
                  label: 'Ja, på vänster sida.',
                },
                {
                  value: 'both',
                  label: 'Ja, på båda sidorna.',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'orthopedics.bodyParts.tingling.legs.where',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Beskriv var i benet eller foten/tårna du haft stickningar.',
            },
            {
              id: 'health.numbnessPelvicArea',
              warning: "get('answers.health.numbnessPelvicArea') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du domningar i området kring ändtarmen eller könsorganen?',
            },
            {
              id: 'health.peeingIssues',
              warning: "get('answers.health.peeingIssues') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Händer det att du kissar på dig, eller inte känner när du är kissnödig?',
            },
            {
              id: 'lumbago.troublePeeingOrPooping',
              warning:
                "get('answers.lumbago.troublePeeingOrPooping') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du haft svårt att kissa/bajsa i samband med dina besvär?',
            },
            {
              id: 'orthopedics.lower.problemBothSides',
              warning:
                "get('answers.orthopedics.lower.problemBothSides') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har dina besvär vid något tillfälle uppträtt på motsatt sida eller i båda benen samtidigt?',
            },
            {
              id: 'orthopedics.position.lessProblem.general.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra kroppsposition uppleva lindring av dina besvär?',
            },
            {
              id: 'orthopedics.position.lessProblem.general.description',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv vilken kroppsposition som lindrar dina besvär.',
            },
            {
              id: 'orthopedics.position.moreProblem.general.match',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Kan du genom att ändra kroppsposition uppleva försämring av dina besvär?',
            },
            {
              id: 'orthopedics.position.moreProblem.general.description',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv vilken kroppsposition som försämrar dina besvär.',
            },
            {
              id: 'joints.walkingDistanceBeforePain',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Hur långt kan du gå innan du får ont?',
              options: [
                {
                  value: 'lessThanHundred',
                  label: 'Mindre än 100 m',
                },
                {
                  value: 'hundredToThousand',
                  label: '100–1000 m',
                },
                {
                  value: 'moreThanThousand',
                  label: 'Mer än 1000 m',
                },
              ],
            },
            {
              id: 'orthopedics.painWhen.knee',
              type: 'question',
              version: '1',
              extras: {},
              label: 'När smärtar ditt knä?',
              options: [
                {
                  value: 'motion',
                  label: 'I rörelse',
                },
                {
                  value: 'rest',
                  label: 'I vila',
                },
                {
                  value: 'both',
                  label: 'I både rörelse och vila',
                },
              ],
            },
            {
              id: 'joints.unstableKnee',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Känns ditt knä instabilt?',
            },
            {
              id: 'joints.unstableKneeWhen',
              type: 'question',
              version: '1',
              extras: {},
              description: '',
              label: 'Vid vilken aktivitet/rörelse känns knät instabilt?',
              options: [
                {
                  value: 'crouching',
                  label: 'När jag böjer mig ner/sätter mig på huk',
                },
                {
                  value: 'normalWalking',
                  label: 'När jag går som vanligt',
                },
                {
                  value: 'stairWalking',
                  label: 'När jag går i trappor',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
            },
            {
              id: 'joints.unstableKneeWhenOther',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Vid vilken annan aktivitet/rörelse känns knät instabilt?',
            },
            {
              id: 'joints.bentKneeStuck',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Händer det att knät låser sig i böjt läge?',
            },
            {
              id: 'orthopedics.bodyParts.warmerOrColder.knee',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Känns ditt knä varmt, jämfört med ditt andra knä?',
            },
            {
              id: 'orthopedics.bodyParts.warmerOrColder.lowerLegFoot',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Känns det underben/den fot som besvärar dig varmare eller kallare än det andra underbenet?',
              options: [
                {
                  value: 'warmer',
                  label: 'Det känns varmare',
                },
                {
                  value: 'colder',
                  label: 'Det känns kallare',
                },
                {
                  value: 'no',
                  label: 'Inget av ovanstående',
                },
              ],
            },
            {
              id: 'joints.unstableFoot',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Känns din fot instabil?',
            },
            {
              id: 'joints.unstableFootWhen',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Vid vilken aktivitet/rörelse känns foten instabil?',
            },
            {
              id: 'orthopedics.shoes.changeMatch',
              type: 'question',
              version: '1',
              extras: {},
              label:
                'Har du bytt skor eller förändrat något gällande vilken typ av skor du använt den senaste tiden?',
            },
            {
              id: 'orthopedics.shoes.changeWhat',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Beskriv förändringen.',
            },
          ],
          version: '1',
        },
        {
          id: 'health.fever',
          warning: "get('answers.health.fever') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har du feber (över 38°C)?',
        },
        {
          id: 'lumbago.weightLossOrIllness',
          warning: "get('answers.lumbago.weightLossOrIllness') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Har du ofrivilligt gått ner i vikt eller känt dig allmänt sjuk tiden före dina besvär?',
        },
        {
          id: 'health.cancer',
          warning: "get('answers.health.cancer') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har du, eller har du tidigare haft, cancer?',
        },
        {
          id: 'health.cortisone',
          warning: "get('answers.health.cortisone') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Blir du regelbundet behandlad med kortison i tablett- eller sprutform?',
        },
        {
          id: 'problem.previousTreatment.xray.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har du blivit röntgad på grund av dina besvär?',
        },
        {
          id: 'problem.previousTreatment.xray.what',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Vilken röntgenundersökning gjordes?',
          options: [
            {
              value: 'xray',
              label: 'Slätröntgen ("vanlig" röntgen)',
            },
            {
              value: 'ct',
              label: 'Datortomografi/CT (skiktröntgen)',
            },
            {
              value: 'mr',
              label: 'MR/Magnetkamera',
            },
          ],
        },
        {
          id: 'problem.previousTreatment.xray.result',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vad visade röntgen?',
        },
        {
          id: 'joints.previousPhysiotherapyMatch',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Har du provat sjukgymnastik/fysioterapi eller arbetsterapi för dina besvär?',
        },
        {
          id: 'joints.previousPhysiotherapyFollowUp',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Hur fungerade sjukgymnastiken/fysioterapin eller arbetsterapin?',
          options: [
            {
              value: 'better',
              label: 'Jag upplevde förbättring.',
            },
            {
              value: 'noChange',
              label: 'Jag upplevde ingen förbättring.',
            },
            {
              value: 'aborted',
              label: 'Jag fullföljde inte behandlingen.',
            },
          ],
        },
        {
          id: 'joints.previousSurgeryMatch',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Har du någon gång blivit opererad i den kroppsdel där du har dina nuvarande besvär?',
        },
        {
          id: 'joints.previousSurgeryWhat',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken operation gjordes?',
        },
        {
          id: 'joints.previousSurgeryWhen',
          type: 'question',
          version: '1',
          extras: {},
          label: 'När gjordes operationen?',
        },
        {
          id: 'medication.tried',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har du provat några läkemedel mot dina besvär?',
        },
        {
          id: 'joints.medication.what',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Vilka läkemedel har du provat?',
          options: [
            {
              value: 'acetylsalicylic',
              label: 'Acetylsalicylsyra (t.ex. Treo, Aspirin)',
            },
            {
              value: 'antiInflammatory',
              label:
                'Antiinflammatoriska läkemedel (t.ex. Ipren, Ibuprofen, Voltaren, Diklofenak, Orudis, Naproxen, Pronaxen)',
            },
            {
              value: 'chlorzoxazone',
              label: 'Klorzoxazon (Paraflex)',
            },
            {
              value: 'cortisoneInjection',
              label: 'Kortisoninjektion',
            },
            {
              value: 'cortisonePill',
              label: 'Kortisontabletter (Betapred, Prednisolon)',
            },
            {
              value: 'morphine',
              label: 'Morfinpreparat (t.ex. Dolcontin, Oxycontin, Oxynorm)',
            },
            {
              value: 'paracetamol',
              label: 'Paracetamol (t.ex. Alvedon, Panodil, Pamol)',
            },
            {
              value: 'paracetamolCodeine',
              label: 'Paracetamol/Kodein (t.ex. Citodon, Panocod)',
            },
            {
              value: 'tramadol',
              label: 'Tramadol (t.ex. Tradolan, Nobligan)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'joints.medication.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilket annat läkemedel har du provat?',
        },
        {
          id: 'joints.medication.effect',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hur fungerade läkemedlen?',
        },
        {
          id: 'joints.previousAid.match',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label:
            'Har du provat någon form av hjälpmedel för att lindra besvären?',
        },
        {
          id: 'joints.previousAid.what',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Vilka hjälpmedel har du provat?',
          options: [
            {
              value: 'insole',
              label: 'Skoinlägg',
            },
            {
              value: 'corset',
              label: 'Korsett',
            },
            {
              value: 'supporter',
              label: 'Stödbandage',
            },
            {
              value: 'rail',
              label: 'Stödskena/Ortos',
            },
            {
              value: 'crutches',
              label: 'Kryckor',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'joints.previousAid.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilket annat hjälpmedel har du provat?',
        },
        {
          id: 'joints.previousAid.effect',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hur fungerade hjälpmedlen?',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Är du gravid?',
        },
        {
          id: 'reproduction.week',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ange graviditetsvecka.',
        },
        {
          id: 'reproduction.recentlyInLabor',
          warning: "get('answers.reproduction.recentlyInLabor') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har du fött barn under det senaste året?',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar du?',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Övrig medicinsk information',
        },
        {
          id: 'physio.test.nape.bendHeadForward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 = Böj fram huvudet. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.nape.bendHeadForward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.nape.bendHeadForward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.nape.bendHeadBackward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 = Böj huvudet bakåt. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.nape.bendHeadBackward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.nape.bendHeadBackward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.nape.leanHead.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Vinkla huvudet åt sidan så att örat närmar sig axeln. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, åt båda hållen',
            },
            {
              value: 'right',
              label: 'Endast åt höger',
            },
            {
              value: 'left',
              label: 'Endast åt vänster',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.nape.leanHead.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Gjorde det ont?',
        },
        {
          id: 'physio.test.nape.leanHead.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.nape.leanHead.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.nape.turnHead.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Vrid huvudet åt sidan. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, åt båda hållen',
            },
            {
              value: 'right',
              label: 'Endast åt höger',
            },
            {
              value: 'left',
              label: 'Endast åt vänster',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.nape.turnHead.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Gjorde det ont?',
        },
        {
          id: 'physio.test.nape.turnHead.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.nape.turnHead.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.nape.handsAboveHead.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 5 = Lyft båda armarna utåt sidorna och låt dem mötas ovanför huvudet. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, med båda armarna',
            },
            {
              value: 'right',
              label: 'Endast med höger arm',
            },
            {
              value: 'left',
              label: 'Endast med vänster arm',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.nape.handsAboveHead.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Gjorde det ont?',
        },
        {
          id: 'physio.test.nape.handsAboveHead.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.nape.handsAboveHead.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.thoracicSpine.bendTorsoForward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 1 = Böj överkroppen framåt/nedåt så långt det går. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.thoracicSpine.bendTorsoForward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id:
            'physio.test.thoracicSpine.bendTorsoForward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.thoracicSpine.bendTorsoBackward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 2 = Böj överkroppen bakåt så långt det går. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.thoracicSpine.bendTorsoBackward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id:
            'physio.test.thoracicSpine.bendTorsoBackward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.thoracicSpine.leanTorso.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Böj överkroppen åt sidan. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, åt båda hållen',
            },
            {
              value: 'right',
              label: 'Endast åt höger',
            },
            {
              value: 'left',
              label: 'Endast åt vänster',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.thoracicSpine.leanTorso.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Gjorde det ont?',
        },
        {
          id: 'physio.test.thoracicSpine.leanTorso.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.thoracicSpine.leanTorso.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.thoracicSpine.turnTorso.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Vrid överkroppen åt sidan. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, åt båda hållen',
            },
            {
              value: 'right',
              label: 'Endast åt höger',
            },
            {
              value: 'left',
              label: 'Endast åt vänster',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.thoracicSpine.turnTorso.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Gjorde det ont?',
        },
        {
          id: 'physio.test.thoracicSpine.turnTorso.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.thoracicSpine.turnTorso.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lumbarSpine.bendHeadForward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 = Böj fram huvudet. Gjorde det ont i ländryggen?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.bendHeadForward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.lumbarSpine.bendHeadForward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lumbarSpine.bendTorsoForward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 2 = Böj överkroppen framåt/nedåt så långt det går. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.bendTorsoForward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.lumbarSpine.bendTorsoForward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lumbarSpine.bendTorsoBackward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Böj överkroppen bakåt så långt det går. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.bendTorsoBackward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id:
            'physio.test.lumbarSpine.bendTorsoBackward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lumbarSpine.leanTorso.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Böj överkroppen åt sidan. Återgå till startposition. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, åt båda hållen',
            },
            {
              value: 'right',
              label: 'Endast åt höger',
            },
            {
              value: 'left',
              label: 'Endast åt vänster',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.leanTorso.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Gjorde det ont?',
        },
        {
          id: 'physio.test.lumbarSpine.leanTorso.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.lumbarSpine.leanTorso.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lumbarSpine.turnTorso.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 5 = Vrid överkroppen åt sidan. Återgå till startposition. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, åt båda hållen',
            },
            {
              value: 'right',
              label: 'Endast åt höger',
            },
            {
              value: 'left',
              label: 'Endast åt vänster',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.turnTorso.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Gjorde det ont?',
        },
        {
          id: 'physio.test.lumbarSpine.turnTorso.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.lumbarSpine.turnTorso.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lumbarSpine.standOnOneLeg.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 6 = Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, med båda fötterna',
            },
            {
              value: 'right',
              label: 'Endast med höger fot',
            },
            {
              value: 'left',
              label: 'Endast med vänster fot',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.standOnOneLeg.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 6 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lumbarSpine.walkOnHeels.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 7 = Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande. Kunde du genomföra övningen?',
          prologue: '',
        },
        {
          id: 'physio.test.lumbarSpine.walkOnHeels.problemWhere',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 7 På vilken sida har du problem?',
          options: [
            {
              value: 'right',
              label: 'På höger sida',
            },
            {
              value: 'left',
              label: 'På vänster sida',
            },
            {
              value: 'both',
              label: 'På båda sidor',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.stretchLegs.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 8 = Sitt på en stol eller ett bord, helst så att du ej når golvet med fötterna. Lägg händerna bakom rumpan. Försök att slappna av helt i ryggen och "säcka ihop". Glöm ej att fälla ner huvudet. Sträck ut ena benet och böj sedan tillbaka det igen. Upprepa på andra sidan. Gjorde det ont i ländryggen?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.lumbarSpine.stretchLegs.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 8 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.lumbarSpine.stretchLegs.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 8 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.shoulderUpperArm.armForward.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 1 = Stå upp. Lyft armen rakt fram, hela vägen upp över huvudet, och fäll sedan ner armen igen. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.shoulderUpperArm.armForward.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.shoulderUpperArm.armForward.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.shoulderUpperArm.armOut.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 2 = Stå upp. Lyft armen utåt sidan, hela vägen upp över huvudet, och fäll sedan ner armen igen. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.shoulderUpperArm.armOut.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.shoulderUpperArm.armOut.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.shoulderUpperArm.wristToWall.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Stå upp med sidan några centimeter från en vägg och pressa handleden (ej handryggen), med rak arm, mot väggen. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.shoulderUpperArm.wristToWall.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.shoulderUpperArm.wristToWall.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.shoulderUpperArm.wristToWall90.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Stå upp med sidan några centimeter från en vägg. Böj armen 90 grader i armbågen och pressa handleden mot väggen, samtidigt som du håller armbågen tätt mot kroppen. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.shoulderUpperArm.wristToWall90.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id:
            'physio.test.shoulderUpperArm.wristToWall90.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.elbow.bend.couldBend',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 1 = Håll handflatan öppen, armen nära kroppen och böj i armbågen. Sträck sedan ut armen igen. Kunde du böja armen hela vägen?',
          prologue: '',
        },
        {
          id: 'physio.test.elbow.bend.couldStraighten',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 2 = Håll handflatan öppen, armen nära kroppen och böj i armbågen. Sträck sedan ut armen igen. Kunde du sträcka ut armen rakt?',
          prologue: '',
        },
        {
          id: 'physio.test.elbow.turnHand.withoutPain',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Vinkla armen i 90 grader. Håll armbågen mot kroppen. Vrid handflatan upp mot taket och ned mot golvet. Håll armen i övrigt så stilla som möjligt. Kunde du genomföra rörelsen utan smärta?',
          prologue: '',
        },
        {
          id: 'physio.test.elbow.pressOnHand.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Lägg armen på ett bord eller liknande, så att handen sticker ut över kanten. Arm och hand ska bilda en rak linje. Pressa ena handen mot den smärtande handens handrygg, och håll emot med armen som du har besvär i. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.elbow.pressOnHand.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.elbow.pressOnHand.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.elbow.pressOnMiddleFinger.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 5 = Lägg armen på ett bord eller liknande, så att handen sticker ut över kanten. Arm och hand ska bilda en rak linje. Pressa ena handen mot den smärtande handens långfinger, och håll emot med långfingret. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.elbow.pressOnMiddleFinger.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv var och på vilket sätt det gjorde ont.',
        },
        {
          id: 'physio.test.elbow.pressOnMiddleFinger.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.hipToThigh.squat.blocked.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 1 = Gå ner på huk och res dig upp igen. Tog det emot någonstans?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.squat.blocked.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv var i rörelsen det tog emot.',
        },
        {
          id: 'physio.test.hipToThigh.squat.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.hipToThigh.standOnOneLeg.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 2 = Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, med båda fötterna',
            },
            {
              value: 'right',
              label: 'Endast med höger fot',
            },
            {
              value: 'left',
              label: 'Endast med vänster fot',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.standOnOneLeg.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.hipToThigh.walkOnHeels.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande. Kunde du genomföra övningen?',
          prologue: '',
        },
        {
          id: 'physio.test.hipToThigh.walkOnHeels.problemWhere',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 På vilken sida har du problem?',
          options: [
            {
              value: 'right',
              label: 'På höger sida',
            },
            {
              value: 'left',
              label: 'På vänster sida',
            },
            {
              value: 'both',
              label: 'På båda sidor',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.bendKnee.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Lägg dig på mage. Böj i knät så att fotsulan pekar mot taket. Fäll ut benet åt sidan och tillbaka igen. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.bendKnee.pain.where',
          type: 'question',
          version: '1',
          extras: {},
          prologue: '',
          description: '',
          label: 'Övning 4 Var gjorde det ont?',
          options: [
            {
              value: 'hip',
              label: 'I höften',
            },
            {
              value: 'thighFront',
              label: 'På framsidan av låret',
            },
            {
              value: 'thighBack',
              label: 'På baksidan av låret',
            },
            {
              value: 'thighOutside',
              label: 'På utsidan av låret',
            },
            {
              value: 'groin',
              label: 'I ljumsken',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.bendKnee.pain.whereOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var det gjorde ont.',
        },
        {
          id: 'physio.test.hipToThigh.bendKnee.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.hipToThigh.legToSide.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 5 = Lägg dig på rygg. Böj på benen. Fäll ut benet åt sidan och tillbaka igen. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.legToSide.pain.where',
          type: 'question',
          version: '1',
          extras: {},
          prologue: '',
          description: '',
          label: 'Övning 5 Var gjorde det ont?',
          options: [
            {
              value: 'hip',
              label: 'I höften',
            },
            {
              value: 'thighFront',
              label: 'På framsidan av låret',
            },
            {
              value: 'thighBack',
              label: 'På baksidan av låret',
            },
            {
              value: 'thighOutside',
              label: 'På utsidan av låret',
            },
            {
              value: 'groin',
              label: 'I ljumsken',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.legToSide.pain.whereOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv var det gjorde ont.',
        },
        {
          id: 'physio.test.hipToThigh.legToSide.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.hipToThigh.kneeUp.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 6 = Lägg dig på rygg. Dra ena knät upp mot magen. Hjälp till med händerna. Gjorde det ont?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.hipToThigh.kneeUp.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 6 Beskriv var det gjorde ont.',
        },
        {
          id: 'physio.test.hipToThigh.kneeUp.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 6 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.knee.squat.blocked.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 1 = Gå ner på huk och res dig upp igen. Tog det emot någonstans?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.knee.squat.blocked.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv var i rörelsen det tog emot.',
        },
        {
          id: 'physio.test.knee.squat.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.knee.standOnOneLeg.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 2 = Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, med båda fötterna',
            },
            {
              value: 'right',
              label: 'Endast med höger fot',
            },
            {
              value: 'left',
              label: 'Endast med vänster fot',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.knee.standOnOneLeg.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.knee.walkOnHeels.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande. Kunde du genomföra övningen?',
          prologue: '',
        },
        {
          id: 'physio.test.knee.walkOnHeels.problemWhere',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 På vilken sida har du problem?',
          options: [
            {
              value: 'right',
              label: 'På höger sida',
            },
            {
              value: 'left',
              label: 'På vänster sida',
            },
            {
              value: 'both',
              label: 'På båda sidor',
            },
          ],
        },
        {
          id: 'physio.test.knee.stretch.equalAmount',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Ligg på rygg med benen utsträckta. Lägg en smal kudde eller hoprullad handduk under knäna. Sträck ut i knäna så mycket du kan på båda benen. Kunde du sträcka lika mycket på båda sidor?',
          prologue: '',
        },
        {
          id: 'physio.test.knee.stretch.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Gjorde det ont?',
        },
        {
          id: 'physio.test.knee.stretch.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var det gjorde ont.',
        },
        {
          id: 'physio.test.knee.heelUp.equalAmount',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 5 = Ligg på rygg med benen utsträckta. Börja med det ben som du inte har ont i: Böj knät så mycket du kan så att hälen närmar sig rumpan. Hjälp till med händerna. Gör samma sak med det ben som du har ont i. Kunde du böja lika mycket på båda sidor?',
          prologue: '',
        },
        {
          id: 'physio.test.knee.heelUp.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Gjorde det ont?',
        },
        {
          id: 'physio.test.knee.heelUp.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv var det gjorde ont.',
        },
        {
          id: 'physio.test.lowerLegToToes.squat.blocked.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 1 = Gå ner på huk och res dig upp igen. Tog det emot någonstans?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'cannotTest',
              label: 'Jag kunde inte genomföra rörelsen',
            },
          ],
        },
        {
          id: 'physio.test.lowerLegToToes.squat.blocked.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv var i rörelsen det tog emot.',
        },
        {
          id: 'physio.test.lowerLegToToes.squat.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 1 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lowerLegToToes.standOnOneLeg.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 2 = Stå på ett ben. Gå upp på tå och ner igen. Det går bra att ta stöd av en vägg eller liknande. Upprepa på andra sidan. Kunde du genomföra rörelsen?',
          prologue: '',
          options: [
            {
              value: 'both',
              label: 'Ja, med båda fötterna',
            },
            {
              value: 'right',
              label: 'Endast med höger fot',
            },
            {
              value: 'left',
              label: 'Endast med vänster fot',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'physio.test.lowerLegToToes.standOnOneLeg.cannotTest.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 2 Beskriv varför du inte kunde genomföra rörelsen.',
        },
        {
          id: 'physio.test.lowerLegToToes.walkOnHeels.couldTest',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 3 = Gå på hälarna. Det går bra att ta stöd av en vägg eller liknande. Kunde du genomföra övningen?',
          prologue: '',
        },
        {
          id: 'physio.test.lowerLegToToes.walkOnHeels.problemWhere',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 3 På vilken sida har du problem?',
          options: [
            {
              value: 'right',
              label: 'På höger sida',
            },
            {
              value: 'left',
              label: 'På vänster sida',
            },
            {
              value: 'both',
              label: 'På båda sidor',
            },
          ],
        },
        {
          id: 'physio.test.lowerLegToToes.leanFeet.equalAmountBend',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 4 = Sitt på golvet med en tjock kudde eller liknande under underbenen, så att hälarna inte rör golvet. Vinkla fötterna mot dig respektive bort från dig. Kunde du böja uppåt lika mycket på båda sidor?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'moreRight',
              label: 'Nej, jag kunde böja mer på höger.',
            },
            {
              value: 'moreLeft',
              label: 'Nej, jag kunde böja mer på vänster.',
            },
          ],
        },
        {
          id: 'physio.test.lowerLegToToes.leanFeet.painBend.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Gjorde det ont?',
        },
        {
          id: 'physio.test.lowerLegToToes.leanFeet.painBend.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 4 Beskriv var det gjorde ont.',
        },
        {
          id: 'physio.test.lowerLegToToes.leanFeet.equalAmountStretch',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 5 = Sitt på golvet med en tjock kudde eller liknande under underbenen, så att hälarna inte rör golvet. Vinkla fötterna mot dig respektive bort från dig. Kunde du sträcka ut lika mycket på båda sidor?',
          prologue: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'moreRight',
              label: 'Nej, jag kunde sträcka ut mer på höger.',
            },
            {
              value: 'moreLeft',
              label: 'Nej, jag kunde sträcka ut mer på vänster.',
            },
          ],
        },
        {
          id: 'physio.test.lowerLegToToes.leanFeet.painStretch.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Gjorde det ont?',
        },
        {
          id: 'physio.test.lowerLegToToes.leanFeet.painStretch.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 5 Beskriv var det gjorde ont.',
        },
        {
          id: 'physio.test.lowerLegToToes.footOnKnee.pain.match',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Övning 6 = Sätt dig på en stol. Lägg upp foten på det ben som besvärar dig på motsatt knä. Håll fast hälen med ena handen och sträck med andra handen ut undersidan av foten. Gjorde det ont?',
          prologue: '',
        },
        {
          id: 'physio.test.lowerLegToToes.footOnKnee.pain.description',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övning 6 Beskriv var det gjorde ont.',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Fysioterapi',
  index: '0',
};

export default {
  id: 'gastricProblemsUpper',
  type: 'formulary',
  version: '5',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '9',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började dina besvär?',
          index: '0.0.0',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.chestPain',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label:
            'Har du ont i eller tryck över bröstet i samband med dina besvär?',
          index: '0.1.0',
        },
        {
          id: 'health.fever',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du feber (över 38°C)?',
          index: '0.1.1',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.stomachPain',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du ont i magen?',
          index: '0.2.0',
        },
        {
          id: 'gastric.painLocation',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'rightUpper',
              label: 'På höger sida i övre delen av magen',
            },
            {
              value: 'leftUpper',
              label: 'På vänster sida i övre delen av magen',
            },
            {
              value: 'rightLower',
              label: 'På höger sida i nedre delen av magen',
            },
            {
              value: 'leftLower',
              label: 'På vänster sida i nedre delen av magen',
            },
            {
              value: 'diffuse',
              label: 'Smärtan är diffus och svår att lokalisera',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.gastric.stomachPain') === 'yes'",
          label: 'Var sitter smärtan?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.2.1',
        },
        {
          id: 'gastric.burningDiscomfortGroin',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Upplever du ett brännande obehag i mellangärdet?',
          index: '0.2.2',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.heartburn',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du problem med halsbränna?',
          index: '0.3.0',
        },
        {
          id: 'gastric.heartburnCircumstances',
          type: 'multipleChoice',
          version: '3',
          options: [
            {
              value: 'afterEating',
              label: 'Efter att jag har ätit',
            },
            {
              value: 'whenLifting',
              label: 'När jag lyfter något',
            },
            {
              value: 'whenLyingDown',
              label: 'När jag ligger ner',
            },
            {
              value: 'whenBendingForward',
              label: 'När jag böjer mig framåt',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.gastric.heartburn') === 'yes'",
          label: 'I vilken eller vilka situationer brukar du få halsbränna?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.3.1',
        },
        {
          id: 'gastric.heartburnCircumstancesOther',
          type: 'text',
          version: '1',
          condition:
            "contains(get('answers.gastric.heartburnCircumstances'), 'other')",
          validations: {
            required: true,
          },
          label: 'Beskriv i vilka situationer du brukar få halsbränna.',
          index: '0.3.2',
        },
        {
          id: 'gastric.resultFromEating',
          type: 'composite',
          version: '2',
          children: [
            {
              id: 'gastric.resultFromEating.what',
              type: 'choice',
              options: [
                {
                  value: 'improved',
                  label: 'Besvären lindras',
                },
                {
                  value: 'worsened',
                  label: 'Besvären tilltar',
                },
                {
                  value: 'unchanged',
                  label: 'Mina symptom påverkas inte av att jag äter',
                },
              ],
              relativeId: 'what',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Vad händer när du äter?',
              index: '0.3.3.0',
            },
            {
              id: 'gastric.resultFromEating.worsened',
              type: 'multipleChoice',
              condition:
                "get('answers.gastric.resultFromEating.what') === 'worsened'",
              options: [
                {
                  value: 'stomachPain',
                  label: 'Jag får ont i magen',
                },
                {
                  value: 'quicklyFull',
                  label: 'Jag blir snabbt mätt',
                },
                {
                  value: 'gasy',
                  label: 'Jag känner mig spänd/uppblåst i magen',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'worsened',
              version: '2',
              label: 'På vilket sätt blir dina symptom förvärrade när du äter?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.3.3.1',
            },
            {
              id: 'gastric.resultFromEating.other',
              type: 'text',
              condition:
                "contains(get('answers.gastric.resultFromEating.worsened'), 'other')",
              relativeId: 'other',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Beskriv hur det känns eller vad som händer när du äter.',
              index: '0.3.3.2',
            },
          ],
          extras: {},
          index: '0.3.3',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.troubleSwallowing',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du svårt att svälja?',
          index: '0.4.0',
        },
        {
          id: 'gastric.foodStuck',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Känns det som att maten fastnar i matstrupen när du äter?',
          index: '0.4.1',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.vomit',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Kräks du?',
          index: '0.5.0',
        },
        {
          id: 'gastric.weightLoss',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label:
            'Har du ofrivilligt gått ner i vikt i samband med att dina magbesvär började?',
          index: '0.5.1',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.blackStool',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Är din avföring svart?',
          index: '0.6.0',
        },
        {
          id: 'gastric.diarrhea.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Har du diarré?',
          index: '0.6.1',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'medication.tried',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du provat några läkemedel mot dina besvär?',
          index: '0.7.0',
        },
        {
          id: 'gastric.medication.what',
          type: 'multipleChoice',
          version: '4',
          options: [
            {
              value: 'losec',
              label: 'Omeprazol, Esomeprazol, Losec och/eller Nexium',
            },
            {
              value: 'novalucol',
              label: 'Novalucol, Novaluzid och/eller Rennie',
            },
            {
              value: 'samarin',
              label: 'Samarin',
            },
            {
              value: 'zantac',
              label: 'Ranitidin, Stomacid och/eller Zantac',
            },
            {
              value: 'pepcid',
              label: 'Pepcid och/eller Pepcid duo',
            },
            {
              value: 'gaviscon',
              label: 'Andapsin, Galieve och/eller Gaviscon',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.medication.tried') === 'yes'",
          label: 'Vilket eller vilka läkemedel har du provat?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.7.1',
        },
        {
          id: 'gastric.medication.other',
          type: 'text',
          version: '2',
          condition:
            "contains(get('answers.gastric.medication.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilka andra läkemedel har du provat?',
          index: '0.7.2',
        },
        {
          id: 'gastric.medication.effect',
          type: 'text',
          version: '2',
          condition: "get('answers.medication.tried') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilken effekt hade läkemedlen?',
          index: '0.7.3',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.history',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du haft liknande magbesvär tidigare?',
          index: '0.8.0',
        },
        {
          id: 'similar',
          type: 'composite',
          version: '2-gastricUpper',
          children: [
            {
              id: 'similar.match',
              type: 'binary',
              relativeId: 'match',
              version: '2-gastricUpper',
              validations: {
                required: true,
              },
              label:
                'Har du tidigare uppsökt läkare och blivit utredd för dina magbesvär?',
              index: '0.8.1.0',
            },
            {
              id: 'similar.when',
              type: 'text',
              condition: "get('answers.similar.match') === 'yes'",
              relativeId: 'when',
              version: '2-gastricUpper',
              validations: {
                required: true,
              },
              label: 'När var detta?',
              index: '0.8.1.1',
            },
            {
              id: 'similar.diagnosis',
              type: 'text',
              condition: "get('answers.similar.match') === 'yes'",
              relativeId: 'diagnosis',
              version: '2-gastricUpper',
              validations: {
                required: true,
              },
              label: 'Vilken diagnos fick du då?',
              index: '0.8.1.2',
            },
            {
              id: 'similar.stomachBacteria',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'unknown',
                  label: 'Vet ej',
                },
              ],
              condition: "get('answers.similar.match') === 'yes'",
              relativeId: 'stomachBacteria',
              version: '2-gastricUpper',
              validations: {
                required: true,
              },
              label:
                'Blev du kontrollerad för förekomsten av "magsårsbakterien" Helicobacter Pylori?',
              index: '0.8.1.3',
            },
            {
              id: 'similar.stomachBacteriaMatch',
              type: 'text',
              condition:
                "get('answers.similar.match') === 'yes' && get('answers.similar.stomachBacteria') === 'yes'",
              relativeId: 'stomachBacteriaMatch',
              version: '2-gastricUpper',
              validations: {
                required: true,
              },
              label: 'Vad visade undersökningen?',
              index: '0.8.1.4',
            },
            {
              id: 'similar.gastroscopy',
              type: 'binary',
              condition: "get('answers.similar.match') === 'yes'",
              relativeId: 'gastroscopy',
              version: '2-gastricUpper',
              validations: {
                required: true,
              },
              label: 'Gjordes en gastroskopi?',
              index: '0.8.1.5',
            },
          ],
          condition: "get('answers.gastric.history') === 'yes'",
          extras: {},
          index: '0.8.1',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.9.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.9.1',
        },
      ],
      version: '1',
      index: '0.9',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.10.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.10.1',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.10',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.11.0',
        },
      ],
      version: '1',
      index: '0.11',
    },
    {
      id: 'predefined.isInsuranceCompany',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.12',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.13',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.14',
    },
  ],
  variables: [
    {
      id: 'gastricProblemsUpper.over3Months',
      type: 'abort',
      expression:
        "get('answers.started') === 'over3months' && get('answers.predefined.isInsuranceCompany') === 'no'",
      relativeId: 'over3Months',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'gastricProblemsUpper.chestPain',
      type: 'abort',
      expression: "get('answers.health.chestPain') === 'yes'",
      relativeId: 'chestPain',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'gastricProblemsUpper.fever',
      type: 'abort',
      expression: "get('answers.health.fever') === 'yes'",
      relativeId: 'fever',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
        },
        {
          id: 'started',
          warning: "get('answers.started') === 'over3months'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären började',
          description: '',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'health.chestPain',
          warning: "get('answers.health.chestPain') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ont i/tryck över bröstet',
          description: '',
        },
        {
          id: 'health.fever',
          warning: "get('answers.health.fever') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber (över 38°C)',
          description: '',
        },
        {
          id: 'gastric.stomachPain',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ont i magen',
          description: '',
        },
        {
          id: 'gastric.painLocation',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärtlokalisation',
          description: '',
          options: [
            {
              value: 'rightUpper',
              label: 'På höger sida i övre delen av magen',
            },
            {
              value: 'leftUpper',
              label: 'På vänster sida i övre delen av magen',
            },
            {
              value: 'rightLower',
              label: 'På höger sida i nedre delen av magen',
            },
            {
              value: 'leftLower',
              label: 'På vänster sida i nedre delen av magen',
            },
            {
              value: 'diffuse',
              label: 'Smärtan är diffus och svår att lokalisera',
            },
          ],
        },
        {
          id: 'gastric.burningDiscomfortGroin',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Brännande obehag i mellangärdet',
          description: '',
        },
        {
          id: 'gastric.heartburn',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Problem med halsbränna',
        },
        {
          id: 'gastric.heartburnCircumstances',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Halsbränna vid följande situationer',
          description: '',
          options: [
            {
              value: 'afterEating',
              label: 'Efter att jag har ätit',
            },
            {
              value: 'whenLifting',
              label: 'När jag lyfter något',
            },
            {
              value: 'whenLyingDown',
              label: 'När jag ligger ner',
            },
            {
              value: 'whenBendingForward',
              label: 'När jag böjer mig framåt',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'gastric.heartburnCircumstancesOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Halsbränna vid följande situationer, fri beskrivning',
        },
        {
          id: 'gastric.resultFromEating.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Symptom vid matintag',
          description: '',
          options: [
            {
              value: 'improved',
              label: 'Besvären lindras',
            },
            {
              value: 'worsened',
              label: 'Besvären tilltar',
            },
            {
              value: 'unchanged',
              label: 'Mina symptom påverkas inte av att jag äter',
            },
          ],
        },
        {
          id: 'gastric.resultFromEating.worsened',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Följande händer vid matintag',
          description: '',
          options: [
            {
              value: 'stomachPain',
              label: 'Jag får ont i magen',
            },
            {
              value: 'quicklyFull',
              label: 'Jag blir snabbt mätt',
            },
            {
              value: 'gasy',
              label: 'Jag känner mig spänd/uppblåst i magen',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'gastric.resultFromEating.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fri beskrivning av vad som händer vid matintag',
          description: '',
        },
        {
          id: 'gastric.troubleSwallowing',
          warning: "get('answers.gastric.troubleSwallowing') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Svårt att svälja',
        },
        {
          id: 'gastric.foodStuck',
          warning: "get('answers.gastric.foodStuck') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Känns som maten fastnar i matstrupen vid måltid',
        },
        {
          id: 'health.vomit',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kräkningar',
        },
        {
          id: 'gastric.weightLoss',
          warning: "get('answers.gastric.weightLoss') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ofrivillig viktminskning i samband med besvärsdebut',
        },
        {
          id: 'gastric.blackStool',
          warning: "get('answers.gastric.blackStool') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Svart avföring',
        },
        {
          id: 'gastric.diarrhea.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diarré',
        },
        {
          id: 'medication.tried',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Provat läkemedel',
        },
        {
          id: 'gastric.medication.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel',
          description: '',
          options: [
            {
              value: 'losec',
              label: 'Omeprazol, Esomeprazol, Losec och/eller Nexium',
            },
            {
              value: 'novalucol',
              label: 'Novalucol, Novaluzid och/eller Rennie',
            },
            {
              value: 'samarin',
              label: 'Samarin',
            },
            {
              value: 'zantac',
              label: 'Ranitidin, Stomacid och/eller Zantac',
            },
            {
              value: 'pepcid',
              label: 'Pepcid och/eller Pepcid duo',
            },
            {
              value: 'gaviscon',
              label: 'Andapsin, Galieve och/eller Gaviscon',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'gastric.medication.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel, annat',
        },
        {
          id: 'gastric.medication.effect',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Effekt av läkemedel',
        },
        {
          id: 'gastric.history',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Liknande besvär tidigare',
        },
        {
          id: 'similar.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare utredd för magbesvären',
        },
        {
          id: 'similar.when',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidpunkt för tidigare utredning',
        },
        {
          id: 'similar.diagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare läkardiagnos',
        },
        {
          id: 'similar.stomachBacteria',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kontrollerats för Heliobacter Pylori',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'unknown',
              label: 'Vet ej',
            },
          ],
        },
        {
          id: 'similar.stomachBacteriaMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kontroll för H. Pylori visade',
        },
        {
          id: 'similar.gastroscopy',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gastroskopi genomförd',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
          description: '',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
          description: '',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Övrig medicinsk information',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Magbesvär – Övre (t.ex. halsbränna)',
  index: '0',
};

import type { Guide } from '../../types';
import summaryExports from './summaryExports';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import aborts from './abort';
import informedConsent from '../questions/informedConsent';

export default (async args => {
  const { exports, ask, significant } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  summaryExports(exports);

  await ask.info(
    `Please answer the following questions to get your sick note.`,
    {
      description:
        'This service is only for sick notes. If you are feeling unwell and need medical help then please choose another service.',
    }
  );

  const howHelp = await eChoice({
    id: 'sickNote.howWeCanHelp',
    label: 'How can we help you today?',
    options: [
      {
        value: 'new',
        label: 'New Sick Note',
        keyFinding: 'New sick note',
      },
      {
        value: 'extension',
        label: 'Extension of a sick note',
        keyFinding: 'Extension of a sick note',
      },
      {
        value: 'phasedReturn',
        label: 'Phased return / amended duties sick note',
        keyFinding: 'Phased return / amended duties sick note',
      },
      {
        value: 'returnToWorkNote',
        label: 'Return to work note',
        abort: aborts.adultNoSickNoteNeeded,
        keyFinding: 'Return to work note',
      },
      {
        value: 'other',
        label: 'Other',
        hide: true,
      },
    ],
  });
  if (howHelp.abort) {
    return { abort: howHelp.abort };
  }

  if (howHelp === 'other') {
    await eText({
      id: 'sickNote.howWeCanHelpOther',
      label: 'Please tell us what you need.',
    });
  }

  if (['new', 'other'].includes(howHelp)) {
    await eText({
      id: 'sickNote.why',
      label: 'Why do you need a sick note?',
      expires: 365,
    });

    const offMoreThan7Days = await eChoice({
      id: 'sickNote.offMoreThan7Days',
      label: 'Have you been off work for more than 7 days?',
      options: [
        {
          value: 'yes',
          hide: true,
        },
        {
          value: 'no',
          hide: true,
          abort: aborts.adultSelfCertifyFor7Days,
        },
      ],
    });
    if (offMoreThan7Days.abort) {
      return { abort: offMoreThan7Days.abort };
    }

    await eText({
      id: 'sickNote.lastAyWork',
      label: 'When were you last at work?',
      description: 'Please specify the exact date.',
    });

    await eText({
      id: 'sickNote.howLongDoYouNeedOff',
      label: 'How long do you think you need off work?',
    });
  }

  if (['extension', 'phasedReturn'].includes(howHelp)) {
    await eText({
      id: 'sickNote.whyNeed',
      label: 'Please tell us why you need this.',
    });
  }

  if (howHelp === 'phasedReturn') {
    await eText({
      id: 'sickNote.whatState',
      label: 'What would you like your sick note to state?',
      description:
        'For example - phased return to work, altered hours, amended duties, workplace adaptations.',
    });

    await ask.choice({
      description: `
We cannot guarantee that your employer will agree to what you have requested in the sick note. 

You will need to discuss this with your line manager.
`,
      options: ['I understand'],
    });
  }

  const photos = await ask.upload(
    `Please upload photos of any documents which may be relevant.`,
    {
      description: `
**You may want to upload a photo of your last sick note.**

You can use a mobile phone camera to take the photo.
              `,
      optional: true,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photos,
  });

  await ask(informedConsent);

  await ask.choice({
    description: `
**We aim to reply within 2 working days.**  

**If your request is urgent, please contact your GP surgery in the normal way.**
`,
    options: ['I understand'],
  });

  return {};
}: Guide);

import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';

export default (async args => {
  const { ask, significant } = args;
  const eChoice = createExtendedChoice(args);

  const howManyUnits = await eChoice({
    id: 'moduleAlcohol.howManyUnits',
    label: 'How many alcohol units do you drink in a week?',
    description:
      'Small glass of wine = 2 units, large glass of wine = 3 units, pint of beer or cider = 2 units, pint of strong beer or cider = 3 units, shot of spirit = 1 unit.',
    clinicLabel: 'Alcohol',
    expires: 180,
    options: [
      {
        label: 'More than 14 units a week',
        value: 'moreThan14',
        keyFinding: 'Alcohol consumption above 14 units a week',
        hide: true,
      },
      {
        label: 'Less than 14 units a week',
        value: 'lessThan14',
      },
      {
        label: "I don't drink alcohol",
        value: 'none',
      },
    ],
  });

  if (howManyUnits === 'moreThan14') {
    const units =
      (await ask.number('Please tell us how many units you drink a week.', {
        id: 'moduleAlcohol.howManyUnitsOver14',
        description:
          'Small glass of wine = 2 units, large glass of wine = 3 units, pint of beer or cider = 2 units, pint of strong beer or cider = 3 units, shot of spirit = 1 unit.',
        expires: 180,
      })) || 0;

    significant.push({
      type: 'number',
      label: 'Alcohol units / week',
      value: units,
    });
  }

  return {};
}: Guide);

export default {
  mentalHealthReview: {
    id: 'mentalHealthReview',
    color: 'black',
    label: 'Mental Health Review',
  },
  immunosuppresed: {
    id: 'immunosuppresed',
    color: 'black',
    label: 'Immunosuppresed patient',
  },
  severePain: {
    id: 'severePain',
    color: 'black',
    label: 'Consider GP appointment',
    description: 'Severe Pain',
  },
  extremePain: {
    id: 'extremePain',
    color: 'black',
    label: 'Consider GP appointment',
    description: 'Extreme Pain',
  },
  previousInvestigations: {
    id: 'previousInvestigations',
    color: 'black',
    label: 'Review clinical notes',
    description: 'Previous investigations',
  },
  reviewPmh: {
    id: 'reviewPmh',
    color: 'black',
    label: 'Review PMH in clinical notes',
    description: '',
  },
  previousDoctorSeen: {
    id: 'previousDoctorSeen',
    color: 'black',
    label: 'Review clinical notes',
    description: 'Seen doctor previously about this issue',
  },
  pvConsultChestPain: {
    id: 'pvConsultChestPain',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Chest pain',
  },
  pvConsultSeverePain: {
    id: 'pvConsultSeverePain',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Severe pain',
  },
  pvConsultDifficultyBreathing: {
    id: 'pvConsultDifficultyBreathing',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Difficulty in breathing',
  },
  pvConsultSyncopePresycnope: {
    id: 'pvConsultSyncopePresycnope',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Syncope or presycnope',
  },
};

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const occupationCurrent = {
    type: 'choice',
    id: 'occupation.current.what',
    label: 'Vad är din huvudsakliga sysselsättning?',
    options: [
      {
        value: 'working',
        label: 'Arbetar',
      },
      {
        value: 'student',
        label: 'Studerar',
      },
      {
        value: 'sicklisted',
        label: 'Sjukskriven',
      },
      {
        value: 'parentalleave',
        label: 'Föräldraledig',
      },
      {
        value: 'retired',
        label: 'Pensionär',
      },
      {
        value: 'jobseeking',
        label: 'Arbetssökande',
      },
      {
        value: 'other',
        label: 'Annat',
      },
    ],
    expires: 180,
  };

  const occupationCurrentAnswer = await ask(occupationCurrent);

  significant.push({
    ...occupationCurrent,
    label: 'Huvudsaklig sysselsättning',
    value: occupationCurrentAnswer,
  });

  if (occupationCurrentAnswer === 'working') {
    const occupationCurrentWhat = {
      type: 'text',
      id: 'occupation.work.what',
      label: 'Vad arbetar du med?',
      expires: 180,
    };

    const occupationCurrentWhatAnswer = await ask(occupationCurrentWhat);

    significant.push({
      ...occupationCurrentWhat,
      label: 'Arbetar med',
      value: occupationCurrentWhatAnswer,
    });
  }

  if (occupationCurrentAnswer === 'sicklisted') {
    const occupationSicklisted = {
      type: 'text',
      id: 'occupation.sicklisted',
      label: 'Varför är du sjukskriven?',
      expires: 90,
    };

    const occupationSicklistedAnswer = await ask(occupationSicklisted);

    significant.push({
      ...occupationSicklisted,
      label: 'Anledning till sjukskrivning',
      value: occupationSicklistedAnswer,
    });

    const occupationSicklistedSince = {
      type: 'text',
      id: 'occupation.sicklistedSince',
      label: 'Vilket datum började din senaste sjukskrivningsperiod?',
      expires: 90,
    };

    const occupationSicklistedSinceAnswer = await ask(
      occupationSicklistedSince
    );

    significant.push({
      ...occupationSicklistedSince,
      label: 'Senaste sjukskrivningsperiod påbörjad datum',
      value: occupationSicklistedSinceAnswer,
    });
  }

  if (occupationCurrentAnswer === 'other') {
    const occupationCurrentOther = {
      type: 'text',
      id: 'occupation.current.other',
      label: 'Beskriv din huvudsakliga sysselsättning.',
      expires: 90,
    };

    const occupationCurrentOtherAnswer = await ask(occupationCurrentOther);

    significant.push({
      ...occupationCurrentOther,
      label: 'Annan huvudsaklig sysselsättning',
      value: occupationCurrentOtherAnswer,
    });
  }

  return {};
}: Guide);

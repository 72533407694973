import type { Export } from '../../types';
import addJournalData, {
  diagnosisOpts,
  investigationsRequestedOpts,
  prescribedOpts,
  outcomeOpts,
  actionNeededOpts,
} from '../common/addJournalData';

export default (exports: Export[]) =>
  addJournalData(exports, {
    presentingComplaint: 'Covid-19 Testing',
    snomedCode: '24641000000107 Investigation result',
    diagnosisOptions: [
      diagnosisOpts.covid19Excluded,
      diagnosisOpts.covid19Confirmed,
      diagnosisOpts.covid19Inconclusive,
    ],
    investigationsRequestedOptions: [
      investigationsRequestedOpts.covid19Pcr,
      investigationsRequestedOpts.none,
    ],
    prescribed: prescribedOpts.none,
    outcome: outcomeOpts.adviceGiven,
    actionNeeded: actionNeededOpts.none,
  });

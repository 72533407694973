import createExtendedChoice from '../common/extendedChoice';
import createExtendedMultipleChoiceWithOther from '../common/extendedMultipleChoiceWithOther';
import createExtendedNumber from '../common/extendedNumber';

import ds from './decisionSupport';
import type { Guide } from '../../types';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eChoiceWithOther = createExtendedMultipleChoiceWithOther(args);
  const eNumber = createExtendedNumber(args);

  const exacerbationInLastYear = await eChoice({
    id: 'asthmaReview.extended.exacerbationInLastYear',
    label: `Have you had an asthma attack in the past year?`,
    description: `
An asthma attack may include the following symptoms: 
- You're wheezing a lot, have a very tight chest or you're coughing a lot
- You're breathless and find it difficult to walk or talk
- Your breathing is getting faster and it feels like you can't get your breath in properly.
- Your blue reliever isn't helping, or you need to use it more than every four hours.

Requiring oral steroids, attending A&E or being admitted to hospital for the above asthma symptoms also counts as an asthma attack.
`,
    clinicLabel: 'Exacerbation of asthma in the past year',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        keyFinding: 'No asthma exacerbation in the past year',
      },
    ],
  });
  if (exacerbationInLastYear === 'yes') {
    await eChoice({
      id: 'asthmaReview.extended.exacerbationCountInLastYear',
      label: `How many times have you had an asthma attack in the past year?`,
      clinicLabel: 'Number of exacerbations in past year',
      options: [
        {
          label: '1',
          value: '1',
          keyFinding: '1 asthma exacerbation in the past year',
        },
        {
          label: '2',
          value: '2',
          keyFinding: '2 asthma exacerbations in the past year',
          ds: ds.considerReferralToRespiratory,
        },
        {
          label: '3',
          value: '3',
          keyFinding: '3 asthma exacerbations in the past year',
          ds: ds.considerReferralToRespiratory,
        },
        {
          label: '4',
          value: '4',
          keyFinding: '4 asthma exacerbations in the past year',
          ds: ds.considerReferralToRespiratory,
        },
        {
          label: '5 or more',
          value: '5OrMore',
          keyFinding: '5 or more asthma exacerbations in the past year',
          ds: ds.considerReferralToRespiratory,
        },
      ],
    });
  }

  await eChoice({
    id: 'asthmaReview.extended.prednisoloneUseInLastYear',
    label: `Have you needed to use oral steroid tablets (prednisolone) because of your asthma in the past year?`,
    clinicLabel: 'Prednisolone use in the past year',
    options: [
      {
        value: 'yes',
        ds: ds.extendedOralSteroidUseInLastYear,
      },
      {
        value: 'no',
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.extended.nebuliserUseInLastYear',
    label: `Have you needed to use a nebuliser because of your asthma in the past year?`,
    clinicLabel: 'Nebuliser use in the past year',
    options: [
      {
        value: 'yes',
        ds: ds.extendedNebuliserUseInLastYear,
      },
      {
        value: 'no',
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.extended.hospitalAttendanceInLastYear',
    label: `Have you been seen in hospital because of an asthma attack in the in the past year?`,
    clinicLabel: 'Hospital attendance in the past year',
    options: [
      {
        value: 'yes',
        ds: ds.extendedHospitalInLastYear,
      },
      {
        value: 'no',
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.extended.ituAdmission',
    label: `Have you ever been admitted to ITU because of an asthma attack?`,
    clinicLabel: 'ITU admission in the past',
    options: [
      {
        value: 'yes',
        ds: ds.extendedPreviousItuAdmission,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  const asthmaTrigger = await eChoice({
    id: 'asthmaReview.extended.asthmaTrigger',
    label: `Does anything trigger or worsen your asthma?`,
    clinicLabel: 'Asthma trigger',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        keyFinding: 'No asthma trigger',
      },
    ],
  });

  if (asthmaTrigger === 'yes') {
    await eChoiceWithOther({
      multipleChoiceQuestions: [
        {
          id: 'asthmaReview.extended.asthmaTriggers1',
          label: 'Do any of the following trigger your asthma?',
          description: 'More than one can be selected',
          options: [
            {
              label: 'Airborne dust',
              value: 'airborneDust',
              keyFinding: 'Asthma triggered by airborne dust',
            },
            {
              label: 'Animals',
              value: 'animals',
              keyFinding: 'Asthma triggered by animals',
            },
            {
              label: 'Cold air',
              value: 'coldAir',
              keyFinding: 'Asthma triggered by cold air',
            },
            {
              label: 'Damp',
              value: 'damp',
              keyFinding: 'Asthma triggered by damp',
            },
            {
              label: 'Dust mites',
              value: 'dustMites',
              keyFinding: 'Asthma triggered by dust mites',
            },
            {
              label: 'Emotion',
              value: 'emotion',
              keyFinding: 'Asthma triggered by emotion',
            },
            {
              label: 'Exercise',
              value: 'exercise',
              keyFinding: 'Asthma triggered by exercise',
            },
            {
              label: 'Humidity',
              value: 'humidity',
              keyFinding: 'Asthma triggered by humidity',
            },
          ],
        },
        {
          id: 'asthmaReview.extended.asthmaTriggers2',
          label: 'Do any of the following trigger your asthma?',
          description: 'More than one can be selected',
          options: [
            {
              label: 'Perfumes',
              value: 'perfumes',
              keyFinding: 'Asthma triggered by perfumes',
            },
            {
              label: 'Pollen',
              value: 'pollen',
              keyFinding: 'Asthma triggered by pollen',
            },
            {
              label: 'Respiratory infections',
              value: 'respiratoryInfections',
              keyFinding: 'Asthma triggered by respiratory infections',
            },
            {
              label: 'Seasonal',
              value: 'seasonal',
              keyFinding: 'Asthma triggered by season',
            },
            {
              label: 'Tobacco smoke',
              value: 'tobaccoSmoke',
              keyFinding: 'Asthma triggered by tobacco smoke',
            },
            {
              label: 'Warm air',
              value: 'warmAir',
              keyFinding: 'Asthma triggered by warm air',
            },
            {
              label: 'Wind',
              value: 'wind',
              keyFinding: 'Asthma triggered by wind',
            },
            {
              label: 'Work related',
              value: 'workRelated',
              keyFinding: 'Asthma triggered by work',
            },
            {
              label: 'Other',
              value: 'other',
            },
          ],
        },
      ],
      otherQuestion: {
        otherValue: 'other',
        id: 'asthmaReview.extended.asthmaTriggersOther',
        label: 'What else triggers your asthma?',
      },
      clinicLabel: 'Asthma trigger',
      optional: true,
    });
  }

  const havePeakFlow = await eChoice({
    id: 'asthmaReview.extended.havePeakFlow',
    label: `Do you have a peak flow meter?`,
    clinicLabel: 'Patient has a peak flow meter',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        ds: ds.extendedPeakFlowReview,
      },
    ],
  });

  if (havePeakFlow === 'yes') {
    const needPeakFlowInstructions = await eChoice({
      id: 'asthmaReview.extended.needPeakFlowInstructions',
      label: `We need to know what your peak flow reading is, do you need instructions on how to use your peak flow meter?`,
      options: [
        {
          value: 'yes',
          hide: true,
        },
        {
          value: 'no',
          hide: true,
        },
      ],
    });

    if (needPeakFlowInstructions === 'yes') {
      await eChoice({
        id: 'asthmaReview.extended.peakFlowInstructions',
        description: `
1. Ensure the marker is set to zero
2. Stand up or sit upright
3. Take as deep a breath as you can and hold it
4. Place the mouthpiece in your mouth and create a tight seal with your lips
5. Blow as hard and fast as you can into the meter
6. Write down the number      
      `,
        options: [
          {
            label: 'I understand',
            value: 'understand',
            hide: true,
          },
        ],
      });
    }

    await eNumber({
      id: 'asthmaReview.extended.peakFlowInstructions',
      label:
        'Please check your peak flow 3 times and write down the highest reading.',
      clinicLabel: 'Peak flow reading',
      min: 10,
      max: 900,
    });
  }

  const hadFluVaccine = await eChoice({
    id: 'asthmaReview.extended.hadFluVaccine',
    label: `Are you up to date with your yearly flu vaccine?`,
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  if (hadFluVaccine === 'no') {
    await eChoice({
      id: 'asthmaReview.extended.fluVaccineInfo',
      description: `
**Patients who have asthma and get the flu are more likely to get a serious infection, end up in hospital or even die.**

**Having the flu vaccine can reduce the chance of you getting a serious illness because of the flu.  It is recommended that you should have the flu vaccine every year, usually around October time.**

**Please contact your surgery to arrange a flu vaccine.**
      `,
      clinicLabel: 'Flu vaccine information provided',
      options: [
        {
          label:
            'I understand, I will contact my surgery to arrange a flu vaccine',
          value: 'understandWillContact',
          hide: true,
        },
        {
          label: 'I understand, but I do not want a flu vaccine',
          value: 'understandDecline',
          keyFinding: 'Flu vaccine declined',
          ds: ds.extendedDeclinedFlu,
        },
      ],
    });
  }

  return {};
}: Guide);

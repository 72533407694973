import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import ds from './decisionSupport';
import { keyFindingLabelReview } from './_text';

export default (async args => {
  const { exports, scores } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);
  // newSymptomScore     have you discussed = no || how is now = got worse || new symptoms = yes
  const bumpNewSymptomScore = () => {
    scores.increase('newSymptomScore');
  };
  const returnHome = () => scores.get('newSymptomScore') >= 1;

  const previousconsultationforcough = await eChoice({
    id: 'coughChild.previousconsultationforcough',
    label: 'Have you previously discussed their cough with a doctor or nurse?',
    clinicLabel: 'Previous consultation for cough',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Seen HCP about cough' },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Not seen HCP about cough',
        hide: true,
        onSelected: bumpNewSymptomScore,
      },
    ],
  });

  if (returnHome()) {
    return {};
  }

  if (previousconsultationforcough === 'yes') {
    await eChoice({
      id: 'coughChild.howiscoughnow',
      label: "How is your child's cough now?",
      clinicLabel: 'How is cough now',
      options: [
        {
          label: 'Better',
          value: 'better',
          keyFinding: 'Cough improved',
        },
        {
          label: 'No change',
          value: 'nochange',
          keyFinding: 'Cough same',
          ds: ds.sameCough,
        },
        {
          label: 'Worse',
          value: 'worse',
          keyFinding: 'Cough worsened',
          onSelected: bumpNewSymptomScore,
        },
      ],
    });

    if (returnHome()) {
      return {};
    }

    const newsymptoms = await eChoice({
      id: 'coughChild.newsymptoms',
      label: 'Does your child have any new symptoms?',
      clinicLabel: 'New symptoms',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          keyFinding: 'New symptoms',
          onSelected: bumpNewSymptomScore,
        },
        { label: 'No', value: 'no', keyFinding: 'No new symptoms' },
      ],
    });

    if (newsymptoms === 'yes') {
      await eText({
        id: 'coughChild.newsymptom',
        label: 'Please tell us about these new symptoms.',
        clinicLabel: 'New symptoms',
      });
    }

    if (returnHome()) {
      return {};
    }

    if (newsymptoms === 'no') {
      const previoustests = await eChoice({
        id: 'coughChild.previoustestsforcough',
        label: 'Has your child had any previous tests for their cough?',
        clinicLabel: 'Previous tests for cough',
        options: [
          {
            label: 'Yes',
            value: 'yes',
            keyFinding: 'Previous tests for cough',
            ds: ds.previousTest,
            hide: true,
          },
          {
            label: 'No',
            value: 'no',
            keyFinding: 'No previous tests for cough',
          },
        ],
      });

      if (previoustests === 'yes') {
        await eText({
          id: 'coughChild.whatwastheprevioustestforcough',
          label: 'What tests have they had?',
          clinicLabel: 'Previous test for cough',
        });
      }

      const previousdiagnosis = await eChoice({
        id: 'coughChild.previousdiagnosis',
        label: 'Has your child been given a diagnosis?',
        clinicLabel: 'Previous diagnosis',
        options: [
          {
            label: 'Yes',
            value: 'yes',
            keyFinding: 'Previous diagnosis',
            hide: true,
          },
          { label: 'No', value: 'no', keyFinding: 'No previous diagnosis' },
        ],
      });

      if (previousdiagnosis === 'yes') {
        await eText({
          id: 'coughChild.previousdiagnosiswhat',
          label: 'What diagnosis was your child given?',
          clinicLabel: 'Previous diagnosis',
        });
      }

      const askingForReview = exports.find(
        s => s.value === keyFindingLabelReview
      );

      let medicine = null;

      if (askingForReview) {
        medicine = await eChoice({
          id: 'coughChild.triedmedicationforcough',
          label:
            'Has your child been given any medication to help their cough?',
          clinicLabel: 'Tried medication for cough',
          options: [
            {
              label: 'Yes',
              value: 'yes',
              keyFinding: 'Medication tried for cough',
              ds: ds.previousMedicine,
              hide: true,
            },
            {
              label: 'No',
              value: 'no',
              keyFinding: 'Medication not tried for cough',
            },
          ],
        });
      }

      if (medicine === 'yes' || medicine === null) {
        await eText({
          id: 'coughChild.whatmedicationwastriedforcough',
          label: 'What medication were they given?',
          clinicLabel: 'Medication tried for cough',
        });

        await eChoice({
          id: 'coughChild.didthemedicationhelp',
          label: 'Did the medication help?',
          clinicLabel: 'Did the medication help',
          options: [
            { label: 'Yes', value: 'yes', keyFinding: 'Medication helped' },
            {
              label: 'No',
              value: 'no',
              keyFinding: 'Medication did not help',
            },
          ],
        });
      }
    }
  }

  return {};
}: Guide);

import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Progress from '@material-ui/core/CircularProgress';

import Page from './components/Page';
import Frame from './components/Frame';
import Body from './components/Body';

import ThemeProvider from './theme/ThemeProvider';
import BackgroundColor from './components/BackgroundColor';

import colors from './theme/colors';
import Logo from './components/Logo';

import { useFirebase } from 'react-redux-firebase';

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: theme.spacing(2),
  },
  h2: {
    marginBottom: theme.spacing(2),
  },
  body1: {
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
}));

function UnauthenticatedApp(props: *) {
  const firebase = useFirebase();
  const loading = false;
  const error = props.error;

  const handleGoogleSignIn = () => {
    firebase.login({ provider: 'google', type: 'popup' });
  };

  const classes = useStyles(props);
  return (
    <>
      <BackgroundColor color={colors.grey_100} />
      <ThemeProvider>
        <Body>
          <Page>
            <Frame>
              <Grid container>
                <Grid item xs={1}>
                  <Logo className={classes.logo} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" className={classes.h2}>
                    Log in with your Docly account
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.body1}>
                    To access inquisitor you need to log in with your Docly
                    account from Google.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {loading ? (
                    <Grid container justify="center">
                      <Grid item xs={1}>
                        <Progress />
                      </Grid>
                    </Grid>
                  ) : (
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => handleGoogleSignIn()}
                    >
                      Log in
                    </Button>
                  )}
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Typography color="primary" variant="body1">
                      {error}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Frame>
          </Page>
        </Body>
      </ThemeProvider>
    </>
  );
}

export default UnauthenticatedApp;

import type { Guide } from '../../types';

import aborts from './aborts';

export default (async ({ ask, significant }) => {
  const suicideThoughts = {
    type: 'choice',
    id: 'suicideThoughts',
    label:
      'När man drabbas av psykisk ohälsa är det vanligt att man upplever tillvaron som hopplös och att livet ibland inte känns värt att leva. Känner du igen den här typen av tankar hos dig själv?',
    options: [
      {
        value: 'often',
        label: 'Ja, jag tänker ofta så.',
      },
      {
        value: 'sometimes',
        label: 'Jag tänker så ibland.',
      },
      {
        value: 'no',
        label: 'Nej',
      },
    ],
  };

  const suicideThoughtsAnswer = await ask(suicideThoughts);

  significant.push({
    ...suicideThoughts,
    label: 'Tankar på att livet inte känns värt att leva',
    value: suicideThoughtsAnswer,
    warn: ['sometimes', 'often'],
  });

  if (
    suicideThoughtsAnswer.includes('sometimes') ||
    suicideThoughtsAnswer.includes('often')
  ) {
    const triedSuicide = await ask({
      label: 'Har du någon gång försökt ta ditt liv?',
      id: 'triedSuicide',
    });

    significant.push({
      label: 'Tidigare suicidförsök',
      value: triedSuicide,
    });

    if (triedSuicide === 'yes') {
      return { abort: aborts.suicidal };
    }

    const suicidePlan = {
      type: 'choice',
      id: 'suicidePlan',
      label: 'Har du en plan för hur du ska gå tillväga för att ta ditt liv?',
      options: [
        {
          value: 'yes',
          label: 'Ja',
        },
        {
          value: 'noJustThoughts',
          label: 'Nej, bara flyktiga tankar',
        },
        {
          value: 'notAtAll',
          label: 'Nej, inte alls',
        },
      ],
    };

    const suicidePlanAnswers = await ask(suicidePlan);

    significant.push({
      ...suicidePlan,
      label: 'Suicidplaner',
      value: suicidePlanAnswers,
    });

    if (suicidePlanAnswers === 'yes') {
      return { abort: aborts.suicidal };
    }
  }

  return {};
}: Guide);

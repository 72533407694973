export const ADD_TEST_CASE_STARTED = 'ADD_TEST_CASE_STARTED';
export const ADD_TEST_CASE_SUCCESS = 'ADD_TEST_CASE_SUCCESS';
export const ADD_TEST_CASE_FAILURE = 'ADD_TEST_CASE_FAILURE';

export type CurrentTestCaseMeta = {
  id?: string,
  saving: boolean,
  saveError?: string,
};

export type State = {
  current: CurrentTestCaseMeta,
};

export type FirebaseState = {
  data: *,
};

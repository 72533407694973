import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import { _t } from '../../../i18n';

import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

import Key from '../../components/Key';

class SourceButton extends React.Component<{
  guideId: string,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { guideId, classes, className } = this.props;

    const fn = () => navigate(`${guideId}/source`);

    return (
      <Button
        variant="outlined"
        onClick={fn}
        className={classNames(classes.root, className)}
      >
        {_t('guides.source')}

        <Key character="S" onPress={fn} className={classes.key} />
      </Button>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {},

  key: {
    marginLeft: spacing(),
  },
}))(connect(undefined)(SourceButton));

export default {
  rejectBreathingProblem: {
    id: 'rejectBreathingProblem',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: '?Breathing problem',
  },
  rejectWheeze: {
    id: 'rejectWheeze',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: 'Wheeze',
  },
  rejectLrtiBrownPhlem: {
    id: 'rejectLrtiBrownPhlem',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: '?Bacterial LRTI - brown phlegm',
  },
  rejectLrtiHaemoptysis: {
    id: 'rejectLrtiHaemoptysis',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: '?Bacterial LRTI - haemoptysis',
  },
  rejectLrti: {
    id: 'rejectLrti',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: '?Bacterial LRTI',
  },
  rejectSevereMusclePain: {
    id: 'rejectSevereMusclePain',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: '?Sepsis - Severe muscle pain',
  },
  rejectAbdoPain: {
    id: 'rejectAbdoPain',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: '?Sepsis - Abdo Pain',
  },
  rejectFeelingFaint: {
    id: 'rejectFeelingFaint',
    label: 'Reject - Urgent Appointment',
    color: 'red',
    description: '?Bacterial LRTI - feeling faint',
  },
  pvConsultPregnant: {
    id: 'pvConsultPregnant',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Pregnant',
  },
  pvConsultPostpartumAndCough: {
    id: 'pvConsultPostpartumAndCough',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Postpartum + cough',
  },
  pvConsultImmunosuppressed: {
    id: 'pvConsultImmunosuppressed',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Immunosuppressed',
  },
  pvConsultCOPD: {
    id: 'pvConsultCOPD',
    color: 'red',
    label: 'Phone/video consult',
    description: 'COPD',
  },
  pvConsultHeartDisease: {
    id: 'pvConsultHeartDisease',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Heart disease',
  },
  pvConsultPreviousVTE: {
    id: 'pvConsultPreviousVTE',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Previous VTE',
  },
  pvConsultRecentMajorSurgery: {
    id: 'pvConsultRecentMajorSurgery',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Recent major surgery',
  },
  pvConsultCough: {
    id: 'pvConsultCough',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Cough > 3 weeks',
  },
  pvConsultDifficultyInBreathing: {
    id: 'pvConsultDifficultyInBreathing',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Difficulty in breathing',
  },
  pvConsultWheeze: {
    id: 'pvConsultWheeze',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Wheeze',
  },
  pvConsultLrtiBrownPhlem: {
    id: 'pvConsultLrtiBrownPhlem',
    color: 'red',
    label: 'Phone/video consult',
    description: '?Bacterial LRTI - brown phlegm',
  },
  pvConsultHaemoptysis: {
    id: 'pvConsultHaemoptysis',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Haemoptysis',
  },
  pvConsultBacterialLrti: {
    id: 'pvConsultBacterialLrti',
    color: 'red',
    label: 'Phone/video consult',
    description: '?Bacterial LRTI',
  },
  pvConsultAbdominalPain: {
    id: 'pvConsultAbdominalPain',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Abdominal pain',
  },
  pvConsultDV: {
    id: 'pvConsultDV',
    color: 'red',
    label: 'Phone/video consult',
    description: 'D&V',
  },
  pvConsultSevereMusclePain: {
    id: 'pvConsultSevereMusclePain',
    color: 'red',
    label: 'Phone/video consult',
    description: 'Severe muscle pain',
  },
  pvConsultBacterialLrtiFeelingFaint: {
    id: 'pvConsultBacterialLrtiFeelingFaint',
    color: 'red',
    label: 'Phone/video consult',
    description: '?Bacterial LRTI - feeling faint',
  },
};

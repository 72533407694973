import * as React from 'react';

import { _t } from '../../i18n';

import { isPhone } from '../utils/device';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

import withStyles from '@material-ui/core/styles/withStyles';

import Next from '../components/Next';

class Text extends React.Component<
  {
    type?: 'text' | 'number',
    placeholder?: string,
    multiline?: boolean,
    value?: *,
    error?: string,
    update: (?*) => *,
    done: (?*) => *,
    classes: { [string]: string },
  },
  {
    touched: boolean,
    errorVisible: boolean,
  }
> {
  state = {
    touched: !!this.props.value,
    errorVisible: false,
  };

  onChange = ({ target: { value } }) => {
    const { update } = this.props;

    this.setState({ touched: true });

    update(value);
  };

  onBlur = () => {
    const { error } = this.props;
    const { touched } = this.state;

    this.setState({ errorVisible: error !== undefined && touched });
  };

  render() {
    const {
      type,
      placeholder,
      multiline,
      value = '',
      error,
      done,
      classes,
    } = this.props;
    const { errorVisible } = this.state;

    return (
      <div className={classes.root}>
        <FormControl className={classes.form}>
          <TextField
            type={type}
            variant="filled"
            error={errorVisible && !!error}
            defaultValue={value}
            multiline={multiline !== undefined ? multiline : true}
            autoFocus={!isPhone}
            fullWidth
            placeholder={
              placeholder !== undefined ? placeholder : _t('input.placeholder')
            }
            onChange={this.onChange}
            onBlur={this.onBlur}
            className={classes.text}
            InputProps={{
              className: classes.input,
              classes: { input: multiline === false && classes.inputField },
            }}
          />

          <FormHelperText className={classes.error}>
            {!!errorVisible && error}
          </FormHelperText>
        </FormControl>

        <div className={classes.buttons}>
          <Next
            disabled={!!error}
            onClick={() => done(value)}
            className={classes.button}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContet: 'center',
  },

  form: {
    flex: 1,
    width: '100%',
  },

  text: {
    width: '100%',
  },

  input: {
    padding: '16px 12px',
  },

  inputField: {
    height: 'auto',
    padding: 0,
  },

  error: {
    minHeight: 0,
  },

  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: spacing(3),
  },
}))(Text);

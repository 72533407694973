import { type Guide } from '../../types';

import wrapFormulary from '../../formulary/wrapFormulary';

import formulary from './formulary';

const wrappedFormulary = wrapFormulary(formulary);

export default (async args => {
  await wrappedFormulary(args);

  return {};
}: Guide);

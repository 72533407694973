import * as React from 'react';
import classNames from 'classnames';
import isArray from 'lodash/isArray';

import colors from '../../theme/colors';
import typography, { fontWeights } from '../../theme/typography';

import { type Answer as AnswerType } from '../../../guides/types';

import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

import Files from '../../components/Files';
import Markdown from '../../components/Markdown';

import { getDisplayValue } from '../utils';

export const AnswerMarkdown = withStyles(() => ({
  heading: {
    ...typography.body1,
    marginBottom: 0,
  },
  paragraph: {
    marginBottom: 0,
  },
  bold: {
    fontWeight: fontWeights.regular,
  },
}))(Markdown);

class Answer extends React.Component<{
  answer: AnswerType,
  classes: { [string]: string },
  className?: string,
}> {
  render = () => {
    const { answer, classes, className } = this.props;

    const { type, label, description, warn = false, value } = answer;

    const displayValue = getDisplayValue(answer);

    if (!(Array.isArray(displayValue) || typeof displayValue === 'string')) {
      console.warn(
        'Can not render answer',
        answer,
        'with value: ',
        displayValue
      );
    }

    return (
      <div className={classNames(classes.root, className)}>
        {label !== undefined && label !== '' && (
          <Typography>{label}</Typography>
        )}
        {description !== undefined && description !== '' && (
          <AnswerMarkdown>{description.trim()}</AnswerMarkdown>
        )}
        {type === 'upload' && !!(value && value.length) ? (
          <Files values={value} />
        ) : (
          (isArray(displayValue) ? displayValue : [displayValue]).map(
            (a, i) => (
              <Typography
                key={i}
                className={classNames(
                  classes.bold,
                  warn === true && classes.warn
                )}
              >
                {a}
              </Typography>
            )
          )
        )}

        {warn === true && <div className={classes.warningBar} />}
      </div>
    );
  };
}

export default withStyles(({ spacing }) => ({
  root: {
    position: 'relative',
  },

  bold: { fontWeight: 500 },

  warn: { color: colors.red_500 },

  warningBar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -(spacing() + 4),
    width: 4,
    backgroundColor: colors.red_500,
    borderRadius: 2,
  },
}))(Answer);

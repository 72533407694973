import type { Export } from '../../types';

export default (exports: Export[]) => {
  exports.push({ id: 'presentingComplaint', value: 'Urinary symptoms' });
  exports.push({ id: 'snomedCode', value: '249274008 Urinary symptoms' });
  exports.push({
    id: 'diagnosis.options',
    value: [
      'Suspected UTI',
      'Recurrent UTI',
      'Urinary symptoms',
      'Seeking antibiotics for future use',
    ],
  });
  exports.push({
    id: 'investigationsRequested.options',
    value: ['Urine dipstick', 'Urine culture', 'None'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Self care',
      'Delayed prescription offered with follow up consultation',
      'Antibiotics and advice',
      'Referred to GP',
      'Referred to GP or Urgent care',
      'Urgent care recommended',
      'Emergency care recommended',
    ],
  });
  exports.push({
    id: 'actionNeeded.options',
    value: [
      'Perform urine dipstick and advise patient accordingly',
      'Urine Culture',
      'Assess for pyelonephritis / sepsis',
      'Assess genital symptoms',
      'Assess and manage recurrent UTI',
      'None',
    ],
  });
};

import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

import type { Guide } from '../../types';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const expectations = await eChoice({
    id: 'otherProblems.describeProblem.expectations',
    label: `What do you hope the doctor does for you today?`,
    description: 'More than one answer can be selected',
    clinicLabel: 'Expectations',
    isMultipleChoice: true,
    options: [
      {
        label: 'Sends me for tests',
        value: 'tests',
      },
      {
        label: 'Changes my treatment',
        value: 'changeTreatment',
      },
      {
        label: 'Gives me more medication',
        value: 'moreMeds',
      },
      {
        label: 'Gives me a diagnosis',
        value: 'diagnosis',
      },
      {
        label: 'Other',
        value: 'other',
        hide: true,
      },
    ],
  });
  if (Array.isArray(expectations) && expectations.includes('other')) {
    await eText({
      id: 'otherProblems.describeProblem.otherExpectations',
      label: `What else do you hope the doctor does for you today?`,
      clinicLabel: 'Other expectation',
    });
  }

  const patientConcern = await eChoice({
    id: 'otherProblems.describeProblem.patientConcern',
    label: `What concerns you the most about your symptoms?`,
    description: 'More than one answer can be selected',
    clinicLabel: 'Patient concern',
    isMultipleChoice: true,
    options: [
      {
        label: "I'm not concerned, I just want treatment",
        value: 'treatment',
      },
      {
        label: "They're getting worse",
        value: 'gettingWorse',
      },
      {
        label: "They're not improving",
        value: 'notImproving',
      },
      {
        label: "I've got new symptoms",
        value: 'newSymptoms',
      },
      {
        label: "I'm worried I might have cancer",
        value: 'haveCancer',
      },
      {
        label: 'Other',
        value: 'other',
        hide: true,
      },
    ],
  });
  if (Array.isArray(patientConcern) && patientConcern.includes('newSymptoms')) {
    await eText({
      id: 'otherProblems.describeProblem.otherNewSymptoms',
      label: `What new symptoms are you concerned about?`,
      clinicLabel: 'New symptoms patient is concerned about',
    });
  }
  if (Array.isArray(patientConcern) && patientConcern.includes('other')) {
    await eText({
      id: 'otherProblems.describeProblem.otherPatientConcern',
      label: `What else are you concerned about?`,
      clinicLabel: 'Other concerns',
    });
  }

  return {};
}: Guide);

export const DRAFTS_UPDATE = 'DRAFTS_UPDATE';
export const DRAFTS_INITIALIZED = 'DRAFTS_INITIALIZED';

export type Draft = {
  visited: string[],
  values: { [string]: * },
};

export type State = {
  byId: {
    [string]: Draft,
  },
};

export default {
  id: 'borrelia',
  type: 'formulary',
  version: '5',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'borrelia.started',
          type: 'choice',
          version: '2',
          options: [
            {
              value: 'lessThan4days',
              label: 'För mindre än 4 dagar sedan',
            },
            {
              value: 'from4to14days',
              label: 'För 4–14 dagar sedan',
            },
            {
              value: 'from15to3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
            {
              value: 'didntNotice',
              label: 'Jag har inte märkt att jag blivit biten',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När tror du att du blev biten av en fästing?',
          index: '0.0.0',
        },
        {
          id: 'borrelia.bitten',
          type: 'text',
          version: '2',
          condition:
            "!(get('answers.borrelia.started') === 'didntNotice' || get('answers.borrelia.started') === undefined)",
          validations: {
            required: true,
          },
          label: 'Var på kroppen har du blivit biten?',
          index: '0.0.1',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'rash.quantity',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'none',
              label: 'Jag har ingen hudrodnad.',
            },
            {
              value: 'one',
              label: 'Jag har en hudrodnad.',
            },
            {
              value: 'twoOrMore',
              label: 'Jag har två eller fler hudrodnader.',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Välj det alternativ som stämmer bäst.',
          index: '0.1.0',
        },
        {
          id: 'rash.started',
          type: 'text',
          version: '2',
          condition:
            "get('answers.rash.quantity') === 'one' || get('answers.rash.quantity') === 'twoOrMore'",
          validations: {
            required: true,
          },
          label: 'När uppstod din(a) hudrodnad(er)?',
          index: '0.1.1',
        },
        {
          id: 'rash.size',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'lessThan5cm',
              label: 'Mindre än 5 cm',
            },
            {
              value: '5cmOrBigger',
              label: '5 cm eller större',
            },
          ],
          condition:
            "get('answers.rash.quantity') === 'one' || get('answers.rash.quantity') === 'twoOrMore'",
          validations: {
            required: true,
          },
          label: 'Hur stor är (den största) hudrodnaden i diameter?',
          index: '0.1.2',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.fever',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du feber (över 38°C)?',
          index: '0.2.0',
        },
        {
          id: 'health.temperature',
          version: '4',
          type: 'number',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur hög feber har du just nu?',
          unit: '°C',
          index: '0.2.1',
        },
        {
          id: 'medication.recentAgainstFever',
          version: '1',
          type: 'binary',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Har du tagit febernedsättande läkemedel under de senaste 4 timmarna?',
          index: '0.2.2',
        },
        {
          id: 'health.feverDuration',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'within24hours',
              label: 'I ett dygn eller mindre',
            },
            {
              value: 'last2to4days',
              label: 'Under 2–4 dagars tid',
            },
            {
              value: 'last5to14days',
              label: 'Under 5–14 dagars tid',
            },
          ],
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur länge har du haft feber?',
          index: '0.2.3',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'rash.pictures',
          type: 'upload',
          version: '9',
          validations: {
            required: true,
            min: 2,
          },
          extras: {
            instructions: ['closeup', 'lighting'],
          },
          condition:
            "get('answers.rash.quantity') === 'one' || get('answers.rash.quantity') === 'twoOrMore'",
          label:
            'Bifoga minst två aktuella bilder av din(a) hudrodnad(er); en närbild och en översiktsbild.',
          description:
            '  <p>\n    Det går bra att ta bilden med din mobilkamera, men undvik att använda dess zoom-funktion. Försök ta en så detaljerad närbild som möjligt i bra belysning.\n  </p>\n\n  <p>\n    Observera att även bilderna är en del av din journal och omfattas av sekretess.\n  </p>',
          index: '0.3.0',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.4.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.4.1',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'reproduction.pregnant',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Är du gravid?',
              index: '0.5.0.0',
            },
            {
              id: 'reproduction.breastfeeding',
              version: '1',
              type: 'binary',
              validations: {
                required: true,
              },
              label: 'Ammar du?',
              index: '0.5.0.1',
            },
          ],
          version: '1',
          condition:
            "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
          index: '0.5.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'other',
              type: 'text',
              validations: {
                required: false,
              },
              version: '1',
              label: 'Övrig medicinsk information',
              description:
                'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
              index: '0.5.1.0',
            },
          ],
          index: '0.5.1',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.6',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.7',
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
        },
        {
          id: 'borrelia.started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Biten av fästing',
          options: [
            {
              value: 'lessThan4days',
              label: 'För mindre än 4 dagar sedan',
            },
            {
              value: 'from4to14days',
              label: 'För 4–14 dagar sedan',
            },
            {
              value: 'from15to3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
            {
              value: 'didntNotice',
              label: 'Jag har inte märkt att jag blivit biten',
            },
          ],
        },
        {
          id: 'borrelia.bitten',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Bettlokalisation',
        },
        {
          id: 'rash.quantity',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Antal hudrodnader',
          description: '',
          options: [
            {
              value: 'none',
              label: 'Jag har ingen hudrodnad.',
            },
            {
              value: 'one',
              label: 'Jag har en hudrodnad.',
            },
            {
              value: 'twoOrMore',
              label: 'Jag har två eller fler hudrodnader.',
            },
          ],
        },
        {
          id: 'rash.started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hudrodnad uppstod',
          description: '',
        },
        {
          id: 'rash.size',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diameter på hudrodnad',
          description: '',
          options: [
            {
              value: 'lessThan5cm',
              label: 'Mindre än 5 cm',
            },
            {
              value: '5cmOrBigger',
              label: '5 cm eller större',
            },
          ],
        },
        {
          id: 'health.fever',
          warning: "get('answers.health.fever') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber',
          description: '',
        },
        {
          id: 'health.temperature',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Temperatur',
          description: '',
          unit: '°C',
        },
        {
          id: 'medication.recentAgainstFever',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Febernedsättande läkemedel under de senaste 4 timmarna',
          description: '',
        },
        {
          id: 'health.feverDuration',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber sedan',
          description: '',
          options: [
            {
              value: 'within24hours',
              label: 'I ett dygn eller mindre',
            },
            {
              value: 'last2to4days',
              label: 'Under 2–4 dagars tid',
            },
            {
              value: 'last5to14days',
              label: 'Under 5–14 dagars tid',
            },
          ],
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Borrelia',
  index: '0',
};

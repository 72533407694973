export default {
  id: 'vaccination',
  version: '6-mdk',
  type: 'formulary',
  children: [
    {
      id: 'vaccination.abroad.match',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label: 'Ska du vaccinera dig inför en utlandsvistelse?',
      index: '0.0',
    },
    {
      id: 'vaccination.abroad.countriesAndDates',
      version: '1',
      type: 'text',
      condition: "get('answers.vaccination.abroad.match') === 'yes'",
      validations: {
        required: true,
      },
      label: 'Vilka resor (land och datum) har du planerat?',
      index: '0.1',
    },
    {
      id: 'vaccination.previous.match',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label: 'Har du fått någon vaccination under de senaste 4 veckorna?',
      index: '0.2',
    },
    {
      id: 'vaccination.previous.what',
      type: 'multipleChoice',
      version: '1',
      options: [
        {
          value: 'TBEDose1',
          label: 'TBE dos 1',
        },
        {
          value: 'TBEDose2',
          label: 'TBE dos 2',
        },
        {
          value: 'other',
          label: 'Annan vaccination',
        },
      ],
      validations: {
        required: true,
      },
      condition: "get('answers.vaccination.previous.match') === 'yes'",
      label: 'Vilken vaccination har du fått under de senaste 4 veckorna?',
      description: 'Flera svarsalternativ möjliga.',
      index: '0.3',
    },
    {
      id: 'vaccination.previous.comment',
      version: '1',
      type: 'text',
      condition: "contains(get('answers.vaccination.previous.what'), 'other')",
      validations: {
        required: true,
      },
      label: 'Kommentar - Nyligen vaccinerats',
      index: '0.4',
    },
    {
      id: 'vaccination.seriousReaction.match',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label: 'Har du reagerat kraftigt på tidigare vaccinationer?',
      index: '0.5',
    },
    {
      id: 'vaccination.seriousReaction.comment',
      version: '1',
      type: 'text',
      condition: "get('answers.vaccination.seriousReaction.match') === 'yes'",
      validations: {
        required: true,
      },
      label: 'Kommentar - Kraftig reaktion på tidigare vaccination',
      index: '0.6',
    },
    {
      id: 'allergy.egg',
      type: 'binary',
      version: '1',
      validations: {
        required: true,
      },
      label: 'Är du allergisk mot ägg?',
      index: '0.7',
    },
    {
      id: 'allergy.eggComment',
      type: 'text',
      version: '1',
      condition: "get('answers.allergy.egg') === 'yes'",
      validations: {
        required: true,
      },
      label: 'Kommentar - Äggallergi',
      index: '0.8',
    },
    {
      id: 'allergy.penicillinMatch',
      type: 'choice',
      version: '1',
      options: [
        {
          value: 'yesKnows',
          label:
            'Ja, och jag vet vilken sorts penicillin eller antibiotika jag är allergisk mot.',
        },
        {
          value: 'yesDontKnow',
          label:
            'Ja, men jag vet inte vilken sorts penicillin eller antibiotika jag är allergisk mot.',
        },
        {
          value: 'no',
          label: 'Nej',
        },
        {
          value: 'dontKnow',
          label: 'Vet ej',
        },
      ],
      expires: 180,
      validations: {
        required: true,
      },
      label: 'Är du allergisk mot penicillin eller annan antibiotika?',
      index: '0.9',
    },
    {
      id: 'allergy.penicillinComment',
      type: 'text',
      version: '1',
      condition:
        "any(get('answers.allergy.penicillinMatch'), 'yesKnows', 'yesDontKnow')",
      validations: {
        required: true,
      },
      label: 'Kommentar - Antibiotikaallergi',
      index: '0.10',
    },
    {
      id: 'health.issues.what',
      version: '6',
      type: 'multipleChoice',
      options: [
        {
          value: 'bloodpreassure',
          label: 'Högt blodtryck med tablettbehandling',
        },
        {
          value: 'cardiovascular',
          label: 'Hjärt-kärlsjukdom',
        },
        {
          value: 'diabetes',
          label: 'Diabetes',
        },
        {
          value: 'lungs',
          label: 'Lungsjukdom (inkl. astma)',
        },
        {
          value: 'cancer',
          label: 'Cancersjukdom',
        },
        {
          value: 'neuro',
          label: 'Neurologisk sjukdom',
        },
        {
          value: 'blooddisease',
          label: 'Blodsmitta (HIV, Hepatit B, Hepatit C)',
        },
        {
          value: 'kidney',
          label: 'Njurfunktionsnedsättning',
        },
        {
          value: 'liver',
          label: 'Leverfunktionsnedsättning',
        },
        {
          value: 'immuneSystem',
          label: 'Nedsatt immunförsvar',
        },
        {
          value: 'rheumatic',
          label: 'Reumatologisk sjukdom',
        },
        {
          value: 'other',
          label: 'Annat',
        },
      ],
      expires: 900,
      validations: {
        required: false,
      },
      label: 'Markera de sjukdomar eller hälsoproblem du har, eller har haft.',
      description: 'Flera svarsalternativ möjliga.',
      index: '0.11',
    },
    {
      id: 'health.issues.other',
      version: '2',
      type: 'text',
      expires: 900,
      condition:
        "contains(get('answers.health.issues.what'),'other') || contains(get('answers.health.issues.what'),'rheumatic')",
      validations: {
        required: true,
      },
      label: 'Vilken sjukdom?',
      index: '0.12',
    },
    {
      id: 'health.cardiovascular.what',
      type: 'composite',
      version: '2',
      children: [
        {
          id: 'health.cardiovascular.what.what',
          type: 'multipleChoice',
          options: [
            {
              value: 'infarction',
              label: 'Hjärtinfarkt',
            },
            {
              value: 'congestiveHeart',
              label: 'Hjärtsvikt',
            },
            {
              value: 'anginaPectoris',
              label: 'Kärlkramp',
            },
            {
              value: 'atrialFibrillation',
              label: 'Förmaksflimmer',
            },
            {
              value: 'heartValve',
              label: 'Hjärtklaffsfel',
            },
            {
              value: 'stroke',
              label: 'Stroke',
            },
            {
              value: 'bloodClot',
              label: 'Blodpropp som krävt blodförtunnande medicinering',
            },
            {
              value: 'other',
              label: 'Annan hjärt-kärlsjukdom',
            },
          ],
          validations: {
            required: true,
          },
          expires: 900,
          relativeId: 'what',
          version: '2',
          label: 'Vilka hjärt-kärlsjukdomar har du, eller har du haft?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.13.0',
        },
        {
          id: 'health.cardiovascular.what.other',
          type: 'text',
          condition:
            "contains(get('answers.health.cardiovascular.what.what'), 'other')",
          expires: 900,
          relativeId: 'other',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Vilken hjärt-kärlsjukdom har du, eller har du haft?',
          index: '0.13.1',
        },
      ],
      condition:
        "contains(get('answers.health.issues.what'), 'cardiovascular')",
      extras: {},
      index: '0.13',
    },
    {
      id: 'health.diabetes.type',
      version: '1',
      type: 'multipleChoice',
      options: [
        {
          value: 'type1',
          label: 'Diabetes typ 1',
        },
        {
          value: 'type2',
          label: 'Diabetes typ 2',
        },
        {
          value: 'other',
          label: 'Annat',
        },
      ],
      expires: 900,
      validations: {
        required: true,
      },
      condition: "contains(get('answers.health.issues.what'), 'diabetes')",
      label: 'Vilken typ av diabetes har du?',
      description: 'Flera svarsalternativ möjliga.',
      index: '0.14',
    },
    {
      id: 'health.diabetes.typeOther',
      version: '1',
      type: 'text',
      expires: 900,
      condition: "contains(get('answers.health.diabetes.type'), 'other')",
      validations: {
        required: true,
      },
      label: 'Vilken typ av diabetes har du?',
      index: '0.15',
    },
    {
      id: 'health.diabetes.treatment',
      version: '2',
      type: 'multipleChoice',
      options: [
        {
          value: 'food',
          label: 'Kostbehandling',
        },
        {
          value: 'pills',
          label: 'Tablettbehandling',
        },
        {
          value: 'insuline',
          label: 'Insulinbehandling',
        },
      ],
      expires: 900,
      validations: {
        required: true,
      },
      condition:
        "contains(get('answers.health.diabetes.type'), 'type2') || contains(get('answers.health.diabetes.type'), 'other')",
      label: 'Hur behandlas du för din diabetes?',
      description: 'Flera svarsalternativ möjliga.',
      index: '0.16',
    },
    {
      id: 'health.lungDiseases.what',
      type: 'composite',
      version: '1',
      children: [
        {
          id: 'health.lungDiseases.what.what',
          type: 'multipleChoice',
          options: [
            {
              value: 'astma',
              label: 'Astma',
            },
            {
              value: 'kol',
              label: 'KOL',
            },
            {
              value: 'other',
              label: 'Annan lungsjukdom',
            },
          ],
          validations: {
            required: true,
          },
          expires: 900,
          relativeId: 'what',
          version: '1',
          label: 'Vilka lungsjukdomar har du, eller har du haft?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.17.0',
        },
        {
          id: 'health.lungDiseases.what.other',
          type: 'text',
          condition:
            "contains(get('answers.health.lungDiseases.what.what'), 'other')",
          expires: 900,
          relativeId: 'other',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Vilken lungsjukdom har du, eller har du haft?',
          index: '0.17.1',
        },
      ],
      condition: "contains(get('answers.health.issues.what'), 'lungs')",
      extras: {},
      index: '0.17',
    },
    {
      id: 'health.cancerWhat',
      type: 'text',
      expires: 900,
      version: '1',
      condition: "contains(get('answers.health.issues.what'), 'cancer')",
      validations: {
        required: true,
      },
      label: 'Vilken cancersjukdom har du, eller har du haft?',
      index: '0.18',
    },
    {
      id: 'health.neurological.what',
      type: 'composite',
      version: '1',
      children: [
        {
          id: 'health.neurological.what.what',
          type: 'multipleChoice',
          options: [
            {
              value: 'epilepsy',
              label: 'Epilepsi',
            },
            {
              value: 'ms',
              label: 'MS',
            },
            {
              value: 'parkinson',
              label: 'Parkinson',
            },
            {
              value: 'other',
              label: 'Annan neurologisk sjukdom',
            },
          ],
          validations: {
            required: true,
          },
          expires: 900,
          relativeId: 'what',
          version: '1',
          label: 'Vilka neurologiska sjukdomar har du, eller har du haft?',
          index: '0.19.0',
        },
        {
          id: 'health.neurological.what.other',
          type: 'text',
          condition:
            "contains(get('answers.health.neurological.what.what'), 'other')",
          expires: 900,
          relativeId: 'other',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Vilken neurologisk sjukdom har du, eller har du haft?',
          index: '0.19.1',
        },
      ],
      condition: "contains(get('answers.health.issues.what'), 'neuro')",
      extras: {},
      index: '0.19',
    },
    {
      id: 'health.bloodDisease.what',
      version: '2',
      type: 'multipleChoice',
      options: [
        {
          value: 'hepB',
          label: 'Hepatit B',
        },
        {
          value: 'hepC',
          label: 'Hepatit C',
        },
        {
          value: 'hiv',
          label: 'HIV',
        },
      ],
      validations: {
        required: true,
      },
      expires: 365,
      condition: "contains(get('answers.health.issues.what'), 'blooddisease')",
      label: 'Vilken blodsmitta har du, eller har du haft?',
      description: 'Flera svarsalternativ möjliga.',
      index: '0.20',
    },
    {
      id: 'health.immuneSystem.what',
      version: '3',
      type: 'multipleChoice',
      options: [
        {
          value: 'noSpleen',
          label: 'Jag har ingen mjälte.',
        },
        {
          value: 'immuneDeficiencyDisease',
          label: 'Jag har en diagnosticerad immunbristsjukdom.',
        },
        {
          value: 'medicineImmunesystem',
          label: 'Jag tar läkemedel som försämrar immunförsvaret.',
        },
        {
          value: 'other',
          label: 'Annat',
        },
      ],
      validations: {
        required: true,
      },
      condition: "contains(get('answers.health.issues.what'), 'immuneSystem')",
      label: 'Vad är anledningen till att du har nedsatt immunförsvar?',
      description: 'Flera svarsalternativ möjliga.',
      index: '0.21',
    },
    {
      id: 'health.immuneSystem.other',
      version: '1',
      type: 'text',
      condition: "contains(get('answers.health.immuneSystem.what'), 'other')",
      validations: {
        required: true,
      },
      label: 'Vad är anledningen till att du har nedsatt immunförsvar?',
      index: '0.22',
    },
    {
      id: 'health.issues.comment',
      version: '1',
      type: 'text',
      condition: "get('answers.health.issues.what') !== undefined",
      validations: {
        required: true,
      },
      label: 'Kommentar - Sjukdom/Hälsoproblem',
      index: '0.23',
    },
    {
      id: 'medication.vaccination.what',
      version: '1',
      type: 'multipleChoice',
      options: [
        {
          value: 'kortison',
          label: 'Kortison',
        },
        {
          value: 'waranWafarin',
          label: 'Waran/Warfarin',
        },
        {
          value: 'otherBloodThinning',
          label: 'Annan blodförtunnande behandling',
        },
        {
          value: 'immuneAffecting',
          label: 'Läkemedel som påverkar ditt immunförsvar',
        },
        {
          value: 'other',
          label: 'Annat läkemedel',
        },
      ],
      validations: {
        required: false,
      },
      label: 'Använder du något av följande läkemedel för tillfället?',
      description: 'Flera svarsalternativ möjliga.',
      index: '0.24',
    },
    {
      id: 'medication.vaccination.other',
      version: '1',
      type: 'text',
      condition:
        "contains(get('answers.medication.vaccination.what'), 'other')",
      validations: {
        required: true,
      },
      label: 'Vilket annat läkemedel använder du?',
      index: '0.25',
    },
    {
      id: 'medication.vaccination.comment',
      version: '1',
      type: 'text',
      condition:
        "get('answers.medication.vaccination.what') !== undefined && (length(get('answers.medication.vaccination.what')) > 1 || !contains(get('answers.medication.vaccination.what'), 'other'))",
      validations: {
        required: true,
      },
      label: 'Kommentar - Läkemedel',
      index: '0.26',
    },
    {
      id: 'reproduction.pregnant',
      version: '1',
      type: 'binary',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 55",
      validations: {
        required: true,
      },
      label: 'Är du gravid?',
      index: '0.27',
    },
    {
      id: 'reproduction.breastfeeding',
      version: '1',
      type: 'binary',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 55",
      validations: {
        required: true,
      },
      label: 'Ammar du?',
      index: '0.28',
    },
    {
      id: 'reproduction.pregnantComment',
      version: '1',
      type: 'text',
      condition:
        "get('answers.reproduction.pregnant') === 'yes' || get('answers.reproduction.breastfeeding') === 'yes'",
      validations: {
        required: true,
      },
      label: 'Kommentar - Gravid/Ammar',
      index: '0.29',
    },
    {
      id: 'health.feverColdIllness',
      version: '2',
      type: 'binary',
      validations: {
        required: true,
      },
      label:
        'Har du feber, förkylningssymptom eller andra nya symptom på sjukdom?',
      index: '0.30',
    },
    {
      id: 'health.feverColdIllnessComment',
      version: '1',
      type: 'text',
      condition: "get('answers.health.feverColdIllness') === 'yes'",
      validations: {
        required: true,
      },
      label: 'Kommentar - Pågående sjukdom',
      index: '0.31',
    },
    {
      id: 'otherChoice',
      version: '3-expiry',
      type: 'text',
      validations: {
        required: false,
      },
      label:
        'Finns det något annat angående din hälsostatus som du vill förmedla?',
      index: '0.32',
    },
    {
      id: 'vaccination.smsReminder.match',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label:
        'Vill du ta emot påminnelse om kommande vaccinationsdoser via sms?',
      index: '0.33',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.34',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.35',
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'vaccination.abroad.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vaccination inför utlandsvistelse',
          description: '',
        },
        {
          id: 'vaccination.abroad.countriesAndDates',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Resmål och datum',
          description: '',
        },
        {
          id: 'vaccination.previous.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vaccination senaste fyra veckorna',
          description: '',
        },
        {
          id: 'vaccination.previous.what',
          warning:
            "contains(get('answers.vaccination.previous.what'), 'other')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken vaccination',
          description: '',
          options: [
            {
              value: 'TBEDose1',
              label: 'TBE dos 1',
            },
            {
              value: 'TBEDose2',
              label: 'TBE dos 2',
            },
            {
              value: 'other',
              label: 'Annan vaccination',
            },
          ],
        },
        {
          id: 'vaccination.previous.comment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Nyligen vaccinerats kommentar ssk',
          description: '',
        },
        {
          id: 'vaccination.seriousReaction.match',
          warning: "get('answers.vaccination.seriousReaction.match') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kraftig reaktion på tidigare vaccination',
          description: '',
        },
        {
          id: 'vaccination.seriousReaction.comment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kraftig reaktion på tidigare vaccination kommentar ssk',
          description: '',
        },
        {
          id: 'allergy.egg',
          warning: "get('answers.allergy.egg') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Allergi mot ägg',
          description: '',
        },
        {
          id: 'allergy.eggComment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Äggallergi kommentar ssk',
          description: '',
        },
        {
          id: 'allergy.penicillinMatch',
          warning:
            "get('answers.allergy.penicillinMatch') === 'yesKnows' || get('answers.allergy.penicillinMatch') === 'yesDontKnow'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Allergi mot penicillin/antibiotika',
          description: '',
          options: [
            {
              value: 'yesKnows',
              label:
                'Ja, och jag vet vilken sorts penicillin eller antibiotika jag är allergisk mot.',
            },
            {
              value: 'yesDontKnow',
              label:
                'Ja, men jag vet inte vilken sorts penicillin eller antibiotika jag är allergisk mot.',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'dontKnow',
              label: 'Vet ej',
            },
          ],
        },
        {
          id: 'allergy.penicillinComment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Antibiotikaallergi kommentar ssk',
          description: '',
        },
        {
          id: 'health.issues.what',
          warning:
            "get('answers.health.issues.what') !== undefined && (length(get('answers.health.issues.what')) > 1 || !contains(get('answers.health.issues.what'), 'bloodpreassure'))",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare och/eller nuvarande sjukdomar',
          description: '',
          options: [
            {
              value: 'bloodpreassure',
              label: 'Högt blodtryck med tablettbehandling',
            },
            {
              value: 'cardiovascular',
              label: 'Hjärt-kärlsjukdom',
            },
            {
              value: 'diabetes',
              label: 'Diabetes',
            },
            {
              value: 'lungs',
              label: 'Lungsjukdom (inkl. astma)',
            },
            {
              value: 'cancer',
              label: 'Cancersjukdom',
            },
            {
              value: 'neuro',
              label: 'Neurologisk sjukdom',
            },
            {
              value: 'blooddisease',
              label: 'Blodsmitta (HIV, Hepatit B, Hepatit C)',
            },
            {
              value: 'kidney',
              label: 'Njurfunktionsnedsättning',
            },
            {
              value: 'liver',
              label: 'Leverfunktionsnedsättning',
            },
            {
              value: 'immuneSystem',
              label: 'Nedsatt immunförsvar',
            },
            {
              value: 'rheumatic',
              label: 'Reumatologisk sjukdom',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'health.issues.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken annan sjukdom',
          description: '',
        },
        {
          id: 'health.cardiovascular.what.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hjärt-kärlsjukdom',
          description: '',
          options: [
            {
              value: 'infarction',
              label: 'Hjärtinfarkt',
            },
            {
              value: 'congestiveHeart',
              label: 'Hjärtsvikt',
            },
            {
              value: 'anginaPectoris',
              label: 'Kärlkramp',
            },
            {
              value: 'atrialFibrillation',
              label: 'Förmaksflimmer',
            },
            {
              value: 'heartValve',
              label: 'Hjärtklaffsfel',
            },
            {
              value: 'stroke',
              label: 'Stroke',
            },
            {
              value: 'bloodClot',
              label: 'Blodpropp som krävt blodförtunnande medicinering',
            },
            {
              value: 'other',
              label: 'Annan hjärt-kärlsjukdom',
            },
          ],
        },
        {
          id: 'health.cardiovascular.what.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken annan hjärt-kärlsjukdom',
          description: '',
        },
        {
          id: 'health.diabetes.type',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Typ av diabetes',
          description: '',
          options: [
            {
              value: 'type1',
              label: 'Diabetes typ 1',
            },
            {
              value: 'type2',
              label: 'Diabetes typ 2',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'health.diabetes.typeOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken annan typ av diabetes',
          description: '',
        },
        {
          id: 'health.diabetes.treatment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diabetes behandlas med',
          description: '',
          options: [
            {
              value: 'food',
              label: 'Kostbehandling',
            },
            {
              value: 'pills',
              label: 'Tablettbehandling',
            },
            {
              value: 'insuline',
              label: 'Insulinbehandling',
            },
          ],
        },
        {
          id: 'health.lungDiseases.what.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Lungsjukdom',
          description: '',
          options: [
            {
              value: 'astma',
              label: 'Astma',
            },
            {
              value: 'kol',
              label: 'KOL',
            },
            {
              value: 'other',
              label: 'Annan lungsjukdom',
            },
          ],
        },
        {
          id: 'health.lungDiseases.what.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken annan lungsjukdom',
          description: '',
        },
        {
          id: 'health.cancerWhat',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Cancersjukdom',
          description: '',
        },
        {
          id: 'health.neurological.what.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Neurologisk sjukdom',
          description: '',
          options: [
            {
              value: 'epilepsy',
              label: 'Epilepsi',
            },
            {
              value: 'ms',
              label: 'MS',
            },
            {
              value: 'parkinson',
              label: 'Parkinson',
            },
            {
              value: 'other',
              label: 'Annan neurologisk sjukdom',
            },
          ],
        },
        {
          id: 'health.neurological.what.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Annan neurologisk sjukdom',
          description: '',
        },
        {
          id: 'health.bloodDisease.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Blodsmitta',
          description: '',
          options: [
            {
              value: 'hepB',
              label: 'Hepatit B',
            },
            {
              value: 'hepC',
              label: 'Hepatit C',
            },
            {
              value: 'hiv',
              label: 'HIV',
            },
          ],
        },
        {
          id: 'health.immuneSystem.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Nedsatt immunförsvar',
          description: '',
          options: [
            {
              value: 'noSpleen',
              label: 'Jag har ingen mjälte.',
            },
            {
              value: 'immuneDeficiencyDisease',
              label: 'Jag har en diagnosticerad immunbristsjukdom.',
            },
            {
              value: 'medicineImmunesystem',
              label: 'Jag tar läkemedel som försämrar immunförsvaret.',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'health.immuneSystem.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Anledning nedsatt immunförsvar',
          description: '',
        },
        {
          id: 'health.issues.comment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare och/eller nuvarande sjukdomar kommentar ssk',
          description: '',
        },
        {
          id: 'medication.vaccination.what',
          warning:
            "get('answers.medication.vaccination.what') !== undefined && (length(get('answers.medication.vaccination.what')) > 1 || !contains(get('answers.medication.vaccination.what'), 'other'))",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tar läkemedel för tillfället',
          description: '',
          options: [
            {
              value: 'kortison',
              label: 'Kortison',
            },
            {
              value: 'waranWafarin',
              label: 'Waran/Warfarin',
            },
            {
              value: 'otherBloodThinning',
              label: 'Annan blodförtunnande behandling',
            },
            {
              value: 'immuneAffecting',
              label: 'Läkemedel som påverkar ditt immunförsvar',
            },
            {
              value: 'other',
              label: 'Annat läkemedel',
            },
          ],
        },
        {
          id: 'medication.vaccination.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilka läkemedel',
          description: '',
        },
        {
          id: 'medication.vaccination.comment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel kommentar ssk',
          description: '',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
          description: '',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
          description: '',
        },
        {
          id: 'reproduction.pregnantComment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid/ammar kommentar ssk',
          description: '',
        },
        {
          id: 'health.feverColdIllness',
          warning: "get('answers.health.feverColdIllness') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Infektionssymptom',
          description: '',
        },
        {
          id: 'health.feverColdIllnessComment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Pågående sjukdom kommentar ssk',
          description: '',
        },
        {
          id: 'otherChoice',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
        {
          id: 'vaccination.smsReminder.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'SMS-påminnelse',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
    prescriptionwarnings: {
      children: [
        {
          id: 'reproduction.pregnant',
          condition: "get('answers.reproduction.pregnant') === 'yes'",
          warning: 'true',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
        },
        {
          id: 'reproduction.breastfeeding',
          condition: "get('answers.reproduction.breastfeeding') === 'yes'",
          warning: 'true',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
        },
        {
          id: 'prescriptionwarnings.liverFunctions',
          type: 'text',
          condition: "contains(get('answers.health.issues.what'), 'liver')",
          warning: 'true',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Nedsatt leverfunktion',
          description: '',
          template: "'Ja'",
        },
        {
          id: 'prescriptionwarnings.kidneyFunctions',
          type: 'text',
          condition: "contains(get('answers.health.issues.what'), 'kidney')",
          warning: 'true',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Nedsatt njurfunktion',
          description: '',
          template: "'Ja'",
        },
        {
          id: 'allergy.penicillinMatch',
          condition:
            "any(get('answers.allergy.penicillinMatch'), 'yesKnows', 'yesDontKnow')",
          warning: 'true',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har penicillin/antibiotikaallergi',
          description: '',
          options: [
            {
              value: 'yesKnows',
              label:
                'Ja, och jag vet vilken sorts penicillin eller antibiotika jag är allergisk mot.',
            },
            {
              value: 'yesDontKnow',
              label:
                'Ja, men jag vet inte vilken sorts penicillin eller antibiotika jag är allergisk mot.',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'dontKnow',
              label: 'Vet ej',
            },
          ],
        },
        {
          id: 'allergy.egg',
          condition: "get('answers.allergy.egg') === 'yes'",
          warning: 'true',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Allergi mot ägg',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
    decisionSupport: {
      children: [
        {
          id: 'previousVaccination',
          type: 'comment',
          condition:
            "contains(get('answers.vaccination.previous.what'), 'other')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Nyligen vaccinerad',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs då patienten svarat att hen nyligen har fått en vaccination.',
        },
        {
          id: 'seriousReaction',
          type: 'comment',
          condition:
            "get('answers.vaccination.seriousReaction.match') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Kraftig reaktion på vaccination tidigare',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs då patienten svarat att hen reagerat kraftigt på tidigare vaccinationer.',
        },
        {
          id: 'eggAllergy',
          type: 'comment',
          condition: "get('answers.allergy.egg') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Äggallergi',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs då patienten svarat att hen har äggallergi.',
        },
        {
          id: 'penicillinAllergy',
          type: 'comment',
          condition:
            "any(get('answers.allergy.penicillinMatch'), 'yesKnows', 'yesDontKnow')",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Antibiotikaallergi',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs då patienten svarat att hen har antibiotikaallergi.',
        },
        {
          id: 'otherDisease',
          type: 'comment',
          condition:
            "get('answers.health.issues.what') !== undefined && (length(get('answers.health.issues.what')) > 1 || !contains(get('answers.health.issues.what'), 'bloodpreassure'))",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Sjukdom/Hälsoproblem',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs då patienten svarat att hen har eller har haft en sjukdom eller ett hälsoproblem.',
        },
        {
          id: 'pregnantOrBreastfeeding',
          type: 'comment',
          condition:
            "get('answers.reproduction.pregnant') === 'yes' || get('answers.reproduction.breastfeeding') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Gravid/Ammar',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs. Slutsatsen bygger på att patienten svarat att hon är gravid eller ammar.',
        },
        {
          id: 'feverColdIllness',
          type: 'comment',
          condition: "get('answers.health.feverColdIllness') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Förkylning/Feber/Pågående sjukdom',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs då patienten svarat att hen har pågående sjukdom.',
        },
        {
          id: 'medication',
          type: 'comment',
          condition:
            "get('answers.medication.vaccination.what') !== undefined && (length(get('answers.medication.vaccination.what')) > 1 || !contains(get('answers.medication.vaccination.what'), 'other'))",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Läkemedel',
          description:
            'Ställningstagande till vaccination och fördjupad anamnes krävs. Slutsatsen bygger på att patienten svarat att hen står på läkemedel som kan kontraindicera vaccination.',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Vaccination',
  index: '0',
};

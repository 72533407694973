// @flow

// Helper function that adds clinicLabel and significant support reduce manual checks.

import type { Ask, RawAnswer, Limit } from '../../types';

export type ExtendedTextQuestion = {
  id?: string,
  label?: string,
  description?: string,
  optional?: boolean,
  expires?: number,
  min?: number | Limit,
  max?: number | Limit,
  unit?: string,
  clinicLabel?: string,
  hide?: boolean,
  warn?: boolean,
};

type ExtendedText = {
  ask: Ask,
  significant: RawAnswer[],
  question: ExtendedTextQuestion,
};

const extendedText = async ({ ask, significant, question }: ExtendedText) => {
  const {
    id,
    label,
    description,
    optional,
    expires,
    min,
    max,
    unit,
    clinicLabel,
    hide,
    warn,
  } = question;

  const rawQuestion = {
    id,
    type: 'text',
    label,
    description,
    optional,
    expires,
    min,
    max,
    unit,
  };
  const answer = await ask(rawQuestion);

  if (!hide === true) {
    significant.push({
      type: 'text',
      label: clinicLabel !== undefined ? clinicLabel : question.label,
      value: answer,
      warn,
    });
  }

  return answer;
};

export default ({
  ask,
  significant,
}: {
  ask: Ask,
  significant: RawAnswer[],
}) => (question: ExtendedTextQuestion) =>
  extendedText({ ask, significant, question });

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  /* ISI - Insomnia Severity Index */

  const fallAsleep = await ask.choice({
    id: 'isi.fallAsleep',
    label:
      'Skatta hur svår din sömnlöshet är just nu dvs. senaste 2 veckorna. Svårigheter att somna in:',
    options: [
      { value: '0', label: 'Inga' },
      { value: '1', label: 'Lindriga' },
      { value: '2', label: 'Måttliga' },
      { value: '3', label: 'Allvarliga' },
      { value: '4', label: 'Mycket allvarliga' },
    ],
  });

  const wakeUpDuringNight = await ask.choice({
    id: 'isi.wakeUpDuringNight',
    label:
      'Skatta hur allvarliga dina nuvarande (dvs. senaste 2 veckorna) sömnproblem är. Problem med att vakna under natten:',
    options: [
      { value: '0', label: 'Inga' },
      { value: '1', label: 'Lindriga' },
      { value: '2', label: 'Måttliga' },
      { value: '3', label: 'Allvarliga' },
      { value: '4', label: 'Mycket allvarliga' },
    ],
  });

  const wakeUpEarly = await ask.choice({
    id: 'isi.wakeUpEarly',
    label:
      'Skatta hur allvarliga dina nuvarande (dvs. senaste 2 veckorna) sömnproblem är. Problem med att vakna för tidigt:',
    options: [
      { value: '0', label: 'Inga' },
      { value: '1', label: 'Lindriga' },
      { value: '2', label: 'Måttliga' },
      { value: '3', label: 'Allvarliga' },
      { value: '4', label: 'Mycket allvarliga' },
    ],
  });

  const satisfactionSleep = await ask.choice({
    id: 'isi.satisfactionSleep',
    label: 'Hur nöjd/missnöjd är du med ditt nuvarande sömnmönster?',
    options: [
      { value: '0', label: 'Mycket nöjd' },
      { value: '1', label: 'Nöjd' },
      { value: '2', label: 'Neutral' },
      { value: '3', label: 'Missnöjd' },
      { value: '4', label: 'Mycket missnöjd' },
    ],
  });

  const impactOnDailyLife = await ask.choice({
    id: 'isi.impactOnDailyLife',
    label:
      'I vilken omfattning anser du att dina sömnproblem stör hur du fungerar dagtid (t.ex. trött på dagen, hur du klarar arbetet/dagliga sysslor, koncentration, minne, sinnesstämning, osv.).',
    options: [
      { value: '0', label: 'Stör inte alls' },
      { value: '1', label: 'Litet' },
      { value: '2', label: 'En del' },
      { value: '3', label: 'Mycket' },
      { value: '4', label: 'Extremt störande' },
    ],
  });

  const impactOnQualityOfLife = await ask.choice({
    id: 'isi.impactOnQualityOfLife',
    label:
      'Hur märkbara för andra tror du att dina sömnproblem är i termer av att de försämrar din livskvalitet?',
    options: [
      { value: '0', label: 'Märks inte alls' },
      { value: '1', label: 'Knappt märkbara' },
      { value: '2', label: 'En del' },
      { value: '3', label: 'Mycket' },
      { value: '4', label: 'Extremt märkbara' },
    ],
  });

  const disturbed = await ask.choice({
    id: 'isi.disturbed',
    label: 'Hur orolig/störd är du över dina nuvarande sömnproblem?',
    options: [
      { value: '0', label: 'Inte alls' },
      { value: '1', label: 'Litet' },
      { value: '2', label: 'En del' },
      { value: '3', label: 'Mycket' },
      { value: '4', label: 'Väldigt mycket' },
    ],
  });

  // Put all answers into an array
  const isiArray = [
    fallAsleep,
    wakeUpDuringNight,
    wakeUpEarly,
    satisfactionSleep,
    impactOnDailyLife,
    impactOnQualityOfLife,
    disturbed,
  ];

  // Transform array values from string to integer values and then calculate the sum
  // of all values in array, stored in the variable isiScore
  const isiScore = isiArray
    .map(value => parseInt(value))
    .reduce((total, value) => total + value);

  // Depending on score, push different information to significant (Clinic view)
  if (isiScore < 8) {
    significant.push({
      type: 'text',
      label: 'ISI score',
      value: isiScore + ' - sannolikt inga sömnbesvär',
    });
  } else if (isiScore < 15) {
    significant.push({
      type: 'text',
      label: 'ISI score',
      value: isiScore + ' - vissa sömnbesvär',
    });
  } else if (isiScore < 22) {
    significant.push({
      type: 'text',
      label: 'ISI score',
      value: isiScore + ' - medelsvåra sömnbesvär (klinisk signifikant)',
    });
  } else {
    significant.push({
      type: 'text',
      label: 'ISI score',
      value: isiScore + ' - svåra sömnbesvär (klinisk signifikant)',
    });
  }

  return {};
}: Guide);

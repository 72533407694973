import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const lowIntensityExercixe = {
    type: 'choice',
    id: 'healthCheck.exercise.lowIntensityExercixe',
    label:
      'Hur många dagar per vecka är du fysiskt aktiv med måttlig intensitet (motsvarande promenad, cykling, trädgårdsarbete) under sammanlagt 30 min eller mer?',
    options: [
      { value: 'lessThanOnceAWeek', label: 'Mindre än 1 dag/vecka' },
      { value: 'oneToTwoDaysAWeek', label: '1–2 dagar/vecka' },
      { value: 'threeToFourDaysAWeek', label: '3–4 dagar/vecka' },
      { value: 'fiveToSevenDaysAWeek', label: '5–7 dagar/vecka' },
    ],
  };

  const lowIntensityExercixeAnswer = await ask(lowIntensityExercixe);

  significant.push({
    ...lowIntensityExercixe,
    label: 'Fysiskt aktiv med måttlig intensitet',
    value: lowIntensityExercixeAnswer,
  });

  const highIntensityExercixe = {
    type: 'choice',
    id: 'healthCheck.exercise.highIntensityExercixe',
    label:
      'Hur många gånger per vecka är du fysiskt aktiv med hög intensitet (motsvarande löpning, gympapass) under 20–60 min per tillfälle?',
    options: [
      { value: 'lessThanOnceAWeek', label: 'Mindre än 1 dag/vecka' },
      { value: 'oneToTwoDaysAWeek', label: '1–2 dagar/vecka' },
      { value: 'threeToFourDaysAWeek', label: '3–4 dagar/vecka' },
      { value: 'fiveToSevenDaysAWeek', label: '5–7 dagar/vecka' },
    ],
  };

  const highIntensityExercixeAnswer = await ask(highIntensityExercixe);

  significant.push({
    ...highIntensityExercixe,
    label: 'Fysiskt aktiv med hög intensitet',
    value: highIntensityExercixeAnswer,
  });

  const satisfactionExercise = {
    type: 'choice',
    id: 'healthCheck.exercise.satisfactionExercise',
    label: 'Hur nöjd är du med dina motionsvanor i allmänhet?',
    options: [
      { value: 'notSatisfied', label: 'Inte nöjd / vill förändra' },
      { value: 'quiteSatisfied', label: 'Ganska nöjd' },
      { value: 'verySatisfied', label: 'Mycket nöjd' },
    ],
  };

  const satisfactionExerciseAnswer = await ask(satisfactionExercise);

  significant.push({
    ...satisfactionExercise,
    label: 'Nöjd med motionsvanor',
    value: satisfactionExerciseAnswer,
  });

  return {};
}: Guide);

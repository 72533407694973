import type { Guide } from '../../types';

import healthProfile from '../partials/healthProfile';

import informedConsent from '../questions/informedConsent';

import aborts from './aborts';
import ds from './decisionSupport';

import triage from './triage';

export default (async args => {
  const { ask, decisionSupport, significant } = args;

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) return { abort: triageAbort };

  await ask.info({
    description: 'We are now going to ask about your sore throat...',
  });

  let feverPainScore = 0;
  let rejectReasons = [];

  const durationQuestion = {
    id: 'duration',
    type: 'choice',
    options: {
      '0To3Days': '0 - 3 days',
      '4To7Days': '4 - 7 days',
      moreThan7Days: 'More than 7 days',
    },
  };
  const duration = await ask({
    ...durationQuestion,
    label: 'How long have you had a sore throat?',
  });
  significant.push({
    ...durationQuestion,
    label: 'Duration',
    value: duration,
    warn: 'moreThan7Days',
  });
  if (duration === '0To3Days') {
    feverPainScore += 1;
  }
  if (duration === 'moreThan7Days') {
    significant.push({
      type: 'text',
      label: 'How long has it been',
      value: await ask.text('Please tell us how long it has been.'),
    });
  }
  let improving;
  if (['4To7Days', 'moreThan7Days'].includes(duration)) {
    const improvingQuestion = {
      type: 'choice',
      options: {
        yes: `Yes, It's getting better`,
        same: `No, It's staying the same`,
        worse: `No, It's getting worse`,
      },
    };
    improving = await ask({
      ...improvingQuestion,
      label: 'Is your sore throat getting better?',
    });
    significant.push({
      ...improvingQuestion,
      label: 'Sore throat improving',
      value: improving,
      warn: ['yes', 'worse'],
    });
  }

  const swollenTonsilsQuestion = {
    type: 'choice',
    options: {
      yes: 'Yes',
      no: 'No',
      dontHaveTonsils: `I don't have tonsils`,
    },
  };
  const swollenTonsils = await ask({
    ...swollenTonsilsQuestion,
    label: 'Are your tonsils red and swollen?',
  });
  significant.push({
    ...swollenTonsilsQuestion,
    label: 'Red and swollen tonsils',
    value: swollenTonsils,
    warn: 'yes',
  });
  if (swollenTonsils === 'yes') {
    feverPainScore += 1;
  }

  const spotsTonsils = await ask(
    'Have you got white / yellow spots on your tonsils or at the back of your throat?'
  );
  if (spotsTonsils === 'yes') {
    significant.push({
      label: 'Exudate on tonsils / throat',
      value: spotsTonsils,
      warn: true,
    });
    feverPainScore += 1;
  }

  const unilateralPain = await ask(
    'Does it hurt on just one side of your throat?'
  );
  if (unilateralPain === 'yes') {
    significant.push({
      label: 'Unilateral pain',
      value: unilateralPain,
    });
    decisionSupport.push(ds.unilateralPain);
  }

  const drinkOrSwallowQuestion = {
    type: 'choice',
    options: {
      yes: 'Yes',
      yesHurts: 'Yes, but it hurts',
      no: 'No - I cannot drink water or swallow saliva at all',
    },
  };
  const drinkOrSwallow = await ask({
    ...drinkOrSwallowQuestion,
    label: 'Are you able to drink water or swallow your saliva?',
  });
  if (drinkOrSwallow === 'no') {
    significant.push({
      ...drinkOrSwallowQuestion,
      label: 'Able to swallow liquid or saliva',
      value: drinkOrSwallow,
      warn: true,
    });
    return { abort: aborts.unableToSwallow };
  }

  const eatSoftFoodsQuestion = {
    type: 'choice',
    options: {
      yes: 'Yes',
      yesHurts: 'Yes, but it hurts',
      no: 'No',
    },
  };
  const eatSoftFoods = await ask({
    ...eatSoftFoodsQuestion,
    label: 'Are you able to eat soft foods?',
  });
  if (eatSoftFoods === 'no') {
    significant.push({
      ...eatSoftFoodsQuestion,
      label: 'Able to eat soft foods',
      value: eatSoftFoods,
    });
    rejectReasons.push('Unable to swallow soft foods');
  }

  const breatheNormallyQuestion = {
    type: 'choice',
    label: 'Can you breathe normally through your mouth?',
    options: {
      yes: 'Yes - I can breathe normally',
      yesNoisy: 'Yes - but my breathing is a little noisy',
      noNoisyHarder:
        'No - my breathing is noisy and it is harder than normal to breathe',
      noStruggling: 'No - I am struggling to breathe',
    },
  };
  const breatheNormally = await ask(breatheNormallyQuestion);
  if (breatheNormally !== 'yes') {
    significant.push({
      ...breatheNormallyQuestion,
      value: breatheNormally,
      warn: true,
    });
    if (breatheNormally === 'yesNoisy') {
      rejectReasons.push('Breathing problem');
    }
    if (['noNoisyHarder', 'noStruggling'].includes(breatheNormally)) {
      return { abort: aborts.airwayObstruction };
    }
  }

  const speakNormallyQuestion = {
    type: 'choice',
    options: {
      yes: 'Yes - I can speak normally',
      no: 'No - I am out of breath when speaking',
    },
  };
  const speakNormally = await ask({
    ...speakNormallyQuestion,
    label:
      'When you are speaking, can you get to the end of a short sentance without taking a breath? ',
  });
  significant.push({
    ...speakNormallyQuestion,
    label: 'Complete sentence in one breath',
    value: speakNormally,
    warn: 'no',
  });
  if (speakNormally === 'no') {
    return { abort: aborts.airwayObstruction };
  }

  const voiceChanged = await ask('Has your voice changed?');
  if (voiceChanged === 'yes') {
    const voiceSoundLikeQuestion = {
      type: 'choice',
      options: {
        nasal: 'Nasal, like I have a cold',
        muffled: 'Muffled or unclear, like I have a hot potato in my mouth',
        hoarse: 'Hoarse Croaky',
        lost: 'I have lost my voice',
      },
    };
    const voiceSoundLike = await ask({
      ...voiceSoundLikeQuestion,
      label: 'What does your voice sound like?',
    });
    if (voiceSoundLike === 'muffled') {
      significant.push({
        ...voiceSoundLikeQuestion,
        label: 'Voice sounds like',
        value: voiceSoundLike,
        warn: true,
      });
      rejectReasons.push('?Peritonsillar abscess - hot potato voice');
    }
  }

  const commonSymptoms = await ask(
    'Do you have any other common cold symptoms, like a cough or runny / blocked nose?'
  );
  if (commonSymptoms === 'no') {
    significant.push({
      label: 'Cold / cough',
      value: commonSymptoms,
      warn: true,
    });
    feverPainScore += 1;
  }

  const fever = await ask.tertiary(
    'Have you had a fever in the past 24 hours?'
  );
  significant.push({
    type: 'tertiary',
    label: 'Temperature in past 24 hours',
    value: fever,
    warn: 'yes',
  });
  if (fever === 'yes') {
    feverPainScore += 1;
  }

  const experiencedQuestion = {
    type: 'multipleChoice',
    options: {
      swollenTenderGlands: 'Swollen or tender glands in armpit or groin',
      newRash: 'New Rash (one that does not fade when pressed)',
      abdominalPain: 'Abdominal / tummy pain',
      diarrheaVomiting: 'Diarrhea or vomiting',
    },
    optional: true,
  };
  const experienced = await ask({
    ...experiencedQuestion,
    label:
      'Since your sore throat started, have you experienced any of the following?',
    description: 'More than one answer can be selected.',
  });
  if (experienced.length > 0) {
    significant.push({
      ...experiencedQuestion,
      label: 'Symptoms, other than sore throat',
      value: experienced,
      warn: true,
    });
    if (experienced.includes('swollenTenderGlands')) {
      rejectReasons.push('?Glandular fever');
    }
    if (experienced.includes('newRash')) {
      rejectReasons.push('?Sepsis / Glandular Fever - Rash');
      return { abort: aborts.sepsis };
    }
    if (experienced.includes('abdominalPain')) {
      rejectReasons.push('?Sepsis / Glandular Fever - Abdo Pain');
    }
    if (experienced.includes('diarrheaVomiting')) {
      rejectReasons.push('?Sepsis - D&V');
    }
  }

  const faintedQuestion = {
    type: 'choice',
    label: 'In the past 24 hours, have you fainted, or almost fainted?',
    options: {
      yesFeelingFaint: 'Feeling faint',
      yesFainted: 'Fainted / Lost consciousness',
      no: 'No',
    },
  };
  const fainted = await ask(faintedQuestion);
  if (['yesFeelingFaint', 'yesFainted'].includes(fainted)) {
    significant.push({
      ...faintedQuestion,
      value: fainted,
      warn: true,
    });
    if (fainted === 'yesFainted') {
      return { abort: aborts.emergency };
    }
  }

  const severeMusclePain = await ask(
    'Do you have severe all-over muscle pain which is new?'
  );
  if (severeMusclePain) {
    significant.push({
      label: 'Severe muscle pain',
      value: severeMusclePain,
    });
    const severeMusclePainDescriptionQuestion = {
      type: 'choice',
      options: {
        achy: 'I feel achy, but can manage',
        severe:
          'I have severe muscle pain, and am struggling with day to day activities',
        other: 'Other',
      },
    };
    const severeMusclePainDescription = await ask({
      ...severeMusclePainDescriptionQuestion,
      label: 'Please describe your pain.',
    });
    significant.push({
      ...severeMusclePainDescriptionQuestion,
      label: 'Severe muscle pain, description',
      value: severeMusclePainDescription,
      warn: 'severe',
    });
    if (severeMusclePainDescription === 'severe') {
      rejectReasons.push('?Sepsis - Severe muscle pain');
    }
    if (severeMusclePainDescription === 'other') {
      significant.push({
        type: 'text',
        label: 'Severe muscle pain, description',
        value: await ask.text('Please describe your pain.'),
        warn: true,
      });
    }
  }

  const earache = await ask(
    'Have you got severe earache (not just when you swallow)?'
  );
  if (earache === 'yes') {
    significant.push({
      label: 'Severe earache (not just when swallowing)',
      value: earache,
    });
    const earacheDescriptionQuestion = {
      type: 'choice',
      options: {
        swallow: 'It only hurts when I swallow',
        pressure: 'Pressure in my ear',
        blocked: 'My ears feel blocked',
        ache: 'Dull ache',
        severe: 'Severe, sharp or and constant',
        other: 'Other',
      },
    };
    const earacheDescription = await ask({
      ...earacheDescriptionQuestion,
      label: 'Please describe your ear pain.',
    });
    significant.push({
      ...earacheDescriptionQuestion,
      label: 'Severe earache, description',
      value: earacheDescription,
      warn: 'severe',
    });
    if (earacheDescription === 'severe') {
      rejectReasons.push('?Otitis Media');
    }
    if (earacheDescription === 'other') {
      significant.push({
        type: 'text',
        label: 'Severe earache, description',
        value: await ask.text('Please describe your ear pain.'),
        warn: true,
      });
    }
  }

  const antibiotics = await ask(
    'Have you needed antibiotics for a sore throat or tonsillitis in the past month?'
  );
  if (antibiotics === 'yes') {
    significant.push({
      label:
        'Used antibiotics for sore throat or tonsillitis in the past month',
      value: antibiotics,
    });
  }

  const amountQuestion = {
    type: 'choice',
    options: {
      none: 'None',
      '1To3': '1 to 3',
      '4OrMore': '4 or more',
      always: 'My throat is always sore',
    },
  };
  const amount = await ask({
    ...amountQuestion,
    label: 'How many sore throats have you had in the past 12 months?',
  });
  if (['4OrMore', 'always'].includes(amount)) {
    significant.push({
      ...amountQuestion,
      label: 'Sore throats in the past year',
      value: amount,
      warn: true,
    });
    if (amount === '4OrMore') {
      rejectReasons.push('Recurrent sore throat - Why? Tonsillectomy needed? ');
    }
    if (amount === 'always') {
      rejectReasons.push('Persistent sore throat - Why? Malignancy?');
    }
  }

  const photos = await ask.upload(
    `We'd like you to upload a photo of your sore throat to help your doctor with their diagnosis.`,
    {
      mimeTypes: 'image/*,video/*',
      description: `
You can use a mobile phone camera to take the photo — you can ask someone to take the photo for you, or you can do it yourself by activating the selfie-mode and turning your phone upside down.

For best results we need to clearly see your throat, so try not to lift your tongue too much — saying ‘aaar’ when taking the photo is a great way of achieving this.

Rest assured, your photos will be kept confidential and will only be added to your medical record.
    `,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Photos',
    value: photos,
  });

  await healthProfile(args);

  await ask(informedConsent);

  if (rejectReasons.length > 0) {
    decisionSupport.push({
      ...ds.reject,
      description: rejectReasons.map(reason => `- ${reason}`).join('\n'),
    });
  }

  if (feverPainScore < 4) {
    if (duration === '7OrMore') {
      decisionSupport.push(ds.persistent);
    }

    if (feverPainScore < 2) {
      decisionSupport.push(ds.feverPainScoreLow);
    } else {
      decisionSupport.push(ds.feverPainScoreMedium);
    }
  } else {
    if (improving === 'yes') {
      decisionSupport.push(ds.improving);
    }

    decisionSupport.push(ds.feverPainScoreHigh);
  }

  return {};
}: Guide);

import type { GuideWithOut, RawAnswer } from '../../types';

const alcoholDescription = `
- Small glass of wine = 2 units
- Large glass of wine = 3 units
- Pint beer or cider = 2 units
- Pint of strong beer or cider = 3 units
- Shot of spirit = 1 unit
`;

export default (async args => {
  const {
    ask,
    patient: { gender },
    decisionSupport,
  } = args;

  const hpSignificant: RawAnswer[] = [];

  const heightUnit = await ask.choice('How tall are you?', {
    id: 'height.unit',
    description: 'Please choose either cm or feet',
    options: {
      metric: 'Centimeters',
      imperial: 'Feet and inches',
    },
  });

  let heightMetric = 0;

  if (heightUnit === 'metric') {
    heightMetric =
      (await ask.number('How tall are you?', { id: 'height', unit: 'cm' })) ||
      0;
  } else {
    const heightFeet =
      (await ask.number('How tall are you?', {
        description: `It's essential that we receive the correct information regarding your height in feet and inches. For this reason, we ask you to enter your height over two screens. So if, for example, you are 5 foot 10 inches in height, you enter 5 on the first screen and 10 on the second screen.`,
        unit: 'feet',
      })) || 0;
    const heightInches =
      (await ask.number('How tall are you?', {
        unit: 'inches',
      })) || 0;

    heightMetric = 30.48 * heightFeet + 2.54 * heightInches;
  }

  const weightUnit = await ask.choice('How much do you weigh?', {
    id: 'weight.unit',
    description: 'Please choose either kg or stones',
    options: {
      metric: 'Kilograms',
      imperial: 'Stones and pounds',
    },
  });

  let weightMetric = 0;

  if (weightUnit === 'metric') {
    weightMetric =
      (await ask.number('How much do you weigh?', {
        id: 'weight',
        unit: 'kg',
      })) || 0;
  } else {
    const weightStones =
      (await ask.number('How much do you weigh?', {
        description: `It's essential that we receive the correct information regarding your weight in stones and pounds. For this reason, we ask you to enter your weight over two screens. So if, for example, you weigh 10 stone 5 pounds, you enter 10 on the first screen and 5 on the second screen.`,
        unit: 'stones',
      })) || 0;
    const weightPounds =
      (await ask.number('How much do you weigh?', {
        unit: 'pounds',
      })) || 0;

    weightMetric = 6.35029 * weightStones + 0.453592142840941 * weightPounds;
  }

  const bmi = (weightMetric * 10000) / (heightMetric * heightMetric);

  hpSignificant.push({
    type: 'number',
    label: 'BMI',
    value: Math.round(bmi * 100) / 100,
  });

  if (bmi <= 17.5) {
    decisionSupport.push({
      label: 'Query Low BMI',
      description: 'BMI ≤ 17.5',
      color: 'black',
    });
  }

  const smokingQuestion = {
    type: 'choice',
    options: {
      lessThan10: 'Yes - less than 10 times a day',
      moreThan10: 'Yes - more than 10 times a day',
      sometimes: 'Sometimes',
      previously: 'Ex-smoker',
      no: 'No',
    },
  };
  const smoking = await ask({
    ...smokingQuestion,
    label: 'Do you smoke?',
    description: 'E.g. cigarettes, roll-ups, vaping.',
  });
  if (['lessThan10', 'moreThan10', 'sometimes'].includes(smoking)) {
    hpSignificant.push({
      ...smokingQuestion,
      label: 'Smoking, history',
      value: smoking,
      warn: true,
    });
  }
  let smokingPreviously;
  if (smoking === 'previously') {
    const smokingPreviouslyQuestion = {
      type: 'choice',
      options: {
        lessThanYear: 'Less than a year ago',
        yearOrMore: 'A year ago or more',
      },
    };
    smokingPreviously = await ask({
      ...smokingPreviouslyQuestion,
      label: 'When did you stop smoking?',
    });
    if (smokingPreviously === 'lessThanYear') {
      hpSignificant.push({
        ...smokingPreviouslyQuestion,
        label: 'Ex-Smoker, stop date',
        value: smokingPreviously,
        warn: true,
      });
    }
  }

  const smoker =
    ['lessThan10', 'moreThan10', 'sometimes'].includes(smoking) ||
    smokingPreviously === 'lessThanYear';

  const alcoholQuestion = {
    type: 'choice',
    options: {
      moreThan14: 'More than 14 units a week',
      '14OrLess': '14 units or less a week',
      none: "I don't drink alcohol",
    },
  };
  const alcohol = await ask({
    ...alcoholQuestion,
    label: 'How many alcohol units do you drink in a week?',
    description: alcoholDescription,
  });
  if (alcohol === 'moreThan14') {
    hpSignificant.push({
      ...alcoholQuestion,
      label: 'Alcohol',
      value: alcohol,
    });
    hpSignificant.push({
      type: 'text',
      label: 'Alcohol units / week',
      value: await ask.text('Please tell us how many units you drink a week.', {
        description: alcoholDescription,
      }),
      warn: true,
    });
  }

  const issuesImportant = [
    'diabetes',
    'bloodpressure',
    'cardiovascular',
    'strokeOrTia',
    'clotsLegsOrLung',
    'cancer',
    'epilepsy',
    'kidney',
    'liver',
    'blooddisease',
    'other',
  ];
  const issuesQuestion = {
    type: 'multipleChoice',
    options: {
      asthma: 'Asthma',
      copdOrSmokersLungs: 'COPD or "smoker\'s lungs"',
      diabetes: 'Diabetes',
      bloodpressure: 'High blood pressure',
      cardiovascular: 'Heart disease',
      strokeOrTia: 'Stroke or TIA (mini stroke)',
      clotsLegsOrLung: 'Blood clots to the legs or lung (DVT or PE)',
      cancer: 'Cancer',
      epilepsy: 'Epilepsy',
      kidney: 'Kidney disease',
      liver: 'Liver disease',
      blooddisease: 'Blood-borne virus (HIV, Hepatitis B, Hepatitis C)',
      other: 'Other medical condition',
    },
    optional: true,
  };
  const issues = await ask({
    ...issuesQuestion,
    label:
      'What diseases or health issues do you currently have, or have you had previously?',
    description: 'More than one answer can be selected.',
  });
  if (!!issues.filter(v => issuesImportant.includes(v)).length) {
    hpSignificant.push({
      ...issuesQuestion,
      label: 'Past medical history',
      value: issues,
      warn: true,
    });
  }
  if (issues.includes('cardiovascular')) {
    const whatQuestion = {
      type: 'multipleChoice',
      options: {
        infarction: 'Heart attack',
        congestiveHeart: 'Heart failure',
        anginaPectoris: 'Angina',
        atrialFibrillation: 'Atrial fibrillation',
        heartValve: 'Heart valve disorder',
        cardiomyopathy: 'Cardiomyopathy',
        other: 'Other',
      },
    };
    const what = await ask({
      ...whatQuestion,
      label: 'What kind of heart disease have you been diagnosed with?',
      description: 'More than one answer can be selected.',
    });
    hpSignificant.push({
      ...whatQuestion,
      label: 'Heart disease',
      value: what,
      warn: true,
    });
    if (what.includes('other')) {
      hpSignificant.push({
        type: 'text',
        label: 'Heart disease, other',
        value: await ask.text(
          'Please tell us what other heart disease you have been diagnosed with?'
        ),
        warn: true,
      });
    }
  }
  if (issues.includes('diabetes')) {
    const whatQuestion = {
      type: 'multipleChoice',
      options: [
        {
          value: 'type1',
          label: 'Diabetes type 1',
        },
        {
          value: 'type2',
          label: 'Diabetes type 2',
        },
        {
          value: 'preDiabetes',
          label: 'Pre-diabetes (diet controlled)',
        },
        gender === 'female'
          ? {
              value: 'diabetesInPregnancy',
              label: 'Diabetes in pregnancy (gestational)',
            }
          : undefined,
      ].filter(option => !!option),
    };
    const what = await ask({
      ...whatQuestion,
      label: 'What type of diabetes do you have?',
      description: 'More than one answer can be selected.',
    });
    hpSignificant.push({
      ...whatQuestion,
      label: 'Type of diabetes',
      value: what,
      warn: ['type1', 'type2'].includes(what),
    });
  }
  if (issues.includes('cancer')) {
    hpSignificant.push({
      type: 'text',
      label: 'Type of cancer',
      value: await ask.text(
        'What type of cancer do you currently have, or have you had previously?'
      ),
      warn: true,
    });
    const remission = await ask('Is your cancer in remission?', {
      description:
        'I.e. you have been given the all clear and no longer require any treatment.',
    });
    hpSignificant.push({
      label: 'Cancer in remission',
      value: remission,
      warn: remission === 'no',
    });
  }
  if (issues.includes('kidney')) {
    hpSignificant.push({
      type: 'text',
      label: 'Kidney disease',
      value: await ask.text(
        'What type of kidney disease do you currently have, or have you had previously?'
      ),
    });
    hpSignificant.push({
      type: 'text',
      label: 'Kidney disease, treatment',
      value: await ask.text('How has your kidney disease been treated?'),
      warn: true,
    });
  }
  if (issues.includes('liver')) {
    hpSignificant.push({
      type: 'text',
      label: 'Liver disease',
      value: await ask.text(
        'What type of liver disease do you currently have, or have you had previously?'
      ),
      warn: true,
    });
    hpSignificant.push({
      type: 'text',
      label: 'Liver disease, treatment',
      value: await ask.text('How has you liver disease been treated?'),
    });
  }
  if (issues.includes('blooddisease')) {
    const question = {
      type: 'multipleChoice',
      options: {
        hepB: 'Hepatitis B',
        hepC: 'Hepatitis C',
        hiv: 'HIV',
      },
    };
    hpSignificant.push({
      ...question,
      label: 'Blood-borne virus',
      value: await ask({
        ...question,
        label:
          'What blood-borne virus do you have, or have you had previously?',
        description: 'More than one answer can be selected.',
      }),
      warn: true,
    });
  }
  if (issues.includes('other')) {
    hpSignificant.push({
      type: 'text',
      label: 'Other medical condition',
      value: await ask.text(
        'Please list the other medical conditions you suffer from.'
      ),
      warn: true,
    });
  }
  const majorSurgery = await ask('Have you undergone any major surgery?');
  if (majorSurgery === 'yes') {
    hpSignificant.push({
      label: 'Major surgery',
      value: majorSurgery,
    });
    hpSignificant.push({
      type: 'text',
      label: 'Type of major surgery',
      value: await ask.text('What type of major surgery was it?'),
    });
  }
  const naturalRemedies = await ask(
    'Are you using any medications or natural remedies at the moment?',
    {
      description:
        gender === 'female'
          ? 'If you are using a hormonal contraceptive or HRT, this should be included.'
          : undefined,
    }
  );
  if (naturalRemedies === 'yes') {
    hpSignificant.push({
      type: 'text',
      label: 'Medications / Natural Remedies',
      value: await ask.text(
        'Please list all your medications / natural remedies below.',
        {
          description:
            'Include the name, strength and dose, and the reason you are taking it. E.g. Levothyroxine, 100 micrograms, 1 tablet once daily for an underactive thyroid.',
        }
      ),
    });
  } else {
    hpSignificant.push({
      label: 'Medications / Natural Remedies',
      value: naturalRemedies,
    });
  }
  const allergies = await ask('Do you have any allergies?', {
    description: 'E.g. Medication, food, animals, plants.',
  });
  hpSignificant.push({
    label: 'Allergies',
    value: allergies,
  });
  if (allergies === 'yes') {
    const whatQuestion = {
      type: 'multipleChoice',
      options: {
        penicillin: 'Penicillin',
        otherMedications: 'Other medications',
        lactose: 'Lactose',
        gluten: 'Gluten',
        nuts: 'Nuts',
        pollenHayfever: 'Pollen / Hayfever',
        animals: 'Animals',
        houseDust: 'House dust',
        other: 'Other',
      },
    };
    const what = await ask({
      ...whatQuestion,
      label: 'What are you allergic to?',
      description: 'More than one answer can be selected.',
    });
    hpSignificant.push({
      ...whatQuestion,
      label: 'Type of allergy',
      value: what,
    });
    if (what.includes('lactose')) {
      await ask.multipleChoice({
        description: `
Many medications contain a small amount of lactose, this is unlikely to cause you any problems unless your lactose allergy is severe.

# Do you accept responsibility for checking if any medications we prescribe contain lactose and the effects this may have?
        `,
        options: ['Yes, I accept'],
      });
    }
    if (what.includes('otherMedications')) {
      hpSignificant.push({
        type: 'text',
        label: 'Other medication allergies',
        value: await ask.text(
          'Please tell us what other medications you are allergic to.'
        ),
        warn: true,
      });
    }
    if (what.includes('other')) {
      hpSignificant.push({
        type: 'text',
        label: 'Other allergies',
        value: await ask.text('Please tell us what other allergies you have.'),
      });
    }
  }
  const other = await ask(
    'Is there anything else about your general state of health that the doctor should know?'
  );
  if (other === 'yes') {
    hpSignificant.push({
      label: 'Other general health information',
      value: other,
    });
    hpSignificant.push({
      type: 'text',
      label: 'Other general health information — description',
      value: await ask.text(
        'What else about your general state of health would you like to tell the doctor?'
      ),
    });
  }

  if (!!hpSignificant.find(item => item.warn === true)) {
    decisionSupport.push({
      label: 'Significant PMH',
      color: 'black',
    });
  }

  const { significant } = args;

  significant.push(...hpSignificant);

  return {
    height: heightMetric,
    weight: weightMetric,
    bmi,
    smoker,
  };
}: GuideWithOut<{
  height: number,
  weight: number,
  bmi: number,
  smoker: boolean,
}>);

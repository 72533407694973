import * as React from 'react';

export default ({ color }: { color: string }) => (
  <style>
    {`
      body {
        background-color: ${color};
      }
    `}
  </style>
);

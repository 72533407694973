import type { GuideWithIn } from '../../types';

import ds from './decisionSupport';

export default (async ({ ask, significant, decisionSupport, wantRepeat }) => {
  if (wantRepeat) {
    if (
      (await ask(
        'Do you also need medication that is NOT on your normal repeat prescription?',
        {
          id: 'acute.match',
        }
      )) === 'no'
    ) {
      return {};
    }
  }

  // TODO: a bit of a special hack to display a header. Be warned!
  significant.push({
    type: 'text',
    value: 'Acute medication'.toUpperCase(),
  });

  const howQuestion = {
    type: 'choice',
    options: {
      prescribed: 'Yes, it has been prescribed by a doctor',
      recommended:
        'Yes, it has been recommended by a healthcare professional e.g. pharmacist, nurse',
      no: 'No',
      unknown: "I don't know",
    },
  };
  const how = await ask({
    ...howQuestion,
    id: 'acute.how',
    label:
      'Has this medication been prescribed or recommended in the last 12 months?',
  });
  significant.push({
    ...howQuestion,
    label:
      'Has acute medication been issued/recommended in the last 12 months?',
    value: how,
    warn: ['no', 'unknown'],
  });

  const askAboutMedication = async () => {
    significant.push({
      type: 'text',
      label: 'Name of acute medication requested',
      value: await ask.text(
        'What is the name, strength and dose of the medication you need?',
        {
          id: 'acute.medication',
          description:
            'E.g. Hydrocortisone 1% cream, 30 grams, apply twice daily.',
        }
      ),
    });

    significant.push({
      type: 'text',
      label: 'Reasons for requesting acute medication',
      value: await ask.text('Why do you need this medication?', {
        id: 'acute.medication.why',
      }),
    });
  };

  if (how === 'prescribed') {
    const whoQuestion = {
      type: 'choice',
      options: {
        gp: 'My GP',
        hospital: 'Specialist/Consultant in Hospital',
        private: 'A private doctor',
      },
    };
    const who = await ask({
      ...whoQuestion,
      id: 'acute.prescribed.who',
      label: 'Which doctor prescribed this medication for you?',
    });
    significant.push({
      ...whoQuestion,
      label: 'Which doctor prescribed this acute medication?',
      value: who,
      warn: ['hospital', 'private'],
    });

    await askAboutMedication();

    if (who === 'gp') {
      await ask.choice(
        'We will check your records, and will only be able to prescribe the medication if we can see that your GP has prescribed it in the last 12 months.',
        { options: ['I understand'] }
      );
    } else if (who === 'hospital' || who === 'private') {
      const documented = await ask(
        'Do you have a letter or prescription from your specialist showing that you require this medication?',
        {
          id: 'acute.prescribed.documented',
        }
      );
      if (documented === 'no') {
        significant.push({
          label:
            'Photo of letter or prescription from specialist or private doctor',
          value: documented,
          warn: true,
        });

        decisionSupport.push(ds.checkSpecialistLetter);

        await ask.choice(
          'We will now need to review your records to see if your specialist has sent us a letter with information about your new medication.',
          {
            description:
              'We can only prescribe this medication if it is appropriate.',
            options: ['I understand'],
          }
        );
      } else {
        const uploads = await ask.upload(
          'Upload one or more photos of your letter or prescription from your specialist.',
          {
            id: 'acute.prescribed.photos',
            description: `
  The medication’s name and strength, as well as the text on the label (including your personal details) must be clearly visible.

  Rest assured, your photos will be kept confidential and will only be added to your medical record.
          `,
          }
        );
        significant.push({
          type: 'upload',
          label:
            'Upload photo of letter or prescription from specialist or private doctor',
          value: uploads,
        });

        await ask.choice(
          'We can only prescribe this medication after we have reviewed the letter and it is clinically appropriate for your GP to prescribe it.',
          { options: ['I understand'] }
        );
      }
    }
  } else if (how === 'recommended') {
    const whoQuestion = {
      type: 'choice',
      options: {
        consultant: 'Consultant or Specialist',
        pharmacist: 'Pharmacist',
        nurse: 'Nurse',
        dietician: 'Dietician',
        other: 'Other',
      },
    };
    const who = await ask({
      ...whoQuestion,
      id: 'acute.recommended.who',
      label: 'Which healthcare professional has recommended this medication?',
    });
    if (who === 'other') {
      significant.push({
        type: 'text',
        label: 'Who recommended acute medication?',
        value: await ask.text('Please give details.'),
        warn: true,
      });
    } else {
      significant.push({
        ...whoQuestion,
        label: 'Who recommended acute medication?',
        value: who,
        warn: true,
      });
    }

    await askAboutMedication();

    if (who !== 'pharmacist') {
      const documented = await ask(
        'Do you have a letter or prescription from your healthcare professional showing that you require this medication?',
        {
          id: 'acute.recommended.documented',
        }
      );
      if (documented === 'no') {
        significant.push({
          label: 'Does patient have letter from HCP for acute medication?',
          value: documented,
          warn: true,
        });

        decisionSupport.push(ds.checkSpecialistLetter);

        await ask.choice(
          'We will now need to review your records to see if your specialist has sent us a letter with information about your new medication.',
          {
            description:
              'We can only prescribe this medication if it is appropriate.',
            options: ['I understand'],
          }
        );
      } else {
        const uploads = await ask.upload(
          'Upload one or more photos of your letter or prescription from your healthcare professional.',
          {
            id: 'acute.recommended.photos',
            description: `
  The medication’s name and strength, as well as the text on the label (including your personal details) must be clearly visible.

  Rest assured, your photos will be kept confidential and will only be added to your medical record.
          `,
          }
        );
        significant.push({
          type: 'upload',
          label:
            'Upload photos of your letter or prescription from your healthcare professional.',
          value: uploads,
        });
      }
    }
  } else {
    decisionSupport.push(ds.checkAcuteMedication);

    await ask.choice({
      label:
        'We can only prescribe medications online that have been issued or recommened in the last 12 months.',
      description:
        'We will check your records, and will only be able to prescribe if this is the case.',
      options: ['I understand'],
    });

    await askAboutMedication();
  }

  return {};
}: GuideWithIn<{
  wantRepeat: boolean,
}>);

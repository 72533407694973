export default {
  id: 'allergy',
  type: 'formulary',
  version: '9',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'health.troubleBreathing',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'yesLittle',
              label: 'Ja, jag upplever lättare andningsbesvär.',
            },
            {
              value: 'yesSevere',
              label: 'Ja, jag upplever andnöd/kvävningskänsla.',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Har du svårt att andas?',
          index: '0.0.0',
        },
        {
          id: 'allergy.started',
          type: 'choice',
          version: '4',
          options: [
            {
              value: 'lessThanFourteenDays',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: 'fourteenDaysToThreeMonths',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'moreThanThreeMonths',
              label: 'För mer än 3 månader sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började allergibesvären?',
          index: '0.0.1',
        },
        {
          id: 'allergy.when',
          type: 'multipleChoice',
          version: '5',
          options: [
            {
              value: 'year',
              label: 'Under hela året',
            },
            {
              value: 'spring',
              label: 'Under våren',
            },
            {
              value: 'summer',
              label: 'Under sommaren',
            },
            {
              value: 'autumn',
              label: 'Under hösten',
            },
            {
              value: 'winter',
              label: 'Under vintern',
            },
            {
              value: 'unknown',
              label: 'Vet ej, besvären har precis börjat',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När på året har du främst besvär?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.0.2',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.diurnalVariation',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'unknown',
              label: 'Vet ej',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Upplever du att dina besvär varierar över dygnet?',
          index: '0.1.0',
        },
        {
          id: 'problem.diurnalVariationWhen',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'morning',
              label: 'På morgonen',
            },
            {
              value: 'day',
              label: 'På dagen',
            },
            {
              value: 'evening',
              label: 'På kvällen',
            },
            {
              value: 'night',
              label: 'På natten',
            },
          ],
          validations: {
            required: true,
          },
          condition: "contains(get('answers.problem.diurnalVariation'), 'yes')",
          label: 'När på dygnet är dina besvär som värst?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.1.1',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'allergy.symptoms',
          type: 'composite',
          version: '6',
          children: [
            {
              id: 'allergy.symptoms.where',
              type: 'multipleChoice',
              options: [
                {
                  value: 'nose',
                  label: 'Besvär i/från näsan',
                },
                {
                  value: 'eyes',
                  label: 'Besvär i/från ögonen',
                },
                {
                  value: 'breathing',
                  label: 'Besvär i/från luftvägarna/halsen',
                },
                {
                  value: 'skin',
                  label: 'Hudbesvär',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'where',
              version: '6',
              label: 'Vilka besvär har du?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.0.0',
            },
            {
              id: 'allergy.symptoms.nose',
              type: 'multipleChoice',
              options: [
                {
                  value: 'itching',
                  label: 'Kliande näsa',
                },
                {
                  value: 'running',
                  label: 'Rinnande näsa',
                },
                {
                  value: 'blocked',
                  label: 'Nästäppa',
                },
                {
                  value: 'sneezing',
                  label: 'Nysningar',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "contains(get('answers.allergy.symptoms.where'), 'nose')",
              relativeId: 'nose',
              version: '6',
              label: 'Vilka besvär har du i/från näsan?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.0.1',
            },
            {
              id: 'allergy.symptoms.noseOther',
              type: 'text',
              condition:
                "contains(get('answers.allergy.symptoms.nose'), 'other')",
              relativeId: 'noseOther',
              version: '6',
              validations: {
                required: true,
              },
              label: 'Vilka andra besvär har du i/från näsan?',
              index: '0.2.0.2',
            },
            {
              id: 'allergy.symptoms.eyes',
              type: 'multipleChoice',
              options: [
                {
                  value: 'running',
                  label: 'De rinner',
                },
                {
                  value: 'red',
                  label: 'De blir röda',
                },
                {
                  value: 'itching',
                  label: 'De kliar',
                },
                {
                  value: 'chafing',
                  label: 'De skaver',
                },
                {
                  value: 'swollen',
                  label: 'De svullnar',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "contains(get('answers.allergy.symptoms.where'), 'eyes')",
              relativeId: 'eyes',
              version: '6',
              label: 'Vilka besvär har du i/från ögonen?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.0.3',
            },
            {
              id: 'allergy.symptoms.eyesOther',
              type: 'text',
              condition:
                "contains(get('answers.allergy.symptoms.eyes'), 'other')",
              relativeId: 'eyesOther',
              version: '6',
              validations: {
                required: true,
              },
              label: 'Vilka andra besvär har du i/från ögonen?',
              index: '0.2.0.4',
            },
            {
              id: 'allergy.symptoms.eyesContactLensesMatch',
              type: 'binary',
              condition:
                "contains(get('answers.allergy.symptoms.where'), 'eyes')",
              relativeId: 'eyesContactLensesMatch',
              version: '6',
              validations: {
                required: true,
              },
              label: 'Använder du kontaktlinser?',
              index: '0.2.0.5',
            },
            {
              id: 'allergy.symptoms.breathing',
              type: 'multipleChoice',
              options: [
                {
                  value: 'cough',
                  label: 'Rethosta',
                },
                {
                  value: 'shortOfBreath',
                  label: 'Jag blir andfådd',
                },
                {
                  value: 'wheezing',
                  label: 'Pipande andning',
                },
                {
                  value: 'itchingMouth',
                  label: 'Klåda i munnen',
                },
                {
                  value: 'itchingThroat',
                  label: 'Klåda i halsen',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "contains(get('answers.allergy.symptoms.where'), 'breathing')",
              relativeId: 'breathing',
              version: '6',
              label: 'Vilka besvär har du i/från luftvägarna/halsen?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.0.6',
            },
            {
              id: 'allergy.symptoms.breathingOther',
              type: 'text',
              condition:
                "contains(get('answers.allergy.symptoms.breathing'), 'other')",
              relativeId: 'breathingOther',
              version: '6',
              validations: {
                required: true,
              },
              label: 'Vilka andra besvär har du i/från luftvägarna/halsen?',
              index: '0.2.0.7',
            },
            {
              id: 'allergy.symptoms.skin',
              type: 'multipleChoice',
              options: [
                {
                  value: 'red',
                  label: 'Rodnad',
                },
                {
                  value: 'rash',
                  label: 'Utslag',
                },
                {
                  value: 'blisters',
                  label: 'Blåsor',
                },
                {
                  value: 'itching',
                  label: 'Klåda',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "contains(get('answers.allergy.symptoms.where'), 'skin')",
              relativeId: 'skin',
              version: '6',
              label: 'Vilken typ av hudbesvär har du?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.0.8',
            },
            {
              id: 'allergy.symptoms.skinOther',
              type: 'text',
              condition:
                "contains(get('answers.allergy.symptoms.skin'), 'other')",
              relativeId: 'skinOther',
              version: '6',
              validations: {
                required: true,
              },
              label: 'Vilka andra hudbesvär har du?',
              index: '0.2.0.9',
            },
            {
              id: 'allergy.symptoms.skinWhere',
              type: 'choice',
              condition:
                "contains(get('answers.allergy.symptoms.where'), 'skin') && (contains(get('answers.allergy.symptoms.skin'), 'red') || contains(get('answers.allergy.symptoms.skin'), 'rash') || contains(get('answers.allergy.symptoms.skin'), 'blisters') || contains(get('answers.allergy.symptoms.skin'), 'itching'))",
              options: [
                {
                  value: 'spreadOut',
                  label: 'På större delen av kroppen',
                },
                {
                  value: 'localized',
                  label: 'På enstaka ställen',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'skinWhere',
              version: '6',
              label: 'Var på kroppen får du rodnad/utslag/blåsor/klåda?',
              index: '0.2.0.10',
            },
            {
              id: 'allergy.symptoms.skinWhereOther',
              type: 'text',
              condition:
                "get('answers.allergy.symptoms.skinWhere') === 'localized'",
              relativeId: 'skinWhereOther',
              version: '6',
              validations: {
                required: true,
              },
              label:
                'Beskriv var på kroppen du får rodnad/utslag/blåsor/klåda.',
              index: '0.2.0.11',
            },
            {
              id: 'allergy.symptoms.skinNowMatch',
              type: 'binary',
              condition:
                "contains(get('answers.allergy.symptoms.where'), 'skin')",
              relativeId: 'skinNowMatch',
              version: '6',
              validations: {
                required: true,
              },
              label: 'Har du hudbesvär just nu?',
              index: '0.2.0.12',
            },
          ],
          extras: {},
          index: '0.2.0',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'allergy.relatedToExposure',
          type: 'composite',
          version: '6',
          children: [
            {
              id: 'allergy.relatedToExposure.match',
              type: 'binary',
              relativeId: 'match',
              version: '6',
              validations: {
                required: true,
              },
              label:
                'Finns det ämnen eller föremål som ger dig – eller som du misstänker ger dig – besvär vid hudkontakt?',
              index: '0.3.0.0',
            },
            {
              id: 'allergy.relatedToExposure.what',
              type: 'multipleChoice',
              condition:
                "get('answers.allergy.relatedToExposure.match') === 'yes'",
              options: [
                {
                  value: 'latex',
                  label: 'Latex (t.ex. engångshandskar, kanten på underkläder)',
                },
                {
                  value: 'jewelry',
                  label: 'Smycken',
                },
                {
                  value: 'detergent',
                  label: 'Tvättmedel',
                },
                {
                  value: 'makeup',
                  label: 'Smink',
                },
                {
                  value: 'perfume',
                  label: 'Parfym',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'what',
              version: '6',
              label: 'Vad ger dig besvär vid hudkontakt?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.3.0.1',
            },
            {
              id: 'allergy.relatedToExposure.other',
              type: 'text',
              condition:
                "contains(get('answers.allergy.relatedToExposure.what'), 'other')",
              relativeId: 'other',
              version: '6',
              validations: {
                required: true,
              },
              label:
                'Vilka andra ämnen eller föremål ger dig besvär vid hudkontakt?',
              index: '0.3.0.2',
            },
          ],
          extras: {},
          index: '0.3.0',
        },
        {
          id: 'allergy.relatedToFood',
          type: 'composite',
          version: '5',
          children: [
            {
              id: 'allergy.relatedToFood.match',
              type: 'choice',
              options: [
                {
                  value: 'yesWithDiagnos',
                  label: 'Ja, jag har en diagnosticerad födoämnesallergi',
                },
                {
                  value: 'yesWithoutDiagnos',
                  label:
                    'Ja, jag har besvär i samband med att jag äter vissa saker men har ingen diagnosticerad födoämnesallergi',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'match',
              version: '5',
              label: 'Har du besvär i samband med att du äter vissa saker?',
              index: '0.3.1.0',
            },
            {
              id: 'allergy.relatedToFood.what',
              type: 'multipleChoice',
              condition:
                "get('answers.allergy.relatedToFood.match') === 'yesWithDiagnos' || get('answers.allergy.relatedToFood.match') === 'yesWithoutDiagnos'",
              options: [
                {
                  value: 'apple',
                  label: 'Äpple',
                },
                {
                  value: 'pear',
                  label: 'Päron',
                },
                {
                  value: 'stoneFruits',
                  label:
                    'Stenfrukter (t.ex. aprikos, plommon, persika, nektarin, körsbär, mango, mandel)',
                },
                {
                  value: 'nuts',
                  label: 'Valnötter/hasselnötter/paranötter',
                },
                {
                  value: 'carrots',
                  label: 'Morötter',
                },
                {
                  value: 'potatoes',
                  label: 'Potatis med skal',
                },
                {
                  value: 'celery',
                  label: 'Selleri',
                },
                {
                  value: 'parsley',
                  label: 'Persilja',
                },
                {
                  value: 'shellfish',
                  label: 'Skaldjur',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'what',
              version: '5',
              label: 'Vilka födoämnen reagerar du mot?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.3.1.1',
            },
            {
              id: 'allergy.relatedToFood.other',
              type: 'text',
              condition:
                "contains(get('answers.allergy.relatedToFood.what'), 'other')",
              relativeId: 'other',
              version: '5',
              validations: {
                required: true,
              },
              label: 'Vilka andra födoämnen reagerar du mot?',
              index: '0.3.1.2',
            },
          ],
          extras: {},
          index: '0.3.1',
        },
        {
          id: 'allergy.relatedToAnimal',
          type: 'composite',
          version: '6',
          children: [
            {
              id: 'allergy.relatedToAnimal.match',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'unknown',
                  label: 'Vet ej',
                },
              ],
              relativeId: 'match',
              version: '6',
              validations: {
                required: true,
              },
              label:
                'Har du märkt att du har besvär i samband med att du vistas bland djur?',
              index: '0.3.2.0',
            },
            {
              id: 'allergy.relatedToAnimal.what',
              type: 'multipleChoice',
              condition:
                "get('answers.allergy.relatedToAnimal.match') === 'yes'",
              options: [
                {
                  value: 'horse',
                  label: 'Häst',
                },
                {
                  value: 'dog',
                  label: 'Hund',
                },
                {
                  value: 'cat',
                  label: 'Katt',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'what',
              version: '6',
              label: 'Vilka djur ger dig besvär?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.3.2.1',
            },
            {
              id: 'allergy.relatedToAnimal.other',
              type: 'text',
              condition:
                "contains(get('answers.allergy.relatedToAnimal.what'), 'other')",
              relativeId: 'other',
              version: '6',
              validations: {
                required: true,
              },
              label: 'Vilka andra djur ger dig besvär?',
              index: '0.3.2.2',
            },
          ],
          extras: {},
          index: '0.3.2',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'allergy.triedMedication',
          type: 'composite',
          version: '4',
          children: [
            {
              id: 'allergy.triedMedication.match',
              type: 'binary',
              relativeId: 'match',
              version: '4',
              validations: {
                required: true,
              },
              label:
                'Har du provat några läkemedel inklusive krämer mot dina besvär?',
              index: '0.4.0.0',
            },
            {
              id: 'allergy.triedMedication.what',
              type: 'multipleChoice',
              condition:
                "get('answers.allergy.triedMedication.match') === 'yes'",
              options: [
                {
                  value: 'antihistamine',
                  label:
                    'Antihistaminer (t.ex. Aerius, Clarityn, Loratadin, Kestine)',
                },
                {
                  value: 'inhaler',
                  label: 'Inhalationer (t.ex. Bricanyl, Pulmicort)',
                },
                {
                  value: 'cortisoneCream',
                  label:
                    'Kortisonkräm (t.ex. Hydrokortison, Mildison, Locoid, Betnovat, Dermovat)',
                },
                {
                  value: 'cortisoneTablets',
                  label: 'Kortisontabletter (t.ex. Betapred)',
                },
                {
                  value: 'noseDrops',
                  label: 'Näsdroppar (t.ex. Renässans naturell)',
                },
                {
                  value: 'noseSpray',
                  label:
                    'Nässpray (t.ex. Nasonex, Mometasone, Rhinocort Aqua, Lomudal Nasal)',
                },
                {
                  value: 'eyeDrops',
                  label:
                    'Ögondroppar (t.ex. Livostin, Lomudal, Allgil, Lecrolyn)',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'what',
              version: '4',
              label: 'Vilka läkemedel har du provat?',
              index: '0.4.0.1',
            },
            {
              id: 'allergy.triedMedication.whatOther',
              type: 'text',
              condition:
                "contains(get('answers.allergy.triedMedication.what'), 'other')",
              relativeId: 'whatOther',
              version: '4',
              validations: {
                required: true,
              },
              label: 'Vilka andra läkemedel har du provat?',
              index: '0.4.0.2',
            },
            {
              id: 'allergy.triedMedication.effect',
              type: 'text',
              condition:
                "get('answers.allergy.triedMedication.match') === 'yes'",
              relativeId: 'effect',
              version: '4',
              validations: {
                required: true,
              },
              label: 'Vilken effekt hade läkemedlen?',
              index: '0.4.0.3',
            },
          ],
          extras: {},
          index: '0.4.0',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'allergy.similar',
          type: 'composite',
          version: '5',
          children: [
            {
              id: 'allergy.similar.match',
              type: 'binary',
              relativeId: 'match',
              version: '5',
              validations: {
                required: true,
              },
              label: 'Har du tidigare sökt läkare för detta besvär?',
              index: '0.5.0.0',
            },
            {
              id: 'allergy.similar.diagnosisMatch',
              type: 'binary',
              condition: "get('answers.allergy.similar.match') === 'yes'",
              relativeId: 'diagnosisMatch',
              version: '5',
              validations: {
                required: true,
              },
              label: 'Fick du någon diagnos?',
              index: '0.5.0.1',
            },
            {
              id: 'allergy.similar.diagnosis',
              type: 'multipleChoice',
              condition:
                "get('answers.allergy.similar.diagnosisMatch') === 'yes'",
              options: [
                {
                  value: 'pollen',
                  label: 'Pollenallergi',
                },
                {
                  value: 'fur',
                  label: 'Pälsdjursallergi',
                },
                {
                  value: 'exposure',
                  label: 'Kontaktallergi',
                },
                {
                  value: 'mite',
                  label: 'Kvalster-/mögelallergi',
                },
                {
                  value: 'cold',
                  label: 'Köldallergi',
                },
                {
                  value: 'food',
                  label: 'Födoämnesallergi',
                },
                {
                  value: 'fragrance',
                  label: 'Allergi mot starka dofter',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'diagnosis',
              version: '5',
              label: 'Vilken diagnos fick du då?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.5.0.2',
            },
            {
              id: 'allergy.similar.otherDiagnosis',
              type: 'text',
              condition:
                "contains(get('answers.allergy.similar.diagnosis'), 'other')",
              relativeId: 'otherDiagnosis',
              version: '5',
              validations: {
                required: true,
              },
              label: 'Vilken annan diagnos fick du?',
              index: '0.5.0.3',
            },
          ],
          extras: {},
          index: '0.5.0',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'allergy.pictures',
          type: 'upload',
          version: '1',
          validations: {
            required: true,
            min: 2,
          },
          condition: "get('answers.allergy.symptoms.skinNowMatch') === 'yes'",
          label:
            'Ladda upp minst två bilder på dina hudbesvär, en närbild och en översiktsbild.',
          description:
            '<p>\n  Det går bra att ta bilderna med din mobilkamera, men undvik att använda dess zoom-funktion. Försök ta en så detaljerad närbild som möjligt i bra belysning.\n</p>\n\n<p>\n  Observera att även bilderna är en del av din journal och omfattas av sekretess.\n</p>',
          index: '0.6.0',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.7.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.7.1',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.8.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.8.1',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.9.0',
        },
      ],
      index: '0.9',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.10',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.11',
    },
  ],
  variables: [
    {
      id: 'allergy.troubleBreathing',
      type: 'abort',
      expression: "get('answers.health.troubleBreathing') === 'yesSevere'",
      relativeId: 'troubleBreathing',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
        },
        {
          id: 'health.troubleBreathing',
          warning:
            "get('answers.health.troubleBreathing') === 'yesLittle' || get('answers.health.troubleBreathing') === 'yesSevere'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andningssvårigheter',
          options: [
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'yesLittle',
              label: 'Ja, jag upplever lättare andningsbesvär.',
            },
            {
              value: 'yesSevere',
              label: 'Ja, jag upplever andnöd/kvävningskänsla.',
            },
          ],
        },
        {
          id: 'allergy.started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären började',
          description: '',
          options: [
            {
              value: 'lessThanFourteenDays',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: 'fourteenDaysToThreeMonths',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'moreThanThreeMonths',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'allergy.when',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Främst besvär under tid på året',
          description: '',
          options: [
            {
              value: 'year',
              label: 'Under hela året',
            },
            {
              value: 'spring',
              label: 'Under våren',
            },
            {
              value: 'summer',
              label: 'Under sommaren',
            },
            {
              value: 'autumn',
              label: 'Under hösten',
            },
            {
              value: 'winter',
              label: 'Under vintern',
            },
            {
              value: 'unknown',
              label: 'Vet ej, besvären har precis börjat',
            },
          ],
        },
        {
          id: 'problem.diurnalVariation',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvär varierar över dygnet',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'unknown',
              label: 'Vet ej',
            },
          ],
        },
        {
          id: 'problem.diurnalVariationWhen',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären är som värst',
          description: '',
          options: [
            {
              value: 'morning',
              label: 'På morgonen',
            },
            {
              value: 'day',
              label: 'På dagen',
            },
            {
              value: 'evening',
              label: 'På kvällen',
            },
            {
              value: 'night',
              label: 'På natten',
            },
          ],
        },
        {
          id: 'allergy.symptoms.where',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har besvär',
          description: '',
          options: [
            {
              value: 'nose',
              label: 'Besvär i/från näsan',
            },
            {
              value: 'eyes',
              label: 'Besvär i/från ögonen',
            },
            {
              value: 'breathing',
              label: 'Besvär i/från luftvägarna/halsen',
            },
            {
              value: 'skin',
              label: 'Hudbesvär',
            },
          ],
        },
        {
          id: 'allergy.symptoms.nose',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvär i/från näsan',
          description: '',
          options: [
            {
              value: 'itching',
              label: 'Kliande näsa',
            },
            {
              value: 'running',
              label: 'Rinnande näsa',
            },
            {
              value: 'blocked',
              label: 'Nästäppa',
            },
            {
              value: 'sneezing',
              label: 'Nysningar',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.symptoms.noseOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra besvär i/från näsan',
        },
        {
          id: 'allergy.symptoms.eyes',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvär i/från ögonen',
          description: '',
          options: [
            {
              value: 'running',
              label: 'De rinner',
            },
            {
              value: 'red',
              label: 'De blir röda',
            },
            {
              value: 'itching',
              label: 'De kliar',
            },
            {
              value: 'chafing',
              label: 'De skaver',
            },
            {
              value: 'swollen',
              label: 'De svullnar',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.symptoms.eyesOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra besvär i/från ögonen',
        },
        {
          id: 'allergy.symptoms.eyesContactLensesMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Använder kontaktlinser',
        },
        {
          id: 'allergy.symptoms.breathing',
          warning:
            "contains(get('answers.allergy.symptoms.breathing'), 'wheezing')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvär i/från luftvägarna/halsen',
          description: '',
          options: [
            {
              value: 'cough',
              label: 'Rethosta',
            },
            {
              value: 'shortOfBreath',
              label: 'Jag blir andfådd',
            },
            {
              value: 'wheezing',
              label: 'Pipande andning',
            },
            {
              value: 'itchingMouth',
              label: 'Klåda i munnen',
            },
            {
              value: 'itchingThroat',
              label: 'Klåda i halsen',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.symptoms.breathingOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra besvär i/från luftvägarna/halsen',
        },
        {
          id: 'allergy.symptoms.skin',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hudbesvär',
          description: '',
          options: [
            {
              value: 'red',
              label: 'Rodnad',
            },
            {
              value: 'rash',
              label: 'Utslag',
            },
            {
              value: 'blisters',
              label: 'Blåsor',
            },
            {
              value: 'itching',
              label: 'Klåda',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.symptoms.skinOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra hudbesvär',
        },
        {
          id: 'allergy.symptoms.skinWhere',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hudbesvären sitter',
          options: [
            {
              value: 'spreadOut',
              label: 'På större delen av kroppen',
            },
            {
              value: 'localized',
              label: 'På enstaka ställen',
            },
          ],
        },
        {
          id: 'allergy.symptoms.skinWhereOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Lokalisation av hudbesvär',
        },
        {
          id: 'allergy.symptoms.skinNowMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hudbesvär just nu',
        },
        {
          id: 'allergy.relatedToExposure.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ämnen/föremål som ger besvär vid hudkontakt',
        },
        {
          id: 'allergy.relatedToExposure.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ämnen/föremål som ger besvär vid hudkontakt',
          description: '',
          options: [
            {
              value: 'latex',
              label: 'Latex (t.ex. engångshandskar, kanten på underkläder)',
            },
            {
              value: 'jewelry',
              label: 'Smycken',
            },
            {
              value: 'detergent',
              label: 'Tvättmedel',
            },
            {
              value: 'makeup',
              label: 'Smink',
            },
            {
              value: 'perfume',
              label: 'Parfym',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.relatedToExposure.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra ämnen/föremål som ger besvär vid hudkontakt',
        },
        {
          id: 'allergy.relatedToFood.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvär i samband med födointag',
          options: [
            {
              value: 'yesWithDiagnos',
              label: 'Ja, jag har en diagnosticerad födoämnesallergi',
            },
            {
              value: 'yesWithoutDiagnos',
              label:
                'Ja, jag har besvär i samband med att jag äter vissa saker men har ingen diagnosticerad födoämnesallergi',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          id: 'allergy.relatedToFood.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Reaktion mot vilka födoämnen',
          description: '',
          options: [
            {
              value: 'apple',
              label: 'Äpple',
            },
            {
              value: 'pear',
              label: 'Päron',
            },
            {
              value: 'stoneFruits',
              label:
                'Stenfrukter (t.ex. aprikos, plommon, persika, nektarin, körsbär, mango, mandel)',
            },
            {
              value: 'nuts',
              label: 'Valnötter/hasselnötter/paranötter',
            },
            {
              value: 'carrots',
              label: 'Morötter',
            },
            {
              value: 'potatoes',
              label: 'Potatis med skal',
            },
            {
              value: 'celery',
              label: 'Selleri',
            },
            {
              value: 'parsley',
              label: 'Persilja',
            },
            {
              value: 'shellfish',
              label: 'Skaldjur',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.relatedToFood.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Reaktion mot annat födoämne',
        },
        {
          id: 'allergy.relatedToAnimal.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Märkt av besvär i samband med djurvistelse',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'unknown',
              label: 'Vet ej',
            },
          ],
        },
        {
          id: 'allergy.relatedToAnimal.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Djur som ger besvär',
          description: '',
          options: [
            {
              value: 'horse',
              label: 'Häst',
            },
            {
              value: 'dog',
              label: 'Hund',
            },
            {
              value: 'cat',
              label: 'Katt',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.relatedToAnimal.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra djur som ger besvär',
        },
        {
          id: 'allergy.triedMedication.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Provat läkemedel (inklusive krämer)',
        },
        {
          id: 'allergy.triedMedication.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel',
          options: [
            {
              value: 'antihistamine',
              label:
                'Antihistaminer (t.ex. Aerius, Clarityn, Loratadin, Kestine)',
            },
            {
              value: 'inhaler',
              label: 'Inhalationer (t.ex. Bricanyl, Pulmicort)',
            },
            {
              value: 'cortisoneCream',
              label:
                'Kortisonkräm (t.ex. Hydrokortison, Mildison, Locoid, Betnovat, Dermovat)',
            },
            {
              value: 'cortisoneTablets',
              label: 'Kortisontabletter (t.ex. Betapred)',
            },
            {
              value: 'noseDrops',
              label: 'Näsdroppar (t.ex. Renässans naturell)',
            },
            {
              value: 'noseSpray',
              label:
                'Nässpray (t.ex. Nasonex, Mometasone, Rhinocort Aqua, Lomudal Nasal)',
            },
            {
              value: 'eyeDrops',
              label: 'Ögondroppar (t.ex. Livostin, Lomudal, Allgil, Lecrolyn)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.triedMedication.whatOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra läkemedel',
        },
        {
          id: 'allergy.triedMedication.effect',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Effekt av läkemedel',
        },
        {
          id: 'allergy.similar.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare sökt läkare',
        },
        {
          id: 'allergy.similar.diagnosisMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fick diagnos',
        },
        {
          id: 'allergy.similar.diagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diagnos',
          description: '',
          options: [
            {
              value: 'pollen',
              label: 'Pollenallergi',
            },
            {
              value: 'fur',
              label: 'Pälsdjursallergi',
            },
            {
              value: 'exposure',
              label: 'Kontaktallergi',
            },
            {
              value: 'mite',
              label: 'Kvalster-/mögelallergi',
            },
            {
              value: 'cold',
              label: 'Köldallergi',
            },
            {
              value: 'food',
              label: 'Födoämnesallergi',
            },
            {
              value: 'fragrance',
              label: 'Allergi mot starka dofter',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'allergy.similar.otherDiagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Annan diagnos',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Allergi',
  index: '0',
};

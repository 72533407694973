import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const otherChoice = {
    type: 'binary',
    id: 'otherChoice',
    label: 'Övrigt',
    description:
      'Finns det något mer angående din allmänna hälsostatus som du tror vi kan behöva veta?',
    expires: 99999,
  };

  const otherChoiceAnswer = await ask(otherChoice);

  significant.push({
    ...otherChoice,
    label: 'Övrigt',
    description: '',
    value: otherChoiceAnswer,
  });

  if (otherChoiceAnswer === 'yes') {
    const other = {
      type: 'text',
      id: 'other',
      label:
        'Vad mer angående din allmänna hälsostatus skulle du vilja förmedla?',
      expires: 99999,
    };

    const otherAnswer = await ask(other);

    significant.push({
      ...other,
      label: 'Övrigt',
      value: otherAnswer,
    });
  }

  return {};
}: Guide);

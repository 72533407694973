import { type DecisionSupport } from '../../types';

const decisionSupport: { [string]: DecisionSupport } = {
  exampleDS: {
    label: `This is a decision support label`,
    description: `This is a decision support description`,
    color: 'red',
  },
};

Object.keys(decisionSupport).forEach(id => (decisionSupport[id].id = id));

export default decisionSupport;

import type { Guide } from './../../types';

import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const lastepisodeofvomiting = await eChoice({
    id: 'coughChild.lastepisodeofvomiting',
    label: 'When did your child last vomit?',
    clinicLabel: 'Last episode of vomiting',
    options: [
      {
        label: 'In the last few hours',
        value: 'Inthelastfewhours',
        keyFinding: 'Vomited in the last few hours',
        warn: true,
        urgent: true,
      },
      {
        label: 'In the last 24 hours',
        value: 'Inthelast24hours',
        keyFinding: 'Vomtied in the last 24 hours',
        warn: true,
        urgent: true,
      },
      {
        label: '2–3 days ago',
        value: '2–3daysago',
        keyFinding: 'Vomited 2–3 days ago',
      },
      {
        label: '4–7 days ago',
        value: '4–7daysago',
        keyFinding: 'Vomited 4–7 days ago',
      },
      {
        label: 'More than 1 week ago',
        value: 'Morethan1weekago',
        keyFinding: 'Vomited more than 1 week ago',
        hide: true,
      },
    ],
  });

  if (lastepisodeofvomiting === 'Morethan1weekago') {
    await eText({
      id: 'coughChild.lastepisodeofvomitingother',
      label: 'When did you child last vomit?',
      clinicLabel: 'Last episode of vomiting',
    });
  }

  await eChoice({
    id: 'coughChild.eatennormallyinlast48hours',
    label: 'How much has your child eaten the last 48 hours?',
    clinicLabel: 'Eating in the last 48h hours',
    options: [
      {
        label: 'Eating normally in last 48 hours',
        value: 'eatingnormally',
        keyFinding: 'Eating normally in the last 48 hours',
      },
      {
        label: 'Eating slightly less than normal in last 48 hours',
        value: 'Theyareeatingslightlylessthannormal',
        keyFinding: 'Eating slightly less than normal in last 48 hours',
      },
      {
        label: 'Half of normal',
        value: 'Theyareeatinghalfofwhattheyusuallyeat',
        keyFinding: 'Eating half of normal in last 48 hours',
        warn: true,
      },
      {
        label: 'Nothing',
        value: 'Theyarenoteatinganythingatall',
        keyFinding: 'Eating nothing in last 48 hours',
        warn: true,
        urgent: true,
      },
    ],
  });

  return {};
}: Guide);

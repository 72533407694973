import type { Guide } from '../../types';

import pharmacy from '../modules/pharmacy';

import informedConsent from '../questions/informedConsent';

import acute from './acute';
import ds from './decisionSupport';
import healthProfile from './healthProfile';
import repeat from './repeat';
import review from './review';
import triage from './triage';
import { coronaSeason } from '../common/consts';

export default (async args => {
  const {
    ask,
    // patient: { domain },
    significant,
    decisionSupport,
    exports,
  } = args;

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) return { abort: triageAbort };

  const repeatSignificant = [];

  const { abort: repeatAbort, want: wantRepeat, needReview } = await repeat({
    ...args,
    significant: repeatSignificant,
  });
  if (repeatAbort) return { abort: repeatAbort };

  const acuteSignificant = [];

  const { abort: acuteAbort } = await acute({
    ...args,
    significant: acuteSignificant,
    wantRepeat,
  });
  if (acuteAbort) return { abort: acuteAbort };

  const reviewSignificant = [];

  if (needReview) {
    const { abort: reviewAbort } = await review({
      ...args,
      significant: reviewSignificant,
    });
    if (reviewAbort) return { abort: reviewAbort };
  }

  significant.push(...acuteSignificant);
  significant.push(...repeatSignificant);
  significant.push(...reviewSignificant);

  // TODO: a bit of a special hack to display a header. Be warned!
  significant.push({
    type: 'text',
    value: 'Health profile'.toUpperCase(),
  });

  await healthProfile(args);

  await pharmacy(args);

  await ask(informedConsent);

  await ask.choice({
    description: `
We aim to reply within 2 working days. 

**If your request is urgent, please contact your GP surgery in the normal way.**
`,
    options: ['I understand'],
  });

  if (!coronaSeason) {
    decisionSupport.push(ds.controlledDrugs);
  }

  exports.push({ id: 'presentingComplaint', value: 'Medication requested' });
  exports.push({ id: 'snomedCode', value: '182918009 Medication requested' });
  exports.push({
    id: 'diagnosis.options',
    value: ['Repeat prescription issued', 'Medication requested'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Medication prescribed',
      'Medication prescribed and advice given',
      'Medication prescribed and investigations requested',
      'Investigations requested, no medication issued',
      'Self care',
      'Follow up consultation',
      'Referred to GP',
      'Urgent care recommended',
      'Emergency care recommended',
    ],
  });

  return {};
}: Guide);

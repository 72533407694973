import type { Guide } from '../../../types';
import informedConsent from '../../questions/informedConsent';

export default (async args => {
  const { ask } = args;

  await ask(informedConsent);

  await ask.choice({
    description: `
We will reply to you as soon as we can.  Within working hours, we aim to reply within 2 hours. 

**If you become more unwell whilst you are waiting, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.**
`,
    options: ['I understand'],
  });

  return {};
}: Guide);

import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { _t } from '../../i18n';

import { type Patient } from '../../guides/types';

import { updatePatient } from '../../state/patients/actions';
import { getPatient } from '../../state/patients/selectors';

import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

import femaleIconSource from '../../images/icons/ic_female.svg';
import maleIconSource from '../../images/icons/ic_male.svg';

import Key from '../components/Key';

import PatientModal from './PatientModal';

class PatientButton extends React.Component<
  {
    patient: Patient,
    updatePatient: typeof updatePatient,
    classes: { [string]: string },
    className?: string,
  },
  {
    modal: boolean,
  }
> {
  state = {
    modal: false,
  };

  toggleModal = () => this.setState({ modal: !this.state.modal });
  openModal = () => this.setState({ modal: true });
  closeModal = () => this.setState({ modal: false });

  save = patient => {
    const { updatePatient } = this.props;

    this.closeModal();

    updatePatient(patient);
  };

  render() {
    const { patient, classes, className } = this.props;
    const { modal } = this.state;

    return (
      <>
        <Button
          variant="outlined"
          onClick={this.openModal}
          className={classNames(classes.root, className)}
        >
          <img
            alt="icon"
            src={
              patient.gender === 'female' ? femaleIconSource : maleIconSource
            }
            className={classes.icon}
          />
          {`${patient.age} ${_t('patient.years')}`}

          <Key
            character="P"
            onPress={this.toggleModal}
            className={classes.key}
          />
        </Button>

        <PatientModal
          open={!!modal}
          close={this.closeModal}
          patient={patient}
          save={this.save}
        />
      </>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {},

  icon: {
    height: 20,
    marginRight: spacing(),
  },

  key: {
    marginLeft: spacing(),
  },
}))(
  connect(
    state => ({ patient: getPatient(state) }),
    { updatePatient }
  )(PatientButton)
);

import type { GuideWithOut } from '../../types';

import ds from './decisionSupport';

export default (async args => {
  const { ask, significant, decisionSupport } = args;

  const contact = await ask(
    `Have you previously discussed this problem with a healthcare professional?`
  );
  significant.push({
    label: 'Previously seen Healthcare Professional about symptoms',
    value: contact,
  });

  if (contact === 'yes') {
    const testsQuestion = {
      type: 'multipleChoice',
      options: {
        bloodTest: 'Blood test',
        breathTest: 'H. Pylori breath test',
        ecg: 'ECG (Heart tracing)',
        ultrasound: 'Ultrasound scan of the stomach',
        endoscopy: 'Endoscopy (camera into your stomach)',
        other: 'Other',
      },
      optional: true,
    };
    const tests = await ask({
      ...testsQuestion,
      label:
        'Have you previously had any of the following tests for this problem?',
      description: 'More than one answer can be selected',
    });
    if (tests.length > 1 || (tests.length === 1 && !tests.includes('other'))) {
      significant.push({
        ...testsQuestion,
        label: 'Other previous tests',
        value: tests,
      });
    }
    if (tests.includes('other')) {
      significant.push({
        type: 'text',
        label: 'Other Previous tests',
        value: await ask.text('What other test did you have?'),
      });
    }
    if (tests.includes('endoscopy')) {
      significant.push({
        type: 'text',
        label: 'Last Endoscopy',
        value: await ask.text(
          'When did you last have the endoscopy (camera into your stomach) test?'
        ),
      });
    }
    if (tests.length >= 1) {
      significant.push({
        type: 'text',
        label: 'Test results',
        value: await ask.text('What did the tests show?'),
      });
    }
  }

  const diagnosisQuestion = {
    type: 'multipleChoice',
    options: {
      refluxDisease: 'Reflux disease (also known as GORD or dyspepsia)',
      stomachBacteria: 'H Pylori (bacteria in the stomach)',
      hiatusHernia: 'Hiatus hernia',
      stomachUlcer: 'Stomach ulcer',
      oesophagitis: 'Oesophagitis (inflammation of the food pipe)',
      other: 'Other',
    },
  };
  const diagnosis = await ask({
    ...diagnosisQuestion,
    label:
      // 'Have you been given any of the following as a diagnosis for your symptoms?',
      'Have you been diagnosed with any of the following?',
    description: 'More than one answer can be selected.',
    optional: true,
  });
  if (
    diagnosis.length > 1 ||
    (diagnosis.length === 1 && !diagnosis.includes('other'))
  ) {
    significant.push({
      ...diagnosisQuestion,
      label: 'Previous diagnosis',
      value: diagnosis,
      warn: [
        'refluxDisease',
        'stomachBacteria',
        'hiatusHernia',
        'stomachUlcer',
        'oesophagitis',
      ],
    });
  }
  if (diagnosis.includes('other')) {
    significant.push({
      type: 'text',
      label: 'Other previous diagnosis',
      value: await ask.text('Please tell us what diagnosis you were given.'),
    });
  }
  if (diagnosis.includes('stomachBacteria')) {
    decisionSupport.push(ds.previousStomachBacteria);
  }
  if (diagnosis.includes('stomachUlcer')) {
    decisionSupport.push(ds.previousStomachUlcer);
  }
  if (diagnosis.includes('oesophagitis')) {
    decisionSupport.push(ds.previousOesophagitis);
  }

  return {
    stomachBacteria: diagnosis.includes('stomachBacteria'),
  };
}: GuideWithOut<{ stomachBacteria: boolean }>);

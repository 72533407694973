import type { Export } from '../../types';

export default (exports: Export[]) => {
  exports.push({ id: 'presentingComplaint', value: 'Child Return Visit' });
  exports.push({ id: 'snomedCode', value: 'Ongoing episode 303350001' });
  exports.push({
    id: 'diagnosis.options',
    value: [
      'Ongoing episode',
      'Worsening symptoms',
      'New symptoms',
      'New problem',
    ],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed antibiotics', 'Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Self-care advice',
      'Antibiotics prescribed and advice given',
      'For referral to ENT',
      'For referral to Respiratory',
      'For referral to Dermatology',
      'Routine appointment to see GP face to face',
      'Urgent appointment to see GP face to face',
      'Referred for emergency care (A&E)',
    ],
  });
};

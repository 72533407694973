// @flow

import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';
import createExtendedText from '../../common/extendedText';
// import abort from './abort';

// const ds = {
//   coronaRisk: {
//     id: 'coronaRisk',
//     color: 'black',
//     label: '? Coronavirus Risk',
//   },
// };

const childMaxAge = 15;

// const coronaAbort = patient => {
//   if (patient.age <= childMaxAge) {
//     return abort.coronaChildAbort;
//   }
//   return abort.coronaAdultAbort;
// };

const questionLabels = {
  'coronaVirusScreen.traveledAbroad': {
    adult: 'Have you traveled abroad in the last 14 days?',
    child: 'Have you, or your child travelled abroad in the last 14 days?',
  },
  'coronaVirusScreen.whereTraveledAbroad': {
    adult: 'Where have you visited?',
    child: 'Where have you, or your child visited?',
  },
  'coronaVirusScreen.whereTraveledAbroadSouthEastAsia': {
    adult: 'Where have you been in South East Asia?',
    child: 'Where have you, or your child been in South East Asia?',
  },
  'coronaVirusScreen.whereTraveledAbroadOther': {
    adult: 'Please list all the countries you have visited in the last 14 days',
    child:
      'Please list all the countries that have been visited in the last 14 days',
  },
  'coronaVirusScreen.contactWithOthers': {
    adult:
      'Have you come into contact with anyone with suspected or confirmed Coronavirus in the last 14 days?',
    child:
      'Have you, or your child, come into contact with anyone with suspected or confirmed Coronavirus in the last 14 days?',
  },
};

const questionLabel = (id, patient) => {
  const label = questionLabels[id];
  if (patient.age <= childMaxAge) {
    return label.child;
  }
  return label.adult;
};

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);
  const patient = args.patient;

  const traveledAbroad = await eChoice({
    id: 'coronaVirusScreen.traveledAbroad',
    label: questionLabel('coronaVirusScreen.traveledAbroad', patient),
    clinicLabel: 'Traveled abroad in the last 14 days',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        warn: true,
        keyFinding: 'Traveled abroad in the last 14 days',
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Not traveled abroad in the last 14 days',
      },
    ],
  });

  if (traveledAbroad === 'yes') {
    const whereTraveledAbroad = await eChoice({
      id: 'coronaVirusScreen.whereTraveledAbroad',
      label: questionLabel('coronaVirusScreen.whereTraveledAbroad', patient),
      clinicLabel: 'Regions visited in the last 14 days',
      isMultipleChoice: true,
      options: [
        {
          label: 'Northern Italy',
          value: 'northernItaly',
          keyFinding: 'Traveled to Northern Italy in the last 14 days',
          warn: true,
        },
        {
          label: 'China',
          value: 'china',
          keyFinding: 'Traveled to China in the last 14 days',
          warn: true,
        },
        {
          label: 'South East Asia',
          value: 'southEastAsia',
          warn: true,
        },
        {
          label: 'Iran',
          value: 'iran',
          keyFinding: 'Traveled to Iran in the last 14 days',
          warn: true,
        },
        {
          label: 'Other',
          value: 'other',
          hide: true,
        },
      ],
    });

    let whereTraveledAbroadSouthEastAsia;
    if (
      whereTraveledAbroad &&
      Array.isArray(whereTraveledAbroad) &&
      whereTraveledAbroad.includes('southEastAsia')
    ) {
      whereTraveledAbroadSouthEastAsia = await eChoice({
        id: 'coronaVirusScreen.whereTraveledAbroadSouthEastAsia',
        label: questionLabel(
          'coronaVirusScreen.whereTraveledAbroadSouthEastAsia',
          patient
        ),
        clinicLabel: 'Countries visited in the last 14 days',
        isMultipleChoice: true,
        options: [
          {
            label: 'Hong Kong',
            value: 'hongKong',
            keyFinding: 'Traveled to Hong Kong in the last 14 days',
            warn: true,
          },
          {
            label: 'Singapore',
            value: 'singapore',
            keyFinding: 'Traveled to Singapore in the last 14 days',
            warn: true,
          },
          {
            label: 'Thailand',
            value: 'thailand',
            keyFinding: 'Traveled to Thailand in the last 14 days',
            warn: true,
          },
          {
            label: 'Malaysia',
            value: 'malaysia',
            keyFinding: 'Traveled to Malaysia in the last 14 days',
            warn: true,
          },
          {
            label: 'Macau',
            value: 'macau',
            keyFinding: 'Traveled to Macau in the last 14 days',
            warn: true,
          },
          {
            label: 'Japan',
            value: 'japan',
            keyFinding: 'Traveled to Japan in the last 14 days',
            warn: true,
          },
          {
            label: 'Vietnam',
            value: 'vietnam',
            keyFinding: 'Traveled to Vietnam in the last 14 days',
            warn: true,
          },
          {
            label: 'Taiwan',
            value: 'taiwan',
            keyFinding: 'Traveled to Taiwan in the last 14 days',
            warn: true,
          },
          {
            label: 'Laos',
            value: 'laos',
            keyFinding: 'Traveled to Laos in the last 14 days',
            warn: true,
          },
          {
            label: 'Myanmar',
            value: 'myanmar',
            keyFinding: 'Traveled to Myanmar in the last 14 days',
            warn: true,
          },
          {
            label: 'Cambodia',
            value: 'cambodia',
            keyFinding: 'Traveled to Cambodia in the last 14 days',
            warn: true,
          },
          {
            label: 'South Korea',
            value: 'southKorea',
            keyFinding: 'Traveled to South Korea in the last 14 days',
            warn: true,
          },
          {
            label: 'Other',
            value: 'other',
            hide: true,
          },
        ],
      });
    }
    if (
      (whereTraveledAbroad &&
        Array.isArray(whereTraveledAbroad) &&
        whereTraveledAbroad.includes('other')) ||
      (whereTraveledAbroadSouthEastAsia &&
        Array.isArray(whereTraveledAbroadSouthEastAsia) &&
        whereTraveledAbroadSouthEastAsia.includes('other'))
    ) {
      await eText({
        id: 'coronaVirusScreen.whereTraveledAbroadOther',
        label: questionLabel(
          'coronaVirusScreen.whereTraveledAbroadOther',
          patient
        ),
        clinicLabel: 'Countries visited in the last 14 days',
        warn: true,
      });
    }
  }

  await eChoice({
    id: 'coronaVirusScreen.contactWithOthers',
    label: questionLabel('coronaVirusScreen.contactWithOthers', patient),
    clinicLabel: 'Recent contact with coronavirus',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        warn: true,
        keyFinding: 'Recent contact with coronavirus',
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'No recent contact with coronavirus',
      },
    ],
  });

  return {};
}: Guide);

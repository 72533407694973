import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { _t } from '../../../i18n';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import withStyles from '@material-ui/core/styles/withStyles';

import colors from '../../theme/colors';

import Key from '../../components/Key';
import { getResult } from '../../../state/drafts/selectors';
import { getPatient } from '../../../state/patients/selectors';
import { addTestCase } from '../../../state/testCases/actions';
import { getUser } from '../../../state/users/selectors';
import { getCurrentTestCaseMeta } from '../../../state/testCases/selectors';

const SaveTestButton = (props: {
  classes: { [string]: string },
  className?: string,
  guideId: string,
}) => {
  const { classes, className, guideId } = props;

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const patient = useSelector(getPatient);
  const result = useSelector(store => getResult(store, guideId));
  const { saving, saveError } = useSelector(getCurrentTestCaseMeta);

  const [justSaved, setJustSaved] = useState(false);
  const [saveResult, setSaveResult] = useState();

  useEffect(() => {
    if (justSaved && !saving) {
      setSaveResult(saveError !== undefined ? 'failure' : 'success');
      const timer = setTimeout(() => {
        setJustSaved(false);
        setSaveResult(undefined);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
    return undefined;
  }, [justSaved, saving, saveError]);

  const handleClick = () => {
    let testCase = {
      guideId,
      hostname: window.location.hostname,
      createdBy: user.email,
      createdAt: new Date().getTime(),
      patient,
      result: result,
    };
    setJustSaved(true);
    // $FlowFixMe
    dispatch(addTestCase(testCase));
  };

  if (!result || Object.keys(result).length === 0) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      disabled={saving}
      onClick={handleClick}
      className={classNames(classes.root, className)}
    >
      {_t('guides.saveTest')}
      {saving ? (
        <CircularProgress size={20} className={classes.saving} />
      ) : saveResult === 'success' ? (
        <DoneIcon
          fontSize="small"
          htmlColor={colors.green_500}
          className={classes.icon}
        />
      ) : saveResult === 'failure' ? (
        <ErrorIcon
          fontSize="small"
          htmlColor={colors.red_400}
          className={classes.icon}
        />
      ) : (
        <Key character="T" onPress={handleClick} className={classes.key} />
      )}
    </Button>
  );
};

export default withStyles(({ spacing }) => ({
  root: {},
  key: {
    marginLeft: spacing(),
  },
  saving: {
    marginLeft: spacing(),
  },
  icon: {
    marginLeft: spacing(),
    width: '20px',
  },
}))(SaveTestButton);

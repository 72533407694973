import type { Guide } from '../../types';

export default (async ({ ask }) => {
  const type = {
    bloodpressure: 'bloodpressure',
    cardiovascular: 'cardiovascular',
    diabetes: 'diabetes',
    lungs: 'lungs',
    cancer: 'cancer',
    neuro: 'neuro',
    blooddisease: 'blooddisease',
    kidney: 'kidney',
    liver: 'liver',
    immunesystem: 'immunesystem',
    rheumatic: 'rheumatic',
    other: 'other',
  };

  const healthIssues = await ask.multipleChoice(
    'Markera de sjukdomar eller hälsoproblem du har, eller har haft.',
    {
      id: 'health-issues',
      description: 'Fler svarsalternativ möjliga',
      optional: true,
      options: [
        {
          value: type.bloodpressure,
          label: 'Högt blodtryck med tablettbehandling',
        },
        {
          value: type.cardiovascular,
          label: 'Hjärt-kärlsjukdom',
        },
        {
          value: type.diabetes,
          label: 'Diabetes',
        },
        {
          value: type.lungs,
          label: 'Lungsjukdom (inkl. astma)',
        },
        {
          value: type.cancer,
          label: 'Cancersjukdom',
        },
        {
          value: type.neuro,
          label: 'Neurologisk sjukdom',
        },
        {
          value: type.blooddisease,
          label: 'Blodsmitta (HIV, Hepatit B, Hepatit C)',
        },
        {
          value: type.kidney,
          label: 'Njurfunktionsnedsättning',
        },
        {
          value: type.liver,
          label: 'Leverfunktionsnedsättning',
        },
        {
          value: type.immunesystem,
          label: 'Nedsatt immunförsvar',
        },
        {
          value: type.rheumatic,
          label: 'Reumatologisk sjukdom',
        },
        {
          value: type.other,
          label: 'Annat',
        },
      ],
    }
  );

  if (healthIssues.length) {
    if (healthIssues.includes(type.bloodpressure)) {
      await ask.choice('När blev du diagnosticerad med högt blodtryck?', {
        options: [
          {
            value: 'lessThan6months',
            label: 'För mindre än 6 månader sedan',
          },
          {
            value: '6to12months',
            label: 'För 6-12 månader sedan',
          },
          {
            value: '1to3years',
            label: 'För 1-3 år sedan',
          },
          {
            value: '4to10years',
            label: 'För 4-10 år sedan',
          },
          {
            value: 'moreThan10years',
            label: 'För mer än 10 år sedan',
          },
        ],
      });
    }

    if (healthIssues.includes(type.cardiovascular)) {
      await ask.multipleChoice('Vilka hjärt-kärlsjukdomar har du?', {
        description: 'Flera svarsalternativ möjliga',
        options: [
          {
            value: 'infarction',
            label: 'Hjärtinfarkt',
          },
          {
            value: 'congestiveHeart',
            label: 'Hjärtsvikt',
          },
          {
            value: 'anginaPectoris',
            label: 'Kärlkramp',
          },
          {
            value: 'atrialFibrillation',
            label: 'Förmaksflimmer',
          },
          {
            value: 'heartValve',
            label: 'Hjärtklaffsfel',
          },
          {
            value: 'stroke',
            label: 'Stroke',
          },
          {
            value: 'bloodClot',
            label: 'Blodpropp som krävt blodförtunnande medicinering',
          },
          {
            value: 'other',
            label: 'Annan hjärt-kärlsjukdom',
          },
        ],
      });
    }

    if (healthIssues.includes(type.diabetes)) {
      const diabetes = await ask.multipleChoice(
        'Vilken typ av diabetes har du?',
        {
          description: 'Flera svarsalternativ möjliga',
          options: [
            {
              value: 'type1',
              label: 'Diabetes typ 1',
            },
            {
              value: 'type2',
              label: 'Diabetes typ 2',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        }
      );

      if (diabetes.includes('type1') || diabetes.includes('type2')) {
        await ask.multipleChoice('Hur behandlas du för din diabetes?', {
          description: 'Flera svarsalternativ möjliga',
          options: [
            {
              value: 'food',
              label: 'Kostbehandling',
            },
            {
              value: 'pills',
              label: 'Tablettbehandling',
            },
            {
              value: 'insuline',
              label: 'Insulinbehandling',
            },
          ],
        });
      }
    }

    if (healthIssues.includes(type.lungs)) {
      await ask.multipleChoice(
        'Vilka lungsjukdomar har du, eller har du haft?',
        {
          description: 'Flera svarsalternativ möjliga',
          options: [
            {
              value: 'astma',
              label: 'Astma',
            },
            {
              value: 'kol',
              label: 'KOL',
            },
            {
              value: 'other',
              label: 'Annan lungsjukdom',
            },
          ],
        }
      );
    }

    if (healthIssues.includes(type.neuro)) {
      await ask.choice(
        'Vilka neurologiska sjukdomar har du, eller har du haft?',
        {
          options: [
            {
              value: 'epilepsy',
              label: 'Epilepsi',
            },
            {
              value: 'ms',
              label: 'MS',
            },
            {
              value: 'parkinson',
              label: 'Parkinson',
            },
            {
              value: 'other',
              label: 'Annan neurologisk sjukdom',
            },
          ],
        }
      );
    }
    if (healthIssues.includes(type.blooddisease)) {
      await ask.multipleChoice('Vilken blodsmitta har du, eller har du haft?', {
        description: 'Flera svarsalternativ möjliga',
        options: [
          {
            value: 'hepB',
            label: 'Hepatit B',
          },
          {
            value: 'hepC',
            label: 'Hepatit C',
          },
          {
            value: 'hiv',
            label: 'HIV',
          },
        ],
      });
    }

    if (healthIssues.includes(type.immunesystem)) {
      await ask.multipleChoice(
        'Vad är anledningen till att du har nedsatt immunförsvar?',
        {
          description: 'Flera svarsalternativ möjliga',
          options: [
            {
              value: 'noSpleen',
              label: 'Jag har ingen mjälte.',
            },
            {
              value: 'immuneDeficiencyDisease',
              label: 'Jag har en diagnosticerad immunbristsjukdom.',
            },
            {
              value: 'medicineImmunesystem',
              label: 'Jag tar läkemedel som försämrar immunförsvaret.',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        }
      );
    }

    await ask.binary('Använder du några läkemedel för tillfället?');

    const penicillin = await ask.choice(
      'Är du allergisk mot penicillin eller annan antibiotika?',
      {
        options: [
          {
            value: 'yesKnows',
            label:
              'Ja, och jag vet vilken sorts penicillin eller antibiotika jag är allergisk mot.',
          },
          {
            value: 'yesDontKnow',
            label:
              'Ja, men jag vet inte vilken sorts penicillin eller antibiotika jag är allergisk mot.',
          },
          {
            value: 'no',
            label: 'Nej',
          },
          {
            value: 'dontKnow',
            label: 'Vet ej',
          },
        ],
      }
    );
    if (penicillin === 'yesKnows') {
      await ask.multipleChoice(
        'Vilken typ av penicillin eller annan antibiotika är du allergisk mot?',
        {
          description: 'Flera svarsalternativ möjliga.',
          options: [
            {
              value: 'kavepenin',
              label: 'Kåvepenin',
            },
            {
              value: 'amoxicillin',
              label: 'Amoxicillin',
            },
            {
              value: 'heracillin',
              label: 'Heracillin',
            },
            {
              value: 'selexid',
              label: 'Selexid',
            },
            {
              value: 'spektramox',
              label: 'Spektramox',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        }
      );
    }
    if (penicillin === 'yesDontKnow') {
      await ask.multipleChoice(
        'Hur reagerade du på penicillinet/antibiotikan?',
        {
          description: 'Flera svarsalternativ möjliga.',
          options: [
            {
              value: 'severe',
              label:
                'Allvarlig reaktion med t.ex. andningssvårigheter och svullnad i mun och svalg',
            },
            {
              value: 'light',
              label: 'Lättare besvär såsom hudutslag och klåda',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        }
      );
    }

    await ask.binary(
      'Förutom penicillin eller annan antibiotika, finns det andra läkemedel som du vet att du är allergisk mot, eller har fått biverkningar av?'
    );

    await ask.choice('Är du rökare?', {
      options: ['Ja', 'Nej', 'Före detta'],
    });
  }

  return {};
}: Guide);

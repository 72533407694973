import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/lib/integration/react';
import thunk from 'redux-thunk';
import { Provider as ReduxProvider } from 'react-redux';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import fbConfig from './firebaseConfig';

// $FlowFixMe
import firebase from 'firebase/app';
// $FlowFixMe
import 'firebase/auth';
// $FlowFixMe
import 'firebase/firestore';

import reducer from './state/reducer';

import App from './web/App';

firebase.initializeApp(fbConfig);
firebase.firestore();

// TODO should we make this always on?
const composeEnhancers =
  (window.location.hostname === 'localhost' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persists = [
  { name: 'drafts', keys: ['byId'] },
  { name: 'patients', keys: ['patient'] },
];
// $FlowFixMe
const store = createStore(
  persistReducer(
    {
      key: 'root',
      storage,
      whitelist: persists.map(({ name }) => name),
      transforms: persists.map(({ name, keys }) =>
        createTransform(
          inboundState => {
            const data = {};
            keys.forEach(key => (data[key] = inboundState[key]));
            return data;
          },
          outboundState => {
            const data = {};
            keys.forEach(key => (data[key] = outboundState[key]));
            return data;
          },
          { whitelist: [name] }
        )
      ),
    },
    reducer
  ),
  composeEnhancers(applyMiddleware(thunk.withExtraArgument(getFirebase)))
);
const persistor = persistStore(store);
const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </ReduxProvider>,
  window.document.getElementById('root')
);

import type { Guide } from './../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import ds from './decisionSupport';
import { union, matchOne } from '../common/arrayUtils';
import fever from './fever';
import breathing from './breathing';
import phlegm from './phlegm';
import medicine from './medicine';
import vomiting from './vomiting';

import {
  keyFindingLabelVomitingNotKeepFluids,
  keyFindingLabelVomitingKeepFluids,
} from './triage';

// These keyfindings is used externally, therefore constants.
export const keyFindingLabelCoughLessThanAweek = 'Cough <1 week';
export const keyFindingLabelCoughThreeWeeks = 'Cough >3 weeks';
export const keyFindingLabelCough1to3weeks = 'Cough 1-3 weeks';
export const keyFindingLabelContinuousCough = 'Continuous cough';

export default (async args => {
  const {
    ask,
    patient: { age },
    decisionSupport,
    scores,
    exports,
  } = args;

  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const bumpWarningScore = () => {
    scores.increase('warning');
  };

  const coughduration = await eChoice({
    id: 'coughChild.coughduration',
    label: 'How long has your child had a cough?',
    clinicLabel: 'Cough duration',
    options: [
      {
        label: 'Less than a week',
        value: 'Lessthanaweek',
        keyFinding: keyFindingLabelCoughLessThanAweek,
      },
      {
        label: '1–3 weeks',
        value: '1–3weeks',
        keyFinding: keyFindingLabelCough1to3weeks,
      },
      {
        label: 'More than 3 weeks',
        value: 'Morethan3weeks',
        keyFinding: keyFindingLabelCoughThreeWeeks,
        hide: true,
      },
    ],
  });

  if (coughduration === 'Morethan3weeks') {
    await eText({
      id: 'coughChild.coughduration',
      label: 'How long has your child had a cough?',
      clinicLabel: 'Cough duration',
      warn: true,
    });

    const continouscough = await eChoice({
      id: 'coughChild.continouscough',
      label: 'Has your child been coughing every day?',
      clinicLabel: 'Continous cough',
      options: [
        {
          label: 'Yes, everyday',
          value: 'Yes,everyday',
          keyFinding: keyFindingLabelContinuousCough,
        },
        {
          label: 'No, it got better then came back',
          value: 'No,itgotbetterthencameback',
          keyFinding: 'Intermittent cough',
          ds: ds.ongoingCough,
        },
      ],
    });

    if (continouscough === 'No,itgotbetterthencameback') {
      await eText({
        id: 'coughChild.durationofcurrentcough',
        label: 'How long has the cough lasted this time?',
        clinicLabel: 'Duration of current cough',
        options: [{ label: 'Free text', value: 'Freetext' }],
      });
    }
  }

  const descriptionofcough = await eChoice({
    id: 'coughChild.descriptionofcough',
    label: "What best describes your child's cough?",
    clinicLabel: 'Description of cough',
    isMultipleChoice: true,
    options: [
      { label: 'Dry', value: 'Dry', keyFinding: 'Dry cough' },
      { label: 'Tickly', value: 'Tickly', keyFinding: 'Tickly cough' },
      {
        label: 'Wet or fruity',
        value: 'Wetorfruity',
        keyFinding: 'Wet cough',
        warn: true,
      },
      {
        label: 'Barking',
        value: 'Barking',
        keyFinding: 'Barking cough',
        warn: true,
        urgent: true,
        ds: ds.croup,
      },
      {
        label: 'Uncontrollable attacks of coughing',
        value: 'Uncontrollableattacksofcoughing',
        keyFinding: 'Paroxysmal cough',
        warn: true,
        ds: ds.whoopingCough,
      },
    ],
  });

  if (Array.isArray(descriptionofcough)) {
    scores.increase(
      'warning',
      union(descriptionofcough, [
        'Wetorfruity',
        'Barking',
        'Uncontrollableattacksofcoughing',
      ]).length
    );

    if (descriptionofcough.includes('Wetorfruity')) {
      if (matchOne([coughduration], ['1–3weeks', 'Morethan3weeks'])) {
        decisionSupport.push(ds.wetCoughUrgent);
      } else {
        decisionSupport.push(ds.wetCough);
      }
    }
  }

  const coughexacerbationfactors = await eChoice({
    id: 'coughChild.coughexacerbationfactors',
    label: "When is your child's cough worse?",
    clinicLabel: 'Cough exacerbation factors',
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'All the time',
        value: 'Allthetime',
        keyFinding: 'All the time',
      },
      {
        label: 'Morning',
        value: 'morning',
        keyFinding: 'Cough worse in the morning',
      },
      {
        label: 'Night',
        value: 'night',
        keyFinding: 'Cough worse at night',
      },
      {
        label: 'During or after exercising',
        value: 'duringorafterexercising',
        keyFinding: 'Cough when exercising',
      },
      {
        label: 'Cold weather',
        value: 'Coldweather',
        keyFinding: 'Cold weather',
      },
      {
        label: 'Summer months',
        value: 'Summermonths',
        keyFinding: 'Summer months',
        ds: ds.hayfever,
      },
      {
        label: 'After eating',
        value: 'eaftereating',
        keyFinding: 'Cough when after eating',
        ds: ds.gord,
      },
    ],
  });

  if (Array.isArray(coughexacerbationfactors)) {
    if (coughexacerbationfactors.includes('Allthetime')) {
      scores.increase('warning');
    }

    scores.increase(
      'airways',
      union(coughexacerbationfactors, [
        'morning',
        'night',
        'duringorafterexercising',
        'Coldweather',
      ]).length
    );

    if (age < 2 && coughexacerbationfactors.includes('eaftereating')) {
      await eChoice({
        id: 'coughChild.archingbackorirritableaftereating',
        label: 'Does your child arch their back or get irritable after eating?',
        clinicLabel: 'Arching back or irritable after eating',
        options: [
          {
            label: 'Yes',
            value: 'yes',
            keyFinding: 'Irritable after eating',
            warn: true,
          },
          {
            label: 'No',
            value: 'no',
            keyFinding: 'Doesnt get irritable after eating',
          },
        ],
      });
    }
  }

  const coughstartedwithacold = await eChoice({
    id: 'coughChild.coughstartedwithacold',
    label: "Did your child's cough start with a cold or runny nose?",
    clinicLabel: 'Cough started with a cold',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Cough started with a cold' },
      { label: 'No', value: 'no', keyFinding: 'Cough didnt start with a cold' },
    ],
  });

  if (coughstartedwithacold === 'no') {
    scores.increase('feverPain');
  }

  const feverinlast24hours = await eChoice({
    id: 'coughChild.feverinlast24hours',
    label: 'Has your child had a fever in the last 24 hours?',
    clinicLabel: 'Fever in last 24 hours',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Fever <24 hours',
        warn: true,
        urgent: true,
      },
      { label: 'No', value: 'no', keyFinding: 'No fever <24 hours' },
    ],
  });

  if (feverinlast24hours === 'yes') {
    const feverResult = await fever(args);
    if (feverResult.abort) {
      return feverResult;
    }
  }

  await ask.info({
    label: 'A fever can be sign of a serious illness',
    description: `
Your child needs urgent medical attention if they have a fever and are:

- Very unwell
- Feeding/drinking less than half of normal
- Vomiting
- Not breathing normally
- Not passing urine every 4 hours

If it is outside of normal working hours, or it is the weekend, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.
`,
  });

  const changeinbreathing = await eChoice({
    id: 'coughChild.changeinbreathing',
    label: "Has your child's breathing changed?",
    clinicLabel: 'Change in breathing',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Change in breathing',
        hide: true,
      },
      { label: 'No', value: 'no', keyFinding: 'No change in breathing' },
    ],
  });

  if (changeinbreathing === 'yes') {
    const breathingResult = await breathing(args);
    if (breathingResult.abort) {
      return breathingResult;
    }
  }

  const productivecough = await eChoice({
    id: 'coughChild.productivecough',
    label: 'Is your child coughing up phlegm or mucus?',
    clinicLabel: 'Productive cough',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Productive cough',
        warn: true,
        onSelected: bumpWarningScore,
        hide: true,
      },
      {
        label: 'No, but I can hear it',
        value: 'NobutIcanhearit',
        keyFinding: 'Parent can hear phelgm',
        warn: true,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Non productive cough',
      },
    ],
  });

  if (productivecough === 'yes') {
    const phlegmResult = await phlegm(args);
    if (phlegmResult.abort) {
      return phlegmResult;
    }
  }
  /*
  const isvomiting = await eChoice({
    id: 'coughChild.vomiting',
    label: 'Has your child vomited?',
    clinicLabel: 'Vomiting',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Vomited', hide: true },
      { label: 'No', value: 'no', keyFinding: 'Not vomited' },
    ],
  });
*/

  const isvomiting = !!exports.find(s =>
    [
      keyFindingLabelVomitingNotKeepFluids,
      keyFindingLabelVomitingKeepFluids,
    ].includes(s.value)
  );

  if (isvomiting) {
    const vomitingResult = await vomiting(args);
    if (vomitingResult.abort) {
      return vomitingResult;
    }
  }

  const triedmedicaitonforcough = await eChoice({
    id: 'coughChild.triedmedicaitonforcough',
    label: 'Has your child tried any medication to help with their cough?',
    clinicLabel: 'Tried medicaiton for cough',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Tried medication for cough',
        hide: true,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Not tried medication for cough',
      },
    ],
  });

  if (triedmedicaitonforcough === 'yes') {
    const medicineResult = await medicine(args);
    if (medicineResult.abort) {
      return medicineResult;
    }
  }

  return {};
}: Guide);

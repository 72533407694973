import type { Guide } from '../../types';

import nutrition from './nutrition/';
import exercise from './exercise/';
import tobacco from './tobacco/';
import audit from './audit';
import drugs from './drugs';
import isi from './isi';
import had from './had';

export default (async args => {
  const { ask, significant } = args;

  const expectations = {
    type: 'text',
    id: 'healthCheck.expectations',
    label: 'Vilka är dina förväntningar inför din hälsokontroll?',
  };

  const expectationsAnswer = await ask(expectations);

  significant.push({
    ...expectations,
    label: 'Förväntningar inför hälsokontroll',
    value: expectationsAnswer,
  });

  await nutrition(args);
  await exercise(args);
  await tobacco(args);
  await audit(args);
  await drugs(args);
  await isi(args);
  await had(args);

  const generalHealthPerception = {
    type: 'text',
    id: 'healthCheck.generalHealthPerception',
    label: 'Hur upplever du din allmänna hälsa?',
  };

  const generalHealthPerceptionAnswer = await ask(generalHealthPerception);

  significant.push({
    ...generalHealthPerception,
    label: 'Upplevelse av allmän hälsa',
    value: generalHealthPerceptionAnswer,
  });

  const generalHealthOther = {
    type: 'text',
    id: 'healthCheck.generalHealthOther',
    label: 'Finns det något annat du vill förmedla angående din hälsa?',
  };

  const generalHealthOtherAnswer = await ask(generalHealthOther);

  significant.push({
    ...generalHealthOther,
    label: 'Övrigt',
    value: generalHealthOtherAnswer,
  });

  return {};
}: Guide);

import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { _t } from '../../../i18n';

import { clearDraft } from '../../../state/drafts/actions';

import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

import Key from '../../components/Key';

class ClearButton extends React.Component<{
  guideId: string,
  clearDraft: typeof clearDraft,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { guideId, clearDraft, classes, className } = this.props;

    const fn = () => clearDraft(guideId);

    return (
      <Button
        variant="outlined"
        onClick={fn}
        className={classNames(classes.root, className)}
      >
        {_t('guides.clear')}

        <Key character="C" onPress={fn} className={classes.key} />
      </Button>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {},

  key: {
    marginLeft: spacing(),
  },
}))(
  connect(
    undefined,
    { clearDraft }
  )(ClearButton)
);

import type { Guide } from '../../types';

import aborts from './aborts';

import ds from './decisionSupport';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const {
    ask,
    decisionSupport,
    exports,
    patient: { age },
    significant,
  } = args;

  await ask.info(
    'Before we begin, we need to ask you a few questions about your child to make sure it is safe to treat them online...'
  );

  const pmh = {
    type: 'multipleChoice',
    id: 'triage.pmh',
    label: 'Does your child have any of the conditions below?',
    description: 'More than one answer can be selected.',
    options: [
      {
        label: 'Cancer - current or previous',
        value: 'cancer',
      },
      {
        label: 'Cerebral Palsy',
        value: 'cerebalPalsy',
      },
      {
        label: 'Congenital heart disease',
        value: 'congenitalHeartDisease',
      },
      {
        label: 'Cystic fibrosis',
        value: 'cysticFibrosis',
      },
      {
        label: 'Sickle cell anaemia',
        value: 'sickleCellAnemia',
      },
    ],
    optional: true,
  };

  const pmhAnswer = await ask(pmh);

  if (pmhAnswer.length === 0) {
    significant.push({
      ...pmh,
      label: 'PMH',
      description: '',
      value: ['None'],
    });
  } else {
    significant.push({
      ...pmh,
      label: 'PMH',
      description: '',
      value: pmhAnswer,
      warn: pmhAnswer.length !== 0,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      cancer: 'Cancer - current or previous',
      cerebalPalsy: 'Cerebral Palsy',
      congenitalHeartDisease: 'Congenital heart disease',
      cysticFibrosis: 'Cystic fibrosis',
      sickleCellAnemia: 'Sickle cell anaemia',
    }[pmhAnswer],
  });

  if (age < 11 && pmhAnswer.length !== 0) {
    return { abort: aborts.pmh };
  } else if (
    pmhAnswer.includes('cancer') ||
    pmhAnswer.includes('congenitalHeartDisease') ||
    pmhAnswer.includes('sickleCellAnemia')
  ) {
    return { abort: aborts.pmh };
  }

  const signsOfSepsis = {
    type: 'multipleChoice',
    id: 'triage.signsOfSepsis',
    label: 'Does your child have any of the symptoms below right now?',
    description: 'More than one answer can be selected.',
    options: [
      {
        label: 'A new rash which does not fade when rolling a glass over it',
        value: 'nonFadingRash',
      },
      {
        label: 'A fever',
        value: 'fever',
      },
      {
        label: 'Drowsiness',
        value: 'drowsiness',
      },
      {
        label: 'Vomiting and not able to keep fluids down',
        value: 'vomiting',
      },
      {
        label: 'Not passed urine for more than 8 hours',
        value: 'noUrineIn8Hours',
      },
      {
        label: 'Blue skin, lips or a tongue',
        value: 'blueSkinLipsOrTongue',
      },
      {
        label: 'Swollen lips, mouth or tongue',
        value: 'swollenLipsMouthOrTongue',
      },
      {
        label: 'Wheezing or difficulty breathing ',
        value: 'difficultyBreathing',
      },
    ],
    optional: true,
  };

  const signsOfSepsisAnswer = await ask(signsOfSepsis);

  if (signsOfSepsisAnswer.length === 0) {
    significant.push({
      ...signsOfSepsis,
      label: 'Signs of sepsis or anaphylaxis',
      description: '',
      value: ['None'],
    });
  } else {
    significant.push({
      ...signsOfSepsis,
      label: 'Signs of sepsis or anaphylaxis',
      description: '',
      value: signsOfSepsisAnswer,
      warn: signsOfSepsisAnswer.length !== 0,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      nonFadingRash: 'Non blanching rash',
      fever: 'Fever',
      drowsiness: 'Drowsiness',
      vomiting: 'Vomiting and unable to keep fluids down',
      noUrineIn8Hours: 'Not passed urine for more than 8 hours',
      blueSkinLipsOrTongue: 'Blue skin, lips or a tongue',
      swollenLipsMouthOrTongue: 'Swollen lips, mouth or tongue',
      difficultyBreathing: 'Wheezing or shortness of breath',
    }[signsOfSepsisAnswer],
  });

  if (
    signsOfSepsisAnswer.includes('swollenLipsMouthOrTongue') ||
    signsOfSepsisAnswer.includes('difficultyBreathing')
  ) {
    return { abort: aborts.anaphylaxis };
  } else if (signsOfSepsisAnswer.length !== 0) {
    return { abort: aborts.sepsis };
  } else if (signsOfSepsisAnswer.includes('fever')) {
    return { abort: aborts.fever };
  }

  const drinking = {
    type: 'choice',
    id: 'triage.drinking',
    label: 'How much has your child drank in the last 24 hours?',
    options: [
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'Sightly less than normal',
        value: 'slightlyLessThanNormal',
      },
      {
        label: 'Less than half of normal',
        value: 'lessThanHalfOfNormal',
      },
      {
        label: 'Nothing in the last 24 hours',
        value: 'nothing',
      },
    ],
  };

  const drinkingAnswer = await ask(drinking);

  significant.push({
    ...drinking,
    label: 'Fluid intake in last 24 hours',
    description: '',
    value: drinkingAnswer,
    warn:
      drinkingAnswer.includes('slightlyLessThanNormal') ||
      drinkingAnswer.includes('lessThanHalfOfNormal') ||
      drinkingAnswer.includes('nothing'),
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      normal: 'Normal oral intake in last 24 hours',
      slightlyLessThanNormal: 'Oral intake slightly less than normal',
      lessThanHalfOfNormal: 'Oral intake less than half of normal',
      none: 'No oral intake in last 24 hours',
    }[drinkingAnswer],
  });

  if (age < 3 && drinkingAnswer.includes('lessThanHalfOfNormal')) {
    return { abort: aborts.dehydrationUrgent };
  } else if (age >= 3 && drinkingAnswer.includes('lessThanHalfOfNormal')) {
    decisionSupport.push(ds.riskOfDehydrationFluidIntake);
  }

  if (drinkingAnswer.includes('nothing')) {
    return { abort: aborts.dehydration };
  }

  const lastWee = {
    type: 'choice',
    id: 'triage.lastWee',
    label: 'When did they last wee?',
    options: [
      {
        label: 'Less than 4 hours ago',
        value: 'lessThanFourHoursAgo',
      },
      {
        label: 'Between 4-8 hours ago',
        value: 'betweenFourToEightHoursAgo',
      },
      {
        label: 'More than 8 hours ago',
        value: 'moreThanEightHoursAgo',
      },
    ],
  };

  const lastWeeAnswer = await ask(lastWee);

  significant.push({
    ...lastWee,
    label: 'Last passed urine',
    value: lastWeeAnswer,
    warn:
      lastWeeAnswer.includes('betweenFourToEightHoursAgo') ||
      lastWeeAnswer.includes('moreThanEightHoursAgo'),
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      lessThanFourHoursAgo: 'Last passed urine < 4hours ago',
      betweenFourToEightHoursAgo: 'Passed urine 4-8 hours ago',
      moreThanEightHoursAgo: 'Passed urine > 8 hours ago',
    }[lastWeeAnswer],
  });

  if (age < 3 && lastWeeAnswer.includes('betweenFourToEightHoursAgo')) {
    return { abort: aborts.dehydrationUrgent };
  } else if (age >= 3 && lastWeeAnswer.includes('betweenFourToEightHoursAgo')) {
    decisionSupport.push(ds.riskOfDehydrationFluidOutput);
  }
  if (lastWeeAnswer.includes('moreThanEightHoursAgo')) {
    return { abort: aborts.dehydration };
  }

  const diarrhoea = {
    type: 'binary',
    id: 'triage.diarrhoea',
    label: 'Does your child have diarrhoea?',
  };

  const diarrhoeaAnswer = await ask(diarrhoea);

  if (diarrhoeaAnswer === 'yes') {
    significant.push({
      ...diarrhoea,
      label: 'Diarrhoea',
      value: diarrhoeaAnswer,
      warn: ['yes'],
    });

    decisionSupport.push(ds.diarrhoea);
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Diarrhoea',
      no: 'No diarrhoea',
    }[diarrhoeaAnswer],
  });

  const viralSymptoms = {
    type: 'binary',
    id: 'triage.viralSymptoms',
    label:
      'Does your child have any common cold symptoms, like a cough or runny/ blocked nose?',
  };

  const viralSymptomsAnswer = await ask(viralSymptoms);

  if (viralSymptomsAnswer === 'yes') {
    significant.push({
      ...viralSymptoms,
      label: 'Cold/cough type symptoms',
      value: viralSymptomsAnswer,
      warn: ['yes'],
    });

    decisionSupport.push(ds.viralSymptoms);
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Has cough/ coryzal symptom',
      no: 'Absence of cough/ coryzal symptoms',
    }[viralSymptomsAnswer],
  });

  const fever = {
    type: 'binary',
    id: 'triage.fever',
    label: 'Has your child had a temperature in the past 24 hours?',
  };

  const feverAnswer = await ask(fever);

  significant.push({
    ...fever,
    label: 'Fever in past 24 hours',
    value: feverAnswer,
    warn: ['yes'],
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Fever in past 24 hours',
      no: 'No fever in past 24 hours',
    }[feverAnswer],
  });

  if (feverAnswer.includes('yes')) {
    return { abort: aborts.feverUrgent };
  }

  const feverInfo = {
    type: 'choice',
    label: '',
    description:
      '**A fever can be sign of a serious illness**\n\nYour child needs urgent medical attention if:\n\n* They have a red, sore, rash which is spreading\n\n* Feeding/drinking less than half of normal\n\n* Vomiting and not able to keep fluids down\n\n* Not breathing normally\n\n* Not passing urine every 4 hours\n\n* Very unwell\n\nIf it is outside of normal working hours, or it is the weekend, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.',
    options: [{ label: 'I understand', value: 'agree' }],
  };

  await ask(feverInfo);

  return {};
}: Guide);

import type { Guide } from '../../types';

import ds from './decisionSupport';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const { ask, decisionSupport, exports, significant } = args;

  const nbrOfVerrucas = {
    type: 'number',
    id: 'nbrOfVerrucas',
    label: 'How many verrucas (or warts) does your child have?',
  };

  const nbrOfVerrucasAnswer = await ask(nbrOfVerrucas);

  significant.push({
    ...nbrOfVerrucas,
    label: 'Number of verrucas/ warts',
    value: nbrOfVerrucasAnswer,
  });

  const verrucaFirstAppeared = {
    type: 'text',
    id: 'verrucaFirstAppeared',
    label: "When did you first notice your child's verruca (or wart)?",
  };

  const verrucaFirstAppearedAnswer = await ask(verrucaFirstAppeared);

  significant.push({
    ...verrucaFirstAppeared,
    label: 'Duration of verruca/ wart',
    value: verrucaFirstAppearedAnswer,
  });

  const verrucaLocation = {
    type: 'multipleChoice',
    id: 'verrucaLocation',
    label: "Where is your child's verruca (or wart)?",
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Sole of foot', value: 'soleOfFoot' },
      { label: 'Palm of hand', value: 'palmOfHand' },
      { label: 'Finger', value: 'finger' },
      { label: 'Face', value: 'face' },
      { label: 'Other', value: 'other' },
    ],
  };

  const verrucaLocationAnswer = await ask(verrucaLocation);

  if (!verrucaLocationAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...verrucaLocation,
      label: 'Location of verruca/ wart',
      description: '',
      value: verrucaLocationAnswer.filter(answer => answer !== 'other'),
      warn: verrucaLocationAnswer.includes('face'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      soleOfFoot: 'Verucca/ wart on sole of foot',
      palmOfHand: 'Verucca/ wart on palm of hand',
      finger: 'Verucca/ wart on finger',
      face: 'Verucca/ wart on face',
    }[verrucaLocationAnswer],
  });

  if (verrucaLocationAnswer.includes('face')) {
    decisionSupport.push(ds.facialVerruca);
  }

  if (verrucaLocationAnswer.includes('other')) {
    const verrucaLocationOther = {
      type: 'text',
      id: 'verrucaLocationOther',
      label: 'Where exactly is the verruca (or wart)?',
    };

    const verrucaLocationOtherAnswer = await ask(verrucaLocationOther);

    significant.push({
      ...verrucaLocationOther,
      label: 'Location of verruca/ wart',
      value: verrucaLocationOtherAnswer,
    });
  }

  const verrucaTreatment = {
    type: 'multipleChoice',
    id: 'verrucaTreatment',
    label: 'What have you previously tried for the verruca (or wart)?',
    description: 'More than one answer can be selected.',
    options: [
      {
        label: 'Home remedy e.g. duct tape, apple cider vinegar',
        value: 'homeRemedy',
      },
      {
        label: 'Cream/gel e.g. Bazuka',
        value: 'chemistTreatment',
      },
      {
        label: 'Home freezing treatment',
        value: 'homeFreezeTreatment',
      },
      {
        label: 'Freezing treatment by a doctor',
        value: 'doctorFreezeTreatment',
      },
      { label: 'Other', value: 'other' },
    ],
    optional: true,
  };

  const verrucaTreatmentAnswer = await ask(verrucaTreatment);

  if (!verrucaLocationAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...verrucaTreatment,
      label: 'Previous verucca/ wart treatment',
      description: '',
      value: verrucaTreatmentAnswer.filter(answer => answer !== 'other'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      homeRemedy: 'Home remedy eg duct tape, apple cider vinegar',
      chemistTreatment: 'Verruca/ wart treatments from the chemist eg Bazuka',
      homeFreezeTreatment: 'Verruca/ wart home freeze treatment',
      doctorFreezeTreatment: 'Freezing treatment done be a doctor',
      other: 'Other',
    }[verrucaTreatmentAnswer],
  });

  if (verrucaTreatmentAnswer.includes('other')) {
    const verrucaOtherTreatment = {
      type: 'text',
      id: 'verrucaOtherTreatment',
      label: 'Please tell us what else you tried.',
    };

    const verrucaOtherTreatmentAnswer = await ask(verrucaOtherTreatment);

    significant.push({
      ...verrucaOtherTreatment,
      label: 'Other verruca/ wart treatments tried',
      value: verrucaOtherTreatmentAnswer,
    });
  }

  if (verrucaTreatmentAnswer.length === 1) {
    const verrucaTreatmentDuration = {
      type: 'text',
      id: 'verrucaTreatmentDuration',
      label: 'How long did you use this this treatment for?',
    };

    const verrucaTreatmentDurationAnswer = await ask(verrucaTreatmentDuration);

    significant.push({
      ...verrucaTreatmentDuration,
      label: 'How long previous verruca/ wart treatment used for',
      value: verrucaTreatmentDurationAnswer,
    });

    const verrucaTreatmentFrequency = {
      type: 'text',
      id: 'verrucaTreatmentFrequency',
      label: 'How often did you apply this treatment?',
    };

    const verrucaTreatmentFrequencyAnswer = await ask(
      verrucaTreatmentFrequency
    );

    significant.push({
      ...verrucaTreatmentFrequency,
      label: 'How often previous verucca/ wart treatment used',
      value: verrucaTreatmentFrequencyAnswer,
    });
  } else if (verrucaTreatmentAnswer.length > 1) {
    const verrucaMultipleTreatmentDuration = {
      type: 'text',
      id: 'verrucaMultipleTreatmentDuration',
      label: 'How long did you use each of these treatments for?',
    };

    const verrucaMultipleTreatmentDurationAnswer = await ask(
      verrucaMultipleTreatmentDuration
    );

    significant.push({
      ...verrucaMultipleTreatmentDuration,
      label: 'How long previous verruca/ wart treatments used for',
      value: verrucaMultipleTreatmentDurationAnswer,
    });

    const verrucaMultipleTreatmentFrequency = {
      type: 'text',
      id: 'verrucaMultipleTreatmentFrequency',
      label: 'How often did you apply each of the treatments for?',
    };

    const verrucaMultipleTreatmentFrequencyAnswer = await ask(
      verrucaMultipleTreatmentFrequency
    );

    significant.push({
      ...verrucaMultipleTreatmentFrequency,
      label: 'How often previous verucca/ wart treatments used',
      value: verrucaMultipleTreatmentFrequencyAnswer,
    });
  }

  const verrucaStatus = {
    type: 'choice',
    id: 'verrucaStatus',
    label: 'Has the verruca (or wart) changed?',
    options: [
      { label: 'No change', value: 'noChange' },
      { label: 'Got better', value: 'better' },
      { label: 'Got worse', value: 'worse' },
    ],
  };

  const verrucaStatusAnswer = await ask(verrucaStatus);

  significant.push({
    ...verrucaStatus,
    label: 'Verruca/ wart changed',
    value: verrucaStatusAnswer,
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      noChange: 'Verruca/ wart remained the same',
      better: 'Verruca/ wart got better',
      worse: 'Verruca/ wart got worse',
    }[verrucaStatusAnswer],
  });

  const verrucaCausingOtherSymptoms = {
    type: 'binary',
    id: 'verrucaCausingOtherSymptoms',
    label: 'Is the verucca (or wart) causing any other symptoms?',
  };

  const verrucaCausingOtherSymptomsAnswer = await ask(
    verrucaCausingOtherSymptoms
  );

  if (verrucaCausingOtherSymptomsAnswer === 'no') {
    significant.push({
      ...verrucaCausingOtherSymptoms,
      label: 'Verruca/ wart causing symptoms',
      value: verrucaCausingOtherSymptomsAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Verucca/ wart causing symptoms',
      no: 'Verruca/ wart not causing symptoms',
    }[verrucaCausingOtherSymptomsAnswer],
  });

  if (verrucaCausingOtherSymptomsAnswer === 'yes') {
    const verrucaCausingOtherSymptomsWhat = {
      type: 'multipleChoice',
      id: 'verrucaCausingOtherSymptomsWhat',
      label: 'Is the verruca (or wart) causing any of the symtoms below?',
      description: 'More than one answer can be selected.',
      options: [
        { label: 'Pain', value: 'pain' },
        { label: 'Itching', value: 'itch' },
        { label: 'Bleeding', value: 'bleeding' },
        { label: 'Other', value: 'other' },
      ],
      optional: true,
    };

    const verrucaCausingOtherSymptomsWhatAnswer = await ask(
      verrucaCausingOtherSymptomsWhat
    );

    if (
      !verrucaCausingOtherSymptomsWhatAnswer.every(answer => answer === 'other')
    ) {
      significant.push({
        ...verrucaCausingOtherSymptomsWhat,
        label: 'Verruca/ wart causing symptoms',
        description: '',
        value: verrucaCausingOtherSymptomsWhatAnswer.filter(
          answer => answer !== 'other'
        ),
      });
    }

    if (verrucaCausingOtherSymptomsWhatAnswer.includes('bleeding')) {
      decisionSupport.push(ds.bleedingVerruca);
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        pain: 'Verruca/ wart causing pain',
        itch: 'Verruca/ wart is itchy',
        bleeding: 'Verruca/ wart is bleeding',
        other: 'Other',
      }[verrucaCausingOtherSymptomsWhatAnswer],
    });

    if (verrucaCausingOtherSymptomsWhatAnswer.includes('other')) {
      const verrucaCausingOtherSymptomsOther = {
        type: 'text',
        id: 'verrucaCausingOtherSymptomsOther',
        label: 'Please tell us what symptoms the verruca (or wart) is causing.',
      };

      const verrucaCausingOtherSymptomsOtherAnswer = await ask(
        verrucaCausingOtherSymptomsOther
      );

      significant.push({
        ...verrucaCausingOtherSymptomsOther,
        label: 'Verruca/ wart causing symptoms',
        value: verrucaCausingOtherSymptomsOtherAnswer,
      });
    }
  }

  const verrucaHelpWith = {
    type: 'multipleChoice',
    id: 'verrucaHelpWith',
    label: "How can we help with your child's verruca (or wart)?",
    description: 'More than one answer can be selected.',
    options: [
      { label: "Advice about my child's verruca (or wart)", value: 'advice' },
      {
        label: "Treatment for my child's verruca (or wart)",
        value: 'treatment',
      },
      {
        label: 'I would like to know about freezing therapy',
        value: 'freezingTreatment',
      },
      { label: 'Other', value: 'other' },
    ],
  };

  const verrucaHelpWithAnswer = await ask(verrucaHelpWith);

  significant.push({
    ...verrucaHelpWith,
    label: "Aim of today's visit?",
    description: '',
    value: verrucaHelpWithAnswer,
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      advice: 'Advice on what to do for the verruca/ wart',
      treatment: "Treatment for my child's verruca/ wart",
      freezingTreatment:
        'I want them to having freezing treatment for their verruca/ wart',
    }[verrucaHelpWithAnswer],
  });

  if (verrucaHelpWithAnswer.includes('other')) {
    const verrucaHelpWithOther = {
      type: 'text',
      id: 'verrucaHelpWithOther',
      label: "What would you like from today's visit?",
    };

    const verrucaHelpWithOtherAnswer = await ask(verrucaHelpWithOther);

    significant.push({
      ...verrucaHelpWithOther,
      value: verrucaHelpWithOtherAnswer,
    });
  }

  const photoVerruca = await ask.upload(
    `We'd like you to upload a photo of your child's verruca (or wart) to help the doctor with their diagnosis.`,
    {
      description: `
          You can use a mobile phone camera to take the photo.\n\nRest assured, your photos will be kept confidential and will only be added to your medical record.
        `,
      optional: true,
    }
  );

  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photoVerruca,
  });

  return {};
}: Guide);

import type { Guide } from '../../types';

export default (async args => {
  const { ask } = args;

  await ask({
    label: 'Upplever du att du fått relevant hjälp?',
    type: 'choice',
    id: 'relevant.help',
    options: {
      no: 'Inte alls',
      partially: 'Till viss del',
      mostly: 'Till stor del',
      yes: 'I högsta grad',
    },
  });

  await ask({
    label: 'Hur nöjd är du med bemötandet?',
    type: 'choice',
    id: 'satisfaction.treatment',
    options: {
      no: 'Inte alls',
      partially: 'Till viss del',
      mostly: 'Till stor del',
      yes: 'I högsta grad',
    },
  });

  await ask({
    label: 'Hur lätt var det att söka vård hos Min Doktor?',
    type: 'choice',
    id: 'satisfaction.treatment',
    options: {
      veryHard: 'Mycket svårt',
      quiteDifficult: 'Ganska svårt',
      prettyEasy: 'Ganska lätt',
      veryEasy: 'Mycket lätt',
    },
  });

  await ask({
    label: 'Får vi kontakta dig?',
    type: 'multipleChoice',
    id: 'contact.request',
    optional: true,
    options: {
      contactYes:
        'Ja, jag kan tänka mig att bli kontaktad av Min Doktor för att besvara fler frågor.',
    },
  });
  return {};
}: Guide);

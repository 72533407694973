import type { Export } from '../../types';

export default (exports: Export[]) => {
  exports.push({ id: 'presentingComplaint', value: "Med3 Doctor's Statement" });
  exports.push({
    id: 'snomedCode',
    value: "1321000000100 | MED3 - doctor's statement",
  });
  exports.push({
    id: 'diagnosis.options',
    value: [
      'Cardiovascular problem',
      'Respiratory problem',
      'Gastrointestinal problem',
      'Neurological problem',
      'Endocrine problem',
      'Eye problem',
      'ENT problem',
      'Musculoskeletal problem',
      'Dermatology problem',
      'Renal / Urinary problem',
      'Gynaecological / Genital problem',
      'Male urological / Genital problem',
      'Mental illness',
      'Other',
    ],
  });
  exports.push({
    id: 'investigationsRequested.options',
    value: ['None'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Self-care',
      'Fit note completed',
      'Routine GP appointment',
      'Urgent GP appointment',
    ],
  });
  exports.push({
    id: 'actionNeeded.options',
    value: ['None'],
  });
};

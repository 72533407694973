import isFunction from 'lodash/isFunction';

import type { Guide } from '../guides/types';

export default (
  guides: *,
  fn?: (guide: Guide, country: string, id: string, version?: string) => Guide
): { [string]: Guide } => {
  const guideById = {};

  const add = (guide, country, id, version) => {
    guideById[`${country}-${id}${version ? '-' + version : ''}`] = fn
      ? fn(guide, country, id, version)
      : guide;
  };

  Object.keys(guides).forEach(country => {
    Object.keys(guides[country]).forEach(id => {
      const guide = guides[country][id];
      if (isFunction(guide)) {
        add(guide, country, id);
      } else if (typeof guide === 'object') {
        Object.keys(guide).forEach(version => {
          add(
            guide[version],
            country,
            id,
            version !== 'default' ? version : undefined
          );
        });
      }
    });
  });

  return guideById;
};

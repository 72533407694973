import type { Guide } from '../../types';

export default (async args => {
  const { ask, significant } = args;

  const newSymptomsQuestion = {
    type: 'choice',
    options: {
      yes: 'Yes, they are new',
      noCameBack: 'No, they went away and have now come back',
      noChanged:
        'I have had them a while, but they have changed or become worse',
    },
  };
  const newSymptoms = await ask({
    ...newSymptomsQuestion,
    label: 'Are your heartburn symptoms new?',
  });
  significant.push({
    ...newSymptomsQuestion,
    label: 'New onset of symptoms',
    value: newSymptoms,
  });

  const onsetQuestion = {
    type: 'choice',
    options: {
      lessThan24Hours: 'Less than 24 hours ago',
      '1To7Days': '1–7 days ago',
      '1To4Weeks': '1–4 weeks ago',
      moreThan4Weeks: 'More than 4 weeks ago',
    },
  };
  const onset = await ask({
    ...onsetQuestion,
    label: {
      yes: 'When did your symptoms first appear?',
      noCameBack: 'When did your symptoms appear this time?',
      noChanged:
        'How long has it been since your symptoms changed or became worse?',
    }[newSymptoms],
  });
  if (onset === 'moreThan4Weeks') {
    significant.push({
      type: 'text',
      label: 'Onset of symptoms on this occasion',
      value: await ask.text(
        {
          yes: 'When exactly did your symptoms first appear?',
          noCameBack: 'When exactly did your symptoms appear this time?',
          noChanged: 'When exactly did your symptoms change or become worse?',
        }[newSymptoms]
      ),
    });
  } else {
    significant.push({
      ...onsetQuestion,
      // TODO: if "New onset", just write out "Onset of symptoms"
      label: 'Onset of symptoms on this occasion',
      value: onset,
    });
  }

  if (newSymptoms === 'yes') {
    const changedQuestion = {
      type: 'choice',
      options: {
        same: 'They have stayed the same',
        improving: 'They are improving',
        improvedButBadAgain: 'They improved but have got bad again',
        worse: 'They have been getting worse',
      },
    };
    const changed = await ask({
      ...changedQuestion,
      label: 'Since your symptoms started have they changed?',
    });
    significant.push({
      ...changedQuestion,
      label: 'Have symptoms changed?',
      value: changed,
    });
  }

  return {};
}: Guide);

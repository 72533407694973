import type { Guide } from '../../types';

import aborts from './aborts';

export default (async args => {
  const { ask, significant } = args;

  const locationOfPainQuestion = {
    type: 'multipleChoice',
    options: {
      aboveBellyButton: 'In my stomach above my belly button',
      belowBellyButton: 'In my stomach below my belly button',
      allOver: 'All over my stomach',
      sides: 'Down the sides of my stomach',
      shoulder: 'Pain moving to my shoulder tip',
      other: 'Other',
    },
  };
  const locationOfPain = await ask({
    ...locationOfPainQuestion,
    id: 'symptoms',
    label: 'Where best describes the location of your stomach pain?',
    description: 'More than one answer can be selected',
  });
  if (locationOfPain.length > 1 || !locationOfPain.includes('other')) {
    significant.push({
      ...locationOfPainQuestion,
      label: 'Location of stomach pain',
      // TODO: erik?
      value: locationOfPain.filter(v => v !== 'other'),
      warn: values =>
        values.includes('belowBellyButton') ||
        values.includes('allOver') ||
        values.includes('sides') ||
        values.includes('shoulder'),
    });
  }
  if (locationOfPain.includes('belowBellyButton')) {
    return { abort: aborts.lowerAbdominalPain };
  }
  if (locationOfPain.includes('allOver')) {
    return { abort: aborts.abdominalPain };
  }
  if (locationOfPain.includes('sides')) {
    return { abort: aborts.flankPain };
  }
  if (locationOfPain.includes('shoulder')) {
    return { abort: aborts.shoulderPain };
  }
  if (locationOfPain.includes('other')) {
    significant.push({
      type: 'text',
      label: 'Other location of stomach pain',
      value: await ask.text('Please describe the location to us.'),
      warn: true,
    });
  }

  return {};
}: Guide);

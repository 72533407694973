import type { Guide } from '../../types';
import journalData from './journalData';
import qof from './qof';
import act from './act';
import extended from './extended';
import medication from './medication';
import hayfever from './hayfever';
import modulePharmacy from '../modules/pharmacy';
import moduleEmergency from '../modules/emergency';
import moduleSmoking from '../modules/smoking';
import collateDecisionSupport from '../common/collateDecisionSupport';
import informedConsent from '../questions/informedConsent';

export default (async args => {
  const { exports, ask, decisionSupport } = args;

  journalData(exports);

  await ask.choice({
    description: `
**This questionnaire is for a review of your asthma management. Your GP surgery or a Docly clinician will have asked you to complete this questionnaire.** 

**If you have not been diagnosed with asthma, this questionnaire is not suitable for you.** 

**If you feel unwell or are struggling with your breathing, do not use this guide, please seek urgent medical attention.**

**Your answers will be reviewed within 2 working days. If you need an urgent reply, please contact your GP or ring 111.**
    `,
    options: ['I understand and want to continue'],
  });

  await moduleEmergency(args);

  const { abort: qofAbort } = await qof(args);
  if (qofAbort) {
    return { abort: qofAbort };
  }

  const { abort: actAbort, actScore } = await act(args);
  if (actAbort) {
    return { abort: actAbort };
  }

  const { abort: extendedAbort } = await extended(args);
  if (extendedAbort) {
    return { abort: extendedAbort };
  }

  const { abort: medicationAbort } = await medication({
    ...args,
    actScore,
  });
  if (medicationAbort) {
    return { abort: medicationAbort };
  }

  const { abort: hayfeverAbort } = await hayfever(args);
  if (hayfeverAbort) {
    return { abort: hayfeverAbort };
  }

  await moduleSmoking(args);
  await modulePharmacy(args);

  await ask(informedConsent);

  await ask.choice({
    description: `
We will reply to you as soon as we can. We aim to reply within 2 working days. 

**If you become unwell whilst you are waiting, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.**
`,
    options: ['I understand'],
  });

  collateDecisionSupport(decisionSupport);

  return {};
}: Guide);

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, exports, significant } = args;

  const description = 'Flera svarsalternativ möjliga.';

  const healthIssuesWhat = {
    type: 'multipleChoice',
    id: 'health.issues.what',
    label: 'Markera de sjukdomar eller hälsoproblem du har, eller har haft.',
    description: description,
    options: [
      {
        value: 'bloodpreassure',
        label: 'Högt blodtryck med tablettbehandling',
      },
      {
        value: 'cardiovascular',
        label: 'Hjärt-kärlsjukdom',
      },
      {
        value: 'diabetes',
        label: 'Diabetes',
      },
      {
        value: 'lungs',
        label: 'Lungsjukdom',
      },
      {
        value: 'cancer',
        label: 'Cancersjukdom',
      },
      {
        value: 'neuro',
        label: 'Neurologisk sjukdom',
      },
      {
        value: 'blooddisease',
        label: 'Blodsmitta (HIV, Hepatit B, Hepatit C)',
      },
      {
        value: 'kidney',
        label: 'Njurfunktionsnedsättning',
      },
      {
        value: 'liver',
        label: 'Leverfunktionsnedsättning',
      },
    ],
    expires: 900,
    optional: true,
  };

  const healthIssuesWhatAnswer = await ask(healthIssuesWhat);

  significant.push({
    ...healthIssuesWhat,
    label: 'Tidigare och/eller nuvarande sjukdomar',
    description: '',
    value: healthIssuesWhatAnswer,
    warn: healthIssuesWhatAnswer.length !== 0,
  });

  if (healthIssuesWhatAnswer.includes('kidney')) {
    exports.push({
      id: 'prescriptionWarnings',
      value: ['**Nedsatt njurfunktion** \n Ja'],
    });
  }

  if (healthIssuesWhatAnswer.includes('liver')) {
    exports.push({
      id: 'prescriptionWarnings',
      value: ['**Nedsatt leverfunktion** \n Ja'],
    });
  }

  if (healthIssuesWhatAnswer.includes('cardiovascular')) {
    const healthCardiovascularWhat = {
      type: 'multipleChoice',
      id: 'health.cardiovascular.what',
      label: 'Vilka hjärt-kärlsjukdomar har du, eller har du haft?',
      description: description,
      options: [
        {
          value: 'infarction',
          label: 'Hjärtinfarkt',
        },
        {
          value: 'congestiveHeart',
          label: 'Hjärtsvikt',
        },
        {
          value: 'anginaPectoris',
          label: 'Kärlkramp',
        },
        {
          value: 'atrialFibrillation',
          label: 'Förmaksflimmer',
        },
        {
          value: 'heartValve',
          label: 'Hjärtklaffsfel',
        },
        {
          value: 'stroke',
          label: 'Stroke',
        },
        {
          value: 'bloodClot',
          label: 'Blodpropp som krävt blodförtunnande medicinering',
        },
        {
          value: 'other',
          label: 'Annan hjärt-kärlsjukdom',
        },
      ],
      expires: 900,
    };

    const healthCardiovascularWhatAnswer = await ask(healthCardiovascularWhat);

    significant.push({
      ...healthCardiovascularWhat,
      label: 'Hjärt-kärlsjukdom',
      description: '',
      value: healthCardiovascularWhatAnswer,
    });

    if (healthCardiovascularWhatAnswer.includes('other')) {
      const healthCardiovascularWhatOther = {
        type: 'text',
        id: 'health.cardiovascular.what.other',
        label: 'Vilken hjärt-kärlsjukdom har du, eller har du haft?',
      };

      const healthCardiovascularWhatOtherAnswer = await ask(
        healthCardiovascularWhatOther
      );

      significant.push({
        ...healthCardiovascularWhatOther,
        label: 'Fri beskrivning av annan hjärt-kärlsjukdom',
        value: healthCardiovascularWhatOtherAnswer,
      });
    }
  }

  if (healthIssuesWhatAnswer.includes('diabetes')) {
    const healthDiabetesType = {
      type: 'multipleChoice',
      id: 'health.diabetes.type',
      label: 'Vilken typ av diabetes har du?',
      description: description,
      options: [
        {
          value: 'type1',
          label: 'Diabetes typ 1',
        },
        {
          value: 'type2',
          label: 'Diabetes typ 2',
        },
        {
          value: 'other',
          label: 'Annat',
        },
      ],
      expires: 900,
    };

    const healthDiabetesTypeAnswer = await ask(healthDiabetesType);

    significant.push({
      ...healthDiabetesType,
      label: 'Typ av diabetes',
      description: '',
      value: healthDiabetesTypeAnswer,
    });

    if (healthDiabetesTypeAnswer.includes('other')) {
      const healthDiabetesTypeOther = {
        type: 'text',
        id: 'health.diabetes.typeOther',
        label: 'Vilken typ av diabetes har du?',
        expires: 900,
      };

      const healthDiabetesTypeOtherAnswer = await ask(healthDiabetesTypeOther);

      significant.push({
        ...healthDiabetesTypeOther,
        label: 'Typ av diabetes, annat',
        value: healthDiabetesTypeOtherAnswer,
      });
    }

    const healthDiabetesTreatment = {
      type: 'multipleChoice',
      id: 'health.diabetes.treatment',
      label: 'Hur behandlas du för din diabetes?',
      description: description,
      options: [
        {
          value: 'food',
          label: 'Kostbehandling',
        },
        {
          value: 'pills',
          label: 'Tablettbehandling',
        },
        {
          value: 'insuline',
          label: 'Insulinbehandling',
        },
      ],
      expires: 900,
    };

    const healthDiabetesTreatmentAnswer = await ask(healthDiabetesTreatment);

    significant.push({
      ...healthDiabetesTreatment,
      label: 'Diabetes behandlas med',
      description: '',
      value: healthDiabetesTreatmentAnswer,
    });
  }

  if (healthIssuesWhatAnswer.includes('lungs')) {
    const healthLungDiseaseWhat = {
      type: 'multipleChoice',
      id: 'health.lungDiseases.what',
      label: 'Vilka lungsjukdomar har du, eller har du haft?',
      description: description,
      options: [
        {
          value: 'astma',
          label: 'Astma',
        },
        {
          value: 'kol',
          label: 'KOL',
        },
        {
          value: 'other',
          label: 'Annan lungsjukdom',
        },
      ],
      expires: 900,
    };

    const healthLungDiseaseWhatAnswer = await ask(healthLungDiseaseWhat);

    significant.push({
      ...healthLungDiseaseWhat,
      label: 'Lungsjukdom',
      description: '',
      value: healthLungDiseaseWhatAnswer,
    });

    if (healthLungDiseaseWhatAnswer.includes('other')) {
      const healthLungDiseaseWhatOther = {
        type: 'text',
        id: 'health.lungDiseases.what.other',
        label: 'Vilken lungsjukdom har du, eller har du haft?',
        expires: 900,
      };

      const healthLungDiseaseWhatOtherAnswer = await ask(
        healthLungDiseaseWhatOther
      );

      significant.push({
        ...healthLungDiseaseWhatOther,
        label: 'Fri beskrivning annan lungsjukdom',
        value: healthLungDiseaseWhatOtherAnswer,
      });
    }
  }

  if (healthIssuesWhatAnswer.includes('cancer')) {
    const healthCancerWhat = {
      type: 'text',
      id: 'health.cancerWhat',
      label: 'Vilken cancersjukdom har du, eller har du haft?',
      expires: 900,
    };

    const healthCancerWhatAnswer = await ask(healthCancerWhat);

    significant.push({
      ...healthCancerWhat,
      label: 'Cancersjukdom',
      value: healthCancerWhatAnswer,
    });

    const healthCancerYearDiagnosed = {
      type: 'text',
      id: 'health.cancerYearDiagnosed',
      label: 'Vilket år fick du din cancerdiagnos?',
      expires: 900,
    };

    const healthCancerYearDiagnosedAnswer = await ask(
      healthCancerYearDiagnosed
    );

    significant.push({
      ...healthCancerYearDiagnosed,
      label: 'Diagnosticerad (cancer)',
      value: healthCancerYearDiagnosedAnswer,
    });

    const healthCancerAffected = {
      type: 'binary',
      id: 'health.cancerAffected.match',
      label: 'Är ditt hälsotillstånd i dagsläget påverkat av din cancer?',
      expires: 900,
    };

    const healthCancerAffectedAnswer = await ask(healthCancerAffected);

    significant.push({
      ...healthCancerAffected,
      label: 'Har aktuella besvär på grund av cancer',
      value: healthCancerAffectedAnswer,
    });

    if (healthCancerAffectedAnswer === 'yes') {
      const healthCancerAffectedHow = {
        type: 'text',
        id: 'health.cancerAffected.how',
        label:
          'På vilket sätt är ditt hälsotillstånd i dagsläget påverkat av din cancer?',
      };

      const healthCancerAffectedHowAnswer = await ask(healthCancerAffectedHow);

      significant.push({
        ...healthCancerAffectedHow,
        label: 'Beskrivning av aktuella besvär på grund av cancer',
        value: healthCancerAffectedHowAnswer,
      });
    }
  }

  if (healthIssuesWhatAnswer.includes('neuro')) {
    const healthNeurologicalWhat = {
      type: 'multipleChoice',
      id: 'health.neurological.what',
      label: 'Vilka neurologiska sjukdomar har du, eller har du haft?',
      description: description,
      options: [
        {
          value: 'epilepsy',
          label: 'Epilepsi',
        },
        {
          value: 'ms',
          label: 'MS',
        },
        {
          value: 'parkinson',
          label: 'Parkinson',
        },
        {
          value: 'other',
          label: 'Annan neurologisk sjukdom',
        },
      ],
      expires: 900,
    };

    const healthNeurologicalWhatAnswer = await ask(healthNeurologicalWhat);

    significant.push({
      ...healthNeurologicalWhat,
      label: 'Neurologisk sjukdom',
      description: '',
      value: healthNeurologicalWhatAnswer,
    });

    if (healthNeurologicalWhatAnswer.includes('other')) {
      const healthNeurologicalOther = {
        type: 'text',
        id: 'health.neurological.what.other',
        label: 'Vilken neurologisk sjukdom har du, eller har du haft?',
        expires: 900,
      };

      const healthNeurologicalOtherAnswer = await ask(healthNeurologicalOther);

      significant.push({
        ...healthNeurologicalOther,
        label: 'Fri beskrivning annan neurologisk sjukdom',
        value: healthNeurologicalOtherAnswer,
      });
    }
  }

  if (healthIssuesWhatAnswer.includes('blooddisease')) {
    const healthBloodDiseaseWhat = {
      type: 'multipleChoice',
      id: 'health.bloodDisease.what',
      label: 'Vilken blodsmitta har du, eller har du haft?',
      description: description,
      options: [
        {
          value: 'hepB',
          label: 'Hepatit B',
        },
        {
          value: 'hepC',
          label: 'Hepatit C',
        },
        {
          value: 'hiv',
          label: 'HIV',
        },
      ],
      expires: 900,
    };

    const healthBloodDiseaseWhatAnswer = await ask(healthBloodDiseaseWhat);

    significant.push({
      ...healthBloodDiseaseWhat,
      label: 'Blodsmitta',
      description: '',
      value: healthBloodDiseaseWhatAnswer,
    });

    if (
      healthBloodDiseaseWhatAnswer.includes('hepB') ||
      healthBloodDiseaseWhatAnswer.includes('hepC')
    ) {
      exports.push({
        id: 'prescriptionWarnings',
        value: ['**Nedsatt leverfunktion pga blodsmitta** \n Ja'],
      });
    }
  }

  const healthOperation = {
    type: 'binary',
    id: 'health.operation.match',
    label: 'Har du genomgått någon större operation?',
    expires: 900,
  };

  const healthOperationAnswer = await ask(healthOperation);

  significant.push({
    ...healthOperation,
    label: 'Har genomgått större operation',
    value: healthOperationAnswer,
  });

  if (healthOperationAnswer === 'yes') {
    const healthOperationWhat = {
      type: 'text',
      id: 'health.operation.what',
      label: 'Vilken större operation har du genomgått?',
      expires: 900,
    };

    const healthOperationWhatAnswer = await ask(healthOperationWhat);

    significant.push({
      ...healthOperationWhat,
      label: 'Beskrivning av operation',
      value: healthOperationWhatAnswer,
    });
  }

  return {};
}: Guide);

import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import aborts from './abort';
import activityLevelChild from '../partials/triage/activityLevelChild';

export default (async args => {
  const eChoice = createExtendedChoice(args);

  const {
    patient: { age },
  } = args;

  const PHMOption = { warn: true, abort: aborts.PMHChild };
  const conditions = await eChoice({
    id: 'triage.conditionsChild',
    label: 'Does your child have any of the following conditions?',
    isMultipleChoice: true,
    optional: true,
    clinicLabel: 'Significant PMH',
    options: [
      {
        value: 'previousCurrentCancer',
        label: 'Previous or current cancer',
        keyFinding: 'Previous or current cancer',
        ...PHMOption,
      },
      {
        value: 'cerebralPalsy',
        label: 'Cerebral palsy',
        keyFinding: 'Cerebral palsy',
        ...PHMOption,
      },
      {
        value: 'congenitalHeartDisease',
        label: 'Heart condition',
        keyFinding: 'Congenital heart disease',
        ...PHMOption,
      },
      {
        value: 'cysticFibrosis',
        label: 'Cystic fibrosis',
        keyFinding: 'Cystic fibrosis',
        ...PHMOption,
      },
      {
        value: 'SickleCellAnaemia',
        label: 'Sickle cell anaemia',
        keyFinding: 'Sickle cell anaemia',
        ...PHMOption,
      },
      {
        value: 'previousRheumaticFever',
        label: 'Previous rheumatic fever',
        keyFinding: 'Previous rheumatic fever',
        ...PHMOption,
      },
      {
        value: 'previousQuinsyTonsilAbscess',
        label: `Previous quinsy/tonsil abscess`,
        keyFinding: `Previous quinsy/tonsil abscess`,
        ...PHMOption,
      },
    ],
  });

  if (conditions.abort) {
    return { abort: conditions.abort };
  }

  const breathingDifficultyOption = {
    warn: true,
    abort: aborts.breathingDifficulty,
  };
  const currentBreathing = await eChoice({
    id: 'triage.currentBreathing',
    label: 'Is your child breathing normally right now?',
    isMultipleChoice: true,
    clinicLabel: 'Current breathing',
    options: [
      {
        value: 'normal',
        label: 'They are breathing normally',
        hide: true,
      },
      {
        value: 'blockednose',
        label: 'They have a blocked nose.',
        hide: true,
        keyFinding: 'Blocked nose',
      },
      {
        value: 'tachypnoeic',
        label:
          'They are breathing very fast and I can see the ribs on their chest sucking in when they breathe',
        ...breathingDifficultyOption,
        keyFinding: 'Tachypnoeic',
      },
      {
        value: 'wheeze',
        label: 'They are wheezing (making a whistling noise then they breathe)',
        ...breathingDifficultyOption,
        keyFinding: 'Wheeze',
      },
      {
        value: 'DIB',
        label: 'They are struggling to breathe through their mouth',
        ...breathingDifficultyOption,
        keyFinding: 'Difficulty breathing',
      },
      {
        value: 'Breathless when talking',
        label: 'They are breathless when talking',
        ...breathingDifficultyOption,
        keyFinding: 'Breathless when talking',
      },
    ],
  });
  if (currentBreathing.abort) {
    return currentBreathing;
  }

  const sepsisOption = {
    warn: true,
    abort: aborts.sepsis,
  };
  const sepsis = await eChoice({
    id: 'triage.sepsis',
    label: 'Does your child have any of the symptoms below right now?',
    isMultipleChoice: true,
    optional: true,
    clinicLabel: 'Signs of sepsis',
    options: [
      {
        keyFinding: 'Fever >39 degrees',
        value: 'FeverAbove39',
        label:
          'They have a high fever (more than 39 degrees) which is not coming down with medication',
        ...sepsisOption,
      },
      {
        keyFinding: 'New drooling',
        value: 'NewDrooling',
        label: 'Drooling which is unusual for them',
        ...sepsisOption,
      },
      {
        keyFinding: 'New rash ?meningicoccal',
        value: 'AnewRash',
        label: 'A new rash which does not fade when rolling a glass over it',
        ...sepsisOption,
      },
      {
        keyFinding: 'Vomiting and not able to keep fluids down',
        value: 'Vomit',
        label: 'They keep vomiting and cannnot keep fluids down',
        ...sepsisOption,
      },
      {
        keyFinding: 'Not PU for >12 hours',
        value: 'NotPUForMoreThen12Hours',
        label: 'They have not had a wee for over 12 hours',
        ...sepsisOption,
      },
      {
        keyFinding: 'Blueish skin, lips or tongue',
        value: 'BlueSkinLipsOrATongue',
        label: 'Blue skin, lips or a tongue',
        ...sepsisOption,
      },
      {
        keyFinding: 'Fainted or almost fainted',
        value: 'FaintedOrAlmostFainted',
        label: 'They have fainted or almost fainted',
        ...sepsisOption,
      },
    ],
  });
  if (sepsis.abort) {
    return sepsis;
  }

  const activityLevelChildResult = await activityLevelChild(args);
  if (activityLevelChildResult.abort) {
    return activityLevelChildResult;
  }

  if (age < 12) {
    const pontentialFOBSwallowed = await eChoice({
      id: 'triage.pontentialFOBSwallowed',
      label: `Could your child have swallowed something that has made their throat sore?`,
      clinicLabel: 'Potential foreign body swallowed',
      options: [
        {
          value: 'yes',
          label: 'Yes',
          keyFinding: 'Potential foreign body swallowed',
          abort: aborts.pontentialFOBSwallowed,
        },
        {
          value: 'no',
          label: 'No',
          hide: true,
        },
      ],
    });
    if (pontentialFOBSwallowed.abort) {
      return pontentialFOBSwallowed;
    }
  }

  return {};
}: Guide);

import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import { _t } from '../../../i18n';

import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

import Key from '../../components/Key';

class SwitchButton extends React.Component<{
  country: string,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { country, classes, className } = this.props;

    const openGuides = () => navigate(`/${country}/guides`);

    return (
      <Button
        variant="outlined"
        onClick={openGuides}
        className={classNames(classes.root, className)}
      >
        {_t('guides.switch')}

        <Key character="G" onPress={openGuides} className={classes.key} />
      </Button>
    );
  }
}

export default withStyles(({ spacing }) => ({
  root: {},

  key: {
    marginLeft: spacing(),
  },
}))(connect(undefined)(SwitchButton));

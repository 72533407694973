import clone from 'lodash/clone';

import type { Node, Transform } from './types';

export const DEFAULT_RANGE_MIN_VALUE = 0;
export const DEFAULT_RANGE_MAX_VALUE = 10;

let transforms = {
  binary: value => {
    return !value || value === 'no' ? 'no' : 'yes';
  },

  choice: (value, properties) => {
    if ((properties.options || []).find(o => o.value === value)) return value;
    return undefined;
  },

  multipleChoice: (value, properties) => {
    const a = (Array.isArray(value) ? value : [value]).filter(v => {
      return (properties.options || []).find(o => o.value === v);
    });

    if (a.length) return a;
    return undefined;
  },

  number: value => {
    let value2;

    if (typeof value === 'string') {
      if (!value) return undefined;

      // Because in Sweden, ',' is used before float decimals instead of
      // '.', but the function isNaN expects '.'.
      value2 = value.replace(',', '.');
      if (!isNaN(value2)) return Number(value2);
    } else if (typeof value === 'number' && !isNaN(value)) {
      return value;
    }

    return undefined;
  },

  range: (value, properties) => {
    let number;
    if (isNaN(value)) return undefined;
    number = Number(value);
    if (
      number <
      ((properties.min && properties.min.value) || DEFAULT_RANGE_MIN_VALUE)
    ) {
      return undefined;
    }
    if (
      number >
      ((properties.max && properties.max.value) || DEFAULT_RANGE_MAX_VALUE)
    ) {
      return undefined;
    }
    return number;
  },

  tertiary: value => {
    if (['yes', 'no', 'unknown'].indexOf(value) > -1) return value;
    return undefined;
  },

  text: value => {
    let string = '' + value;
    if (string) return string;
    return undefined;
  },

  upload: value => {
    const a = (Array.isArray(value) ? clone(value) : [value]).filter(a => !!a);
    if (a.length) return a;
    return undefined;
  },
};

export default function Transforms(properties: Node): Transform {
  let type = properties.type;

  // Takes a value, checks that it conforms to all the rules for the given node type. If not,
  // it returns undefined.
  return value => {
    if (value === undefined || value === null) return undefined;
    if (!transforms[type]) return value;
    return transforms[type](value, properties);
  };
}

import {
  type State,
  type FirebaseState,
  type CurrentTestCaseMeta,
} from './types';
import { type TestCase } from '../../guides/test/types';

export const getCurrentTestCaseMeta = ({
  testCases,
}: {
  testCases: State,
}): CurrentTestCaseMeta => testCases.current;

export const getAllTestCases = ({
  firebase,
}: {
  firebase: FirebaseState,
}): TestCase[] => firebase.data['inquisitor-test-cases'];

export default {
  id: 'acne',
  type: 'formulary',
  version: '3',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '6-acne',
          options: [
            {
              value: 'lessThanFourteenDays',
              label: 'Mindre än 14 dagar',
            },
            {
              value: 'fourteenDaysToThreeMonths',
              label: '14 dagar – 3 månader',
            },
            {
              value: 'moreThanThreeMonths',
              label: 'Mer än 3 månader',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Hur länge har du haft akne?',
          index: '0.0.0',
        },
        {
          id: 'acne.where',
          type: 'composite',
          version: '1',
          children: [
            {
              id: 'acne.where.part',
              type: 'multipleChoice',
              options: [
                {
                  value: 'face',
                  label: 'Ansikte',
                },
                {
                  value: 'chest',
                  label: 'Bröst',
                },
                {
                  value: 'back',
                  label: 'Rygg',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'part',
              version: '1',
              label: 'Var på kroppen sitter aknen?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.0.1.0',
            },
            {
              id: 'acne.where.other',
              type: 'text',
              condition: "contains(get('answers.acne.where.part'), 'other')",
              relativeId: 'other',
              version: '1',
              validations: {
                required: true,
              },
              label: 'Var sitter aknen?',
              index: '0.0.1.1',
            },
          ],
          extras: {},
          index: '0.0.1',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'rash.pictures',
          type: 'upload',
          version: '6',
          validations: {
            required: true,
            min: 2,
          },
          extras: {
            instructions: ['distance', 'closeup', 'lighting'],
          },
          label: 'Bifoga minst två bilder, en närbild och en översiktsbild.',
          description:
            'Det går bra att ta bilderna med din mobilkamera, men undvik att använda dess zoom-funktion. Försök ta en så detaljerad närbild som möjligt i bra belysning.',
          modal: {
            link: 'Se bildexempel',
            content:
              '  <p>\n    <img src="https://storage.googleapis.com/mindoktor-assets/formulary/skin_photos.jpg">\n  </p>',
          },
          index: '0.1.0',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'acne.parents',
          type: 'tertiary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har någon av dina föräldrar haft akne?',
          index: '0.2.0',
        },
        {
          id: 'exercise.regularly',
          version: '3',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Tränar du 3 gånger i veckan eller mer?',
          index: '0.2.1',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.diet',
          type: 'composite',
          version: '1',
          children: [
            {
              id: 'health.diet.match',
              type: 'binary',
              relativeId: 'match',
              version: '1',
              validations: {
                required: true,
              },
              label: 'Äter du någon specifik diet?',
              index: '0.3.0.0',
            },
            {
              id: 'health.diet.what',
              type: 'text',
              condition: "get('answers.health.diet.match') === 'yes'",
              relativeId: 'what',
              version: '1',
              validations: {
                required: true,
              },
              label: 'Vad äter du för diet?',
              index: '0.3.0.1',
            },
          ],
          extras: {},
          index: '0.3.0',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.4.0',
        },
        {
          id: 'reproduction.planningPregnancy',
          version: '1',
          type: 'tertiary',
          validations: {
            required: true,
          },
          label: 'Planerar du graviditet under det närmsta året?',
          index: '0.4.1',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.4.2',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'anticonception.using',
          version: '7',
          type: 'composite',
          children: [
            {
              id: 'anticonception.using.match',
              type: 'choice',
              options: [
                {
                  value: 'pillRing',
                  label: 'P-piller/P-ring',
                },
                {
                  value: 'stave',
                  label: 'P-stav',
                },
                {
                  value: 'hormoneSpiral',
                  label: 'Hormonspiral',
                },
                {
                  value: 'miniPill',
                  label: 'Minipiller',
                },
                {
                  value: 'copperSpiral',
                  label: 'Kopparspiral',
                },
                {
                  value: 'otherNone',
                  label: 'Annat/Inget',
                },
              ],
              relativeId: 'match',
              version: '7',
              validations: {
                required: true,
              },
              label: 'Använder du något av följande preventivmedel?',
              index: '0.5.0.0',
            },
            {
              id: 'anticonception.using.pillRingType',
              type: 'text',
              condition:
                "get('answers.anticonception.using.match') === 'pillRing' || get('answers.anticonception.using.match') === 'miniPill'",
              relativeId: 'pillRingType',
              version: '7',
              validations: {
                required: true,
              },
              label:
                'Vilken sorts p-piller, p-ring eller minipiller använder du?',
              index: '0.5.0.1',
            },
          ],
          condition:
            "get('answers.predefined.gender') === 'female' && get('answers.reproduction.pregnant') === 'no'",
          extras: {},
          index: '0.5.0',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.periodFrequency',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'often',
              label: 'Jag får mens oftare än var 35:e dag',
            },
            {
              value: 'seldom',
              label: 'Jag får mens mer sällan än var 35:e dag',
            },
          ],
          condition:
            "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
          validations: {
            required: true,
          },
          label: 'Hur ser ditt menstruationsmönster ut utan preventivmedel?',
          index: '0.6.0',
        },
        {
          id: 'hair.facial.increased',
          version: '1',
          type: 'binary',
          condition: "get('answers.predefined.gender') === 'female'",
          validations: {
            required: true,
          },
          label: 'Upplever du att du har ökad behåring i ansiktet?',
          index: '0.6.1',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.similar',
          version: '2',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Har du någon gång tidigare haft liknande besvär?',
          index: '0.7.0',
        },
        {
          id: 'acne.previous.doctor.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Har du tidigare sökt läkare för din akne?',
          index: '0.7.1',
        },
        {
          id: 'acne.previous.doctor.when',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'thisYear',
              label: 'Under senaste året',
            },
            {
              value: 'oneToFiveYears',
              label: 'För 1–5 år sedan',
            },
            {
              value: 'moreThanFiveYears',
              label: 'För mer än 5 år sedan',
            },
          ],
          condition: "get('answers.acne.previous.doctor.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'När sökte du läkare för din akne?',
          index: '0.7.2',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'acne.previous.treatment.match',
          version: '1',
          type: 'binary',
          condition: "get('answers.acne.previous.doctor.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Fick du någon behandling för din akne?',
          index: '0.8.0',
        },
        {
          id: 'acne.previous.treatment.what',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'acnatac',
              label: 'Acnatac',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotikatabletter (Lymecycline/Tetralysal, Ery-Max)',
            },
            {
              value: 'basiron',
              label: 'Basiron',
            },
            {
              value: 'epiduo',
              label: 'Epiduo',
            },
            {
              value: 'finaceaSkinoren',
              label: 'Finacea/Skinoren',
            },
            {
              value: 'dalacingel',
              label: 'Dalacingel (Klindamycingel) kutan emulsion',
            },
            {
              value: 'differin',
              label: 'Differin',
            },
            {
              value: 'duac',
              label: 'Duac',
            },
            {
              value: 'isotretinoin',
              label: 'Isotretinoin (Roaccutan)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.acne.previous.treatment.match') === 'yes'",
          label: 'Vilken behandling fick du?',
          index: '0.8.1',
        },
        {
          id: 'acne.previous.treatment.other',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.acne.previous.treatment.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vad för annan behandling fick du?',
          index: '0.8.2',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'acne.previous.treatment.ended',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'lessThanFourteenDays',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: 'fourteenDaysToThreeMonths',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'moreThanThreeMonths',
              label: 'För mer än 3 månader sedan',
            },
          ],
          condition: "get('answers.acne.previous.treatment.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'När avlutades behandlingen?',
          index: '0.9.0',
        },
        {
          id: 'acne.previous.treatment.sideEffects.match',
          version: '1',
          type: 'binary',
          condition: "get('answers.acne.previous.treatment.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Fick du biverkningar av behandlingen?',
          index: '0.9.1',
        },
        {
          id: 'acne.previous.treatment.sideEffects.what',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'sick',
              label: 'Illamående och kräkningar',
            },
            {
              value: 'diarrhea',
              label: 'Diarré',
            },
            {
              value: 'stomachPain',
              label: 'Magsmärta',
            },
            {
              value: 'drySkin',
              label: 'Torr hud',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition:
            "get('answers.acne.previous.treatment.sideEffects.match') === 'yes'",
          label: 'Vilka biverkningar fick du?',
          index: '0.9.2',
        },
        {
          id: 'acne.previous.treatment.sideEffects.other',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.acne.previous.treatment.sideEffects.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilka andra biverkningar fick du?',
          index: '0.9.3',
        },
        {
          id: 'acne.previous.treatment.sideEffects.hadToStop',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.acne.previous.treatment.sideEffects.match') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Var du tvungen att avbryta behandlingen på grund av biverkningarna?',
          index: '0.9.4',
        },
      ],
      version: '1',
      index: '0.9',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.10.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.10.1',
        },
      ],
      version: '1',
      index: '0.10',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.11.0',
        },
      ],
      version: '1',
      index: '0.11',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.12',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.13',
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Akne sedan',
          description: '',
          options: [
            {
              value: 'lessThanFourteenDays',
              label: 'Mindre än 14 dagar',
            },
            {
              value: 'fourteenDaysToThreeMonths',
              label: '14 dagar – 3 månader',
            },
            {
              value: 'moreThanThreeMonths',
              label: 'Mer än 3 månader',
            },
          ],
        },
        {
          id: 'acne.where.part',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Aknen på/i',
          description: '',
          options: [
            {
              value: 'face',
              label: 'Ansikte',
            },
            {
              value: 'chest',
              label: 'Bröst',
            },
            {
              value: 'back',
              label: 'Rygg',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'acne.where.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Akne övrigt',
        },
        {
          id: 'acne.parents',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Förälder/föräldrar haft acne',
        },
        {
          id: 'exercise.regularly',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tränar 3 ggr i veckan eller mer',
        },
        {
          id: 'health.diet.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Äter specifik diet',
        },
        {
          id: 'health.diet.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken diet',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
        },
        {
          id: 'reproduction.planningPregnancy',
          warning:
            "get('answers.reproduction.planningPregnancy') === 'yes' || get('answers.reproduction.planningPregnancy') === 'unknown'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Planerar graviditet inom det närmsta året',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
        },
        {
          id: 'anticonception.using.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Använder preventivmedel',
          options: [
            {
              value: 'pillRing',
              label: 'P-piller/P-ring',
            },
            {
              value: 'stave',
              label: 'P-stav',
            },
            {
              value: 'hormoneSpiral',
              label: 'Hormonspiral',
            },
            {
              value: 'miniPill',
              label: 'Minipiller',
            },
            {
              value: 'copperSpiral',
              label: 'Kopparspiral',
            },
            {
              value: 'otherNone',
              label: 'Annat/Inget',
            },
          ],
        },
        {
          id: 'anticonception.using.pillRingType',
          type: 'question',
          version: '1',
          extras: {},
          label: 'P-piller sort',
        },
        {
          id: 'reproduction.periodFrequency',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Menstruationsmönster',
          options: [
            {
              value: 'often',
              label: 'Jag får mens oftare än var 35:e dag',
            },
            {
              value: 'seldom',
              label: 'Jag får mens mer sällan än var 35:e dag',
            },
          ],
        },
        {
          id: 'hair.facial.increased',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ökad behåring i ansiktet',
        },
        {
          id: 'problem.similar',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Haft liknande besvär tidigare',
        },
        {
          id: 'acne.previous.doctor.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare läkarbesök för samma besvär',
        },
        {
          id: 'acne.previous.doctor.when',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkarbesök när',
          options: [
            {
              value: 'thisYear',
              label: 'Under senaste året',
            },
            {
              value: 'oneToFiveYears',
              label: 'För 1–5 år sedan',
            },
            {
              value: 'moreThanFiveYears',
              label: 'För mer än 5 år sedan',
            },
          ],
        },
        {
          id: 'acne.previous.treatment.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fick behandling',
        },
        {
          id: 'acne.previous.treatment.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fick följande behandling',
          options: [
            {
              value: 'acnatac',
              label: 'Acnatac',
            },
            {
              value: 'antibiotics',
              label: 'Antibiotikatabletter (Lymecycline/Tetralysal, Ery-Max)',
            },
            {
              value: 'basiron',
              label: 'Basiron',
            },
            {
              value: 'epiduo',
              label: 'Epiduo',
            },
            {
              value: 'finaceaSkinoren',
              label: 'Finacea/Skinoren',
            },
            {
              value: 'dalacingel',
              label: 'Dalacingel (Klindamycingel) kutan emulsion',
            },
            {
              value: 'differin',
              label: 'Differin',
            },
            {
              value: 'duac',
              label: 'Duac',
            },
            {
              value: 'isotretinoin',
              label: 'Isotretinoin (Roaccutan)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'acne.previous.treatment.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Behandling övrigt',
        },
        {
          id: 'acne.previous.treatment.ended',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Avslutade behandlingen',
          options: [
            {
              value: 'lessThanFourteenDays',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: 'fourteenDaysToThreeMonths',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'moreThanThreeMonths',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'acne.previous.treatment.sideEffects.match',
          warning:
            "get('answers.acne.previous.treatment.sideEffects.match') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fick biverkningar',
        },
        {
          id: 'acne.previous.treatment.sideEffects.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fick dessa biverkningar',
          options: [
            {
              value: 'sick',
              label: 'Illamående och kräkningar',
            },
            {
              value: 'diarrhea',
              label: 'Diarré',
            },
            {
              value: 'stomachPain',
              label: 'Magsmärta',
            },
            {
              value: 'drySkin',
              label: 'Torr hud',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'acne.previous.treatment.sideEffects.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Biverkning övrigt',
        },
        {
          id: 'acne.previous.treatment.sideEffects.hadToStop',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tvingades avbryta behandling pga biverkningar',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrigt',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Akne',
  index: '0',
};

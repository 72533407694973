import { combineReducers, type Reducer } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import { type Action } from './types';

import drafts from './drafts/reducer';
import patients from './patients/reducer';
import testCases from './testCases/reducer';
import ui from './ui/reducer';

const reducers = {
  drafts,
  patients,
  testCases,
  ui,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
};

export type State = $ObjMap<typeof reducers, <V>(v: (...args: *) => V) => V>;

export default (combineReducers(reducers): Reducer<State, Action>);

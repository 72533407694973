import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const drugs = {
    type: 'binary',
    id: 'healthCheck.drugs',
    label: 'Använder du, eller har du testat, andra droger än alkohol?',
  };

  const drugsAnswer = await ask(drugs);

  significant.push({
    ...drugs,
    label: 'Använder/testat andra droger än alkohol',
    value: drugsAnswer,
  });

  if (drugsAnswer === 'yes') {
    const drugsWhat = {
      type: 'text',
      id: 'healthCheck.drugsWhat',
      label: 'Vilka droger använder du eller har du testat?',
    };

    const drugsWhatAnswer = await ask(drugsWhat);

    significant.push({
      ...drugsWhat,
      label: 'Vilka droger',
      value: drugsWhatAnswer,
    });
  }

  return {};
}: Guide);

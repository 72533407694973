import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

import type { Guide } from '../../types';
import ds from './decisionSupport';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const haveHayfever = await eChoice({
    id: 'asthmaReview.hayfever.haveHayfever',
    label: `Do you have hayfever?`,
    clinicLabel: 'Hayfever',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
      },
    ],
  });

  if (haveHayfever === 'yes') {
    const wellControlled = await eChoice({
      id: 'asthmaReview.hayfever.wellControlled',
      label: `Is your hayfever well controlled?`,
      clinicLabel: 'Hayfever well controlled',
      options: [
        {
          value: 'yes',
        },
        {
          value: 'no',
        },
      ],
    });

    if (wellControlled === 'no') {
      const useAntihistamine = await eChoice({
        id: 'asthmaReview.hayfever.useAntihistamine',
        label: `Do you regularly use an antihistamine tablet?`,
        clinicLabel: 'Regular use of antihistamine tablet',
        options: [
          {
            value: 'yes',
            hide: true,
          },
          {
            value: 'no',
            ds: ds.hayfeverUseOtcAntihistamine,
          },
        ],
      });

      if (useAntihistamine === 'yes') {
        const antihistamineUsed = await eChoice({
          id: 'asthmaReview.hayfever.antihistamineUsed',
          label: `Which antihistamine tablet do you use?`,
          clinicLabel: 'Antihistamine used',
          options: [
            {
              value: 'cetirizine',
              label: 'Cetirizine (Piriteze)',
            },
            {
              value: 'chlorphenamine',
              label: 'Chlorphenamine (Piriton)',
            },
            {
              value: 'loratadine',
              label: 'Loratadine (Claritin)',
            },
            {
              value: 'acrivastine',
              label: 'Acrivastine (Benadryl)',
            },
            {
              value: 'fexofenadine',
              label: 'Fexofenadine',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        });
        if (antihistamineUsed === 'other') {
          await eText({
            id: 'asthmaReview.hayfever.antihistamineUsedOther',
            label: `Please tell us the name of the antihistamine tablet you use`,
            clinicLabel: 'Antihistamine used',
          });
        }
      }

      const useNasalSpray = await eChoice({
        id: 'asthmaReview.hayfever.useNasalSpray',
        label: `Do you regularly use a nose spray?`,
        clinicLabel: 'Regular use of nasal spray',
        options: [
          {
            value: 'yes',
            hide: true,
          },
          {
            value: 'no',
            ds: ds.hayfeverUseOtcNasalSpray,
          },
        ],
      });

      if (useNasalSpray === 'yes') {
        const nasalSprayUsed = await eChoice({
          id: 'asthmaReview.hayfever.nasalSprayUsed',
          label: `Which nose spray do you use?`,
          clinicLabel: 'Nasal spray used',
          options: [
            {
              value: 'sterimar',
              label: 'Sterimar',
              ds: ds.hayfeverUseOtcNasalSpray,
            },
            {
              value: 'otrivine',
              label: 'Otrivine ',
              ds: ds.hayfeverUseOtcNasalSpray,
            },
            {
              value: 'beclometasone',
              label: 'Beclometasone (Beconase)',
            },
            {
              value: 'fluticasone',
              label: 'Fluticasone (Avamys)',
            },
            {
              value: 'mometasone',
              label: 'Mometasone (Nasonex)',
            },
            {
              value: 'dymista',
              label: 'Dymista',
            },
            {
              value: 'other',
              label: 'Other',
            },
          ],
        });
        if (nasalSprayUsed === 'other') {
          await eText({
            id: 'asthmaReview.hayfever.nasalSprayUsedOther',
            label: `Please tell us the name of the nose spray you use`,
            clinicLabel: 'Nasal spray used',
          });
        }
      }
    }
  }

  return {};
}: Guide);

import type { GuideWithIn } from '../../types';

import healthProfile from '../partials/healthProfile';

import aborts from './aborts';
import { decisionSupports } from './decisionSupport';
import pop from './pop';

export default (async ({
  ask,
  patient,
  decisionSupport,
  significant,
  exports,
  scores,
  doctorChoose,
  skinPatch,
  lowDose,
}) => {
  const args = { ask, patient, decisionSupport, significant, exports, scores };

  const changeOrAppointment = async (explanation, reason) => {
    const question = {
      id: 'chc.changeOrAppointment',
      type: 'choice',
      options: {
        change: 'Change to a progesterone only "mini" pill',
        appointment: 'Arrange an appointment to discuss other options',
      },
    };
    const want = await ask({
      ...question,
      description: `
${explanation}

What would you like to do?
      `,
    });
    significant.push({
      ...question,
      label: 'What would you like to do?',
      value: want,
      warn: 'appointment',
    });
    if (want === 'change') {
      decisionSupport.push(
        decisionSupports.swapContraceptiveProgesterone(reason)
      );

      return await pop(args);
    }
    return { abort: aborts.appointment };
  };

  if (!doctorChoose) {
    if (patient.age >= 49) {
      const question = {
        type: 'choice',
        description: `
We cannot prescribe combined hormonal contraception to women over the age of 49.

Would you like to switch to the progesterone only "mini" pill?
        `,
        options: {
          yesProgesterone: `Yes - I'll try the progesterone only "mini" pill`,
          noAppointment: `No - I'd like to arrange an appointment to discuss my options e.g. Copper Coil, Hormonal Coil (Mirena IUS)`,
        },
      };
      const want = await ask(question);
      significant.push({
        ...question,
        value: want,
        warn: 'noAppointment',
      });
      if (want === 'yesProgesterone') {
        decisionSupport.push(
          decisionSupports.swapContraceptiveProgesterone('Patient ≥ 49 years')
        );
        return await pop(args);
      }
      return { abort: aborts.appointment };
    } else if (patient.age >= 40) {
      let want;

      if (lowDose) {
        const question = {
          type: 'choice',
          options: {
            keep: `I want my chosen contraceptive - I accept the risk.`,
            changeProgesterone:
              'I want to change to a progesterone only "mini" pill',
          },
        };
        want = await ask({
          ...question,
          description: `
Women aged 40 years or over have an increased risk of developing **serious side effects** from using combined contraception, such as **blood clots**, **heart attacks** or **strokes**.

Your risk is low as you have chosen a low dose pill.

To decrease this risk further we recommend choosing a progesterone only ""mini"" pill.

What would you like to do?
        `,
        });
        significant.push({
          ...question,
          label: 'Risk decision for ≥40s',
          value: want,
        });
      } else {
        const question = {
          type: 'choice',
          options: {
            keep: `I want my chosen contraceptive - I accept the risk.`,
            changeLowDose: 'I want to change to a low dose pill',
            changeProgesterone:
              'I want to change to a progesterone only "mini" pill',
          },
        };
        want = await ask({
          ...question,
          description: `
  Women aged 40 years or over have an increased risk of developing **serious side effects** from using combined contraception, such as **blood clots**, **heart attacks** or **strokes**.

  To decrease this risk, we recommend choosing a low dose combined pill, or better still a progesterone only ""mini"" pill.

  What would you like to do?
        `,
        });
        significant.push({
          ...question,
          label: 'Risk decision for ≥40s',
          value: want,
        });
      }
      if (want === 'changeLowDose') {
        decisionSupport.push(
          decisionSupports.swapContraceptiveLowDose('Patient ≥ 40 years')
        );
      } else if (want === 'changeProgesterone') {
        decisionSupport.push(
          decisionSupports.swapContraceptiveProgesterone('Patient ≥ 40 years')
        );

        return await pop(args);
      }
    }
  }

  const highBloodPressure = await ask(
    'Have you had high blood pressure while pregnant, even if your blood pressure is normal now?',
    {
      id: 'chc.highBloodPressure',
      expires: 999999,
    }
  );
  if (highBloodPressure === 'yes') {
    significant.push({
      label: 'Gestational hypertension',
      value: highBloodPressure,
    });
    decisionSupport.push(decisionSupports.gestationalHypertension);
  }

  const migraineWithAura = await ask.tertiary(
    'Have you ever suffered from migraines with aura? ',
    {
      id: 'chc.migrainesWithAura',
      description:
        'E.g. visual symptoms, numbness, tingling, speech difficulties, weakness of limbs or face.',
      expires: 999999,
    }
  );
  if (['yes', 'unknown'].includes(migraineWithAura)) {
    significant.push({
      type: 'tertiary',
      label: 'Migraines with aura',
      value: migraineWithAura,
      warn: true,
    });
    if (migraineWithAura === 'unknown') {
      decisionSupport.push(decisionSupports.migrainesWithAura);

      significant.push({
        type: 'text',
        label: 'Migraines with aura, description',
        value: await ask.text(
          'Please describe the symptoms you get with your migraines',
          {
            expires: 999999,
          }
        ),
      });
    } else if (migraineWithAura === 'yes') {
      return await changeOrAppointment(
        'You should not use combined hormonal contraception. This is because you have had a migraine with an aura.',
        'Migraine with aura'
      );
    }
  }

  const majorSurgery = await ask(
    'Have you had major surgery in the past 3 months, or are you about to have major surgery?',
    {
      id: 'chc.majorSurgery',
    }
  );
  if (majorSurgery === 'yes') {
    significant.push({
      label: 'Recent or imminent surgery',
      value: majorSurgery,
    });

    decisionSupport.push(decisionSupports.majorSurgery);

    significant.push({
      type: 'text',
      label: 'Recent or imminent surgery, description',
      value: await ask.text(
        'Please tell us what surgery you have had, or plan to have.'
      ),
      warn: true,
    });
  }

  const mobilityQuestion = {
    id: 'chc.mobility',
    type: 'choice',
    label: 'Do you have any problems with your mobility?',
    options: {
      none: 'None',
      walkingAid: 'Walking aid needed',
      wheelchair: 'Wheelchair user',
      other: 'Other',
    },
    expires: 365,
  };
  const mobility = await ask(mobilityQuestion);
  if (['walkingAid', 'wheelchair'].includes(mobility)) {
    significant.push({
      ...mobilityQuestion,
      label: 'Mobility problem',
      value: mobility,
      warn: 'wheelchair',
    });
  }
  if (mobility === 'other' || mobility === 'walkingAid') {
    decisionSupport.push(decisionSupports.mobility);

    significant.push({
      type: 'text',
      label: 'Details',
      value: await ask.text('Please describe your mobility problem', {
        expires: 365,
      }),
      warn: true,
    });
  } else if (mobility === 'wheelchair') {
    return await changeOrAppointment(
      'You should not use combined hormonal contraception. This is because together with your mobility problem, it puts you at greater risk of having a blood clot in your leg (DVT).',
      'Immobility'
    );
  }

  const gallstonesQuestion = {
    id: 'chc.gallstones',
    label: 'Have you ever had gallstones?',
    expires: 9999999,
  };
  const gallstones = await ask(gallstonesQuestion);
  if (gallstones === 'yes') {
    significant.push({
      ...gallstonesQuestion,
      value: gallstones,
    });
    const gallstonesRemovedQuestion = {
      label: 'Have you had your gallbladder removed?',
      expires: 9999999,
    };
    const gallstonesRemoved = await ask(gallstonesRemovedQuestion);
    significant.push({
      ...gallstonesRemovedQuestion,
      value: gallstonesRemoved,
      warn: 'no',
    });

    if (gallstonesRemoved === 'no') {
      return await changeOrAppointment(
        'You should not use combined hormonal contraception. This is because it might make your gallstones worse.',
        'Gallstones'
      );
    }
  }

  const sickleCell = await ask(
    'Do you have sickle cell disease? Not sickle cell trait.',
    {
      id: 'chc.sickeCell',
      expires: 9999999,
    }
  );
  if (sickleCell === 'yes') {
    significant.push({
      label: 'Sickle cell disease',
      value: sickleCell,
      warn: true,
    });
    return await changeOrAppointment(
      'You should not use combined hormonal contraception. This is because together with sickle cell disease, it puts you at higher risk of having a blood clot, heart attack or stroke.',
      'Sickle Cell Disease'
    );
  }

  const bloodClotQuestion = {
    id: 'chc.bloodClot',
    type: 'choice',
    label:
      'Do you have a condition that means you are more likely to get a blood clot?',
    options: {
      yes: 'Yes',
      yesHaveHad: 'Yes - I have had a blood clot before',
      no: 'No',
    },
    expires: 9999999,
  };
  const bloodClot = await ask(bloodClotQuestion);
  let bloodClotWho = [];
  if (['yes', 'yesHaveHad'].includes(bloodClot)) {
    significant.push({
      ...bloodClotQuestion,
      label: 'Thrombophilia',
      value: bloodClot,
      warn: true,
    });
  } else {
    const bloodClotFamily = await ask(
      'Has anyone in your family ever had a blood clot in their lungs or legs?',
      {
        id: 'chc.bloodClotFamily',
        expires: 9999999,
      }
    );
    if (bloodClotFamily === 'yes') {
      significant.push({
        label: 'FH of VTE',
        value: bloodClotFamily,
        warn: true,
      });
      const bloodClotWhoQuestion = {
        type: 'multipleChoice',
        options: {
          parent: 'Parent',
          sibling: 'Brother or sister',
          child: 'Son or daughter',
          other: 'Other',
        },
        expires: 9999999,
      };
      bloodClotWho = await ask({
        ...bloodClotWhoQuestion,
        label: 'Who had the blood clot?',
        description: 'More than one answer can be selected.',
      });
      significant.push({
        ...bloodClotWhoQuestion,
        label: 'Family member with VTE',
        value: bloodClotWho,
      });
      if (bloodClotWho.includes('other')) {
        decisionSupport.push(decisionSupports.bloodClot);

        significant.push({
          type: 'text',
          label: 'Family member with VTE, other',
          value: await ask.text('Who had the blood clot?', {
            expires: 9999999,
          }),
        });
      }
    }
  }
  if (
    bloodClot === 'yes' ||
    bloodClot === 'yesHaveHad' ||
    bloodClotWho.includes('parent') ||
    bloodClotWho.includes('sibling') ||
    bloodClotWho.includes('child')
  ) {
    return await changeOrAppointment(
      'You should not use combined hormonal contraception. This is because you have a personal or family history of blood clots.',
      'Thrombophillia'
    );
  }

  const heartAttackFamilyQuestion = {
    id: 'chc.heartAttackFamily',
    type: 'choice',
    label:
      'Has anyone in your close (biological) family had a heart attack or stroke under the age of 55?',
    options: {
      motherSister: 'Yes, a mom or sister',
      fatherBrother: 'Yes, a dad or brother',
      no: 'No',
    },
    expires: 9999999,
  };
  const heartAttackFamily = await ask(heartAttackFamilyQuestion);
  if (heartAttackFamily === 'motherSister') {
    significant.push({
      ...heartAttackFamilyQuestion,
      label: 'FH of MI or CVA < 55 years',
      value: heartAttackFamily,
      warn: true,
    });
  }
  let heartAttackYoungFatherBrother;
  if (heartAttackFamily === 'fatherBrother') {
    heartAttackYoungFatherBrother = await ask(
      'Were your dad or brother under the age of 45 when this happened?',
      { expires: 9999999 }
    );
    if (heartAttackYoungFatherBrother === 'yes') {
      significant.push({
        label: 'FH of MI or CVA Male < 45 years',
        value: heartAttackYoungFatherBrother,
        warn: true,
      });
    }
  }
  if (
    heartAttackFamily === 'motherSister' ||
    heartAttackYoungFatherBrother === 'yes'
  ) {
    return await changeOrAppointment(
      'You should not use combined hormonal contraception. This is because you have a family history of heart disease or stroke.',
      'FH of MI / CVA'
    );
  }

  const { weight, bmi, smoker } = await healthProfile(args);

  if (skinPatch && weight >= 90) {
    decisionSupport.push(decisionSupports.skinPatchContraindicated);
  }

  const reasons = [];
  if (bmi >= 34) {
    reasons.push('BMI ≥ 34');
  }
  if (smoker) {
    if (patient.age >= 35) {
      reasons.push('Age ≥ 35 + Smoker or recent smoker');
    }
    if (bmi >= 30) {
      reasons.push('BMI ≥ 30 + Smoker or stopped in the past year');
    }
  }
  if (reasons.length > 0) {
    decisionSupport.push(decisionSupports.chcContraindicated(reasons));
  }

  return {};
}: GuideWithIn<{
  doctorChoose: boolean,
  skinPatch: boolean,
  lowDose: boolean,
}>);

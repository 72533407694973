import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import aborts from './abort';

export default (async args => {
  const { ask } = args;
  const eChoice = createExtendedChoice(args);

  await ask.info(
    'Before we start, we need to make sure it is safe to treat your child online...'
  );

  const conditions = await eChoice({
    id: 'triage.currentSymptoms',
    label: 'Does your child have any of these symptoms right now?',
    isMultipleChoice: true,
    optional: true,
    clinicLabel: 'Signs of sepsis or anaphylaxis',
    options: [
      {
        value: 'fever',
        label: 'A fever',
        keyFinding: 'Fever',
        warn: true,
        abort: aborts.fever,
        abortPriority: 3,
      },
      {
        value: 'vomitingCantKeepFluidsDown',
        label: 'Vomiting and not able to keep fluids down',
        keyFinding: 'Vomiting and unable to keep fluids down',
        warn: true,
        abort: aborts.sepsis,
        abortPriority: 1,
      },
      {
        value: 'difficultyBreathing',
        label: 'Wheezing or difficulty breathing',
        keyFinding: 'Wheezing or shortness of breath',
        warn: true,
        abort: aborts.anaphylaxis,
        abortPriority: 2,
      },
      {
        value: 'notPassedUrine',
        label: 'Not passed urine for more than 8 hours',
        keyFinding: 'Not passed urine for more than 8 hours',
        warn: true,
        abort: aborts.sepsis,
        abortPriority: 1,
      },
      {
        value: 'swollenLipsMouthTongue',
        label: 'Swollen lips, mouth or tongue',
        keyFinding: 'Swollen lips, mouth or tongue',
        warn: true,
        abort: aborts.anaphylaxis,
        abortPriority: 2,
      },
      {
        value: 'nonBlanchingRash',
        label: 'A new rash which does not fade when rolling a glass over it',
        keyFinding: 'Non blanching rash',
        warn: true,
        abort: aborts.sepsis,
        abortPriority: 1,
      },
      {
        value: 'drowsiness',
        label: 'Not waking up, or if does wake up can not stay awake',
        keyFinding: 'Drowsiness',
        warn: true,
        abort: aborts.sepsis,
        abortPriority: 1,
      },
      {
        value: 'blueSkinLipsTongue',
        label: 'Blue skin, lips or a tongue',
        keyFinding: 'Blue skin, lips or a tongue',
        warn: true,
        abort: aborts.sepsis,
        abortPriority: 1,
      },
    ],
  });
  if (conditions.abort) {
    return { abort: conditions.abort };
  }

  const relatedVisit = await ask({
    id: 'triage.relatedToLastVisit',
    type: 'binary',
    label: "Is this visit related to your child's last Docly case?",
  });
  if (relatedVisit !== 'yes') {
    return { abort: aborts.unrelated };
  }

  return {};
}: Guide);

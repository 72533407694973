const decisionSupport = {
  early: {
    label: 'Medication requested early',
    color: 'black',
  },
  lost: {
    label: 'Lost repeat medication',
    color: 'black',
  },
  ranOut: {
    label: 'Repeat prescription requested early: used more than expected',
    color: 'black',
  },
  asthma: {
    label: 'Asthma Annual Review',
    color: 'black',
  },
  copd: {
    label: 'COPD Annual Review',
    color: 'black',
  },
  diabetes: {
    label: 'Diabetes Annual Review',
    description: `
- Foot review
- Retinopathy screening
- Recent HbA1C in last 6/12
- QRISK - consider lipid modification, BP
- Urine ACR in last 6/12
    `,
    color: 'black',
  },
  cholesterol: {
    label: 'Statins Annual Review',
    description: 'Check side effects & compliance',
    color: 'black',
  },
  bloodPressure: {
    label: 'Hypertension Annual Review',
    description: `
- BP in 6/12
- Renal function in last 12 months
- Urine ACR in last 12 months
- QRISK in last 12 months
    `,
    color: 'black',
  },
  bloodClot: {
    label: 'Anticoagulation Review',
    description: `
- Warfarin - check recent INR and plan for repeat INR
- DOAC - LFTs and U&Es in the last 6/12
    `,
    color: 'black',
  },
  mentalHealthReview: {
    label: 'Mental health review',
    color: 'black',
  },
  thyroidReview: {
    label: 'Thyroid Status Review',
    description: 'TFTs in last 12 months',
    color: 'black',
  },
  medicationReview: {
    label: 'Medication Review Consider',
    description: `
- Tests and monitoring required - Bloods / Imaging
- New evidence or guidelines
- Simplifcation or switches
    `,
    color: 'black',
  },
  controlledDrugs: {
    label: 'Do NOT prescribe CD1-4 drugs',
    color: 'black',
  },
  checkSpecialistLetter: {
    label: 'Check notes for specialist letter re: acute medication',
    color: 'black',
  },
  checkAcuteMedication: {
    label: 'Check acute medication in notes',
    color: 'black',
  },
};

// $FlowFixMe
Object.keys(decisionSupport).forEach(id => (decisionSupport[id].id = id));

export default decisionSupport;

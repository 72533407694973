export default {
  id: 'headache',
  type: 'formulary',
  version: '7',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '10',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började besvären?',
          index: '0.0.0',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'headache.seriousAndRecent',
          type: 'binary',
          version: '3',
          validations: {
            required: true,
          },
          label:
            'Har du en extremt kraftig huvudvärk som uppstod oväntat och på mindre än en minut, så kallad "åskknallshuvudvärk"?',
          index: '0.1.0',
        },
        {
          id: 'headache.hitToHead',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Började din huvudvärk efter ett slag mot huvudet?',
          index: '0.1.1',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.vomit',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Kräks du?',
          index: '0.2.0',
        },
        {
          id: 'health.fever',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du feber (över 38°C)?',
          index: '0.2.1',
        },
        {
          id: 'health.temperature',
          version: '4',
          type: 'number',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur hög feber har du just nu?',
          unit: '°C',
          index: '0.2.2',
        },
        {
          id: 'medication.recentAgainstFever',
          version: '1',
          type: 'binary',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Har du tagit febernedsättande läkemedel under de senaste 4 timmarna?',
          index: '0.2.3',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'headache.frequency',
          version: '3',
          type: 'choice',
          options: [
            {
              value: 'maxOncePerWeek',
              label: 'Högst en gång i veckan',
            },
            {
              value: 'severalTimesWeek',
              label: 'Flera gånger i veckan',
            },
            {
              value: 'daily',
              label: 'Dagligen',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Hur ofta har du huvudvärk?',
          index: '0.3.0',
        },
        {
          id: 'headache.regularity',
          version: '3',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Brukar huvudvärken komma under en viss tid på dygnet?',
          index: '0.3.1',
        },
        {
          id: 'headache.timeOfDay',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'earlyMorning',
              label: 'På morgonen',
            },
            {
              value: 'lateMorning',
              label: 'På förmiddagen',
            },
            {
              value: 'afternoon',
              label: 'På eftermiddagen',
            },
            {
              value: 'evening',
              label: 'På kvällen',
            },
            {
              value: 'night',
              label: 'På natten',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.headache.regularity') === 'yes'",
          label: 'När på dygnet brukar huvudvärken komma?',
          index: '0.3.2',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'headache.duration',
          type: 'choice',
          version: '2',
          options: [
            {
              value: 'lessThanMinute',
              label: 'Mindre än en minut',
            },
            {
              value: 'multipleMinutes',
              label: 'Flera minuter',
            },
            {
              value: 'moreThanHour',
              label: 'En timme eller mer',
            },
            {
              value: 'allDay',
              label: 'Hela dagen',
            },
            {
              value: 'manyDays',
              label: 'Flera dagar',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Hur länge varar huvudvärken?',
          description: 'Välj det alternativ som stämmer bäst.',
          index: '0.4.0',
        },
        {
          id: 'headache.worsened',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har din huvudvärk blivit värre under den senaste tiden?',
          index: '0.4.1',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'headache.location',
          type: 'multipleChoice',
          version: '3',
          options: [
            {
              value: 'oneSide',
              label: 'På ena sidan av huvudet',
            },
            {
              value: 'bothSides',
              label: 'På båda sidorna av huvudet',
            },
            {
              value: 'aroundNoseOrEyes',
              label: 'Kring ögonen eller näsan',
            },
            {
              value: 'forehead',
              label: 'I pannan',
            },
            {
              value: 'neck',
              label: 'I nacken',
            },
          ],
          validations: {
            required: false,
          },
          label: 'Var sitter huvudvärken?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.5.0',
        },
        {
          id: 'headache.coldIssues',
          type: 'binary',
          version: '1',
          condition:
            "contains(get('answers.headache.location'), 'forehead') || contains(get('answers.headache.location'), 'aroundNoseOrEyes')",
          validations: {
            required: true,
          },
          label: 'Har du nästäppa/rinnsnuva eller andra förkylningssymptom?',
          index: '0.5.1',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'headache.worseWhenChewing',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Blir huvudvärken värre när du tuggar?',
          index: '0.6.0',
        },
        {
          id: 'headache.soreCheekMuscles',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Är du öm i käkmusklerna?',
          index: '0.6.1',
        },
        {
          id: 'headache.soreTemples',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Är du öm kring tinningarna?',
          index: '0.6.2',
        },
        {
          id: 'headache.nauseaVomit',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Blir du illamående/kräks i samband med huvudvärken?',
          index: '0.6.3',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'headache.lightSoundSensitive',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Blir du ljus-/ljudkänslig i samband med huvudvärken?',
          index: '0.7.0',
        },
        {
          id: 'headache.lightFlashes',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Ser du ljusblixtar/-flimmer i samband med huvudvärken?',
          index: '0.7.1',
        },
        {
          id: 'headache.premonitions',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label:
            'Får du någon "förkänning" eller något föregående symptom innan huvudvärken sätter igång?',
          index: '0.7.2',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'headache.medication',
          type: 'composite',
          version: '2',
          children: [
            {
              id: 'headache.medication.match',
              type: 'binary',
              relativeId: 'match',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Har du provat några läkemedel mot din huvudvärk?',
              index: '0.8.0.0',
            },
            {
              id: 'headache.medication.what',
              type: 'multipleChoice',
              condition: "get('answers.headache.medication.match') === 'yes'",
              options: [
                {
                  value: 'paracetamol',
                  label: 'Paracetamol (t.ex. Alvedon, Panodil, Pamol)',
                },
                {
                  value: 'ibuprofen',
                  label: 'Ibuprofen (t.ex. Ipren, Ibumetin)',
                },
                {
                  value: 'diklofenak',
                  label: 'Diklofenak (t.ex. Voltaren)',
                },
                {
                  value: 'naproxen',
                  label: 'Naproxen',
                },
                {
                  value: 'acetylsalicylicacid',
                  label: 'Acetylsalicylsyra (t.ex. Treo, Aspirin)',
                },
                {
                  value: 'triptanes',
                  label:
                    'Triptaner (t.ex. Oriptan, Sumatriptan, Zomig, Relpax, Imigran)',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'what',
              version: '2',
              label: 'Vilka läkemedel har du provat?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.8.0.1',
            },
            {
              id: 'headache.medication.other',
              type: 'text',
              condition:
                "contains(get('answers.headache.medication.what'), 'other')",
              relativeId: 'other',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Vilka andra läkemedel har du provat?',
              index: '0.8.0.2',
            },
            {
              id: 'headache.medication.effect',
              type: 'text',
              condition: "get('answers.headache.medication.match') === 'yes'",
              relativeId: 'effect',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Hur fungerade läkemedlen?',
              index: '0.8.0.3',
            },
          ],
          extras: {},
          index: '0.8.0',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.similar',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du haft liknande besvär tidigare?',
          index: '0.9.0',
        },
        {
          id: 'problem.previousTreatment.visitMatch',
          version: '1',
          type: 'binary',
          condition: "get('answers.problem.similar') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Har du tidigare sökt läkare för detta besvär?',
          index: '0.9.1',
        },
        {
          id: 'problem.previousTreatment.visitWhen',
          version: '2',
          type: 'text',
          condition:
            "get('answers.problem.previousTreatment.visitMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'När sökte du läkare?',
          index: '0.9.2',
        },
        {
          id: 'problem.previousTreatment.brainScan.match',
          type: 'binary',
          version: '1',
          condition:
            "get('answers.problem.previousTreatment.visitMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Har du genomgått röntgen av hjärnan?',
          index: '0.9.3',
        },
        {
          id: 'problem.previousTreatment.brainScan.what',
          type: 'text',
          version: '1',
          condition:
            "get('answers.problem.previousTreatment.brainScan.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vad visade röntgen?',
          index: '0.9.4',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosisMatch',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.problem.previousTreatment.visitMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Fick du en diagnos?',
          index: '0.9.5',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosis',
          version: '1-headache',
          type: 'multipleChoice',
          options: [
            {
              value: 'tension',
              label: 'Spänningshuvudvärk',
            },
            {
              value: 'migraine',
              label: 'Migrän',
            },
            {
              value: 'horton',
              label: 'Hortons huvudvärk',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition:
            "get('answers.problem.previousTreatment.visitDiagnosisMatch') === 'yes'",
          label: 'Vilken diagnos fick du?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.9.6',
        },
        {
          id: 'problem.previousTreatment.visitOtherDiagnosis',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.problem.previousTreatment.visitDiagnosis'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilken annan diagnos fick du?',
          index: '0.9.7',
        },
      ],
      version: '1',
      index: '0.9',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.10.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.10.1',
        },
      ],
      version: '1',
      index: '0.10',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.11.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.11.1',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.11',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.12.0',
        },
      ],
      version: '1',
      index: '0.12',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.13',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.14',
    },
  ],
  variables: [
    {
      id: 'headache.seriousAndRecentHeadache',
      type: 'abort',
      expression: "get('answers.headache.seriousAndRecent') === 'yes'",
      relativeId: 'seriousAndRecentHeadache',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'headache.hitToHead',
      type: 'abort',
      expression: "get('answers.headache.hitToHead') === 'yes'",
      relativeId: 'hitToHead',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'headache.headacheFeverVomit',
      type: 'abort',
      expression:
        "get('answers.health.vomit') === 'yes' && get('answers.health.fever') === 'yes'",
      relativeId: 'headacheFeverVomit',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären började',
          description: '',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'headache.seriousAndRecent',
          warning: "get('answers.headache.seriousAndRecent') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Extremt kraftig plötslig huvudvärk (åskknallshuvudvärk)',
          description: '',
        },
        {
          id: 'headache.hitToHead',
          warning: "get('answers.headache.hitToHead') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudvärken började efter slag mot huvudet',
          description: '',
        },
        {
          id: 'health.vomit',
          warning: "get('answers.health.vomit') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kräkningar',
          description: '',
        },
        {
          id: 'health.fever',
          warning: "get('answers.health.fever') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber',
          description: '',
        },
        {
          id: 'health.temperature',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Temperatur',
          description: '',
          unit: '°C',
        },
        {
          id: 'medication.recentAgainstFever',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Febernedsättande läkemedel under de senaste 4 timmarna',
          description: '',
        },
        {
          id: 'headache.frequency',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har huvudvärk',
          description: '',
          options: [
            {
              value: 'maxOncePerWeek',
              label: 'Högst en gång i veckan',
            },
            {
              value: 'severalTimesWeek',
              label: 'Flera gånger i veckan',
            },
            {
              value: 'daily',
              label: 'Dagligen',
            },
          ],
        },
        {
          id: 'headache.regularity',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudvärk viss tid på dygnet',
          description: '',
        },
        {
          id: 'headache.timeOfDay',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tid på dygnet',
          description: '',
          options: [
            {
              value: 'earlyMorning',
              label: 'På morgonen',
            },
            {
              value: 'lateMorning',
              label: 'På förmiddagen',
            },
            {
              value: 'afternoon',
              label: 'På eftermiddagen',
            },
            {
              value: 'evening',
              label: 'På kvällen',
            },
            {
              value: 'night',
              label: 'På natten',
            },
          ],
        },
        {
          id: 'headache.duration',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Duration',
          description: '',
          options: [
            {
              value: 'lessThanMinute',
              label: 'Mindre än en minut',
            },
            {
              value: 'multipleMinutes',
              label: 'Flera minuter',
            },
            {
              value: 'moreThanHour',
              label: 'En timme eller mer',
            },
            {
              value: 'allDay',
              label: 'Hela dagen',
            },
            {
              value: 'manyDays',
              label: 'Flera dagar',
            },
          ],
        },
        {
          id: 'headache.worsened',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Eskalerande symptom',
          description: '',
        },
        {
          id: 'headache.location',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Lokalisation',
          description: '',
          options: [
            {
              value: 'oneSide',
              label: 'På ena sidan av huvudet',
            },
            {
              value: 'bothSides',
              label: 'På båda sidorna av huvudet',
            },
            {
              value: 'aroundNoseOrEyes',
              label: 'Kring ögonen eller näsan',
            },
            {
              value: 'forehead',
              label: 'I pannan',
            },
            {
              value: 'neck',
              label: 'I nacken',
            },
          ],
        },
        {
          id: 'headache.coldIssues',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Förkylningssymptom',
          description: '',
        },
        {
          id: 'headache.worseWhenChewing',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Förvärras när pat tuggar',
          description: '',
        },
        {
          id: 'headache.soreCheekMuscles',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Öm i käkmuskler',
          description: '',
        },
        {
          id: 'headache.soreTemples',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Öm kring tinningar',
          description: '',
        },
        {
          id: 'headache.nauseaVomit',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Illamående/kräks vid huvudvärk',
          description: '',
        },
        {
          id: 'headache.lightSoundSensitive',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ljus-/ljudkänslig vid huvudvärk',
          description: '',
        },
        {
          id: 'headache.lightFlashes',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ljusblixtar/-flimmer vid huvudvärk',
          description: '',
        },
        {
          id: 'headache.premonitions',
          type: 'question',
          version: '1',
          extras: {},
          label: '"Förkänning"/föregående symptom',
          description: '',
        },
        {
          id: 'headache.medication.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Provat läkemedel',
          description: '',
        },
        {
          id: 'headache.medication.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel',
          description: '',
          options: [
            {
              value: 'paracetamol',
              label: 'Paracetamol (t.ex. Alvedon, Panodil, Pamol)',
            },
            {
              value: 'ibuprofen',
              label: 'Ibuprofen (t.ex. Ipren, Ibumetin)',
            },
            {
              value: 'diklofenak',
              label: 'Diklofenak (t.ex. Voltaren)',
            },
            {
              value: 'naproxen',
              label: 'Naproxen',
            },
            {
              value: 'acetylsalicylicacid',
              label: 'Acetylsalicylsyra (t.ex. Treo, Aspirin)',
            },
            {
              value: 'triptanes',
              label:
                'Triptaner (t.ex. Oriptan, Sumatriptan, Zomig, Relpax, Imigran)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'headache.medication.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel, annat',
          description: '',
        },
        {
          id: 'headache.medication.effect',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Effekt av läkemedel',
          description: '',
        },
        {
          id: 'problem.similar',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Liknande besvär tidigare',
          description: '',
        },
        {
          id: 'problem.previousTreatment.visitMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare sökt läkare',
          description: '',
        },
        {
          id: 'problem.previousTreatment.visitWhen',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sökt läkare när',
          description: '',
        },
        {
          id: 'problem.previousTreatment.brainScan.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Röntgen av hjärnan',
          description: '',
        },
        {
          id: 'problem.previousTreatment.brainScan.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Resultat av röntgen',
          description: '',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosisMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fått diagnos',
          description: '',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diagnos',
          description: '',
          options: [
            {
              value: 'tension',
              label: 'Spänningshuvudvärk',
            },
            {
              value: 'migraine',
              label: 'Migrän',
            },
            {
              value: 'horton',
              label: 'Hortons huvudvärk',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'problem.previousTreatment.visitOtherDiagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diagnos, annat',
          description: '',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
          description: '',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
          description: '',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Huvudvärk',
  index: '0',
};

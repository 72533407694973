// @flow

export const otherAnticonceptionMedication = {
  id: 1000,
  label: 'Annat',
  value: 'other',
  description: '',
  tags: [
    'anticonception_pill',
    'anticonception_hormonal_iud',
    'anticonception_ring',
    'anticonception_injection',
    'anticonception_implant',
    'anticonception_patch',
  ],
};

type Medication = {
  id: number,
  label?: string,
  value: string | number,
  description?: string,
  tags: string[],
  country?: string, // 'SE'
};

export const anticonceptionMedicationList: Medication[] = [
  {
    id: 1,
    label: 'Abelonelle 28',
    value: 'abelonelle_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 2,
    label: 'Amorest 28',
    value: 'amorest_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 3,
    label: 'Anastrella 28',
    value: 'anastrella_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 4,
    label: 'Azalia',
    value: 'azalia',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 5,
    label: 'Cerazette',
    value: 'cerazette',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 7,
    label: 'Daylette',
    value: 'daylette',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception,combined'],
  },
  {
    id: 8,
    label: 'Depo-Provera',
    value: 'depo_provera',
    description: 'Gestagen/P-spruta',
    tags: ['anticonception', 'anticonception_injection'],
  },
  {
    id: 9,
    label: 'Desogestrel Orifarm',
    value: 'desogestrel_orifarm',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 10,
    label: 'Desogestrel Sandoz',
    value: 'desogestrel_sandoz',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 11,
    label: 'Desogestrel STADA',
    value: 'desogestrel_stada',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 107,
    label: 'Desolett',
    value: 'desolett',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 108,
    label: 'Desolett 28',
    value: 'desolett_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 105,
    label: 'Dienorette',
    value: 'dienorette',
    description: 'Kombinerat',
    tags: ['anticonception', 'anticonception_pill', 'combined'],
  },
  {
    id: 12,
    label: 'Diza',
    value: 'diza',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 13,
    label: 'Dizmine 28',
    value: 'dizmine_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 14,
    label: 'Dizminelle',
    value: 'dizminelle',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 16,
    label: 'Estrelen',
    value: 'estrelen',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 17,
    label: 'Estron',
    value: 'estron',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 44,
    label: 'Evra',
    value: 'evra',
    description: 'Kombinerat/Plåster',
    tags: ['anticonception_patch', 'anticonception', 'combined'],
  },
  {
    id: 18,
    label: 'Exlutena',
    value: 'exlutena',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception', 'mini'],
  },
  {
    id: 19,
    label: 'Gestrina',
    value: 'gestrina',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 46,
    label: 'Jaydess',
    value: 'jaydess',
    description: 'Gestagen/Hormonspiral',
    tags: ['anticonception_hormonal_iud', 'anticonception'],
  },
  {
    id: 47,
    label: 'Kyleena',
    value: 'kyleena',
    description: 'Gestagen/Hormonspiral',
    tags: ['anticonception_hormonal_iud', 'anticonception'],
  },
  {
    id: 111,
    label: 'Leverette',
    value: 'leverette',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 106,
    label: 'Levesia',
    value: 'levensia',
    description: 'Kombinerat',
    tags: ['anticonception', 'anticonception_pill', 'combined'],
  },
  {
    id: 110,
    label: 'Levosert',
    value: 'levosert',
    description: 'Gestagen/Hormonspiral',
    tags: ['anticonception_hormonal_iud', 'anticonception'],
  },
  {
    id: 20,
    label: 'Mercilon',
    value: 'mercilon',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 21,
    label: 'Midiana',
    value: 'midiana',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 22,
    label: 'Midiana 28',
    value: 'midiana_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 23,
    label: 'Mini-Pe',
    value: 'mini_pe',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception', 'mini'],
  },
  {
    id: 48,
    label: 'Mirena',
    value: 'mirena',
    description: 'Gestagen/Hormonspiral',
    tags: ['anticonception_hormonal_iud', 'anticonception'],
  },
  {
    id: 24,
    label: 'Neovletta',
    value: 'neovletta',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 25,
    label: 'Neovletta 28',
    value: 'neovletta_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 49,
    label: 'Nexplanon',
    value: 'nexplanon',
    description: 'Gestagen/P-stav',
    tags: ['anticonception_implant', 'anticonception'],
  },
  {
    id: 45,
    label: 'NuvaRing',
    value: 'nuvaring',
    description: 'Kombinerat/P-ring',
    tags: ['anticonception_ring', 'anticonception', 'combined'],
    country: 'SE',
  },
  {
    id: 26,
    label: 'Ornibel',
    value: 'ornibel',
    description: 'Kombinerat/P-ring',
    tags: ['anticonception_ring', 'anticonception', 'combined'],
  },
  {
    id: 27,
    label: 'Prionelle',
    value: 'prionelle',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 28,
    label: 'Prionelle 28',
    value: 'prionelle_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 29,
    label: 'Qlaira',
    value: 'qlaira',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 30,
    label: 'Rigevidon 28',
    value: 'rigevidon_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 31,
    label: 'Rosal 28',
    value: 'rosal_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 112,
    label: 'Slinda',
    value: 'slinda',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 33,
    label: 'Trionetta',
    value: 'trionetta',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 34,
    label: 'Trionetta 28',
    value: 'trionetta_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 109,
    label: 'Vagiprev',
    value: 'vagiprev',
    description: 'Kombinerat/P-ring',
    tags: ['anticonception_ring', 'anticonception,combined'],
  },
  {
    id: 35,
    label: 'Vinelle',
    value: 'vinelle',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 36,
    label: 'Yasmin',
    value: 'yasmin',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 37,
    label: 'Yasmin 28',
    value: 'yasmin_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 38,
    label: 'Yasminelle',
    value: 'yasminelle',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 39,
    label: 'Yasminelle 28',
    value: 'yasminelle_28',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 40,
    label: 'Yaz',
    value: 'yaz',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
  {
    id: 41,
    label: 'Zarelle',
    value: 'zarelle',
    description: 'Gestagen',
    tags: ['anticonception_pill', 'anticonception'],
  },
  {
    id: 43,
    label: 'Zoely',
    value: 'zoely',
    description: 'Kombinerat',
    tags: ['anticonception_pill', 'anticonception', 'combined'],
  },
];

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const allergyOther = {
    type: 'binary',
    id: 'allergy.other',
    label: 'Har du några andra kända allergier?',
    description: 'T.ex. husdjur eller pollen.',
    expires: 900,
  };

  const allergyOtherAnswer = await ask(allergyOther);

  significant.push({
    ...allergyOther,
    label: 'Andra allergier',
    description: '',
    value: allergyOtherAnswer,
  });

  if (allergyOtherAnswer === 'yes') {
    const allergyOtherWhat = {
      type: 'multipleChoice',
      id: 'allergy.other.what',
      label: 'Vad är du allergisk mot?',
      description: 'Flera svarsalternativ möjliga.',
      options: [
        {
          value: 'pollen',
          label: 'Pollen',
        },
        {
          value: 'fur',
          label: 'Pälsdjur',
        },
        {
          value: 'mite',
          label: 'Kvalster',
        },
        {
          value: 'food',
          label: 'Födoämnen',
        },
        {
          value: 'other',
          label: 'Annat',
        },
      ],
      expires: 900,
    };

    const allergyOtherWhatAnswer = await ask(allergyOtherWhat);

    significant.push({
      ...allergyOtherWhat,
      label: 'Andra allergier',
      description: '',
      value: allergyOtherWhatAnswer,
    });

    if (allergyOtherWhatAnswer.includes('other')) {
      const allergyOtherOther = {
        type: 'text',
        id: 'allergy.other.other',
        label: 'Vad är du allergisk mot?',
        expires: 900,
      };

      const allergyOtherOtherAnswer = await ask(allergyOtherOther);

      significant.push({
        ...allergyOtherOther,
        label: 'Fri beskrivning av andra allergier',
        value: allergyOtherOtherAnswer,
      });
    }
  }

  return {};
}: Guide);

import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

import abort from '../common/commonAborts';
import ds from './decisionSupport';
import type { Guide } from '../../types';
import { coronaSeason } from '../common/consts';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  // TODO helper function
  await eChoice({
    id: 'otherProblems.shortOfBreathSevereIllness',
    label: `Difficulty in breathing can be a sign of a serious illness.`,
    description: `
If you feel unwell, are struggling with breathing at rest, or have noticed a blue colour to your fingers or lips — you need urgent medical attention.
    
**If it is outside of normal working hours, or it is the weekend, please go to to A&E or dial 999.**
    `,
    options: [
      {
        label: 'I understand',
        value: 'understand',
        hide: true,
      },
    ],
  });

  const shortOfBreathCurrentBreathing = await eChoice({
    id: 'otherProblems.shortOfBreathCurrentBreathing',
    label: `When do you feel short of breath?`,
    clinicLabel: 'When do you feel short of breath',
    options: [
      {
        label: 'During exercise e.g. running or fast walking',
        value: 'exercising',
      },
      {
        label: 'Walking at the same speed as other people',
        value: 'walkingNormal',
      },
      {
        label: 'After a few minutes of walking at a gentle pace',
        value: 'walkingForAFewMinutes',
        warn: true,
        urgent: true,
        ds: ds.pvConsultDifficultyBreathing,
        abort: coronaSeason ? undefined : abort.adultDifficultyBreathingUrgent,
      },
      {
        label: 'Doing simple day to day things e.g. getting dressed',
        value: 'dressing',
        keyFinding: 'Short of breath dressing',
        warn: true,
        urgent: true,
        ds: ds.pvConsultDifficultyBreathing,
        abort: coronaSeason ? undefined : abort.adultDifficultyBreathingUrgent,
      },
      {
        label: 'Resting or sitting',
        value: 'resting',
        warn: true,
        keyFinding: 'Dyspnoea at rest',
        abort: abort.adultDifficultyBreathingAe,
      },
      {
        label: 'Trying to talk',
        value: 'talking',
        warn: true,
        keyFinding: 'Unable to complete sentences',
        abort: abort.adultDifficultyBreathingAe,
      },
    ],
  });
  if (shortOfBreathCurrentBreathing.abort) {
    return { abort: shortOfBreathCurrentBreathing.abort };
  }

  const breathingChanged = await eChoice({
    id: 'otherProblems.breathingChanged',
    label: `When did your breathing change?`,
    clinicLabel: 'Breathing changed',
    options: [
      {
        label: 'Less than 24 hours ago',
        value: 'lessThan24Hours',
        keyFinding: 'Breathing changed less than 24 hours ago',
        warn: true,
        urgent: true,
      },
      {
        label: '1–7 days ago',
        value: '1to7Days',
        keyFinding: 'Breathing changed 1–7 days ago',
        warn: true,
        urgent: true,
      },
      {
        label: '1–4 weeks ago',
        value: '1to4Weeks',
        keyFinding: 'Breathing changed 1–4 weeks ago',
      },
      {
        label: '1–3 months ago',
        value: '1to3Months',
        keyFinding: 'Breathing changed 1–3 months ago',
      },
      {
        label: 'More than 3 months ago',
        value: 'moreThan3Months',
        keyFinding: 'Breathing changed more than 3 months ago',
        hide: true,
      },
    ],
  });

  if (breathingChanged === 'moreThan3Months') {
    await eText({
      id: 'otherProblems.breathingChangedOver3MonthsAgo',
      label: `When exactly did your breathing change?`,
      clinicLabel: 'When did breathing change',
    });
  }

  return {};
}: Guide);

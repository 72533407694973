import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';

import Fixed from '../components/Fixed';
import ActionMenu from './actions/ActionMenu';

import { withLocaleByCountry } from '../locale';
import HistoryDraw from './draws/HistoryDraw';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenDraws } from '../../state/ui/selectors';
import { closeDraw } from '../../state/ui/actions';

type Props = {
  country: string,
  guideId: string,
  children: React.Node,
  width: string,
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  body: {
    flexGrow: 1,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'scroll',
  },
  drawer: {
    flexShrink: 0,
    height: '100vh',
    overflow: 'scroll',
  },
  drawerClosed: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    width: '100vw',
    height: `calc(100% - ${theme.spacing(4)}px)`,
    maxWidth: 400,
    padding: `${theme.spacing(2)}px 0px`,
  },
  menu: {
    [theme.breakpoints.down('xs')]: { marginRight: 56 + theme.spacing() },
  },
}));

function GuideLayout({ country, guideId, children, width }: Props) {
  const classes = useStyles();
  const isSmallScreen = /xs|sm/.test(width);

  const openDraws = useSelector(getOpenDraws);
  const dispatch = useDispatch();

  const leftDrawOpen = openDraws.history === true;

  return (
    <div className={classes.container}>
      <HistoryDraw
        guideId={guideId}
        open={openDraws.history === true}
        onRequestClose={() => dispatch(closeDraw('history'))}
        variant={isSmallScreen ? 'temporary' : 'permanent'}
        anchor="left"
        transitionDuration={0}
        classes={{
          paper: classes.drawerPaper,
        }}
        className={cn(classes.drawer, {
          [classes.drawerClosed]: openDraws.history !== true,
        })}
      />

      <div className={classes.body}>{children}</div>

      <Fixed vertical="top" horizontal="right" spacing className={classes.menu}>
        <ActionMenu
          country={country}
          guideId={guideId}
          alwaysOpen={!isSmallScreen && !leftDrawOpen}
        />
      </Fixed>
    </div>
  );
}

export default withLocaleByCountry()(withWidth()(GuideLayout));

export default {
  id: 'cough',
  type: 'formulary',
  version: '3',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '9',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började dina besvär?',
          index: '0.0.0',
        },
        {
          id: 'cough.startedWithCold',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Började hostan med en vanlig förkylning?',
          index: '0.0.1',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.fever',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du feber (över 38°C)?',
          index: '0.1.0',
        },
        {
          id: 'health.temperature',
          version: '3',
          type: 'number',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur hög feber har du?',
          unit: '°C',
          index: '0.1.1',
        },
        {
          id: 'medication.recentAgainstFever',
          version: '2',
          type: 'binary',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Har du tagit något febernedsättande läkemedel under de senaste 4 timmarna?',
          index: '0.1.2',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'cough.coughingSlime',
          type: 'composite',
          version: '2',
          children: [
            {
              id: 'cough.coughingSlime.match',
              type: 'binary',
              relativeId: 'match',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Hostar du upp slem?',
              index: '0.2.0.0',
            },
            {
              id: 'cough.coughingSlime.describe',
              type: 'text',
              condition: "get('answers.cough.coughingSlime.match') === 'yes'",
              relativeId: 'describe',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Vilket utseende och färg har slemmet?',
              index: '0.2.0.1',
            },
          ],
          extras: {},
          index: '0.2.0',
        },
        {
          id: 'cough.coughingBlood',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du hostat upp blod?',
          index: '0.2.1',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'cough.wheezingBreath',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Piper det i dina luftrör när du andas?',
          index: '0.3.0',
        },
        {
          id: 'cough.outOfBreath',
          type: 'composite',
          version: '2',
          children: [
            {
              id: 'cough.outOfBreath.match',
              type: 'binary',
              relativeId: 'match',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Känner du dig andfådd?',
              index: '0.3.1.0',
            },
            {
              id: 'cough.outOfBreath.describe',
              type: 'choice',
              condition: "get('answers.cough.outOfBreath.match') === 'yes'",
              options: [
                {
                  value: 'suddenContinuous',
                  label:
                    'Jag blev plötsligt andfådd utan förklaring och har fortsatt att vara det',
                },
                {
                  value: 'lightStrain',
                  label: 'Jag blir andfådd vid lättare ansträngning',
                },
                {
                  value: 'whenCoughing',
                  label:
                    'Jag hostar väldigt mycket så att det känns som att jag tappar andan',
                },
                {
                  value: 'none',
                  label: 'Inget av ovanstående',
                },
              ],
              relativeId: 'describe',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Vad stämmer bäst överens med din andfåddhet?',
              index: '0.3.1.1',
            },
          ],
          extras: {},
          index: '0.3.1',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'cough.similarSymptoms',
          type: 'composite',
          version: '3',
          children: [
            {
              id: 'cough.similarSymptoms.match',
              type: 'binary',
              relativeId: 'match',
              version: '3',
              validations: {
                required: true,
              },
              label: 'Har du någon gång tidigare haft liknande besvär?',
              index: '0.4.0.0',
            },
            {
              id: 'cough.similarSymptoms.matchDoctor',
              type: 'binary',
              condition: "get('answers.cough.similarSymptoms.match') === 'yes'",
              relativeId: 'matchDoctor',
              version: '3',
              validations: {
                required: true,
              },
              label: 'Har du tidigare sökt läkare för denna typ av besvär?',
              index: '0.4.0.1',
            },
            {
              id: 'cough.similarSymptoms.diagnosis',
              type: 'multipleChoice',
              condition:
                "get('answers.cough.similarSymptoms.matchDoctor') === 'yes'",
              options: [
                {
                  value: 'pneumonia',
                  label:
                    'Lunginflammation (Penicillin- eller antibiotikabehandlad)',
                },
                {
                  value: 'respiratory',
                  label: 'Luftvägsinfektion',
                },
                {
                  value: 'astma',
                  label: 'Astma/KOL',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'diagnosis',
              version: '3',
              label: 'Vilken diagnos fick du då?',
              index: '0.4.0.2',
            },
            {
              id: 'cough.similarSymptoms.diagnosisOther',
              type: 'text',
              condition:
                "contains(get('answers.cough.similarSymptoms.diagnosis'), 'other')",
              relativeId: 'diagnosisOther',
              version: '3',
              validations: {
                required: true,
              },
              label: 'Vilken annan diagnos fick du?',
              index: '0.4.0.3',
            },
          ],
          extras: {},
          index: '0.4.0',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.5.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.5.1',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.6.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.6.1',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'cough.video',
          type: 'upload',
          version: '1',
          types: 'video/mp4,video/webm,video/quicktime',
          validations: {
            required: false,
            max: 1,
          },
          label:
            'För att läkaren på bästa sätt ska kunna bedöma din hosta har du här möjlighet att ladda upp en kort film på dig själv på max 20 sekunder som fångar hur din hosta låter.',
          description:
            '  <p>\n    För att ljudkvaliteten ska bli så bra som möjligt behöver det vara tyst i bakgrunden.\n  </p>\n\n  <p>\n    Observera att även filmen är en del av din journal och omfattas av sekretess.\n  </p>',
          index: '0.7.0',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.8.0',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.9',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.10',
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakliga besvär',
          description: '',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
          description: '',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären började',
          description: '',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'cough.startedWithCold',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hostan började med en vanlig förkylning',
          description: '',
        },
        {
          id: 'health.fever',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber',
          description: '',
        },
        {
          id: 'health.temperature',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber',
          description: '',
          unit: '°C',
        },
        {
          id: 'medication.recentAgainstFever',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tagit febernedsättande senaste 4 timmarna',
        },
        {
          id: 'cough.coughingSlime.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Hostar upp slem',
          description: '',
        },
        {
          id: 'cough.coughingSlime.describe',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Slem - utseende och färg',
          description: '',
        },
        {
          id: 'cough.coughingBlood',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har hostat blod',
          description: '',
        },
        {
          id: 'cough.wheezingBreath',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Piper i luftrören',
          description: '',
        },
        {
          id: 'cough.outOfBreath.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andfådd',
          description: '',
        },
        {
          id: 'cough.outOfBreath.describe',
          warning:
            "get('answers.cough.outOfBreath.describe') === 'suddenContinuous' || get('answers.cough.outOfBreath.describe') === 'lightStrain'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andfåddhetens debut/karaktär',
          description: '',
          options: [
            {
              value: 'suddenContinuous',
              label:
                'Jag blev plötsligt andfådd utan förklaring och har fortsatt att vara det',
            },
            {
              value: 'lightStrain',
              label: 'Jag blir andfådd vid lättare ansträngning',
            },
            {
              value: 'whenCoughing',
              label:
                'Jag hostar väldigt mycket så att det känns som att jag tappar andan',
            },
            {
              value: 'none',
              label: 'Inget av ovanstående',
            },
          ],
        },
        {
          id: 'cough.similarSymptoms.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Liknande besvär tidigare',
          description: '',
        },
        {
          id: 'cough.similarSymptoms.matchDoctor',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare sökt läkare',
          description: '',
        },
        {
          id: 'cough.similarSymptoms.diagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare diagnos',
          description: '',
          options: [
            {
              value: 'pneumonia',
              label:
                'Lunginflammation (Penicillin- eller antibiotikabehandlad)',
            },
            {
              value: 'respiratory',
              label: 'Luftvägsinfektion',
            },
            {
              value: 'astma',
              label: 'Astma/KOL',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'cough.similarSymptoms.diagnosisOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Annan diagnos',
          description: '',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
          description: '',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
          description: '',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Hosta',
  index: '0',
};

import { type Action } from '../types';

import { CLOSE_DRAW, TOGGLE_DRAW } from './types';

export const toggleDraw = (name: string): Action => ({
  type: TOGGLE_DRAW,
  payload: { name },
});

export const closeDraw = (name: string): Action => ({
  type: CLOSE_DRAW,
  payload: { name },
});

import {
  type RawQuestion,
  type Question,
  type RawAnswer,
  type Answer,
  type RawOption,
  type Option,
  type Condition,
  type Limit,
  type Patient,
  type Guide,
  type Result,
  questionTypes,
} from '../guides/types';

const hash = (o: *) => {
  return JSON.stringify(o)
    .split('')
    .reduce((result, c) => {
      return ((result << 5) - result + c.charCodeAt(0)) | 0;
    }, 0)
    .toString(16)
    .replace('-', '1');
};

const validateValue = (question, value) => {
  const { type, options = [], optional = false, min = {}, max = {} } = question;

  switch (type) {
    case 'binary':
      return ['yes', 'no'].includes(value);

    case 'choice':
      return options.map(({ value }) => value).includes(value);

    case 'info':
      return value === 'yes';

    case 'multipleChoice': {
      if (!Array.isArray(value)) {
        return false;
      }

      if (!optional && !value.length) {
        return false;
      }

      const optionValues = options.map(({ value }) => value);

      for (let i = 0; i < value.length; i++) {
        if (!optionValues.includes(value[i])) {
          return false;
        }
      }

      return true;
    }

    case 'number':
    case 'range':
      if (typeof value !== 'number') {
        return false;
      }

      if (min.value !== undefined && value < min.value) {
        return false;
      }

      if (max.value !== undefined && value > max.value) {
        return false;
      }

      return true;

    case 'tertiary':
      return ['yes', 'no', 'unknown'].includes(value);

    case 'text':
      if (typeof value !== 'string') {
        return false;
      }

      const trimmedValue = value.trim();

      if (!optional && !trimmedValue.length) {
        return false;
      }

      if (min.value !== undefined && trimmedValue.length < min.value) {
        return false;
      }

      if (max.value !== undefined && trimmedValue.length > max.value) {
        return false;
      }

      return true;

    case 'upload': {
      if (!Array.isArray(value)) {
        return false;
      }

      if (!optional && !value.length) {
        return false;
      }

      return true;
    }

    default:
      return true;
  }
};

const evaluateCondition = (
  condition?: Condition,
  value: *,
  defaultValue: boolean
): boolean => {
  if (condition === undefined) {
    return defaultValue;
  }

  if (condition === true || condition === false) {
    return condition;
  }

  if (
    typeof condition === 'function' &&
    {}.toString.call(condition) === '[object Function]'
  ) {
    return !!condition(value);
  }

  if (Array.isArray(condition)) {
    return Array.isArray(value)
      ? !!value.find(v => condition.includes(v))
      : condition.includes(value);
  }

  return Array.isArray(value)
    ? !!value.find(v => v === condition)
    : condition === value;
};

const createAnswer = (question: Question | Answer, value: *): Answer => {
  const { warn, ...other } = question;

  return {
    ...other,
    value,
    warn:
      warn !== undefined ? evaluateCondition(warn, value, false) : undefined,
  };
};

const alignQuestionLimit = (limit: number | Limit): Limit => {
  if (typeof limit === 'number') {
    return {
      value: limit,
    };
  }

  return {
    value: Number(limit.value),
    label: limit.label !== undefined ? String(limit.label) : undefined,
  };
};

const alignQuestionOptions = (
  options: (string | void | RawOption)[] | { [string]: string }
): Option[] => {
  if (Array.isArray(options)) {
    return options
      .filter(option => !!option)
      .map(option =>
        typeof option === 'object'
          ? {
              value: option.value !== undefined ? option.value : '',
              label:
                option.label !== undefined
                  ? option.label
                  : String(option.value),
            }
          : { value: option !== undefined ? option : '', label: String(option) }
      );
  }

  if (typeof options === 'object') {
    return Object.keys(options).map(value => ({
      value,
      label: options[value] !== undefined ? options[value] : value,
    }));
  }

  return [];
};

const supportsOptional = ['multipleChoice', 'number', 'text', 'upload'];

const createQuestion = (
  rawQuestion: RawQuestion | RawAnswer,
  visited: string[]
): Question => {
  const question = {
    id: rawQuestion.id,
    type: rawQuestion.type || 'binary',
    label: rawQuestion.label,
    description: rawQuestion.description,
    warn: rawQuestion.warn,
    expires: rawQuestion.expires,
    optional: supportsOptional.includes(rawQuestion.type)
      ? rawQuestion.optional
      : undefined,
    options:
      rawQuestion.options !== undefined
        ? alignQuestionOptions(rawQuestion.options)
        : undefined,
    min:
      rawQuestion.min !== undefined
        ? alignQuestionLimit(rawQuestion.min)
        : undefined,
    max:
      rawQuestion.max !== undefined
        ? alignQuestionLimit(rawQuestion.max)
        : undefined,
    unit: rawQuestion.unit,
    // upload
    mimeTypes: rawQuestion.mimeTypes,
  };

  const hashedQuestion = hash(question);
  let ref = hashedQuestion;
  let index = 0;
  while (visited.includes(ref)) ref = `${hashedQuestion}.${++index}`;

  return {
    ...question,
    ref,
  };
};

const alignAnswers = (answers: RawAnswer[]): Answer[] => {
  const aligned = [];
  answers.forEach(answer => {
    aligned.push(
      createAnswer(
        createQuestion(answer, aligned.map(a => a.ref)),
        answer.value
      )
    );
  });
  return aligned;
};

export default (guide: Guide) => async ({
  ask,
  patient,
}: {
  ask: Question => Promise<*> | *,
  patient: Patient,
}): Promise<?Result> => {
  const answers = [];
  const decisionSupport = [];
  const significant = [];
  const exports = [];
  const scores = {
    scores: {},

    get: function(score) {
      if (!this.scores.hasOwnProperty(score)) {
        this.set(score, 0);
      }
      return this.scores[score];
    },

    set: function(score, value) {
      this.scores[score] = value;
    },

    increase: function(score, value = 1) {
      if (value !== null && value !== undefined) {
        this.scores[score] = this.get(score) + value;
      }
    },

    decrease: function(score, value = 1) {
      if (value !== null && value !== undefined) {
        this.scores[score] = this.get(score) - value;
      }
    },

    getAll: function() {
      return this.scores;
    },
  };

  const fn = (label: string | RawQuestion, options?: RawQuestion) =>
    new Promise(async (resolve, reject) => {
      const question = createQuestion(
        {
          ...(typeof label === 'object' ? label : { label }),
          ...(options || {}),
        },
        answers.map(a => a.ref)
      );

      let value;

      try {
        value = await ask({
          ...question,
          warn: undefined,
        });
      } catch (e) {
        reject(e);
        return;
      }

      if (!validateValue(question, value)) {
        console.warn('Validation error: ', { question, value });
        reject();
        return;
      }

      answers.push(createAnswer(question, value));

      // TODO clone value if its an array.
      // Currently its possible for the guide function to manipulate the answer array.
      resolve(value);
    });

  Object.keys(questionTypes).forEach(type => {
    fn[type] = (label, options = {}) => fn(label, { ...options, type });
  });

  let result;

  try {
    result = await guide({
      ask: fn,
      patient,
      decisionSupport,
      significant,
      exports,
      scores,
    });
  } catch (e) {
    if (e) {
      throw e;
    }
    return undefined;
  }

  return {
    answers,
    decisionSupport,
    significant: alignAnswers(significant),
    exports,
    scores,
    ...result,
  };
};

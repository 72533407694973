import { type Action } from '../types';

import { type State, TOGGLE_DRAW, CLOSE_DRAW } from './types';

export default (state: State = { openDraws: {} }, action: Action): State => {
  const { type, payload = {} } = action;

  switch (type) {
    case TOGGLE_DRAW: {
      const { name } = payload;
      return {
        ...state,
        openDraws: {
          ...state.openDraws,
          [name]: !state.openDraws[name],
        },
      };
    }
    case CLOSE_DRAW: {
      const { name } = payload;
      return {
        ...state,
        openDraws: {
          ...state.openDraws,
          [name]: false,
        },
      };
    }

    default:
      return state;
  }
};

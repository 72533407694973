const contactSexHealth =
  'Call 0300 124 0102 or for more information, go to [www.leicestersexualhealth.nhs.uk](www.leicestersexualhealth.nhs.uk)';
const contactSupport =
  'Please call our support team on 0800 433 7304 and we will arrange a routine appointment.';

export default {
  appointment: {
    description: `
To get the help you need, please make an appointment at your local sexual health clinic.

${contactSexHealth}
          `,
  },
  breastfeeding: {
    description: `
Unfortunately, we cannot treat women who are breast feeding online.

If you are not already using contraception, you may want to think about using the progesterone only "mini" pill or a longer lasting method of contraception, like the implant, coil (IUD) or Mirena (IUS).

Please see your GP or get expert advice at your local sexual health clinic. ${contactSexHealth}
    `,
  },
  pregnant: {
    description: `
If you are already pregnant, and you don't want to be, taking regular contraception will not help. You should contact the British Pregnancy Advisory Service [www.bpas.org](www.bpas.org)

If you have had unprotected sex in the last 5 days and you need emergency contraception, then please use our Emergency Contraception Service, or you can get this from most pharmacies.
    `,
  },
  baby: {
    description: `
If you have had a baby in the last 6 weeks we are unable to treat you online.

This is because choosing what contraception is best for you, can be a tricky choice at this time in your life - some contraceptives should not be used so soon after giving birth or if you are breastfeeding. You might also want to think about using a long lasting method of contraception.

Please see your GP or get expert advice at your local sexual health clinic. ${contactSexHealth}
    `,
  },
  breastCancer: {
    description: `
You should not use hormonal contraception if you are at risk of breast cancer. There are other forms of contraception that you can use.

To get the help you need, please make an appointment at your local sexual health clinic. C${contactSexHealth}
    `,
  },
  emergencyContraception: {
    description: `
Using hormonal contraception within 5 days of taking ellaOne (emergency contraceptive pill), could make your emergency contraception not work.

Please use condoms or do not have sex, until 5 days have passed, and then we would be happy to treat you online.
    `,
  },
  pregnancyTest: {
    description:
      'You need to do a pregnancy test 3 weeks after having unprotected sex, or missing contraception. Please do this now, and if the test is negative, we would be happy to treat you online.',
  },
  ivf: {
    description: `
We cannot prescribe this medication as part of IVF treatment. This needs to be prescribed and overseen by the specialist looking after you.

Thank you for using Docly.
    `,
  },
  period: {
    description: `
Unfortunately, we cannot prescribe contraception online, for the treatment for period problems.

It is best that you see a doctor face to face for this.

${contactSupport}
  `,
  },
  unavailable: {
    description: `
Unfortunately, we cannot arrange for you to have the contraceptive injection, implant, coil (IUD) or hormonal coil (Mirena / IUS) online. To get the help you need, please make an appointment at your local sexual health clinic.

${contactSexHealth}
  `,
  },
  unsure: {
    description: `
If you are unsure what type of contraception is best for you and you would like some advice, please make an appointment at your local sexual health clinic.

${contactSexHealth}
  `,
  },
};

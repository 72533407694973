import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const smoking = {
    type: 'choice',
    id: 'smoking.status',
    label: 'Är du rökare?',
    options: [
      {
        value: '1',
        label: 'Ja',
      },
      {
        value: '2',
        label: 'Nej',
      },
      {
        value: '3',
        label: 'Före detta',
      },
    ],
    expires: 180,
  };

  const smokingAnswer = await ask(smoking);

  significant.push({
    ...smoking,
    label: 'Rökning',
    value: smokingAnswer,
  });

  if (smokingAnswer === '1') {
    const smokingAmount = {
      type: 'choice',
      id: 'smoking.amount',
      label: 'Hur många cigaretter röker du per dag?',
      options: [
        {
          value: 'lessthan10',
          label: 'Färre än tio',
        },
        {
          value: 'more',
          label: 'Tio eller fler',
        },
      ],
      expires: 180,
    };

    const smokingAmountAnswer = await ask(smokingAmount);

    significant.push({
      ...smokingAmount,
      label: 'Anta cigaretter/dag',
      value: smokingAmountAnswer,
    });
  }

  return {};
}: Guide);

import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import { _t } from '../../i18n';

import { fetchGuide } from '../../state/guides/actions';
import { getGuides, getGuideMeta } from '../../state/guides';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import withStyles from '@material-ui/core/styles/withStyles';

import Fixed from '../components/Fixed';
import Footer from '../components/Footer';
import Page from '../components/Page';

import { withLocaleByCountry } from '../locale';

import ActionMenu from './actions/ActionMenu';
import UserMenu from '../components/UserMenu';

const Item = withStyles(({ spacing, breakpoints: { up } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    [up('sm')]: {
      borderRadius: 99,
    },
  },

  icon: {
    height: 40,
    width: 40,
    marginRight: spacing(),
  },
}))(({ icon, title, onClick, classes, className }) => (
  <ListItem
    button
    onClick={onClick}
    className={classNames(classes.root, className)}
  >
    <img alt="icon" src={icon} className={classes.icon} />

    <Typography variant="h3">{title}</Typography>
  </ListItem>
));

const GuideList = withStyles(({ spacing }) => ({
  list: {
    margin: `0 -${spacing(3)}px`,
    width: `calc(100% + ${spacing(6)}px)`,
  },

  item: {
    marginBottom: spacing(0.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))(({ classes, guides, openGuideFn }) => (
  <List className={classes.list}>
    {guides.map(meta => (
      <Item
        key={meta.guideId}
        icon={meta.icon}
        title={meta.title}
        onClick={() => openGuideFn(meta.guideId)}
        className={classes.item}
      />
    ))}
  </List>
));

const CenteredTabs = withStyles(() => ({
  tabs: {
    width: '100%',
  },
}))(({ value, onChange, classes, children }) => (
  <Tabs
    className={classes.tabs}
    value={value}
    onChange={onChange}
    indicatorColor="primary"
    textColor="primary"
    centered
  >
    {children}
  </Tabs>
));

class Guides extends React.Component<
  {
    country: string,
    fetchGuide: typeof fetchGuide,
    classes: { [string]: string },
  },
  {
    tabIndex: number,
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  changeTab = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  openGuide = guideId => {
    const { fetchGuide, country } = this.props;
    fetchGuide(guideId);

    navigate(`/${country}/guide/${guideId}`);
  };

  render() {
    const { country, classes } = this.props;
    const { tabIndex } = this.state;

    const guidesForCountry = getGuides()
      .map(guideId => getGuideMeta(guideId))
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .filter(meta => !country || meta.country === country);

    const guides = guidesForCountry.filter(meta => !meta.isModule);
    const modules = guidesForCountry.filter(meta => meta.isModule);

    const showModules = tabIndex === 1 && modules.length > 0;

    return (
      <>
        <Page>
          {modules.length > 0 ? (
            <CenteredTabs value={tabIndex} onChange={this.changeTab}>
              <Tab label={_t('guides.title')} />
              <Tab label={_t('modules.title')} />
            </CenteredTabs>
          ) : (
            <Typography variant="h2" className={classes.title}>
              {_t('guides.choose')}
            </Typography>
          )}

          {showModules ? (
            <>
              <Typography className={classes.intro}>
                {_t('modules.intro')}
              </Typography>
              <GuideList
                guides={modules}
                openGuideFn={guideId => this.openGuide(guideId)}
              />
            </>
          ) : (
            <>
              <GuideList
                guides={guides}
                openGuideFn={guideId => this.openGuide(guideId)}
              />
            </>
          )}
        </Page>
        <Footer />
        <Fixed vertical="top" horizontal="right" spacing>
          <ActionMenu country={country} />
        </Fixed>
        <Fixed vertical="top" horizontal="left" spacing>
          <UserMenu />
        </Fixed>
      </>
    );
  }
}

export default withStyles(({ spacing }) => ({
  title: {
    marginBottom: spacing(3),
  },
  intro: {
    marginTop: spacing(1),
  },
}))(
  withLocaleByCountry()(
    connect(
      undefined,
      { fetchGuide }
    )(Guides)
  )
);

import type { Guide } from '../../types';

import healthProfile from '../partials/healthProfile';
import pregnantOrBreastfeeding from '../partials/pregnantOrBreastfeeding';

export default (async args => {
  const { ask, decisionSupport } = args;

  await ask('Have you been given a diagnosis of eczema at any time?');

  const helpWith = await ask.multipleChoice(
    'How can we help you with your eczema?',
    {
      description: 'More than one answer can be selected.',
      options: {
        renewPrescription: 'More medication (repeat prescription)',
        newMedication:
          'I need medication that is not on my repeat prescription',
        worsenedOrChanged: 'Rash is worse or changed',
        infected: 'Eczema looks infected',
        other: 'Other',
      },
    }
  );
  if (helpWith.includes('newMedication')) {
    await ask.text(
      'Please list the medication that you need and tell us why you need it.'
    );
  }
  if (helpWith.includes('other')) {
    await ask.text('Please tell us how else we can help with your eczema');
  }
  if (
    ['worsenedOrChanged', 'infected', 'other'].find(key =>
      helpWith.includes(key)
    )
  ) {
    const started = await ask.choice('When did the problem start this time?', {
      options: {
        lessThan24Hours: 'Less than 24 hours ago',
        '1to2Days': '1–2 days ago',
        '3to7Days': '3–7 days ago',
        '1to2Weeks': '1–2 weeks ago',
        moreThan2Weeks: 'More than 2 weeks ago',
      },
    });
    if (started === 'moreThan2Weeks') {
      await ask.text('Please tell us how long it has been.');
    }
  }
  if (helpWith.includes('newMedication')) {
    const changed = await ask('Has your rash worsened or changed?');
    if (changed === 'yes') {
      const started = await ask.choice(
        'How long has it been since your condition worsened or changed?',
        {
          options: {
            lessThan24Hours: 'Less than 24 hours ago',
            '1to2Days': '1–2 days ago',
            '3to7Days': '3–7 days ago',
            '1to2Weeks': '1–2 weeks ago',
            moreThan2Weeks: 'More than 2 weeks ago',
          },
        }
      );
      if (started === 'moreThan2Weeks') {
        await ask.text('Please tell us how long it has been.');
      }
    }
  }
  const where = await ask.multipleChoice('Where on your body is the eczema?', {
    description: 'More than one answer can be selected.',
    options: {
      faceNeck: 'Face or neck',
      scalp: 'Scalp',
      torsoButtocks: 'Chest, stomach, back or buttocks',
      limbs: 'Arms, hands, legs or feet',
      genitals: 'Genitals or anal region',
    },
  });
  await ask.multipleChoice('Do any of the following describe your rash?', {
    description: 'More than one answer can be selected.',
    options: {
      raised: 'Raised',
      itchy: 'Itchy',
      flaky: 'Flaky',
    },
    optional: true,
  });
  const signs = await ask.multipleChoice(
    'Right now, does your rash show any signs of the following?',
    {
      description: 'More than one answer can be selected.',
      options: {
        weeping: 'Weeping',
        bleeding: 'Bleeding',
        blisters: 'Blisters or boils',
      },
      optional: true,
    }
  );
  await ask.text('Please describe the rash in your own words.');
  const fever = await ask('Have you had a fever in the past 48 hours?', {
    id: 'fever',
  });
  const feverAbove38 =
    fever === 'yes' &&
    (await ask.tertiary('Was your temperature 38 °C or more?', {
      id: 'feverAbove38',
    }));
  const treating = await ask(
    'Are you using anything to treat your eczema at the moment?'
  );
  let specificEmollient;
  let steroidCreamFrequency;
  let steroidCreamDuration;
  if (treating === 'yes') {
    const what = await ask.multipleChoice(
      'Are you using any of the treatments below for your eczema at the moment?',
      {
        description: 'More than one answer can be selected.',
        options: {
          emollients:
            'Emollients e.g. Diprobase cream, zerobase cream, E45 cream',
          steroidCreams: 'Steroid creams',
          antibiotics: 'Antibiotics',
          other: 'Other',
        },
      }
    );
    if (what.includes('emollients')) {
      await ask.text('Which emollient (moisturiser) are you currently using?');
      await ask('Do you use your emollient as a soap substitute?');
      await ask.choice(
        'When you use your emollient as a moisturiser, how often do you use it?',
        {
          options: {
            '1to2TimesADay': '1–2 times a day',
            '2to3TimesADay': '2–3 times a day',
            '3to4TimesADay': '3–4 times a day',
            '2to3TimesAWeek': '2–3 times a week',
          },
        }
      );
      const satisfied = await ask.choice(
        'Are you satisfied with your emollient or would you like to try another one?',
        {
          options: {
            yes: 'Yes, I am happy with my emollient',
            no: 'No, I would like to try something else',
          },
        }
      );
      if (satisfied === 'no') {
        specificEmollient = await ask.choice(
          'Is there a specific emollient you would like to try?',
          {
            options: {
              yes: 'Yes, I know which one I would like to try',
              no: "No, I'd like the doctor to choose for me",
            },
          }
        );
        if (specificEmollient === 'yes') {
          await ask.text(
            'Please tell us the name of the emollient you would like to try.'
          );
        }
      }
    }
    if (what.includes('steroidCreams')) {
      const whatSteroidCreams = await ask.multipleChoice(
        'Which of these steroid creams are you currently using?',
        {
          description: 'More than one answer can be selected.',
          options: {
            hydrocortisone005: 'Hydrocortisone 0.05%',
            hydrocortisone1: 'Hydrocortisone 1%',
            hydrocortisone2: 'Hydrocortisone 2%',
            betnovate0025: 'Betnovate RD 0.025%',
            eumovate005: 'Eumovate (clobetasone butyrate 0.05%)',
            betnovate01: 'Betnovate (betamethasone valerate 0.1%)',
            diprosone005: 'Diprosone (betamethasone dipropionate 0.05%)',
            other: 'Other',
          },
        }
      );
      if (whatSteroidCreams.includes('other')) {
        await ask.text(
          'Please tell us the name of the steroid cream you are using.'
        );
      }
      steroidCreamFrequency = await ask.choice(
        'How often are you using steroid cream?',
        {
          options: {
            onceADay: 'Once a day',
            '2to3TimesADay': '2–3 times a day',
            '3to4TimesADay': '3–4 times a day',
            onceAWeek: 'Once a week',
            '2to3TimesAWeek': '2–3 times a week',
          },
        }
      );
      steroidCreamDuration = await ask.choice(
        'How long have you used this steroid cream continuously for?',
        {
          description: 'This means every day without a break.',
          options: {
            lessThan24Hours: 'Less than 24 hours',
            '1to7Days': '1–7 days',
            '1to2Weeks': '1–2 weeks',
            '2WeeksTo1Month': '2 weeks – 1 month',
            '1to3Months': '1–3 months',
            moreThan3Months: 'More than 3 months',
          },
        }
      );
    }
    if (what.includes('antibiotics')) {
      const whatAntibiotics = await ask.multipleChoice(
        'What antibiotic treatments are you using at the moment?',
        {
          description: 'More than one answer can be selected.',
          options: {
            fucidinCream: 'Fucidin cream',
            fucibetCream: 'Fucibet cream',
            flucloxacillin: 'Flucloxacillin',
            clarithromycin: 'Clarithromycin',
            other: 'Other',
          },
        }
      );
      if (whatAntibiotics.includes('other')) {
        await ask.text(
          'Please tell us the name of the antibiotic you are using.'
        );
      }
    }
    if (what.includes('other')) {
      await ask.text(
        'Please list the other eczema treatments you are currently using.',
        {
          description:
            'Include the name, how often you use it, and how long you have been using it for.',
        }
      );
    }
  }
  const previous = await ask.multipleChoice(
    'Have you used any of these treatments previously for your eczema?',
    {
      description: 'More than one answer can be selected.',
      options: {
        emollients:
          'Emollients e.g. Diprobase cream, zerobase cream, E45 cream',
        steroidCreams: 'Steroid creams',
        antibiotics: 'Antibiotics',
        other: 'Other',
      },
      optional: true,
    }
  );
  let skinChanges;
  if (previous.includes('emollients')) {
    await ask.text('Please list the emollients you have used before.');
    const whatSteroidCreams = await ask.multipleChoice(
      'Have you used any of these steroid creams previously?',
      {
        description: 'More than one answer can be selected.',
        options: {
          hydrocortisone005: 'Hydrocortisone 0.05%',
          hydrocortisone1: 'Hydrocortisone 1%',
          hydrocortisone2: 'Hydrocortisone 2%',
          betnovate0025: 'Betnovate RD 0.025%',
          eumovate005: 'Eumovate (clobetasone butyrate 0.05%)',
          betnovate01: 'Betnovate (betamethasone valerate 0.1%)',
          diprosone005: 'Diprosone (betamethasone dipropionate 0.05%)',
          other: 'Other',
        },
      }
    );
    if (whatSteroidCreams.includes('other')) {
      await ask.text(
        'Please tell us the names of the steroid creams you have used previously.'
      );
    }
    await ask.text(
      'Please tell us the names of the steroid creams that helped your eczema'
    );
    skinChanges = await ask.multipleChoice(
      'Do you have any of these skin changes?',
      {
        description: 'More than one answer can be selected.',
        options: {
          skinThicknessChanged:
            'Skin feels thinner or thicker than it was before',
          breaksEasily: 'Skin which breaks easily',
          bruisesEasily: 'Skin which bruises easily',
          lighterColour:
            'Patches of skin which are lighter than your normal skin colour',
          strechmarks: 'Stretchmarks where you have had patches of eczema',
        },
        optional: true,
      }
    );
  }
  if (
    steroidCreamFrequency === '3to4TimesADay' ||
    steroidCreamDuration === '1to3Months' ||
    steroidCreamDuration === 'moreThan3Months' ||
    (skinChanges && skinChanges.length)
  ) {
    await ask.multipleChoice(
      'Steroid cream should not be used more than twice a day, and for no longer than 2 weeks in a row.  Over use can cause; skin thinning, easy bruising, skin colour changes, and stretch marks, as well as more serious problems, such as: weight gain, osteoperosis, glaucoma, and high blood pressure. If your steroid cream is not helping your eczema than you should see your GP.',
      {
        options: ['I understand'],
      }
    );
  }
  if (previous.includes('antibiotics')) {
    const lastMonth = await ask(
      'Have you used any antibiotic treatments in the past month?'
    );
    if (lastMonth === 'yes') {
      const what = await ask.multipleChoice(
        'Please tell us the name of the antibiotic you used.',
        {
          description: 'More than one answer can be selected.',
          options: {
            fucidinCream: 'Fucidin cream',
            fucibetCream: 'Fucibet cream',
            flucloxacillin: 'Flucloxacillin',
            clarithromycin: 'Clarithromycin',
            other: 'Other',
          },
        }
      );
      if (what.includes('other')) {
        await ask.text('Please tell us which other antibiotic you used.');
      }
    }
  }
  if (previous.includes('other')) {
    await ask.text(
      'Please tell us the other treatments you have used for your eczema. Include if they helped or not.'
    );
  }
  const skinCondition = await ask(
    'Do you have any known skin conditions, other than eczema?'
  );
  let skinConditions;
  if (skinCondition === 'yes') {
    const skinConditions = await ask.multipleChoice(
      'What type of skin condition do you have?',
      {
        description: 'More than one answer can be selected.',
        options: {
          rosacea: 'Rosacea',
          acne: 'Acne',
          psoriasis: 'Psoriasis',
          other: 'Other',
        },
      }
    );
    if (skinConditions.includes('other')) {
      await ask.text('What other type of skin condition do you have?');
    }
  }
  const recentMedication = await ask(
    'Have you started using any new medications (including natural remedies) within the last 6 months?'
  );
  if (recentMedication === 'yes') {
    await ask.text(
      'Please tell us which medications, including natural remedies, you started using within the past 6 months. Include how long you have been using them for.'
    );
  }
  await pregnantOrBreastfeeding(args);
  const negativeImpact = await ask(
    'Do you feel like your eczema is having a negative impact on your life right now?'
  );
  if (negativeImpact === 'yes') {
    const nrs = await ask.range(
      'How much is your eczema impacting on your life?',
      {
        min: {
          value: 0,
          label: 'Not at all',
        },
        max: {
          value: 10,
          label: 'Very high impact',
        },
      }
    );
    if (nrs !== undefined && nrs >= 5.5) {
      await ask.text("Please tell us how it's impacting your life.");
    }
  }
  await healthProfile(args);
  if (where.includes('faceNeck')) {
    decisionSupport.push({
      label: 'Eczema on face',
      description:
        'Emollient +/ - Mild steroid or Protopic. Use message "Mild eczema: emollient + mild steroid" or "Mild eczema: emollient + Protopic 0.03%" or "Mild eczema: emollient only" or "Moderate eczema: emollient + Protopic 0.1%"',
      color: 'yellow',
      priority: 2,
    });
  }
  if (where.includes('genitals')) {
    decisionSupport.push({
      label: 'Doctor to decide',
      description:
        'Genital / Perianal Eczema / Not Eczema. Use message "Mild eczema: emollient + mild steroid" "Mild eczema: emollient + Protopic 0.03%" or "Mild eczema: emollient only" or "Moderate eczema: emollient + Protopic 0.1%" or "Routine GP Appointment"',
      color: 'green',
    });
  }
  if (signs && (signs.includes('weeping') || signs.includes('bleeding'))) {
    decisionSupport.push({
      label: 'Severe / Infected Eczema',
      description:
        'Use message "Severe Eczema: Emollient + potent steroid" or "Infected Eczema - Flucloxacillin" or "Infected Eczema - Clarithromycin" or "Infected Eczema - Fucidin H + emollient" or "Infected Eczema - Fucibet + emollient"',
      color: 'yellow',
      priority: 1,
    });
  }
  if (signs && signs.includes('blisters')) {
    decisionSupport.push({
      label: 'Reject - Urgent Appointment',
      description: 'Cellulitis / Abscess. Use "Cellulitis/ abscess" message',
      color: 'red',
      priority: 3,
    });
  }
  if (feverAbove38 === 'yes') {
    decisionSupport.push({
      id: 'fever',
      label: 'T ≥ 38 C',
      color: 'black',
    });
  }
  if (specificEmollient === 'no') {
    decisionSupport.push({
      label: 'Doctor to choose emollient',
      color: 'black',
    });
  }
  if (specificEmollient === 'yes') {
    decisionSupport.push({
      label: 'Doctor to prescribe emollient patient has requested',
      color: 'black',
    });
  }
  if (
    steroidCreamDuration === '1to3Months' ||
    steroidCreamDuration === 'moreThan3Months'
  ) {
    decisionSupport.push({
      label: 'Steroid overuse',
      description:
        'Consider Protopic (Calcineurin) or F2F. Use message "Mild eczema: emollient + Protopic 0.03%" or "Moderate eczema: emollient + Protopic 0.1%" or "Routine GP Appointment"',
      color: 'black',
    });
  }
  if (skinChanges && skinChanges.length) {
    decisionSupport.push({
      label: 'Signs of Steroid overuse',
      description: 'Consider Protopic (Calcineurin) or F2F',
      color: 'black',
    });
  }
  if (skinConditions && skinConditions.includes('psoriasis')) {
    decisionSupport.push({
      label: 'Doctor to decide',
      description: 'PMH Psoriasis. Use "Routine GP Appointment" message',
      color: 'black',
    });
  }
  return {};
}: Guide);

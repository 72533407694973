import * as React from 'react';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import MenuIcon from '@material-ui/icons/Menu';

import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import type { ThemeWidth } from '../theme/types';

class Menu extends React.Component<
  {
    children?: React.Node,
    width: ThemeWidth,
    classes: { [string]: string },
    className?: string,
    alwaysOpen?: boolean,
  },
  { open: boolean }
> {
  state = {
    open: false,
  };

  anchor = React.createRef();

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  render() {
    const { children, width, classes, className, alwaysOpen } = this.props;
    const { open } = this.state;

    const always =
      alwaysOpen === undefined ? isWidthUp('sm', width) : alwaysOpen;

    return (
      <div className={classNames(classes.root, className)}>
        {always ? (
          children
        ) : (
          <>
            <IconButton
              ref={this.anchor}
              color="secondary"
              onClick={this.open}
              className={classes.button}
            >
              <MenuIcon className={classes.icon} />
            </IconButton>

            <Popover
              open={open}
              anchorEl={this.anchor.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={this.close}
            >
              <div className={classes.popover}>{children}</div>
            </Popover>
          </>
        )}
      </div>
    );
  }
}

export default withWidth()(
  withStyles(({ spacing }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },

    button: {
      height: 56,
      width: 56,
      padding: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },

    icon: {
      height: 30,
      width: 30,
    },

    popover: {
      padding: spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
  }))(Menu)
);

import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import journalData from './journalData';
import { ensureArray, matchOne } from '../common/arrayUtils';
import ds from './decisionSupport';
import aborts from './aborts';
import createExtendedText from '../common/extendedText';

export default (async args => {
  const { exports, ask } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  journalData(exports);

  const whatTypeOfTest = await eChoice({
    id: 'coronaChecker.whatTypeOfTest',
    label: 'What type of coronavirus test will you be having?',
    clinicLabel: 'Type of test',
    options: [
      {
        label: 'Swab (PCR)',
        value: 'swab',
      },
      {
        label: 'Blood test (venous)',
        value: 'blood',
        abort: aborts.bloodTestNotAvailable,
      },
    ],
  });
  if (whatTypeOfTest.abort) {
    return { abort: whatTypeOfTest.abort };
  }

  const whatSymptomsDoYouHave = ensureArray(
    await eChoice({
      id: 'coronaChecker.whatSymptomsDoYouHave',
      label: 'In the past 3 weeks, have you had any of these symptoms?',
      description: 'You can choose more than one',
      isMultipleChoice: true,
      optional: true,
      options: [
        {
          label: 'Cough (new or worse)',
          value: 'cough',
          keyFinding: 'Cough',
        },
        {
          label: 'Shortness of breath',
          value: 'shortnessOfBreath',
          keyFinding: 'Shortness of breath',
        },
        {
          label: 'Fever',
          value: 'fever',
          keyFinding: ' Fever',
        },
        {
          label: 'Loss of taste / smell (new)',
          value: 'lossOfTasteSmell',
          keyFinding: ' Loss of taste / smell (new)',
        },
        {
          label: 'Tiredness',
          value: 'tiredness',
          keyFinding: 'Tiredness',
        },
        {
          label: 'Muscle aches',
          value: 'muscleAches',
          keyFinding: 'Muscle aches',
        },
        {
          label: 'Sore throat',
          value: 'soreThroat',
          keyFinding: 'Sore throat',
        },
        {
          label: 'Headache',
          value: 'headache',
          keyFinding: 'Headache',
        },
        {
          label: 'Chills or shivering',
          value: 'chills',
          keyFinding: 'Chills',
        },
        {
          label: 'Blocked / Runny Nose',
          value: 'blockedRunnyNose',
          keyFinding: 'Blocked / Runny Nose',
        },
        {
          label: 'Chest pain or pressure',
          value: 'chestPainPressure',
          keyFinding: 'Chest pain / pressure',
        },
        {
          label: 'Nausea, vomiting or diarrohea',
          value: 'nauseaVomitingDiarrohea',
          keyFinding: 'Nausea, vomiting or diarrohea',
        },
        {
          label: 'Feeling confused or muddled',
          value: 'confusion',
          keyFinding: 'Confusion',
        },
      ],
    })
  );

  if (
    matchOne(whatSymptomsDoYouHave, [
      'cough',
      'shortnessOfBreath',
      'chestPainPressure',
      'confusion',
    ])
  ) {
    await ask.choice({
      description: `
If you are very short of breath, wheezing, have pain or pressure in your chest, or are feeling confused or muddled, please get help.

**For urgent advice [please visit 111,](https://111.nhs.uk/) for emergencies call 999.**

This service is for testing only.
`,
      options: ['I understand, please continue'],
    });
  }

  if (whatSymptomsDoYouHave.length > 0) {
    const haveSymptomsNow = await eChoice({
      id: 'coronaChecker.haveSymptomsNow',
      label: 'Do you have symptoms right now?',
      options: [
        {
          value: 'yes',
          warn: true,
          ds: ds.currentSymptoms,
        },
        {
          value: 'no',
          ds: ds.symptomsResolved,
        },
      ],
    });

    const majorSymptoms = matchOne(whatSymptomsDoYouHave, [
      'cough',
      'shortnessOfBreath',
      'fever',
      'lossOfTasteSmell',
    ]);

    const whenDidSymptomsStart = await eChoice({
      id: 'coronaChecker.whenDidSymptomsStart',
      label: 'When did your symptoms start?',
      options: [
        {
          label: '0 - 7 days ago',
          value: '0To7DaysAgo',
          ds:
            majorSymptoms && whatTypeOfTest === 'blood'
              ? ds.testAndTraceNeededSymptomatic
              : undefined,
        },
        {
          label: '1 - 3 weeks ago',
          value: '1To3WeeksAgo',
        },
        {
          label: 'More than 3 weeks ago',
          value: 'moreThan3WeeksAgo',
        },
      ],
    });

    if (
      matchOne(whatSymptomsDoYouHave, ['cough', 'fever', 'lossOfTasteSmell']) &&
      (haveSymptomsNow === 'yes' || whenDidSymptomsStart === '0To7DaysAgo')
    ) {
      await ask.choice({
        description: `
You might have Coronavirus. Please make sure you stay at home and self isolate for at least 7 days, or longer if you still have a temperature (until it returns to normal).
`,
        options: ['I understand'],
      });
    }
  }

  await eChoice({
    id: 'coronaChecker.recentContactWithCoronavirus',
    label:
      'In the past 14 days, have you been in contact with anyone confirmed as having Coronavirus?',
    options: [
      {
        value: 'yes',
        warn: true,
        keyFinding: 'Contact with case of Covid-19',
        ds:
          whatTypeOfTest === 'blood'
            ? ds.testAndTraceNeededCovidContact
            : undefined,
      },
      {
        value: 'no',
      },
    ],
  });

  await eText({
    id: 'coronaChecker.whatCompany',
    label: 'What company do you work for?',
  });

  const shareWithGp = await eChoice({
    id: 'coronaChecker.shareWithGp',
    label: 'We need to let your GP know your test results.',
    description: 'This is a condition of using this service.',
    options: [
      {
        label: "Yes, that's ok",
        value: 'yes',
        hide: true,
      },
      {
        label: "No, I don't agree - I won't use this service",
        value: 'no',
        abort: aborts.optOut,
      },
      {
        label: "I don't have a GP",
        value: 'noGP',
        warn: true,
      },
    ],
  });
  if (shareWithGp.abort) {
    return { abort: shareWithGp.abort };
  }

  if (shareWithGp === 'yes') {
    await eText({
      id: 'coronaChecker.gpDetails',
      label: "What is your GP's name and address?",
    });
  }

  if (whatTypeOfTest === 'swab') {
    await ask.choice({
      description:
        'We will soon send you a link to choose a time for a video call with a clinician, who will help you do your Coronavirus test.',
      options: ['I understand'],
    });
  }

  await eChoice({
    id: 'coronaChecker.howGetResult',
    label:
      'Once your test is complete, how would you prefer to get your results?',
    clinicLabel: 'How patient would like their results',
    options: [
      {
        label: 'Message (written)',
        value: 'message',
      },
      {
        label: 'Phone call',
        value: 'phoneCall',
      },
      {
        label: 'Video call',
        value: 'videoCall',
      },
    ],
  });

  await ask.choice({
    description:
      'After you have had your test, it will take us a few days to get your result. Please keep checking your messages, we will contact you as soon as your result is ready.',
    options: ['I understand'],
  });

  return {};
}: Guide);

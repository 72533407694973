export default {
  id: 'herpes',
  type: 'formulary',
  version: '3',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '8',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'moreThan4days',
              label: 'För mer än 4 dagar sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började dina besvär?',
          index: '0.0.0',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'herpes.similar',
          type: 'choice',
          version: '2',
          options: [
            {
              value: 'yes',
              label: 'Ja, jag har haft herpes tidigare.',
            },
            {
              value: 'yesUnsure',
              label: 'Ja, men jag är osäker på om det är herpes.',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Har du haft liknande besvär tidigare?',
          index: '0.1.0',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      condition: "get('answers.herpes.similar') === 'yes'",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'problem.samePlace.always',
              type: 'binary',
              version: '1',
              validations: {
                required: true,
              },
              label: 'Återkommer besvären alltid på samma ställe?',
              index: '0.2.0.0',
            },
            {
              id: 'herpes.recurring.where',
              type: 'multipleChoice',
              version: '1',
              options: [
                {
                  value: 'mouth',
                  label: 'I och/eller runt munnen',
                },
                {
                  value: 'vagina',
                  label: 'I och/eller runt slidan',
                  condition: "get('answers.predefined.gender') === 'female'",
                },
                {
                  value: 'penis',
                  label: 'På penis och/eller pungen',
                  condition: "get('answers.predefined.gender') === 'male'",
                },
                {
                  value: 'anal',
                  label: 'Runt ändtarmsöppningen',
                },
                {
                  value: 'buttocks',
                  label: 'På skinkorna och/eller låren',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Var brukar du ha besvär?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.0.1',
            },
            {
              id: 'problem.recurring.where',
              type: 'text',
              version: '1',
              condition:
                "contains(get('answers.herpes.recurring.where'), 'other')",
              validations: {
                required: true,
              },
              label: 'Beskriv var du brukar ha besvär.',
              index: '0.2.0.2',
            },
          ],
          version: '1',
          index: '0.2.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'problem.current.match',
              type: 'binary',
              version: '1',
              validations: {
                required: true,
              },
              label: 'Har du besvär just nu?',
              index: '0.2.1.0',
            },
            {
              id: 'herpes.current.where',
              type: 'multipleChoice',
              version: '1',
              options: [
                {
                  value: 'mouth',
                  label: 'I och/eller runt munnen',
                },
                {
                  value: 'vagina',
                  label: 'I och/eller runt slidan',
                  condition: "get('answers.predefined.gender') === 'female'",
                },
                {
                  value: 'penis',
                  label: 'På penis och/eller pungen',
                  condition: "get('answers.predefined.gender') === 'male'",
                },
                {
                  value: 'anal',
                  label: 'Runt ändtarmsöppningen',
                },
                {
                  value: 'buttocks',
                  label: 'På skinkorna och/eller låren',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition:
                "get('answers.problem.current.match') === 'yes' && length(get('answers.herpes.recurring.where')) > 1",
              label: 'Var har du besvär just nu?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.1.1',
            },
            {
              id: 'problem.current.whereOther',
              version: '1',
              type: 'text',
              condition:
                "contains(get('answers.herpes.current.where'), 'other')",
              validations: {
                required: true,
              },
              label: 'Beskriv var du har besvär just nu.',
              index: '0.2.1.2',
            },
          ],
          version: '1',
          index: '0.2.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'herpes.describeProblem',
              version: '2',
              type: 'multipleChoice',
              options: [
                {
                  value: 'itchy',
                  label: 'Pirrande/kliande känsla',
                },
                {
                  value: 'sting',
                  label: 'Svidande/molande smärta',
                },
                {
                  value: 'soreness',
                  label: 'Ömhet vid beröring',
                },
                {
                  value: 'redness',
                  label: 'Rodnad',
                },
                {
                  value: 'blisters',
                  label: 'Blåsor',
                },
                {
                  value: 'ulcer',
                  label: 'Sår',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              condition: "get('answers.problem.current.match') === 'yes'",
              label: 'Vilka besvär har du just nu?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.2.2.0',
            },
            {
              id: 'problem.describeOther',
              version: '2',
              type: 'text',
              condition:
                "contains(get('answers.herpes.describeProblem'), 'other')",
              validations: {
                required: true,
              },
              label: 'Vilka andra besvär har du?',
              index: '0.2.2.1',
            },
          ],
          version: '1',
          index: '0.2.2',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      condition:
        "get('answers.herpes.similar') === 'yesUnsure' || get('answers.herpes.similar') === 'no'",
      children: [
        {
          type: 'section',
          children: [
            {
              id: 'herpes.current.where',
              type: 'multipleChoice',
              version: '1',
              options: [
                {
                  value: 'mouth',
                  label: 'I och/eller runt munnen',
                },
                {
                  value: 'vagina',
                  label: 'I och/eller runt slidan',
                  condition: "get('answers.predefined.gender') === 'female'",
                },
                {
                  value: 'penis',
                  label: 'På penis och/eller pungen',
                  condition: "get('answers.predefined.gender') === 'male'",
                },
                {
                  value: 'anal',
                  label: 'Runt ändtarmsöppningen',
                },
                {
                  value: 'buttocks',
                  label: 'På skinkorna och/eller låren',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Var har du besvär just nu?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.3.0.0',
            },
            {
              id: 'problem.current.whereOther',
              version: '1',
              type: 'text',
              condition:
                "contains(get('answers.herpes.current.where'), 'other')",
              validations: {
                required: true,
              },
              label: 'Beskriv var du har besvär just nu.',
              index: '0.3.0.1',
            },
          ],
          version: '1',
          index: '0.3.0',
        },
        {
          type: 'section',
          children: [
            {
              id: 'herpes.describeProblem',
              version: '2',
              type: 'multipleChoice',
              options: [
                {
                  value: 'itchy',
                  label: 'Pirrande/kliande känsla',
                },
                {
                  value: 'sting',
                  label: 'Svidande/molande smärta',
                },
                {
                  value: 'soreness',
                  label: 'Ömhet vid beröring',
                },
                {
                  value: 'redness',
                  label: 'Rodnad',
                },
                {
                  value: 'blisters',
                  label: 'Blåsor',
                },
                {
                  value: 'ulcer',
                  label: 'Sår',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              label: 'Vilka besvär har du just nu?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.3.1.0',
            },
          ],
          version: '1',
          index: '0.3.1',
        },
        {
          type: 'section',
          children: [
            {
              id: 'problem.describeOther',
              version: '2',
              type: 'text',
              condition:
                "contains(get('answers.herpes.describeProblem'), 'other')",
              validations: {
                required: true,
              },
              label: 'Vilka andra besvär har du?',
              index: '0.3.2.0',
            },
            {
              id: 'blisters.samePlaceSimilar',
              type: 'binary',
              version: '1',
              validations: {
                required: true,
              },
              label:
                'Innan blåsorna uppstod, hade du andra besvär på samma ställe?',
              description: 'T.ex. sveda eller irritation.',
              index: '0.3.2.1',
            },
          ],
          version: '1',
          index: '0.3.2',
        },
        {
          type: 'section',
          children: [
            {
              id: 'herpes.testedForChlamydia',
              version: '2',
              type: 'composite',
              children: [
                {
                  id: 'herpes.testedForChlamydia.match',
                  type: 'choice',
                  version: '2',
                  options: [
                    {
                      value: 'yesPositive',
                      label: 'Ja, med positivt resultat',
                    },
                    {
                      value: 'yesNegative',
                      label: 'Ja, med negativt resultat',
                    },
                    {
                      value: 'yesButNoAnswer',
                      label: 'Ja, men inte fått svar än',
                    },
                    {
                      value: 'no',
                      label: 'Nej',
                    },
                  ],
                  relativeId: 'match',
                  validations: {
                    required: true,
                  },
                  label:
                    'Vid herpes kring könsorganen kan det vara bra att även testa sig för könssjukdomen klamydia. Har du i samband med dina besvär testat dig för klamydia?',
                  index: '0.3.3.0.0',
                },
              ],
              condition:
                "(get('answers.herpes.similar') === 'no' || get('answers.herpes.similar') === 'yesUnsure' ) && (contains(get('answers.herpes.current.where'), 'vagina') || contains(get('answers.herpes.current.where'), 'penis'))",
              extras: {},
              index: '0.3.3.0',
            },
          ],
          version: '1',
          index: '0.3.3',
        },
        {
          type: 'section',
          children: [
            {
              id: 'health.peeingPossible',
              type: 'binary',
              version: '2',
              condition:
                "(get('answers.predefined.gender') === 'female') && (contains(get('answers.herpes.current.where'), 'vagina'))",
              validations: {
                required: true,
              },
              label: 'Har du i samband med dina besvär problem med att kissa?',
              index: '0.3.4.0',
            },
            {
              id: 'health.swollenGlands',
              version: '4',
              type: 'choice',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'unknown',
                  label: 'Vet ej',
                },
              ],
              validations: {
                required: true,
              },
              label:
                'Upplever du att du har svullna och/eller ömmande körtlar i ljumskarna?',
              index: '0.3.4.1',
            },
          ],
          version: '1',
          index: '0.3.4',
        },
        {
          type: 'section',
          children: [
            {
              id: 'health.fever',
              type: 'binary',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Har du feber (över 38°C)?',
              index: '0.3.5.0',
            },
            {
              id: 'health.temperature',
              version: '4',
              type: 'number',
              condition: "get('answers.health.fever') === 'yes'",
              validations: {
                required: true,
              },
              label: 'Hur hög feber har du just nu?',
              unit: '°C',
              index: '0.3.5.1',
            },
            {
              id: 'medication.recentAgainstFever',
              version: '3',
              type: 'binary',
              condition: "get('answers.health.fever') === 'yes'",
              validations: {
                required: true,
              },
              label:
                'Har du tagit febernedsättande läkemedel under de senaste 4 timmarna?',
              index: '0.3.5.2',
            },
          ],
          version: '1',
          index: '0.3.5',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.previousTreatment.visitMatch',
          version: '3',
          type: 'binary',
          condition:
            "(get('answers.herpes.similar') === 'yesUnsure') || (get('answers.herpes.similar') === 'yes')",
          validations: {
            required: true,
          },
          label: 'Har du tidigare sökt läkare för dina besvär?',
          index: '0.4.0',
        },
        {
          id: 'problem.previousTreatment.visitWhen',
          version: '1',
          type: 'choice',
          options: [
            {
              value: '14days',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: '3months',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
          condition:
            "get('answers.problem.previousTreatment.visitMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'När sökte du läkare?',
          index: '0.4.1',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.previousTreatment.visitDiagnosisMatch',
          version: '2',
          type: 'binary',
          condition:
            "get('answers.problem.previousTreatment.visitMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Fick du någon diagnos?',
          index: '0.5.0',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosis',
          version: '1-herpes',
          type: 'choice',
          options: [
            {
              value: 'herpes',
              label: 'Herpes',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          condition:
            "get('answers.problem.previousTreatment.visitDiagnosisMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilken diagnos fick du?',
          index: '0.5.1',
        },
        {
          id: 'problem.previousTreatment.visitOtherDiagnosis',
          version: '1',
          type: 'text',
          condition:
            "get('answers.problem.previousTreatment.visitDiagnosis') === 'other'",
          validations: {
            required: true,
          },
          label: 'Vilken annan diagnos fick du?',
          index: '0.5.2',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'herpes.test',
          type: 'tertiary',
          version: '1',
          condition:
            "get('answers.problem.previousTreatment.visitMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Tog man då prov för herpes?',
          index: '0.6.0',
        },
        {
          id: 'problem.previousTreatment.treatment',
          version: '2',
          type: 'binary',
          condition:
            "get('answers.problem.previousTreatment.visitMatch') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Fick du någon behandling?',
          index: '0.6.1',
        },
        {
          id: 'herpes.previousTreatment.standardMedication',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'aciklovirPill',
              label: 'Aciklovir tablett',
            },
            {
              value: 'aciklovirPaste',
              label: 'Aciklovir kräm (t.ex. Anti, Zovirax)',
            },
            {
              value: 'famciklovir',
              label: 'Famciklovir (t.ex. Famvir)',
            },
            {
              value: 'penciclovir',
              label: 'Penciclovir (t.ex. Vectatone, Vectavir)',
            },
            {
              value: 'valaciklovir',
              label: 'Valaciklovir (t.ex. Valtrex)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition:
            "get('answers.problem.previousTreatment.treatment') === 'yes'",
          label: 'Vilken behandling fick du?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.6.2',
        },
        {
          id: 'herpes.previousTreatment.other',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.herpes.previousTreatment.standardMedication'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilken annan behandling fick du?',
          index: '0.6.3',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'herpes.pictures',
          type: 'upload',
          version: '2',
          validations: {
            required: true,
            min: 2,
          },
          extras: {
            instructions: ['closeup', 'lighting'],
          },
          condition:
            "(contains(get('answers.herpes.describeProblem'), 'redness')) || (contains(get('answers.herpes.describeProblem'), 'ulcer')) || (contains(get('answers.herpes.describeProblem'), 'blisters'))",
          label:
            'Bifoga minst två bilder på dina besvär, en närbild och en översiktsbild.',
          description:
            '  <p>\n    Det går bra att ta bilden med din mobilkamera, men undvik att använda dess zoom-funktion.\n  </p>\n  <p>\n    Observera att även bilderna är en del av din journal och omfattas av sekretess.\n  </p>',
          index: '0.7.0',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.8.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.8.1',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.9.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.9.1',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.9',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.10.0',
        },
      ],
      index: '0.10',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.11',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.12',
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären började',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'moreThan4days',
              label: 'För mer än 4 dagar sedan',
            },
          ],
        },
        {
          id: 'herpes.similar',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Haft liknande besvär tidigare',
          options: [
            {
              value: 'yes',
              label: 'Ja, jag har haft herpes tidigare.',
            },
            {
              value: 'yesUnsure',
              label: 'Ja, men jag är osäker på om det är herpes.',
            },
            {
              value: 'no',
              label: 'Nej',
            },
          ],
        },
        {
          type: 'section',
          condition: "get('answers.herpes.similar') === 'yes'",
          children: [
            {
              id: 'problem.samePlace.always',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Besvär alltid på samma ställe',
            },
            {
              id: 'herpes.recurring.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Lokalisation för återkommande besvär',
              description: '',
              options: [
                {
                  value: 'mouth',
                  label: 'I och/eller runt munnen',
                },
                {
                  value: 'vagina',
                  label: 'I och/eller runt slidan',
                  condition: "get('answers.predefined.gender') === 'female'",
                },
                {
                  value: 'penis',
                  label: 'På penis och/eller pungen',
                  condition: "get('answers.predefined.gender') === 'male'",
                },
                {
                  value: 'anal',
                  label: 'Runt ändtarmsöppningen',
                },
                {
                  value: 'buttocks',
                  label: 'På skinkorna och/eller låren',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
            },
            {
              id: 'problem.recurring.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Annan lokalisation för återkommande besvär',
            },
            {
              id: 'problem.current.match',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Har besvär just nu',
            },
            {
              id: 'herpes.current.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Lokalisation för besvär just nu',
              description: '',
              options: [
                {
                  value: 'mouth',
                  label: 'I och/eller runt munnen',
                },
                {
                  value: 'vagina',
                  label: 'I och/eller runt slidan',
                  condition: "get('answers.predefined.gender') === 'female'",
                },
                {
                  value: 'penis',
                  label: 'På penis och/eller pungen',
                  condition: "get('answers.predefined.gender') === 'male'",
                },
                {
                  value: 'anal',
                  label: 'Runt ändtarmsöppningen',
                },
                {
                  value: 'buttocks',
                  label: 'På skinkorna och/eller låren',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
            },
            {
              id: 'problem.current.whereOther',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Annan lokalisation för besvär just nu',
            },
            {
              id: 'herpes.describeProblem',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Besvär just nu',
              description: '',
              options: [
                {
                  value: 'itchy',
                  label: 'Pirrande/kliande känsla',
                },
                {
                  value: 'sting',
                  label: 'Svidande/molande smärta',
                },
                {
                  value: 'soreness',
                  label: 'Ömhet vid beröring',
                },
                {
                  value: 'redness',
                  label: 'Rodnad',
                },
                {
                  value: 'blisters',
                  label: 'Blåsor',
                },
                {
                  value: 'ulcer',
                  label: 'Sår',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
            },
            {
              id: 'problem.describeOther',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Andra besvär just nu',
            },
          ],
          version: '1',
        },
        {
          type: 'section',
          condition:
            "get('answers.herpes.similar') === 'yesUnsure' || get('answers.herpes.similar') === 'no'",
          children: [
            {
              id: 'herpes.current.where',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Lokalisation för besvär just nu',
              description: '',
              options: [
                {
                  value: 'mouth',
                  label: 'I och/eller runt munnen',
                },
                {
                  value: 'vagina',
                  label: 'I och/eller runt slidan',
                  condition: "get('answers.predefined.gender') === 'female'",
                },
                {
                  value: 'penis',
                  label: 'På penis och/eller pungen',
                  condition: "get('answers.predefined.gender') === 'male'",
                },
                {
                  value: 'anal',
                  label: 'Runt ändtarmsöppningen',
                },
                {
                  value: 'buttocks',
                  label: 'På skinkorna och/eller låren',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
            },
            {
              id: 'problem.current.whereOther',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Annan lokalisation för besvär just nu',
            },
            {
              id: 'herpes.describeProblem',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Besvär just nu',
              description: '',
              options: [
                {
                  value: 'itchy',
                  label: 'Pirrande/kliande känsla',
                },
                {
                  value: 'sting',
                  label: 'Svidande/molande smärta',
                },
                {
                  value: 'soreness',
                  label: 'Ömhet vid beröring',
                },
                {
                  value: 'redness',
                  label: 'Rodnad',
                },
                {
                  value: 'blisters',
                  label: 'Blåsor',
                },
                {
                  value: 'ulcer',
                  label: 'Sår',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
            },
            {
              id: 'problem.describeOther',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Andra besvär just nu',
            },
            {
              id: 'blisters.samePlaceSimilar',
              warning: "get('answers.blisters.samePlaceSimilar') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label: 'Andra besvär på samma plats innan blåsor uppstod',
              description: '',
            },
            {
              id: 'herpes.testedForChlamydia.match',
              warning:
                "get('answers.herpes.testedForChlamydia.match') === 'yesPositive'",
              type: 'question',
              version: '1',
              extras: {},
              label: 'Klamydiatest utfört',
              options: [
                {
                  value: 'yesPositive',
                  label: 'Ja, med positivt resultat',
                },
                {
                  value: 'yesNegative',
                  label: 'Ja, med negativt resultat',
                },
                {
                  value: 'yesButNoAnswer',
                  label: 'Ja, men inte fått svar än',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
              ],
            },
            {
              id: 'health.peeingPossible',
              warning: "get('answers.health.peeingPossible') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label: 'Miktionssvårigheter',
            },
            {
              id: 'health.swollenGlands',
              warning: "get('answers.health.swollenGlands') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label: 'Upplever svullna och/eller ömmande körtlar i ljumskarna',
              options: [
                {
                  value: 'yes',
                  label: 'Ja',
                },
                {
                  value: 'no',
                  label: 'Nej',
                },
                {
                  value: 'unknown',
                  label: 'Vet ej',
                },
              ],
            },
            {
              id: 'health.fever',
              warning: "get('answers.health.fever') === 'yes'",
              type: 'question',
              version: '1',
              extras: {},
              label: 'Feber',
            },
            {
              id: 'health.temperature',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Temperatur',
              unit: '°C',
            },
            {
              id: 'medication.recentAgainstFever',
              type: 'question',
              version: '1',
              extras: {},
              label: 'Febernedsättande läkemedel under de senaste 4 timmarna',
            },
          ],
          version: '1',
        },
        {
          id: 'problem.previousTreatment.visitMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare sökt läkare',
        },
        {
          id: 'problem.previousTreatment.visitWhen',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sökt läkare när',
          options: [
            {
              value: '14days',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: '3months',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'problem.previousTreatment.visitDiagnosisMatch',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fick diagnos',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diagnos',
          options: [
            {
              value: 'herpes',
              label: 'Herpes',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'problem.previousTreatment.visitOtherDiagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Annan diagnos',
        },
        {
          id: 'herpes.test',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Provtagning för herpes vid läkarbesök',
        },
        {
          id: 'problem.previousTreatment.treatment',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fick behandling',
        },
        {
          id: 'herpes.previousTreatment.standardMedication',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Behandling',
          description: '',
          options: [
            {
              value: 'aciklovirPill',
              label: 'Aciklovir tablett',
            },
            {
              value: 'aciklovirPaste',
              label: 'Aciklovir kräm (t.ex. Anti, Zovirax)',
            },
            {
              value: 'famciklovir',
              label: 'Famciklovir (t.ex. Famvir)',
            },
            {
              value: 'penciclovir',
              label: 'Penciclovir (t.ex. Vectatone, Vectavir)',
            },
            {
              value: 'valaciklovir',
              label: 'Valaciklovir (t.ex. Valtrex)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'herpes.previousTreatment.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Annan behandling',
          description: '',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Herpes',
  index: '0',
};

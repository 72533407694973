export default {
  PMHChild: {
    description: `
Unfortunately, we cannot treat your child online. 

**It is best for your child to see a GP face to face due to their medical illness**.  This is because you may need to be examined or have some tests.  

**Please make an appointment to see a GP.**  If your GP surgery is closed and you need immediate help, please call [111](tel:111) for urgent medical care. For emergencies call [999](tel:999) or go to A&E
`,
  },

  breathingDifficulty: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they are having difficulty with their breathing.**  

Please **take them to A&E or call 999 immediately**. 
`,
  },
  sepsis: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they may have signs of a serious infection.**  

Please **take them to A&E straight away or call 999.**
`,
  },
  pregnantOrBreastfeeding: {
    description: `
Unfortunately, we cannot treat your child online. 

**While pregnant or breastfeeding, it's best if your child sees a GP face to face**.

Medications and treatment need to be given extra consideration during these special times — for the sake of both mum and baby.

Please book an appointment with a GP.

If your GP surgery is closed, please contact your midwife, local out of hours service, or go to A&E.

Thank you for using Docly. We hope they feel better soon.
`,
  },
  pontentialFOBSwallowed: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because there is a possibility that they have swallowed a foreign object.**

Please **go to A&E or call 999 immediately**. 
`,
  },
  unableToSwallowWater: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs to be seen urgently — this is because they are unable to swallow water.**

Please call our support team on 020 3995 4945 and ask for an **urgent GP appointment**. Please quote 'unable to swallow water' when you book this appointment.

If your child needs immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E. 

Thank you for using Docly. We hope you child feels better soon.`,
  },
  quinsy: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because we think they have a serious throat infection.**  

Please **take them to A&E or call 999 immediately**.
`,
  },
  supprativeOtitisMedia: {
    description: `
Unfortunately, we cannot treat your child online. 

It sounds like your child may have an ear infection. We would like you to **see a GP** who will provide further help for your problem.

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**. Please quote **'Ear Infection'** when booking this appointment. 

If the service is closed, you can get **urgent care by calling 111**. For **emergencies call 999 or go to A&E**.  `,
  },
};

import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

const yesOption = { label: 'Yes', value: 'yes' };
const noOption = { label: 'No', value: 'no' };
const idkOption = { label: "I don't know", value: 'idontknow', warn: true };

/*
const hasKeyValue = (keyValue, exports) =>
  !!exports.find(e => e.id === 'keyFindings' && e.value === keyValue);
*/

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);
  //  const { ask, significant, exports } = args;
  const {
    ask,
    exports,
    patient: { age, gender },
  } = args;

  await ask.info(
    `We now need to ask you some general questions about your child's health.`
  );

  /*
  const heightMetricOrImperial = await ask({
    id: 'healthProfileChild.howtallisyourchild',
    label: 'How tall is your child?',
    description: 'Please choose either cm or feet',
    type: 'choice',
    expires: 180,
    options: [
      { label: 'Centimeters', value: 'metric' },
      { label: 'Feet and inches', value: 'imperial' },
    ],
  });

  if (heightMetricOrImperial === 'imperial') {
    await ask({
      id: 'healthProfileChild.heightfeet',
      label: 'How tall is your child?',
      description: `It's essential that we receive the correct information regarding your height in feet and inches. For this reason, we ask you to enter your height over two screens. So if, for example, you are 5 foot 10 inches in height, you enter 5 on the first screen and 10 on the second screen.`,
      type: 'number',
      expires: 180,
      min: 1,
      max: 9,
      unit: 'feet',
    });

    await ask({
      id: 'healthProfileChild.heightinches',
      label: 'How tall is your child?',
      type: 'number',
      expires: 180,
      min: 0,
      max: 11,
      unit: 'inches',
    });
  } else {
    await ask({
      id: 'healthProfileChild.heightcm',
      label: 'How tall is your child?',
      type: 'number',
      expires: 180,
      unit: 'cm',
      min: 50,
      max: 300,
    });
  }

  const weightMetricOrImperial = await ask(
    {
      id: 'healthProfileChild.weight',
      label: 'How much does your child weigh?',
      description: 'Please choose either kg or stones',
      type: 'choice',
      expires: 180,
    },
    {
      options: [
        { label: 'Kilograms', value: 'metric', hide: true },
        { label: 'Stones and pounds', value: 'imperial', hide: true },
      ],
    }
  );

  if (weightMetricOrImperial === 'imperial') {
    const weightStones = await ask({
      id: 'healthProfileChild.weightstone',
      label: 'How much does you weigh?',
      description: `It is essential that we receive the correct information regarding your weight in stones and pounds. For this reason, we ask you to enter your weight over two screens. So if, for example, you weigh 10 stone 5 pounds, you enter 10 on the first screen and 5 on the second screen.`,
      type: 'number',
      expires: 180,
      min: 2,
      max: 80,
      unit: 'stone',
    });

    const weightPounds = await ask({
      id: 'healthProfileChild.weightpounds',
      label: 'How much does your weigh?',
      type: 'number',
      expires: 180,
      min: 0,
      max: 13,
      unit: 'pounds',
    });

    significant.push({
      label: 'Weight (stones and lbs)',
      type: 'text',
      value: `${weightStones} stones and ${weightPounds} lbs`,
    });
  } else {
    const weightKilogramQ = {
      id: 'healthProfileChild.weightkilogram',
      label: 'How much does your child weigh?',
      type: 'number',
      expires: 180,
      min: 1,
      max: 700,
      unit: 'kg',
    };
    const weightKilogram = await ask(weightKilogramQ);
    significant.push({
      ...weightKilogramQ,
      label: 'Weight (kg)',
      value: [`${weightKilogram}`],
    });
  }
  */

  // Don't ask again if already asked in other guide (ie: kids sore throat) (checked via keyvalues)
  //  const alreadyAsked =
  //    hasKeyValue('Immunisations up to date', exports) ||
  //    hasKeyValue('Immunisations NOT up to date', exports);

  //  if (!alreadyAsked) {

  const clinicalNotes = {
    color: 'black',
    label: 'Review clinical notes',
  };

  await eChoice({
    id: 'healthProfileChild.vaccinations',
    label: 'Are your vaccinations up to date?',
    type: 'choice',
    clinicLabel: 'Vaccinations up to date?',
    options: [
      { ...yesOption, keyFinding: 'Immunisations up to date' },
      {
        ...noOption,
        keyFinding: 'Immunisations NOT up to date',
        warn: true,
        ds: {
          ...clinicalNotes,
          description:
            'Vaccination history not up to date. Possible safeguarding concern',
        },
      },
      {
        label: 'I have decided against some vacccines',
        keyFinding: 'Parent decided against some vaccinations',
        value: 'decidedAgainstSomeVaccinations',
        warn: true,
      },
      {
        ...idkOption,
        ds: {
          ...clinicalNotes,
          description: 'Uncertain vacccination history',
        },
      },
    ],
  });
  //  }

  const PMHConditions1 = await eChoice({
    id: 'healthProfileChild.PMHCondition1',
    label: 'Does your child have any of the conditions listed below:',
    isMultipleChoice: true,
    optional: true,
    clinicLabel: 'PMH Condition1',
    options: [
      { label: 'Asthma', keyFinding: 'Asthma', value: 'Asthma' },
      { label: 'Eczema', keyFinding: 'Eczema', value: 'Eczema' },
      { label: 'Diabetes', keyFinding: 'Diabetes', value: 'Diabetes' },
      { label: 'ADHD', keyFinding: 'ADHD', value: 'ADHD' },
      { label: 'Epilepsy', keyFinding: 'Epilepsy', value: 'Epilepsy' },
    ],
  });

  const PMHConditions2 = await eChoice({
    id: 'healthProfileChild.PMHCondition2',
    label: 'Does your child have any of the conditions listed below:',
    isMultipleChoice: true,
    optional: true,
    clinicLabel: 'PMH Condition2',
    options: [
      {
        label: 'Cerebral palsy',
        keyFinding: 'Cerebral palsy',
        value: 'Cerebralpalsy',
      },
      {
        label: 'Sickle cell anemia',
        keyFinding: 'Sickle cell anemia',
        value: 'Sicklecellanemia',
      },
      {
        label: 'Cystic fibrosis',
        keyFinding: 'Cystic fibrosis',
        value: 'Cysticfibrosis',
      },
      {
        label: 'Spina bifida',
        keyFinding: 'Spina bifida',
        value: 'Spinabifida',
      },
      {
        label: 'Congenital heart problems',
        keyFinding: 'Congenital heart problems',
        value: 'Congenitalheartproblems',
      },
      {
        label: 'Other',
        value: 'other',
        hide: true,
      },
    ],
  });

  if (
    Array.isArray(PMHConditions1) &&
    PMHConditions1.length === 0 &&
    (Array.isArray(PMHConditions2) && PMHConditions2.length === 0)
  ) {
    exports.push({ id: 'keyFindings', value: 'No other PMH' });
  }

  if (Array.isArray(PMHConditions2) && PMHConditions2.includes('other')) {
    await eText({
      label: `Please tell us more about your child's health problem`,
      clinicLabel: 'PMH',
    });
  }

  const medication = await eChoice({
    label:
      'Is your child taking any regular medication, including natural remedies?',
    clinicLabel: 'Regular Medication',
    options: [
      {
        ...yesOption,
        hide: true,
        keyFinding: 'Regular medication',
      },
      {
        ...noOption,
        keyFinding: 'No regular medication',
      },
    ],
  });

  if (medication === 'yes') {
    await eText({
      label: 'What medication or natural remedy is your child taking?',
      description:
        'List the medication, strength and dose, e.g. Lamotrigine, 200 mg, 2 tablets once daily',
      clinicLabel: 'Current Medication',
    });
  }

  // *** KHP8 ***
  const KHP8 = await eChoice({
    id: 'KHP8',
    label: 'Does your child have any allergies?',
    description: 'E.g. Medication, food, animals, plants.',
    clinicLabel: 'Allergies',
    expire: 0,
    options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
  });

  if (KHP8 === 'yes') {
    // *** KHP9 ***
    // Only shown if KHP8y selected

    const KHP9 = await eChoice({
      id: 'KHP9',
      label: 'What are they allergic to?',
      clinicLabel: 'Type of allergy',
      isMultipleChoice: true,
      options: [
        {
          label: 'Penicillin',
          value: 'penicillin',
          keyFinding: 'Penicillin allergy',
          warn: true,
        },
        {
          label: 'Other medication',
          value: 'othermedication',
          keyFinding: 'Other medication allergy',
        },
        { label: 'Lactose', value: 'lactose', keyFinding: 'Lactose allergy' },
        { label: 'Gluten', value: 'gluten', keyFinding: 'Gluten allergy' },
        { label: 'Nuts', value: 'nuts', keyFinding: 'Nuts allergy' },
        {
          label: 'Pollen / Hayfever ',
          value: 'pollen/hayfever',
          keyFinding: 'Pollen allergy',
        },
        { label: 'Animals', value: 'animals', keyFinding: 'Animal allergy' },
        { label: 'House dust', value: 'housedust', keyFinding: 'Dust allergy' },
        { label: 'Other', value: 'other', keyFinding: 'Other allergy' },
      ],
    });

    if (Array.isArray(KHP9)) {
      // *** KHP10 ***
      // Only shown if KHP9b selected

      if (KHP9.includes('othermedication')) {
        await eText({
          id: 'KHP10',
          label: 'What other medications are they allergic to?',
          clinicLabel: 'Medication allergies',
          warn: true,
        });
      }

      if (KHP9.includes('lactose')) {
        await ask.info(`
Many medications contain a small amount of lactose, this is unlikely to cause your child any problems unless their lactose allergy is severe. 
Do you accept responsibility for checking if any medications we prescribe contain lactose and the effects this may have?`);
      }

      if (KHP9.includes('other')) {
        await eText({
          id: 'KHP12',
          label: 'What else is your child allergic to?',
          clinicLabel: 'Other allergies',
          warn: true,
        });
      }
    }
  }

  if (gender === 'female' && age > 13) {
    const pregnant = await eChoice({
      id: 'hp.pregnant',
      label: 'Is your daughter currently pregnant or been pregnant previously?',
      clinicLabel: 'Pregnant',
      options: [
        {
          label: 'Yes, she is currently pregnant',
          value: 'currently',
          keyFinding: 'Patient currently pregnant',
          warn: true,
          ds: {
            label: 'Reject - Urgent Appointment',
            description: 'Safeguarding concern - pregnant',
            color: 'red',
          },
        },
        {
          label: 'Yes, she has been pregnant previously ',
          value: 'previously',
          warn: true,
          ds: {
            label: 'Reject - Urgent Appointment',
            description: 'Safeguarding concern - previous pregnancy',
            color: 'red',
          },
        },
        {
          label: 'No',
          value: 'no',
          hide: true,
        },
      ],
    });

    if (['previously', 'currently'].includes(pregnant)) {
      await eChoice({
        id: 'hp.breastfeeding',
        label: 'Is your daughter currently breastfeeding?',
        clinicLabel: 'Breastfeeding',
        expire: 0,
        options: [
          {
            label: 'Yes',
            value: 'yes',
            keyFinding: 'Breastfeeding',
            warn: true,
            ds: {
              label: 'Reject - Urgent Appointment',
              description: 'Safeguarding concern - breastfeeding',
              color: 'red',
            },
          },
          {
            label: 'No',
            value: 'no',
            keyFinding: 'Not breastfeeding',
            hide: true,
          },
        ],
      });
    }
  }

  /*
  await eChoice({
    label: 'Is your child allergic to penicillin?',
    clinicLabel: 'Penicillin allergy',
    options: [
      { ...yesOption, warn: true, keyFinding: 'Allergic to penicillin' },
      { ...noOption, keyFinding: 'Not known to be allergic to penicillin' },
    ],
  });


  const allergic = await eChoice({
    label: 'Is your child allergic to any other medication?',
    options: [
      {
        ...yesOption,
        hide: true,
        keyFinding: 'Other drug allergies',
      },
      {
        ...noOption,
        keyFinding: 'No known drug allergies',
      },
    ],
  });

  if (allergic === 'yes') {
    await eText({
      label: `Please list your child's medication allergies below.`,
      clinicLabel: 'Drug allergies',
      warn: true,
    });
  }
*/

  // *** KS2 ***

  const KS2 = await eChoice({
    id: 'KS2',
    label:
      'Have any of these issues ever affected your child, or anyone in the household?',
    clinicLabel:
      'Possible safeguarding concern/additional needs in child or household',
    expire: 99999,
    optional: true,
    isMultipleChoice: true,
    options: [
      {
        label: 'Thoughts of self harm or harm to others',
        value: 'thoughtsofselfharmorharmtoothers',
        keyFinding:
          'Child or household member has previous/current thoughts of self-harm or harm to others',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concerns - self harm',
          color: 'red',
        },
      },
      {
        label:
          'Mental health condition (e.g. depression, anxiety, schizophrenia, anorexia, ADHD, autism)',
        value:
          'mentalhealthcondition(egdepression,anxiety,schizophrenia,anorexia,adhd,autism)',
        keyFinding:
          'Child or household member has a previous/current mental health condition',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - mental health',
          color: 'red',
        },
      },
      {
        label:
          'Addiction to alcohol or illegal drugs (e.g. cannabis, heroin, weed)',
        value: 'addictiontoalcoholorillegaldrugs(egcannabis,heroin,weed)',
        keyFinding:
          'Child or household member has previous/current addiction to alcohol or illegal drugs',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - alcohol/ drug addiction',
          color: 'red',
        },
      },
      {
        label: 'Physical disability',
        value: 'physicaldisability',
        keyFinding:
          'Child or household member has previous/current physical disability',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - physical disability',
          color: 'red',
        },
      },
      {
        label: 'Learning disability',
        value: 'learningdisability',
        keyFinding: 'Child or household member has a learning disability',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - learning disability',
          color: 'red',
        },
      },
      {
        label: 'Cancer',
        value: 'cancer',
        keyFinding: 'Child or household member has previous/current cancer',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - cancer',
          color: 'red',
        },
      },
    ],
  });

  if (Array.isArray(KS2) && KS2.length > 0) {
    await eText({
      id: 'KS3',
      label: 'Please tell us more.',
      clinicLabel:
        'Further details about potential safeguarding issue/additional needs',
      description: 'e.g. who, when and how they are affected. ',
      warn: true,
      expire: 99999,
    });
  }

  // *** KS4 ***
  const KS4 = await eChoice({
    label:
      'Have any of these ever applied to your child, or anyone in the household?',
    clinicLabel:
      'Possible safeguarding concern/additional needs in child or household',
    optional: true,
    isMultipleChoice: true,
    expire: 99999,
    options: [
      {
        label: 'Safeguarding concerns',
        value: 'safeguardingconcerns',
        keyFinding:
          'Child or household member has previous/current safeguarding concerns',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concerns in household',
          color: 'red',
        },
      },
      {
        label: 'Social services involvement',
        value: 'socialservicesinvolvement',
        keyFinding:
          'Child or household member previously/currently under social services',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Social services involvement',
          color: 'red',
        },
      },
      {
        label: 'CAMHS (Child and Adolescent Mental Health Service) involvement',
        value: 'camhs(childandadolescentmentalhealthservice)involvement',
        keyFinding:
          'Child or household member previously/currently under CAMHS',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'CAMHS involvement',
          color: 'red',
        },
      },
      {
        label: 'Psychiatric hospital admission',
        value: 'psychiatrichospitaladmission',
        keyFinding:
          'Child or household member previously/currently admitted to mental health hospital',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Previous/current admission to psychiatric hospital ',
          color: 'red',
        },
      },
      {
        label: 'Domestic abuse',
        value: 'domesticabuse',
        keyFinding:
          'Child or household member previous/current at risk of domestic abuse',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description:
            'Child or household member previously/currently at risk domestic abuse',
          color: 'red',
        },
      },
      {
        label: 'Female genital multilation ',
        value: 'femalegenitalmultilation',
        keyFinding:
          'Child or household member has previously had or is at risk of FGM ',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - FGM',
          color: 'red',
        },
      },
      {
        label: 'Trafficking',
        value: 'trafficking',
        keyFinding:
          'Child or household member has previously/currently been a victim of trafficking',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description:
            'Child or household member previously/currently at risk of trafficking',
          color: 'red',
        },
      },
    ],
  });

  if (Array.isArray(KS4) && KS4.length > 0) {
    await eText({
      id: 'KS5',
      label: 'Please tell us more.',
      clinicLabel:
        'Further details about potential safeguarding issue/additional needs',
      description: 'e.g. who, when and how they were affected.',
      expire: 99999,
      warn: true,
    });

    if (KS4.includes('domesticabuse')) {
      await ask.choice({
        description: `
If you are experiencing domestic abuse, it is important to have a safe place that you can go to.
  
If you, or anyone else is in danger please call 999.`,
        options: ['I understand'],
      });
    }
  }

  // *** KS7 ***
  await eChoice({
    id: 'KS7',
    label: 'Do any of the following apply to your child?',
    clinicLabel:
      'Possible safeguarding concern/additional needs in child or household',
    expire: 365,
    optional: true,
    isMultipleChoice: true,
    options: [
      {
        label: 'Asylum seeker',
        value: 'asylumseeker',
        keyFinding: 'Child is an asylum seeker',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - asylum seeker',
          color: 'red',
        },
      },
      {
        label: 'Needs an interpretor',
        value: 'needsaninterpretor',
        keyFinding: 'Child needs an interpretor',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Safeguarding concern - needs interpreter',
          color: 'red',
        },
      },
      {
        label: 'Homeless or at risk of becoming homeless',
        value: 'homelessoratriskofbecominghomeless',
        keyFinding: 'Child is homeless or at risk of becoming homeless',
        warn: true,
        ds: {
          label: 'Reject - Urgent Appointment',
          description: 'Homeless or at risk of becoming homesless',
          color: 'red',
        },
      },
    ],
  });

  // *** KS8 ***
  await eChoice({
    id: 'KS8',
    label: 'Do any of the following apply to your child?',
    clinicLabel:
      'Possible safeguarding concern/additional needs in child or household',
    optional: true,
    isMultipleChoice: true,
    expires: 365,
    options: [
      {
        label: 'More than 3 visits to A&E or Urgent Care in the last 12 months',
        value: 'morethan3visitstoa&eorurgentcareinthelast12months',
        warn: true,
        ds: {
          label: 'Reject - Routine Appointment',
          description:
            'Child has more than 3 visits to A&E or UCC in the last 12 months',
          color: 'red',
        },
      },
      {
        label: 'Under the care of a specialist',
        value: 'underthecareofaspecialist',
        warn: true,
        ds: {
          label: 'Reject - Routine Appointment',
          description: 'Under secondary care',
          color: 'red',
        },
      },
      {
        label: 'Spent a night in hospital in the last 12 months',
        value: 'spentanightinhospitalinthelast12months',
        warn: true,
        ds: {
          label: 'Reject - Routine Appointment',
          description: 'Hospital admission in last 12 months',
          color: 'red',
        },
      },
    ],
  });

  const generalHealth = await ask(
    `Is there anything else about your child's general state of health that the doctor should know?`
  );
  if (generalHealth === 'yes') {
    await eText({
      label: `What else about your child's general state of health would you like to tell the doctor?`,
      clinicLabel: 'General Health',
    });
  }

  await ask.choice({
    description: `
We will aim to reply within the next 2 hours.  

If your child becomes more unwell whilst you are waiting, call 111 for urgent medical care. For emergencies call 999 or go to to A&E.
`,
    options: ['I understand'],
  });

  return {};
}: Guide);

import type { Guide } from '../../types';

import chc from './chc';
import { decisionSupports } from './decisionSupport';
import larc from './larc';
import { lowDosePills, popPills } from './pills';
import pop from './pop';
import sideEffects from './sideEffects';
import usingContraceptive from './usingContraceptive';
import { getContraceptiveBrandLabel } from './utils';

export default (async args => {
  const { decisionSupport } = args;

  const { using, brandName } = await usingContraceptive(args);

  let want = using;

  if (['combined', 'progesterone', 'dontKnow'].includes(want)) {
    decisionSupport.push(
      decisionSupports.repeatContraceptive12Months(
        getContraceptiveBrandLabel(want, brandName)
      )
    );
  } else if (want === 'skinPatch') {
    decisionSupport.push(decisionSupports.repeatContraceptiveSkinPatch);
  } else if (want === 'vaginalRing') {
    decisionSupport.push(decisionSupports.repeatContraceptiveVaginalRing);
  } else {
    const { abort, want: alternative } = await larc(args);
    if (abort) return { abort };

    want = alternative;
  }

  await sideEffects(args);

  if (popPills.includes(brandName)) {
    return await pop(args);
  }

  return await chc({
    ...args,
    doctorChoose: want === 'doctorChoose',
    skinPatch: want === 'skinPatch',
    lowDose: lowDosePills.includes(brandName),
  });
}: Guide);

import type { Guide } from '../../types';

export default (async ({ ask, significant }) => {
  const vaccinationAdviceType = {
    type: 'choice',
    id: 'vaccinationAdviceType',
    label: 'Vilken typ av vaccination gäller ditt besök huvudsakligen?',
    options: [
      {
        value: 'travel',
        label: 'Vaccination inför resa',
      },
      {
        value: 'tbe',
        label: 'Vaccination mot TBE',
      },
      {
        value: 'flu',
        label: 'Vaccination mot säsongsinfluensa',
      },
      {
        value: 'other',
        label: 'Övrigt vaccinationsärende',
      },
    ],
  };

  const vaccinationAdviceTypeAnswer = await ask(vaccinationAdviceType);

  significant.push({
    ...vaccinationAdviceType,
    label: 'Rådgivning gäller huvudsakligen',
    value: vaccinationAdviceTypeAnswer,
  });

  const adviceTo = {
    type: 'choice',
    id: 'adviceTo',
    label: 'Vem gäller vaccinationsrådgivningen?',
    options: [
      {
        value: 'mySelf',
        label: 'Endast mig själv',
      },
      {
        value: 'myChildren',
        label: 'Endast mitt/mina barn',
      },
      {
        value: 'mySelfAndFamily',
        label: 'Mig själv och fler familjemedlemmar',
      },
    ],
  };

  const adviceToAnswer = await ask(adviceTo);

  significant.push({
    ...adviceTo,
    label: 'Rådgivning gäller',
    value: adviceToAnswer,
  });

  if (adviceToAnswer.includes('myChildren')) {
    await ask.info(``, {
      description: `
        Observera att du som förälder som loggat in med BankID behöver vara den som följer med till mottagningen och visar upp din legitimation i samband med vaccinationen för att kunna nyttja rabatten.
      `,
    });
  }

  if (adviceToAnswer.includes('mySelfAndFamily')) {
    await ask.info(``, {
      description: `
      Observera att du som loggat in med BankID behöver följa med till mottagningen och visa upp din legitimation i samband med vaccinationen för att ni ska kunna nyttja rabatten.
      `,
    });
  }

  if (adviceToAnswer.includes('myChildren')) {
    const childrenAge = {
      type: 'text',
      id: 'childrenAge',
      label: 'Hur gamla är dina barn som ev. ska vaccineras?',
    };
    const childrenAgeAnswer = await ask(childrenAge);

    significant.push({
      ...childrenAge,
      label: 'Ålder på barn',
      value: childrenAgeAnswer,
    });
  }

  if (adviceToAnswer.includes('mySelfAndFamily')) {
    const familyAge = {
      type: 'text',
      id: 'familyAge',
      label: 'Hur gamla är de familjemedlemmar som ev. ska vaccineras?',
    };
    const familyAgeAnswer = await ask(familyAge);

    significant.push({
      ...familyAge,
      label: 'Ålder på familjemedlemmar',
      value: familyAgeAnswer,
    });
  }

  if (vaccinationAdviceTypeAnswer.includes('travel')) {
    const destination = {
      type: 'text',
      id: 'destination',
      label: 'Vart ska du/ni resa?',
    };
    const destinationAnswer = await ask(destination);

    significant.push({
      ...destination,
      label: 'Resmål',
      value: destinationAnswer,
    });

    const travelDate = {
      type: 'text',
      id: 'travelDate',
      label: 'Vilket datum reser du/ni iväg?',
    };
    const travelDateAnswer = await ask(travelDate);

    significant.push({
      ...travelDate,
      label: 'Datum för avresa',
      value: travelDateAnswer,
    });

    const travelDuration = {
      type: 'text',
      id: 'travelDuration',
      label: 'Hur länge ska du/ni vara borta?',
    };
    const travelDurationAnswer = await ask(travelDuration);

    significant.push({
      ...travelDuration,
      label: 'Reslängd',
      value: travelDurationAnswer,
    });

    const accommodation = {
      type: 'text',
      id: 'accommodation',
      label: 'Hur ska du/ni bo/vistas?',
      description:
        'Ex. i stad, på landsbygd, bo tillsammans med lokalbefolkning, resa omkring',
    };
    const accommodationAnswer = await ask(accommodation);

    significant.push({
      ...accommodation,
      label: 'Boende på resmål',
      description: '',
      value: accommodationAnswer,
    });
  }

  const other = {
    type: 'text',
    id: 'other',
    label: 'Vilka frågor och funderingar har du kring den ev. vaccinationen?',
  };
  const otherAnswer = await ask(other);

  significant.push({
    ...other,
    label: 'Frågor och funderingar kring ev. vaccination',
    value: otherAnswer,
  });

  return {};
}: Guide);

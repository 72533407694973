import * as React from 'react';
import cn from 'classnames';

import typography, { fontWeights } from '../../theme/typography';

import withStyles from '@material-ui/core/styles/withStyles';

import Menu from '../../components/Menu';

import PatientButton from '../../patient/PatientButton';

import ClearButton from './ClearButton';
import CountryPicker from './CountryPicker';
import HistoryButton from './HistoryButton';
import JumpButton from './JumpButton';
import RandomizeButton from './RandomizeButton';
import SwitchButton from './SwitchButton';
import SourceButton from './SourceButton';
import SaveTestButton from './SaveTestButton';

class ActionMenu extends React.Component<{
  country: string,
  guideId: string,
  classes: { [string]: string },
  alwaysOpen: boolean,
}> {
  render() {
    const { country, guideId, classes, alwaysOpen, ...rest } = this.props;

    const buttonClassName = cn(classes.button, {
      [classes.buttonInCollapsibleMenu]: !alwaysOpen,
    });

    return (
      <Menu alwaysOpen={alwaysOpen} {...rest}>
        <CountryPicker country={country} className={classes.picker} />

        <PatientButton className={buttonClassName} />

        {!!guideId && (
          <>
            <SwitchButton country={country} className={buttonClassName} />

            <ClearButton guideId={guideId} className={buttonClassName} />

            <RandomizeButton guideId={guideId} className={buttonClassName} />

            <HistoryButton guideId={guideId} className={buttonClassName} />

            <JumpButton guideId={guideId} className={buttonClassName} />

            <SourceButton guideId={guideId} className={buttonClassName} />

            <SaveTestButton guideId={guideId} className={buttonClassName} />
          </>
        )}
      </Menu>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  picker: {
    marginBottom: spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  button: {
    marginBottom: spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
    ...typography.body1,
    fontWeight: fontWeights.medium,
    border: 'none',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    [down('xs')]: {
      boxShadow: 'none',
      paddingLeft: spacing(),
      paddingRight: spacing(),
    },
  },
  buttonInCollapsibleMenu: {
    boxShadow: 'none',
    paddingLeft: spacing(),
    paddingRight: spacing(),
  },
}))(ActionMenu);

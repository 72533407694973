import type { Guide } from '../../../types';

export default (async args => {
  const { ask } = args;

  await ask.choice({
    description: `
**Call 999 now if you have:** 

* **Signs of a heart attack** - Pain like a very tight band, heavy weight or squeezing in the centre of your chest
* **Signs of a stroke** - face drooping on one side, can’t hold both arms up, difficulty speaking
* **Severe difficulty breathing** - gasping, not being able to get words out, choking or lips turning blue
* **Heavy bleeding** - that won’t stop
* **Severe injuries** - or deep cuts after a serious accident
* **Seizure (fit)** - someone is shaking or jerking because of a fit, or is unconscious (can’t be woken up)
* **Sudden or rapid swelling** - of the eyes, lips, mouth, throat or tongue
`,
    options: ['I have none of these'],
  });

  return {};
}: Guide);

import * as React from 'react';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import type { ThemeWidth } from '../../theme/types';

class Header extends React.Component<{
  icon: *,
  title: string,
  width: ThemeWidth,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { icon, title, width, classes, className } = this.props;

    return (
      <div className={classNames(classes.root, className)}>
        <img alt="logo" src={icon} className={classes.icon} />

        <Typography variant={isWidthUp('sm', width) ? 'h2' : 'h3'}>
          {title}
        </Typography>
      </div>
    );
  }
}

export default withWidth()(
  withStyles(({ spacing, breakpoints: { up } }) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: spacing(1.5),
    },

    icon: {
      height: 40,
      width: 40,
      marginRight: spacing(1.5),
      [up('md')]: {
        height: 50,
        width: 50,
        marginRight: spacing(2),
        marginLeft: -(50 + spacing(2)),
      },
    },
  }))(Header)
);

import type { Guide } from '../../types';

import aborts from './aborts';
import { decisionSupports } from './decisionSupport';

export default (async ({ ask, decisionSupport, significant }) => {
  await ask.choice({
    description: `
# Before we begin...

We can only prescribe contraceptive pills, skin patches or NuvaRing online.
We cannot help with coils, implants and injections.
    `,
    options: ['I understand'],
  });

  const wantLabel = 'What type of contraceptive do you need today?';
  const wantOptions = {
    pillPathOrRing: 'Contraceptive pill, patch or vaginal ring',
    coilImplantInjectionOther:
      'Coil, Implant, Injection or other contraceptives',
  };
  const want = await ask.choice(wantLabel, {
    id: 'triage.type',
    options: wantOptions,
  });
  if (want === 'coilImplantInjectionOther') {
    significant.push({
      type: 'choice',
      label: wantLabel,
      options: wantOptions,
      value: want,
      warn: true,
    });
    return {
      abort: aborts.appointment,
    };
  }

  const pregnant = await ask(
    'Are you pregnant, or do you think you could be pregnant?',
    { id: 'triage.pregnant' }
  );
  if (pregnant === 'yes') {
    significant.push({
      label: 'Pregnant, or could be pregnant',
      value: pregnant,
      warn: true,
    });
    return { abort: aborts.pregnant };
  }

  const breastfeeding = await ask('Are you breastfeeding?', {
    id: 'triage.breastfeeding',
  });
  if (breastfeeding === 'yes') {
    significant.push({
      label: 'Breastfeeding',
      value: breastfeeding,
      warn: true,
    });
    return { abort: aborts.breastfeeding };
  }

  const recentBaby = await ask('Have you had a baby in the last 6 weeks?', {
    id: 'triage.recentBaby',
  });
  if (recentBaby === 'yes') {
    significant.push({
      label: '≤ 6 weeks postpartum',
      value: recentBaby,
      warn: true,
    });
    return { abort: aborts.baby };
  }

  const bleeding = await ask(
    'Do you get any bleeding between periods or after sex?',
    { id: 'triage.vaginalBleeding' }
  );
  if (bleeding === 'yes') {
    significant.push({
      label: 'Post coital or intermenstrual bleeding',
      value: bleeding,
      warn: true,
    });
    decisionSupport.push(decisionSupports.vaginalBleeding);

    const seenDoctor = await ask('Have you seen a doctor about this?');
    significant.push({
      label: 'Seen a doctor about PCB / IMB',
      value: seenDoctor,
      warn: 'no',
    });
    if (seenDoctor === 'yes') {
      significant.push({
        type: 'text',
        label: 'Cause / Diagnosis',
        value: await ask.text('What was causing the bleeding (diagnosis)?'),
      });
    }

    await ask.choice({
      description: `
The doctor will send you a message about your vaginal bleeding.

Please continue with the questionnaire.
      `,
      options: ['I understand'],
    });
  }

  await ask.choice({
    description:
      'Hormonal contraception can increase your risk of breast cancer. You need to check your breasts regularly and see your GP urgently if you notice any changes. (e.g. lumps, dimpling of the skin, or changes in the nipple).',
    options: ['I understand'],
  });
  const riskOfBreastCancer = await ask.tertiary(
    'Have you been told that you are at high risk of breast cancer, or that you carry the breast cancer gene?',
    {
      id: 'triage.breastCancer',
      expires: 365,
    }
  );
  if (['yes', 'unknown'].includes(riskOfBreastCancer)) {
    significant.push({
      type: 'tertiary',
      label: 'Increased risk of breast cancer',
      value: riskOfBreastCancer,
      warn: true,
    });
    if (riskOfBreastCancer === 'yes') {
      return { abort: aborts.breastCancer };
    } else if (riskOfBreastCancer === 'unknown') {
      decisionSupport.push(decisionSupports.breastCancer);
    }
  }

  const recentSexWithoutProtection = await ask({
    id: 'triage.recentSexWithoutProtection',
    description: `
# In the past 2 months, have you had sex without contraceptive protection, e.g.

- no contraception used
- missed any pills
- forgotten to use your contraceptive on time?
    `,
  });
  if (recentSexWithoutProtection === 'yes') {
    significant.push({
      label: 'UPSI or Missed contraception in past 2 months',
      value: recentSexWithoutProtection,
    });
    const emergencyContraceptionQuestion = {
      type: 'choice',
      label:
        'Did you use emergency contraception, or follow the missed contraception advice in the leaflet that comes with your medication?',
      options: {
        yesEmergency: 'Yes - I used emergency contraception',
        yesAdvice: 'Yes - I followed the missed contraception advice',
        no: 'No',
      },
    };
    const emergencyContraception = await ask(emergencyContraceptionQuestion);
    significant.push({
      ...emergencyContraceptionQuestion,
      value: emergencyContraception,
    });
    let recentEmergencyContraception;
    if (emergencyContraception === 'yesEmergency') {
      const recentEmergencyContraceptionQuestion = {
        type: 'choice',
        label:
          'Have you taken the emergency contraceptive pill in the last 5 days?',
        options: {
          yesEllaOne: 'Yes - I took ellaOne',
          yesLevonorgestel: 'Yes - I took levonorgestrel (Levonelle)',
          yesDontKnow: `Yes - I don't know what I took`,
          no: 'No',
        },
      };
      recentEmergencyContraception = await ask(
        recentEmergencyContraceptionQuestion
      );
      if (
        recentEmergencyContraception === 'yesEllaOne' ||
        recentEmergencyContraception === 'yesDontKnow'
      ) {
        significant.push({
          ...recentEmergencyContraceptionQuestion,
          value: recentEmergencyContraception,
          warn: true,
        });
        return { abort: aborts.emergencyContraception };
      }
    }
    if (
      emergencyContraception === 'yesAdvice' ||
      emergencyContraception === 'no' ||
      recentEmergencyContraception === 'no'
    ) {
      const normalPeriodQuestion = {
        type: 'choice',
        label:
          'Have you had a "normal" period since having unprotected sex or missing your contraception?',
        description:
          'If it was lighter, shorter or late, then that would be abnormal.',
        options: {
          yes: 'Yes',
          no: 'No',
          noNotDue: 'No, my period is not due yet',
          dontGetPeriod: `I don't get periods`,
        },
      };
      const normalPeriod = await ask(normalPeriodQuestion);
      significant.push({ ...normalPeriodQuestion, value: normalPeriod });
      if (normalPeriod !== 'yes') {
        const pregnancyTestQuestion = {
          type: 'choice',
          label:
            'Did you do a pregnancy test 3 weeks after the unprotected sex or missed contraception?',
          options: {
            yes: 'Yes',
            yesLessThan3WeeksAfter:
              'Yes, but it was less than 3 weeks afterwards',
            no: 'No',
            noLessThan3WeeksAgo: 'No - this happened less than 3 weeks ago',
          },
        };
        const pregnancyTest = await ask(pregnancyTestQuestion);
        significant.push({
          ...pregnancyTestQuestion,
          value: pregnancyTest,
          warn: ['yesLessThan3WeeksAfter', 'no'],
        });
        if (['yesLessThan3WeeksAfter', 'no'].includes(pregnancyTest)) {
          return { abort: aborts.pregnancyTest };
        }
        if (pregnancyTest === 'noLessThan3WeeksAgo') {
          const proceedQuestion = {
            type: 'choice',
            label: `You need to make sure that you do a pregnancy test 3 weeks after a possible pregnancy could occur. If you are already pregnant, hormonal contraception will not harm an unborn baby.`,
            options: {
              yes: 'I understand. I wish to continue.',
              no: 'I do NOT wish to continue.',
            },
          };
          const proceed = await ask(proceedQuestion);
          significant.push({
            ...proceedQuestion,
            value: proceed,
            warn: 'no',
          });
          if (proceed === 'no') {
            return { abort: aborts.pregnancyTest };
          }
        } else if (pregnancyTest === 'yes') {
          const pregnancyTestResultQuestion = {
            type: 'choice',
            label: 'What was the result of pregnancy test?',
            options: {
              negative: 'Negative',
              positive: 'Positive',
            },
          };
          const pregnancyTestResult = await ask(pregnancyTestResultQuestion);
          significant.push({
            ...pregnancyTestResultQuestion,
            value: pregnancyTestResult,
            warn: 'positive',
          });
          if (pregnancyTestResult === 'positive') {
            return { abort: aborts.pregnant };
          }
        }
      }
    }
  }

  const reasonQuestion = {
    id: 'triage.reason',
    type: 'multipleChoice',
    options: {
      stopMeGettingPregnant: 'Stop me getting pregnant',
      regulatePeriod: 'Regulate my periods',
      painfulOrHeavyPeriods: 'Painful or heavy periods',
      ivf: 'Part of IVF treatment',
      other: 'Other',
    },
    expires: 365,
  };
  const reason = await ask({
    ...reasonQuestion,
    label: 'What is your main reason for wanting contraception?',
    description: 'More than one can be selected',
  });
  significant.push({
    ...reasonQuestion,
    label: 'Reason for wanting contraception',
    value: reason,
    warn: reason.includes('ivf'),
  });
  if (reason.includes('ivf')) {
    return { abort: aborts.ivf };
  }
  if (reason.includes('other')) {
    const question = {
      type: 'text',
      label: 'Please give details',
      expires: 365,
    };
    significant.push({
      ...question,
      value: await ask(question),
      warn: true,
    });

    decisionSupport.push(decisionSupports.otherReason);
  }
  if (reason.includes('painfulOrHeavyPeriods')) {
    const recommendedByQuestion = {
      type: 'choice',
      label: `Who recommended the contraceptive pill for your painful / heavy periods?`,
      options: {
        myself: 'Myself - I know it will help',
        doctor: 'Doctor',
      },
    };
    const recommendedBy = await ask(recommendedByQuestion);
    if (recommendedBy === 'myself') {
      significant.push({
        ...recommendedByQuestion,
        value: recommendedBy,
        warn: !reason.includes('stopMeGettingPregnant'),
      });

      if (!reason.includes('stopMeGettingPregnant')) {
        return { abort: aborts.period };
      }

      const question = {
        type: 'choice',
        label:
          'You need to see a doctor about your painful / heavy periods, as it may be caused by something serious, but we can still help you with your contraception today.',
        options: ['I understand'],
      };
      significant.push({
        ...question,
        value: await ask(question),
      });
    }
  }
  if (reason.includes('regulatePeriod')) {
    const recommendedByQuestion = {
      type: 'choice',
      label: `Who recommended the contraceptive pill to regulate your periods?`,
      options: {
        myself: 'Myself - I know it will help',
        doctor: 'Doctor',
      },
    };
    const recommendedBy = await ask(recommendedByQuestion);
    if (recommendedBy === 'myself') {
      significant.push({
        ...recommendedByQuestion,
        value: recommendedBy,
        warn: !reason.includes('stopMeGettingPregnant'),
      });

      const question = {
        type: 'choice',
        label:
          'You need to see a doctor about your irregular periods, as this may be caused by something serious, but we can still help you with your contraception today.',
        options: ['I understand'],
      };
      significant.push({
        ...question,
        value: await ask(question),
      });
    }
  }

  return {};
}: Guide);

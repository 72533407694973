import type { Guide } from '../../types';

import aborts from './aborts';
import { coronaSeason } from '../common/consts';

export default (async ({ ask, patient: { gender, age }, significant }) => {
  if (gender === 'female' && age < 55) {
    await ask.info(
      'Before we begin, we need to ask a few questions to make sure it is safe to treat you online...',
      {
        description:
          'If you are not feeling well and need your medication urgently, you should arrange to see your GP face to face.',
      }
    );

    const pregnant = await ask('Are you pregnant?', {
      id: 'triage.pregnant',
    });
    if (pregnant === 'yes') {
      significant.push({
        label: 'Pregnant',
        value: pregnant,
        warn: true,
      });
      if (!coronaSeason) {
        return { abort: aborts.pregnant };
      }
    }

    const breastfeeding = await ask('Are you breastfeeding?', {
      id: 'triage.breastfeeding',
    });
    if (breastfeeding === 'yes') {
      significant.push({
        label: 'Breastfeeding',
        value: breastfeeding,
        warn: true,
      });
      if (!coronaSeason) {
        return { abort: aborts.breastfeeding };
      }
    }
  }

  return {};
}: Guide);

import * as React from 'react';
import classNames from 'classnames';

import colors from '../theme/colors';

import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

import Logo from './Logo';

export default withStyles(({ spacing }) => ({
  root: {},

  body: {
    padding: spacing(3),
    width: `calc(100% - ${spacing(6)}px)`,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  logo: {
    marginLeft: spacing(),
    height: 26,
  },

  text: {
    color: colors.grey_700,
  },
}))(({ classes, className }) => (
  <div className={classNames(classes.root, className)}>
    <div className={classes.body}>
      <Typography variant="body2" className={classes.text}>
        © {new Date().getFullYear()} Docly Healthcare AB
      </Typography>

      <Logo className={classes.logo} />
    </div>
  </div>
));

import type { GuideWithOut, RawAnswer } from '../../types';

const alcoholDescription = `
- Small glass of wine = 2 units
- Large glass of wine = 3 units
- Pint beer or cider = 2 units
- Pint of strong beer or cider = 3 units
- Shot of spirit = 1 unit
`;

export default (async args => {
  const {
    ask,
    patient: { gender },
    decisionSupport,
  } = args;

  await ask.info(
    'Finally, we need to ask you some quick questions about your health...'
  );

  const hpSignificant: RawAnswer[] = [];

  const smokingQuestion = {
    type: 'choice',
    options: {
      lessThan10: 'Yes - less than 10 times a day',
      moreThan10: 'Yes - more than 10 times a day',
      sometimes: 'Sometimes',
      previously: 'Ex-smoker',
      no: 'No',
    },
  };
  const smoking = await ask({
    ...smokingQuestion,
    id: 'healthProfile.smoking',
    label: 'Do you smoke?',
    description: 'E.g. cigarettes, roll-ups, vaping.',
  });
  if (['lessThan10', 'moreThan10', 'sometimes'].includes(smoking)) {
    hpSignificant.push({
      ...smokingQuestion,
      label: 'Smoking, history',
      value: smoking,
      warn: true,
    });
  }
  let smokingStopped;
  if (smoking === 'previously') {
    const smokingStoppedQuestion = {
      type: 'choice',
      options: {
        lessThanYear: 'Less than a year ago',
        yearOrMore: 'A year ago or more',
      },
    };
    smokingStopped = await ask({
      ...smokingStoppedQuestion,
      id: 'healthProfile.smoking.stopped',
      label: 'When did you stop smoking?',
    });
    if (smokingStopped === 'lessThanYear') {
      hpSignificant.push({
        ...smokingStoppedQuestion,
        label: 'Ex-Smoker, stop date',
        value: smokingStopped,
        warn: true,
      });
    }
  }
  const smoker =
    ['lessThan10', 'moreThan10', 'sometimes'].includes(smoking) ||
    smokingStopped === 'lessThanYear';

  if (
    (await ask.binary({
      label: 'Have you used any recreational drugs in the past year?',
      description: 'E.g. Cannabis, Cocaine',
    })) === 'yes'
  ) {
    const drugsQuestion = {
      type: 'multipleChoice',
      options: ['Cannabis', 'Cocaine', 'Ecstasy', 'Other'],
    };
    const drugs = await ask({
      ...drugsQuestion,
      id: 'healthProfile.drugs',
      label: 'Please tell us which drugs you have used.',
      description: 'More than one answer can be selected.',
    });
    if (
      drugs.includes('Cannabis') ||
      drugs.includes('Cocaine') ||
      drugs.includes('Ecstasy')
    ) {
      hpSignificant.push({
        ...drugsQuestion,
        label: 'Recreational drugs in past 12 months',
        value: drugs,
        warn: true,
      });
    }
    if (drugs.includes('Other')) {
      hpSignificant.push({
        type: 'text',
        label: 'Other drugs used',
        value: await ask.text(
          'Please tell us which other drugs you have used.',
          {
            id: 'healthProfile.drugs.other',
          }
        ),
        warn: true,
      });
    }
  }

  const alcoholQuestion = {
    type: 'choice',
    options: {
      moreThan14: 'More than 14 units a week',
      '14OrLess': '14 units or less a week',
      none: "I don't drink alcohol",
    },
  };
  const alcohol = await ask({
    ...alcoholQuestion,
    id: 'healthProfile.alcohol',
    label: 'How many alcohol units do you drink in a week?',
    description: alcoholDescription,
  });
  if (alcohol === 'moreThan14') {
    hpSignificant.push({
      ...alcoholQuestion,
      label: 'Alcohol',
      value: alcohol,
    });
    hpSignificant.push({
      type: 'text',
      label: 'Alcohol units / week',
      value: await ask.text('Please tell us how many units you drink a week.', {
        id: 'healthProfile.alcohol.amount',
        description: alcoholDescription,
      }),
      warn: true,
    });
  }

  const medications = await ask(
    'Are you using any other medications or natural remedies at the moment?',
    {
      id: 'healthProfile.medications',
      description:
        gender === 'female'
          ? 'If you are using a hormonal contraceptive or HRT, this should be included.'
          : undefined,
    }
  );
  if (medications === 'yes') {
    hpSignificant.push({
      type: 'text',
      label: 'Medications / Natural Remedies',
      value: await ask.text(
        'Please list all your medications / natural remedies below.',
        {
          id: 'healthProfile.medications.list',
          description:
            'Include the name, strength and dose, and the reason you are taking it. E.g. Levothyroxine, 100 micrograms, 1 tablet once daily for an underactive thyroid.',
        }
      ),
    });
  } else {
    hpSignificant.push({
      label: 'Medications / Natural Remedies',
      value: medications,
    });
  }

  const allergies = await ask('Do you have any allergies?', {
    description: 'E.g. Medication, food, animals, plants.',
  });
  if (allergies === 'yes') {
    const whatQuestion = {
      type: 'multipleChoice',
      options: {
        penicillin: 'Penicillin',
        otherMedications: 'Other medications',
        lactose: 'Lactose',
        gluten: 'Gluten',
        nuts: 'Nuts',
        pollenHayfever: 'Pollen / Hayfever',
        animals: 'Animals',
        houseDust: 'House dust',
        other: 'Other',
      },
    };
    const what = await ask({
      ...whatQuestion,
      id: 'healthProfile.allergies',
      label: 'What are you allergic to?',
      description: 'More than one answer can be selected.',
    });
    hpSignificant.push({
      ...whatQuestion,
      label: 'Type of allergy',
      value: what,
      warn: ['penicillin', 'otherMedications'],
    });
    if (what.includes('lactose')) {
      await ask.multipleChoice({
        description: `
Many medications contain a small amount of lactose, this is unlikely to cause you any problems unless your lactose allergy is severe.

# Do you accept responsibility for checking if any medications we prescribe contain lactose and the effects this may have?
        `,
        options: ['Yes, I accept'],
      });
    }
    if (what.includes('otherMedications')) {
      hpSignificant.push({
        type: 'text',
        label: 'Other medication allergies',
        value: await ask.text(
          'Please tell us what other medications you are allergic to.',
          {
            id: 'healthProfile.allergies.otherMedications',
          }
        ),
        warn: true,
      });
    }
    if (what.includes('other')) {
      hpSignificant.push({
        type: 'text',
        label: 'Other allergies',
        value: await ask.text('Please tell us what other allergies you have.', {
          id: 'healthProfile.allergies.other',
        }),
        warn: true,
      });
    }
  } else {
    hpSignificant.push({
      label: 'Allergies',
      value: allergies,
    });
  }

  const other = await ask(
    'Is there anything else about your general state of health that the doctor should know?'
  );
  if (other === 'yes') {
    hpSignificant.push({
      type: 'text',
      label: 'Other general health information',
      value: await ask.text(
        'What else about your general state of health would you like to tell the doctor?',
        {
          id: 'healthProfile.other',
        }
      ),
    });
  } else {
    hpSignificant.push({
      label: 'Other general health information',
      value: other,
    });
  }

  if (!!hpSignificant.find(item => item.warn === true)) {
    decisionSupport.push({
      label: 'Significant PMH',
      color: 'black',
    });
  }

  const { significant } = args;

  significant.push(...hpSignificant);

  return {
    smoker,
  };
}: GuideWithOut<{
  smoker: boolean,
}>);

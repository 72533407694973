import {
  type Guide,
  type Patient,
  type Question,
  type Result,
} from '../../guides/types';

import wrapGuide from '../../common/wrapGuide';

import { type Draft } from './types';

type State = {
  resolve?: (*) => *,
  reject?: (*) => *,
  question?: Question,
  result?: Result,
};

type Inquisitor = {
  getQuestion: () => ?Question,
  getResult: () => ?Result,

  start: Draft => Promise<Draft>,
  push: (*) => Promise<void>,
};

const inquisitors = {};

export const setInquisitor = (id: string, inquisitor: Inquisitor) =>
  (inquisitors[id] = inquisitor);
export const getInquisitor = (id: string) => inquisitors[id];

export const createInquisitor = (
  guide: Guide,
  patient: Patient
): Inquisitor => {
  const { getState, getStatePromise, replaceState } = createState();

  return {
    getQuestion: () => getState().question,
    getResult: () => getState().result,

    start: async ({ visited = [], values = {} } = {}) => {
      const { reject } = getState();
      if (reject) reject();

      let forward = true;
      let index = 0;

      const statePromise = getStatePromise();

      wrapGuide(guide)({
        ask: question =>
          new Promise((resolve, reject) => {
            if (forward && visited[index] === question.ref) {
              index++;
              resolve(values[question.ref]);
            } else {
              replaceState({ resolve, reject, question });
            }
          }),
        patient,
      }).then(result => result && replaceState({ result }));

      await statePromise;

      forward = false;

      return { visited: visited.slice(0, index), values };
    },

    push: async value => {
      const { resolve } = getState();

      if (!resolve) throw new Error('no resolve');

      const statePromise = getStatePromise();

      resolve(value);

      await statePromise;
    },
  };
};

const createState = () => {
  let _state: State = {};
  let _stateResolve;
  let _statePromise;
  return {
    getState: () => _state,
    getStatePromise: () =>
      (_statePromise =
        _statePromise || new Promise(resolve => (_stateResolve = resolve))),
    replaceState: newState => {
      _state = newState;
      if (_stateResolve) _stateResolve();
      _statePromise = _stateResolve = undefined;
    },
  };
};

import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

const ds = {
  persistentSoreThroat: {
    id: 'persistentSoreThroat',
    color: 'black',
    label: 'Persistent sore throat',
    description: `If FeverPAIN Score ≥ 4 — treat with antibiotics, otherwise refer to GP.`,
  },

  symptomsImproving: {
    id: 'symptomsImproving',
    color: 'black',
    label: 'Sore throat improving',
    description: 'Likely viral',
  },

  unilateralThroat: {
    id: 'unilateralThroat',
    color: 'black',
    label: 'Unilateral sore throat',
    description: 'Increased likelihood of bacterial',
  },

  softFoods: {
    id: 'softFoods',
    label: 'Reject - Urgent Appointment',
    description: 'Unable to swallow soft foods',
    color: 'red',
  },

  persistentFever: {
    id: 'persistentFever',
    label: 'Persistent fever despite anti-pyretic',
    description: 'Urgent GP appointment',
    color: 'red',
  },

  earAche: {
    id: 'earAche',
    label: 'Earache - Possible acute otits media',
    description: 'Urgent GP appointment',
    color: 'red',
  },

  coryzalSymptoms: {
    id: 'coryzalSymptoms',
    label: 'Coryzal symptoms',
    description: 'Likely viral',
    color: 'black',
  },

  croakyVoice: {
    id: 'croakyVoice',
    label: 'Croaky voice',
    description: 'Likely viral',
    color: 'black',
  },

  laryngitis: {
    id: 'laryngitis',
    label: 'Laryngitis',
    description: 'Likely viral',
    color: 'black',
  },
};

const aborts = {
  unableToSwallowWater: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs to be seen urgently — this is because they are unable to swallow water.**

Please call our support team on 020 3995 4945 and ask for an **urgent GP appointment**. Please quote 'unable to swallow water' when you book this appointment.

If your child needs immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E. 

Thank you for using Docly. We hope you child feels better soon.`,
  },
  quinsy: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because we think they have a serious throat infection.**  

Please **take them to A&E or call 999 immediately**.
`,
  },
  supprativeOtitisMedia: {
    description: `
Unfortunately, we cannot treat your child online. 

It sounds like your child may have an ear infection. We would like you to **see a GP** who will provide further help for your problem.

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**. Please quote **'Ear Infection'** when booking this appointment. 

If the service is closed, you can get **urgent care by calling 111**. For **emergencies call 999 or go to A&E**.  `,
  },
};

// TODO this is now only used in one place. Should be moved into the cough guide
export default (async args => {
  const { ask, scores } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const bumpFeverPainScore = () => {
    scores.increase('feverPain');
  };

  const duration = await eChoice({
    id: 'soreThroatChild.duration',
    label: 'How long has your child had a sore throat?',
    clinicLabel: 'Duration of sore throat',
    options: [
      {
        label: '0–3 days',
        value: '0-3days',
        keyFinding: 'Symptoms for 0–3 days',
        warn: true,
        onSelected: bumpFeverPainScore,
      },
      {
        label: '4–7 days',
        value: '4-7days',
        keyFinding: 'Symptoms for 4–7 days',
      },
      {
        label: 'More than 7 days',
        value: 'moreThan7days',
        keyFinding: `Persistent sore throat >7/7`,
        hide: true,
        ds: ds.persistentSoreThroat,
      },
    ],
  });

  if (duration === 'moreThan7days') {
    await eText({
      clinicLabel: 'Sore throat duration',
      label: 'How long has your child had a sore throat?',
      warn: true,
    });
  }

  await eChoice({
    id: 'soreThroatChild.symptomsImproving',
    label: `Is your child's sore throat getting better?`,
    clinicLabel: 'Are symptoms improving or worsening?',
    options: [
      {
        label: `Yes, It's getting better`,
        value: 'improving',
        keyFinding: 'Improving',
        ds: ds.symptomsImproving,
      },
      {
        label: `No, It's staying the same`,
        value: 'noImprovement',
        keyFinding: 'No improvement',
      },
      {
        label: `No, It's getting worse`,
        value: 'worsening',
        keyFinding: `Worsening`,
        warn: true,
      },
    ],
  });

  await eChoice({
    id: 'soreThroatChild.UnilateralThroatPain',
    label: `Does it hurt on just one side of your child's throat?`,
    clinicLabel: 'Unilateral throat pain?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Unilateral throat pain',
        warn: true,
        ds: ds.unilateralThroat,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Pain not unilateral',
      },
    ],
  });

  const swallowWater = await eChoice({
    id: 'soreThroatChild.swallowWater',
    label: `Is your child able to swallow water?`,
    clinicLabel: 'Able to swallow water?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'Yes, but it hurts',
        value: 'YesButPainful',
        keyFinding: 'Yes but painful',
      },
      {
        label: 'No',
        value: 'no',
        warn: true,
        abort: aborts.unableToSwallowWater,
      },
    ],
  });
  if (swallowWater.abort) {
    return swallowWater;
  }

  await eChoice({
    id: 'soreThroatChild.softFoods',
    label: `Is your child able to eat soft foods?`,
    clinicLabel: 'Able to eat soft foods?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'Yes, but it hurts',
        value: 'YesButPainful',
        keyFinding: 'Yes but painful',
      },
      {
        label: 'No',
        value: 'no',
        warn: true,
        ds: ds.softFoods,
        urgent: true,
      },
    ],
  });

  const tonsils = await eChoice({
    id: 'soreThroatChild.redTonsils',
    label: `Are your child's tonsils red and swollen?`,
    clinicLabel: 'Red and swollen tonsils?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Red and swollen tonsils',
        warn: true,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Tonsils not red or swollen',
      },
      {
        label: 'My child does not have tonsils',
        value: 'noTonsils',
        keyFinding: 'No tonsils',
      },
      {
        label: `I am unable to check my child's throat`,
        value: 'ParentUnableToCheckThroat',
        keyFinding: 'Parent unable to check throat',
      },
    ],
  });

  if (tonsils === 'yes') {
    bumpFeverPainScore();
  }

  if (tonsils === 'yes' || tonsils === 'no' || tonsils === 'noTonsils') {
    const pustularTonsils = await eChoice({
      id: 'soreThroatChild.pustularTonsils',
      label: `Has your child got white / yellow spots at the back of their throat?`,
      type: 'choice',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          warn: true,
          keyFinding: 'Pustular tonsils',
        },
        {
          label: 'No',
          value: 'no',
          keyFinding: 'No pustular tonsils',
        },
      ],
    });

    if (pustularTonsils === 'yes') {
      bumpFeverPainScore();
    }
  }

  const urgentEarAche = {
    urgent: true,
    ds: ds.earAche,
  };

  await eChoice({
    id: 'soreThroatChild.pullingonears',
    label: 'Do you think your child has earache?',
    clinicLabel: 'Earache',
    options: [
      {
        label: 'Yes, they have complained of earache',
        value: 'complained',
        keyFinding: 'Earache',
        ...urgentEarAche,
      },
      {
        label: 'Yes, they have been pulling or holding both ears',
        value: 'Pullingonbothears',
        keyFinding: 'Pulling on both ears',
        ...urgentEarAche,
      },
      {
        label: 'Yes, they have been pulling or holding one ear',
        value: 'Pullingononeear',
        keyFinding: 'Pulling on one ear',
        ...urgentEarAche,
      },
      { label: 'No', value: 'no', keyFinding: 'No Earache' },
    ],
  });

  const otorrhoea = await eChoice({
    id: 'soreThroatChild.otorrhoea',
    label: "Is there any discharge coming out of your child's ear?",
    clinicLabel: 'Otorrhoea',
    options: [
      {
        label: 'Yes, it looks waxy',
        value: 'waxy',
        keyFinding: 'Waxy ear discharge',
      },
      {
        label: 'Yes, it is yellow and smelly.',
        value: 'waxysmelly',
        keyFinding: 'Yellow or smelly ear discharge',
        hide: true,
        abort: aborts.supprativeOtitisMedia,
      },
      { label: 'No', value: 'no', hide: true, keyFinding: 'No ear discharge' },
    ],
  });

  if (otorrhoea.abort) {
    return otorrhoea;
  }

  const hasvoicechanged = await eChoice({
    id: 'soreThroatChild.hasvoicechanged?',
    label: "Has your child's voice changed?",
    clinicLabel: 'Change in voice',
    options: [
      { label: 'Yes', value: 'yes', hide: true, keyFinding: 'Change in voice' },
      { label: 'No', value: 'no', keyFinding: 'No change in voice' },
    ],
  });

  if (hasvoicechanged === 'yes') {
    const whatdoesyourvoicesoundlike = await eChoice({
      id: 'soreThroatChild.whatdoesyourvoicesoundlike?',
      label: "What does your child's voice sound like?",
      clinicLabel: 'Change in voice',
      options: [
        {
          label: 'Nasal, like they have a cold',
          value: 'Coryzalsymptoms',
          keyFinding: 'Coryzal symptoms',
          ds: ds.coryzalSymptoms,
        },
        {
          label: 'Croaky',
          value: 'Hoarsevoice',
          keyFinding: 'Hoarse voice',
          ds: ds.croakyVoice,
        },
        {
          label: 'They have lost their voice',
          value: 'Lostvoice',
          keyFinding: 'Lost voice',
          ds: ds.laryngitis,
        },
        {
          label:
            'Muffled or unclear, like they have a hot potato in their mouth',
          value: 'Muffled/hotpotatovoice',
          keyFinding: 'Muffled/hot potato voice',
          warn: true,
          abort: aborts.quinsy,
        },
      ],
    });
    if (whatdoesyourvoicesoundlike.abort) {
      return whatdoesyourvoicesoundlike;
    }
  }

  await ask.upload(
    `We'd like you to upload a photo of your child's sore throat to help the doctor with their diagnosis.`,
    {
      optional: true,
      description: `
      You can use a mobile phone camera to take the photo — you can activate the selfie-mode and turn your phone upside down.

For best results we need to clearly see your child's throat.  Try not to get your child to lift their tongue too much — saying ‘aaar’ when taking the photo is a great way of achieving this.

Rest assured, your photos will be kept confidential and will only be added to your medical record.
    `,
    }
  );

  return {};
}: Guide);

import {
  type Question,
  type Answer,
  type SimpleAnswer,
  type Patient,
  type DecisionSupport,
  type Abort,
  type Guide,
} from '../guides/types';

import wrapGuide from './wrapGuide';

const generateRandomAnswer = (question: Question): * => {
  const { type, options, optional = false, min, max, unit } = question;

  switch (type) {
    case 'binary':
      return Math.random() < 0.5 ? 'yes' : 'no';

    case 'choice': {
      if (!options) return undefined;

      const index = Math.floor(Math.random() * options.length);
      const option =
        index >= 0 && index < options.length ? options[index] : undefined;

      return option !== undefined ? option.value : undefined;
    }

    case 'info': {
      return 'yes';
    }

    case 'multipleChoice':
      if (!options) return undefined;

      const values = options ? options.filter(() => Math.random() < 0.5) : [];

      if (!values.length && !optional) {
        const index = Math.floor(Math.random() * options.length);
        if (index >= 0 && index < options.length) {
          values.push(options[index]);
        }
      }

      return values.map(option => option.value);

    case 'number':
    case 'range':
      if (unit === 'kg') {
        return 30 + Math.floor(Math.random() * 100);
      }

      if (unit === 'cm') {
        return 130 + Math.floor(Math.random() * 90);
      }

      const minValue = min !== undefined ? min.value : 0;
      const maxValue = max !== undefined ? max.value : 1000;

      return (
        (minValue || 0) + Math.floor(Math.random() * (maxValue - minValue))
      );

    case 'tertiary':
      return ['yes', 'no', 'unknown'][Math.floor(Math.random() * 3)];

    case 'text':
      return 'This is a very important test!';

    case 'upload': {
      return !optional ? ['file.jpg'] : [];
    }

    default:
      return undefined;
  }
};

export class RefMismatchError extends Error {
  answer: SimpleAnswer;
  question: Question;
  patient: Patient;

  constructor(
    message: string,
    answer: SimpleAnswer,
    question: Question,
    patient: Patient
  ) {
    super(message);
    this.name = 'RefMismatchError';
    this.answer = answer;
    this.question = question;
    this.patient = patient;
  }
}

export default async (
  guide: Guide,
  patient: Patient,
  answered?:
    | SimpleAnswer[]
    | {
        [string]: *,
      } = {},
  preset?: { [string]: * } = {},
  {
    randomize,
  }: {
    randomize?: boolean,
  } = {}
): Promise<{
  result: ?{
    answers: Answer[],
    decisionSupport: DecisionSupport[],
    significant: Answer[],
    abort?: Abort,
  },
  answers: Answer[],
  visited: string[],
}> => {
  let answerMap = {};
  if (Array.isArray(answered)) {
    answered.forEach(({ ref, value }) => {
      answerMap[ref] = value;
    });
  } else {
    answerMap = answered;
  }

  const answers: Answer[] = [];

  let index = 0;
  let result;

  try {
    result = await wrapGuide(guide)({
      ask: question =>
        new Promise((resolve, reject) => {
          const _resolve = value => {
            // This flow issue is being caused by the warn field that is a condition
            // in the question and boolean in the answer. Though wrapGuide always calls ask with warn: undefined
            // $FlowFixMe
            answers.push({ ...question, value });
            resolve(value);
          };

          const { id, ref } = question;

          if (Array.isArray(answered)) {
            const answer = answered[index++];
            if (!answer || answer.ref !== ref) {
              return reject(
                new RefMismatchError(
                  `Ref not matching between answer with ref ${answer &&
                    answer.ref} and question ${JSON.stringify(
                    question
                  )}. Patient: ${JSON.stringify(patient)}`,
                  answer,
                  question,
                  patient
                )
              );
            }
          }

          if (answerMap[ref] !== undefined) {
            return _resolve(answerMap[ref]);
          }

          if (id !== undefined && preset[id] !== undefined) {
            return _resolve(preset[id]);
          }

          if (randomize === true) {
            return _resolve(generateRandomAnswer(question));
          }

          return reject();
        }),
      patient,
    });
  } catch (e) {
    if (e) {
      throw e;
    }
  }

  return { result, answers, visited: answers.map(({ ref }) => ref) };
};

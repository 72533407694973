import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';

import {
  type Question as QuestionType,
  type Result as ResultType,
} from '../../guides/types';

import { fetchDraft, popDraft } from '../../state/drafts/actions';
import { getQuestion, getResult } from '../../state/drafts/selectors';

import { postGuide } from '../../state/guides/actions';
import { getGuideMeta } from '../../state/guides';

import Back from '../components/Back';
import Fixed from '../components/Fixed';
import Footer from '../components/Footer';
import Page from '../components/Page';

import { withLocaleByCountry } from '../locale';

import Answers from './result/Answers';
import DecisionSupport from './result/DecisionSupport';
import Header from './result/Header';
import Exports from './result/Exports';
import Typography from '@material-ui/core/Typography';
import GuideLayout from './GuideLayout';

type Props = {
  country: string,
  guideId: string,
  question?: QuestionType,
  result?: ResultType,
  postGuide: typeof postGuide,
  fetchDraft: typeof fetchDraft,
  popDraft: typeof popDraft,
  classes: { [string]: string },
};

function Result(props: Props) {
  const { country, guideId } = props;

  const result = useSelector(state => {
    return getResult(state, guideId);
  });
  const question = useSelector(state => getQuestion(state, guideId));
  const dispatch = useDispatch();

  const back = async () => {
    await dispatch(popDraft(guideId));
  };

  const redirectIf = useCallback(
    ({ guideId }: Props) => {
      const abort = result && result.abort ? result.abort : undefined;
      if (question || abort) {
        navigate(`/${country}/guide/${guideId}`);
      }
    },
    [country, question, result]
  );

  useEffect(() => {
    redirectIf(props);
  }, [props, redirectIf]);

  useEffect(() => {
    if (result) {
      dispatch(postGuide(guideId, result));
    }
    dispatch(fetchDraft(guideId));
  }, [guideId, result, dispatch]);

  if (!result) return null;

  const { icon, title } = getGuideMeta(guideId);
  const { answers, significant, decisionSupport, exports } = result;

  return (
    <GuideLayout guideId={guideId} country={country}>
      <Page>
        <Header icon={icon} title={title} />

        {!!decisionSupport.length && (
          <DecisionSupport decisionSupport={decisionSupport} />
        )}

        {!significant.length && (
          <p>
            <Typography variant="h3">
              No significant data produced by the guide. Full set of questions
              and answers:
            </Typography>
          </p>
        )}
        <Answers answers={significant.length ? significant : answers} />

        <Exports exports={exports} />
      </Page>

      <Footer />

      <Fixed vertical="top" horizontal="left" spacing>
        <Back onClick={() => back()} />
      </Fixed>
    </GuideLayout>
  );
}

export default withLocaleByCountry()(Result);

import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';

const smokingCessationDs = {
  id: 'smokingCessation',
  color: 'yellow',
  label: 'Use smoking cessation template',
  description: 'Smoking cessation advice',
};

export default (async args => {
  const eChoice = createExtendedChoice(args);

  const doYou = await eChoice({
    id: 'moduleSmoking.doYou',
    label: 'Do you smoke?',
    description: 'E.g. cigarettes, roll-ups, vaping.',
    clinicLabel: 'Smoker',
    expires: 365,
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Smoker',
        warn: true,
      },
      {
        label: 'Ex-smoker',
        value: 'exSmoker',
        keyFinding: 'Ex-smoker',
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Non-smoker',
      },
    ],
  });

  if (doYou === 'exSmoker') {
    await eChoice({
      id: 'moduleSmoking.stoppedWhen',
      label: 'When did you stop smoking?',
      clinicLabel: 'Stopped smoking',
      expires: 365,
      options: [
        {
          label: 'Less than a year ago',
          value: 'lessThan1YearAgo',
        },
        {
          label: 'More than a year ago',
          value: 'moreThan1YearAgo',
        },
      ],
    });
  }

  if (doYou === 'yes') {
    const smokingCessation = await eChoice({
      id: 'moduleSmoking.smokingCessation',
      label: 'What are your thoughts about smoking?',
      clinicLabel: 'Thoughts about smoking cessation',
      expires: 90,
      options: [
        {
          label: 'I would like to stop',
          value: 'likeTo',
          hide: true,
        },
        {
          label: 'I would like to stop but not yet',
          value: 'likeToNotYet',
          hide: true,
        },
        {
          label: "I don't want to stop",
          value: 'dontWantTo',
          hide: true,
        },
        {
          label: "I haven't thought about it",
          value: 'notThoughtAboutIt',
          hide: true,
        },
      ],
    });

    if (smokingCessation === 'likeTo') {
      await eChoice({
        id: 'moduleSmoking.stopMoreHelp',
        description: `
**Smoking cessation has many health benefits.  Wanting to stop smoking makes it more likely that you will quit.**

**Would you like help to stop smoking?**`,
        expires: 90,
        options: [
          {
            value: 'yes',
            keyFinding: 'Smoking cessation advice',
            ds: smokingCessationDs,
            hide: true,
          },
          {
            value: 'no',
            keyFinding: 'Smoking cessation advice',
            hide: true,
          },
        ],
      });
    } else if (smokingCessation === 'likeToNotYet') {
      await eChoice({
        id: 'moduleSmoking.likeToNotYetMoreHelp',
        description: `
**Smoking cessation has many health benefits. Support is available which can help you quit smoking.**

**Would you like help to stop smoking?**`,
        expires: 90,
        options: [
          {
            value: 'yes',
            keyFinding: 'Smoking cessation advice',
            ds: smokingCessationDs,
            hide: true,
          },
          {
            value: 'no',
            keyFinding: 'Smoking cessation advice',
            hide: true,
          },
        ],
      });
    } else {
      await eChoice({
        id: 'moduleSmoking.dontWantToMoreHelp',
        description: `
**Stopping smoking has many health benefits. Continuing to smoke may mean your health worsens over time.**

**Would you like help to stop smoking?**`,
        expires: 90,
        options: [
          {
            value: 'yes',
            keyFinding: 'Smoking cessation advice',
            ds: smokingCessationDs,
            hide: true,
          },
          {
            value: 'no',
            keyFinding: 'Smoking cessation advice',
            hide: true,
          },
        ],
      });
    }
  }

  return {};
}: Guide);

const thanks = 'Thank you for using Docly. We hope you feel better soon.';
const bookGP = '**Please book an appointment with a GP**';
const call999 = 'Please **go to A&E or call 999 immediately**.';

export default {
  pregnantOrBreastfeeding: {
    description: `
Unfortunately, we cannot treat you online. **While pregnant or breastfeeding, it's best if you see a GP face to face**.

Medications and treatment need to be given extra consideration during these special times — for the sake of both you and your baby.

${bookGP} so you can talk through your best options.

If your GP surgery is closed, please contact your midwife, local out of hours service, or go to A&E.

${thanks}
    `,
  },
  immuneCondition: {
    description: `
Unfortunately, we cannot treat you online.

When you have a condition or are taking medication that affects your immune system, it's best if you see a GP face to face. This is because you may need to be examined or have some blood tests.

${bookGP}.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  highRiskConditions: {
    description: `
Unfortunately, we cannot treat you online.

When you have certain conditions,  it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

${bookGP}.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E**.

${thanks}
    `,
  },
  stomachCancer: {
    description: `
Unfortunately, we cannot treat you online.

When you have had family members with stomach or oesophageal cancer,  it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  severeStomachOrChestPain: {
    description: `
Unfortunately, we cannot treat you online.

**You need emergency care — this is because you have severe abdominal or chest pain.**

${call999}

${thanks}
    `,
  },
  severeHeartburnOrReflux: {
    description: `
Unfortunately, we cannot treat you online.

When you have severe heartburn or reflux symptoms, it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  jaundice: {
    description: `
Unfortunately, we cannot treat you online.

**You need emergency care — this is because your skin or eyes have turned yellow.**

${call999}

${thanks}
    `,
  },
  vomitedBlood: {
    description: `
Unfortunately, we cannot treat you online.

**You need emergency care — this is because you have vomited blood.**

${call999}

${thanks}
    `,
  },
  vomitedBloodMoreThanAWeekAgo: {
    description: `
Unfortunately, we cannot treat you online.

When you have vomited blood, even if it was more than a week ago,  it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  notIndigestion: {
    description: `
**You don't appear to have any symptoms of indigestion** — perhaps you need another treatment area?

You can take a look at the other conditions we can help with and see if any of those matches your symptoms better.

${thanks}
    `,
  },
  lowerAbdominalPain: {
    description: `
Unfortunately, we cannot treat you online.

When you have lower abdominal pain it's usually not related to heartburn or acid reflux, so it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  abdominalPain: {
    description: `
Unfortunately, we cannot treat you online.

When you have abdominal pain, it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  flankPain: {
    description: `
Unfortunately, we cannot treat you online.

When you have pain down the sides of your stomach, it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  shoulderPain: {
    description: `
Unfortunately, we cannot treat you online.

When you have pain which moves to your shoulder tip, it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  acuteChestPainNow: {
    description: `
Unfortunately, we cannot treat you online.

**You need emergency care — this is because you have chest pain right now.**

Please **go to A&E or call 999 immediately.**

${thanks}`,
  },
  acuteChestPain: {
    description: `
Unfortunately, we cannot treat you online.

**You need emergency care — this is because you had chest pain.**

Please **go to A&E or call 999 immediately.**

${thanks}
    `,
  },
  chestPain: {
    description: `
Unfortunately, we cannot treat you online.

When you have had chest pain, it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  vomiting: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

${bookGP}.

You may be seriously unwell, if you are vomiting and:

- Feeling very unwell
- Feel dizzy or light headed
- Have a high temperature
- Are unable to keep fluids down
- Are peeing less than normal

${call999}

${thanks}
    `,
  },
  exercise: {
    description: `
**Unfortunately, we cannot treat you online.**

Symptoms that become worse when you exercise could mean your condition is severe.

Please **call our support team on [020 3995 4945](tel:02039954945) and ask for an urgent appointment**.

If you cannot reach our support team, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

${thanks}
    `,
  },
  vomitingWithDehydration: {
    description: `
Unfortunately, we cannot treat you online.

**You need emergency care — this is because you have been vomiting and seem to be suffering from dehydration.**

${call999}

${thanks}
    `,
  },
};

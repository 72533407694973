import merge from 'deepmerge';
import * as guides from '../../guides';
import * as modules from '../../guides/modules';
import { type Guide } from '../../guides/types';

import { _tWithLocale, localeByCountry } from '../../i18n';

import mapGuides from '../../common/mapGuides';

import presets from '../../guides/test/presets';

import icons from './icons';

import { type Meta } from './types';

const blacklist = ['se-mentalHealth'];

const metaById = {};
const guideById = mapGuides(
  merge(guides, modules),
  (guide, country, id, version) => {
    const countryIcons = icons[country] || {};
    const countryPresets = presets[country] || {};
    const guideId = `${country}-${id}${
      version !== undefined ? '-' + version : ''
    }`;
    const isModule = modules[country] && modules[country][id];
    metaById[guideId] = {
      guideId,
      country,
      version,
      icon: countryIcons[id] || icons[id] || icons.placeholder,
      preset: countryPresets[id] || presets[id] || {},
      title:
        _tWithLocale(
          `${isModule ? 'modules' : 'guides'}.title.${id}`,
          localeByCountry[country] || localeByCountry.uk
        ) + (version !== undefined ? ` (${version})` : ''),
      blacklisted: !!blacklist.includes(guideId),
      isModule,
      start: guide,
    };
    return guide;
  }
);

export const getGuides = (): string[] => Object.keys(guideById);
export const getGuide = (guideId: string): Guide => guideById[guideId];
export const getGuideMeta = (guideId: string): Meta => metaById[guideId];

export default {
  lessThan1Week: {
    label: 'Cough — Less than 1 week',
    description: 'Consider self care message',
    color: 'red',
  },
  moreThan2Weeks: {
    label: 'Cough — More than 2 weeks',
    description: 'Consider routine appointment',
    color: 'red',
  },
  moreThan3Weeks: {
    label: 'Cough — More than 3 weeks',
    description: 'Reject — urgent GP appt',
    color: 'red',
  },
  startedColdYes: {
    label: 'Cough started with a cold',
    description: 'Likely viral cause',
    color: 'black',
  },
  startedColdNo: {
    label: 'Cough did not start with a cold',
    description: 'Bacterial? Consider GP appt',
    color: 'black',
  },
  soundsLikeABark: {
    label: 'Barking cough',
    description: 'Consider croup',
  },
  asthma: {
    label: 'Consider asthma',
    description: 'Asthma? Routine GP appt',
  },
  reversibleAirwaysDisease: {
    label: 'Symptoms suggestive of reversible airways disease',
    description:
      'GP to decide if treat as asthma or organise face to face appointment.',
    color: 'green',
  },
  continuousCough: {
    label: 'Reject Routine GP appointment',
    description: 'Continuous cough more than 3 weeks',
    color: 'red',
  },
  ongoingCough: {
    label: 'Doctor to decide if needs appointment',
    description: 'Ongoing cough more than 3 weeks',
    color: 'green',
  },
  wetCoughUrgent: {
    label: 'Reject Urgent GP appointment',
    description: 'Wet cough for more than 1 week',
    color: 'red',
  },
  wetCough: {
    label: 'Doctor to decide if needs appointment',
    description: 'Wet cough',
    color: 'green',
  },
  croup: {
    label: 'Reject Urgent GP appointment',
    description: 'Possible croup',
    color: 'red',
  },
  whoopingCough: {
    label: 'Reject Urgent GP appointment',
    description: 'Possible whooping cough',
    color: 'red',
  },
  gord: {
    label: 'Consider diagnosis',
    description: 'Possible GORD - cough worse after eating',
    color: 'black',
  },
  hayfever: {
    label: 'Consider diagnosis',
    description: 'Possible hayfever - cough worse in summer month',
    color: 'black',
  },
  persistantFever: {
    label: 'Persistent fever despite anti-pyretic',
    description: 'Urgent GP appointment',
    color: 'red',
  },
  selfCare: {
    label: 'Consider diagnosis',
    description: 'Possible self limiting cough - use cough self-care template',
  },
  reversible: {
    label: 'Doctor to decide if needs appointment',
    description:
      'Symptoms suggestive of reversible airways disease - consider treating as asthma or organise face to face appointment.',
    color: 'green',
  },
  blockedNose: {
    label: 'Doctor to decide if needs appointment',
    description: 'Difficulty in breathing due to blocked nose',
    color: 'green',
  },
  chestInfection: {
    label: 'Reject Urgent GP appointment',
    description: 'Suspected chest infection',
    color: 'red',
  },
  haemoptysis: {
    label: 'Reject Urgent GP appointment',
    description: 'Haemoptysis',
    color: 'red',
  },
  notEating: {
    label: 'Significantly reduced appetite',
    description: 'Urgent GP appt',
    color: 'red',
  },
  loosingWeight: {
    label: 'Reject Urgent GP appointment',
    description: 'Child losing weight',
    color: 'red',
  },
  nightsweats: {
    label: 'Reject Urgent GP appointment',
    description: 'Night sweats',
    color: 'red',
  },
  clearThroat: {
    label: 'Consider diagnosis',
    description: 'Possible postnasal drip - sounds like trying to clear throat',
    color: 'black',
  },
  sameCough: {
    label: 'Doctor to decide if needs appointment',
    description: 'Ongoing cough remains the same',
    color: 'green',
  },
  previousTest: {
    label: 'Review clinical notes',
    description: 'Previous test results',
    color: 'black',
  },
  previousMedicine: {
    label: 'Review clinical notes',
    description: 'Previous medication given',
    color: 'black',
  },
  paleskinSmallChild: {
    label: 'Doctor to decide if needs appointment',
    description: 'Pale Skin Amber warning on traffic lights',
    color: 'green',
  },
  diarrhoea: {
    label: 'Use dehydration template',
    description: 'Diarrhoea',
    color: 'yellow',
  },
  dehydration: {
    label: 'Use dehydration template',
    description: 'Passed urine within 8 hours',
    color: 'yellow',
  },
  feverPain0to1: {
    label: 'FeverPAIN Score 0-1',
    description: 'Advice only - self care',
    color: 'black',
  },
  feverPain2to3: {
    label: 'FeverPAIN Score 2-3',
    description: 'Delayed prescription antibiotics',
    color: 'black',
  },
  feverPain4: {
    label: 'FeverPAIN Score >=4',
    description: 'Antibiotics / Delayed prescription',
    color: 'black',
  },
  feverMoreThan5Days: {
    label: 'Reject Urgent GP appointment',
    description: 'Fever more than 5 days',
    color: 'red',
  },
};

import * as React from 'react';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';

import withStyles from '@material-ui/core/styles/withStyles';

const closeIconSource = require('../../images/icons/ic_close.svg');

class Close extends React.Component<{
  onClick: () => *,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { onClick, classes, className } = this.props;

    return (
      <IconButton
        color="secondary"
        onClick={onClick}
        className={classNames(classes.root, className)}
      >
        <img alt="icon" src={closeIconSource} className={classes.icon} />
      </IconButton>
    );
  }
}

export default withStyles(() => ({
  root: {
    height: 56,
    width: 56,
    padding: 0,
  },

  icon: {
    height: 18,
  },
}))(Close);

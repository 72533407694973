import type { GuideWithOut } from '../../types';

import { cocpPills, popPills } from './pills';

export default (async ({ ask, significant }) => {
  const usingQuestion = {
    id: 'using.type',
    type: 'choice',
    label: 'What kind of contraceptive do you usually use?',
    options: {
      combined:
        'Combined Pill (usually taken for 21 days followed a 7 day break)',
      progesterone:
        'Progesterone Only "Mini" pill (taken everyday without a break)',
      dontKnow: `I don't know what type of pill it is`,
      skinPatch: 'Skin Patch (Evra)',
      vaginalRing: 'Vaginal Ring (NuvaRing)',
      injection: 'Injection',
      implant: 'Implant',
      coil: 'Coil (IUD)',
      hormonalCoil: 'Hormonal Coil (Mirena / IUS)',
      other: 'Other, e.g. condoms, withdrawal, cap etc',
    },
  };
  const using = await ask(usingQuestion);
  significant.push({
    ...usingQuestion,
    label: 'Current contraception',
    value: using,
  });

  let brandName;

  if (['combined', 'progesterone', 'dontKnow'].includes(using)) {
    const brandNameQuestion = {
      id: 'using.brand',
      type: 'choice',
      label: 'What is the name of your pill?',
      options: [
        ...(using === 'combined' || using === 'dontKnow' ? cocpPills : []),
        ...(using === 'progesterone' || using === 'dontKnow' ? popPills : []),
        {
          label: 'None of the above',
          value: 'none',
        },
      ],
      expires: 365,
    };
    brandName = await ask(brandNameQuestion);

    if (brandName !== 'none') {
      significant.push({
        ...brandNameQuestion,
        value: brandName,
      });
    } else {
      const question = {
        type: 'text',
        label: 'Please tell us the name of your contraceptive pill',
        expires: 365,
      };
      significant.push({
        ...question,
        value: await ask(question),
        warn: true,
      });
    }
  }

  return { using, brandName };
}: GuideWithOut<{
  using: string,
  brandName?: string,
}>);

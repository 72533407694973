import type { Guide } from '../../types';

export default (async ({ ask }) => {
  const happyQuestion = {
    type: 'binary',
    id: 'happy',
    label: 'Are you happy?',
  };
  await ask(happyQuestion);

  return {};
}: Guide);

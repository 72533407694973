import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  /* HAD - Hospital Anxiety and Depression Scale
  Please note that the order of the values in the option array
  in the questions differs between questions!

  Also some questions will contribute to the anxiety score while the others will
  contribute to the depression score.
  */

  await ask.info({
    description:
      'Läs igenom varje påstående och sätt ett kryss i den ruta som bäst beskriver hur du har känt dig den senaste veckan. Fundera inte för länge över dina svar; din spontana reaktion inför varje påstående är förmodligen mer korrekt än ett svar som du tänkt på länge.',
  });

  const tense = await ask.choice({
    id: 'had.tense',
    label: 'Jag känner mig spänd eller nervös.',
    options: [
      { value: '3', label: 'Mestadels' },
      { value: '2', label: 'Ofta' },
      { value: '1', label: 'Av och till' },
      { value: '0', label: 'Inte alls' },
    ],
  });

  const appreciative = await ask.choice({
    id: 'had.appreciative',
    label: 'Jag uppskattar fortfarande saker jag tidigare uppskattat.',
    options: [
      { value: '0', label: 'Definitivt lika mycket' },
      { value: '1', label: 'Inte lika mycket' },
      { value: '2', label: 'Endast delvis' },
      { value: '3', label: 'Nästan inte alls' },
    ],
  });

  const horrible = await ask.choice({
    id: 'had.horrible',
    label: 'Jag har en känsla av att något hemskt kommer att hända.',
    options: [
      { value: '3', label: 'Mycket klart och obehagligt' },
      { value: '2', label: 'Inte så starkt nu' },
      { value: '1', label: 'Betydligt svagare nu' },
      { value: '0', label: 'Inte alls' },
    ],
  });

  const laugh = await ask.choice({
    id: 'had.laugh',
    label: 'Jag kan skratta och se det roliga i saker och ting.',
    options: [
      { value: '0', label: 'Lika ofta som tidigare' },
      { value: '1', label: 'Inte lika ofta nu' },
      { value: '2', label: 'Betydligt mer sällan nu' },
      { value: '3', label: 'Aldrig' },
    ],
  });

  const worry = await ask.choice({
    id: 'had.worry',
    label: 'Jag bekymrar mig över saker.',
    options: [
      { value: '3', label: 'Mestadels' },
      { value: '2', label: 'Ganska ofta' },
      { value: '1', label: 'Av och till' },
      { value: '0', label: 'Någon enstaka gång' },
    ],
  });

  const goodMood = await ask.choice({
    id: 'had.goodMood',
    label: 'Jag känner mig på gott humör.',
    options: [
      { value: '3', label: 'Aldrig' },
      { value: '2', label: 'Sällan' },
      { value: '1', label: 'Ibland' },
      { value: '0', label: 'Mestadels' },
    ],
  });

  const relaxed = await ask.choice({
    id: 'had.relaxed',
    label: 'Jag kan sitta stilla och känna mig avslappnad.',
    options: [
      { value: '0', label: 'Definitivt' },
      { value: '1', label: 'Vanligtvis' },
      { value: '2', label: 'Sällan' },
      { value: '3', label: 'Aldrig' },
    ],
  });

  const slow = await ask.choice({
    id: 'had.slow',
    label: 'Allting känns trögt.',
    options: [
      { value: '3', label: 'Nästan alltid' },
      { value: '2', label: 'Ofta' },
      { value: '1', label: 'Ibland' },
      { value: '0', label: 'Aldrig' },
    ],
  });

  const butterflies = await ask.choice({
    id: 'had.butterflies',
    label: 'Jag känner mig orolig, som om jag hade ”fjärilar” i magen.',
    options: [
      { value: '0', label: 'Aldrig' },
      { value: '1', label: 'Ibland' },
      { value: '2', label: 'Ganska ofta' },
      { value: '3', label: 'Väldigt ofta' },
    ],
  });

  const appearance = await ask.choice({
    id: 'had.appearance',
    label: 'Jag har tappat intresset för hur jag ser ut.',
    options: [
      { value: '3', label: 'Fullständigt' },
      { value: '2', label: 'Till stor del' },
      { value: '1', label: 'Delvis' },
      { value: '0', label: 'Inte alls' },
    ],
  });

  const restless = await ask.choice({
    id: 'had.restless',
    label: 'Jag känner mig rastlös.',
    options: [
      { value: '3', label: 'Väldigt ofta' },
      { value: '2', label: 'Ganska ofta' },
      { value: '1', label: 'Sällan' },
      { value: '0', label: 'Inte alls' },
    ],
  });

  const lookForwardTo = await ask.choice({
    id: 'had.lookForwardTo',
    label: 'Jag ser med glädje fram emot saker och ting.',
    options: [
      { value: '0', label: 'Lika mycket som tidigare' },
      { value: '1', label: 'Mindre än tidigare' },
      { value: '2', label: 'Mycket mindre än tidigare' },
      { value: '3', label: 'Knappast alls' },
    ],
  });

  const panic = await ask.choice({
    id: 'had.panic',
    label: 'Jag får plötsliga panikkänslor.',
    options: [
      { value: '3', label: 'Väldigt ofta' },
      { value: '2', label: 'Ganska ofta' },
      { value: '1', label: 'Sällan' },
      { value: '0', label: 'Aldrig' },
    ],
  });

  const bookTvRadio = await ask.choice({
    id: 'had.bookTvRadio',
    label: 'Jag kan uppskatta en god bok, ett TV- eller radioprogram.',
    options: [
      { value: '0', label: 'Ofta' },
      { value: '1', label: 'Ibland' },
      { value: '2', label: 'Sällan' },
      { value: '3', label: 'Mycket sällan' },
    ],
  });

  // HAD scores consists of two different scores; anxiety and depression.

  // ANXIETY SCORE
  // Put all anxiety answers into an array
  const anxietyArray = [
    tense,
    horrible,
    worry,
    relaxed,
    butterflies,
    restless,
    panic,
  ];

  // Transform array values from string to integer values and then calculate the sum
  // of all values in array, stored in the variable anxietyScore
  const anxietyScore = anxietyArray
    .map(value => parseInt(value))
    .reduce((total, value) => total + value);

  // Depending on score, push different information to significant (Clinic view)
  if (anxietyScore < 8) {
    significant.push({
      type: 'text',
      label: 'HAD - Ångest score',
      value: anxietyScore + ' - talar ej för ångest',
    });
  } else if (anxietyScore >= 11) {
    significant.push({
      type: 'text',
      label: 'HAD - Ångest score',
      value: anxietyScore + ' - talar för att ångest föreligger',
    });
  } else {
    significant.push({
      type: 'text',
      label: 'HAD - Ångest score',
      value: anxietyScore + ' - ångest föreligger möjligen',
    });
  }

  // DEPRESSION SCORE
  // Put all depression answers into an array
  const depressionArray = [
    appreciative,
    laugh,
    goodMood,
    slow,
    appearance,
    lookForwardTo,
    bookTvRadio,
  ];

  // Transform array values from string to integer values and then calculate the sum
  // of all values in array, stored in the variable depressionScore
  const depressionScore = depressionArray
    .map(value => parseInt(value))
    .reduce((total, value) => total + value);

  // Depending on score, push different information to significant (Clinic view)
  if (depressionScore < 8) {
    significant.push({
      type: 'text',
      label: 'HAD - Depression score',
      value: depressionScore + ' - talar ej för depression',
    });
  } else if (depressionScore >= 11) {
    significant.push({
      type: 'text',
      label: 'HAD - Depression score',
      value: depressionScore + ' - talar för att depression föreligger',
    });
  } else {
    significant.push({
      type: 'text',
      label: 'HAD - Depression score',
      value: depressionScore + ' - depression föreligger möjligen',
    });
  }

  return {};
}: Guide);

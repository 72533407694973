import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import { _t } from '../../i18n';

import colors from '../theme/colors';

import { chooseFiles, getFileData, isVideo } from '../utils/files';

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import Next from '../components/Next';
import VideoPreview from '../components/VideoPreview';

const closeIconSource = require('../../images/icons/ic_close.svg');
const photoIconSource = require('../../images/icons/ic_photo.svg');

type Props = {
  value?: string[],
  error?: string,
  update: (?*) => *,
  done: (?*) => *,
  classes: { [string]: string },
  mimeTypes?: string,
  max?: { value: number },
  min?: { value: number },
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: `calc(100% - ${theme.spacing(10)}px)`,
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - ${theme.spacing(5)}px)`,
      marginLeft: theme.spacing(5),
    },
  },

  upload: {
    position: 'relative',
    marginBottom: theme.spacing(1.5),
  },

  uploadLast: {
    marginBottom: theme.spacing(3),
  },

  placeholder: {
    width: '100%',
    height: 200,
    display: 'flex',
    backgroundColor: colors.grey_100,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },

  placeholderIcon: {
    height: 60,
    width: 60,
    marginBottom: theme.spacing(),
  },

  image: {
    maxWidth: '100%',
    maxHeight: 200,
    borderRadius: 4,
  },

  videoWrapper: {
    maxWidth: 400,
  },

  video: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 200,
    backgroundColor: '#eee',
  },

  remove: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(),
  },

  removeIcon: {
    height: 14,
  },

  button: {
    marginBottom: theme.spacing(3),
  },

  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

function Upload(props: Props) {
  const [files, setFiles] = useState({});
  const {
    value: values = [],
    error,
    update,
    mimeTypes,
    min: { value: min } = {},
    max: { value: max } = {},
  } = props;
  const classes = useStyles(props);

  const load = useCallback(
    async (values = []) => {
      // Prevent memory leak for promises
      let isSubscribed = true;

      const updates = await Promise.all(
        values.map(path => {
          if (!isSubscribed) {
            return { path, value: undefined };
          }
          if (files[path]) {
            return { path, value: files[path] };
          }
          return getFileData(path)
            .then(data => ({ path, value: { data } }))
            .catch(error => ({ path, value: { error: error.message } }));
        })
      );
      setFiles(
        updates.reduce(
          (map, { path, value }) => ({
            ...map,
            [path]: value,
          }),
          { ...files }
        )
      );

      return () => (isSubscribed = false);
    },
    [files]
  );

  const choose = async () => {
    const { value: prevValues = [] } = props;
    const newValues = await chooseFiles(mimeTypes, true);
    update([...prevValues, ...newValues]);
  };

  const remove = value => () => {
    const newValues = values.filter(v => v !== value);
    update(newValues.length ? newValues : undefined);
  };

  const done = () => {
    const { done } = props;

    done(values === undefined ? [] : values);
  };

  useEffect(() => {
    load(values);
  }, [values, load]);

  const nextDisabled =
    error !== undefined || (min !== undefined && min > values.length);
  return (
    <div className={classes.root}>
      {values.map((value, i) => {
        const file = files[value];
        if (
          file === undefined ||
          file.error !== undefined ||
          value === undefined
        ) {
          return null;
        }

        const fileData = file.data;

        return (
          <div
            key={value}
            className={classNames(
              classes.upload,
              i === values.length - 1 && classes.uploadLast,
              !fileData && classes.placeholder
            )}
          >
            {fileData ? (
              isVideo(fileData) ? (
                <VideoPreview alt={value} src={fileData} />
              ) : (
                <img alt={value} src={fileData} className={classes.image} />
              )
            ) : (
              <>
                <img
                  alt=""
                  role="presentation"
                  src={photoIconSource}
                  className={classes.placeholderIcon}
                />
                <Typography variant="body2">{value}</Typography>
              </>
            )}

            <Fab
              size="small"
              onClick={remove(value)}
              className={classes.remove}
            >
              <img
                alt="close"
                src={closeIconSource}
                className={classes.removeIcon}
              />
            </Fab>
          </div>
        );
      })}

      {(!max || values.length < max) && (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => choose()}
          className={classes.button}
        >
          {_t('input.upload')}
        </Button>
      )}

      <div className={classes.buttons}>
        <Next
          disabled={nextDisabled}
          onClick={() => done()}
          label={
            !values.length && !nextDisabled
              ? _t('input.upload.skip')
              : undefined
          }
        />
      </div>
    </div>
  );
}

export default Upload;

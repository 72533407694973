import type { Guide } from '../../types';

import aborts from './aborts';
import commonAborts from '../common/commonAborts';
import createExtendedChoice from '../common/extendedChoice';
import moduleCoronavirus from '../modules/coronavirus';
import { coronaSeason } from '../common/consts';
import ds from './decisionSupport';
import addUrgentFlag from '../common/addUrgentFlag';
import { ensureArray } from '../common/arrayUtils';

export default (async args => {
  const {
    ask,
    patient: { gender, age },
    significant,
    decisionSupport,
    exports,
  } = args;
  const eChoice = createExtendedChoice(args);

  await ask.info({
    description:
      'Before we begin, we need to ask a few questions to make sure it is safe to treat you online...',
  });

  const coronaVirusResult = await moduleCoronavirus(args);
  if (coronaVirusResult.abort) {
    return coronaVirusResult;
  }

  if (gender === 'female' && age < 55) {
    const pregnant = await ask('Are you pregnant?', {
      id: 'triage.pregnant',
    });
    if (pregnant === 'yes') {
      significant.push({
        label: 'Pregnant',
        value: pregnant,
        warn: true,
      });
      if (coronaSeason) {
        decisionSupport.push(ds.pvConsultPregnant);
        addUrgentFlag(exports);
      } else {
        return { abort: commonAborts.adultPregnantOrBreastfeedingGp };
      }
    }

    const breastfeeding = await ask('Are you breastfeeding?', {
      id: 'triage.breastfeeding',
    });
    if (breastfeeding === 'yes') {
      significant.push({
        label: 'Breastfeeding',
        value: breastfeeding,
        warn: true,
      });
      if (!coronaSeason) {
        return { abort: commonAborts.adultPregnantOrBreastfeedingGp };
      }
    }

    const recentBaby = await ask('Have you had a baby in the last 6 weeks?', {
      id: 'triage.recentBaby',
    });
    if (recentBaby === 'yes') {
      significant.push({
        label: '≤ 6 weeks postpartum',
        value: recentBaby,
        warn: true,
      });
      if (coronaSeason) {
        decisionSupport.push(ds.pvConsultPostpartumAndCough);
        addUrgentFlag(exports);
      } else {
        return { abort: aborts.postpartum };
      }
    }
  }

  const immunosuppressed = await ask(
    'Do you have a condition, or are you taking medication, that affects your immune system?',
    {
      id: 'triage.immunosuppressed',
      description:
        "E.g. methotrexate, current cancer, chemotherapy, renal dialysis, a spleen that doesn't work, HIV, organ transplant",
    }
  );
  if (immunosuppressed === 'yes') {
    significant.push({
      label: 'Immunosuppressed',
      value: immunosuppressed,
      warn: true,
    });
    if (coronaSeason) {
      decisionSupport.push(ds.pvConsultImmunosuppressed);
      addUrgentFlag(exports);
    } else {
      return { abort: commonAborts.adultImmunosuppressedGp };
    }
  }

  const conditions = await eChoice({
    id: 'triage.currentConditions',
    label: 'Do you have any of these conditions?',
    isMultipleChoice: true,
    optional: true,
    clinicLabel: 'Significant PMH',
    options: [
      {
        value: 'severeAsthma',
        label: 'Severe asthma',
        keyFinding: 'Severe asthma',
        warn: true,
        abort: aborts.unsuitableForOnlineCare,
      },
      {
        value: 'copd',
        label: 'COPD',
        keyFinding: 'COPD',
        warn: true,
        urgent: true,
        ds: ds.pvConsultCOPD,
        abort: coronaSeason ? undefined : aborts.unsuitableForOnlineCare,
      },
      {
        value: 'diabetes',
        label: 'Diabetes',
        keyFinding: 'Diabetes',
        warn: true,
        abort: coronaSeason ? undefined : aborts.unsuitableForOnlineCare,
      },
      {
        value: 'heartDisease',
        label:
          'Heart Disease e.g. abnormal heart rhythm, heart failure, angina',
        keyFinding: 'Heart Disease',
        warn: true,
        urgent: true,
        ds: ds.pvConsultHeartDisease,
        abort: coronaSeason ? undefined : aborts.unsuitableForOnlineCare,
      },
      {
        value: 'previousVTE',
        label: 'Previous blood clot in the leg (DVT) or lungs (PE)',
        keyFinding: 'Previous VTE',
        warn: true,
        urgent: true,
        ds: ds.pvConsultPreviousVTE,
        abort: coronaSeason ? undefined : aborts.unsuitableForOnlineCare,
      },
      {
        value: 'renalLiverDisease',
        label: 'Severe kidney or liver disease',
        keyFinding: 'Renal / Liver disease',
        warn: true,
        abort: coronaSeason ? undefined : aborts.unsuitableForOnlineCare,
      },
      {
        value: 'sickleCellAnaemia',
        label: 'Sickle Cell Anaemia',
        keyFinding: 'Sickle Cell Anaemia',
        warn: true,
        abort: aborts.unsuitableForOnlineCare,
      },
    ],
  });
  if (conditions.abort) {
    return { abort: conditions.abort };
  }
  if (ensureArray(conditions).includes('previousVTE')) {
    await ask.choice({
      description: `
If you have had a previous blood clot in your legs or lungs (DVT or PE), there is a chance it could happen again. 
**If you are very short of breath or have chest pain, please call 999.**
`,
      options: ['I understand'],
    });
  }

  const majorSurgery = await ask(
    'Have you had major surgery in the last 4 weeks that has left you resting in a chair or in bed for long periods of time?',
    {
      id: 'triage.majorSurgery',
    }
  );
  if (majorSurgery === 'yes') {
    significant.push({
      label: '≤ 4 weeks post major surgery',
      value: majorSurgery,
      warn: true,
    });
    if (coronaSeason) {
      decisionSupport.push(ds.pvConsultRecentMajorSurgery);
      addUrgentFlag(exports);

      await ask.choice({
        description: `
If you have had recent major surgery there is a chance you could have a serious illness like pneumonia or a blood clot in your lungs (PE).
**If you are very short of breath or have chest pain, please call 999.**
`,
        options: ['I understand'],
      });
    } else {
      return { abort: aborts.majorSurgery };
    }
  }

  return {};
}: Guide);

export const getContraceptiveBrandLabel = (
  want?: string,
  brandName?: string
) => {
  if (brandName === 'none') {
    if (want === 'combined') {
      return 'COCP';
    } else if (want === 'progesterone') {
      return 'POP';
    }
    return 'Contraceptive pill';
  }
  return brandName || '';
};

import type { Guide } from '../../types';

import informedConsent from '../questions/informedConsent';

export default (async ({
  ask,
  patient: { age },
  decisionSupport,
  significant,
}) => {
  const happy = await ask('Are you happy?');

  significant.push({
    label: 'Happy',
    value: happy,
    warn: 'no',
  });

  if (happy === 'no') {
    if (age >= 40) {
      const porsche = await ask(
        'Have you had a sudden unexplainable urge to buy a porsche?'
      );

      if (porsche === 'yes') {
        significant.push({
          label: 'Wants porsche',
          value: 'yes',
        });
        decisionSupport.push({
          label: 'Give patient a bullshit pep talk',
          description: 'Mid-life crisis',
        });
      }
    }

    const countries = {
      china: 'China',
      india: 'India',
      russia: 'Russia',
    };

    const traveledRecently = await ask.multipleChoice(
      'What countries have you recently traveled to?',
      {
        options: countries,
        optional: true,
      }
    );

    if (traveledRecently.length > 0) {
      significant.push({
        type: 'multipleChoice',
        label: 'Traveled recently',
        options: countries,
        value: traveledRecently,
      });
    }

    if (traveledRecently.includes('russia')) {
      decisionSupport.push({
        label: 'Prescribe anti-depressants',
        description: 'Might be depressed, been in Russia',
      });
    }

    for (let country of traveledRecently) {
      await ask.text(`So, what did you think of ${countries[country]}?`);
    }
  }

  await ask(informedConsent);

  return {};
}: Guide);

export default {
  optOut: {
    description: `
Please let your employer know you have decided not to take the test.
  
Thank you for using Docly.
    `,
  },
  bloodTestNotAvailable: {
    description: `
Blood tests (venous) is not yet available through this service. Please speak to your employer.

Thank you for using Docly.
    `,
  },
};

const aborts = {
  polypharmacy: {
    description: `Unfortunately, we are unable to issue a repeat prescription for your child if they are requesting more than five medications, and they have not had a recent medication review. 

Our doctors would like to see your child instead for a face to face appointment.
    
Please call our support team on 020 3995 4945 and ask for a routine appointment.
    
Thank you for using Docly.`,
  },
};

// $FlowFixMe
Object.keys(aborts).forEach(id => (aborts[id].id = id));

export default aborts;

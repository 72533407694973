import { type Question, type Result } from '../../guides/types';

import { type State } from './types';
import { getInquisitor } from './inquisitor';

export const getQuestion = (
  state: { drafts: State },
  id: string
): ?Question => {
  const inquisitor = getInquisitor(id);

  return inquisitor && inquisitor.getQuestion();
};

export const getResult = (state: { drafts: State }, id: string): ?Result => {
  const inquisitor = getInquisitor(id);

  return inquisitor && inquisitor.getResult();
};

export const getValue = (state: { drafts: State }, id: string) => {
  const { values = {} } = getDraft(state, id) || {};
  const question = getQuestion(state, id);

  return question ? values[question.ref] : undefined;
};

export const isFirst = (state: { drafts: State }, id: string) => {
  const { visited = [] } = getDraft(state, id) || {};

  return !visited.length;
};

export const getDraft = ({ drafts }: { drafts: State }, id: string) =>
  drafts.byId && drafts.byId[id];

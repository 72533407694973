export default {
  unrelated: {
    description: `
**Please open a new Docly case**

As your child has a new problem, it will be best for you to open a new case for them.

If your child is unwell and you need immediate help, please call 111 for urgent medical care. For emergencies call 999 or go to A&E.
`,
  },
  fever: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs to be seen urgently — this is because they have a fever**

Please call our support team on 020 3995 4945 and ask for an **urgent GP appointment**. Please quote "fever" when booking this appointment.

If your child needs immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E. 

Thank you for using Docly. We hope you child feels better soon.
`,
  },
  sepsis: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they may have a severe infection.**

Please **take your child to A&E or call 999 immediately**. 

Thank you for using Docly. We hope your child feels better soon.
`,
  },
  anaphylaxis: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they may have a severe allergic reaction.**

Please **take your child to A&E or call 999 immediately**. 

Thank you for using Docly. We hope your child feels better soon.
`,
  },
  zenDesk: {
    description: `
To give feedback please follow this link https://www.docly.uk/feedback/ 

Thank you for using Docly.
`,
  },
};

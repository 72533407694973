import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

import abort from '../common/commonAborts';
import ds from './decisionSupport';
import type { Guide } from '../../types';
import { coronaSeason } from '../common/consts';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const painNow = await eChoice({
    id: 'otherProblems.painNow',
    label: `Are you in pain now?`,
    clinicLabel: 'Currently in pain',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        warn: true,
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  });

  const painLocation = await eChoice({
    id: 'otherProblems.painLocation',
    label: `Where is the pain?`,
    clinicLabel: 'Location of pain',
    isMultipleChoice: true,
    options: [
      {
        label: 'Chest',
        value: 'chest',
        keyFinding: 'Chest pain',
        warn: true,
        urgent: true,
        ds: ds.pvConsultChestPain,
      },
      {
        label: 'Back',
        value: 'back',
        keyFinding: 'Back pain',
        warn: true,
      },
      {
        label: 'Stomach',
        value: 'stomach',
        keyFinding: 'Abdominal pain',
        warn: true,
      },
      {
        label: 'Head',
        value: 'head',
        keyFinding: 'Headache',
        warn: true,
      },
      {
        label: 'Arm',
        value: 'arm',
        keyFinding: 'Arm pain',
        warn: true,
      },
      {
        label: 'Leg',
        value: 'leg',
        keyFinding: 'Leg pain',
        warn: true,
      },
      {
        label: 'Other',
        value: 'other',
        hide: true,
      },
    ],
  });
  if (
    !coronaSeason &&
    painNow === 'no' &&
    Array.isArray(painLocation) &&
    painLocation.includes('chest')
  ) {
    return { abort: abort.adultChestPainUrgent };
  }

  if (Array.isArray(painLocation) && painLocation.includes('other')) {
    await eText({
      id: 'otherProblems.painLocationOther',
      label: `Where exactly is the pain?`,
      clinicLabel: 'Location of pain',
      warn: true,
    });
  }

  const painRadiating = await eChoice({
    id: 'otherProblems.painRadiating',
    label: `Does the pain move anywhere?`,
    clinicLabel: 'Radiating pain',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        hide: true,
        keyFinding: 'Radiating pain',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  });

  if (painRadiating === 'yes') {
    await eText({
      id: 'otherProblems.painRadiatingTo',
      label: `Where does the pain move to?`,
      clinicLabel: 'Pain radiates to',
    });
  }

  if (painNow === 'yes') {
    const currentLevelOfPain = await eChoice({
      id: 'otherProblems.currentLevelOfPain',
      label: `How bad is the pain now?`,
      clinicLabel: 'How bad is the pain currently',
      options: [
        {
          label: 'Very mild – able to carry out normal activities',
          value: 'veryMild',
          keyFinding: 'Very mild pain',
        },
        {
          label: 'Mild – some difficulty with normal activities',
          value: 'mild',
          keyFinding: 'Mild pain',
        },
        {
          label: 'Moderate – distracting and difficult to do normal activities',
          value: 'moderate',
          warn: true,
          keyFinding: 'Moderate pain',
        },
        {
          label: 'Severe – cannot carry out normal activities',
          value: 'severe',
          warn: true,
          urgent: true,
          keyFinding: 'Severe pain',
          ds: ds.pvConsultSeverePain,
        },
        {
          label: 'Extreme – worst pain imaginable and unable to function',
          value: 'extreme',
          warn: true,
          urgent: true,
          keyFinding: 'Extreme pain',
        },
      ],
    });

    if (
      !coronaSeason &&
      ['veryMild', 'mild'].includes(currentLevelOfPain) &&
      Array.isArray(painLocation) &&
      painLocation.includes('chest')
    ) {
      return { abort: abort.adultChestPainUrgent };
    }

    if (
      !coronaSeason &&
      currentLevelOfPain === 'moderate' &&
      Array.isArray(painLocation) &&
      painLocation.includes('chest')
    ) {
      return { abort: abort.adultChestPainAe };
    }

    if (
      currentLevelOfPain === 'severe' &&
      Array.isArray(painLocation) &&
      (painLocation.includes('chest') || painLocation.includes('stomach'))
    ) {
      return { abort: abort.adultSeverePainAe };
    }

    if (currentLevelOfPain === 'extreme') {
      return { abort: abort.adultSeverePainAe };
    }
  }

  await eChoice({
    id: 'otherProblems.painAtWorst',
    label: `How severe is the pain at its worst?`,
    clinicLabel: 'How bad is the pain at its worst',
    options: [
      {
        label: 'Very mild – able to carry out normal activities',
        value: 'veryMild',
      },
      {
        label: 'Mild – some difficulty with normal activities',
        value: 'mild',
      },
      {
        label: 'Moderate – distracting and difficult to do normal activities',
        value: 'moderate',
        warn: true,
      },
      {
        label: 'Severe – cannot carry out normal activities',
        value: 'severe',
        warn: true,
      },
      {
        label: 'Extreme – worst pain imaginable and unable to function',
        value: 'extreme',
        warn: true,
      },
    ],
  });

  const painContinuous = await eChoice({
    id: 'otherProblems.painContinuous',
    label: `Is the pain continuous?`,
    clinicLabel: 'Continuous pain',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Continuous pain',
      },
      {
        label: 'No, it comes and goes',
        value: 'no',
        keyFinding: 'Intermittent pain',
      },
    ],
  });

  if (painContinuous === 'no') {
    await eText({
      id: 'otherProblems.painDuration',
      label: `How long does the pain last for?`,
      clinicLabel: 'Duration of pain',
    });
  }

  await eText({
    id: 'otherProblems.painAnalgesiaTried',
    label: `What have you tried for the pain?`,
    clinicLabel: 'Analgesia tried',
  });

  const exacerbatingFactors = await eChoice({
    id: 'otherProblems.painExacerbatingFactors',
    label: `Does anything make it worse?`,
    clinicLabel: 'Exacerbating factors',
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
      },
    ],
  });

  if (exacerbatingFactors === 'yes') {
    await eText({
      id: 'otherProblems.painExacerbatingFactorsDescription',
      label: `What makes your pain worse?`,
      clinicLabel: 'Exacerbating factors',
    });
  }

  return {};
}: Guide);

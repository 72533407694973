import * as React from 'react';
import classNames from 'classnames';

import colors from '../theme/colors';

import { isPhone } from '../utils/device';

import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

class Key extends React.Component<{
  character: string,
  onPress: () => *,
  classes: { [string]: string },
  className?: string,
}> {
  keypress = event => {
    const { character, onPress } = this.props;

    if (
      event.key === character.toLowerCase() &&
      !['input', 'textarea'].includes(event.target.type)
    ) {
      onPress();
    }
  };

  componentDidMount() {
    window.document.addEventListener('keypress', this.keypress);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keypress', this.keypress);
  }

  render() {
    const { character, classes, className } = this.props;

    if (isPhone) return null;

    return (
      <Typography
        classes={classes}
        className={classNames(classes.root, className)}
      >
        {character.toUpperCase()}
      </Typography>
    );
  }
}

export default withStyles(() => ({
  root: {
    width: 20,
    backgroundColor: colors.grey_900,
    color: colors.white,
    textAlign: 'center',
    borderRadius: 4,
    fontWeight: 500,
  },
}))(Key);

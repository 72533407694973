import { _t } from '../../i18n';

import { type Question, type Answer } from '../../guides/types';

export const getDisplayError = (question: Question, value: *) => {
  const { optional = false, min, max } = question;

  if (
    !optional &&
    (value === undefined ||
      (typeof value === 'string' && value.trim() === '') ||
      (Array.isArray(value) && !value.length))
  ) {
    return _t('errors.required');
  }

  if (typeof value === 'number') {
    if (min !== undefined && value < min.value) {
      return _t('errors.min');
    }

    if (max !== undefined && value > max.value) {
      return _t('errors.max');
    }
  }

  return undefined;
};

export const getDisplayValue = (answer: Answer): ?(string | string[]) => {
  const { type, options, max, unit, value } = answer;

  if (type === 'info') {
    return undefined;
  }

  if (
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && !value.length)
  ) {
    if (type === 'upload') {
      return _t('input.noPhoto');
    }
    return _t('input.unanswered');
  }

  switch (type) {
    case 'binary':
      return {
        yes: _t('input.yes'),
        no: _t('input.no'),
      }[value];

    case 'choice': {
      const option = options && options.find(option => option.value === value);
      return option ? option.label : value;
    }

    case 'multipleChoice':
      return value.map(v => {
        const option = options && options.find(option => option.value === v);
        return option ? option.label : v;
      });

    case 'number':
      return `${value} ${unit || ''}`;

    case 'range':
      return `${Math.round(value * 10) / 10}/${
        max !== undefined ? max.value : 10
      }`;

    case 'tertiary':
      return {
        yes: _t('input.yes'),
        no: _t('input.no'),
        unknown: _t('input.unknown'),
      }[value];

    default:
      return String(value);
  }
};

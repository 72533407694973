import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';

import { _t } from '../../i18n';

import colors from '../theme/colors';
import { type ThemeWidth } from '../theme/types';

import { fetchDraft, popDraft } from '../../state/drafts/actions';
import { getResult } from '../../state/drafts/selectors';

import BackgroundColor from '../components/BackgroundColor';
import Body from '../components/Body';
import Frame from '../components/Frame';
import Header from '../components/Header';
import Logo from '../components/Logo';
import Markdown from '../components/Markdown';
import PreviousLink from '../components/PreviousLink';
import ScrollIndicator from '../components/ScrollIndicator';

import {
  type Question as QuestionType,
  type Result as ResultType,
} from '../../guides/types';
import { withLocaleByCountry } from '../locale';
import GuideLayout from './GuideLayout';

type Props = {
  country: string,
  guideId: string,
  question?: QuestionType,
  result?: ResultType,
  fetchDraft: typeof fetchDraft,
  popDraft: typeof popDraft,
  width: ThemeWidth,
};

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: theme.spacing(3),
  },

  section: {
    marginBottom: theme.spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

function Abort(props: Props) {
  const { country, guideId, width } = props;

  const classes = useStyles(props);

  const result = useSelector(state => {
    return getResult(state, guideId);
  });
  const dispatch = useDispatch();

  const back = async () => {
    await dispatch(popDraft(guideId));
    navigate(`/${country}/guide/${guideId}`, { replace: true });
  };

  const redirectIf = useCallback(
    ({ guideId }) => {
      if (result) {
        const { abort } = result;
        if (!abort) {
          navigate(`/${country}/guide/${guideId}`, { replace: true });
        }
      } else {
        navigate(`/${country}/guide/${guideId}`, { replace: true });
      }
    },
    [country, result]
  );

  useEffect(() => {
    redirectIf(props);
  }, [props, redirectIf]);

  useEffect(() => {
    dispatch(fetchDraft(guideId));
  }, [guideId, result, dispatch]);

  return result ? (
    <GuideLayout guideId={guideId} country={country}>
      <Body>
        <Header>
          <PreviousLink label={_t('guides.back')} onClick={() => back()} />
        </Header>

        <Frame>
          <Logo className={classes.logo} />

          {result.abort && result.abort.label !== undefined && (
            <Typography variant="h2" className={classes.section}>
              {result.abort.label}
            </Typography>
          )}

          {result.abort && result.abort.description !== undefined && (
            <Markdown className={classes.section}>
              {result.abort.description}
            </Markdown>
          )}

          <ScrollIndicator />
        </Frame>
      </Body>

      {isWidthUp('sm', width) && <BackgroundColor color={colors.grey_100} />}
    </GuideLayout>
  ) : null;
}

export default withWidth()(withLocaleByCountry()(Abort));

export default {
  pregnantOrBreastfeedingChild: {
    description: `
Unfortunately, we cannot treat your child online. **While pregnant or breastfeeding, it's best if you see a GP face to face**.

Medications and treatment need to be given extra consideration during these special times — for the sake of both you and your baby.

**Please book an appointment with a GP** so you can talk through your best options.

If your GP surgery is closed, please contact your midwife, local out of hours service, or go to A&E.

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  AsthmaChild: {
    description: `
Unfortunately, we cannot treat your child online. 

When a child has asthma, it's best if they see a GP face to face.  This is because they may need to be examined. 

Please **call our customer support team on 020 3995 4945 and ask for an appointment.**

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care.
**For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope your child feels better soon.`,
  },

  CurrentInhalerUseChild: {
    description: `As your child needs to use an inhaler, it is really important that they are seen by a doctor who can examine them. Please call your surgery to book an appointment with your GP. If it is outside of weekday working hours (08:30 - 18:30) then please call 111 or attend your local urgent care centre or visit A&E.`,
  },

  PreviousInhalerUseChild: {
    description: `As your child has previously needed to use an inhaler, it is really important that they are seen by a doctor who can examine them. Please call your surgery to book an appointment with your GP. If it is outside of weekday working hours (08:30 - 18:30) then please call 111 or attend your local urgent care centre or visit A&E.`,
  },

  ImmunosuppressedChild: {
    description: `
Unfortunately, we cannot treat you online.

When you have a condition or are taking medication that affects your immune system, it's best if you see a GP face to face. This is because you may need to be examined or have some blood tests.

**Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },

  PMHChild: {
    description: `
Unfortunately, we cannot treat your child online. 

Your child has a medical conditions which means that it's best to see a GP face to face.  

Please **call our customer support team on 020 3995 4945 and ask for an appointment.**

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care.
**For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope your child feels better soon.`,
  },

  AcuteChestPain: {
    description: `
Unfortunately, we cannot treat you online. 

**You need emergency care — this is because you have severe chest pain.**

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon.
    `,
  },

  SevereDifficultyInBreathing: {
    description: `
Unfortunately, we cannot treat you online. 

** You need emergency care — this is because you have severe difficuly in breathing.**

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  HomeOxygenTherapy: {
    description: `
Unfortunately, we cannot treat you online. 

When you have home oxygen therapy, it's best if you see a GP face to face.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP**.

If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. ** For emergencies call 999 or go to A& E.**

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  AcuteExacerbationOfCOPD: {
    description: `
Unfortunately, we cannot treat you online. 

** You need emergency care — this is because you may have a serious exacerbation of COPD.**

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon."
    `,
  },

  Orthopnea: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP.**

You may be seriously unwell, if you are feeling out of breath when when laying flat and:

- Have chest pain
- Have a fast or fluttering heart beat
- Are dizzy or lightheaded

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon."
    `,
  },

  PND: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP.**

You may be seriously unwell, if you are wake up gasping for air and:

- Have chest pain
- Have a fast or fluttering heart beat
- Are dizzy or lightheaded

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  ChestPain: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP.**

You may be seriously unwell, if you have chest pain and:

- Difficulty in breathing
- Are vomiting
- Have coughed up blood
- Are dizzy or lightheaded

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  Vomiting: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP.**

You may be seriously unwell, if you are vomiting and:

- Feeling very unwell
- Feel dizzy or lightheaded
- Have a high temperature
- Are unable to keep fluids down
- Are peeing less than normal

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  UnilateralLowerLimbOedema: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP.**

You may be seriously unwell, if you have one sided leg swelling and:

- Difficulty in breathing
- Chest pain
- Have coughed up blood
- Are dizzy or lightheaded

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  PeripheralOedema: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP.**

You may be seriously unwell, if you have leg swelling and:

- Difficulty in breathing
- Have a fast or fluttering heart beat
- Are dizzy or lightheaded

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  AbnormalHeartRateOrRhythm: {
    description: `
Unfortunately, we cannot treat you online.

This is because you may need to be examined or have some tests.

** Please book an appointment with a GP.**

You may be seriously unwell, if you have palpitations and:

- Difficulty in breathing
- Chest pain
- Are dizzy or lightheaded

Please ** go to A & E or call 999 immediately **.

Thank you for using Docly.We hope you feel better soon.
    `,
  },

  UrgentGPAppointment: {
    description: `TBC`,
  },

  SameDayGPAppointment: {
    description: `TBC`,
  },

  Sepsis: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they may have a severe infection.**

Please **take your child to A&E or call 999 immediately**. 

Thank you for using Docly. We hope your child feels better soon.    `,
  },

  SevereDifficultyBreathing: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs to be seen urgently — this is because they have severe difficulty breathing.**

Please call our support team on 020 3995 4945 and ask for an **urgent GP appointment**. Please quote 'difficulty in breathing' when you book this appointment.

If your child needs immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E. 

Thank you for using Docly. We hope you child feels better soon.    
`,
  },

  AnaphylaxisChild: {
    description: `Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they may have a severe allergic reaction.**

Please **take your child to A&E or call 999 immediately**. 

Thank you for using Docly. We hope your child feels better soon.
`,
  },
  DehydrationChild: {
    description: `Unfortunately, we cannot treat your child online. 

  **Your child needs emergency care — this is because they may have dehydration.**

  Please **take your child to A&E or call 999 immediately**. 
  
  Thank you for using Docly. We hope your child feels better soon.
`,
  },
  FOB: {
    description: `Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they may have swallowed something.**

Please **take your child to A&E or call 999 immediately**. 

Thank you for using Docly. We hope your child feels better soon.
`,
  },
  SmallChildFever: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs to be seen urgently — this is because they have a had a fever for more than 5 days.**

Please call our support team on 020 3995 4945 and ask for an **urgent GP appointment**. Please quote 'fever more than 5 days' when you book this appointment.

If your child needs immediate help, call 111 for urgent medical care. For emergencies call 999 or go to A&E. 

Thank you for using Docly. We hope you child feels better soon.`,
  },
};

export const leicesterDomains = {
  'uk-clarendon': {
    pharmacies: [
      'Spiers Pharmacy, 272 Welford Road, LE2 6BD',
      'Healthcare Pharmacy, 340 Welford Road, LE2 6EH',
      'Well Pharmacy Clarendon Park, 78 Queens Road, LE2 1TU',
      'Yakub Chemist, 67 Hartington Road, LE2 0GQ',
      'Howitts Pharmacy, 465-467 Saffron Lane, LE2 6UG',
    ],
  },
  'uk-dishley-grange': {
    pharmacies: [
      'Well Loughborough, 30 Leicester Rd, LE11 2AG',
      'Well Gorse Covert, 32a Maxwell Dr, LE11 4RZ',
      'Church Pharmacy, 7 Prince William Rd, LE11 5GU',
      'Rosebery Pharmacy, Rosebery St, LE11 5DX',
      'Loughborough Pharmacy, 24 Hermitage Rd, LE11 4PE',
      'The Medicine Box, Ashby Rd, LE11 3TT',
      'HMS Pharmacy, 31 High St, LE11 2PZ',
      'LloydsPharmacy In Sainsburys, Greenclose Ln, LE11 5AS',
      'Church Pharmacy, Unit 1 Old Bleach Yard, LE11 3HA',
      'Tesco Pharmacy, 1 The Rushes, LE11 5BE',
      'LloydsPharmacy, 19B Bridge St, LE11 1NQ',
      'LloydsPharmacy, 26 Pinfold Gate, LE11 1BE',
      'Superdrug Pharmacy, 2 Market Pl, LE11 3EP',
      'Outwoods Pharmacy, 18 Derwent Dr, LE11 3RJ',
      'Saraj, 206 Park Rd, LE11 2HJ',
    ],
  },
  'uk-dishley-grange-cross-street': {
    pharmacies: ['Dishley Grange Medical Practice, dispensary'],
  },
  'uk-heatherbrook': {
    pharmacies: [
      'Astill Lodge Pharmacy, 242 Astill Lodge Rd, LE4 1EF',
      'Boots Beaumont Shopping Centre, 20-22 Fletcher Mall, LE4 1DG',
      'Brennans Pharmacy, 65 Fosse Rd S, LE3 0LP',
    ],
  },
  'uk-springfield': {
    pharmacies: [
      'Well Pharmacy, 78 Queens Road, LE2 1TU',
      'Stoneycroft Pharmacy, 41 Francis Street, LE2 2BE',
      'Knights Pharmacy, 75 Queens Road, LE2 1TT',
      'Icare Pharmacy, 108 Bridge Road, LE5 3QN',
      'Boots, 35 The Parade, Oadby, LE2 5BB',
      'Boots, Thurmaston Shopping Centre, Barkby Thorpe Lane, LE4 8GP',
    ],
  },
  'uk-willowbrook': {
    pharmacies: [
      'Well Pharmacy (inside Willowbrook)',
      'Well Pharmacy, 78 Queens Road, LE2 1TU',
      'Unipharm, 83 Netherhall Road, LE5 1DR',
      'Boots, 149 Uppingham Road, LE5 4BP',
      'Boots, 212 Uppingham Road, LE5 0QG',
    ],
  },
  'uk-willows': {
    pharmacies: [
      'Rowlands Pharmacy, 174 Ethel Road, LE5 4WE',
      'Boots, 212 Uppingham Road, LE5 0QG',
      'Lads Chemist, 158 East Park Road, LE5 4QB',
      'Icare Pharmacy, 108 Bridge Road, LE5 3QN',
      'Daynight Pharmacy, 77-83 Chesterfield Road, LE5 5LR',
    ],
  },
  'uk-pasleyroad': {
    pharmacies: [
      'Well Pharmacy, Sturdee Road, Eyres Monsell LE2 9DB',
      'Monsell Pharmacy, Sturdee Road, Leicester LE2 9BA',
      'Well South Wigston - Blaby Road, 64 Blaby Road, Wigston LE18 4SD',
      'Well Wigston - Two steeples Medical Centre, Abington Cl, Wigston LE18 2EW',
      'Boots Pharmacy - Bell Street, 27 Bell Street, Wigston LE18 1AD',
    ],
  },
};

export const londonDomains = {
  'uk-theprojectsurgery': {
    pharmacies: [
      'Rohpharm Pharmacy, Opus Studios, 1, 212 Plaistow Rd, Plaistow, London E13 0AL',
      'Pharmaram Pharmacy, 8-10 Terrace Rd, Plaistow, London E13 0PB',
      'Medina Pharmacy, 161 Plaistow Rd, London E15 3ET',
      'Daystar Pharmacy, 121 High St, Plaistow, London E13 9HH',
    ],
  },
  'uk-ashar': {
    pharmacies: [
      'Vicarage Pharmacy, 10 Vicarage Lane, Stratford, Newham, London, E15 4ES',
      'Mayors Chemist, 127 The Grove, STRATFORD, LONDON, E15 1EN',
      'Britannia Pharmacy, 6 Church Street, West Ham, Newham, London, E15 3HX',
      'Cartwrights Pharmacy, 67 Leytonstone Road, Stratford, Newham, London, E15 1JA',
      'Osbon Pharmacy, 54 The Mall, Stratford Shopping Centre, Stratford, London, E15 1XE',
      'Boots, 31-32 The Mall, The Stratford Ctr, London, Middlesex, E15 1XD',
    ],
  },
  'uk-exmoor': {
    pharmacies: [
      'Borno Pharmacy The Gatehouse, St Charles Ctr, Exmoor St, London, Greater London, W10 6DZ',
      'LloydsPharmacy In Sainsburys, 2 Canal Way, Ladbroke Grove, London, W10 5AA',
      'Golborne Pharmacy, 106 Golborne Road, London, W10 5PS',
      'Dr Care Pharmacy, 73 Golborne Road, North Kensington, London, W10 5NP',
      'My Pharmacy, My Pharmacy Ltd T/A Shellys, 10 North Pole Road, London, W10 6QL',
      'Dillons Pharmacy, 24 Golborne Road, London, W10 5PF',
      'Chana Chemist, 114 Ladbroke Grove, London, W10 5NE',
    ],
  },
};

export const allDomains = {
  ...leicesterDomains,
  ...londonDomains,
};

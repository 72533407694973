const withSignOff = text => `
${text}

Please book an appointment to discuss this with your GP in the normal way.

Thank you for using Docly, we are sorry we couldn't help you this time.
`;

const aborts = {
  pregnant: {
    description: withSignOff(
      'Unfortunately, we cannot prescribe medications online if you are pregnant.'
    ),
  },
  breastfeeding: {
    description: withSignOff(
      'Unfortunately, we cannot prescribe medications online if you are breastfeeding.'
    ),
  },
  polypharmacy: {
    description: withSignOff(
      'Unfortunately, we are unable to issue a repeat prescription for you if you are requesting more than five medications and you have not had a recent medication review. This is because of potential interactions with your medications.'
    ),
  },
};

// $FlowFixMe
Object.keys(aborts).forEach(id => (aborts[id].id = id));

export default aborts;

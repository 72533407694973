import type { Guide } from '../../types';

export default (async ({
  ask,
  patient: { gender, age },
  decisionSupport,
  significant,
}) => {
  if (gender === 'female' && age === 30) {
    await ask.info(`Hello ${age} year old ${gender}!`);

    const happyQuestion = {
      type: 'binary',
      id: 'happy',
      label: 'Are you happy?',
    };
    const happy = await ask(happyQuestion);

    if (happy === 'yes') {
      decisionSupport.push({ id: 'happy', label: 'Happy', color: 'green' });
    } else {
      decisionSupport.push({
        id: 'unhappy',
        label: 'Unhappy',
        color: 'yellow',
      });
      significant.push({
        ...happyQuestion,
        value: happy,
      });

      const favoriteFood = await ask.choice('What is your favorite food?', {
        options: ['Ice cream', 'Sushi', 'Octopus'],
      });

      const amount = await ask.range(`How much do you like ${favoriteFood}?`, {
        min: {
          value: 0,
          label: 'Not so much',
        },
        max: {
          value: 1000,
          label: 'Very much',
        },
      });

      if (amount !== undefined && amount < 500) {
        const recent = await ask.tertiary(
          `Have you had ${favoriteFood} recently?`
        );

        if (recent === 'no') {
          await ask.text(`When exactly did you have ${favoriteFood}?`);

          const countriesQuestion = {
            type: 'multipleChoice',
            options: ['China', { value: 'india', label: 'India' }, 'Russia'],
          };
          const countries = await ask({
            ...countriesQuestion,
            label: 'Where have you been recently?',
            description: 'You can select multiple options.',
          });

          significant.push({
            ...countriesQuestion,
            label: 'Recent countries',
            value: countries,
            warn: countries.includes('Russia'),
          });

          if (countries.includes('india') && countries.length > 1) {
            await ask.upload('How about some pictures?', { optional: true });
          }

          if (countries.includes('Russia')) {
            decisionSupport.push({
              label: 'Been to Russia',
              description: 'Might be depressed',
              color: 'red',
            });
          }
        }
      }
    }
  }

  return {};
}: Guide);

export const patient = {
  gender: 'female',
  age: 30,
};

export const expected = {
  answers: [
    {
      type: 'info',
      label: 'Hello 30 year old female!',
      ref: '139a84bce',
      value: 'yes',
    },
    {
      id: 'happy',
      type: 'binary',
      label: 'Are you happy?',
      ref: '16f2f849c',
      value: 'no',
    },
    {
      type: 'choice',
      label: 'What is your favorite food?',
      options: [
        { value: 'Ice cream', label: 'Ice cream' },
        { value: 'Sushi', label: 'Sushi' },
        { value: 'Octopus', label: 'Octopus' },
      ],
      ref: '12a531f26',
      value: 'Ice cream',
    },
    {
      type: 'range',
      label: 'How much do you like Ice cream?',
      min: { value: 0, label: 'Not so much' },
      max: { value: 1000, label: 'Very much' },
      ref: '750342b7',
      value: 209.38,
    },
    {
      type: 'tertiary',
      label: 'Have you had Ice cream recently?',
      ref: '16a29d23c',
      value: 'no',
    },
    {
      type: 'text',
      label: 'When exactly did you have Ice cream?',
      ref: '71d80281',
      value: 'A long time ago...',
    },
    {
      type: 'multipleChoice',
      label: 'Where have you been recently?',
      description: 'You can select multiple options.',
      options: [
        { value: 'China', label: 'China' },
        { value: 'india', label: 'India' },
        { value: 'Russia', label: 'Russia' },
      ],
      ref: '1984f892',
      value: ['india', 'Russia'],
    },
    {
      type: 'upload',
      label: 'How about some pictures?',
      optional: true,
      ref: '720c4184',
      value: ['b3896992fce8.png'],
    },
  ],
  decisionSupport: [
    { id: 'unhappy', label: 'Unhappy', color: 'yellow' },
    {
      label: 'Been to Russia',
      description: 'Might be depressed',
      color: 'red',
    },
  ],
  exports: [],
  scores: {
    scores: {},
  },
  significant: [
    {
      id: 'happy',
      type: 'binary',
      label: 'Are you happy?',
      ref: '16f2f849c',
      value: 'no',
    },
    {
      type: 'multipleChoice',
      label: 'Recent countries',
      options: [
        { value: 'China', label: 'China' },
        { value: 'india', label: 'India' },
        { value: 'Russia', label: 'Russia' },
      ],
      ref: '64acd22b',
      value: ['india', 'Russia'],
      warn: true,
    },
  ],
};

import type { Guide } from '../../types';

import ds from './decisionSupport';

// Health profile modules
import alcohol from '../modules/alcohol';
import allergyAntibiotics from '../modules/allergyAntibiotics';
import allergyMedications from '../modules/allergyMedications';
import allergyOther from '../modules/allergyOther';
// import anamnesis from '../modules/anamnesis'; Not imported in this guide since adaptions needed to be made
import bodyMeasures from '../modules/bodyMeasures';
import heredity from '../modules/heredity';
// import medications from '../modules/medications'; Not imported in this guide since adaptions needed to be made
import naturalMedications from '../modules/naturalMedications';
import occupation from '../modules/occupation';
import other from '../modules/other';
import smoking from '../modules/smoking';

import {
  anticonceptionMedicationList,
  otherAnticonceptionMedication,
} from '../medications';

export default (async args => {
  const {
    ask,
    decisionSupport,
    exports,
    patient: { age },
    significant,
  } = args;

  decisionSupport.push(ds.integratedHealthProfile);

  let pregnantAnswer;
  let usesContraceptionAnswer;
  let whatContraceptionAnswer;
  let satisfactionAnswer;

  const combinedContrapetivesList = anticonceptionMedicationList.filter(med =>
    med.tags.includes('combined')
  );

  const miniPillContraceptivesList = anticonceptionMedicationList.filter(med =>
    med.tags.includes('mini')
  );

  await ask.info({
    description:
      'Innan du besvarar frågorna vill vi betona vikten av dina ärliga och korrekta svar. Vi vet av erfarenhet att uppgifter om t.ex. vikt, rökning och blodtryck ibland kan vara lockande att försköna litegrann, men anledningen till att vi frågar om detta är att de i allra högsta grad påverkar vilka preventivmedel som kan vara lämpliga, eller direkt olämpliga, för dig. Vi värnar om din säkerhet och ditt välmående, och ser fram emot att få hjälpa dig på allra bästa sätt!',
  });

  if (age < 50) {
    const pregnant = {
      type: 'choice',
      id: 'contraception.pregnant',
      label: 'Är du gravid?',
      description:
        'Vi ställer frågan för att vara säkra på att vi inte skriver ut preventivmedel vid fel tillfälle. Om du skulle vara osäker behöver barnmorskan veta detta för att kunna hjälpa dig på bästa sätt.',
      options: [
        { value: 'yes', label: 'Ja' },
        { value: 'notsure', label: 'Osäker' },
        { value: 'no', label: 'Nej' },
      ],
    };

    pregnantAnswer = await ask(pregnant);

    significant.push({
      ...pregnant,
      label: 'Gravid',
      description: '',
      value: pregnantAnswer,
      options: [
        { value: 'yes', label: 'Ja ► ej aktuellt med preventivmedel' },
        { value: 'notsure', label: 'Osäker' },
        { value: 'no', label: 'Nej' },
      ],
      warn: pregnantAnswer !== 'no',
    });

    if (pregnantAnswer === 'yes') {
      exports.push({
        id: 'prescriptionWarnings',
        value: ['**Gravid** \nJa'],
      });
    }
  }

  if (pregnantAnswer === 'no') {
    const givenBirthLastTwoMonths = {
      type: 'choice',
      id: 'contraception.givenBirthLastTwoMonths',
      label: 'Har du fött barn under de senaste 2 månaderna?',
      options: [{ value: 'yes', label: 'Ja' }, { value: 'no', label: 'Nej' }],
    };

    const givenBirthLastTwoMonthsAnswer = await ask(givenBirthLastTwoMonths);

    significant.push({
      ...givenBirthLastTwoMonths,
      label: 'Fött barn under de senaste 2 månaderna',
      value: givenBirthLastTwoMonthsAnswer,
      options: [
        { value: 'yes', label: 'Ja ► ska ej ha kombinerad metod' },
        { value: 'no', label: 'Nej' },
      ],
      warn: ['yes'],
    });
  }

  if (age < 50) {
    const breastfeeding = {
      type: 'binary',
      id: 'contraception.breastfeeding',
      label: 'Ammar du?',
    };

    const breastfeedingAnswer = await ask(breastfeeding);

    significant.push({
      ...breastfeeding,
      label: 'Ammar',
      value: breastfeedingAnswer,
    });

    if (breastfeedingAnswer === 'yes') {
      exports.push({
        id: 'prescriptionWarnings',
        value: ['**Ammar** \nJa'],
      });
    }
  }

  const abortion = {
    type: 'choice',
    id: 'contraception.abortion',
    label: 'Har du någon gång gjort abort pga oönskad graviditet?',
    options: [
      { value: 'yesOnce', label: 'Ja, en gång' },
      { value: 'yesTwiceOrMore', label: 'Ja, två eller fler gånger' },
      { value: 'no', label: 'Nej' },
    ],
  };

  const abortionAnswer = await ask(abortion);

  significant.push({
    ...abortion,
    label: 'Abort',
    value: abortionAnswer,
    options: [
      { value: 'yesOnce', label: 'Ja, en gång' },
      { value: 'yesTwiceOrMore', label: 'Ja, två eller fler ► överväg LARC' },
      { value: 'no', label: 'Nej' },
    ],
  });

  const needOfContraceptionDuration = {
    type: 'choice',
    id: 'contraception.needOfContraceptionDuration',
    label: 'Hur länge tror du att du är i behov av ett preventivmedel?',
    options: [
      { value: 'lessThanAYear', label: 'Kortare tid än ett år' },
      { value: 'longerThanAYear', label: 'Längre tid än ett år' },
    ],
  };

  const needOfContraceptionDurationAnswer = await ask(
    needOfContraceptionDuration
  );

  significant.push({
    ...needOfContraceptionDuration,
    label: 'Hur länge behov av preventivmedel',
    value: needOfContraceptionDurationAnswer,
    options: [
      { value: 'lessThanAYear', label: 'Kortare tid än ett år' },
      {
        value: 'longerThanAYear',
        label: 'Längre än ett år ► överväg LARC',
      },
    ],
  });

  const unwantedPregnancy = {
    type: 'choice',
    id: 'contraception.unwantedPregnancy',
    label: 'Hur viktigt är det för dig att inte bli gravid?',
    description:
      'Olika preventivmetoder varierar lite i hur pass säkra de är. Vi strävar efter att väga in även detta för att kunna rekommendera dig den metod som vi tror kan passa dig bäst.',
    options: [
      {
        value: 'important',
        label: 'Jag vill absolut inte bli gravid nu.',
      },
      {
        value: 'notThatImportant',
        label:
          'Jag vill helst inte bli gravid nu, men om det skulle hända är det okej.',
      },
    ],
  };

  const unwantedPregnancyAnswer = await ask(unwantedPregnancy);

  significant.push({
    ...unwantedPregnancy,
    label: 'Hur viktigt ej bli gravid',
    description: '',
    value: unwantedPregnancyAnswer,
    options: [
      {
        value: 'important',
        label: 'Vill absolut inte bli gravid nu ► överväg LARC',
      },
      {
        value: 'notThatImportant',
        label:
          'Vill helst inte bli gravid nu, men om det skulle hända är det okej.',
      },
    ],
  });

  const compliance = {
    type: 'binary',
    id: 'contraception.compliance',
    label:
      'Känner du dig trygg med att behöva komma ihåg att ta ett preventivmedel varje dag?',
    options: [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nej',
      },
    ],
  };

  const complianceAnswer = await ask(compliance);

  significant.push({
    ...compliance,
    label: 'Klarar dagligt kom ihåg',
    value: complianceAnswer,
    options: [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nej ► överväg LARC',
      },
    ],
  });

  const affectPeriod = {
    type: 'choice',
    id: 'contraception.affectPeriod',
    label:
      'Om det går att påverka, på vilket sätt önskar du att ditt preventivmedel ska påverka din mens?',
    options: [
      {
        value: 'decreasePeriod',
        label: 'Jag önskar undvika eller minska min blödning',
      },
      {
        value: 'periodicPeriod',
        label: 'Jag önskar regelbunden mens varje månad',
      },
      {
        value: 'noSignificance',
        label: 'Ingen betydelse',
      },
    ],
  };

  const affectPeriodAnswer = await ask(affectPeriod);

  significant.push({
    ...affectPeriod,
    label: 'Önskemål om mens',
    value: affectPeriodAnswer,
    options: [
      {
        value: 'decreasePeriod',
        label:
          'Önskar undvika eller minska min blödning ► lämpligt med hormonspiral',
      },
      {
        value: 'periodicPeriod',
        label:
          'Önskar regelbunden mens varje månad ► lämpligt med kombinerad metod',
      },
      {
        value: 'noSignificance',
        label: 'Ingen betydelse',
      },
    ],
  });

  const painfulPeriod = {
    type: 'choice',
    id: 'contraception.painfulPeriod',
    label: 'Har du problem med mensvärk och/eller rikliga blödningar vid mens?',
    options: [{ value: 'yes', label: 'Ja' }, { value: 'no', label: 'Nej' }],
  };

  const painfulPeriodAnswer = await ask(painfulPeriod);

  significant.push({
    ...painfulPeriod,
    label: 'Smärtsam o/e riklig mens',
    value: painfulPeriodAnswer,
    options: [
      { value: 'yes', label: 'Ja ► undvik kopparspiral' },
      { value: 'no', label: 'Nej' },
    ],
  });

  const acne = {
    type: 'choice',
    id: 'contraception.acne',
    label: 'Har du besvär med akne?',
    options: [{ value: 'yes', label: 'Ja' }, { value: 'no', label: 'Nej' }],
  };

  const acneAnswer = await ask(acne);

  significant.push({
    ...acne,
    label: 'Akne',
    value: acneAnswer,
    options: [
      { value: 'yes', label: 'Ja ► kan förbättras med kombinerad metod' },
      { value: 'no', label: 'Nej' },
    ],
  });

  const usesContraception = {
    type: 'binary',
    id: 'contraception.usesContraception',
    label: 'Använder du något preventivmedel?',
  };

  usesContraceptionAnswer = await ask(usesContraception);

  significant.push({
    ...usesContraception,
    label: 'Använder preventivmedel',
    value: usesContraceptionAnswer,
  });

  if (usesContraceptionAnswer === 'no') {
    const lastPeriodMoreThanThreeMonthsAgo = {
      type: 'choice',
      id: 'contraception.lastPeriodMoreThanThreeMonthsAgo',
      label: 'Är det 3 månader eller mer sedan du hade din senaste mens?',
      options: [{ value: 'yes', label: 'Ja' }, { value: 'no', label: 'Nej' }],
    };

    const lastPeriodMoreThanThreeMonthsAgoAnswer = await ask(
      lastPeriodMoreThanThreeMonthsAgo
    );

    significant.push({
      ...lastPeriodMoreThanThreeMonthsAgo,
      label: '3 mån eller mer sedan SM',
      value: lastPeriodMoreThanThreeMonthsAgoAnswer,
      options: [
        { value: 'yes', label: 'Ja ► rådgör med eller hänvisa till läkare' },
        { value: 'no', label: 'Nej' },
      ],
      warn: ['yes'],
    });
  }

  if (usesContraceptionAnswer === 'yes') {
    const anticonceptionList = anticonceptionMedicationList;

    const optionsList = [...anticonceptionList, otherAnticonceptionMedication];

    const whatContraception = {
      type: 'choice',
      id: 'contraception.whatContraception',
      label: 'Vilket preventivmedel använder du?',
      options: optionsList,
    };

    whatContraceptionAnswer = await ask(whatContraception);

    significant.push({
      ...whatContraception,
      label: 'Vilket',
      value: whatContraceptionAnswer,
    });

    if (whatContraceptionAnswer === 'other') {
      const whatContraceptionOther = {
        type: 'text',
        id: 'contraception.whatContraceptionOther',
        label: 'Vilket annat preventivmedel använder du?',
      };

      const whatContraceptionOtherAnswer = await ask(whatContraceptionOther);

      significant.push({
        ...whatContraceptionOther,
        label: 'Vilket annat',
        value: whatContraceptionOtherAnswer,
      });
    }

    const usedContraceptionFor = {
      type: 'choice',
      id: 'contraception.usedContraceptionFor',
      label: 'Hur länge har du använt detta preventivmedel?',
      options: [
        { value: 'lessThanThreeMonths', label: 'Mindre än 3 månader' },
        { value: 'threeToTwelveMonths', label: '3–12 månader' },
        { value: 'oneToFourYears', label: '1–4 år' },
        { value: 'fourToTenYears', label: '4–10 år' },
        { value: 'moreThanTenYears', label: 'Mer än 10 år' },
      ],
    };

    const usedContraceptionForAnswer = await ask(usedContraceptionFor);

    significant.push({
      ...usedContraceptionFor,
      label: 'Använt preventivmedlet i',
      value: usedContraceptionForAnswer,
    });

    const indication = {
      type: 'choice',
      id: 'contraception.indication',
      label: 'Vad är orsaken till att du använder detta preventivmedel?',
      options: [
        {
          value: 'preventPregnancy',
          label: 'Jag vill inte bli gravid',
        },
        {
          value: 'otherReason',
          label: 'Annan medicinsk orsak',
        },
      ],
    };

    const indicationAnswer = await ask(indication);

    significant.push({
      ...indication,
      label: 'Orsak till användning',
      value: indicationAnswer,
      options: [
        {
          value: 'preventPregnancy',
          label: 'Jag vill inte bli gravid',
        },
        {
          value: 'otherReason',
          label: 'Annan medicinsk orsak ► bör handläggas av läkare',
        },
      ],
      warn: ['otherReason'],
    });

    if (indicationAnswer === 'otherReason') {
      const indicationOther = {
        type: 'text',
        id: 'contraception.indicationOther',
        label: 'Vilken annan medicinsk orsak?',
      };

      const indicationOtherAnswer = await ask(indicationOther);

      significant.push({
        ...indicationOther,
        label: 'Annan medicinsk orsak till användning',
        value: indicationOtherAnswer,
      });
    }

    const satisfaction = {
      type: 'choice',
      id: 'contraception.satisfaction',
      label: 'Är du nöjd med ditt nuvarande preventivmedel?',
      options: [
        {
          value: 'yesRenewal',
          label: 'Ja, och om möjligt vill jag förnya receptet',
        },
        {
          value: 'yesWantOptions',
          label: 'Ja, men jag är öppen för förslag som är mer lämpliga för mig',
        },
        { value: 'no', label: 'Nej' },
      ],
    };

    satisfactionAnswer = await ask(satisfaction);

    significant.push({
      ...satisfaction,
      label: 'Nöjd med nuvarande preventivmedel',
      value: satisfactionAnswer,
    });

    if (satisfactionAnswer === 'no') {
      const notSatisfiedReason = {
        type: 'text',
        id: 'contraception.notSatisfiedReason',
        label:
          'På vilket sätt är du inte nöjd med ditt nuvarande preventivmedel?',
      };

      const notSatisfiedReasonAnswer = await ask(notSatisfiedReason);

      significant.push({
        ...notSatisfiedReason,
        label: 'Varför ej nöjd med nuvarande',
        value: notSatisfiedReasonAnswer,
      });
    }
  }

  const ectopicPregnancy = {
    type: 'choice',
    id: 'contraception.ectopicPregnancy',
    label:
      'Har du någon gång drabbats av utomkvedshavandeskap som krävt kirurgisk eller medicinsk behandling?',
    options: [
      { value: 'yes', label: 'Ja' },
      { value: 'no', label: 'Nej' },
      { value: 'dontknow', label: 'Vet ej' },
    ],
  };

  const ectopicPregnancyAnswer = await ask(ectopicPregnancy);

  significant.push({
    ...ectopicPregnancy,
    label: 'Utomkvedshavandeskap',
    value: ectopicPregnancyAnswer,
    options: [
      { value: 'yes', label: 'Ja ► bör inte ha minipiller' },
      { value: 'no', label: 'Nej' },
      { value: 'dontknow', label: 'Vet ej' },
    ],
  });

  const pastOrOngoingDiseases = {
    type: 'multipleChoice',
    id: 'contraception.pastOrOngoingDiseases',
    label: 'Har du, eller har du haft, något av följande?',
    description: 'Flera svarsalternativ möjliga.',
    options: [
      { value: 'breastCancer', label: 'Bröstcancer' },
      {
        value: 'cardiovascularDisease',
        label: 'Stroke, TIA, blodpropp i ben eller lunga',
      },
      {
        value: 'heredityCardioVascularDisease',
        label:
          'Biologisk förälder eller biologiskt syskon som drabbats av blodpropp i ben eller lunga',
      },
      { value: 'myocardialDisease', label: 'Hjärtinfarkt eller kärlkramp' },
      {
        value: 'otherHeartDisease',
        label:
          'Förmaksflimmer, mekanisk hjärtklaff eller annan allvarlig hjärtsjukdom',
      },
      {
        value: 'notSure',
        label: 'Jag känner mig osäker på något av ovanstående',
      },
    ],
    optional: true,
  };

  const pastOrOngoingDiseasesAnswer = await ask(pastOrOngoingDiseases);

  significant.push({
    ...pastOrOngoingDiseases,
    label: 'Tidigare eller pågående sjukdomar - kontraindikationer',
    description: '',
    value: pastOrOngoingDiseasesAnswer,
    options: [
      {
        value: 'breastCancer',
        label: 'Bröstcancer ► kontraindikation mot hormonella preventivmedel',
      },
      {
        value: 'cardiovascularDisease',
        label:
          'Stroke, TIA, DVT eller lungemboli ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'heredityCardioVascularDisease',
        label:
          'Biologisk förälder eller biologiskt syskon som drabbats av blodpropp i ben eller lunga ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'myocardialDisease',
        label:
          'Hjärtinfarkt eller kärlkramp ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'otherHeartDisease',
        label:
          'Förmaksflimmer, mekanisk hjärtklaff eller annan allvarlig hjärtsjukdom ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'notSure',
        label: 'Jag känner mig osäker på något av ovanstående',
      },
    ],
    warn: pastOrOngoingDiseasesAnswer.length !== 0,
  });

  const onGoingDiseases = {
    type: 'multipleChoice',
    id: 'contraception.onGoingDiseases',
    label: 'Har du något av följande?',
    description: 'Flera svarsalternativ möjliga.',
    options: [
      {
        value: 'hypertension',
        label: 'Högt blodtryck, eller behandlas för högt blodtryck',
      },
      { value: 'migraine', label: 'Migrän med aura (s.k. förkänning)' },
      {
        value: 'bloodClottingDisorder',
        label: 'Blodkoagulationssjukdom som ökar risk för proppbildning',
      },
      { value: 'aip', label: 'Akut intermittent porfyri' },
      {
        value: 'sle',
        label: 'Antifosfolipidsyndrom/SLE (systemisk lupus eretymatosus)',
      },
      {
        value: 'diabetesWithComplications',
        label: 'Diabetes med kärlkomplikationer',
      },
      { value: 'familiarHyperlipidemia', label: 'Familjär hyperlipdemi' },
      {
        value: 'cancer',
        label:
          'Pågående cancerbehandling, eller cancerbehandling som avslutats under de senaste 6 månaderna',
      },
      { value: 'liverDisease', label: 'Aktiv leversjukdom' },
      {
        value: 'ulcerativeColitisOrMbCrohn',
        label: 'Ulcerös kolit eller Crohns sjukdom',
      },
      { value: 'varicoseVeins', label: 'Åderbråck som orsakar besvär' },
      {
        value: 'bariatricSurgery',
        label:
          'Genomgått fetmakirurgi (t.ex. gastric by pass el. gastric banding)',
      },
      {
        value: 'notSure',
        label: 'Jag känner mig osäker på något av ovanstående',
      },
    ],
    optional: true,
  };

  const onGoingDiseasesAnswer = await ask(onGoingDiseases);

  significant.push({
    ...onGoingDiseases,
    label: 'Pågående sjukdomar - kontraindikationer',
    value: onGoingDiseasesAnswer,
    description: '',
    options: [
      {
        value: 'hypertension',
        label:
          'Högt blodtryck, eller behandlas för högt blodtryck ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'migraine',
        label:
          'Migrän med aura (s.k. förkänning) ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'bloodClottingDisorder',
        label:
          'Blodkoagulationssjukdom som ökar risk för proppbildning ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'aip',
        label:
          'Akut intermittent porfyri ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'sle',
        label:
          'Antifosfolipidsyndrom/SLE (systemisk lupus eretymatosus) ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'diabetesWithComplications',
        label:
          'Diabetes med kärlkomplikationer ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'familiarHyperlipidemia',
        label:
          'Familjär hyperlipdemi ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'cancer',
        label:
          'Pågående cancerbehandling, eller cancerbehandling som avslutats under de senaste 6 månaderna ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'liverDisease',
        label:
          'Aktiv leversjukdom ► kontraindikation mot hormonella preventivmedel',
      },
      {
        value: 'ulcerativeColitisOrMbCrohn',
        label:
          'Ulcerös kolit eller Crohns sjukdom ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'varicoseVeins',
        label:
          'Åderbråck som orsakar besvär ► kontraindikation mot kombinerade metoder',
      },
      {
        value: 'bariatricSurgery',
        label:
          'Genomgått fetmakirurgi (t.ex. gastric by pass el. gastric banding ► kontraindikation mot perorala metoder',
      },
      {
        value: 'notSure',
        label: 'Jag känner mig osäker på något av ovanstående',
      },
    ],
    warn: onGoingDiseasesAnswer.length !== 0,
  });

  if (onGoingDiseasesAnswer.includes('liverDisease')) {
    exports.push({
      id: 'prescriptionWarnings',
      value: ['**Nedsatt leverfunktion** \n Ja'],
    });
  }

  const combinedContraceptionContradictions = {
    type: 'multipleChoice',
    id: 'contraception.combinedContraceptionContradictions',
    label: 'Har du något av följande?',
    description: 'Flera svarsalternativ möjliga.',
    options: [
      { value: 'rheumatoidArthritis', label: 'Reumatoid artrit (RA)' },
      {
        value: 'diabetesWithoutComplications',
        label: 'Diabetes utan kärlkomplikationer',
      },
      { value: 'hyperlipidemia', label: 'Förhöjda blodfetter' },
      {
        value: 'heredityHeartDiseaseFatherOrBrother',
        label:
          'Biologisk pappa eller bror med hjärtinfarkt eller stroke före 55 år ålder',
      },
      {
        value: 'heredityHeartDiseaseMotherOrSister',
        label:
          'Biologisk mamma eller syster med hjärtinfarkt eller stroke före 65 års ålder',
      },
      {
        value: 'notSure',
        label: 'Jag känner mig osäker på något av ovanstående',
      },
    ],
    optional: true,
  };

  const combinedContraceptionContradictionsAnswer = await ask(
    combinedContraceptionContradictions
  );

  const epilepsy = {
    type: 'choice',
    id: 'contraception.epilepsy',
    label: 'Behandlas du med förebyggande läkemedel mot epilepsi?',
    options: [{ value: 'yes', label: 'Ja' }, { value: 'no', label: 'Nej' }],
  };

  const epilepsyAnswer = await ask(epilepsy);

  significant.push({
    ...epilepsy,
    label: 'Epilepsi med förebyggande behandling',
    value: epilepsyAnswer,
    options: [
      { value: 'yes', label: 'Ja ► ska sannolikt handläggas av läkare' },
      { value: 'no', label: 'Nej' },
    ],
    warn: ['yes'],
  });

  const isCombined = !!combinedContrapetivesList.find(
    med => med.value === whatContraceptionAnswer
  );

  if (
    usesContraceptionAnswer === 'no' ||
    (usesContraceptionAnswer === 'yes' &&
      isCombined &&
      satisfactionAnswer === 'yesRenewal') ||
    (usesContraceptionAnswer === 'yes' &&
      (satisfactionAnswer === 'yesWantOptions' ||
        satisfactionAnswer === 'no')) ||
    whatContraceptionAnswer === 'other'
  ) {
    await ask.info('Ange ditt blodtryck', {
      description: `Blodtryck anges i övertryck och undertryck, t.ex. 120/50. Övertrycket är värdet före snedstrecket. Undertrycket är värdet efter snedstrecket.\n\nDu kan mäta ditt blodtryck på Apoteket, din vårdcentral eller med en blodtrycksmätare hemma.\n\nBlodtrycksmätningen måste ha gjorts under den senaste månaden.`,
    });

    const bloodPressureSystolic = {
      type: 'number',
      id: 'contraception.bloodPressureSystolic',
      label: 'Ange ditt övertryck:',
      description: 'Anges i mmHg',
      min: 1,
      max: 250,
    };

    const bloodPressureDiastolic = {
      type: 'number',
      id: 'contraception.bloodPressureDiastolic',
      label: 'Ange ditt undertryck:',
      description: 'Anges i mmHg',
      min: 1,
      max: 130,
    };

    const bloodPressureSystolicAnswer = await ask(bloodPressureSystolic);

    const bloodPressureDiastolicAnswer = await ask(bloodPressureDiastolic);

    if (
      bloodPressureSystolicAnswer > 140 ||
      bloodPressureDiastolicAnswer > 90
    ) {
      significant.push({
        type: 'number',
        label: 'Blodtryck',
        value: `${bloodPressureSystolicAnswer} / ${bloodPressureDiastolicAnswer} ► hypertoni, kontraindikation med kombinerade metoder`,
        warn: true,
      });
    } else {
      significant.push({
        type: 'number',
        label: 'Blodtryck',
        value: `${bloodPressureSystolicAnswer} / ${bloodPressureDiastolicAnswer}`,
      });
    }

    const confirmBloodPressure = {
      type: 'choice',
      id: 'contraception.confirmBloodPressure',

      label:
        'Ditt angivna blodtryck är ' +
        bloodPressureSystolicAnswer +
        ' / ' +
        bloodPressureDiastolicAnswer +
        ' mmHg',
      options: [
        {
          value: 'confirm',
          label: 'Gå vidare',
        },
      ],
    };

    await ask(confirmBloodPressure);

    if (
      bloodPressureSystolicAnswer > 200 ||
      bloodPressureDiastolicAnswer > 110
    ) {
      await ask.info({
        description:
          'Du har högt blodtryck, vilket kan vara tecken på allvarlig sjukdom. Vi hjälper dig gärna med preventivmedel men du måste parallellt utreda ditt höga blodtryck. Kontakta omgående 1177 för råd om vart du bör vända dig.',
      });
    }
  }

  const otherQuestion = {
    type: 'text',
    id: 'contraception.other',
    label: 'Övrig medicinsk information',
    description:
      'Här kan du ange eventuell övrig information som du tror att barnmorskan kan behöva i sin bedömning.',
    optional: true,
  };

  const otherQuestionAnswer = await ask(otherQuestion);

  significant.push({
    ...otherQuestion,
    label: 'Övrig medicinsk information',
    description: '',
    value: otherQuestionAnswer,
  });

  // Health profile

  await ask.info('Nu har du bara hälsoprofilen kvar.', {
    description: `Hälsoprofilen är till för att ge den som behandlar dig en bild av din allmänna hälsostatus.`,
  });

  significant.push({
    type: 'text',
    label: 'HÄLSOPROFIL',
    value: [''],
  });

  // Health profile modules
  await occupation(args);
  await bodyMeasures(args);
  await alcohol(args);
  await smoking(args);

  const healthIssuesWhat = {
    type: 'multipleChoice',
    id: 'health.issues.what',
    label: 'Markera de sjukdomar eller hälsoproblem du har, eller har haft.',
    description: 'Flera svarsalternativ möjliga.',
    options: [
      {
        value: 'lungs',
        label: 'Lungsjukdom',
      },
      {
        value: 'cancer',
        label: 'Cancersjukdom',
      },
      {
        value: 'neuro',
        label: 'Neurologisk sjukdom',
      },
      {
        value: 'blooddisease',
        label: 'Blodsmitta (HIV, Hepatit B, Hepatit C)',
      },
      {
        value: 'kidney',
        label: 'Njurfunktionsnedsättning',
      },
    ],
    expires: 900,
    optional: true,
  };

  const healthIssuesWhatAnswer = await ask(healthIssuesWhat);

  significant.push({
    ...healthIssuesWhat,
    label: 'Tidigare och/eller nuvarande sjukdomar',
    description: '',
    value: healthIssuesWhatAnswer,
    warn: healthIssuesWhatAnswer.length !== 0,
  });

  if (healthIssuesWhatAnswer.includes('kidney')) {
    exports.push({
      id: 'prescriptionWarnings',
      value: ['**Nedsatt njurfunktion** \n Ja'],
    });
  }

  if (healthIssuesWhatAnswer.includes('lungs')) {
    const healthLungDiseaseWhat = {
      type: 'multipleChoice',
      id: 'health.lungDiseases.what',
      label: 'Vilka lungsjukdomar har du, eller har du haft?',
      description: 'Flera svarsalternativ möjliga.',
      options: [
        {
          value: 'astma',
          label: 'Astma',
        },
        {
          value: 'kol',
          label: 'KOL',
        },
        {
          value: 'other',
          label: 'Annan lungsjukdom',
        },
      ],
      expires: 900,
    };

    const healthLungDiseaseWhatAnswer = await ask(healthLungDiseaseWhat);

    significant.push({
      ...healthLungDiseaseWhat,
      label: 'Lungsjukdom',
      description: '',
      value: healthLungDiseaseWhatAnswer,
    });

    if (healthLungDiseaseWhatAnswer.includes('other')) {
      const healthLungDiseaseWhatOther = {
        type: 'text',
        id: 'health.lungDiseases.what.other',
        label: 'Vilken lungsjukdom har du, eller har du haft?',
        expires: 900,
      };

      const healthLungDiseaseWhatOtherAnswer = await ask(
        healthLungDiseaseWhatOther
      );

      significant.push({
        ...healthLungDiseaseWhatOther,
        label: 'Fri beskrivning annan lungsjukdom',
        value: healthLungDiseaseWhatOtherAnswer,
      });
    }
  }

  if (healthIssuesWhatAnswer.includes('cancer')) {
    const healthCancerWhat = {
      type: 'text',
      id: 'health.cancerWhat',
      label: 'Vilken cancersjukdom har du, eller har du haft?',
      expires: 900,
    };

    const healthCancerWhatAnswer = await ask(healthCancerWhat);

    significant.push({
      ...healthCancerWhat,
      label: 'Cancersjukdom',
      value: healthCancerWhatAnswer,
    });

    const healthCancerYearDiagnosed = {
      type: 'text',
      id: 'health.cancerYearDiagnosed',
      label: 'Vilket år fick du din cancerdiagnos?',
      expires: 900,
    };

    const healthCancerYearDiagnosedAnswer = await ask(
      healthCancerYearDiagnosed
    );

    significant.push({
      ...healthCancerYearDiagnosed,
      label: 'Diagnosticerad (cancer)',
      value: healthCancerYearDiagnosedAnswer,
    });

    const healthCancerAffected = {
      type: 'binary',
      id: 'health.cancerAffected.match',
      label: 'Är ditt hälsotillstånd i dagsläget påverkat av din cancer?',
      expires: 900,
    };

    const healthCancerAffectedAnswer = await ask(healthCancerAffected);

    significant.push({
      ...healthCancerAffected,
      label: 'Har aktuella besvär på grund av cancer',
      value: healthCancerAffectedAnswer,
    });

    if (healthCancerAffectedAnswer === 'yes') {
      const healthCancerAffectedHow = {
        type: 'text',
        id: 'health.cancerAffected.how',
        label:
          'På vilket sätt är ditt hälsotillstånd i dagsläget påverkat av din cancer?',
      };

      const healthCancerAffectedHowAnswer = await ask(healthCancerAffectedHow);

      significant.push({
        ...healthCancerAffectedHow,
        label: 'Beskrivning av aktuella besvär på grund av cancer',
        value: healthCancerAffectedHowAnswer,
      });
    }
  }

  if (healthIssuesWhatAnswer.includes('neuro')) {
    const healthNeurologicalWhat = {
      type: 'multipleChoice',
      id: 'health.neurological.what',
      label: 'Vilka neurologiska sjukdomar har du, eller har du haft?',
      description: 'Flera svarsalternativ möjliga.',
      options: [
        {
          value: 'epilepsy',
          label: 'Epilepsi',
        },
        {
          value: 'ms',
          label: 'MS',
        },
        {
          value: 'parkinson',
          label: 'Parkinson',
        },
        {
          value: 'other',
          label: 'Annan neurologisk sjukdom',
        },
      ],
      expires: 900,
    };

    const healthNeurologicalWhatAnswer = await ask(healthNeurologicalWhat);

    significant.push({
      ...healthNeurologicalWhat,
      label: 'Neurologisk sjukdom',
      description: '',
      value: healthNeurologicalWhatAnswer,
    });

    if (healthNeurologicalWhatAnswer.includes('other')) {
      const healthNeurologicalOther = {
        type: 'text',
        id: 'health.neurological.what.other',
        label: 'Vilken neurologisk sjukdom har du, eller har du haft?',
        expires: 900,
      };

      const healthNeurologicalOtherAnswer = await ask(healthNeurologicalOther);

      significant.push({
        ...healthNeurologicalOther,
        label: 'Fri beskrivning annan neurologisk sjukdom',
        value: healthNeurologicalOtherAnswer,
      });
    }
  }

  if (healthIssuesWhatAnswer.includes('blooddisease')) {
    const healthBloodDiseaseWhat = {
      type: 'multipleChoice',
      id: 'health.bloodDisease.what',
      label: 'Vilken blodsmitta har du, eller har du haft?',
      description: 'Flera svarsalternativ möjliga.',
      options: [
        {
          value: 'hepB',
          label: 'Hepatit B',
        },
        {
          value: 'hepC',
          label: 'Hepatit C',
        },
        {
          value: 'hiv',
          label: 'HIV',
        },
      ],
      expires: 900,
    };

    const healthBloodDiseaseWhatAnswer = await ask(healthBloodDiseaseWhat);

    significant.push({
      ...healthBloodDiseaseWhat,
      label: 'Blodsmitta',
      description: '',
      value: healthBloodDiseaseWhatAnswer,
    });

    if (
      healthBloodDiseaseWhatAnswer.includes('hepB') ||
      healthBloodDiseaseWhatAnswer.includes('hepC')
    ) {
      exports.push({
        id: 'prescriptionWarnings',
        value: ['**Nedsatt leverfunktion pga blodsmitta** \n Ja'],
      });
    }
  }

  const healthOperation = {
    type: 'binary',
    id: 'health.operation.match',
    label: 'Har du genomgått någon större operation?',
    expires: 900,
  };

  const healthOperationAnswer = await ask(healthOperation);

  significant.push({
    ...healthOperation,
    label: 'Har genomgått större operation',
    value: healthOperationAnswer,
  });

  if (healthOperationAnswer === 'yes') {
    const healthOperationWhat = {
      type: 'text',
      id: 'health.operation.what',
      label: 'Vilken större operation har du genomgått?',
      expires: 900,
    };

    const healthOperationWhatAnswer = await ask(healthOperationWhat);

    significant.push({
      ...healthOperationWhat,
      label: 'Beskrivning av operation',
      value: healthOperationWhatAnswer,
    });
  }

  await heredity(args);

  const medicationCurrentFemale = {
    type: 'binary',
    id: 'medication.current.anyFemale',
    label: 'Använder du några läkemedel för tillfället?',
    expires: 180,
  };

  const medicationCurrentFemaleAnswer = await ask(medicationCurrentFemale);

  significant.push({
    ...medicationCurrentFemale,
    label: 'Tar läkemedel för tillfället',
    value: medicationCurrentFemaleAnswer,
  });

  if (medicationCurrentFemaleAnswer === 'yes') {
    const medicationCurrentWhat = {
      type: 'text',
      id: 'medication.current.what',
      label: 'Vilka läkemedel tar du?',
      description:
        'Ange läkemedel, styrka och dos, t.ex. Levaxin, 100 mikrogram, 1 tablett 1 gång/dag. Om du vet av vilken anledning du tar läkemedlet, ange detta.',
      expires: 180,
    };

    const medicationCurrentWhatAnswer = await ask(medicationCurrentWhat);

    significant.push({
      ...medicationCurrentWhat,
      label: 'Beskrivning av läkemedel',
      description: '',
      value: medicationCurrentWhatAnswer,
    });

    const medicationPackaged = {
      type: 'binary',
      id: 'medication.packaged',
      label:
        'Får du dina läkemedel förberedda i små plastpåsar (en påse per doseringstillfälle), märkta med personuppgifter, vilka läkemedel påsen innehåller samt datum och tid för när dessa ska tas?',
      description:
        '![medicationPackaged](https://storage.googleapis.com/mindoktor-assets/formulary/dose_packaged_meds.png)',
      expires: 180,
    };

    const medicationPackagedAnswer = await ask(medicationPackaged);

    significant.push({
      ...medicationPackaged,
      label: 'Har dosförpackade läkemedel',
      description: '',
      value: medicationPackagedAnswer,
      warn: ['yes'],
    });

    if (medicationPackagedAnswer === 'yes') {
      exports.push({
        id: 'prescriptionWarnings',
        value: ['**Har dosförpackade läkemedel** \nJa'],
      });
    }

    const medicationDeliveryHelp = {
      type: 'binary',
      id: 'medication.deliveryHelp.match',
      label:
        'Får du särskild hjälp med utdelning av dina läkemedel av hemtjänst, vårdcentral, mottagning eller liknande?',
      expires: 180,
    };

    const medicationDeliveryHelpAnswer = await ask(medicationDeliveryHelp);

    significant.push({
      ...medicationDeliveryHelp,
      label: 'Får hjälp med utdelning av läkemedel',
      description: '',
      value: medicationDeliveryHelpAnswer,
      warn: ['yes'],
    });

    if (medicationDeliveryHelpAnswer === 'yes') {
      const medicationDeliveryHelpWhat = {
        type: 'text',
        id: 'medication.deliveryHelp.what',
        label: 'Beskriv vilken typ av hjälp med utdelning du får.',
        expires: 180,
      };

      const medicationDeliveryHelpWhatAnswer = await ask(
        medicationDeliveryHelpWhat
      );

      significant.push({
        ...medicationDeliveryHelpWhat,
        label: 'Typ av hjälp med utdelning av läkemedel',
        value: medicationDeliveryHelpWhatAnswer,
      });
    }
  }

  await naturalMedications(args);
  await allergyAntibiotics(args);
  await allergyMedications(args);
  await allergyOther(args);
  await other(args);

  // Import answers from health profile modules through significant

  const heightObj = significant.find(q => q.id === 'height');
  let height;
  if (!!heightObj) {
    height = heightObj.value;
  }

  const weightObj = significant.find(q => q.id === 'weight');
  let weight;
  if (!!weightObj) {
    weight = weightObj.value;
  }

  let bmi;
  if (!!height && !!weight) {
    bmi = Math.round(((weight * 10000) / (height * height)) * 10) / 10;
  }

  const smokingObj = significant.find(q => q.id === 'smoking.status');
  let smoker;
  if (!!smokingObj) {
    smoker = smokingObj.value === '1';
  }

  // Push to significant with values from health profile to manipulate clinic view

  const onGoingDiseasesIndex = significant.findIndex(
    q => q.id === 'contraception.onGoingDiseases'
  );

  if (bmi !== undefined && bmi > 30) {
    significant.splice(onGoingDiseasesIndex + 1, 0, {
      type: 'number',
      label:
        'BMI > 30 ► kontraindikation mot kombinerade metoder och mini-piller',
      value: bmi,
      warn: true,
    });
  }

  const epilepsyIndex = significant.findIndex(
    q => q.id === 'contraception.epilepsy'
  );

  let contradicitionScore = combinedContraceptionContradictionsAnswer.length;

  if (smoker !== undefined && smoker === true) {
    contradicitionScore += 1;
  }

  if (age > 35) {
    contradicitionScore += 1;
  }

  if (combinedContraceptionContradictionsAnswer.includes('notSure')) {
    contradicitionScore -= 1;
  }

  if (age > 35 || (smoker !== undefined && smoker === true)) {
    let contradictionArray = [];
    if (age > 35) {
      contradictionArray.push('Pat > 35 år');
    }
    if (smoker !== undefined && smoker === true) {
      contradictionArray.push('Rökare');
    }

    if (contradicitionScore < 2) {
      significant.splice(epilepsyIndex, 0, {
        type: 'choice',
        label: 'Relativa kontraindikationer',
        description: '',
        value: contradictionArray,
      });
    } else {
      significant.splice(epilepsyIndex, 0, {
        type: 'choice',
        label: 'Absoluta kontraindikationer mot kombinerade metoder',
        value: contradictionArray,
        warn: true,
      });
    }
  }

  if (contradicitionScore < 2) {
    significant.splice(epilepsyIndex, 0, {
      ...combinedContraceptionContradictions,
      label: 'Relativa kontraindikationer',
      description: '',
      value: combinedContraceptionContradictionsAnswer,
    });
  } else {
    significant.splice(epilepsyIndex, 0, {
      ...combinedContraceptionContradictions,
      label: 'Absoluta kontraindikationer mot kombinerade metoder',
      description: '',
      value: combinedContraceptionContradictionsAnswer,
      options: [
        { value: 'rheumatoidArthritis', label: 'Reumatoid artrit (RA)' },
        {
          value: 'diabetesWithoutComplications',
          label: 'Diabetes utan kärlkomplikationer',
        },
        { value: 'hyperlipidemia', label: 'Förhöjda blodfetter' },
        {
          value: 'heredityHeartDiseaseFatherOrBrother',
          label:
            'Biologisk pappa eller bror med hjärtinfarkt eller stroke före 55 år ålder',
        },
        {
          value: 'heredityHeartDiseaseMotherOrSister',
          label:
            'Biologisk mamma eller syster med hjärtinfarkt eller stroke före 65 års ålder',
        },
        {
          value: 'notSure',
          label: 'Jag känner mig osäker på något av ovanstående',
        },
      ],
      warn: true,
    });
  }

  let isOver45;
  if (age > 45) {
    isOver45 = true;
  }

  let isOver35AndSmoker;
  if (age > 35 && (smoker !== undefined && smoker === true)) {
    isOver35AndSmoker = true;
  }

  let hasBmiOver30;
  if (bmi !== undefined && bmi > 30) {
    hasBmiOver30 = true;
  }

  const giveAdvice = 'ge råd om annan metod';
  const giveAdviceOrSendToDoctor = `ge råd om annan metod eller läkarbedömning
    `;
  const isOver45Warning = 'Pat > 45 år';
  const isOver35AndSmokerWarning = 'Pat > 35 år och rökare';
  const hasBmiOver30Warning = 'BMI > 30 ';

  let combinedContraceptionWarningStrings = [];
  if (isCombined) {
    if (isOver45) {
      combinedContraceptionWarningStrings.push(isOver45Warning);
    }
    if (isOver35AndSmoker) {
      combinedContraceptionWarningStrings.push(isOver35AndSmokerWarning);
    }
    if (hasBmiOver30) {
      combinedContraceptionWarningStrings.push(hasBmiOver30Warning);
    }
    if (combinedContraceptionWarningStrings.length > 0) {
      combinedContraceptionWarningStrings.push(giveAdviceOrSendToDoctor);
    }
  }

  const whatContraceptionIndex = significant.findIndex(
    q => q.id === 'contraception.whatContraception'
  );

  if (isCombined && combinedContraceptionWarningStrings.length > 0) {
    significant.splice(whatContraceptionIndex + 1, 0, {
      type: 'choice',
      label: 'Kombinerat preparat valt ► kombinerad metod ej lämpligt då',
      value: combinedContraceptionWarningStrings,
      warn: true,
    });
  }

  const isMiniPill = !!miniPillContraceptivesList.find(
    med => med.value === whatContraceptionAnswer
  );

  let miniPillContraceptionWarningStrings = [];
  if (isMiniPill) {
    if (hasBmiOver30) {
      miniPillContraceptionWarningStrings.push(hasBmiOver30Warning);
    }
    if (miniPillContraceptionWarningStrings.length > 0) {
      miniPillContraceptionWarningStrings.push(giveAdvice);
    }
  }

  if (isMiniPill && miniPillContraceptionWarningStrings.length > 0) {
    significant.splice(whatContraceptionIndex + 1, 0, {
      type: 'choice',
      label: 'Mini-piller preparat valt ► mini-piller ej lämplig metod vid',
      value: miniPillContraceptionWarningStrings,
      warn: true,
    });
  }

  exports.push({ id: 'presentingComplaint', value: 'Preventivmedel' });

  return {};
}: Guide);

import type { GuideWithOut } from '../../types';

export default (async args => {
  const { ask, significant } = args;

  significant.push({
    type: 'text',
    value: 'Patient agenda'.toUpperCase(),
  });

  const hopesQuestion = {
    type: 'multipleChoice',
    options: {
      tests: 'Sends me for tests',
      treatmentChange: 'Changes my treatment',
      diagnosis: 'Gives me a diagnosis',
      moreMedication: 'Gives me more medication',
      other: 'Other',
    },
  };
  const hopes = await ask({
    ...hopesQuestion,
    label: 'What do you hope the doctor does for you today?',
    description: 'More than one answer can be selected',
  });
  if (hopes.filter(key => key !== 'other').length > 0) {
    significant.push({
      ...hopesQuestion,
      label: 'Patient expectations',
      value: hopes,
    });
  }
  if (hopes.includes('other')) {
    significant.push({
      type: 'text',
      label: 'Other expectations',
      value: await ask.text(
        'What else do you hope the doctor does for you today?'
      ),
    });
  }

  const concernsQuestion = {
    type: 'multipleChoice',
    options: {
      notConcerned: `I'm not concerned, I just want treatment`,
      gettingWorse: `They're getting worse`,
      notImproving: `They're not improving`,
      // TODO: follow up and maybe add a flag for newSymptoms
      newSymptoms: `I've got new symptoms`,
      cancer: `I'm worried I might have cancer`,
      other: 'Other',
    },
    optional: true,
  };
  const concerns = await ask({
    ...concernsQuestion,
    label: `What concerns you the most about your symptoms?`,
    description: 'More than one answer can be selected',
  });
  if (concerns.filter(key => key !== 'other').length > 0) {
    significant.push({
      ...concernsQuestion,
      label: 'Patient concerns',
      value: concerns,
      warn: 'cancer',
    });
  }
  if (concerns.includes('other')) {
    significant.push({
      type: 'text',
      label: 'Other concerns',
      value: await ask.text('What else are you concerned about?'),
    });
  }

  return {
    concernedAboutCancer: concerns.includes('cancer'),
  };
}: GuideWithOut<{ concernedAboutCancer: boolean }>);

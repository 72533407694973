import type { Guide } from '../../types';

import aborts from './aborts';
import ds from './decisionSupport';

export default (async ({ ask, decisionSupport, significant }) => {
  decisionSupport.push(ds.medicationReview);

  await ask.choice({
    label:
      'We will now do a medication review. This should take less than 5 minutes.',
    options: ['Continue'],
  });

  // TODO: a bit of a special hack to display a header. Be warned!
  significant.push({
    type: 'text',
    value: 'Medication review'.toUpperCase(),
  });

  const amountOptions = {
    '1To4': '1 - 4',
    '5OrMore': '5 or more',
  };
  const amount = await ask.choice(
    'How many repeat medications are you requesting today?',
    { id: 'review.amount', options: amountOptions }
  );
  if (amount === '5OrMore') {
    significant.push({
      type: 'choice',
      label: 'Number of repeat medications needed',
      options: amountOptions,
      value: amount,
      warn: true,
    });
    return { abort: aborts.polypharmacy };
  }

  const knowWhyQuestion = {
    type: 'choice',
    options: {
      yesAll: 'Yes, all of them.',
      yesSome: 'Yes, some of them.',
      no: 'No',
    },
  };
  const knowWhy = await ask({
    ...knowWhyQuestion,
    id: 'review.knowWhy',
    label: 'Do you know why you are taking your medications?',
  });
  significant.push({
    ...knowWhyQuestion,
    label: 'Does patient know why they are taking repeat medication?',
    value: knowWhy,
    warn: 'no',
  });
  if (['yesAll', 'yesSome'].includes(knowWhy)) {
    const whyOptions = {
      asthma: 'Asthma',
      copd: 'COPD',
      diabetes: 'Diabetes',
      cholesterol: 'High cholesterol',
      bloodPressure: 'High blood pressure',
      heartDisease: 'Heart disease',
      bloodClot: 'Blood clots to the legs or lungs (DVT or PE)',
    };
    const why = await ask.multipleChoice('Why do you take your medication?', {
      id: 'review.why1',
      description: 'More than one answer can be selected.',
      options: whyOptions,
      optional: true,
    });
    if (why.includes('asthma')) {
      decisionSupport.push(ds.asthma);
    }
    if (why.includes('copd')) {
      decisionSupport.push(ds.copd);
    }
    if (why.includes('diabetes')) {
      decisionSupport.push(ds.diabetes);
    }
    if (why.includes('cholesterol')) {
      decisionSupport.push(ds.cholesterol);
    }
    if (why.includes('bloodPressure')) {
      decisionSupport.push(ds.bloodPressure);
    }
    if (why.includes('bloodClot')) {
      decisionSupport.push(ds.bloodClot);
    }
    const why2Options = {
      mentalHealth: 'Mental health',
      thyroid: 'Thyroid problems',
      epilepsy: 'Epilepsy',
      heartburn: 'Heartburn / Indigestion',
      digestion: 'Digestive problems e.g. constipation, IBS',
      pain: 'Pain relief',
      skinCondition: 'Skin condition',
      allergies: 'Allergies',
      other: 'Other',
    };
    const why2 = await ask.multipleChoice('Why do you take your medication?', {
      id: 'review.why2',
      description: 'More than one answer can be selected.',
      options: why2Options,
      optional: !!why.length,
    });
    const value = [...why, ...why2].filter(key => key !== 'other');
    if (value) {
      significant.push({
        type: 'multipleChoice',
        label: 'Reason for taking repeat medication',
        options: { ...whyOptions, ...why2Options },
        value,
      });
    }
    if (why2.includes('mentalHealth')) {
      decisionSupport.push(ds.mentalHealthReview);
    }
    if (why2.includes('thyroid')) {
      decisionSupport.push(ds.thyroidReview);
    }
    if (why2.includes('other')) {
      significant.push({
        type: 'text',
        label: 'Reason for taking repeat medication',
        value: await ask.text('Please give details.', {
          id: 'review.why.other',
        }),
      });
    }
  }

  if (['yesSome', 'no'].includes(knowWhy)) {
    const description = `
# You mentioned you are unsure why you are taking some medications.

# Please list those medications here.
    `;
    significant.push({
      type: 'text',
      label: 'Pt is unsure why they are taking these medications',
      value: await ask.text({ id: 'review.unsure.list', description }),
      warn: true,
    });
  }

  const matchLabel = 'Do you have any problems with your medication?';
  const match = await ask(matchLabel);
  if (match === 'no') {
    significant.push({
      label: matchLabel,
      value: match,
    });
  }

  if (match === 'yes') {
    const problemsOptions = {
      sideEffects: 'Side effects',
      doesntWork: `It doesn't work`,
      difficultSwallow: 'Difficult to swallow',
      useTooOften: 'Have to use too often',
      notSerious: `I don't think my condition is very serious`,
      takeTooMuch: 'I take too much medication',
      forget: 'I often forget to take it',
      other: 'Other',
    };
    const problems = await ask.multipleChoice(
      'What problems do you have with your medication?',
      {
        id: 'review.problems',
        description: 'More than one answer can be selected.',
        options: problemsOptions,
      }
    );
    significant.push({
      type: 'multipleChoice',
      label: 'What problems do you have with your medication?',
      options: problemsOptions,
      value: problems,
      warn:
        problems.includes('difficultSwallow') ||
        problems.includes('useTooOften'),
    });
    if (problems.includes('other')) {
      significant.push({
        id: 'review.problems.other',
        type: 'text',
        label: 'Details of other problem with medication',
        value: await ask.text(
          'Please specify what other problems you have with your medication.'
        ),
      });
    }
    if (problems.includes('sideEffects')) {
      significant.push({
        id: 'review.problems.sideEffects',
        type: 'text',
        label: 'Side effects?',
        value: await ask.text(
          'Please list the medication and the side effect.'
        ),
      });

      const options = {
        yes: 'Yes',
        yesReduced:
          'Yes, if it is possible to reduce the dose or change the frequency that I take the medication',
        noOther: 'No, I want to try something else',
        no: 'No, I dont want medication',
      };
      const proceed = await ask.choice(
        'Are you willing to continue taking this medication despite the side effects?',
        { id: 'review.problems.sideEffects.continue', options }
      );
      significant.push({
        type: 'choice',
        label: 'Is the patient willing to continue taking the medication?',
        options,
        value: proceed,
        warn: 'yes',
      });

      if (proceed === 'noOther') {
        const matchQuestion = {
          type: 'choice',
          options: {
            yes: 'Yes',
            no: 'No',
            doctorChoose: 'Let the doctor choose for me',
          },
        };
        const match = await ask({
          ...matchQuestion,
          label:
            'Do you know the name of the medication you would like to try?',
        });
        if (match === 'yes') {
          significant.push({
            type: 'text',
            label: 'Would patient want to try alternative?',
            value: await ask.text(
              'Please let us know what you would try instead.',
              {
                id: 'review.medication.wantToTry',
              }
            ),
            warn: true,
          });
        } else {
          significant.push({
            ...matchQuestion,
            label: 'Want to try alternative medication?',
            value: match,
          });
        }
      }
    }
    if (problems.includes('doesntWork')) {
      const whyOptions = {
        notImproved: 'My condition has not improved',
        symptomsRemain: `My symptoms have improved but they haven't gone away.`,
        alternative: 'I have heard of a better alternative',
        other: 'Other',
      };
      const why = await ask.multipleChoice(
        `Why do you think the medication doesn't work?`,
        { id: 'review.doesntWork.why', options: whyOptions }
      );
      significant.push({
        type: 'multipleChoice',
        label: 'Why does the patient think the medication does not work.',
        options: whyOptions,
        value: why,
        warn: ['notImproved', 'symptomsRemain'],
      });
      if (why.includes('other')) {
        significant.push({
          type: 'text',
          label: 'Reason why repeat medication does not work',
          value: await ask.text(
            'What is the other reason why your medication does not work?',
            {
              id: 'review.doesntWork.why.other',
            }
          ),
        });
      }
      if (
        ['notImproved', 'symptomsRemain'].filter(key => why.includes(key))
          .length > 0
      ) {
        significant.push({
          type: 'text',
          label: `Patient's ongoing symptoms`,
          value: await ask.text(
            'Tell us about your symptoms that have not gone away.',
            {
              id: 'review.ongoingSymptoms',
            }
          ),
        });

        const photoNeeded = await ask(
          'Do you need to show the doctor a photo to better explain your symptoms?'
        );
        if (photoNeeded === 'yes') {
          const photos = await ask.upload('Please upload at least one photo.', {
            id: 'review.ongoingSymptoms.photos',
          });
          significant.push({
            type: 'upload',
            label: 'Photo from patient regarding their ongoing symptoms',
            value: photos,
          });
        }
      }
      if (why.includes('alternative')) {
        significant.push({
          type: 'text',
          label: 'Medication alternative patient prefers',
          value: await ask.text(
            'Tell us about the alternative you would prefer.',
            {
              id: 'review.medication.alternative',
            }
          ),
        });
      }
    }
    if (problems.includes('forget')) {
      significant.push({
        type: 'text',
        label: 'Reasons for forgetting to take medication',
        value: await ask.text('Why do you forget to take your medication?', {
          id: 'review.forget.why',
        }),
      });
    }
  }

  return {};
}: Guide);

import type { Guide } from '../../types';

import aborts from './aborts';

import ds from './decisionSupport';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const { ask, decisionSupport, exports, significant } = args;

  const nappyRashHelpWith = {
    type: 'choice',
    id: 'nappyRashHelpWith',
    label: "How can we help with your child's nappy rash?",
    options: [
      {
        label: "Advice on how to help my child's nappy rash",
        value: 'advice',
      },
      {
        label: "I'm worried that my child's nappy rash is infected",
        value: 'infected',
      },
      {
        label:
          "I'm worried that my child has got something other than a nappy rash",
        value: 'notNappyRash',
      },
      { label: 'Other', value: 'other' },
    ],
  };

  const nappyRashHelpWithAnswer = await ask(nappyRashHelpWith);

  if (!nappyRashHelpWithAnswer.includes('other')) {
    significant.push({
      ...nappyRashHelpWith,
      label: 'Reason to seek help today',
      value: nappyRashHelpWithAnswer,
      warn:
        nappyRashHelpWithAnswer.includes('infected') ||
        nappyRashHelpWithAnswer.includes('notNappyRash'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      advice: 'Would like advice on how to help nappy rash',
      infected: 'Worried nappy rash is infected',
      notCradleCap: 'Worried alternate diagnosis to nappy rash',
    }[nappyRashHelpWithAnswer],
  });

  if (nappyRashHelpWithAnswer.includes('other')) {
    const nappyRashHelpWithOther = {
      type: 'text',
      id: 'nappyRashHelpWithOther',
      label: "Please tell us what you would like from today's visit.",
    };

    const nappyRashHelpWithOtherAnswer = await ask(nappyRashHelpWithOther);

    significant.push({
      ...nappyRashHelpWithOther,
      label: "Purpose of today's visit",
      value: nappyRashHelpWithOtherAnswer,
    });
  }

  if (nappyRashHelpWithAnswer.includes('advice')) {
    decisionSupport.push(ds.nappyRashAdvice);
  }
  if (nappyRashHelpWithAnswer.includes('infected')) {
    decisionSupport.push(ds.infectedNappyRash);
  }
  if (nappyRashHelpWithAnswer.includes('notNappyRash')) {
    decisionSupport.push(ds.notNappyRash);
  }

  const nappyRashDuration = {
    type: 'choice',
    id: 'nappyRashDuration',
    label: 'How long has your child had a nappy rash for?',
    options: [
      { label: 'A few days', value: 'days' },
      { label: '1 week', value: 'oneWeek' },
      { label: '2-3 weeks', value: 'twoToThreeWeeks' },
      { label: '1 month', value: 'oneMonth' },
      { label: 'Longer than 1 month', value: 'longerThanOneMonth' },
    ],
  };

  const nappyRashDurationAnswer = await ask(nappyRashDuration);

  if (!nappyRashDurationAnswer.includes('longerThanOneMonth')) {
    significant.push({
      ...nappyRashDuration,
      label: 'Duration of nappy rash',
      value: nappyRashDurationAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      days: 'Nappy rash duration - A few days',
      oneWeek: 'Nappy rash duration - 1 week',
      twoToThreeWeeks: 'Nappy rash duration - 2-3 weeks',
      oneMonth: 'Nappy rash duration - 1 month',
      longerThanOneMonth: 'Nappy rash duration - Longer than 1 month',
    }[nappyRashDurationAnswer],
  });

  if (nappyRashDurationAnswer.includes('longerThanOneMonth')) {
    const nappyRashLongDuration = {
      type: 'text',
      id: 'nappyRashLongDuration',
      label: 'Please tell us how long your child has had a nappy rash for.',
    };

    const nappyRashLongDurationAnswer = await ask(nappyRashLongDuration);

    significant.push({
      ...nappyRashLongDuration,
      label: 'Nappy rash duration details',
      value: nappyRashLongDurationAnswer,
    });
  }

  const nappyRashLocation = {
    type: 'multipleChoice',
    id: 'nappyRashLocation',
    label: 'Where is the rash?',
    description: 'More than one answer can be selected.',
    options: [
      { label: 'In the bottom folds', value: 'bottomFolds' },
      {
        label: 'On the bottom',
        value: 'bottom',
      },
      {
        label: 'Front genitals',
        value: 'frontalGenitals',
      },
      {
        label: 'Spreading to the tummy',
        value: 'toTheTummy',
      },
      { label: 'Spreading to the back', value: 'upTheBack' },
      { label: 'Other', value: 'other' },
    ],
  };

  const nappyRashLocationAnswer = await ask(nappyRashLocation);

  significant.push({
    ...nappyRashLocation,
    label: 'Nappy rash location',
    description: '',
    value: nappyRashLocationAnswer,
    warn:
      nappyRashLocationAnswer.includes('toTheTummy') ||
      nappyRashLocationAnswer.includes('upTheBack'),
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      bottomFolds: 'Nappy rash in the natal cleft',
      bottom: 'Nappy rash on the bottom',
      frontalGenitals: 'Nappy rash on the front genitals',
      toTheTummy: 'Nappy rash spreading to the tummy',
      upTheBack: 'Nappy rash spreading to the back',
    }[nappyRashLocationAnswer],
  });

  if (nappyRashLocationAnswer.includes('other')) {
    const nappyRashOtherLocation = {
      type: 'text',
      id: 'nappyRashOtherLocation',
      label: 'Please tell us where the rash is.',
    };

    const nappyRashOtherLocationAnswer = await ask(nappyRashOtherLocation);

    significant.push({
      ...nappyRashOtherLocation,
      label: 'Nappy rash location',
      value: nappyRashOtherLocationAnswer,
    });
  }

  const nappyRashDescription = {
    type: 'multipleChoice',
    id: 'nappyRashDescription',
    label: "What best describes your child's rash?",
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Raised', value: 'raised' },
      { label: 'Itchy', value: 'itchy' },
      { label: 'Red patches', value: 'redPatches' },
      { label: 'Weeping', value: 'weeping' },
      { label: 'Bleeding', value: 'bleeding' },
      { label: 'Blisters or boils', value: 'blistersOrBoils' },
      { label: 'Other', value: 'other' },
    ],
  };

  const nappyRashDescriptionAnswer = await ask(nappyRashDescription);

  if (!nappyRashDescriptionAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...nappyRashDescription,
      label: 'Nappy rash description',
      description: '',
      value: nappyRashDescriptionAnswer.filter(value => value !== 'other'),
      warn:
        nappyRashDescriptionAnswer.includes('weeping') ||
        nappyRashDescriptionAnswer.includes('bleeding') ||
        nappyRashDescriptionAnswer.includes('blistersOrBoils'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      raised: 'Nappy rash is raised',
      itchy: 'Nappy rash is itchy',
      redPatches: 'Nappy rash is red patches',
      weeping: 'Nappy rash is weeping',
      bleeding: 'Nappy rash is bleeding',
      blistersOrBoils: 'Nappy rash has blisters or boils',
      other: 'Other',
    }[nappyRashDescriptionAnswer],
  });

  if (
    nappyRashDescriptionAnswer.includes('weeping') ||
    nappyRashDescriptionAnswer.includes('bleeding') ||
    nappyRashDescriptionAnswer.includes('blistersOrBoils')
  ) {
    return { abort: aborts.nappyRashInfection };
  }

  if (nappyRashDescriptionAnswer.includes('other')) {
    const nappyRashDescriptionOther = {
      type: 'text',
      id: 'nappyRashDescriptionOther',
      label: 'Please describe the rash.',
    };

    const nappyRashDescriptionOtherAnswer = await ask(
      nappyRashDescriptionOther
    );

    significant.push({
      ...nappyRashDescriptionOther,
      label: 'Rash description',
      value: nappyRashDescriptionOtherAnswer,
    });
  }

  const distressedBaby = {
    type: 'binary',
    id: 'distressedBaby',
    label:
      'Does your child seem to be distressed or unhappy because of their rash?',
  };

  const distressedBabyAnswer = await ask(distressedBaby);

  if (distressedBabyAnswer === 'yes') {
    significant.push({
      ...distressedBaby,
      label: 'Distressed because of nappy rash',
      value: distressedBabyAnswer,
    });

    decisionSupport.push(ds.distressedBaby);

    const distressedBabyImpact = {
      type: 'text',
      id: 'distressedBabyImpact',
      label: 'Please describe how the rash is impacting on your child.',
    };

    const distressedBabyImpactAnswer = await ask(distressedBabyImpact);

    significant.push({
      ...distressedBabyImpact,
      label: 'Distress, description',
      value: distressedBabyImpactAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Child appears distressed by nappy rash',
      no: 'Child not distressed by napy rash',
    }[distressedBabyAnswer],
  });

  const antibioticsInPastWeek = {
    type: 'binary',
    id: 'antibioticsInPastWeek',
    label: 'Has your child had any antibiotics in the past week?',
  };

  const antibioticsInPastWeekAnswer = await ask(antibioticsInPastWeek);

  if (antibioticsInPastWeekAnswer === 'yes') {
    significant.push({
      ...antibioticsInPastWeek,
      label: 'Antibiotics in the past week',
      value: antibioticsInPastWeekAnswer,
    });

    decisionSupport.push(ds.napkinCandidasis);
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Antibitoics in the past week',
      no: 'No antibiotics in the past week',
    }[antibioticsInPastWeekAnswer],
  });

  const nappyRashCreams = {
    type: 'binary',
    id: 'nappyRashCreams',
    label: "Have you tried any creams for your child's rash?",
  };

  const nappyRashCreamsAnswer = await ask(nappyRashCreams);

  if (nappyRashCreamsAnswer === 'no') {
    significant.push({
      ...nappyRashCreams,
      label: 'Previously tried creams for nappy rash',
      value: nappyRashCreamsAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Previously tried creams for nappy rash',
      no: 'Not previously tried creams for nappy rash',
    }[nappyRashCreamsAnswer],
  });

  if (nappyRashCreamsAnswer === 'yes') {
    const nappyRashCreamsWhat = {
      type: 'multipleChoice',
      id: 'nappyRashCreamsWhat',
      label: 'Have you tried any of the creams below?',
      description: 'More than one answer can be selected.',
      options: [
        { label: 'E45', value: 'e45' },
        { label: 'Sudocrem', value: 'sudocrem' },
        { label: 'Bepantham nappy rash cream', value: 'bepanhtam' },
        { label: 'Oil', value: 'oil' },
        { label: 'Metanium nappy rash cream', value: 'metanium' },
        { label: 'Zinc and castor cream', value: 'zinc' },
        { label: 'Other', value: 'other' },
      ],
    };

    const nappyRashCreamsWhatAnswer = await ask(nappyRashCreamsWhat);

    if (!nappyRashCreamsWhatAnswer.every(answer => answer === 'other')) {
      significant.push({
        ...nappyRashCreamsWhat,
        label: 'Previously tried creams for nappy rash',
        description: '',
        value: nappyRashCreamsWhatAnswer.filter(answer => answer !== 'other'),
      });
    }
    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        e45: 'Tried E45 for nappy rash',
        sudocrem: 'Tried Sudocrem for nappy rash',
        bepanhtam: 'Tried Bepantham nappy rash cream',
        oil: 'Tried Oil for nappy rash',
        metanium: 'Tried Metanium nappy rash cream',
        zinc: 'Tried Zinc and castor cream for nappy rash',
      }[nappyRashCreamsWhatAnswer],
    });

    if (nappyRashCreamsWhatAnswer.includes('other')) {
      const nappyRashCreamsOther = {
        type: 'text',
        id: 'nappyRashCreamsOther',
        label: 'Please tell us which other creams you have tried.',
      };
      const nappyRashCreamsOtherAnswer = await ask(nappyRashCreamsOther);

      significant.push({
        ...nappyRashCreamsOther,
        label: 'Previously tried creams',
        value: nappyRashCreamsOtherAnswer,
      });
    }
    const nappyRashCreamsHelped = {
      type: 'binary',
      id: 'nappyRashCreamsHelped',
      label: "Did any of the creams you've used so far help?",
    };

    const nappyRashCreamsHelpedAnswer = await ask(nappyRashCreamsHelped);

    if (nappyRashCreamsHelpedAnswer === 'no') {
      significant.push({
        ...nappyRashCreamsHelped,
        label: 'Did creams help',
        value: nappyRashCreamsHelpedAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Creams used for far have helped',
        no: 'Creams used so for have not helped',
      }[nappyRashCreamsHelpedAnswer],
    });

    if (nappyRashCreamsHelpedAnswer === 'yes') {
      const nappyRashCreamsHelpedWhat = {
        type: 'text',
        id: 'nappyRashCreamsHelpedWhat',
        label: 'Please tell us which cream (or creams) did help',
      };

      const nappyRashCreamsHelpedWhatAnswer = await ask(
        nappyRashCreamsHelpedWhat
      );

      significant.push({
        ...nappyRashCreamsHelpedWhat,
        label: 'Cream/s which have helped',
        value: nappyRashCreamsHelpedWhatAnswer,
      });
    }
    const whiteCoatingInMouth = {
      type: 'binary',
      id: 'whiteCoatingInMouth',
      label: 'Does your child have a white coating on their tongue or cheeks?',
    };

    const whiteCoatingInMouthAnswer = await ask(whiteCoatingInMouth);

    significant.push({
      ...whiteCoatingInMouth,
      label: 'White coating on tongue',
      value: whiteCoatingInMouthAnswer,
    });

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'White coating on tongue',
        no: 'No white coating on tongue',
      }[whiteCoatingInMouthAnswer],
    });

    if (whiteCoatingInMouthAnswer === 'yes') {
      decisionSupport.push(ds.oralCandidasis);
    }
  }

  const photoNappyRash = await ask.upload(
    `We'd like you to upload a photo of your child's nappy rash to help the doctor with their diagnosis.`,
    {
      description: `
                You can use a mobile phone camera to take the photo.\n\nRest assured, your photos will be kept confidential and will only be added to your medical record.
              `,
      optional: true,
    }
  );

  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photoNappyRash,
  });

  return {};
}: Guide);

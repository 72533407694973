import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const height = {
    type: 'number',
    id: 'height',
    label: 'Hur lång är du?',
    description: 'Anges i cm',
    min: 50,
    max: 300,
    expires: 99999,
  };

  const heightAnswer = await ask(height);

  significant.push({
    ...height,
    label: 'Längd (cm)',
    description: '',
    value: heightAnswer,
  });

  const weight = {
    type: 'number',
    id: 'weight',
    label: 'Vad väger du?',
    description: 'Anges i kg',
    min: 20,
    max: 700,
    expires: 180,
  };

  const weightAnswer = await ask(weight);

  significant.push({
    ...weight,
    label: 'Vikt (kg)',
    description: '',
    value: weightAnswer,
  });

  const bmi =
    Math.round(((weightAnswer * 10000) / (heightAnswer * heightAnswer)) * 10) /
    10;

  significant.push({
    label: 'BMI',
    type: 'number',
    value: bmi,
    warn: bmi < 17 || bmi >= 35,
  });

  return {};
}: Guide);

export default {
  pregnantOrBreastfeeding: {
    description: `
Unfortunately, we cannot treat you online. **While pregnant or breastfeeding, it's best if you meet see a GP face to face**.

Medications and treatment need to be given extra consideration during these special times — for the sake of both you and your baby.

**Please book an appointment with a GP** so you can talk through your best options.

If your GP surgery is closed, please contact your midwife, local out of hours service, or go to A&E.

Thank you for using Docly. I We hope you feel better soon.
    `,
  },
  immunosuppressed: {
    description: `
Unfortunately, we cannot treat you online if you have a condition, or are taking medication that affects your immune system. This is because it is best if you see a GP face to face and are examined.

If your GP surgery is closed, and you feel very unwell, you can get urgent care by calling 111. For emergencies call 999 or go to A&E.

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  unableToSwallow: {
    description: `
If your sore throat is so bad that you cannot swallow water or your own saliva, you need emergency care.

Please go to A&E or call 999 immediately.
    `,
  },
  airwayObstruction: {
    description: `
If your sore throat is so bad that you have difficulty breathing, you need emergency care.

Please go to A&E or call 999 immediately.
    `,
  },
  sepsis: {
    description: `
If you have deveoped a new rash with your sore throat, it might be a sign that you are seriously unwell.

Please go to A&E or call 999 immediately.
    `,
  },
  emergency: {
    description: `
You need emergency care — this is because I am worried that your condition is severe. Please go to A&E or call 999 immediately.

Thank you for using Docly. We hope you feel better soon.
    `,
  },
};

import * as React from 'react';

import { _t } from '../../../i18n';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Key from '../../components/Key';
import { toggleDraw } from '../../../state/ui/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(({ spacing }) => ({
  key: {
    marginLeft: spacing(),
  },
}));

export default ({ className }: { className?: string }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onClick = () => dispatch(toggleDraw('history'));
  return (
    <Button variant="outlined" onClick={onClick} className={className}>
      {_t('guides.goto.question')}

      <Key character="Q" onPress={onClick} className={classes.key} />
    </Button>
  );
};

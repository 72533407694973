import type { Guide } from '../../types';

export default (async ({ ask, patient: { gender, age }, significant }) => {
  const problemDescribe = {
    type: 'text',
    id: 'dieticianAdvice.problem',
    label: 'Vad har du för besvär eller vad önskar du hjälp med?',
  };
  const problemAnswer = await ask(problemDescribe);

  significant.push({
    ...problemDescribe,
    label: 'Vilka besvär, önskar hjälp med',
    value: problemAnswer,
  });

  const healthProblems = await ask({
    label: 'Har du några hälsoproblem eller sjukdomar?',
    description: 'T.ex. hjärtfel, diabetes, astma.',
    id: 'dieticianAdvice.healthProblems',
  });

  significant.push({
    label: 'Har kända hälsoproblem eller sjukdomar',
    value: healthProblems,
  });

  if (healthProblems === 'yes') {
    const healthProblemsWhich = await ask.text({
      label: 'Ange dina hälsoproblem eller sjukdomar.',
      id: 'dieticianAdvice.healthProblemsWhich',
    });

    significant.push({
      type: 'text',
      label: 'Beskrivning av kända hälsoproblem eller sjukdomar',
      value: healthProblemsWhich,
    });
  }

  const medicationAny = await ask({
    label: 'Använder du några läkemedel för tillfället?',
    id: 'dieticianAdvice.medicationAny',
  });

  significant.push({
    label: 'Tar läkemedel för tillfället',
    value: medicationAny,
  });

  if (medicationAny === 'yes') {
    const medicationWhich = await ask.text({
      label: 'Vilka läkemedel tar du?',
      description:
        'Ange läkemedel, styrka och dos, t.ex. Levaxin, 100 mikrogram, 1 tablett 1 gång/dag. Om du vet av vilken anledning du tar läkemedlet, ange detta.',
      id: 'dieticianAdvice.medicationWhich',
    });

    significant.push({
      type: 'text',
      label: 'Beskrivning av läkemedel',
      value: medicationWhich,
    });
  }

  const activityPerWeek = {
    type: 'choice',
    id: 'dieticianAdvice.activityPerWeek',
    label: 'Ungefär hur många timmar per vecka är du fysiskt aktiv?',
    options: [
      {
        value: '0to2',
        label: '0–2 timmar',
      },
      {
        value: '3to5',
        label: '3–5 timmar',
      },
      {
        value: '6to12',
        label: '6–12 timmar',
      },
      {
        value: '13orMore',
        label: '13 timmar eller mer',
      },
    ],
  };

  const activityPerWeekAnswer = await ask(activityPerWeek);

  significant.push({
    ...activityPerWeek,
    label: 'Ungefär hur många timmar per vecka är du fysiskt aktiv?',
    value: activityPerWeekAnswer,
  });

  const anyDiet = {
    type: 'text',
    id: 'dieticianAdvice.anyDiet',
    label: 'Följer du någon särskild diet och i så fall vilken?',
  };
  const anyDietAnswer = await ask(anyDiet);

  significant.push({
    ...anyDiet,
    label: 'Följer du någon särskild diet och i så fall vilken?',
    value: anyDietAnswer,
  });

  const height = {
    type: 'number',
    id: 'dieticianAdvice.height',
    unit: 'cm',
    label: 'Hur lång är du?',
    description: 'Anges i cm',
  };

  const heightAnswer = await ask(height);

  significant.push({
    ...height,
    label: 'Längd',
    description: '', // hack to not show description from question
    value: heightAnswer,
  });

  const weight = {
    type: 'number',
    id: 'dieticianAdvice.weight',
    unit: 'kg',
    label: 'Hur mycket väger du?',
    description: 'Anges i kg',
  };

  const weightAnswer = await ask(weight);

  significant.push({
    ...weight,
    label: 'Vikt',
    description: '', // hack to not show description from question
    value: weightAnswer,
  });

  if (gender === 'male') {
    const maleAlcohol = {
      type: 'choice',
      id: 'dieticianAdvice.maleAlcohol',
      label: 'Hur mycket alkohol dricker du?',
      description:
        'Ett standardglas motsvarar ett glas vin, en flaska starköl (33 cl) eller 4 cl starksprit.',
      options: [
        {
          value: '14orMore',
          label: '14 standardglas i veckan eller mer',
        },
        {
          value: 'lessThan14',
          label: 'Mindre än 14 standardglas i veckan',
        },
        {
          value: 'dontDrink',
          label: 'Jag dricker inte alkohol',
        },
      ],
    };

    const maleAlcoholAnswers = await ask(maleAlcohol);

    significant.push({
      ...maleAlcohol,
      label: 'Alkoholkonsumtion',
      description: '', // hack to not show description from question
      value: maleAlcoholAnswers,
    });
  } else {
    const femaleAlcohol = {
      type: 'choice',
      id: 'dieticianAdvice.femaleAlcohol',
      label: 'Hur mycket alkohol dricker du?',
      description:
        'Ett standardglas motsvarar ett glas vin, en flaska starköl (33 cl) eller 4 cl starksprit.',
      options: [
        {
          value: '9orMore',
          label: '9 standardglas i veckan eller mer',
        },
        {
          value: 'lessThan9',
          label: 'Mindre än 9 standardglas i veckan',
        },
        {
          value: 'dontDrink',
          label: 'Jag dricker inte alkohol',
        },
      ],
    };

    const femaleAlcoholAnswers = await ask(femaleAlcohol);

    significant.push({
      ...femaleAlcohol,
      label: 'Alkoholkonsumtion',
      description: '', // hack to not show description from question
      value: femaleAlcoholAnswers,
    });
  }

  const natureMedicationAny = await ask({
    label: 'Använder du några natur- eller hälsokostpreparat för tillfället?',
    id: 'dieticianAdvice.natureMedicationAny',
  });

  significant.push({
    label: 'Använder du några natur- eller hälsokostpreparat för tillfället?',
    value: natureMedicationAny,
  });

  if (natureMedicationAny === 'yes') {
    const natureMedicationWhich = await ask.text({
      label: 'Vilka natur- eller hälsokostpreparat tar du?',
      id: 'dieticianAdvice.natureMedicationWhich',
    });

    significant.push({
      type: 'text',
      label: 'Vilka natur- eller hälsokostpreparat tar du?',
      value: natureMedicationWhich,
    });
  }

  const allergiesAny = await ask({
    label: 'Har du några kända allergier?',
    id: 'dieticianAdvice.allergiesAny',
  });

  significant.push({
    label: 'Kända allergier',
    value: allergiesAny,
  });

  if (allergiesAny === 'yes') {
    const allergiesWhich = await ask.text({
      label: 'Vad är du allergisk mot?',
      id: 'dieticianAdvice.allergiesWhich',
    });

    significant.push({
      type: 'text',
      label: 'Vad är du allergisk mot?',
      value: allergiesWhich,
    });
  }

  if (gender === 'female' && age < 50) {
    const pregnant = await ask({
      label: 'Är du gravid?',
      id: 'dieticianAdvice.pregnant',
    });

    significant.push({
      label: 'Gravid',
      value: pregnant,
    });

    const breastfeeding = await ask('Ammar du?', {
      id: 'dieticianAdvice.breastfeeding',
    });

    significant.push({
      label: 'Ammar',
      value: breastfeeding,
    });
  }

  const photos = await ask.upload({
    label:
      'Om du har bilder av testresultat, mätvärden eller liknande som du tror kan vara till hjälp för dietisten, kan du ladda upp dem här.',
    description:
      'Observera att även bilderna är en del av din journal och omfattas av sekretess.',
    optional: true,
  });

  significant.push({
    type: 'upload',
    label: 'Bilder',
    value: photos,
  });

  const other = {
    type: 'text',
    id: 'dieticianAdvice.other',
    label: 'Finns det något annat du vill förmedla till dietisten?',
    optional: true,
  };
  const otherAnswer = await ask(other);

  significant.push({
    ...other,
    label: 'Övrig information',
    value: otherAnswer,
  });

  return {};
}: Guide);

import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';

const ds = {
  reducedActivitySmallChild: {
    id: 'reducedActivitySmallChild',
    label: 'Doctor to decide if needs appointment',
    description: 'Reduced activity amber warning on traffic lights',
    color: 'green',
  },
  reducedActivity: {
    id: 'reducedActivity',
    label: 'Doctor to decide if needs appointment',
    description: 'Reduced activity',
    color: 'green',
  },
};

const aborts = {
  Sepsis: {
    description: `
Unfortunately, we cannot treat your child online. 

**Your child needs emergency care — this is because they may have a severe infection.**

Please **take your child to A&E or call 999 immediately**. 

Thank you for using Docly. We hope your child feels better soon.`,
  },
};

export default (async args => {
  const {
    patient: { age },
  } = args;

  const eChoice = createExtendedChoice(args);

  const activitylevels = await eChoice({
    id: 'triage.activitylevels',
    label: 'How active is your child?',
    clinicLabel: 'Activity levels',
    options: [
      { label: 'Normal', value: 'Normal', keyFinding: 'Normal activity' },
      {
        label: 'Less active or takes a lot to wake them up',
        value: 'Lessactiveortakesalottowakethemup',
        keyFinding: 'Reduced activity',
        warn: true,
        ds: age < 5 ? ds.reducedActivitySmallChild : ds.reducedActivity,
      },
      {
        label: 'Very drowsy',
        value: 'Verydrowsy',
        keyFinding: 'Drowsy',
        warn: true,
        abort: aborts.Sepsis,
      },
      {
        label: 'Not waking up, or if does wake up can not stay awake',
        value: 'Notwakingup,orifdoeswakeupcannotstayawake',
        keyFinding: 'Not rousing',
        warn: true,
        abort: aborts.Sepsis,
      },
    ],
  });
  if (activitylevels.abort) {
    return activitylevels;
  }

  return {};
}: Guide);

import type { Guide } from './../../types';
import { matchOne } from '../common/arrayUtils';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import ds from './decisionSupport';

import informedConsentChild from '../partials/informedConsentChild';
import coughAssessment from './coughAssessment';
import triage from './triage';
import coughReview from './coughReview';
import extendedCough from './extendedCough';
import mainSoreThoatChild from '../partials/mainSoreThoatChild';
import healthProfileChild from '../partials/healthProfileChild';
import pharmacy from '../modules/pharmacy';

import {
  keyFindingLabelCoughLessThanAweek,
  keyFindingLabelCough1to3weeks,
  keyFindingLabelCoughThreeWeeks,
  keyFindingLabelContinuousCough,
} from './coughAssessment';
import { keyFindingLabelBreathingChangedMorethan4weeksago } from './breathing';
import {
  keyFindingLabelSputumYellowGreen,
  keyFindingLabelSputumBrown,
} from './phlegm';

import {
  keyFindingLabelPrescriptionRequest,
  keyFindingLabelReview,
} from './_text';

export default (async args => {
  const {
    ask,
    scores,
    exports,
    decisionSupport,
    patient: { age },
  } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const bumpWarningScore = () => {
    scores.increase('warning');
  };

  const informedConsentChildResult = await informedConsentChild(args);
  if (informedConsentChildResult.abort) {
    return informedConsentChildResult;
  }

  const triageResult = await triage(args);
  if (triageResult.abort) {
    return triageResult;
  }

  const presentingissue = await eChoice({
    id: 'coughChild.presentingissue',
    label: 'How can we help your child?',
    clinicLabel: 'Presenting issue',
    options: [
      {
        label: 'My child has a new cough',
        value: 'Mychildhasanewcough',
        keyFinding: 'New cough',
      },
      {
        label: 'I think my child has a chest infection',
        value: 'Ithinkmychildhasachestinfection',
        keyFinding: 'Possible chest infection',
      },
      {
        label: 'My child needs a follow up for their cough',
        value: 'review',
        keyFinding: keyFindingLabelReview,
      },
      {
        label: 'My child needs another prescription',
        value: 'prescription',
        keyFinding: keyFindingLabelPrescriptionRequest,
      },
      { label: 'Other', value: 'other', hide: true },
    ],
  });

  if (presentingissue === 'other') {
    await eText({
      id: 'coughChild.otherpresentingissue',
      label: 'How can we help your child?',
      clinicLabel: 'Other presenting issue',
      warn: true,
    });
  }

  // newSymptomScore is set in coughReview;
  if (matchOne([presentingissue], ['review', 'prescription'])) {
    const coughReviewResult = await coughReview(args);
    if (coughReviewResult.abort) {
      return coughReviewResult;
    }
  }

  const doAssessment =
    matchOne(
      [presentingissue],
      ['Mychildhasanewcough', 'Ithinkmychildhasachestinfection', 'other']
    ) || scores.get('newSymptomScore') >= 1;

  let sorethroat;
  if (doAssessment) {
    const coughAssessmentResult = await coughAssessment(args);
    if (coughAssessmentResult.abort) {
      return coughAssessmentResult;
    }

    sorethroat = await eChoice({
      id: 'coughChild.sorethroat',
      label: 'Does your child have a sore throat?',
      clinicLabel: 'Sore throat',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          keyFinding: 'Sore throat',
          hide: true,
          onSelected: bumpWarningScore,
        },
        { label: 'No', value: 'no', keyFinding: 'No sore throat' },
      ],
    });

    if (sorethroat === 'yes') {
      const mainSoreThoatChildResult = await mainSoreThoatChild(args);
      if (mainSoreThoatChildResult.abort) {
        return mainSoreThoatChildResult;
      }
    }

    if (exports.find(s => s.value === keyFindingLabelCoughThreeWeeks)) {
      const extendedCoughResult = await extendedCough(args);
      if (extendedCoughResult.abort) {
        return extendedCoughResult;
      }
    }
  }

  const smokerinhousehold = await eChoice({
    id: 'coughChild.smokerinhousehold',
    label: `Does anyone in the child's household smoke?`,
    clinicLabel: 'Smoker in household',
    options: [
      { label: 'Yes', value: 'yes', keyFinding: 'Smoker in the household' },
      { label: 'No', value: 'no', keyFinding: 'No smoker in the household' },
    ],
  });

  if (smokerinhousehold === 'yes') {
    await ask.info({
      description: `
** Secondhand smoke is dangerous, especially for children. **

The best way to protect loved ones is to quit smoking. At the very least, make sure you have a smokefree home and car.`,
    });
  }

  const healthProfileChildResult = await healthProfileChild(args);
  if (healthProfileChildResult.abort) {
    return healthProfileChildResult;
  }

  await pharmacy(args);

  // DS Based on scores (Make sure this is done last in the guide)

  const keyFindingContinuousCough = exports.find(
    s => s.value === keyFindingLabelContinuousCough
  );
  const keyFindingCoughLessThanAweek = exports.find(
    s => s.value === keyFindingLabelCoughLessThanAweek
  );
  const keyFindingCough1to3weeks = exports.find(
    s => s.value === keyFindingLabelCough1to3weeks
  );
  const keyFindingBreathingChangedMorethan4weeksago = exports.find(
    s => s.value === keyFindingLabelBreathingChangedMorethan4weeksago
  );
  const keyFindingSputumYellowGreen = exports.find(
    s => s.value === keyFindingLabelSputumYellowGreen
  );
  const keyFindingSputumBrown = exports.find(
    s => s.value === keyFindingLabelSputumBrown
  );

  if (
    (keyFindingCoughLessThanAweek || keyFindingCough1to3weeks) &&
    scores.get('warning') < 1
  ) {
    decisionSupport.push(ds.selfCare);
  }

  if (keyFindingContinuousCough) {
    if (age > 5 && scores.get('airways') >= 1 && scores.get('warning') < 1) {
      decisionSupport.push(ds.reversibleAirwaysDisease);
    } else {
      decisionSupport.push(ds.continuousCough);
    }
  }

  if (
    keyFindingBreathingChangedMorethan4weeksago &&
    age > 5 &&
    scores.get('airways') >= 1 &&
    scores.get('warning') < 1
  ) {
    decisionSupport.push(ds.reversible);
  }

  if (sorethroat === 'yes') {
    if (scores.get('feverPain') <= 1) {
      decisionSupport.push(ds.feverPain0to1);
    } else if (scores.get('feverPain') <= 3) {
      decisionSupport.push(ds.feverPain2to3);
    } else if (scores.get('feverPain') > 3) {
      decisionSupport.push(ds.feverPain4);
    }
  }

  if (
    (keyFindingSputumYellowGreen || keyFindingSputumBrown) &&
    scores.get('warning') > 1
  ) {
    decisionSupport.push(ds.chestInfection);
  }

  // Summary settings
  exports.push({ id: 'presentingComplaint', value: 'Cough' });
  exports.push({ id: 'snomedCode', value: 'c/o cough 272039006' });
  exports.push({
    id: 'diagnosis.options',
    value: ['Viral URTI', 'Asthma', 'Hayfever', 'Pharyngitis', 'Tonsillitis'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed antibiotics', 'Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Self-care advice',
      'Antibiotics prescribed and advice given',
      'Routine appointment to see GP face to face',
      'Urgent appointment to see GP face to face',
      'Referred for emergency care (A&E)',
    ],
  });

  return {};
}: Guide);

import type { Guide } from './../../types';

import moduleEndMessage from '../modules/endMessage';

import ds from './decisionSupport';
import aborts from './aborts';
import triage from './triage';
import collateDecisionSupport from '../common/collateDecisionSupport';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';
import unwellOutOfHoursWarning from '../partials/unwellOutOfHoursWarning';
import summaryExports from './summaryExports';
import { coronaSeason } from '../common/consts';
import modulePharmacy from '../modules/pharmacy';
import moduleSmoking from '../modules/smoking';
import moduleAlcohol from '../modules/alcohol';
import moduleAlternateMedication from '../modules/alternateMedication';
import moduleAllergies from '../modules/allergies';
import { ensureArray } from '../common/arrayUtils';

export default (async args => {
  const { ask, decisionSupport, exports } = args;

  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  summaryExports(exports);

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  await ask.info({
    description: 'We are now going to ask about your cough...',
  });

  const duration = await eChoice({
    id: 'cough.duration',
    label: 'How long have you been suffering from a cough?',
    clinicLabel: 'Duration of cough',
    options: [
      {
        label: 'Less than 1 week',
        value: 'lessThan1Week',
        keyFinding: 'Duration < 1 week',
      },
      {
        label: '1–3 weeks',
        value: '1To3Weeks',
        keyFinding: 'Duration 1 -3 weeks',
      },
      {
        label: 'More than 3 weeks',
        value: 'moreThan3Weeks',
        keyFinding: 'Duration > 3 weeeks',
        warn: true,
        ds: ds.pvConsultCough,
        abort: coronaSeason ? undefined : aborts.coughGreaterThan3Weeks,
      },
    ],
  });
  if (duration.abort) {
    return { abort: duration.abort };
  }
  await eChoice({
    id: 'cough.startWithCold',
    label: 'Did your cough start with a cold?',
    clinicLabel: 'Coryzal symptoms before cough',
    options: [
      {
        value: 'yes',
        keyFinding: 'Coryzal symptoms before cough',
      },
      {
        value: 'no',
        keyFinding: 'No coryzal symptoms before cough',
        warn: true,
      },
    ],
  });

  const normallyShortOfBreath = await eChoice({
    id: 'cough.normallyShortOfBreath',
    label:
      'Do you have a condition that means that you are normally short of breath?',
    clinicLabel: 'Normally feels short of breath',
    options: [
      {
        value: 'yes',
        warn: true,
        keyFinding: 'Pt normally feels short of breath',
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  let normallyShortOfBreathWorse;
  let shortOfBreathNow;
  if (normallyShortOfBreath === 'yes') {
    await eText({
      id: 'cough.normallyShortOfBreathCondition',
      label: 'What condition do you have that makes you short of breath?',
      clinicLabel: 'Condition making pt normally short of breath',
    });

    normallyShortOfBreathWorse = await eChoice({
      id: 'cough.normallyShortOfBreathWorse',
      label: 'Has your shortness of breath become worse?',
      clinicLabel: 'Worsening shortness of breath',
      options: [
        {
          value: 'yes',
          keyFinding: 'Worsening shortness of breath',
          warn: true,
        },
        {
          value: 'no',
          hide: true,
        },
      ],
    });
  } else {
    shortOfBreathNow = await eChoice({
      id: 'cough.shortOfBreathNow',
      label: 'Do you feel short of breath now?',
      clinicLabel: 'Shortness of breath currently',
      options: [
        {
          value: 'yes',
          keyFinding: 'Feeling short of breath now',
          warn: true,
        },
        {
          value: 'no',
          hide: true,
        },
      ],
    });
  }

  if (normallyShortOfBreathWorse === 'yes' || shortOfBreathNow === 'yes') {
    const whenShortOfBreath = await eChoice({
      id: 'cough.whenShortOfBreath',
      label: 'When do you feel short of breath?',
      clinicLabel: 'When does pt feel short of breath',
      options: [
        {
          label: 'Only when I cough',
          value: 'cough',
        },
        {
          label: 'Moderate physical activity, e.g. climbing stairs',
          value: 'moderatePhysicalActivity',
          keyFinding: 'Shortness of breath with moderate activity',
        },
        {
          label: 'Low level physical activity, e.g. getting dressed',
          value: 'lowPhysicalActivity',
          keyFinding: 'Shortness of breath with low level activity',
          warn: true,
          urgent: true,
          ds: coronaSeason
            ? ds.pvConsultDifficultyInBreathing
            : ds.rejectBreathingProblem,
        },
        {
          label: 'At rest, while I am sitting still',
          value: 'rest',
          keyFinding: 'Shortness of breath at rest',
          warn: true,
          abort: aborts.emergency,
        },
      ],
    });
    if (whenShortOfBreath.abort) {
      return { abort: whenShortOfBreath.abort };
    }
    if (whenShortOfBreath === 'lowPhysicalActivity') {
      await unwellOutOfHoursWarning({
        ...args,
        id: 'cough.whenShortOfBreath',
        label:
          'Feeling out of breath while doing low level physical activities, can be a sign of a severe chest infection.',
      });
    }
    if (
      ['moderatePhysicalActivity', 'lowPhysicalActivity'].includes(
        whenShortOfBreath
      )
    ) {
      const speakNormally = await eChoice({
        id: 'cough.speakNormally',
        label:
          'Can you speak normally, or are you out of breath when speaking?',
        clinicLabel: 'Able to speak in full sentences',
        options: [
          {
            label: 'Yes - I can speak normally',
            value: 'yes',
            keyFinding: 'Able to speak in full sentences',
          },
          {
            label: 'No - I am out of breath when speaking',
            value: 'no',
            keyFinding: 'Unable to speak in full sentences',
            warn: true,
            abort: aborts.emergency,
          },
        ],
      });
      if (speakNormally.abort) {
        return { abort: speakNormally.abort };
      }
    }
  }

  const wheezy = await eChoice({
    id: 'cough.wheezy',
    label: 'Is your breathing noisy or wheezy?',
    clinicLabel: 'Noisy breathing or wheezing',
    options: [
      {
        label: 'I can breathe normally',
        value: 'normally',
        hide: true,
      },
      {
        label: 'My breathing is a little noisy / wheezy',
        value: 'noisyWheezy',
        keyFinding: 'Noisy Breathing / Wheeze',
        warn: true,
        urgent: true,
        ds: coronaSeason ? ds.pvConsultWheeze : ds.rejectWheeze,
      },
      {
        label: 'My breathing is noisy and it is harder than normal to breathe',
        value: 'noisyHarder',
        keyFinding: 'Noisy breathing and mild shortness of breath.',
        warn: true,
        abort: aborts.emergency,
      },
      {
        label: 'I am struggling to breathe',
        value: 'struggling',
        keyFinding: 'Significant SOB',
        warn: true,
        abort: aborts.emergency,
      },
    ],
  });
  if (wheezy.abort) {
    return { abort: wheezy.abort };
  }

  if (wheezy === 'noisyWheezy') {
    await unwellOutOfHoursWarning({
      ...args,
      id: 'cough.whenWheezy',
      label:
        'If your breathing is noisy or wheezy, it could be a sign of a severe breathing problem.',
    });
  }

  const chestPain = await eChoice({
    id: 'cough.chestPain',
    label:
      'Do you have a sharp pain in your chest when you take a deep breath in?',
    clinicLabel: 'Pleuritic chest pain',
    options: [
      {
        label: 'Yes - I have a sharp pain in my chest',
        value: 'yes',
        keyFinding: 'Pleuritic chest pain',
        warn: true,
        abort: aborts.emergency,
      },
      {
        label: 'It hurts to cough, but not when I take a breath in',
        value: 'cough',
      },
      {
        label: 'I have a raspy feeling in my throat when I breathe in',
        value: 'raspy',
      },
      {
        label: 'No',
        value: 'no',
        hide: true,
      },
    ],
  });
  if (chestPain.abort) {
    return { abort: chestPain.abort };
  }

  const coughingPhlegm = await eChoice({
    id: 'cough.coughingPhlegm',
    label: 'Are you coughing up any phlegm or mucus?',
    clinicLabel: 'Productive cough',
    options: [
      {
        value: 'yes',
        hide: true,
        keyFinding: 'Productive cough',
      },
      {
        value: 'no',
        keyFinding: 'Non-productive cough',
      },
    ],
  });
  if (coughingPhlegm === 'yes') {
    await eChoice({
      id: 'cough.colourOfSputum',
      label: 'What colour is it?',
      clinicLabel: 'Colour of sputum',
      options: [
        {
          label: 'Clear or white',
          value: 'clearOrWhite',
        },
        {
          label: 'Yellow or green',
          value: 'yellowOrGreen',
          keyFinding: 'Green / Yellow Sputum',
        },
        {
          label: 'Brown',
          value: 'brown',
          keyFinding: 'Brown Sputum',
          warn: true,
          ds: coronaSeason
            ? ds.pvConsultLrtiBrownPhlem
            : ds.rejectLrtiBrownPhlem,
        },
      ],
    });
  }

  const coughedBlood = await eChoice({
    id: 'cough.coughedBlood',
    label: 'Have you coughed up any blood?',
    clinicLabel: 'Haemoptysis',
    options: [
      {
        value: 'yes',
        warn: true,
        urgent: true,
        keyFinding: 'Blood in sputum',
        ds: coronaSeason ? ds.pvConsultHaemoptysis : ds.rejectLrtiHaemoptysis,
      },
      {
        value: 'no',
      },
    ],
  });

  if (coughedBlood === 'yes') {
    await unwellOutOfHoursWarning({
      ...args,
      id: 'cough.whenCoughedBlood',
      label:
        'Coughing up blood could be a sign of a severe chest infection, or something serious going on.',
    });
  }

  const fever = await eChoice({
    id: 'cough.fever',
    label: 'Have you had a temperature in the past 24 hours?',
    clinicLabel: 'Temperature in past 24 hours',
    options: [
      {
        value: 'yes',
        keyFinding: 'Fever in the last 24 hours',
      },
      {
        value: 'no',
        keyFinding: 'No fever in the last 24 hours',
      },
    ],
  });
  let feverOver38;
  if (fever === 'yes') {
    feverOver38 = await eChoice({
      id: 'cough.feverOver38',
      label: 'Was your temperature 38 °C or more?',
      clinicLabel: 'Temperature of 38 °C or more',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          keyFinding: 'T ≥ 38 °C',
          warn: true,
          urgent: true,
          ds: coronaSeason ? ds.pvConsultBacterialLrti : ds.rejectLrti,
        },
        {
          label: 'No',
          value: 'no',
          hide: true,
        },
        {
          label: "I don't know",
          value: 'dontKnow',
          hide: true,
        },
      ],
    });
  }

  let sepsisSymptoms = await eChoice({
    id: 'cough.sepsisSymptoms',
    label:
      'Since your cough started, have you experienced any of the following?',
    description: 'More than one answer can be selected.',
    clinicLabel: 'Symptoms of sepsis',
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'Severe all over muscle pain',
        value: 'musclePain',
        keyFinding: 'Muscle pain all over',
      },
      {
        label: 'New Rash (one that does not fade when pressed)',
        value: 'newRash',
        keyFinding: 'New rash',
        warn: true,
        abort: aborts.sepsis,
      },
      {
        label: 'Abdominal / tummy pain',
        value: 'abdominalPain',
        keyFinding: 'Abdominal pain',
        warn: true,
        urgent: true,
        ds: coronaSeason ? ds.pvConsultAbdominalPain : ds.rejectAbdoPain,
        abort:
          coronaSeason || feverOver38 !== 'yes' ? undefined : aborts.sepsis,
      },
      {
        label: 'Diarrhoea or vomiting',
        value: 'diarrhoeaOrVomiting',
        keyFinding: 'Diarrhoea / Vomiting',
        urgent: true,
        warn: true,
        ds: coronaSeason ? ds.pvConsultDV : undefined,
        abort: coronaSeason ? undefined : aborts.sepsis,
      },
    ],
  });
  if (sepsisSymptoms !== undefined && sepsisSymptoms.abort) {
    return { abort: sepsisSymptoms.abort };
  }
  if (ensureArray(sepsisSymptoms).includes('abdominalPain')) {
    await unwellOutOfHoursWarning({
      ...args,
      id: 'cough.abdominalPain',
      label:
        'If you have abdominal pain it could be a sign of a severe infection.',
    });
  }
  if (ensureArray(sepsisSymptoms).includes('musclePain')) {
    const musclePain = await eChoice({
      id: 'cough.musclePain',
      label: 'Please describe your muscle pain.',
      clinicLabel: 'Description of severe muscle pain',
      options: [
        {
          label: 'I feel achy, but can manage',
          value: 'achy',
          keyFinding: 'Achey muscles',
        },
        {
          label:
            'I have severe muscle pain, and am struggling with day to day activities',
          value: 'severeMusclePain',
          keyFinding: 'Severe muscle pain affecting normal activities',
          warn: true,
          urgent: true,
          ds: coronaSeason
            ? ds.pvConsultSevereMusclePain
            : ds.rejectSevereMusclePain,
          abort:
            coronaSeason || feverOver38 !== 'yes' ? undefined : aborts.sepsis,
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
    });
    if (musclePain.abort) {
      return { abort: musclePain.abort };
    }

    if (musclePain === 'other') {
      await eText({
        id: 'cough.musclePainOther',
        label: 'Please describe your pain.',
        clinicLabel: 'Description of severe muscle pain',
        warn: true,
      });
    }
  }

  const fainted = await eChoice({
    id: 'cough.fainted',
    label: 'In the past 24 hours, have you fainted, or almost fainted?',
    clinicLabel: 'Fainting or collapse in the last 24hours',
    options: [
      {
        label: 'Feeling faint',
        value: 'feelingFaint',
        keyFinding: 'Feeling faint',
        warn: true,
        urgent: true,
        ds: coronaSeason
          ? ds.pvConsultBacterialLrtiFeelingFaint
          : ds.rejectFeelingFaint,
      },
      {
        label: 'Fainted / Lost consciousness',
        value: 'lostConsciousness',
        keyFinding: 'Pt has lost consciousness / fainted',
        warn: true,
        abort: aborts.emergency,
      },
      {
        label: 'No',
        value: 'no',
        hide: true,
      },
    ],
  });
  if (fainted.abort) {
    return { abort: fainted.abort };
  }

  await ask.info({
    description:
      'We are now going to ask you some general questions about your health...',
  });

  await moduleSmoking(args);
  await moduleAlcohol(args);
  await moduleAlternateMedication(args);
  await moduleAllergies(args);

  await eChoice({
    id: 'cough.pmh',
    label: `Do you have any of the following medical conditions?`,
    description: 'More than one answer can be selected.',
    clinicLabel: 'Past medical history',
    expires: 365,
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'Cancer',
        value: 'cancer',
        warn: true,
      },
      {
        label: 'Blood-borne virus (HIV, Hepatitis B, Hepatitis C)',
        value: 'bloodBorneVirus',
        warn: true,
      },
    ],
  });

  await modulePharmacy(args);
  await moduleEndMessage(args);

  collateDecisionSupport(decisionSupport);

  return {};
}: Guide);

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const healthRelativesCancer = {
    type: 'choice',
    id: 'health.relativesCancer',
    label:
      'Har någon av dina (biologiska) föräldrar eller syskon drabbats av cancer?',
    options: [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nej',
      },
      {
        value: 'dontknow',
        label: 'Vet ej',
      },
    ],
    expires: 900,
  };

  const healthRelativesCancerAnswer = await ask(healthRelativesCancer);

  significant.push({
    ...healthRelativesCancer,
    label: 'Förälder/syskon drabbad av cancer',
    value: healthRelativesCancerAnswer,
  });

  const healthRelativesCardiovascular = {
    type: 'choice',
    id: 'health.relativesCardiovascular',
    label:
      'Har någon av dina (biologiska) föräldrar eller syskon drabbats av hjärt-kärlsjukdom?',
    options: [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nej',
      },
      {
        value: 'dontknow',
        label: 'Vet ej',
      },
    ],
    expires: 900,
  };

  const healthRelativesCardiovascularAnswer = await ask(
    healthRelativesCardiovascular
  );

  significant.push({
    ...healthRelativesCardiovascular,
    label: 'Förälder/syskon drabbad av hjärt-kärlsjukdom',
    value: healthRelativesCardiovascularAnswer,
  });

  const healthRelativesDiabetes = {
    type: 'choice',
    id: 'health.relativesDiabetes',
    label:
      'Har någon av dina (biologiska) föräldrar eller syskon drabbats av diabetes?',
    options: [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nej',
      },
      {
        value: 'dontknow',
        label: 'Vet ej',
      },
    ],
    expires: 900,
  };

  const healthRelativesDiabetesAnswer = await ask(healthRelativesDiabetes);

  significant.push({
    ...healthRelativesDiabetes,
    label: 'Förälder/syskon drabbad av diabetes',
    value: healthRelativesDiabetesAnswer,
  });

  return {};
}: Guide);

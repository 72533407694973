import * as React from 'react';

import { _t } from '../../i18n';

import withStyles from '@material-ui/core/styles/withStyles';

import Next from '../components/Next';

class Info extends React.Component<{
  done: (?*) => *,
  classes: { [string]: string },
}> {
  kp = event => {
    const { charCode } = event;

    if (charCode === 13) {
      this.props.done('yes');
    }
  };

  componentDidMount() {
    window.document.addEventListener('keypress', this.kp);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keypress', this.kp);
  }

  render() {
    const { done, classes } = this.props;

    return (
      <div className={classes.root}>
        <Next label={_t('input.next')} onClick={() => done('yes')} />
      </div>
    );
  }
}

export default withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}))(Info);

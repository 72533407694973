import React from 'react';
import { navigate } from '@reach/router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import Body from '../components/Body';
import Page from '../components/Page';
import Frame from '../components/Frame';
import BackgroundColor from '../components/BackgroundColor';

import colors from '../theme/colors';

import logoSource from '../../images/logo/sad.svg';

const Logo = props => <img src={logoSource} alt="logo" {...props} />;

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: theme.spacing(2),
  },
  h2: {
    marginBottom: theme.spacing(2),
  },
  body1: {
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
}));

function NotFound(props: *) {
  const classes = useStyles(props);

  return (
    <Body>
      <BackgroundColor color={colors.grey_100} />

      <Page>
        <Frame>
          <Grid container>
            <Grid item xs={1}>
              <Logo className={classes.logo} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.h2}>
                Page not found.
              </Typography>

              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => navigate('/')}
              >
                Go to start
              </Button>
            </Grid>
          </Grid>
        </Frame>
      </Page>
    </Body>
  );
}

export default NotFound;

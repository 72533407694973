import * as React from 'react';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';

import logoSource from '../../images/logo/logo.svg';

class Logo extends React.Component<{
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { classes, className } = this.props;

    return (
      <img
        alt="logo"
        src={logoSource}
        className={classNames(classes.root, className)}
      />
    );
  }
}

export default withStyles(() => ({
  root: {
    height: 30,
  },
}))(Logo);

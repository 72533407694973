export default {
  persistent: {
    label: 'Persistent Sore Throat',
    color: 'black',
  },
  improving: {
    label: 'Sore throat — getting better',
    color: 'black',
  },
  unilateralPain: {
    label: 'Unilateral pain',
    description: 'Increased likelihood of bacterial cause',
    color: 'black',
  },
  reject: {
    label: 'Reject - Urgent Appointment',
    color: 'red',
  },
  feverPainScoreLow: {
    label: 'FeverPAIN Score 0-1',
    description: 'Advice only - self care',
  },
  feverPainScoreMedium: {
    label: 'FeverPAIN Score 2-3',
    description: 'Delayed prescription',
  },
  feverPainScoreHigh: {
    label: 'FeverPAIN Score 4-5',
    description: 'Antibiotics / Delayed prescription',
  },
};

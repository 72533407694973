import { type Request } from './types';

const baseUrl = '';

export const getApi = (url: string) => ajaxApi(url);

export const postApi = (url: string, body?: *) =>
  ajaxApi(url, { method: 'POST', body });

export const ajaxApi = async (
  url: string,
  request?: Request = {}
): Promise<{
  json?: *,
  error?: boolean,
}> => {
  let response;

  try {
    response = await fetch(
      `${request.baseUrl !== undefined ? request.baseUrl : baseUrl}/api${url}`,
      {
        method: request.method || 'GET',
        body: request.body ? JSON.stringify(request.body) : undefined,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          ...request.headers,
        },
      }
    );
  } catch (e) {
    console.error(e);
    return { error: true };
  }

  if (!response.ok || response.status !== 200) {
    console.error(response.status, response.statusText);
    return { error: true };
  }

  return { json: await response.json() };
};

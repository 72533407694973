import { type DecisionSupport } from '../types';

import { type Node, type Evaluator } from './types';

export const getDecisionSupport = (
  view: Node,
  evaluator: Evaluator
): DecisionSupport[] => {
  const decisionSupport = [];

  const process = node => {
    const { type, condition, children } = node;

    if (condition !== undefined && !evaluator(condition)) {
      return;
    }

    if (type === 'comment') {
      const { id, label, description } = node;

      decisionSupport.push({
        id,
        label,
        description,
        color: 'black',
      });
    } else if (children) {
      children.forEach(process);
    }
  };

  process(view);

  return decisionSupport;
};

import createExtendedChoice from '../common/extendedChoice';
import type { Guide } from '../../types';

import abort from '../common/commonAborts';
import { coronaSeason } from '../common/consts';
import ds from './decisionSupport';

export default (async args => {
  const { ask } = args;
  const eChoice = createExtendedChoice(args);
  const patient = args.patient;

  await eChoice({
    id: 'otherProblems.triage.notEmergency',
    label: 'Docly is not an emergency service.',
    description:
      'If your symptoms are severe, or you feel very unwell, call **111 for urgent care** or **999 for emergencies.**',
    options: [
      {
        label: 'I understand',
        value: 'understand',
        hide: true,
      },
    ],
  });

  await ask.info({
    description: `
Before we begin, we need to ask a few questions to make sure it is safe to treat you online.

After this, you will be able to tell us your problem in detail.
    `,
  });

  if (patient.gender === 'female' && patient.age < 55) {
    const pregnantOrBreastfeeding = await eChoice({
      id: 'otherProblems.triage.pregnantOrBreastfeeding',
      label: 'Are you pregnant or breastfeeding?',
      clinicLabel: 'Pregnant or breastfeeding',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          warn: true,
          abort: abort.adultPregnantOrBreastfeedingGp,
        },
        {
          label: 'No',
          value: 'no',
          hide: true,
        },
      ],
    });
    if (pregnantOrBreastfeeding.abort) {
      return { abort: pregnantOrBreastfeeding.abort };
    }
  }

  const symptoms = await eChoice({
    id: 'otherProblems.triage.symptoms',
    label: 'Do you have any of these symptoms?',
    clinicLabel: 'Symptoms',
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'Severe chest pain',
        value: 'severeChestPain',
        warn: true,
        abort: abort.adultChestPainAe,
      },
      {
        label: 'Severe breathing difficulty',
        value: 'severeBreathingDifficulty',
        warn: true,
        abort: abort.adultDifficultyBreathingAe,
      },
      {
        label: 'Severe Pain (unable to move or carry out daily activities)',
        value: 'severePain',
        warn: true,
        abort: abort.adultSeverePainAe,
        abortPriority: 1,
      },
    ],
  });
  if (symptoms.abort) {
    return { abort: symptoms.abort };
  }

  const mentalHealth = await eChoice({
    id: 'otherProblems.triage.mentalHealth',
    label: 'Is your problem to do with mental health (thoughts and feelings)?',
    clinicLabel: 'Mental health problem',
    options: [
      {
        value: 'yes',
        warn: true,
        abort: coronaSeason ? undefined : abort.adultMentalHealthGp,
        ds: ds.mentalHealthReview,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (mentalHealth.abort) {
    return { abort: mentalHealth.abort };
  }
  // included coronaSeason here even though it has to be true by this point so we review when it gets turned off.
  if (coronaSeason && mentalHealth === 'yes') {
    await ask.info({
      description: `
**Some people with low mood can sometimes reach dark places.** 

You need urgent medical attention if you feel like you may hurt yourself or try to end things. **Please go to the nearest A&E or dial 999**

If you are struggling with your mood, this can also affect your symptoms. You should either make an appointment to talk to your GP or use the Docly "Feeling anxious" or "Feeling unhappy" options to get more help.
    `,
    });
  }

  const immunosuppressed = await eChoice({
    id: 'otherProblems.triage.immunosuppressed',
    label:
      'Do you have a condition, or are you taking medication, that affects your immune system?',
    description:
      "E.g. methotrexate, current cancer, chemotherapy, renal dialysis, a spleen that doesn't work, HIV, organ transplant",
    clinicLabel: 'Immunosuppressed',
    options: [
      {
        value: 'yes',
        warn: true,
        abort: coronaSeason ? undefined : abort.adultImmunosuppressedGp,
        ds: ds.immunosuppresed,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (immunosuppressed.abort) {
    return { abort: immunosuppressed.abort };
  }

  return {};
}: Guide);

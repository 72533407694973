import * as React from 'react';
import classNames from 'classnames';

import colors from '../theme/colors';
import typography, { fontWeights } from '../theme/typography';

import { isPhone } from '../utils/device';

import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

const redoIconSource = require('../../images/icons/ic_redo_red.svg');

class PreviousLink extends React.Component<{
  label: string,
  onClick: () => *,
  classes: { [string]: string },
  className?: string,
}> {
  kp = event => {
    const { key, target } = event;
    const element = target.nodeName.toLowerCase();

    if (key === 'Backspace' && element !== 'input' && element !== 'textarea') {
      this.props.onClick();
    }
  };

  componentDidMount() {
    window.document.addEventListener('keyup', this.kp, false);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keyup', this.kp, false);
  }

  render() {
    const { label, onClick, classes, className } = this.props;

    return (
      <Button onClick={onClick} className={classNames(classes.root, className)}>
        <img alt="undo" src={redoIconSource} className={classes.icon} />

        {label}
      </Button>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    [down('xs')]: {
      paddingLeft: spacing(1.5),
      paddingRight: spacing(1.5),
    },
    '&:hover': {
      backgroundColor: isPhone ? 'transparent' : `${colors.red_500}15`,
    },
    color: colors.red_500,
    ...typography.body1,
    fontWeight: fontWeights.medium,
  },

  icon: {
    width: 21,
    marginRight: spacing(1.5),
    color: colors.red_500,
  },
}))(PreviousLink);

import type { Guide } from '../../types';

import chc from './chc';
import { decisionSupports, supply } from './decisionSupport';
import larc from './larc';
import { lowDosePills, popPills } from './pills';
import pop from './pop';
import sideEffects from './sideEffects';
import usingContraceptive from './usingContraceptive';
import { getContraceptiveBrandLabel } from './utils';

export default (async args => {
  const { ask, decisionSupport, significant } = args;

  const { using, brandName } = await usingContraceptive(args);

  let want = using;

  const available = ![
    'injection',
    'implant',
    'coil',
    'hormonalCoil',
    'other',
  ].includes(want);

  if (!available) {
    const { abort, want: alternative } = await larc(args);
    if (abort) return { abort };

    want = alternative;
  }

  await sideEffects(args);

  const durationQuestion = {
    type: 'choice',
    label: 'How long have you been on a break from this contraceptive?',
    options: {
      lessThan3Months: 'Less than 3 months',
      '3To12Months': '3 - 12 months',
      moreThan12Months: 'More than 12 months',
    },
  };
  const duration = await ask(durationQuestion);
  significant.push({ ...durationQuestion, value: duration });

  if (available) {
    let description;

    if (duration === 'lessThan3Months') {
      if (want === 'vaginalRing') {
        description = supply['3For12Repeat'];
      } else {
        description = supply['12Months'];
      }
    } else if (duration === '3To12Months') {
      if (popPills.includes(brandName)) {
        description = supply['6Months'];
      } else if (brandName === 'none') {
        description = supply['3To6Months'];
      } else {
        description = supply['3Months'];
      }
    } else {
      description = supply['6Months'];
    }

    if (['combined', 'progesterone', 'dontKnow'].includes(want)) {
      decisionSupport.push(
        decisionSupports.restartContraceptive(
          getContraceptiveBrandLabel(want, brandName),
          description
        )
      );
    } else if (want === 'skinPatch') {
      decisionSupport.push(
        decisionSupports.restartContraceptiveSkinPatch(description)
      );
    } else if (want === 'vaginalRing') {
      decisionSupport.push(
        decisionSupports.restartContraceptiveVaginalRing(description)
      );
    }
  }

  if (popPills.includes(brandName)) {
    return await pop(args);
  }

  return await chc({
    ...args,
    doctorChoose: want === 'doctorChoose',
    skinPatch: want === 'skinPatch',
    lowDose: lowDosePills.includes(brandName),
  });
}: Guide);

export default (name: string, script: string) => {
  const modules = {};
  const fn = `
      with(this) {
        var window = this.window || window;
        ${script};
      }
    `;
  new Function(fn).call({ window: modules }); // eslint-disable-line no-new-func

  return modules[name];
};

import type { Guide } from '../../types';

import triage from './triage';
import cradleCap from './cradleCap';
import nappyRash from './nappyRash';
import verruca from './verruca';
import acne from './acne';
import eczema from './eczema';
import generalRash from './generalRash';
import healthProfileChild from '../partials/healthProfileChild';
import informedConsentChild from '../partials/informedConsentChild';
import pharmacy from '../modules/pharmacy';
import summaryExports from './summaryExports';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const {
    ask,
    exports,
    patient: { age },
    significant,
  } = args;

  const informedConsentChildResult = await informedConsentChild(args);
  if (informedConsentChildResult.abort) {
    return informedConsentChildResult;
  }

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  await ask.info("Now we can ask more about your child's rash.");

  let options;
  if (age < 3) {
    options = [
      // { label: 'Cradle cap', value: 'cradleCap' }, Cradle cap section not used atm
      { label: 'Eczema or dry skin', value: 'eczema' },
      { label: 'Nappy rash', value: 'nappyRash' },
    ];
  } else if (age > 10) {
    options = [
      { label: 'Acne', value: 'acne' },
      { label: 'Eczema or dry skin', value: 'eczema' },
      { label: 'Verruca or wart', value: 'verrucaOrWart' },
    ];
  } else {
    options = [
      { label: 'Eczema or dry skin', value: 'eczema' },
      { label: 'Verruca or wart', value: 'verrucaOrWart' },
    ];
  }
  options.push({ label: 'None of the above', value: 'noneOfTheAbove' });

  const helplWithWhatSkinCondition = {
    type: 'choice',
    id: 'helplWithWhatSkinCondition',
    label: 'Would you like help for any of the following skin conditions?',
    description:
      'If your child has more than one problematic skin condition you need to open another case.',
    options,
    optional: true,
  };

  const helplWithWhatSkinConditionAnswer = await ask(
    helplWithWhatSkinCondition
  );

  if (helplWithWhatSkinConditionAnswer !== 'noneOfTheAbove') {
    significant.push({
      ...helplWithWhatSkinCondition,
      label: 'Would like help with',
      description: '',
      value: helplWithWhatSkinConditionAnswer,
    });

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        cradleCap: 'Would like help with cradle cap',
        nappyRash: 'Would like help with nappy rash',
        eczema: 'Would like help with eczema',
        acne: 'Would like help with acne',
        verrucaOrWart: 'Would like help with verruca',
      }[helplWithWhatSkinConditionAnswer],
    });
  }

  switch (helplWithWhatSkinConditionAnswer) {
    case 'cradleCap': {
      const { abort: cradleCapAbort } = await cradleCap(args);
      if (cradleCapAbort) {
        return { abort: cradleCapAbort };
      }
      break;
    }
    case 'nappyRash': {
      const { abort: nappyRashAbort } = await nappyRash(args);
      if (nappyRashAbort) {
        return { abort: nappyRashAbort };
      }
      break;
    }
    case 'eczema': {
      const { abort: eczemaAbort } = await eczema(args);
      if (eczemaAbort) {
        return { abort: eczemaAbort };
      }
      break;
    }
    case 'verrucaOrWart': {
      await verruca(args);
      break;
    }
    case 'acne': {
      await acne(args);
      break;
    }
    case 'noneOfTheAbove':
    default: {
      const { abort: generalRashAbort } = await generalRash(args);
      if (generalRashAbort) {
        return { abort: generalRashAbort };
      }
    }
  }

  const healthProfileChildResult = await healthProfileChild(args);
  if (healthProfileChildResult.abort) {
    return healthProfileChildResult;
  }

  await pharmacy(args);

  // CONSULTATION SUMMARY
  summaryExports(exports);

  return {};
}: Guide);

import type { Export } from '../../types';

export default (exports: Export[]) => {
  exports.push({ id: 'presentingComplaint', value: 'Skin rash' });
  exports.push({ id: 'snomedCode', value: '271807003 Eruption of skin' });
  exports.push({
    id: 'diagnosis.options',
    value: [
      'Cradle cap',
      'Nappy rash',
      'Eczema',
      'Infected eczema',
      'Verruca',
      'Acne',
      'Urticaria',
      'Contact dermatitis',
      'Impetigo',
    ],
  });
  exports.push({
    id: 'investigationsRequested.options',
    value: ['None', 'Blood test'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Self care advice',
      'Prescription and advice',
      'Routine GP appointment',
      'Urgent GP appointment',
      'Urgent care advised',
      'A&E advised',
    ],
  });
  exports.push({
    id: 'actionNeeded.options',
    value: [
      'None',
      'Review skin rash ?cellulitis',
      'Review skin rash ?infected eczema',
      'Review skin rash ?impetigo',
    ],
  });
};

import * as React from 'react';

import { _t } from '../../../i18n';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default class CancelModal extends React.Component<{
  open: boolean,
  close: () => *,
  cancel: () => *,
}> {
  render() {
    const { open, close, cancel } = this.props;

    return (
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
          <Typography variant="h2">{_t('guides.cancelModal.title')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{_t('guides.cancelModal.body')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} variant="outlined" color="secondary">
            {_t('guides.cancelModal.cancel')}
          </Button>
          <Button onClick={cancel} variant="contained" color="secondary">
            {_t('guides.cancelModal.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

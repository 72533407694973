import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import { ensureArray, matchOne } from '../common/arrayUtils';
import createExtendedText from '../common/extendedText';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const helpWhat = ensureArray(
    await eChoice({
      id: 'genericDemoGuide.helpWhat',
      label: 'How can we help you with your eczema?',
      description: 'More than one answer can be selected.',
      clinicLabel: 'Reason for consultation',
      isMultipleChoice: true,
      optional: true,
      options: [
        {
          label: 'More medication (repeat prescription)',
          value: 'renewPrescription',
          keyFinding: 'renewPrescription',
        },
        {
          label: 'I need medication that is not on my repeat prescription',
          value: 'newMedication',
          keyFinding: 'newMedication',
        },
        {
          label: 'Rash is worse or changed',
          value: 'worsenedOrChanged',
          keyFinding: 'worsenedOrChanged',
        },
        {
          label: 'Eczema looks infected',
          value: 'infected',
          keyFinding: 'infected',
          warn: true,
        },
        {
          label: 'Other',
          value: 'other',
          keyFinding: 'Other',
        },
      ],
    })
  );
  if (matchOne(helpWhat, ['other'])) {
    await eText({
      id: 'genericDemoGuide.helpOther',
      label:
        'Please tell us how else we can help with your eczemaWhat company do you work for?',
    });
  }

  await eChoice({
    id: 'genericDemoGuide.startedThisTime',
    label: 'When did the problem start this time?',
    clinicLabel: 'Duration',
    options: [
      {
        label: 'Less than 24 hours ago',
        value: 'lessThan24Hours',
      },
      {
        label: '1–2 days ago',
        value: '1to2Days',
      },
      {
        label: '3–7 days ago',
        value: '3to7Days',
      },
      {
        label: '1–2 weeks ago',
        value: '1to2Weeks',
      },
      {
        label: 'More than 2 weeks ago',
        value: 'moreThan2Weeks',
        warn: true,
      },
    ],
  });

  await eChoice({
    id: 'genericDemoGuide.rashChangedMatch',
    label: 'Has your rash worsened or changed?',
    clinicLabel: 'Duration',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        warn: true,
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  });

  await eChoice({
    id: 'genericDemoGuide.whereOptions',
    label: 'Where on your body is the eczema?',
    description: 'More than one answer can be selected.',
    clinicLabel: 'Location',
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'Face or neck',
        value: 'faceNeck',
        keyFinding: 'faceNeck',
      },
      {
        label: 'Scalp',
        value: 'scalp',
        keyFinding: 'scalp',
      },
      {
        label: 'Chest, stomach, back or buttocks',
        value: 'torsoButtocks',
        keyFinding: 'torsoButtocks',
      },
      {
        label: 'Arms, hands, legs or feet',
        value: 'limbs',
        keyFinding: 'limbs',
      },
      {
        label: 'Genitals or anal region',
        value: 'genitals',
        keyFinding: 'genitals',
      },
    ],
  });
  await eChoice({
    id: 'genericDemoGuide.rashDescription',
    label: 'Do any of the following describe your rash?',
    description: 'More than one answer can be selected.',
    clinicLabel: 'Description of rash',
    isMultipleChoice: true,
    optional: true,
    options: [
      {
        label: 'Raised',
        value: 'raised',
        keyFinding: 'raised',
      },
      {
        label: 'Itchy',
        value: 'itchy',
        keyFinding: 'itchy',
      },
      {
        label: 'Flaky',
        value: 'flaky',
        keyFinding: 'flaky',
      },
    ],
  });

  await eChoice({
    id: 'genericDemoGuide.healthFever',
    label: 'Have you had a fever in the past 48 hours?',
    clinicLabel: 'Temperature',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        warn: true,
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  });

  await eChoice({
    id: 'genericDemoGuide.treatmentCurrentMatch',
    label: 'Are you using anything to treat your eczema at the moment?',
    clinicLabel: 'Current treament',
    options: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  });

  return {};
}: Guide);

const safeWindow = typeof window === 'object' ? window : {};
const safeDocument = typeof document === 'object' ? document : {};
export const userAgent =
  (safeWindow.navigator && safeWindow.navigator.userAgent) || '';

export const isPhone = /Phone|Android(?=.*Mobile)/i.test(userAgent);
export const isTablet = /iPad|Android(?!.*Mobile)/i.test(userAgent);
export const isDesktop = !isPhone && !isTablet;

export const isIos =
  /iPad|iPhone|iPod/i.test(userAgent) && !safeWindow.MSStream;
const versionInfo = /OS (\d+)/.exec(userAgent);
export const iosVersion =
  versionInfo && versionInfo.length >= 2 ? versionInfo[1] : undefined;
export const isAndroid = /Android/i.test(userAgent);

export const isEdge = /Edge\//i.test(userAgent);
export const isIe =
  !isEdge &&
  (/MSIE/i.test(userAgent) ||
    (!!safeWindow.MSInputMethodContext && !!safeDocument.documentMode));
export const isChrome =
  !isEdge && (/Chrome/i.test(userAgent) || (isIos && /CriOS/i.test(userAgent)));
export const isFirefox =
  !isEdge &&
  (/Firefox/i.test(userAgent) || (isIos && /FxiOS/i.test(userAgent)));
export const isOpera = !isEdge && /Opera/i.test(userAgent);
export const isSafari =
  /Safari/i.test(userAgent) && !isEdge && !isChrome && !isFirefox;

// Currently only Internet Explorer is not supported.
export const isBrowserSupported = () => !isIe;

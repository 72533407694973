// @flow

// Helper function that adds clinicLabel, triage, decisionsupport as options to the ask functions in order to reduce manual checks.

import type {
  Ask,
  RawAnswer,
  DecisionSupport,
  Export,
  Abort,
  RawQuestion,
} from '../../types';
import addUrgentFlag from './addUrgentFlag';

export type ExtendedChoiceOption = {
  value: string | number,
  label?: string,
  abort?: Abort,
  abortPriority?: number,
  hide?: boolean,
  warn?: boolean,
  ds?: DecisionSupport,
  keyFinding?: string,
  onSelected?: (string | number) => void,
  urgent?: boolean,
};

export type ExtendedChoiceQuestion = {
  id?: string,
  label?: string,
  description?: string,
  optional?: boolean,
  expires?: number,
  isMultipleChoice?: boolean,
  options: ExtendedChoiceOption[],
  clinicLabel?: string,
};

type ExtendedChoice = {
  ask: Ask,
  significant: RawAnswer[],
  decisionSupport: DecisionSupport[],
  exports: Export[],
  question: ExtendedChoiceQuestion,
};

export const createQuestionToAsk = ({
  id,
  label,
  description,
  optional,
  expires,
  isMultipleChoice,
  options,
}: ExtendedChoiceQuestion): RawQuestion => {
  if (!Array.isArray(options)) {
    throw new Error('options must be specified');
  }

  // $FlowFixMe this is really not an issue.
  if (optional && !isMultipleChoice) {
    throw new Error(
      'optional is not allowed unless isMultipleChoice is also specified'
    );
  }

  const optionValues = options.map(o => o.value);
  const labelsPresent =
    options.map(o => o.label).filter(l => typeof l === 'string').length > 0;
  const isBinary =
    !labelsPresent &&
    options.length === 2 &&
    optionValues.includes('yes') &&
    optionValues.includes('no');
  const isTertiary =
    !labelsPresent &&
    options.length === 3 &&
    optionValues.includes('yes') &&
    optionValues.includes('no') &&
    optionValues.includes('unknown');

  const seen = {};
  options.forEach((o, i) => {
    if (typeof o.value !== 'string') {
      throw new Error(`the option at position ${i} has no string value`);
    }
    if (seen[o.value]) {
      throw new Error(
        `the option at position ${i} has the same value as another option`
      );
    }
    seen[o.value] = true;
  });

  // $FlowFixMe this is really not an issue.
  if (isMultipleChoice && (isBinary || isTertiary)) {
    throw new Error(
      'isMultipleChoice is not allowed for binary or tertiary questions'
    );
  }

  const questionType =
    isMultipleChoice === true
      ? 'multipleChoice'
      : isBinary
      ? 'binary'
      : isTertiary
      ? 'tertiary'
      : 'choice';

  const rawOptions = ['choice', 'multipleChoice'].includes(questionType)
    ? options.map(({ value, label }) => ({ value, label }))
    : undefined;

  return {
    id,
    type: questionType,
    label,
    description,
    optional,
    expires,
    options: rawOptions,
  };
};

export const handleExtendedAnswer = ({
  questionAsked,
  answer,
  significant,
  decisionSupport,
  exports,
  isMultipleChoice,
  clinicLabel,
  extendedOptions,
}: {
  questionAsked: RawQuestion,
  answer: *,
  significant: RawAnswer[],
  decisionSupport: DecisionSupport[],
  exports: Export[],
  isMultipleChoice?: boolean,
  clinicLabel?: string,
  extendedOptions: ExtendedChoiceOption[],
}): * => {
  const answers = typeof answer === 'string' ? [answer] : answer;

  // Don't add "none of above" answers to significant answers
  if (answers.length === 0) {
    return answer;
  }
  const selectedOptions = extendedOptions.filter(o => {
    return answers.find(answerValue => answerValue === o.value);
  });

  const significantAnswers = selectedOptions
    .filter(option => !option.hide)
    // create and group answers based on warning level
    .reduce((prev, curr) => {
      const answer = {
        type: questionAsked.type,
        label: clinicLabel === undefined ? questionAsked.label : clinicLabel,
        value: isMultipleChoice === true ? [curr.value] : curr.value,
        warn: curr.warn,
        options: questionAsked.options,
      };

      const prevAnswerIndex = prev.findIndex(
        prevAnswer => prevAnswer.warn === curr.warn
      );

      if (prevAnswerIndex > -1) {
        const prevAnswer = prev[prevAnswerIndex];
        const newValue = Array.isArray(prevAnswer.value)
          ? prevAnswer.value
          : [prevAnswer.value];
        newValue.push(curr.value);

        // append existing answer
        prev[prevAnswerIndex] = {
          ...answer,
          type: 'multipleChoice',
          value: newValue,
        };
      } else {
        // create new answer
        prev.push(answer);
      }
      return prev;
    }, []);

  significant.push(...significantAnswers);

  const selectedKeyFindings = extendedOptions.filter(o => {
    return answers.find(
      answerValue => answerValue === o.value && o.keyFinding !== undefined
    );
  });

  const keyFindings = selectedKeyFindings.map(option => {
    return {
      id: 'keyFindings',
      value: option.keyFinding || '', // Had to add fallback due to Flow issues, not sure why
    };
  });

  exports.push(...keyFindings);

  const dsOptions = extendedOptions.filter(o => {
    return answers.find(answerValue => answerValue === o.value && o.ds);
  });

  const dsAnswers = dsOptions.map(option => option.ds || { id: '' }); // Had to add fallback due to Flow issues, not sure why
  decisionSupport.push(...dsAnswers);

  const onSelectedOptions = extendedOptions.filter(o => {
    return answers.find(answerValue => answerValue === o.value && o.onSelected);
  });

  onSelectedOptions.forEach(option => {
    option.hasOwnProperty('onSelected') &&
      typeof option.onSelected === 'function' &&
      option.onSelected(option.value);
  });

  const urgentOption = selectedOptions.find(
    o => o.hasOwnProperty('urgent') && o.urgent === true
  );

  if (urgentOption) {
    addUrgentFlag(exports);
  }

  const abortOptions = selectedOptions.filter(o => o.abort !== undefined);

  if (abortOptions.length > 0) {
    abortOptions.forEach(abortOption => {
      if (abortOption.abort === undefined) {
        console.warn('Missing abort message for option ', abortOption);
      }
    });

    abortOptions.sort(
      (a, b) =>
        ((a && a.abortPriority) || Number.MAX_SAFE_INTEGER) -
        ((b && b.abortPriority) || Number.MAX_SAFE_INTEGER)
    );

    return { abort: abortOptions[0] && abortOptions[0].abort };
  }

  return answer;
};

const extendedChoice = async ({
  ask,
  significant,
  decisionSupport,
  exports,
  question,
}: ExtendedChoice) => {
  const questionToAsk = createQuestionToAsk(question);
  const answer = await ask(questionToAsk);

  return handleExtendedAnswer({
    questionAsked: questionToAsk,
    answer,
    significant,
    decisionSupport,
    exports,
    isMultipleChoice: question.isMultipleChoice,
    clinicLabel: question.clinicLabel,
    extendedOptions: question.options,
  });
};

export default ({
  ask,
  significant,
  decisionSupport,
  exports,
}: {
  ask: Ask,
  significant: RawAnswer[],
  decisionSupport: DecisionSupport[],
  exports: Export[],
}) => (question: ExtendedChoiceQuestion) =>
  extendedChoice({ ask, significant, decisionSupport, exports, question });

export default {
  id: 'asthma',
  version: '3',
  type: 'formulary',
  children: [
    {
      id: 'health.troubleBreathing',
      version: '7',
      type: 'choice',
      options: [
        {
          value: 'no',
          label: 'Nej',
        },
        {
          value: 'yesSomeTrouble',
          label: 'Ja, jag upplever lättare andningsbesvär.',
        },
        {
          value: 'yesTrouble',
          label: 'Ja, jag upplever andnöd/kvävningskänsla.',
        },
      ],
      validations: {
        required: true,
      },
      label: 'Har du svårt att andas just nu?',
      index: '0.0',
    },
    {
      id: 'asthma.diagnosis.doctor',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label: 'Har du fått diagnosen astma av en läkare?',
      index: '0.1',
    },
    {
      id: 'started',
      version: '37-asthma',
      type: 'choice',
      options: [
        {
          value: 'lessThenThreeMonths',
          label: 'För mindre än 3 månader sedan',
        },
        {
          value: 'threeToSixMonths',
          label: 'För 3–6 månader sedan',
        },
        {
          value: 'sixToTwelveMonths',
          label: 'För 6–12 månader sedan',
        },
        {
          value: 'oneToThreeYears',
          label: 'För 1–3 år sedan',
        },
        {
          value: 'moreThenThreeYears',
          label: 'För mer än 3 år sedan',
        },
      ],
      condition: "get('answers.asthma.diagnosis.doctor') === 'yes'",
      validations: {
        required: true,
      },
      label: 'När fick du diagnosen astma?',
      index: '0.2',
    },
    {
      id: 'health.lungDiseases.kol.match',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label: 'Har du lungsjukdomen KOL?',
      index: '0.3',
    },
    {
      id: 'health.immuneSystem.medication',
      version: '1',
      type: 'tertiary',
      validations: {
        required: true,
      },
      label:
        'Tar du kortison i tablettform eller något annat läkemedel som påverkar immunförsvaret som t.ex. Methotrexat?',
      index: '0.4',
    },
    {
      id: 'device.smartphone.match',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label: 'Har du en smartphone med tillgång till internet?',
      index: '0.5',
    },
    {
      id: 'asthma.soughtCare.yearlyCheckup',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label:
        'Går du på årlig kontroll eller har en fast läkarkontakt för din astma?',
      index: '0.6',
    },
    {
      id: 'medication.inhaler.acute.match',
      version: '2',
      type: 'binary',
      validations: {
        required: true,
      },
      label: 'Har du någon inhalator som du använder vid akuta besvär?',
      index: '0.7',
    },
    {
      id: 'medication.inhaler.match',
      version: '1',
      type: 'binary',
      validations: {
        required: true,
      },
      label:
        'Har du någon inhalator som du, i perioder, använder dagligen för att förebygga din astma?',
      index: '0.8',
    },
    {
      id: 'medication.picture.inhaler',
      type: 'upload',
      version: '1',
      validations: {
        required: false,
      },
      label:
        'Du har nu möjlighet att bifoga en eller flera bilder på din/dina inhalatorer som du använder, både i förebyggande syfte och vid akuta besvär.',
      description:
        '  <p>\n    Det är bra om läkemedlets namn och styrka samt texten på etiketten visas på bilden. Detta hjälper läkaren att göra en korrekt bedömning.\n  </p>',
      index: '0.9',
    },
    {
      id: 'asthma.soughtCare.emergency.match',
      version: '2',
      type: 'binary',
      validations: {
        required: true,
      },
      label:
        'Har du under det senaste året behövt uppsöka akut vård pga din astma?',
      index: '0.10',
    },
    {
      id: 'asthma.aktInfo',
      version: '1',
      type: 'info',
      validations: {
        required: false,
      },
      description:
        'Nu följer ett antal frågor gällande hur du har upplevt din astma de senaste fyra veckorna.',
      index: '0.11',
    },
    {
      id: 'asthma.akt.1',
      version: '1',
      type: 'choice',
      options: [
        {
          value: 1,
          label: 'Hela tiden',
        },
        {
          value: 2,
          label: 'En större del av tiden',
        },
        {
          value: 3,
          label: 'En del av tiden',
        },
        {
          value: 4,
          label: 'En mindre del av tiden',
        },
        {
          value: 5,
          label: 'Ingen del av tiden',
        },
      ],
      validations: {
        required: true,
      },
      label:
        'Under de senaste 4 veckorna, hur stor del av tiden har du hindrats av din astma från att utföra dina normala aktiviteter på arbetet, i skolan eller hemma?',
      index: '0.12',
    },
    {
      id: 'asthma.akt.2',
      version: '1',
      type: 'choice',
      options: [
        {
          value: 1,
          label: 'Mer än en gång per dag',
        },
        {
          value: 2,
          label: 'En gång om dagen',
        },
        {
          value: 3,
          label: '3 till 6 gånger i veckan',
        },
        {
          value: 4,
          label: 'En eller två gånger i veckan',
        },
        {
          value: 5,
          label: 'Inte alls',
        },
      ],
      validations: {
        required: true,
      },
      label:
        'Under de senaste 4 veckorna, hur ofta har du varit andfådd/upplevt andnöd?',
      index: '0.13',
    },
    {
      id: 'asthma.akt.3',
      version: '1',
      type: 'choice',
      options: [
        {
          value: 1,
          label: '4 eller fler nätter i veckan',
        },
        {
          value: 2,
          label: '2 till 3 nätter i veckan',
        },
        {
          value: 3,
          label: 'En gång i veckan',
        },
        {
          value: 4,
          label: 'En eller ett par gånger',
        },
        {
          value: 5,
          label: 'Inte alls',
        },
      ],
      validations: {
        required: true,
      },
      label:
        'Under de senaste 4 veckorna, hur ofta har du vaknat av dina astmasymtom under natten eller tidigare än vanligt på morgonen?',
      description:
        't.ex med väsande andning, hosta, andfåddhet/andnöd, täthetskänsla eller värk i bröstet',
      index: '0.14',
    },
    {
      id: 'asthma.akt.4',
      version: '1',
      type: 'choice',
      options: [
        {
          value: 1,
          label: '3 eller fler gånger per dag',
        },
        {
          value: 2,
          label: '1 eller 2 gånger per dag',
        },
        {
          value: 3,
          label: '2 eller 3 gånger per vecka',
        },
        {
          value: 4,
          label: 'En gång i veckan eller mer sällan',
        },
        {
          value: 5,
          label: 'Inte alls',
        },
      ],
      validations: {
        required: true,
      },
      label:
        'Under de senaste 4 veckorna, hur ofta har du använt extrados av snabbverkande luftrörsvidgare?',
      description:
        't.ex. Bricanyl®, Buventol®, Ventoline®, Oxis®, Symbicort®, Innovair®',
      index: '0.15',
    },
    {
      id: 'asthma.akt.5',
      version: '1',
      type: 'choice',
      options: [
        {
          value: 1,
          label: 'Inte alls kontrollerad',
        },
        {
          value: 2,
          label: 'Dåligt kontrollerad',
        },
        {
          value: 3,
          label: 'Till viss del kontrollerad',
        },
        {
          value: 4,
          label: 'Väl kontrollerad',
        },
        {
          value: 5,
          label: 'Helt kontrollerad',
        },
      ],
      validations: {
        required: true,
      },
      label:
        'Hur skulle du bedöma din astmakontroll under de senaste 4 veckorna?',
      index: '0.16',
    },
    {
      id: 'other',
      type: 'text',
      validations: {
        required: false,
      },
      version: '1',
      label: 'Övrig medicinsk information',
      description:
        'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
      index: '0.17',
    },
  ],
  variables: [
    {
      id: 'asthma.akt',
      type: 'number',
      expression:
        "Number(get('answers.asthma.akt.1')) + Number(get('answers.asthma.akt.2')) + Number(get('answers.asthma.akt.3')) + Number(get('answers.asthma.akt.4')) + Number(get('answers.asthma.akt.5'))",
      relativeId: 'akt',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'asthma.troubleBreathing',
      type: 'abort',
      expression: "get('answers.health.troubleBreathing') === 'yesTrouble'",
      relativeId: 'troubleBreathing',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'asthma.noAsthmaDiagnosis',
      type: 'abort',
      expression: "get('answers.asthma.diagnosis.doctor') === 'no'",
      relativeId: 'noAsthmaDiagnosis',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'asthma.lungDiseasesKol',
      type: 'abort',
      expression: "get('answers.health.lungDiseases.kol.match') === 'yes'",
      relativeId: 'lungDiseasesKol',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'asthma.smartphoneAccess',
      type: 'abort',
      expression: "get('answers.device.smartphone.match') === 'no'",
      relativeId: 'smartphoneAccess',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'patientWarrning',
          type: 'comment',
          version: '1',
          validations: {
            required: true,
          },
          label: 'VARNING',
          description:
            'Observera patientens hälsoprofil angående rökning, hjärt- & kärlsjukdom och lungsjukdom som komplement till om denna patient passar att få vård digitalt för sin astma.',
        },
        {
          id: 'health.troubleBreathing',
          warning:
            "any(get('answers.health.troubleBreathing'), 'yesSomeTrouble', 'yesTrouble')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andningssvårigheter',
          description: '',
          options: [
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'yesSomeTrouble',
              label: 'Ja, jag upplever lättare andningsbesvär.',
            },
            {
              value: 'yesTrouble',
              label: 'Ja, jag upplever andnöd/kvävningskänsla.',
            },
          ],
        },
        {
          id: 'asthma.diagnosis.doctor',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Astmadiagnos av läkare',
          description: '',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'När började astman',
          description: '',
          options: [
            {
              value: 'lessThenThreeMonths',
              label: 'För mindre än 3 månader sedan',
            },
            {
              value: 'threeToSixMonths',
              label: 'För 3–6 månader sedan',
            },
            {
              value: 'sixToTwelveMonths',
              label: 'För 6–12 månader sedan',
            },
            {
              value: 'oneToThreeYears',
              label: 'För 1–3 år sedan',
            },
            {
              value: 'moreThenThreeYears',
              label: 'För mer än 3 år sedan',
            },
          ],
        },
        {
          id: 'health.lungDiseases.kol.match',
          warning: "get('answers.health.lungDiseases.kol.match') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'KOL',
          description: '',
        },
        {
          id: 'health.immuneSystem.medication',
          warning: "get('answers.health.immuneSystem.medication') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kortisonbehandling',
          description: '',
        },
        {
          id: 'device.smartphone.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smartphone',
          description: '',
        },
        {
          id: 'asthma.soughtCare.yearlyCheckup',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Årlig kontroll eller fast läkarkontakt',
          description: '',
        },
        {
          id: 'medication.inhaler.acute.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Inhalator vid akuta besvär',
          description: '',
        },
        {
          id: 'medication.inhaler.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Använder inhalator dagligen i perioder',
          description: '',
        },
        {
          id: 'medication.picture.inhaler',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Du har nu möjlighet att bifoga en eller flera bilder på din/dina inhalatorer som du använder, både i förebyggande syfte och vid akuta besvär.',
          description:
            '  <p>\n    Det är bra om läkemedlets namn och styrka samt texten på etiketten visas på bilden. Detta hjälper läkaren att göra en korrekt bedömning.\n  </p>',
        },
        {
          id: 'asthma.soughtCare.emergency.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sökt akut sjukvård senaste året pga astma',
          description: '',
        },
        {
          id: 'asthma.akt.1',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Senaste fyra veckorna hindrats att utföra dagliga aktiviteter',
          description: '',
          options: [
            {
              value: 1,
              label: 'Hela tiden',
            },
            {
              value: 2,
              label: 'En större del av tiden',
            },
            {
              value: 3,
              label: 'En del av tiden',
            },
            {
              value: 4,
              label: 'En mindre del av tiden',
            },
            {
              value: 5,
              label: 'Ingen del av tiden',
            },
          ],
        },
        {
          id: 'asthma.akt.2',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Senaste fyra veckorna varit andfådd/upplevt andnöd',
          description: '',
          options: [
            {
              value: 1,
              label: 'Mer än en gång per dag',
            },
            {
              value: 2,
              label: 'En gång om dagen',
            },
            {
              value: 3,
              label: '3 till 6 gånger i veckan',
            },
            {
              value: 4,
              label: 'En eller två gånger i veckan',
            },
            {
              value: 5,
              label: 'Inte alls',
            },
          ],
        },
        {
          id: 'asthma.akt.3',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Senaste fyra veckorna vaknat av astmasymptom',
          description: '',
          options: [
            {
              value: 1,
              label: '4 eller fler nätter i veckan',
            },
            {
              value: 2,
              label: '2 till 3 nätter i veckan',
            },
            {
              value: 3,
              label: 'En gång i veckan',
            },
            {
              value: 4,
              label: 'En eller ett par gånger',
            },
            {
              value: 5,
              label: 'Inte alls',
            },
          ],
        },
        {
          id: 'asthma.akt.4',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Senaste fyra veckorna - hur ofta använt extrados av snabbverkande luftrörsvidgande',
          description: '',
          options: [
            {
              value: 1,
              label: '3 eller fler gånger per dag',
            },
            {
              value: 2,
              label: '1 eller 2 gånger per dag',
            },
            {
              value: 3,
              label: '2 eller 3 gånger per vecka',
            },
            {
              value: 4,
              label: 'En gång i veckan eller mer sällan',
            },
            {
              value: 5,
              label: 'Inte alls',
            },
          ],
        },
        {
          id: 'asthma.akt.5',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Pat bedömning av astmakontroll de senaste fyra veckorna',
          description: '',
          options: [
            {
              value: 1,
              label: 'Inte alls kontrollerad',
            },
            {
              value: 2,
              label: 'Dåligt kontrollerad',
            },
            {
              value: 3,
              label: 'Till viss del kontrollerad',
            },
            {
              value: 4,
              label: 'Väl kontrollerad',
            },
            {
              value: 5,
              label: 'Helt kontrollerad',
            },
          ],
        },
        {
          id: 'result.akt',
          type: 'text',
          condition: "get('variables.asthma.akt') !== undefined",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Summa AKT',
          template:
            "(function() { var sum = get('variables.asthma.akt'); if (sum < 20) { return sum + ' - Astma inte under kontroll'; } if (sum <= 24) { return sum + ' - Astma inte helt kontrollerad'; } return sum + ' - Välkontrollerad astma'; })()",
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Annan information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
    decisionSupport: {
      children: [
        {
          id: 'healthImmuneSystemMedicationYes',
          type: 'comment',
          condition:
            "get('answers.health.immuneSystem.medication') === 'yes' || get('answers.health.immuneSystem.medication') === 'unknown'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Immunreglerande läkemedel',
          description:
            'Fördjupad anamnes krävs med hänsyn till fortsatt möjlighet att följas via Min Doktor pga astma.',
        },
        {
          id: 'asthmaSoughtCareYearlyCheckupYes',
          type: 'comment',
          condition: "get('answers.asthma.soughtCare.yearlyCheckup') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Möjlig patient att följa via Min Doktor',
          description:
            'Då patienten svarat att hen har årlig kontroll eller har en fast läkarkontakt för sin astma kan denna patient följas via oss istället. Välj meddelandemall med info om astmautbudet på Min Doktor.',
        },
        {
          id: 'aktUnder20',
          type: 'comment',
          condition: "get('variables.asthma.akt') < 19.5",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Astma inte under kontroll',
          description:
            'Astman verkar inte ha varit under kontroll de senaste fyra veckorna. Rekommendera en åtgärdsplan som kan hjälpa patienten att få bättre kontroll över astman.',
        },
        {
          id: 'akt20to24',
          type: 'comment',
          condition:
            "get('variables.asthma.akt') >= 19.5 && get('variables.asthma.akt') < 24.5",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Astma inte helt kontrollerad',
          description:
            'Välkontrollerad men inte helt kontrollerad astma under de senaste fyra veckorna',
        },
        {
          id: 'akt25',
          type: 'comment',
          condition: "get('variables.asthma.akt') >= 24.5",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Välkontrollerad astma',
          description: 'Total kontroll över astman de senaste fyra veckorna',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Astma',
  index: '0',
};

import type { Guide } from '../../../types';

export default (async args => {
  const {
    ask,
    significant,
    patient: { gender },
  } = args;

  const alcoholLabel = 'Hur mycket alkohol dricker du?';
  const alcoholDescription =
    'Ett standardglas motsvarar ett glas vin, en flaska starköl (33 cl) eller 4 cl starksprit.';

  if (gender === 'female') {
    const alcoholAmountFemale = {
      type: 'choice',
      id: 'alcohol.amountFemale',
      label: alcoholLabel,
      description: alcoholDescription,
      options: [
        {
          value: 'more',
          label: '9 standardglas i veckan eller mer',
        },
        {
          value: 'less',
          label: 'Mindre än 9 standardglas i veckan',
        },
        {
          value: 'none',
          label: 'Jag dricker inte alkohol',
        },
      ],
      expires: 180,
    };

    const alcoholAmountFemaleAnswer = await ask(alcoholAmountFemale);

    significant.push({
      ...alcoholAmountFemale,
      label: 'Alkoholkonsumtion',
      description: '',
      value: alcoholAmountFemaleAnswer,
    });
  }

  if (gender === 'male') {
    const alcoholAmountMale = {
      type: 'choice',
      id: 'alcohol.amountMale',
      label: alcoholLabel,
      description: alcoholDescription,
      options: [
        {
          value: 'more',
          label: '14 standardglas i veckan eller mer',
        },
        {
          value: 'less',
          label: 'Mindre än 14 standardglas i veckan',
        },
        {
          value: 'none',
          label: 'Jag dricker inte alkohol',
        },
      ],
      expires: 180,
    };

    const alcoholAmountMaleAnswer = await ask(alcoholAmountMale);

    significant.push({
      ...alcoholAmountMale,
      label: 'Alkoholkonsumtion',
      description: '',
      value: alcoholAmountMaleAnswer,
    });
  }

  return {};
}: Guide);

import type { Guide } from '../../../types';

export default (async args => {
  const { ask, significant } = args;

  const breakfast = {
    type: 'choice',
    id: 'healthCheck.nutrition.breakfast',
    label: 'Hur ofta äter du frukost?',
    options: [
      { value: 'onceAWeek', label: 'En gång/vecka eller mer sällan' },
      { value: 'fewTimeAWeek', label: 'Några gånger/vecka' },
      { value: 'almostEveryDay', label: 'Nästan varje dag' },
      { value: 'daily', label: 'Dagligen' },
    ],
  };

  const breakfastAnswer = await ask(breakfast);

  significant.push({
    ...breakfast,
    label: 'Äter frukost',
    value: breakfastAnswer,
  });

  const vegetables = {
    type: 'choice',
    id: 'healthCheck.nutrition.vegetables',
    label:
      'Hur ofta äter du grönsaker, rotfrukter, frukt eller bär (färska / frysta / tillagade)?',
    options: [
      { value: 'onceAWeek', label: 'En gång/vecka eller mer sällan' },
      { value: 'fewTimeAWeek', label: 'Några gånger/vecka' },
      { value: 'onceADay', label: 'En gång per dag' },
      { value: 'twiceADay', label: 'Två gånger/dag eller oftare' },
    ],
  };

  const vegetablesAnswer = await ask(vegetables);

  significant.push({
    ...vegetables,
    label: 'Äter grönsaker, rotfrukter, frukt eller bär',
    value: vegetablesAnswer,
  });

  const fish = {
    type: 'choice',
    id: 'healthCheck.nutrition.fish',
    label:
      'Hur ofta äter du fisk eller skaldjur? (som huvudrätt, i sallader, som pålägg etc)?',
    options: [
      { value: 'onceAWeek', label: 'En gång/vecka eller mer sällan' },
      { value: 'fewTimeAWeek', label: 'Några gånger/vecka' },
      { value: 'onceADay', label: 'En gång per dag' },
      { value: 'twiceADay', label: 'Två gånger/dag eller oftare' },
    ],
  };

  const fishAnswer = await ask(fish);

  significant.push({
    ...fish,
    label: 'Äter fisk eller skaldjur',
    value: fishAnswer,
  });

  return {};
}: Guide);

import isEqual from 'lodash/isEqual';

import { type Guide } from '../types';

import { getAbort } from './abort';
import { getDecisionSupport } from './decisionSupport';
import evaluators from './evaluators';
import { getPrescriptionWarnings } from './prescriptionWarnings';
import { getSignificant } from './significant';
import transforms from './transforms';
import { type Formulary, type Question } from './types';
import { isQuestion, isPredefined, toGuideQuestion } from './utils';

export default (formulary: Formulary): Guide => async ({
  ask,
  patient: { gender, age },
  decisionSupport,
  significant,
  exports,
}) => {
  const answers = {
    'predefined.gender': gender,
    'predefined.age': age,
  };

  const questions = [];

  const computeVariables = evaluator => {
    const values = {};

    (formulary.variables || []).forEach(node => {
      if (node.id !== undefined) {
        values[node.id || ''] = transforms(node)(evaluator(node.expression));
      }
    });

    return values;
  };

  const createEvaluator = () => {
    let prevVariables;
    let variables;

    while (variables === undefined || !isEqual(variables, prevVariables)) {
      prevVariables = variables;
      variables = computeVariables(evaluators({ answers, variables }));
    }

    return evaluators({ answers, variables });
  };

  const visit = async node => {
    const { condition, children } = node;

    if (isPredefined(node)) {
      return undefined;
    }

    const evaluator = createEvaluator();

    if (condition !== undefined && !evaluator(condition)) {
      return undefined;
    }

    if (isQuestion(node)) {
      let question: Question =
        // $FlowFixMe
        node.type === 'confirm'
          ? {
              ...node,
              type: 'multipleChoice',
              label: undefined,
              options: [{ label: node.label, value: 'yes' }],
              validations: { required: true },
            }
          : node;

      const { id } = question;

      questions.push(question);

      answers[id] = transforms(question)(
        await ask(
          toGuideQuestion(
            question,
            question.options &&
              question.options.filter(
                ({ condition }) =>
                  condition === undefined || !!evaluator(condition)
              )
          )
        )
      );

      const abort = getAbort(
        formulary.abort,
        formulary.variables || [],
        createEvaluator()
      );
      if (abort) {
        return abort;
      }
    } else if (children !== undefined) {
      for (let i = 0; i < children.length; i++) {
        const abort = await visit(children[i]);
        if (abort) {
          return abort;
        }
      }
    }

    return undefined;
  };

  const abort = await visit(formulary);
  if (abort) {
    return { abort };
  }

  const { views } = formulary;

  if (views) {
    const evaluator = createEvaluator();

    if (views.decisionSupport) {
      decisionSupport.push(
        ...getDecisionSupport(views.decisionSupport, evaluator)
      );
    }

    if (views.clinic) {
      significant.push(
        ...getSignificant(
          views.clinic,
          evaluator,
          questions,
          formulary.variables || [],
          answers
        )
      );
    }

    if (views.prescriptionwarnings) {
      const warnings = getPrescriptionWarnings(
        views.prescriptionwarnings,
        evaluator,
        questions,
        formulary.variables || [],
        answers
      );

      if (warnings.length > 0) {
        exports.push({
          id: 'prescriptionWarnings',
          value: warnings,
        });
      }
    }
  }

  return {};
};

import createExtendedChoice from '../common/extendedChoice';

import type { Guide } from '../../types';

export default (async args => {
  const eChoice = createExtendedChoice(args);

  await eChoice({
    id: 'asthmaReview.qof.difficultySleeping',
    label: `In the past month, how often have you had difficulty sleeping because of your asthma symptoms (including cough)?`,
    clinicLabel: 'QOF - Difficulty sleeping',
    options: [
      {
        label: 'Daily',
        value: 'daily',
        keyFinding: 'Asthma symptoms affecting sleep daily',
      },
      {
        label: '1-2 times a week',
        value: '1To2TimesAWeek',
        keyFinding: 'Asthma symptoms affecting sleep 1-2 times a week',
      },
      {
        label: '1-2 times a month',
        value: '1To2TimesAMonth',
        keyFinding: 'Asthma symptoms affecting sleep 1-2 times a month',
      },
      {
        label: 'Never',
        value: 'never',
        keyFinding: 'Asthma symptoms not affecting sleep',
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.qof.daytimeSymptoms',
    label: `In the past month, how often have you had your usual asthma symptoms (cough, wheeze, chest tightness or breathlessness) during the day?`,
    clinicLabel: 'QOF - Daytime asthma symptoms',
    options: [
      {
        label: 'Daily',
        value: 'daily',
        keyFinding: 'QOF - Asthma symptoms during the day daily',
      },
      {
        label: '1-2 times a week',
        value: '1To2TimesAWeek',
        keyFinding: 'QOF - Asthma symptoms during the day 1-2 times a week',
      },
      {
        label: '1-2 times a month',
        value: '1To2TimesAMonth',
        keyFinding: 'QOF - Asthma symptoms during the day 1-2 times a month',
      },
      {
        label: 'Never',
        value: 'never',
        keyFinding: 'QOF - No asthma symptoms during the day',
      },
    ],
  });

  await eChoice({
    id: 'asthmaReview.qof.activity',
    label: `In the past month, how often has your asthma interfered with your usual activities (e.g. housework, work, school, etc.)?`,
    clinicLabel: 'QOF - Asthma affecting activities',
    options: [
      {
        label: 'Sometimes restricts exercise',
        value: 'sometimesRestrictsExercise',
        keyFinding: 'QOF - Asthma sometimes restricts exercise',
      },
      {
        label: 'Severely restricts exercise',
        value: 'severelyRestrictsExercise',
        keyFinding: 'QOF - Asthma severely restricts exercise',
      },
      {
        label: 'Limits walking up hills or stairs',
        value: 'limitsWalkingUpHillsOrStairs',
        keyFinding: 'QOF - Asthma limits walking uphills/stairs',
      },
      {
        label: 'Limits walking on the flat',
        value: 'limitsWalkingOnTheFlat',
        keyFinding: 'QOF - Asthma limits walking on the flat',
      },
      {
        label: 'Never',
        value: 'never',
        keyFinding: 'QOF - Asthma not interfering with usual activities',
      },
    ],
  });

  return {};
}: Guide);

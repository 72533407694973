import React, { useState } from 'react';

import { _t } from '../../i18n';

import { type Patient } from '../../guides/types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

type Props = {
  open?: boolean,
  patient: Patient,
  save: Patient => *,
  close: () => void,
  classes?: { [string]: string },
};

const useStyles = makeStyles(theme => ({
  section: {
    display: 'block',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

function PatientModal(props: Props) {
  const { open, close, patient, save } = props;
  const [gender, setGender] = useState(patient.gender);
  const [age, setAge] = useState(patient.age);
  const [domain, setDomain] = useState(patient.domain);

  const classes = useStyles(props);

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h2">{_t('patient.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl className={classes.section}>
          <FormLabel>{_t('patient.gender')}</FormLabel>
          <RadioGroup
            value={gender}
            onChange={({ target: { value } }) => setGender(value)}
          >
            <FormControlLabel
              value="female"
              control={<Radio color="primary" />}
              label={_t('patient.female')}
            />
            <FormControlLabel
              value="male"
              control={<Radio color="primary" />}
              label={_t('patient.male')}
            />
          </RadioGroup>
        </FormControl>
        <TextField
          type="number"
          variant="filled"
          label={_t('patient.age')}
          fullWidth
          className={classes.section}
          value={age === 0 ? '' : age}
          onChange={({ target: { value } }) =>
            setAge(isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10))
          }
        />

        <TextField
          variant="filled"
          label={_t('patient.domain')}
          fullWidth
          className={classes.section}
          value={domain === undefined ? '' : domain}
          onChange={({ target: { value } }) => setDomain(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary">
          {_t('patient.cancel')}
        </Button>
        <Button
          onClick={() => save({ gender, age: Number(age), domain })}
          variant="contained"
          color="secondary"
          disabled={age === 0}
        >
          {_t('patient.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PatientModal;

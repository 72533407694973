import React, { useState, useCallback, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import colors from '../theme/colors';
import { getFileData, isVideo } from '../utils/files';
import VideoPreview from './VideoPreview';

const photoIconSource = require('../../images/icons/ic_photo.svg');

type Props = {
  values?: string[],
  classes?: { [string]: string },
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginLeft: -theme.spacing(),
    marginRight: -theme.spacing(),
    marginBottom: -theme.spacing(),
  },

  image: {
    maxWidth: 180,
    maxHeight: 100,
    borderRadius: 4,
    margin: theme.spacing(),
  },

  placeholder: {
    width: 180,
    height: 100,
    margin: theme.spacing(),
    display: 'flex',
    backgroundColor: colors.grey_100,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },

  placeholderIcon: {
    height: 40,
    width: 40,
    marginBottom: theme.spacing(),
  },
}));

function Files(props: Props) {
  const [files, setFiles] = useState({});
  const { values: values = [] } = props;

  const classes = useStyles(props);

  const load = useCallback(
    async (values = []) => {
      // Prevent memory leak for promises
      let isSubscribed = true;

      const updates = await Promise.all(
        values.map(path => {
          if (files[path] || !isSubscribed) {
            return { path, value: undefined };
          }
          return getFileData(path)
            .then(data => ({ path, value: { data } }))
            .catch(error => ({ path, value: { error: error.message } }));
        })
      );
      setFiles(
        updates.reduce(
          (map, { path, value }) => ({
            ...map,
            [path]: value,
          }),
          { ...files }
        )
      );

      return () => (isSubscribed = false);
    },
    [files]
  );

  useEffect(() => {
    load(values);
  }, [values, load]);

  return (
    <div className={classes.root}>
      {values.map(value => {
        const file = files[value];

        if (file === undefined || file.error !== undefined) {
          return null;
        }

        const fileData = file.data;

        return fileData ? (
          isVideo(fileData) ? (
            <VideoPreview key={value} alt={value} src={fileData} />
          ) : (
            <img
              key={value}
              alt={value}
              src={fileData}
              className={classes.image}
            />
          )
        ) : (
          <div key={value} className={classes.placeholder}>
            <img
              alt="icon"
              src={photoIconSource}
              className={classes.placeholderIcon}
            />
            <Typography variant="body2">{value}</Typography>
          </div>
        );
      })}
    </div>
  );
}

export default Files;

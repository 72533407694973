export const lowDosePills = ['Loestrin 20'];

export const cocpPills = [
  'Microgynon 30',
  'Rigevidon',
  'Ovranette',
  'Yasmin',
  'Cilest',
  ...lowDosePills,
];

export const popPills = ['Cerazette', 'Cerelle', 'Noriday', 'Norgeston'];

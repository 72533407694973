import type { Guide } from '../../types';

import aborts from './aborts';

export default (async args => {
  const {
    ask,
    patient: { age, gender },
    significant,
  } = args;

  await ask.info(
    'Before we begin, we need to ask a few questions to make sure it is safe to treat you online...'
  );

  if (gender === 'female' && age < 55) {
    const pregnant = await ask('Are you pregnant?', {
      id: 'triage.pregnant',
    });
    if (pregnant === 'yes') {
      significant.push({
        label: 'Pregnant',
        value: pregnant,
        warn: true,
      });
      return { abort: aborts.pregnantOrBreastfeeding };
    }

    const breastfeeding = await ask('Are you breastfeeding?', {
      id: 'triage.breastfeeding',
    });
    if (breastfeeding === 'yes') {
      significant.push({
        label: 'Breastfeeding',
        value: breastfeeding,
        warn: true,
      });
      return { abort: aborts.pregnantOrBreastfeeding };
    }
  }

  const immuneCondition = await ask(
    'Do you have a condition, or are you taking medication, that affects your immune system?',
    {
      id: 'triage.immuneCondition',
      description: `E.g. methotrexate, current cancer, chemotherapy, renal dialysis, a spleen that doesn't work, HIV or organ transplant.`,
    }
  );
  if (immuneCondition === 'yes') {
    significant.push({
      label: 'Immune condition',
      value: immuneCondition,
      warn: true,
    });
    return { abort: aborts.immuneCondition };
  }

  const conditionsQuestion = {
    id: 'triage.conditions',
    type: 'multipleChoice',
    options: {
      gallstones: 'Gallstones',
      pancreatitis: 'Pancreatitis',
      barretsOesophagus: `Barrett's Oesophagus`,
      perniciousAnaemia: 'Pernicious Anaemia',
      crohnsDisease: `Crohn's Disease`,
      previousCurrentCancer: 'Previous or current cancer',
      previousStomachSurgery: 'Previous stomach (not bowel) surgery',
      previousStomachUlcer: 'Previous stomach ulcer',
    },
    optional: true,
  };
  const conditions = await ask({
    ...conditionsQuestion,
    label: 'Do you have any of the following conditions?',
    description: 'More than one answer can be selected',
  });

  if (conditions.length) {
    // only add to significant if thera's a value (ie. hide if "unanswered")
    significant.push({
      ...conditionsQuestion,
      label: 'PMH of high gastric cancer risk',
      value: conditions,
    });
    // ... and triage out.
    return { abort: aborts.highRiskConditions };
  }

  const stomachCancer = await ask(
    'Has anyone in your family had stomach or oesophageal (foodpipe) cancer?',
    {
      id: 'triage.stomachCancer',
    }
  );

  significant.push({
    label: 'FH of gastric cancer',
    value: stomachCancer,
  });

  if (stomachCancer === 'yes') {
    return { abort: aborts.stomachCancer };
  }

  const severeStomachOrChestPain = await ask(
    'Do you currently have SEVERE stomach or chest pain which is stopping you from doing normal activities?',
    {
      id: 'triage.severeStomachOrChestPain',
    }
  );
  if (severeStomachOrChestPain === 'yes') {
    significant.push({
      label: 'Severe Pain',
      value: severeStomachOrChestPain,
    });
    return { abort: aborts.severeStomachOrChestPain };
  }

  const severeHeartburnOrReflux = await ask(
    'Do you have SEVERE heartburn or reflux symptoms which are stopping you from doing normal activities?',
    {
      id: 'triage.severeHeartburnOrReflux',
    }
  );
  if (severeHeartburnOrReflux === 'yes') {
    significant.push({
      label: 'Severe Heartburn or reflux symptoms',
      value: severeHeartburnOrReflux,
    });
    return { abort: aborts.severeHeartburnOrReflux };
  }

  const jaundice = await ask(
    'Have your eyes or skin turned yellow (jaundiced)?',
    {
      id: 'triage.jaundice',
    }
  );
  if (jaundice === 'yes') {
    significant.push({
      label: 'Jaundice',
      value: jaundice,
    });
    return { abort: aborts.jaundice };
  }

  const vomitedBloodQuestion = {
    type: 'choice',
    options: {
      brightRed: 'Yes, it was bright red',
      lookedLikeCoffee: 'Yes, it looked like coffee',
      longerThanWeekAgo: 'I vomited blood longer than a week ago',
      no: 'No',
    },
  };
  const vomitedBlood = await ask({
    ...vomitedBloodQuestion,
    id: 'triage.vomitedBlood',
    label: 'Have you vomited blood within the last week?',
  });
  if (vomitedBlood !== 'no') {
    significant.push({
      ...vomitedBloodQuestion,
      label: 'Haematemesis',
      value: vomitedBlood,
    });
    return { abort: aborts.vomitedBlood };
  }

  return {};
}: Guide);

export default {
  id: 'chlamydia',
  type: 'formulary',
  version: '4',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'chlamydia.info.intro',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Vad är egentligen klamydia och vad behöver jag tänka på?',
          description:
            'Innan du träffar läkaren behöver du ta del av viktig information om sjukdomen och godkänna de förhållningsregler som gäller under behandlingstiden.',
          index: '0.0.0',
        },
        {
          id: 'chlamydia.info.what',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Vad är klamydia?',
          description:
            'Klamydia är den vanligaste sexuellt överförbara infektionen i Sverige. Klamydia orsakas av bakterier. Bakterierna sätter sig oftast i de slemhinnor som finns i urinröret, slidan och ändtarmen. Man kan ha klamydia länge, ibland i flera år utan att märka av det.',
          index: '0.0.1',
        },
        {
          id: 'chlamydia.info.spread',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Hur får man klamydia?',
          description:
            'Klamydia sprids framförallt genom oskyddat sex, antingen genom vaginalt sex, analsex eller oralsex. Det kan också spridas genom att man smeker varandra utan att ha sex, s.k. gemensam onani, eller via sexleksaker. Man blir inte immun utan kan bli smittad av klamydia flera gånger.',
          index: '0.0.2',
        },
        {
          id: 'chlamydia.info.symptoms',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Hur märker jag att jag har klamydia?',
          description:
            'De flesta smittade har inga symptom, det vill säga man märker inte att man har infektionen. Vissa kan känna att det svider lite när de kissar. Kvinnor kan få blodblandade flytningar och ont i nedre delen av magen. Män kan få flytningar från urinröret och ont i pungen. Om man haft analt samlag kan man få flytningar från ändtarmen. Även om man inte har symptom är klamydia mycket smittsamt.',
          index: '0.0.3',
        },
        {
          id: 'chlamydia.info.dangers',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Är klamydia farligt?',
          description:
            'Utan behandling kan kvinnor få skador på äggledarna, som kan göra det svårt att bli gravid eller leda till att fostret utvecklas utanför livmodern, s.k. utomkvedshavandeskap, vilket är ett mycket allvarligt tillstånd. Män kan få inflammation i bitestiklarna, vilket kan leda till sterilitet.',
          index: '0.0.4',
        },
        {
          id: 'chlamydia.info.recovery',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Hur botar man klamydia?',
          description:
            'Klamydia botas med antibiotika i tablettform. Tar du tabletterna som du ska och följer de råd du fått, försvinner infektionen. Behandlingen är kostnadsfri och tar 10 dagar.',
          index: '0.0.5',
        },
        {
          id: 'chlamydia.info.sex',
          version: '2',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Kan jag ha sex under tiden som behandlingen pågår?',
          description:
            'När du har klamydia får du inte riskera att föra smittan vidare. Du ska inte ha samlag eller annan typ av sexuellt umgänge innan du är färdigbehandlad.',
          index: '0.0.6',
        },
        {
          id: 'chlamydia.info.recurrent',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Hur undviker jag att bli smittad igen?',
          description:
            'Du kan skydda dig genom att använda kondom/femidom vid tillfälliga eller nya sexuella kontakter. Kondom kan även användas för att skydda sexleksaker.',
          index: '0.0.7',
        },
        {
          id: 'chlamydia.info.tracing',
          version: '1',
          type: 'info',
          validations: {
            required: false,
          },
          label: 'Vad är smittspårning?',
          description:
            '  <p>\n    Det är mycket viktigt att hitta personer som kan vara smittade av klamydia så att de kan testa sig och vid behov få behandling för att förhindra fortsatt spridning.\n  </p>\n\n  <p>\n    Om du har klamydia måste du därför berätta för en smittspårare vem eller vilka du haft sexuell kontakt med, ibland så långt som ett år tillbaka. Det du säger till smittspåraren omfattas av sekretess. Det innebär att de personer du berättar om inte kommer att få reda på vem som uppgett deras namn; de kommer bara få veta att de måste testa sig.\n  </p>\n\n  <p>\n    Hos Min Doktor är smittspåraren en av våra barnmorskor.\n  </p>',
          index: '0.0.8',
        },
        {
          id: 'chlamydia.info.conduct',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'accepted',
              label: 'Jag har läst och förstår förhållningsreglerna.',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Förhållningsregler',
          description:
            '  <p>\n    I smittskyddslagen finns förhållningsregler för att sjukdomar som t.ex. klamydia inte ska spridas vidare till andra. Du som har klamydia är skyldig att följa dessa regler:\n  </p>\n\n  <p>\n    - Du får inte riskera att föra smittan vidare. Du ska därför inte ha samlag eller annan typ av sexuellt umgänge innan du är färdigbehandlad.<br>\n    - Du är skyldig att samarbeta vid smittspårning.\n  </p>',
          index: '0.0.9',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.fever',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du feber (över 38°C)?',
          index: '0.1.0',
        },
        {
          id: 'health.temperature',
          version: '4',
          type: 'number',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur hög feber har du just nu?',
          unit: '°C',
          index: '0.1.1',
        },
        {
          id: 'medication.recentAgainstFever',
          version: '3',
          type: 'binary',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Har du tagit febernedsättande läkemedel under de senaste 4 timmarna?',
          index: '0.1.2',
        },
        {
          id: 'gastric.painLowerAbdomen',
          type: 'binary',
          version: '1',
          condition: "get('answers.predefined.gender') === 'female'",
          validations: {
            required: true,
          },
          label: 'Har du ont i nedre delen av magen?',
          index: '0.1.3',
        },
        {
          id: 'health.painScrotum',
          version: '1',
          type: 'binary',
          condition: "get('answers.predefined.gender') === 'male'",
          validations: {
            required: true,
          },
          label: 'Har du ont i pungen?',
          index: '0.1.4',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'chlamydia.labtest.reason',
          version: '2',
          type: 'multipleChoice',
          options: [
            {
              value: 'symptoms',
              label: 'Jag har besvär från urinvägarna/underlivet.',
            },
            {
              value: 'sex',
              label:
                'Jag är orolig eller misstänker att jag är smittad av klamydia eller annan könssjukdom.',
            },
            {
              value: 'other',
              label:
                'Annan orsak (t.ex. kontroll vid graviditet, hälsoundersökning)',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Av vilken anledning har du testat dig för klamydia?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.2.0',
        },
        {
          id: 'chlamydia.symptoms.match',
          version: '1',
          type: 'binary',
          condition:
            "contains(get('answers.chlamydia.labtest.reason'), 'symptoms') === false",
          validations: {
            required: true,
          },
          label: 'Har du besvär från urinvägarna/underlivet?',
          description:
            'T.ex. ökade flytningar från underliv eller urinrör, det svider när du kissar, du kissar oftare än vanligt',
          index: '0.2.1',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'chlamydia.symptoms.common',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'femaleDischarge',
              label: 'Jag har flytningar från underlivet.',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'maleDischarge',
              label: 'Jag har flytningar från urinröret.',
              condition: "get('answers.predefined.gender') === 'male'",
            },
            {
              value: 'bleedingBetweenPeriods',
              label: 'Jag har mellanblödningar.',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'peeingOften',
              label: 'Jag kissar oftare än vanligt.',
            },
            {
              value: 'painWhenPeeing',
              label: 'Det svider när jag kissar.',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.chlamydia.symptoms.match') !== 'no'",
          label: 'Vilka symptom har du?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.3.0',
        },
        {
          id: 'chlamydia.symptoms.other',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.chlamydia.symptoms.common'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilka andra symptom har du?',
          index: '0.3.1',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'chlamydia.labtest.date',
          version: '2',
          type: 'text',
          single: true,
          validations: {
            required: true,
            pattern: '^(19|20)[0-9]{2}(1[012]|0[1-9])(31|30|[12][0-9]|0[1-9])$',
          },
          label: 'Vilket datum testade du dig?',
          description: 'Anges i formatet ÅÅÅÅMMDD',
          index: '0.4.0',
        },
        {
          id: 'chlamydia.labtest.fromWhere',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'carestoreCom',
              label: 'carestore.com (Dynamic Code)',
            },
            {
              value: 'klamyidaSe',
              label: 'klamydia.se',
            },
            {
              value: 'klamydiatestNu',
              label: 'klamydiatest.nu',
            },
            {
              value: '1177se',
              label: '1177.se',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Var beställde/köpte du ditt test?',
          index: '0.4.1',
        },
        {
          id: 'chlamydia.labtest.fromWhereOther',
          version: '1',
          type: 'text',
          condition: "get('answers.chlamydia.labtest.fromWhere') === 'other'",
          validations: {
            required: true,
          },
          label: 'Var beställde/köpte du ditt test?',
          index: '0.4.2',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'chlamydia.labtest.pictures',
          type: 'upload',
          version: '2',
          validations: {
            required: true,
            min: 1,
          },
          extras: {
            instructions: ['selfie', 'closeup', 'lighting'],
          },
          label: 'Bifoga en eller flera bilder på ditt positiva klamydiatest.',
          description:
            '  <p>\n    Provsvaren kan se olika ut beroende på vilken typ av test du beställt/köpt. Av bilden ska följande framgå:<br>\n    - datum för när testet gjordes<br>\n    - ditt namn och personnummer eller testets labbkod.\n  </p>\n\n  <p>\n    Observera att även bilden är en del av din journal och omfattas av sekretess.\n  </p>',
          index: '0.5.0',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.6.0',
        },
        {
          id: 'reproduction.week',
          type: 'number',
          version: '2',
          validations: {
            required: true,
            min: 1,
            max: 45,
          },
          condition: "get('answers.reproduction.pregnant') === 'yes'",
          label: 'Ange graviditetsvecka.',
          hint: 'Anges i siffror.',
          index: '0.6.1',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.6.2',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.7.0',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.8',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.9',
    },
  ],
  variables: [
    {
      id: 'chlamydia.painLowerAbdomen',
      type: 'abort',
      expression: "get('answers.gastric.painLowerAbdomen') === 'yes'",
      relativeId: 'painLowerAbdomen',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'chlamydia.painScrotum',
      type: 'abort',
      expression: "get('answers.health.painScrotum') === 'yes'",
      relativeId: 'painScrotum',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'chlamydia.info.conduct',
          type: 'question',
          version: '1',
          extras: {},
          label:
            'Patienten har läst informationen om klamydia och är införstådd med vad som gäller vid klamydia',
          description: '',
          options: [
            {
              value: 'accepted',
              label: 'Jag har läst och förstår förhållningsreglerna.',
            },
          ],
        },
        {
          id: 'health.fever',
          warning: "get('answers.health.fever') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber',
        },
        {
          id: 'health.temperature',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Temperatur',
          description: '',
          unit: '°C',
        },
        {
          id: 'medication.recentAgainstFever',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Febernedsättande läkemedel under de senaste 4 timmarna',
          description: '',
        },
        {
          id: 'gastric.painLowerAbdomen',
          warning: "get('answers.gastric.painLowerAbdomen') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ont i nedre delen av magen',
        },
        {
          id: 'health.painScrotum',
          warning: "get('answers.health.painScrotum') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ont i pungen',
        },
        {
          id: 'chlamydia.labtest.reason',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Anledning till taget test',
          description: '',
          options: [
            {
              value: 'symptoms',
              label: 'Jag har besvär från urinvägarna/underlivet.',
            },
            {
              value: 'sex',
              label:
                'Jag är orolig eller misstänker att jag är smittad av klamydia eller annan könssjukdom.',
            },
            {
              value: 'other',
              label:
                'Annan orsak (t.ex. kontroll vid graviditet, hälsoundersökning)',
            },
          ],
        },
        {
          id: 'chlamydia.symptoms.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Symptom från underlivet/urinvägarna',
          description: '',
        },
        {
          id: 'chlamydia.symptoms.common',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Symptom från underlivet/urinvägarna',
          description: '',
          options: [
            {
              value: 'femaleDischarge',
              label: 'Jag har flytningar från underlivet.',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'maleDischarge',
              label: 'Jag har flytningar från urinröret.',
              condition: "get('answers.predefined.gender') === 'male'",
            },
            {
              value: 'bleedingBetweenPeriods',
              label: 'Jag har mellanblödningar.',
              condition: "get('answers.predefined.gender') === 'female'",
            },
            {
              value: 'peeingOften',
              label: 'Jag kissar oftare än vanligt.',
            },
            {
              value: 'painWhenPeeing',
              label: 'Det svider när jag kissar.',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'chlamydia.symptoms.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra symptom',
        },
        {
          id: 'chlamydia.labtest.date',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Datum för provtagning',
          description: '',
        },
        {
          id: 'chlamydia.labtest.fromWhere',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Test beställt från',
          description: '',
          options: [
            {
              value: 'carestoreCom',
              label: 'carestore.com (Dynamic Code)',
            },
            {
              value: 'klamyidaSe',
              label: 'klamydia.se',
            },
            {
              value: 'klamydiatestNu',
              label: 'klamydiatest.nu',
            },
            {
              value: '1177se',
              label: '1177.se',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'chlamydia.labtest.fromWhereOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Test beställt från, annat',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
        },
        {
          id: 'reproduction.week',
          warning: "get('answers.reproduction.week') > 12",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Graviditetsvecka',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Klamydia',
  index: '0',
};

import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { styled } from '@material-ui/core/styles';

import { getUser } from '../../state/users/selectors';

function UserMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const user = useSelector(getUser);
  const firebase = useFirebase();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    firebase.logout().then(() => window.location.reload());
  };

  const Name = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
  }));
  return (
    <>
      <Button
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Grid container justify="center" alignItems="center">
          <Avatar alt={user.displayName} src={user.photoURL} />
          <Name variant="body2">{user.displayName}</Name>
        </Grid>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;

import { default as bugBiteDefault } from './bugBite/index';
import { default as bugBiteUnder18 } from './bugBite/under18/index';

export const bugBite = {
  default: bugBiteDefault,
  under18: bugBiteUnder18,
};

export { default as acne } from './acne/index';
export { default as allergy } from './allergy/index';
export { default as asthma } from './asthma/index';
export { default as borrelia } from './borrelia/index';
export { default as chlamydia } from './chlamydia/index';
export { default as conjunctivitis } from './conjunctivitis/index';
export { default as contraception } from './contraception';
export { default as cough } from './cough';
export { default as surveyCaseClosed } from './surveyCaseClosed';
export { default as dieticianAdvice } from './dieticianAdvice';
export { default as dieticianRevisit } from './dieticianRevisit';
export { default as gastricProblemsLower } from './gastricProblemsLower/index';
export { default as gastricProblemsUpper } from './gastricProblemsUpper/index';
export { default as headache } from './headache/index';
export { default as healthCheck } from './healthCheck/index';
export { default as herpes } from './herpes/index';
export { default as physio } from './physio/index';
// $FlowFixMe
export { default as pms } from './pms/index';
export { default as psychologyAdvice } from './psychologyAdvice/index';
export {
  default as psychologyAdviceChild,
} from './psychologyAdviceChild/index';
export { default as mentalHealth } from './mentalHealth/index';
export { default as onychomycosis } from './onychomycosis/index';
export { default as uti } from './uti/index';
export { default as vaccination } from './vaccination/index';
export { default as vaccinationAdvice } from './vaccinationAdvice/index';
export { default as kioskBloodpressure } from './kioskBloodpressure';
// Does this guide exist?
// export { default as genericDemoGuide } from './genericDemoGuide';

export default {
  adultPregnantOrBreastfeedingGp: {
    description: `
Unfortunately, we cannot treat you online. 

**While pregnant or breastfeeding, it's best if you see a GP face to face**.

Medications and treatment need to be given extra consideration during these special times — for the sake of both you and your baby.

**Please book an appointment with a GP** so you can talk through your best options.

If your GP surgery is closed, please contact your midwife, local out of hours service, or go to A&E.

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultSeverePainAe: {
    description: `
Unfortunately, we cannot treat you online. 

**You need emergency care — this is because you have severe pain.**

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultChestPainAe: {
    description: `
Unfortunately, we cannot treat you online. 

**You need emergency care — this is because you have chest pain.**

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon.
`,
  },
  adultChestPainUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

This is because you may need to be examined or have some tests.

We would like you to **see a GP** who will provide further help for your problem. 

Please call our support team on **020 3995 4945 and ask for an urgent appointment**. Please quote **"Chest pain"** when booking this appointment. 

If the service is closed, you can get urgent care by calling 111. For **emergencies call 999 or go to A&E**.

You may be seriously unwell, if you have chest pain and:

- Difficulty in breathing
- Are vomiting
- Have coughed up blood
- Are dizzy or lightheaded

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultDifficultyBreathingAe: {
    description: `
Unfortunately, we cannot treat you online. 

**You need emergency care — this is because you have severe difficulty in breathing.**

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultDifficultyBreathingUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

This is because you may need to be examined or have some tests.

We would like you to **see a GP** who will provide further help for your problem. 

Please call our support team on **020 3995 4945 and ask for an urgent appointment**. Please quote **Breathlessness** when booking this appointment. 

If the service is closed, you can get urgent care by calling 111. For **emergencies call 999 or go to A&E**.

You may be seriously unwell, if you have breathlessness and:

- Chest pain
- Coughing up blood
- A fast or fluttering heart beat
- A fever

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon."
    `,
  },
  adultImmunosuppressedGp: {
    description: `
Unfortunately, we cannot treat you online. 

When you have a condition or are taking medication that affects your immune system, it's best if you see a GP face to face. This is because you may need to be examined or have some blood tests.

**Please book an appointment with a GP**.
 
If your GP surgery is closed and you need immediate help, call 111 for urgent medical care. **For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultSepsisAe: {
    description: `
Unfortunately, we cannot treat you online. 

**You need emergency care — this is because you have may have a severe infection.**

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultSyncopeUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

This is because you may need to be examined or have some tests.

We would like you to **see a GP** who will provide further help for your problem. 

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**. Please quote **''Fainting'** when booking this appointment. 

If the service is closed, you can get **urgent care by calling 111**. For **emergencies call 999 or go to A&E**.

You may be seriously unwell, if you have chest pain and:

- Difficulty in breathing
- Are vomiting
- Have coughed up blood
- Are dizzy or lightheaded

Please **go to A&E or call 999 immediately**. 

Thank you for using Docly. We hope you feel better soon.
`,
  },
  adultMentalHealthGp: {
    description: `
Unfortunately, we cannot treat you online. 

**It is best to see your GP to discuss mental health issues**.

**Please book an appointment with a GP** so you can talk through your best options.

Thank you for using Docly. We hope you feel better soon.
`,
  },
  adultAntibioticsForFutureUseGp: {
    description: `
Unfortunately, we cannot prescribe antibiotics for future use online. 

For this, **it's best if you see your GP face to face to talk through your best options.**

**Please book an appointment with your GP**.
    `,
  },
  adultUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

**You need urgent care — this is because I am worried that your condition is severe.**

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**.

If you **cannot reach our support team, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultBloodInUrineUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

**You need urgent care — this is because I am worried that your condition is severe.**

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**.
Please quote **'peeing blood'** when you book this appointment.

If you **cannot reach our support team, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
  adultBloodInUrineAndHighTempUrgent: {
    description: `
Unfortunately, we cannot treat you online. 

**You need urgent care — this is because I am worried that your condition is severe.**

Please **call our support team on 020 3995 4945 and ask for an urgent appointment**.
Please quote **'blood in urine and high temperature'** when you book this appointment.

If you **cannot reach our support team, call 111 for urgent medical care. For emergencies call 999 or go to A&E.**

Thank you for using Docly. We hope you feel better soon.
    `,
  },
};

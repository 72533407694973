// @flow

import type { Guide } from '../../types';
import createExtendedChoice from '../common/extendedChoice';
import aborts from './aborts';

export default (async args => {
  const eChoice = createExtendedChoice(args);

  let { scores } = args;
  const bumpWarningScore = () => {
    scores.increase('warning');
  };
  const bumpFeverPainScore = () => {
    scores.increase('feverPain');
  };

  await eChoice({
    // same parameters as ask (but options will be ignored)
    id: 'exampleGuide.exampleQuestion',
    label: 'What should happen?',
    isMultipleChoice: true,
    options: [
      {
        label: 'Increase feverPainScore',
        value: 'bumpFeverPain',
        onSelected: bumpFeverPainScore,
      },
      {
        label: 'Increase warning score',
        value: 'bumpWarning',
        onSelected: bumpWarningScore,
      },
      {
        label: 'Abort from within this included segment',
        value: 'abort',
        abort: aborts.exampleAbort,
      },
    ],
  });

  return {};
}: Guide);

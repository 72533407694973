export default {
  id: 'gastricProblemsLower',
  type: 'formulary',
  version: '5',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '9',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började dina besvär?',
          index: '0.0.0',
        },
        {
          id: 'gastric.visibleBlood',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Kommer det blod i samband med din avföring?',
          index: '0.0.1',
        },
        {
          id: 'gastric.blackStool',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Är din avföring svart?',
          index: '0.0.2',
        },
        {
          id: 'health.fever',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du feber (över 38°C)?',
          index: '0.0.3',
        },
        {
          id: 'health.temperature',
          version: '4',
          type: 'number',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur hög feber har du just nu?',
          unit: '°C',
          index: '0.0.4',
        },
        {
          id: 'medication.recentAgainstFever',
          version: '3',
          type: 'binary',
          condition: "get('answers.health.fever') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Har du tagit febernedsättande läkemedel under de senaste 4 timmarna?',
          index: '0.0.5',
        },
        {
          id: 'gastric.stomachPain',
          type: 'choice',
          version: '3',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'sometimes',
              label: 'Ibland',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Har du ont i magen?',
          index: '0.0.6',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.painStarted',
          version: '1',
          type: 'choice',
          options: [
            {
              value: 'sudden',
              label: 'Den kom plötsligt (på några sekunder eller minuter)',
            },
            {
              value: 'slowly',
              label:
                'Den kom smygande (ökade långsamt under timmar eller dagar)',
            },
          ],
          condition: "get('answers.gastric.stomachPain') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur började smärtan?',
          index: '0.1.0',
        },
        {
          id: 'gastric.painLocation',
          version: '1',
          type: 'multipleChoice',
          options: [
            {
              value: 'rightUpper',
              label: 'På höger sida i övre delen av magen',
            },
            {
              value: 'leftUpper',
              label: 'På vänster sida i övre delen av magen',
            },
            {
              value: 'rightLower',
              label: 'På höger sida i nedre delen av magen',
            },
            {
              value: 'leftLower',
              label: 'På vänster sida i nedre delen av magen',
            },
            {
              value: 'diffuse',
              label: 'Smärtan är diffus och svår att lokalisera',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.gastric.stomachPain') === 'yes'",
          label: 'Var sitter smärtan?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.1.1',
        },
        {
          id: 'health.painDescription',
          type: 'choice',
          version: '3',
          options: [
            {
              value: 'intervals',
              label: 'Den kommer i intervaller (krampande, knipande)',
            },
            {
              value: 'dull',
              label: 'Det gör ont hela tiden (molande)',
            },
          ],
          condition: "get('answers.gastric.stomachPain') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur skulle du beskriva smärtan?',
          index: '0.1.2',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.painEvaluationNow',
          type: 'range',
          version: '1',
          min: {
            value: 0,
            label: 'ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'värsta tänkbara smärta',
          },
          extras: {
            vas: true,
            invert: false,
          },
          condition: "get('answers.gastric.stomachPain') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Hur ont har du just nu?',
          description: 'Markera på skalan.',
          index: '0.2.0',
        },
        {
          id: 'health.painEvaluationWorst',
          type: 'range',
          version: '1',
          min: {
            value: 0,
            label: 'ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'värsta tänkbara smärta',
          },
          extras: {
            vas: true,
            invert: false,
          },
          condition: "get('answers.health.painDescription') === 'intervals'",
          validations: {
            required: true,
          },
          label: 'Hur ont har du när du har som mest ont?',
          description: 'Markera på skalan.',
          index: '0.2.1',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.alteredBehavior',
          type: 'choice',
          version: '2',
          options: [
            {
              value: 'often',
              label: 'Ja, jag behöver gå oftare på toaletten',
            },
            {
              value: 'rarely',
              label: 'Ja, jag behöver gå mer sällan på toaletten',
            },
            {
              value: 'unchanged',
              label: 'Nej, de är oförändrade',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Har dina avföringsvanor förändrats?',
          index: '0.3.0',
        },
        {
          id: 'gastric.alteredBehaviorWhen',
          type: 'choice',
          version: '1',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
          condition:
            "get('answers.gastric.alteredBehavior') === 'often' || get('answers.gastric.alteredBehavior') === 'rarely'",
          validations: {
            required: true,
          },
          label: 'När förändrades dina avföringsvanor?',
          index: '0.3.1',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.alteredStool',
          type: 'choice',
          version: '2',
          options: [
            {
              value: 'harder',
              label: 'Den har blivit hårdare',
            },
            {
              value: 'softer',
              label: 'Den har blivit lösare',
            },
            {
              value: 'alternating',
              label: 'Den har blivit växelvis hård och lös',
            },
            {
              value: 'unchanged',
              label: 'Den är oförändrad',
            },
          ],
          validations: {
            required: true,
          },
          label:
            'Om din avföring ändrat karaktär, på vilket sätt har den förändrats?',
          index: '0.4.0',
        },
        {
          id: 'gastric.thinStool',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har dina bajskorvar blivit påfallande smala?',
          index: '0.4.1',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.weightLoss',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du ofrivilligt gått ner i vikt på senare tid?',
          index: '0.5.0',
        },
        {
          id: 'problem.abroad.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Har du varit utomlands i samband med att dina besvär började?',
          index: '0.5.1',
        },
        {
          id: 'problem.abroad.where',
          type: 'text',
          version: '1',
          condition: "get('answers.problem.abroad.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilket land besökte du?',
          index: '0.5.2',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.reliefFromPooping',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Lindras dina besvär när du bajsat?',
          index: '0.6.0',
        },
        {
          id: 'gastric.emptyingBowels',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Upplever du att du kan tömma tarmen helt vid dina toabesök?',
          index: '0.6.1',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.nightly',
          type: 'binary',
          version: '2',
          condition: "get('answers.gastric.alteredStool') === 'softer'",
          validations: {
            required: true,
          },
          label: 'Behöver du gå upp och bajsa på natten?',
          index: '0.7.0',
        },
        {
          id: 'gastric.timesPerDay',
          version: '2',
          type: 'choice',
          options: [
            {
              value: 'severalTimesADay',
              label: 'Flera gånger per dygn',
            },
            {
              value: 'onceADay',
              label: 'En gång per dygn',
            },
            {
              value: 'everyOtherDay',
              label: 'Vartannat till vart tredje dygn',
            },
            {
              value: 'everyFourthDay',
              label: 'Vart fjärde till vart sjunde dygn',
            },
            {
              value: 'lessThanOnceAWeek',
              label: 'Mer sällan än en gång i veckan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Hur ofta bajsar du?',
          index: '0.7.1',
        },
        {
          id: 'gastric.timesPerDaySpecific',
          type: 'number',
          version: '1',
          condition:
            "get('answers.gastric.timesPerDay') === 'severalTimesADay'",
          validations: {
            required: true,
          },
          label: 'Ungefär hur många gånger per dygn bajsar du?',
          index: '0.7.2',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.texture',
          version: '2',
          type: 'choice',
          options: [
            {
              value: 'hardLumps',
              label: 'Separata hårda klumpar, som nötter (svåra att få ut)',
            },
            {
              value: 'turdLumps',
              label: 'Korvformade men med klumpar',
            },
            {
              value: 'turdCracks',
              label: 'Korvformade men med sprickor på ytan',
            },
            {
              value: 'turdSoft',
              label: 'Korv- eller ormformade, smidiga och mjuka',
            },
            {
              value: 'softLumps',
              label: 'Mjuka, väl avgränsade klumpar (lätta att få ut)',
            },
            {
              value: 'softMushy',
              label: 'Småbitar, mosig konsistens',
            },
            {
              value: 'watery',
              label: 'Vattnig, inga fasta bitar, enbart vätska',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Hur ser din avföring ut?',
          index: '0.8.0',
        },
        {
          id: 'gastric.stoolSlimy',
          type: 'binary',
          version: '1',
          condition:
            "get('answers.gastric.texture') === 'turdSoft' || get('answers.gastric.texture') === 'softLumps' || get('answers.gastric.texture') === 'softMushy' || get('answers.gastric.texture') === 'watery'",
          validations: {
            required: true,
          },
          label: 'Är din avföring slemmig?',
          index: '0.8.1',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.painAnal',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du ont vid ändtarmsöppningen?',
          index: '0.9.0',
        },
        {
          id: 'gastric.protrution',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Känns det som om något putar ut vid ändtarmsöppningen?',
          index: '0.9.1',
        },
        {
          id: 'gastric.hemorrhoids',
          type: 'binary',
          version: '1',
          condition: "get('answers.gastric.protrution') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Har du diagnosticerade hemorrojder?',
          index: '0.9.2',
        },
      ],
      version: '1',
      index: '0.9',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.nauseous',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Mår du illa?',
          index: '0.10.0',
        },
        {
          id: 'health.vomit',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Kräks du?',
          index: '0.10.1',
        },
        {
          id: 'gastric.resultFromEating',
          type: 'composite',
          version: '2',
          children: [
            {
              id: 'gastric.resultFromEating.what',
              type: 'choice',
              options: [
                {
                  value: 'improved',
                  label: 'Besvären lindras',
                },
                {
                  value: 'worsened',
                  label: 'Besvären tilltar',
                },
                {
                  value: 'unchanged',
                  label: 'Mina symptom påverkas inte av att jag äter',
                },
              ],
              relativeId: 'what',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Vad händer när du äter?',
              index: '0.10.2.0',
            },
            {
              id: 'gastric.resultFromEating.worsened',
              type: 'multipleChoice',
              condition:
                "get('answers.gastric.resultFromEating.what') === 'worsened'",
              options: [
                {
                  value: 'stomachPain',
                  label: 'Jag får ont i magen',
                },
                {
                  value: 'quicklyFull',
                  label: 'Jag blir snabbt mätt',
                },
                {
                  value: 'gasy',
                  label: 'Jag känner mig spänd/uppblåst i magen',
                },
                {
                  value: 'other',
                  label: 'Annat',
                },
              ],
              validations: {
                required: true,
              },
              relativeId: 'worsened',
              version: '2',
              label: 'På vilket sätt blir dina symptom förvärrade när du äter?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.10.2.1',
            },
            {
              id: 'gastric.resultFromEating.other',
              type: 'text',
              condition:
                "contains(get('answers.gastric.resultFromEating.worsened'), 'other')",
              relativeId: 'other',
              version: '2',
              validations: {
                required: true,
              },
              label: 'Beskriv hur det känns eller vad som händer när du äter.',
              index: '0.10.2.2',
            },
          ],
          extras: {},
          index: '0.10.2',
        },
      ],
      version: '1',
      index: '0.10',
    },
    {
      type: 'section',
      children: [
        {
          id: 'gastric.history',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Har du haft liknande magbesvär tidigare?',
          index: '0.11.0',
        },
      ],
      version: '1',
      index: '0.11',
    },
    {
      type: 'section',
      children: [
        {
          id: 'similar',
          type: 'composite',
          version: '2-gastricLower',
          children: [
            {
              id: 'similar.match',
              type: 'binary',
              relativeId: 'match',
              version: '2-gastricLower',
              validations: {
                required: true,
              },
              label:
                'Har du tidigare sökt läkare och blivit utredd för dina magbesvär?',
              index: '0.12.0.0',
            },
            {
              id: 'similar.when',
              type: 'text',
              condition: "get('answers.similar.match') === 'yes'",
              relativeId: 'when',
              version: '2-gastricLower',
              validations: {
                required: true,
              },
              label: 'När var detta?',
              index: '0.12.0.1',
            },
            {
              id: 'similar.exams',
              type: 'multipleChoice',
              condition: "get('answers.similar.match') === 'yes'",
              options: [
                {
                  value: 'bloodInStool',
                  label: 'Test för blod i avföring',
                },
                {
                  value: 'rectoscopy',
                  label: 'Rektoskopi',
                },
                {
                  value: 'colonoscopy',
                  label: 'Koloskopi',
                },
                {
                  value: 'gastroscopy',
                  label: 'Gastroskopi',
                },
                {
                  value: 'xray',
                  label: 'Röntgen',
                },
              ],
              relativeId: 'exams',
              version: '2-gastricLower',
              validations: {
                required: false,
              },
              label: 'Gjordes någon av följande undersökningar?',
              description: 'Flera svarsalternativ möjliga.',
              index: '0.12.0.2',
            },
            {
              id: 'similar.diagnosis',
              type: 'text',
              condition: "get('answers.similar.match') === 'yes'",
              relativeId: 'diagnosis',
              version: '2-gastricLower',
              validations: {
                required: true,
              },
              label: 'Vilken diagnos fick du?',
              index: '0.12.0.3',
            },
          ],
          condition: "get('answers.gastric.history') === 'yes'",
          extras: {},
          index: '0.12.0',
        },
      ],
      version: '1',
      index: '0.12',
    },
    {
      type: 'section',
      children: [
        {
          id: 'medication.tried',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Har du provat några läkemedel mot dina besvär?',
          index: '0.13.0',
        },
        {
          id: 'gastric.medication.what',
          type: 'multipleChoice',
          version: '3',
          options: [
            {
              value: 'constipation',
              label:
                'Läkemedel mot förstoppning (t.ex. Dulcolax, Laxoberal, Inolaxol, Movicol, Laktulos)',
            },
            {
              value: 'gas',
              label: 'Läkemedel mot gaser (t.ex. Dimetikon, Minifom)',
            },
            {
              value: 'diarrhea',
              label: 'Läkemdedel mot diarré (t.ex. Loperamid, Dimor, Imodium)',
            },
            {
              value: 'acid',
              label: 'Syrahämmande (t.ex. Omeprazol, Losec)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.medication.tried') === 'yes'",
          label: 'Vilka läkemedel har du provat?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.13.1',
        },
        {
          id: 'gastric.medication.other',
          type: 'text',
          version: '2',
          condition:
            "contains(get('answers.gastric.medication.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilka andra läkemedel har du provat?',
          index: '0.13.2',
        },
        {
          id: 'gastric.medication.effect',
          type: 'text',
          version: '2',
          condition: "get('answers.medication.tried') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilken effekt hade läkemedlen?',
          index: '0.13.3',
        },
      ],
      version: '1',
      index: '0.13',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.14.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.14.1',
        },
      ],
      version: '1',
      index: '0.14',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.15.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.15.1',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.15',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.16.0',
        },
      ],
      version: '1',
      index: '0.16',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.17',
    },
    {
      id: 'predefined.isInsuranceCompany',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.18',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.19',
    },
  ],
  variables: [
    {
      id: 'gastricProblemsLower.blackStool',
      type: 'abort',
      expression:
        "get('answers.gastric.blackStool') === 'yes' && get('answers.predefined.age') >= 50 && get('answers.predefined.isInsuranceCompany') === 'no'",
      relativeId: 'blackStool',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'gastricProblemsLower.visibleBloodStools',
      type: 'abort',
      expression:
        "get('answers.gastric.visibleBlood') === 'yes' && get('answers.predefined.age') >= 50 && get('answers.predefined.isInsuranceCompany') === 'no'",
      relativeId: 'visibleBloodStools',
      version: '1',
      validations: {
        required: true,
      },
    },
    {
      id: 'gastricProblemsLower.feverAndStomachPain',
      type: 'abort',
      expression:
        "(get('answers.health.fever') === 'yes' && get('answers.gastric.stomachPain') === 'yes')",
      relativeId: 'feverAndStomachPain',
      version: '1',
      validations: {
        required: true,
      },
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
          description: '',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
          description: '',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären började',
          description: '',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'gastric.visibleBlood',
          warning: "get('answers.gastric.visibleBlood') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Blod i samband med avföring',
          description: '',
        },
        {
          id: 'gastric.blackStool',
          warning: "get('answers.gastric.blackStool') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Svart avföring',
          description: '',
        },
        {
          id: 'health.fever',
          warning:
            "get('answers.health.fever') === 'yes' && get('answers.gastric.stomachPain') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Feber över 38°C',
          description: '',
        },
        {
          id: 'health.temperature',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Temperatur',
          description: '',
          unit: '°C',
        },
        {
          id: 'medication.recentAgainstFever',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Febernedsättande läkemedel under de senaste 4 timmarna',
          description: '',
        },
        {
          id: 'gastric.stomachPain',
          warning:
            "get('answers.health.fever') === 'yes' && get('answers.gastric.stomachPain') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ont i magen',
          description: '',
          options: [
            {
              value: 'yes',
              label: 'Ja',
            },
            {
              value: 'no',
              label: 'Nej',
            },
            {
              value: 'sometimes',
              label: 'Ibland',
            },
          ],
        },
        {
          id: 'gastric.painStarted',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärtdebut',
          description: '',
          options: [
            {
              value: 'sudden',
              label: 'Den kom plötsligt (på några sekunder eller minuter)',
            },
            {
              value: 'slowly',
              label:
                'Den kom smygande (ökade långsamt under timmar eller dagar)',
            },
          ],
        },
        {
          id: 'gastric.painLocation',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärtlokalisation',
          description: '',
          options: [
            {
              value: 'rightUpper',
              label: 'På höger sida i övre delen av magen',
            },
            {
              value: 'leftUpper',
              label: 'På vänster sida i övre delen av magen',
            },
            {
              value: 'rightLower',
              label: 'På höger sida i nedre delen av magen',
            },
            {
              value: 'leftLower',
              label: 'På vänster sida i nedre delen av magen',
            },
            {
              value: 'diffuse',
              label: 'Smärtan är diffus och svår att lokalisera',
            },
          ],
        },
        {
          id: 'health.painDescription',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärtkaraktär',
          description: '',
          options: [
            {
              value: 'intervals',
              label: 'Den kommer i intervaller (krampande, knipande)',
            },
            {
              value: 'dull',
              label: 'Det gör ont hela tiden (molande)',
            },
          ],
        },
        {
          id: 'health.painEvaluationNow',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärta just nu enligt VAS (0–10)',
          description: '',
          min: {
            value: 0,
            label: 'ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'värsta tänkbara smärta',
          },
        },
        {
          id: 'health.painEvaluationWorst',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärta vid intervallsmärta enligt VAS (0–10)',
          description: '',
          min: {
            value: 0,
            label: 'ingen smärta alls',
          },
          max: {
            value: 10,
            label: 'värsta tänkbara smärta',
          },
        },
        {
          id: 'gastric.alteredBehavior',
          warning:
            "get('answers.gastric.alteredBehavior') === 'often' || get('answers.gastric.alteredBehavior') === 'rarely'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Förändring av avföringsvanor',
          description: '',
          options: [
            {
              value: 'often',
              label: 'Ja, jag behöver gå oftare på toaletten',
            },
            {
              value: 'rarely',
              label: 'Ja, jag behöver gå mer sällan på toaletten',
            },
            {
              value: 'unchanged',
              label: 'Nej, de är oförändrade',
            },
          ],
        },
        {
          id: 'gastric.alteredBehaviorWhen',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Förändrade avföringsvanor sedan',
          description: '',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'last5to14days',
              label: 'För 5–14 dagar sedan',
            },
            {
              value: '2weeksTo3Months',
              label: 'För 15 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'gastric.alteredStool',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Förändring av avföring',
          description: '',
          options: [
            {
              value: 'harder',
              label: 'Den har blivit hårdare',
            },
            {
              value: 'softer',
              label: 'Den har blivit lösare',
            },
            {
              value: 'alternating',
              label: 'Den har blivit växelvis hård och lös',
            },
            {
              value: 'unchanged',
              label: 'Den är oförändrad',
            },
          ],
        },
        {
          id: 'gastric.thinStool',
          warning: "get('answers.gastric.thinStool') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Bajskorvar blivit påfallande smala',
          description: '',
        },
        {
          id: 'health.weightLoss',
          warning: "get('answers.health.weightLoss') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ofrivillig viktnedgång på senare tid',
          description: '',
        },
        {
          id: 'problem.abroad.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Utlandsvistelse i samband med besvärdebut',
          description: '',
        },
        {
          id: 'problem.abroad.where',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besökt land',
          description: '',
        },
        {
          id: 'gastric.reliefFromPooping',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvärslindring i samband med tarmtömning',
          description: '',
        },
        {
          id: 'gastric.emptyingBowels',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kan tömma tarmen helt vid toabesök',
          description: '',
        },
        {
          id: 'gastric.nightly',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Behöver bajsa nattetid',
          description: '',
        },
        {
          id: 'gastric.timesPerDay',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Bajsar',
          description: '',
          options: [
            {
              value: 'severalTimesADay',
              label: 'Flera gånger per dygn',
            },
            {
              value: 'onceADay',
              label: 'En gång per dygn',
            },
            {
              value: 'everyOtherDay',
              label: 'Vartannat till vart tredje dygn',
            },
            {
              value: 'everyFourthDay',
              label: 'Vart fjärde till vart sjunde dygn',
            },
            {
              value: 'lessThanOnceAWeek',
              label: 'Mer sällan än en gång i veckan',
            },
          ],
        },
        {
          id: 'gastric.timesPerDaySpecific',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Bajsar antal gånger per dygn',
          description: '',
        },
        {
          id: 'gastric.texture',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Avföringens utseende enligt Bristolskalan',
          description: '',
          options: [
            {
              value: 'hardLumps',
              label: 'Separata hårda klumpar, som nötter (svåra att få ut)',
            },
            {
              value: 'turdLumps',
              label: 'Korvformade men med klumpar',
            },
            {
              value: 'turdCracks',
              label: 'Korvformade men med sprickor på ytan',
            },
            {
              value: 'turdSoft',
              label: 'Korv- eller ormformade, smidiga och mjuka',
            },
            {
              value: 'softLumps',
              label: 'Mjuka, väl avgränsade klumpar (lätta att få ut)',
            },
            {
              value: 'softMushy',
              label: 'Småbitar, mosig konsistens',
            },
            {
              value: 'watery',
              label: 'Vattnig, inga fasta bitar, enbart vätska',
            },
          ],
        },
        {
          id: 'gastric.stoolSlimy',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Slemmig avföring',
          description: '',
        },
        {
          id: 'gastric.painAnal',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ont vid ändtarmsöppningen',
          description: '',
        },
        {
          id: 'gastric.protrution',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Putar ut vid ändtarmsöppningen',
          description: '',
        },
        {
          id: 'gastric.hemorrhoids',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diagnosticerade hemorrojder',
          description: '',
        },
        {
          id: 'health.nauseous',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Illamående',
          description: '',
        },
        {
          id: 'health.vomit',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Kräkningar',
          description: '',
        },
        {
          id: 'gastric.resultFromEating.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Följande händer vid matintag',
          description: '',
          options: [
            {
              value: 'improved',
              label: 'Besvären lindras',
            },
            {
              value: 'worsened',
              label: 'Besvären tilltar',
            },
            {
              value: 'unchanged',
              label: 'Mina symptom påverkas inte av att jag äter',
            },
          ],
        },
        {
          id: 'gastric.resultFromEating.worsened',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Symptom vid matintag',
          description: '',
          options: [
            {
              value: 'stomachPain',
              label: 'Jag får ont i magen',
            },
            {
              value: 'quicklyFull',
              label: 'Jag blir snabbt mätt',
            },
            {
              value: 'gasy',
              label: 'Jag känner mig spänd/uppblåst i magen',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'gastric.resultFromEating.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fri beskrivning av vad som händer vid matintag',
          description: '',
        },
        {
          id: 'gastric.history',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Liknande besvär tidigare',
          description: '',
        },
        {
          id: 'similar.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sökt läkare tidigare och utredd för magbesvär',
          description: '',
        },
        {
          id: 'similar.when',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sökt läkare när',
          description: '',
        },
        {
          id: 'similar.exams',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gjorda undersökningar',
          description: '',
          options: [
            {
              value: 'bloodInStool',
              label: 'Test för blod i avföring',
            },
            {
              value: 'rectoscopy',
              label: 'Rektoskopi',
            },
            {
              value: 'colonoscopy',
              label: 'Koloskopi',
            },
            {
              value: 'gastroscopy',
              label: 'Gastroskopi',
            },
            {
              value: 'xray',
              label: 'Röntgen',
            },
          ],
        },
        {
          id: 'similar.diagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Diagnos',
          description: '',
        },
        {
          id: 'medication.tried',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Provat läkemedel',
          description: '',
        },
        {
          id: 'gastric.medication.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel',
          description: '',
          options: [
            {
              value: 'constipation',
              label:
                'Läkemedel mot förstoppning (t.ex. Dulcolax, Laxoberal, Inolaxol, Movicol, Laktulos)',
            },
            {
              value: 'gas',
              label: 'Läkemedel mot gaser (t.ex. Dimetikon, Minifom)',
            },
            {
              value: 'diarrhea',
              label: 'Läkemdedel mot diarré (t.ex. Loperamid, Dimor, Imodium)',
            },
            {
              value: 'acid',
              label: 'Syrahämmande (t.ex. Omeprazol, Losec)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'gastric.medication.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel, annat',
          description: '',
        },
        {
          id: 'gastric.medication.effect',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Effekt av läkemedel',
          description: '',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
          description: '',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
          description: '',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Övrig medicinsk information',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Magbesvär – Nedre (t.ex. diarré och förstoppning)',
  index: '0',
};

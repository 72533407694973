import type { Guide } from '../../../types';

export default (async args => {
  const { ask, exports, significant } = args;

  const description = 'Flera svarsalternativ möjliga.';

  const allergyPenicillin = {
    type: 'choice',
    id: 'allergy.penicillinMatch',
    label: 'Är du allergisk mot penicillin eller annan antibiotika?',
    options: [
      {
        value: 'yesKnows',
        label:
          'Ja, och jag vet vilken sorts penicillin eller antibiotika jag är allergisk mot.',
      },
      {
        value: 'yesDontKnow',
        label:
          'Ja, men jag vet inte vilken sorts penicillin eller antibiotika jag är allergisk mot.',
      },
      {
        value: 'no',
        label: 'Nej',
      },
      {
        value: 'dontKnow',
        label: 'Vet ej',
      },
    ],
    expires: 180,
  };

  const allergyPenicillinAnswer = await ask(allergyPenicillin);

  significant.push({
    ...allergyPenicillin,
    label: 'Har penicillin/antibiotikaallergi',
    value: allergyPenicillinAnswer,
    warn: ['yesKnows', 'yesDontKnow'],
  });

  if (allergyPenicillinAnswer === 'yesKnows') {
    exports.push({
      id: 'prescriptionWarnings',
      value: [
        '**Har penicillin/antibiotikaallergi** \nJa, och jag vet vilken sorts penicillin eller antibiotika jag är allergisk mot.',
      ],
    });
  }

  if (allergyPenicillinAnswer === 'yesDontKnow') {
    exports.push({
      id: 'prescriptionWarnings',
      value: [
        '**Har penicillin/antibiotikaallergi** \nJa, men jag vet inte vilken sorts penicillin eller antibiotika jag är allergisk mot.',
      ],
    });
  }

  const penicillinOptions = [
    {
      value: 'penicillins',
      label:
        'Penicilliner (t.ex. Amimox, Amoxicillin, Avopenin, Bioclavid, Calciopen, Flukloxacillin, Imacillin, Heracillin, Klaximol, Kåvepenin, Penomax, Selexid, Spektramox, Tikacillin)',
    },
    {
      value: 'azitromycin',
      label: 'Azitromycin (t.ex. Azitromax)',
    },
    {
      value: 'cefadroxil',
      label: 'Cefadroxil (t.ex. Cefamox)',
    },
    {
      value: 'ciprofloxacin',
      label: 'Ciprofloxacin (t.ex. Ciproxin)',
    },
    {
      value: 'erytromycin',
      label: 'Erytromycin (t.ex. Abboticin, Ery-Max)',
    },
    {
      value: 'klindamycin',
      label: 'Klindamycin (t.ex. Dalacin)',
    },
    {
      value: 'nitrofurantoin',
      label: 'Nitrofurantoin (t.ex. Furadantin)',
    },
    {
      value: 'norfloxacin',
      label: 'Norfloxacin (t.ex. Lexinor)',
    },
    {
      value: 'sulfa',
      label: 'Sulfa (t.ex. Bactrim, Bactrim forte, Eusaprim, Eusaprim forte)',
    },
    {
      value: 'tetracykliner',
      label:
        'Tetracykliner (t.ex. Doxyferm, Lymelysal, Tetracyklin, Tetralysal)',
    },
    {
      value: 'trimetoprim',
      label: 'Trimetoprim (t.ex. Idotrim)',
    },
    {
      value: 'other',
      label: 'Annat',
    },
  ];

  if (allergyPenicillinAnswer === 'yesKnows') {
    const allergyPenicillinWhat = {
      type: 'multipleChoice',
      id: 'allergy.penicillinWhat',
      label:
        'Vilken typ av penicillin eller annan antibiotika är du allergisk mot?',
      description: description,
      options: penicillinOptions,
      expires: 180,
    };

    const allergyPenicillinWhatAnswer = await ask(allergyPenicillinWhat);

    significant.push({
      ...allergyPenicillinWhat,
      label: 'Typ av penicillin/antibiotikaallergi',
      description: '',
      value: allergyPenicillinWhatAnswer,
    });

    const penicillinLabels = [];
    for (let i = 0; i < penicillinOptions.length; i++) {
      for (let j = 0; j < allergyPenicillinWhatAnswer.length; j++) {
        if (penicillinOptions[i].value === allergyPenicillinWhatAnswer[j]) {
          penicillinLabels.push(penicillinOptions[i].label);
        }
      }
    }

    const penicillinLabelsToString = String(penicillinLabels);

    exports.push({
      id: 'prescriptionWarnings',
      value: [
        `**Typ av penicillin/antibiotikaallergi** \n${penicillinLabelsToString}`,
      ],
    });

    if (allergyPenicillinWhatAnswer.includes('other')) {
      const allergyPenicillinOther = {
        type: 'text',
        label:
          'Vilken typ av penicillin eller annan antibiotika är du allergisk mot?',
        expires: 180,
      };

      const allergyPenicillinOtherAnswer = await ask(allergyPenicillinOther);

      significant.push({
        ...allergyPenicillinOther,
        label: 'Annan typ av penicillin/antibiotikaallergi',
        value: allergyPenicillinOtherAnswer,
      });

      exports.push({
        id: 'prescriptionWarnings',
        value: [
          `**Annan typ av penicillin/antibiotikaallergi** \n${allergyPenicillinOtherAnswer}`,
        ],
      });
    }
  }

  if (allergyPenicillinAnswer.includes('yes')) {
    const allergyPenicillinReaction = {
      type: 'multipleChoice',
      id: 'allergy.penicillinReaction',
      label: 'Hur reagerade du på penicillinet/antibiotikan?',
      description: description,
      options: [
        {
          value: 'severe',
          label:
            'Allvarlig reaktion med t.ex. andningssvårigheter och svullnad i mun och svalg',
        },
        {
          value: 'light',
          label: 'Lättare besvär såsom hudutslag och klåda',
        },
        {
          value: 'other',
          label: 'Annat',
        },
      ],
      expires: 180,
    };

    const allergyPenicillinReactionAnswer = await ask(
      allergyPenicillinReaction
    );

    significant.push({
      ...allergyPenicillinReaction,
      label: 'Reaktion vid penicillin/antibiotikaallergi',
      description: '',
      value: allergyPenicillinReactionAnswer,
    });

    if (allergyPenicillinReactionAnswer.includes('other')) {
      const allergyPenicillinReactionOther = {
        type: 'text',
        id: 'allergy.penicillinReactionOther',
        label: 'Beskriv hur du reagerade på penicillinet/antibiotikan.',
        expires: 180,
      };

      const allergyPenicillinReactionOtherAnswer = await ask(
        allergyPenicillinReactionOther
      );

      significant.push({
        ...allergyPenicillinReactionOther,
        label: 'Fri beskrivning av annan reaktion',
        value: allergyPenicillinReactionOtherAnswer,
      });
    }
  }

  return {};
}: Guide);

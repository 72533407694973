// @flow

import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';

export default (async args => {
  const { ask } = args;
  const eChoice = createExtendedChoice(args);

  await eChoice({
    id: 'coronaVirusScreen.contactWithOthers',
    label:
      'Have you come into contact with anyone with suspected or confirmed Coronavirus?',
    clinicLabel: 'Recent contact with coronavirus',
    options: [
      {
        value: 'yes',
        warn: true,
      },
      {
        value: 'no',
      },
    ],
  });

  await ask.choice({
    description: `
If you have a high temperature or a new, continuous cough, you and the people you live with should not leave your home.

To protect others, do not go to places like a GP surgery or pharmacy. 

**If you are struggling to breath, or feeling very short of breath, do not wait for online care.**  Call 111 for urgent medical care. For emergencies call 999.
`,
    options: ['I understand'],
  });

  return {};
}: Guide);

import type { Guide } from '../../types';

import triage from './triage';

export default (async args => {
  const { ask, significant } = args;

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) return { abort: triageAbort };

  const problemDescribe = {
    type: 'text',
    id: 'psychologyAdviceChild.problem',
    label: 'Vad har ditt barn för besvär eller vad önskar ni hjälp med?',
  };
  const problemAnswer = await ask(problemDescribe);

  significant.push({
    ...problemDescribe,
    label: 'Vilka besvär, önskar hjälp med',
    value: problemAnswer,
  });

  const likeSchool = {
    type: 'text',
    id: 'psychologyAdviceChild.likeSchool',
    label: 'Hur trivs ditt barn i förskolan/skolan?',
  };
  const likeSchoolAnswer = await ask(likeSchool);

  significant.push({
    ...likeSchool,
    label: 'Hur trivs barnet i skolan',
    value: likeSchoolAnswer,
  });

  const healthProblems = await ask({
    label: 'Har ditt barn några hälsoproblem eller sjukdomar?',
    description: 'T.ex. hjärtfel, diabetes, astma.',
    id: 'psychologyAdviceChild.healthProblems',
  });

  significant.push({
    label: 'Har kända hälsoproblem eller sjukdomar',
    value: healthProblems,
  });

  if (healthProblems === 'yes') {
    const healthProblemsWhich = await ask.text({
      label: 'Ange ditt barns hälsoproblem eller sjukdomar.',
      id: 'psychologyAdviceChild.healthProblemsWhich',
    });

    significant.push({
      type: 'text',
      label: 'Beskrivning av kända hälsoproblem eller sjukdomar',
      value: healthProblemsWhich,
    });
  }

  const medicationAny = await ask({
    label: 'Tar ditt barn några läkemedel för tillfället?',
    id: 'psychologyAdviceChild.medicationAny',
  });

  significant.push({
    label: 'Tar läkemedel för tillfället',
    value: medicationAny,
  });

  if (medicationAny === 'yes') {
    const medicationWhich = await ask.text({
      label: 'Vilka läkemedel tar ditt barn?',
      description:
        'Ange läkemedel, styrka och dos, t.ex. Lamotrigin 200 mg, 2 tabletter 1 gång/dag',
      id: 'psychologyAdviceChild.medicationWhich',
    });

    significant.push({
      type: 'text',
      label: 'Beskrivning av läkemedel',
      value: medicationWhich,
    });
  }

  const otherCareContact = await ask({
    label: 'Har barnet någon annan pågående vårdkontakt?',
    id: 'psychologyAdviceChild.otherCareContact',
  });

  significant.push({
    label: 'Pågående vårdkontakt',
    value: otherCareContact,
  });

  if (otherCareContact === 'yes') {
    const careContactDescription = await ask.text({
      label: 'Beskriv barnets pågående vårdkontakt.',
      id: 'psychologyAdviceChild.careContactDescription',
    });

    significant.push({
      type: 'text',
      label: 'Pågående vårdkontakt - Beskrivning',
      value: careContactDescription,
    });
  }

  const other = await ask.text({
    label: 'Finns det något ytterligare som du vill att psykologen ska veta?',
    id: 'psychologyAdviceChild.other',
    optional: true,
  });

  significant.push({
    type: 'text',
    label: 'Övrig information',
    value: other,
  });

  await ask.info(``, {
    description: `
Ni har nu besvarat frågor som ger psykologen information om vad ni önskar hjälp med.

Våra psykologer arbetar vardagar, kl. 8–17. Om du upplever att ditt barn försämras innan ni får kontakt bör ni söka vård där ni bor. Är du osäker på vart ni bör vända er, ring sjukvårdsrådgivningen, telnr. 1177.
    
Ring alltid 112 om ditt barn behöver omedelbar hjälp. 
    `,
    optional: true,
    id: 'psychologyAdviceChild.infoText',
  });

  return {};
}: Guide);

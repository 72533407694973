import {
  ADD_TEST_CASE_STARTED,
  ADD_TEST_CASE_SUCCESS,
  ADD_TEST_CASE_FAILURE,
} from './types';
import { type TestCase } from '../../guides/test/types';
import type { AsyncAction, Dispatch, GetState } from '../types';

export const addTestCase = (testCase: TestCase): AsyncAction => async (
  dispatch: Dispatch,
  getState: GetState,
  getFirebase
) => {
  dispatch(addTestCaseStarted());

  try {
    // Remove undefineds, score functions etc
    // this looks a bit wired but is usually the most efficient method
    // as the JSON methods have native implementations.
    const sanitizedTestCase = JSON.parse(JSON.stringify(testCase));
    const result = await getFirebase()
      .firestore()
      .collection('inquisitor-test-cases')
      .add(sanitizedTestCase);
    dispatch(addTestCaseSuccess(result && result.id));
  } catch (err) {
    dispatch(addTestCaseFailure(err && err.message));
  }
};

const addTestCaseStarted = () => ({
  type: ADD_TEST_CASE_STARTED,
});

const addTestCaseSuccess = id => ({
  type: ADD_TEST_CASE_SUCCESS,
  payload: {
    id,
  },
});

const addTestCaseFailure = error => ({
  type: ADD_TEST_CASE_FAILURE,
  payload: {
    error,
  },
});

import healthProfile from '../healthProfile';

import common from '../common';

import {
  anticonceptionMedicationList,
  otherAnticonceptionMedication,
} from '../medications';
import ds from './decisionSupport';

export default async args => {
  const { ask, decisionSupport, significant } = args;

  function binary(answer) {
    if (answer === 'yes') {
      return true;
    }
    return false;
  }

  const started = {
    type: 'choice',
    label: 'När började besvären?',
    clinicLabel: 'Besvären började',
    options: [
      {
        value: 'lessThanTwelveMonths',
        label: 'För mindre än 12 månader sedan',
      },
      {
        value: 'oneToThreeYears',
        label: 'För 1–3 år sedan',
      },
      {
        value: 'moreThanThreeYears',
        label: 'För mer än 3 år sedan',
      },
    ],
  };

  const startedAnswer = await ask(started);

  significant.push({
    ...started,
    label: started.clinicLabel,
    value: startedAnswer,
  });

  const ongoingTreatment = {
    type: 'choice',
    label: 'Har du en pågående behandling mot dina premenstruella besvär?',
    clinicLabel: 'Pågående behandling',
    options: [
      {
        value: 'yesContent',
        label:
          'Ja, min behandling fungerar bra och jag önskar förnya mitt recept.',
      },
      {
        value: 'yesNotContent',
        label: 'Ja, men jag är inte nöjd med min behandling.',
      },
      {
        value: 'no',
        label: 'Nej',
      },
    ],
  };
  const ongoingTreatmentAnswer = await ask(ongoingTreatment);

  significant.push({
    ...ongoingTreatment,
    label: ongoingTreatment.clinicLabel,
    value: ongoingTreatmentAnswer,
  });

  if (
    ongoingTreatmentAnswer.includes('yesContent') ||
    ongoingTreatmentAnswer.includes('yesNotContent')
  ) {
    const medication = {
      type: 'choice',
      label:
        'Med vilket läkemedel behandlas du mot dina premenstruella besvär?',
      clinicLabel: 'Behandlas med',
      options: [
        {
          value: 'premalex10',
          label: 'Premalex 10 mg',
        },
        {
          value: 'premalex20',
          label: 'Premalex 20 mg',
        },
        {
          value: 'escitalopram10',
          label: 'Escitalopram (t.ex. Cipralex) 10 mg',
        },
        {
          value: 'escitalopram20',
          label: 'Escitalopram (t.ex. Cipralex) 20 mg',
        },
        {
          value: 'other',
          label: 'Annat (inklusive natur- och hälsokostpreparat)',
        },
      ],
    };

    const medicationAnswer = await ask(medication);

    significant.push({
      ...medication,
      label: medication.clinicLabel,
      value: medicationAnswer,
    });

    if (medicationAnswer.includes('other')) {
      const otherMedication = {
        type: 'text',
        label:
          'Med vilket annat läkemedel (inklusive natur- och hälsokostpreparat) behandlas du?',
        clinicLabel: 'Behandlas med, annat',
      };

      const otherMedicationAnswer = await ask(otherMedication);

      significant.push({
        ...otherMedication,
        label: otherMedication.clinicLabel,
        value: otherMedicationAnswer,
      });
    }

    const medicationWhen = {
      type: 'choice',
      label: 'När tar du ditt läkemedel?',
      clinicLabel: 'Tar läkemedel',
      options: [
        {
          value: 'constant',
          label: 'Under hela menscykeln',
        },
        {
          value: 'partly',
          label: 'Under delar av menscykeln',
        },
      ],
    };
    const medicationWhenAnswer = await ask(medicationWhen);

    significant.push({
      ...medicationWhen,
      label: medicationWhen.clinicLabel,
      value: medicationWhenAnswer,
    });

    const medicationFrequency = {
      type: 'number',
      label: 'Ange under hur många dagar i följd som du tar ditt läkemedel.',
      clinicLabel: 'Dagar i följd för läkemedelsintag',
    };

    const medicationFrequencyAnswer = await ask(medicationFrequency);

    significant.push({
      ...medicationFrequency,
      label: medicationFrequency.clinicLabel,
      value: medicationFrequencyAnswer,
    });
  }

  const symptoms = {
    id: 'symptoms',
    type: 'multipleChoice',
    label:
      'Vilka symptom upplever du dagarna före eller i samband med din mens?',
    description:
      'Flera svarsalternativ möjliga. Utgå från hur det har känts under det senaste året.',
    clinicLabel: 'Symptom',
    options: [
      {
        value: 'depression',
        label: 'Nedstämdhet',
      },
      {
        value: 'anxiety',
        label: 'Ångest',
      },
      {
        value: 'moody',
        label: 'Humörsvängningar',
      },
      {
        value: 'anger',
        label: 'Ilska/Irritabilitet',
      },
      {
        value: 'indifference',
        label: 'Minskat intresse för dagliga aktiviteter',
      },
      {
        value: 'concentration',
        label: 'Koncentrationssvårigheter',
      },
      {
        value: 'fatigue',
        label: 'Orkeslöshet/Minskad energi',
      },
      {
        value: 'food',
        label: 'Förändrad matlust',
      },
      {
        value: 'sleep',
        label:
          'Sömnstörningar, t.ex. svårt att sova eller överdrivet sömnbehov',
      },
      {
        value: 'overwhelmed',
        label: 'Känsla av att vara "överhopad" eller tappa kontrollen',
      },
      {
        value: 'physical',
        label:
          'Fysiska symptom, t.ex. bröstspänning, svullnadskänsla, huvudvärk, viktuppgång',
      },
    ],
  };

  const symptomsAnswer = await ask(symptoms);

  significant.push({
    ...symptoms,
    label: symptoms.clinicLabel,
    description: '',
    value: symptomsAnswer,
  });

  const symptomsWhen = {
    id: 'symptomsWhen',
    type: 'choice',
    label: 'Ungefär när i menscykeln upplever du att du har besvär?',
    description: 'Utgå från hur det har känts under det senaste året.',
    clinicLabel: 'Besvärsperiod',
    options: [
      {
        value: 'before',
        label: 'Perioden före mensen',
      },
      {
        value: 'beforeAndStart',
        label: 'Perioden före och i början av mensen',
      },
      {
        value: 'during',
        label: 'Under mensen',
      },
      {
        value: 'after',
        label: 'Perioden efter mensen',
      },
      {
        value: 'always',
        label: 'Under hela menscykeln, dvs alltid',
      },
    ],
  };

  const symptomsWhenAnswer = await ask(symptomsWhen);

  significant.push({
    ...symptomsWhen,
    label: symptomsWhen.clinicLabel,
    description: '',
    value: symptomsWhenAnswer,
  });

  if (symptomsWhenAnswer !== 'always') {
    const duration = {
      type: 'number',
      label:
        'Ungefär hur många dagar under en menscykel upplever du att du har besvär?',
      description: 'Utgå från hur det har känts under det senaste året.',
      clinicLabel: 'Antal dagar med besvär',
    };

    const durationAnswer = await ask(duration);

    significant.push({
      ...duration,
      label: duration.clinicLabel,
      description: '',
      value: durationAnswer,
    });
  }

  const symptomsDuration = {
    type: 'range',
    min: { value: 0, label: 'Ingen negativ inverkan' },
    max: { value: 10, label: 'Maximal negativ inverkan' },
    label:
      'Hur stor negativ inverkan upplever du att dina besvär har på ditt dagliga liv, t.ex. sociala relationer och skola/arbete?',
    description: 'Ange på skalan.',
    clinicLabel: 'Negativ inverkan på dagliga livet, skala 1-10',
  };

  const symptomsDurationAnswer = await ask(symptomsDuration);

  significant.push({
    ...symptomsDuration,
    label: symptomsDuration.clinicLabel,
    description: '',
    value: symptomsDurationAnswer,
  });

  const anticonception = {
    type: 'choice',
    label: 'Använder du något av följande preventivmedel?',
    clinicLabel: 'Använder preventivmedel',
    optional: true,
    options: [
      {
        value: 'pill',
        label: 'P-piller/Minipiller',
      },
      {
        value: 'patch',
        label: 'P-plåster',
      },
      {
        value: 'ring',
        label: 'P-ring',
      },
      {
        value: 'implant',
        label: 'P-stav',
      },
      {
        value: 'injection',
        label: 'P-spruta',
      },
      {
        value: 'hormoneIUD',
        label: 'Hormonspiral',
      },
      {
        value: 'copperIUD',
        label: 'Kopparspiral',
      },
      {
        value: 'otherNone',
        label: 'Annat/Inget',
      },
    ],
  };

  const anticonceptionAnswer = await ask(anticonception);

  significant.push({
    ...anticonception,
    label: anticonception.clinicLabel,
    value: anticonceptionAnswer,
  });

  if (anticonceptionAnswer.includes('pill')) {
    const anticonceptionPillsList = anticonceptionMedicationList.filter(med =>
      med.tags.includes('anticonception_pill')
    );

    const optionsList = [
      ...anticonceptionPillsList,
      otherAnticonceptionMedication,
    ];

    const anticonceptionPill = {
      id: 'anticonceptionPill',
      type: 'choice',
      options: optionsList,
      label: 'Vilket preventivmedel använder du?',
      clinicLabel: 'P-piller/Minipiller',
    };

    const anticonceptionPillAnswer = await ask(anticonceptionPill);

    significant.push({
      ...anticonceptionPill,
      label: anticonceptionPill.clinicLabel,
      value: anticonceptionPillAnswer,
    });

    if (anticonceptionPillAnswer.includes('other')) {
      const anticonceptionPillOther = {
        id: 'anticonceptionPillOther',
        type: 'text',
        label: 'Vilket annat preventivmedel använder du?',
        clinicLabel: 'P-piller/Minipiller, annat',
      };

      const anticonceptionPillOtherAnswer = await ask(anticonceptionPillOther);

      significant.push({
        ...anticonceptionPillOther,
        label: anticonceptionPillOther.clinicLabel,
        value: anticonceptionPillOtherAnswer,
      });
    }
  }

  if (anticonceptionAnswer.includes('hormoneIUD')) {
    const anticonceptionHormonalIUDList = anticonceptionMedicationList.filter(
      med => med.tags.includes('anticonception_hormonal_iud')
    );

    const optionsList = [
      ...anticonceptionHormonalIUDList,
      otherAnticonceptionMedication,
    ];

    const anticonceptionHormoneIUD = {
      id: 'anticonceptionHormoneIUD',
      type: 'choice',
      options: optionsList,
      label: 'Vilken hormonspiral använder du?',
      clinicLabel: 'Hormonspiral',
    };

    const anticonceptionHormoneIUDAnswer = await ask(anticonceptionHormoneIUD);

    significant.push({
      ...anticonceptionHormoneIUD,
      label: anticonceptionHormoneIUD.clinicLabel,
      value: anticonceptionHormoneIUDAnswer,
    });

    if (anticonceptionHormoneIUDAnswer.includes('other')) {
      const anticonceptionHormoneIUDOther = {
        id: 'anticonceptionHormoneIUDOther',
        type: 'text',
        label: 'Vilken annan hormonspiral använder du?',
        clinicLabel: 'Hormonspiral, annan',
      };

      const anticonceptionHormoneIUDOtherAnswer = await ask(
        anticonceptionHormoneIUDOther
      );

      significant.push({
        ...anticonceptionHormoneIUDOther,
        label: anticonceptionHormoneIUDOther.clinicLabel,
        value: anticonceptionHormoneIUDOtherAnswer,
      });
    }
  }

  if (anticonceptionAnswer.includes('ring')) {
    const anticonceptionRingList = anticonceptionMedicationList.map(med =>
      med.tags.includes('anticonception_ring')
    );

    const optionsList = [
      ...anticonceptionRingList,
      otherAnticonceptionMedication,
    ];

    const anticonceptionRing = {
      id: 'anticonceptionRing',
      type: 'choice',
      options: optionsList,
      label: 'Vilken p-ring använder du?',
      clinicLabel: 'P-ring',
    };

    const anticonceptionRingAnswer = await ask(anticonceptionRing);

    significant.push({
      ...anticonceptionRing,
      label: anticonceptionRing.clinicLabel,
      value: anticonceptionRingAnswer,
    });

    if (anticonceptionRingAnswer.includes('other')) {
      const anticonceptionRingOther = {
        id: 'anticonceptionRingOther',
        type: 'text',
        label: 'Vilken annan p-ring använder du?',
        clinicLabel: 'P-ring, annan',
      };

      const anticonceptionRingOtherAnswer = await ask(anticonceptionRingOther);

      significant.push({
        ...anticonceptionRingOther,
        label: anticonceptionRingOther.clinicLabel,
        value: anticonceptionRingOtherAnswer,
      });
    }
  }

  if (anticonceptionAnswer.includes('patch')) {
    const anticonceptionPatchList = anticonceptionMedicationList.map(med =>
      med.tags.includes('anticonception_patch')
    );

    const optionsList = [
      ...anticonceptionPatchList,
      otherAnticonceptionMedication,
    ];

    const anticonceptionPatch = {
      id: 'anticonceptionPatch',
      type: 'choice',
      options: optionsList,
      label: 'Vilket p-plåster använder du?',
      clinicLabel: 'P-plåster',
    };

    const anticonceptionPatchAnswer = await ask(anticonceptionPatch);

    significant.push({
      ...anticonceptionPatch,
      label: anticonceptionPatch.clinicLabel,
      value: anticonceptionPatchAnswer,
    });

    if (anticonceptionPatchAnswer.includes('other')) {
      const anticonceptionPatchOther = {
        id: 'anticonceptionPatchOther',
        type: 'text',
        label: 'Vilket annat p-plåster använder du?',
        clinicLabel: 'P-plåster, annat',
      };

      const anticonceptionPatchOtherAnswer = await ask(
        anticonceptionPatchOther
      );

      significant.push({
        ...anticonceptionPatchOther,
        label: anticonceptionPatchOther.clinicLabel,
        value: anticonceptionPatchOtherAnswer,
      });
    }
  }

  if (anticonceptionAnswer.includes('implant')) {
    const anticonceptionImplantList = anticonceptionMedicationList.map(med =>
      med.tags.includes('anticonception_implant')
    );
    const optionsList = [
      ...anticonceptionImplantList,
      otherAnticonceptionMedication,
    ];

    const anticonceptionImplant = {
      id: 'anticonceptionImplant',
      type: 'choice',
      options: optionsList,
      label: 'Vilken p-stav använder du?',
      clinicLabel: 'P-stav',
    };

    const anticonceptionImplantAnswer = await ask(anticonceptionImplant);

    significant.push({
      ...anticonceptionImplant,
      label: anticonceptionImplant.clinicLabel,
      value: anticonceptionImplantAnswer,
    });

    if (anticonceptionImplantAnswer.includes('other')) {
      const anticonceptionImplantOther = {
        id: 'anticonceptionImplantOther',
        type: 'text',
        label: 'Vilken annan p-stav använder du?',
        clinicLabel: 'P-stav, annan',
      };

      const anticonceptionImplantOtherAnswer = await ask(
        anticonceptionImplantOther
      );

      significant.push({
        ...anticonceptionImplantOther,
        label: anticonceptionImplantOther.clinicLabel,
        value: anticonceptionImplantOtherAnswer,
      });
    }
  }

  if (anticonceptionAnswer.includes('injection')) {
    const anticonceptionInjectionList = anticonceptionMedicationList.map(med =>
      med.tags.includes('anticonception_injection')
    );
    const optionsList = [
      ...anticonceptionInjectionList,
      otherAnticonceptionMedication,
    ];

    const anticonceptionInjection = {
      id: 'anticonceptionInjection',
      type: 'choice',
      options: optionsList,
      label: 'Vilken p-spruta använder du?',
      clinicLabel: 'P-spruta',
    };

    const anticonceptionInjectionAnswer = await ask(anticonceptionInjection);

    significant.push({
      ...anticonceptionInjection,
      label: anticonceptionInjection.clinicLabel,
      value: anticonceptionInjectionAnswer,
    });

    if (anticonceptionInjectionAnswer.includes('other')) {
      const anticonceptionInjectionOther = {
        id: 'anticonceptionInjectionOther',
        type: 'text',
        label: 'Vilken annan p-spruta använder du?',
        clinicLabel: 'P-spruta, annan',
      };

      const anticonceptionInjectionOtherAnswer = await ask(
        anticonceptionInjectionOther
      );

      significant.push({
        ...anticonceptionInjectionOther,
        label: anticonceptionInjectionOther.clinicLabel,
        value: anticonceptionInjectionOtherAnswer,
      });
    }
  }

  const antidepressant = {
    type: 'binary',
    label:
      'Använder du, eller har du använt, något läkemedel mot depression eller annan psykisk sjukdom?',
    clinicLabel: 'Använder/har använt läkemedel mot psykisk sjukdom',
  };

  const antidepressantAnswer = await ask(antidepressant);

  significant.push({
    ...antidepressant,
    label: antidepressant.clinicLabel,
    value: antidepressantAnswer,
  });

  if (binary(antidepressantAnswer)) {
    const medication = {
      type: 'text',
      label: 'Vilket läkemedel använder du, eller har du använt?',
      clinicLabel: 'Läkemedel mot psykisk sjukdom',
    };
    const medicationAnswer = await ask(medication);
    significant.push({
      ...medication,
      label: medication.clinicLabel,
      value: medicationAnswer,
    });
  }

  await common(args);

  const otherMedicalInfo = {
    id: 'otherMedicalInfo',
    type: 'text',
    label: 'Övrig medicinsk information',
    optional: true,
    description:
      'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
    clinicLabel: 'Övrigt',
  };

  const otherMedicalInfoAnswer = await ask(otherMedicalInfo);
  significant.push({
    ...otherMedicalInfo,
    description: '',
    label: otherMedicalInfo.clinicLabel,
    value: otherMedicalInfoAnswer,
  });

  const firstConditionPMDS = {
    type: 'string',
    options: ['depression', 'anxiety', 'moody', 'anger'],
  };

  const secondConditionPMDS = {
    type: 'string',
    options: [
      'indifference',
      'concentration',
      'fatigue',
      'food',
      'sleep',
      'overwhelmed',
      'physical',
    ],
  };

  let isInCat1 = false;
  symptomsAnswer.map(answer =>
    firstConditionPMDS.options.map(opt => {
      if (opt === answer) {
        isInCat1 = true;
      }
      return null;
    })
  );

  let isInCat2 = false;
  symptomsAnswer.map(answer =>
    secondConditionPMDS.options.map(opt => {
      if (opt === answer) {
        isInCat2 = true;
      }
      return null;
    })
  );

  const isPMDS = isInCat1 && isInCat2 && symptomsAnswer.length > 4;

  const isCyclic =
    symptomsWhenAnswer.includes('before') ||
    symptomsWhenAnswer.includes('beforeAndStart');

  if (isPMDS && isCyclic) {
    decisionSupport.push(ds.isPMDS);
  } else if (isCyclic) {
    decisionSupport.push(ds.isCyclic);
  } else {
    decisionSupport.push(ds.noPMDS);
  }

  await healthProfile(args);
};

export default {
  id: 'bugBite',
  version: '3-under18',
  type: 'formulary',
  abort: {
    condition: "get('answers.health.troubleBreathing') === 'yes'",
    description:
      '  <p>\n    Vi skulle gärna vilja hjälpa er\n  </p>\n\n  <p>\n    Eftersom andningssvårigheter efter ett insektsbett eller -stick är ett tecken på allvarlig reaktion, bör ni dock söka vård omedelbart.\n  </p>\n\n  <p>\n    Vänd er till närmaste akutmottagning. I nödsituation, ring 112.\n  </p>\n\n  <p>\n    Vi beklagar att vi inte kan hjälpa er den här gången.\n  </p>',
  },
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'health.troubleBreathing',
          type: 'binary',
          version: '1-under18',
          validations: {
            required: true,
          },
          label: 'Har ditt barn svårt att andas?',
          index: '0.0.0',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'started',
          version: '14-bugBite-under18',
          type: 'choice',
          options: [
            {
              value: 'lessThanFourHours',
              label: 'För mindre än 4 timmar sedan',
            },
            {
              value: 'fourHoursToFourtyEightHours',
              label: 'För 4–48 timmar sedan',
            },
            {
              value: 'twoToFourDays',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'moreThanFourDays',
              label: 'För mer än 4 dagar sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När blev ditt barn biten/stucken?',
          index: '0.1.0',
        },
        {
          id: 'bugBite.type.knows',
          version: '2-under18',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Vet du vilken sorts insekt ditt barn blivit biten/stucken av?',
          index: '0.1.1',
        },
        {
          id: 'bugBite.type.what',
          version: '2-under18',
          type: 'multipleChoice',
          options: [
            {
              value: 'bee',
              label: 'Bi',
            },
            {
              value: 'wasp',
              label: 'Geting',
            },
            {
              value: 'mosquito',
              label: 'Mygga',
            },
            {
              value: 'gnat',
              label: 'Knott',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          condition: "get('answers.bugBite.type.knows') === 'yes'",
          label: 'Vilken sorts insekt har stuckit/bitit ditt barn?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.1.2',
        },
        {
          id: 'bugBite.type.other',
          version: '2-under18',
          type: 'text',
          condition: "contains(get('answers.bugBite.type.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vad har ditt barn blivit biten/stucken av?',
          index: '0.1.3',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'bugBite.where',
          version: '2-under18',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Var på kroppen har ditt barn blivit biten/stucken?',
          index: '0.2.0',
        },
        {
          id: 'bugBite.amount',
          version: '2-under18',
          type: 'choice',
          options: [
            {
              value: 'one',
              label: 1,
            },
            {
              value: 'twoToFour',
              label: '2–4',
            },
            {
              value: 'moreThanFour',
              label: 'Fler än 4',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Hur många bett/stick har ditt barn?',
          index: '0.2.1',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'bugBite.symptoms.what',
          version: '3-under18',
          type: 'multipleChoice',
          options: [
            {
              value: 'itch',
              label: 'Klåda',
            },
            {
              value: 'swelling',
              label: 'Svullnad',
            },
            {
              value: 'blush',
              label: 'Rodnad',
            },
            {
              value: 'pain',
              label: 'Smärta',
            },
            {
              value: 'fever',
              label: 'Feber (över 38°C)',
            },
            {
              value: 'sick',
              label: 'Illamående',
            },
            {
              value: 'diarrheaVomit',
              label: 'Diarré och/eller kräkningar',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: false,
          },
          label: 'Vilka symptom har ditt barn?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.3.0',
        },
        {
          id: 'bugBite.symptoms.other',
          version: '1-under18',
          type: 'text',
          condition: "contains(get('answers.bugBite.symptoms.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilka andra symptom har ditt barn?',
          index: '0.3.1',
        },
        {
          id: 'bugBite.symptoms.itch.where',
          version: '1-under18',
          type: 'multipleChoice',
          options: [
            {
              value: 'local',
              label: 'Mitt barn har klåda kring bettet/sticket',
            },
            {
              value: 'fullBody',
              label: 'Mitt barn har klåda över hela kroppen',
            },
          ],
          condition: "contains(get('answers.bugBite.symptoms.what'), 'itch')",
          validations: {
            required: false,
          },
          label: 'Var sitter klådan?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.3.2',
        },
        {
          id: 'bugBite.symptoms.swelling.where',
          version: '1-under18',
          type: 'multipleChoice',
          options: [
            {
              value: 'local',
              label: 'Mitt barn är svullen kring bettet/sticket',
            },
            {
              value: 'faceNeckMouthTongue',
              label:
                'Mitt barn har svullnat upp i ansikte, hals, mun och/eller på tungan',
            },
          ],
          condition:
            "contains(get('answers.bugBite.symptoms.what'), 'swelling')",
          validations: {
            required: false,
          },
          label: 'Var sitter svullnaden?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.3.3',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'health.temperature',
          version: '4-under18',
          type: 'number',
          condition: "contains(get('answers.bugBite.symptoms.what'), 'fever')",
          validations: {
            required: true,
          },
          label: 'Hur hög feber har ditt barn just nu?',
          unit: '°C',
          index: '0.4.0',
        },
        {
          id: 'medication.recentAgainstFever',
          version: '2-under18',
          type: 'binary',
          condition: "contains(get('answers.bugBite.symptoms.what'), 'fever')",
          validations: {
            required: true,
          },
          label:
            'Har ditt barn fått febernedsättande läkemedel under de senaste 4 timmarna?',
          index: '0.4.1',
        },
      ],
      version: '1',
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'allergy.relatedToInsect.match',
          version: '2-under18',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Är ditt barn sedan tidigare allergiskt eller överkänsligt mot insektsbett?',
          index: '0.5.0',
        },
        {
          id: 'bugBite.similar.match',
          version: '2-under18',
          type: 'tertiary',
          validations: {
            required: true,
          },
          label:
            'Har ditt barn tidigare blivit biten/stucken av samma sorts insekt som ni nu söker hjälp för?',
          index: '0.5.1',
        },
        {
          id: 'bugBite.similar.difference',
          version: '2',
          type: 'choice',
          options: [
            {
              value: 'better',
              label: 'Symptomen är lindrigare nu',
            },
            {
              value: 'same',
              label: 'Symptomen är jämförbara med tidigare bett/stick',
            },
            {
              value: 'worse',
              label: 'Symptomen är värre nu',
            },
          ],
          condition: "get('answers.bugBite.similar.match') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Hur uppfattar du symptomen nu jämfört med vid tidigare bett/stick av samma typ av insekt?',
          index: '0.5.2',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'medication.tried',
          type: 'binary',
          version: '2-under18',
          validations: {
            required: true,
          },
          label:
            'Har ditt barn provat några läkemedel för att lindra de nuvarande symptomen?',
          index: '0.6.0',
        },
        {
          id: 'medication.against.bugBite.what',
          version: '3-under18',
          type: 'multipleChoice',
          options: [
            {
              value: 'alsol',
              label: 'Alsolsprit/alsollösning/alsolgel',
            },
            {
              value: 'xylocain',
              label: 'Bedövningskräm eller -salva (t.ex. EMLA, Xylocain)',
            },
            {
              value: 'cortisoneCream',
              label:
                'Kortisonkräm eller -salva (t.ex. Hydrokortison, Mildison)',
            },
            {
              value: 'antisepticCream',
              label: 'Bakteriedödande kräm (t.ex. LHP)',
            },
            {
              value: 'allergyPill',
              label: 'Allergitabletter (t.ex. Loratadin, Clarityn, Kestine)',
            },
            {
              value: 'cortisonePill',
              label: 'Kortisontabletter (Betapred)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          condition: "get('answers.medication.tried') === 'yes'",
          validations: {
            required: false,
          },
          label: 'Vilka läkemedel har ditt barn provat?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.6.1',
        },
        {
          id: 'medication.against.bugBite.whatOther',
          version: '1-under18',
          type: 'text',
          condition:
            "contains(get('answers.medication.against.bugBite.what'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilket annat läkemedel har ditt barn provat?',
          index: '0.6.2',
        },
        {
          id: 'medication.effect',
          type: 'text',
          version: '1',
          condition: "get('answers.medication.tried') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilken effekt hade läkemedlen?',
          index: '0.6.3',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'bugBite.pictures',
          type: 'upload',
          version: '3-under18',
          validations: {
            required: true,
            min: 2,
          },
          extras: {
            instructions: ['closeup', 'lighting'],
          },
          label:
            'Bifoga minst två bilder, en närbild och en översiktsbild för varje bett/stick.',
          description:
            '  <p>\n    Det går bra att ta bilderna med din mobilkamera, men undvik att använda dess zoom-funktion. Försök ta en så detaljerad närbild som möjligt i bra belysning.\n  </p>\n\n  <p>\n    Observera att även bilderna är en del av ditt barns journal och omfattas av sekretess.\n  </p>',
          index: '0.7.0',
        },
      ],
      version: '1',
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1-under18',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt barns huvudsakliga besvär?',
          index: '0.8.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2-under18',
          validations: {
            required: true,
            max: 250,
          },
          label:
            'Beskriv kortfattat vad du vill att vi ska hjälpa ditt barn med.',
          index: '0.8.1',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.9.0',
        },
      ],
      version: '1',
      index: '0.9',
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
        },
        {
          id: 'health.troubleBreathing',
          warning: "get('answers.health.troubleBreathing') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andningssvårigheter',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Biten/stucken',
          options: [
            {
              value: 'lessThanFourHours',
              label: 'För mindre än 4 timmar sedan',
            },
            {
              value: 'fourHoursToFourtyEightHours',
              label: 'För 4–48 timmar sedan',
            },
            {
              value: 'twoToFourDays',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'moreThanFourDays',
              label: 'För mer än 4 dagar sedan',
            },
          ],
        },
        {
          id: 'bugBite.type.knows',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Känd bett/stickorsak',
        },
        {
          id: 'bugBite.type.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Bett/stickorsak',
          description: 'Flera svarsalternativ möjliga.',
          options: [
            {
              value: 'bee',
              label: 'Bi',
            },
            {
              value: 'wasp',
              label: 'Geting',
            },
            {
              value: 'mosquito',
              label: 'Mygga',
            },
            {
              value: 'gnat',
              label: 'Knott',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'bugBite.type.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Biten/stucken av, annat',
        },
        {
          id: 'bugBite.where',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Lokalisation av bett/stick',
        },
        {
          id: 'bugBite.amount',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Antal bett/stick',
          options: [
            {
              value: 'one',
              label: 1,
            },
            {
              value: 'twoToFour',
              label: '2–4',
            },
            {
              value: 'moreThanFour',
              label: 'Fler än 4',
            },
          ],
        },
        {
          id: 'bugBite.symptoms.what',
          warning:
            "contains(get('answers.bugBite.symptoms.what'), 'fever') || contains(get('answers.bugBite.symptoms.what'), 'sick') || contains(get('answers.bugBite.symptoms.what'), 'diarrheaVomit')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Symptom',
          description: '',
          options: [
            {
              value: 'itch',
              label: 'Klåda',
            },
            {
              value: 'swelling',
              label: 'Svullnad',
            },
            {
              value: 'blush',
              label: 'Rodnad',
            },
            {
              value: 'pain',
              label: 'Smärta',
            },
            {
              value: 'fever',
              label: 'Feber (över 38°C)',
            },
            {
              value: 'sick',
              label: 'Illamående',
            },
            {
              value: 'diarrheaVomit',
              label: 'Diarré och/eller kräkningar',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'bugBite.symptoms.other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra symptom',
        },
        {
          id: 'bugBite.symptoms.itch.where',
          warning:
            "contains(get('answers.bugBite.symptoms.itch.where'), 'fullBody')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Klåda',
          description: '',
          options: [
            {
              value: 'local',
              label: 'Mitt barn har klåda kring bettet/sticket',
            },
            {
              value: 'fullBody',
              label: 'Mitt barn har klåda över hela kroppen',
            },
          ],
        },
        {
          id: 'bugBite.symptoms.swelling.where',
          warning:
            "contains(get('answers.bugBite.symptoms.swelling.where'), 'faceNeckMouthTongue')",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Svullnad',
          description: '',
          options: [
            {
              value: 'local',
              label: 'Mitt barn är svullen kring bettet/sticket',
            },
            {
              value: 'faceNeckMouthTongue',
              label:
                'Mitt barn har svullnat upp i ansikte, hals, mun och/eller på tungan',
            },
          ],
        },
        {
          id: 'health.temperature',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Temperatur',
          description: '',
          unit: '°C',
        },
        {
          id: 'medication.recentAgainstFever',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Febernedsättande läkemedel under de senaste 4 timmarna',
          description: '',
        },
        {
          id: 'allergy.relatedToInsect.match',
          warning: "get('answers.allergy.relatedToInsect.match') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Allergi/överkänslighet insektsbett',
        },
        {
          id: 'bugBite.similar.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Biten/stucken av samma sorts insekt tidigare',
        },
        {
          id: 'bugBite.similar.difference',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Symptom nu jämfört med tidigare bett/stick',
          options: [
            {
              value: 'better',
              label: 'Symptomen är lindrigare nu',
            },
            {
              value: 'same',
              label: 'Symptomen är jämförbara med tidigare bett/stick',
            },
            {
              value: 'worse',
              label: 'Symptomen är värre nu',
            },
          ],
        },
        {
          id: 'medication.tried',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Provat läkemedel för nuvarande besvär',
        },
        {
          id: 'medication.against.bugBite.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedel',
          description: 'Flera svarsalternativ möjliga.',
          options: [
            {
              value: 'alsol',
              label: 'Alsolsprit/alsollösning/alsolgel',
            },
            {
              value: 'xylocain',
              label: 'Bedövningskräm eller -salva (t.ex. EMLA, Xylocain)',
            },
            {
              value: 'cortisoneCream',
              label:
                'Kortisonkräm eller -salva (t.ex. Hydrokortison, Mildison)',
            },
            {
              value: 'antisepticCream',
              label: 'Bakteriedödande kräm (t.ex. LHP)',
            },
            {
              value: 'allergyPill',
              label: 'Allergitabletter (t.ex. Loratadin, Clarityn, Kestine)',
            },
            {
              value: 'cortisonePill',
              label: 'Kortisontabletter (Betapred)',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'medication.against.bugBite.whatOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Annat läkemedel',
        },
        {
          id: 'medication.effect',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Läkemedelseffekt',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Övrig medicinsk information',
          description: '',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Insektsbett och -stick',
  index: '0',
};

export default {
  acidReflux: require('../../images/guides/ill_ont_i_halsen.svg'),
  acne: require('../../images/guides/ill_akne.svg'),
  allergy: require('../../images/guides/ill_allergi.svg'),
  asthma: require('../../images/guides/ill_andningsbesvar.svg'),
  asthmaReview: require('../../images/guides/ill_asthma.svg'),
  asthmaReviewReturnVisit: require('../../images/guides/ill_asthma.svg'),
  backpain: require('../../images/guides/ill_ryggont.svg'),
  borrelia: require('../../images/guides/ill_borrelia.svg'),
  bugBite: require('../../images/guides/ill_insektsbett.svg'),
  chlamydia: require('../../images/guides/ill_klamydia.svg'),
  conjunctivitis: require('../../images/guides/ill_ogoninflammation.svg'),
  contraception: require('../../images/guides/ill_preventivmedel.svg'),
  coronaTest: require('../../images/guides/ill_infektioner.svg'),
  coronaTestReturnVisit: require('../../images/guides/ill_infektioner.svg'),
  cough: require('../../images/guides/ill_hosta.svg'),
  eczema: require('../../images/guides/ill_hudbesvar.svg'),
  gastricProblemsLower: require('../../images/guides/ill_magbesvar_nedre.svg'),
  gastricProblemsUpper: require('../../images/guides/ill_magbesvar_ovre.svg'),
  headache: require('../../images/guides/ill_huvudvark.svg'),
  healthCheck: require('../../images/guides/ill_placeholder.svg'),
  herpes: require('../../images/guides/ill_herpes.svg'),
  kioskBloodpressure: require('../../images/guides/ill_bloodpressure.svg'),
  mentalHealth: require('../../images/guides/ill_stress_oro_depression.svg'),
  onychomycosis: require('../../images/guides/ill_nagelsvamp.svg'),
  otherProblems: require('../../images/guides/ill_ovriga_besvar.svg'),
  physio: require('../../images/guides/ill_muskelochledbesvar.svg'),
  prescriptionRenewal: require('../../images/guides/ill_receptfornyelse.svg'),
  sickNote: require('../../images/guides/sickNote.svg'),
  skinRashesChild: require('../../images/guides/ill_barn_eksem.svg'),
  testResults: require('../../images/guides/bloodTestResult.svg'),
  tonsillitis: require('../../images/guides/ill_ont_i_halsen.svg'),
  uti: require('../../images/guides/ill_urinvagsbesvar.svg'),
  vaccination: require('../../images/guides/ill_vaccination.svg'),
  soreThroatChild: require('../../images/guides/ill_barn_ont_i_halsen.svg'),
  coughChildren: require('../../images/guides/ill_hosta.svg'),
  placeholder: require('../../images/guides/ill_placeholder.svg'),
  unhappy: require('../../images/guides/ill_stress_oro_depression.svg'),
};

export default {
  'guides.back': 'Back',
  'guides.previousQuestion': 'Previous question',
  'guides.choose': 'Choose a guide',
  'guides.clear': 'Clear answers',
  'guides.switch': 'Switch guide',
  'guides.random': 'Random answers',
  'guides.source': 'View source',
  'guides.saveTest': 'Save Test',
  'guides.goto.question': 'Go to question',
  'guides.goto.result': 'Result',
  'guides.goto.last': 'Jump forward',
  'guides.decisionSupport.more': 'More triggers',
  'guides.cancelModal.title': 'Are you sure you want to finish?',
  'guides.cancelModal.body':
    'Your consultation will be saved as a draft, which you can complete later.',
  'guides.cancelModal.cancel': 'Cancel',
  'guides.cancelModal.confirm': 'Finish',

  'guides.title': 'Guides',
  'guides.title.acidReflux': 'Acid reflux',
  'guides.title.acne': 'Acne',
  'guides.title.asthmaReview': 'Asthma Review',
  'guides.title.asthmaReviewReturnVisit': 'Asthma Review Return Visit',
  'guides.title.allergy': 'Allergy',
  'guides.title.asthma': 'Asthma',
  'guides.title.backpain': 'Backpain',
  'guides.title.borrelia': 'Borrelia',
  'guides.title.contraception': 'Contraception',
  'guides.title.coronaTest': 'Corona Test',
  'guides.title.coronaTestReturnVisit': 'Corona Test Return Visit',
  'guides.title.cough': 'Cough',
  'guides.title.coughChildren': 'Cough Children',
  'guides.title.eczema': 'Eczema',
  'guides.title.mentalHealth': 'Mental health',
  'guides.title.genericDemoGuide': 'Generic Demo Guide',
  'guides.title.otherProblems': 'Other problems',
  'guides.title.prescriptionRenewal': 'Repeat prescription',
  'guides.title.prescriptionRenewalChild': 'Repeat prescription for my child',
  'guides.title.returnVisitChild': 'Return visit child',
  'guides.title.sickNote': 'Sick Note',
  'guides.title.skinRashesChild': 'Child with a skin rash',
  'guides.title.soreThroatChild': 'Sore throat Children',
  'guides.title.testResults': 'Test Results',
  'guides.title.tonsillitis': 'Sore throat',
  'guides.title.uti': 'Urinary tract infection',
  'guides.title.vaccination': 'Vaccination',
  'guides.title.surveyCaseClosed': 'Survey - Case Closed',

  'guides.title.dummy': 'Dummy',
  'guides.title.unhappy': 'Unhappy',
  'guides.title.example': 'Example guide',

  'modules.intro':
    'Modules are testable blocks of questions used in guides but not accessible externally by themselves',
  'modules.title': 'Modules',
  'modules.title.moduleAlcohol': 'Alcohol',
  'modules.title.moduleAllergies': 'Allergies',
  'modules.title.moduleAlternateMedication': 'Alternate Medication',
  'modules.title.moduleBmi': 'BMI',
  'modules.title.moduleCoronaScreening': 'Corona Screening',
  'modules.title.moduleCoronavirus': 'Coronavirus',
  'modules.title.moduleCoronavirusChild': 'Coronavirus Child',
  'modules.title.moduleEmergency': 'Emergency',
  'modules.title.moduleEndMessage': 'End Message',
  'modules.title.modulePharmacy': 'Pharmacy',
  'modules.title.moduleSmoking': 'Smoking',

  'input.yes': 'Yes',
  'input.no': 'No',
  'input.unknown': "Don't know",
  'input.upload': 'Upload',
  'input.upload.skip': 'Skip',
  'input.unanswered': 'Unanswered',
  'input.placeholder': 'Your answer',
  'input.noneOfTheAbove': 'None of the above',
  'input.next': 'Next',
  'input.unit': 'Enter {unit}',
  'input.noPhoto': 'No photo uploaded',

  'errors.required': 'Answer required',
  'errors.min': 'Answer is too low',
  'errors.max': 'Answer is too high',

  'patient.title': 'Patient',
  'patient.gender': 'Gender',
  'patient.female': 'Female',
  'patient.male': 'Male',
  'patient.age': 'Age',
  'patient.years': 'years',
  'patient.cancel': 'Cancel',
  'patient.save': 'Save',
  'patient.domain': 'Domain',
};

// @flow
import { type DecisionSupport } from '../../types';

const sepsisPhone = `Potential signs of sepsis. Use telephone or video call to assess further. If unable to contact the patient, use clinical discretion and send either "Emergency care" or "Urgent GP Appointment" message.`;

const decisionSupport: { [string]: DecisionSupport } = {
  FPS1orbelow: {
    color: 'black',
    label: 'FeverPAIN Score 0 - 1',
    description: 'Advice only - self care',
  },

  FPS2or3: {
    color: 'black',
    label: 'FeverPAIN Score 2 - 3',
    description: 'Delayed prescription antibiotics',
  },

  FPSabove4: {
    color: 'black',
    label: 'FeverPAIN Score ≥ 4',
    description: `Antibiotics / Delayed prescription`,
  },

  lymphadenopathy: {
    label: 'Lymphadenopathy',
    description: 'Potential glandular fever. Urgent GP appointment.',
    color: 'red',
  },

  feverWithARash: {
    label: 'Fever with a rash',
    description: sepsisPhone,
    color: 'red',
  },

  tummyPain: {
    label: 'Abdominal/ tummy pain',
    description: sepsisPhone,
    color: 'red',
  },

  diarrhoea: {
    label: 'Diarrhoea',
    description: sepsisPhone,
    color: 'red',
  },

  vomiting: {
    label: 'Vomiting',
    description: sepsisPhone,
    color: 'red',
  },

  rashIncompleteVaccination: {
    label: 'Rash with incomplete vaccination schedule',
    description: 'Urgent GP appointment',
    color: 'red',
  },

  antibiotics: {
    label: `Antibiotics for sore throat in last 1/12`,
    description: 'Urgent GP appointment',
    color: 'red',
  },

  RecurrentTonsillitis7: {
    label: 'Recurrent tonsillitis',
    description: `If ≥ 7 episodes in the past 12/12, refer to ENT.`,
    color: 'black',
  },

  RecurrentTonsillitis5: {
    label: 'Recurrent tonsillitis',
    description: `≥ 10 episodes in the last 2 years, refer to ENT.`,
    color: 'black',
  },
  persistentSoreThroat: {
    id: 'persistentSoreThroat',
    color: 'black',
    label: 'Persistent sore throat',
    description: `If FeverPAIN Score ≥ 4 — treat with antibiotics, otherwise refer to GP.`,
  },

  symptomsImproving: {
    id: 'symptomsImproving',
    color: 'black',
    label: 'Sore throat improving',
    description: 'Likely viral',
  },

  unilateralThroat: {
    id: 'unilateralThroat',
    color: 'black',
    label: 'Unilateral sore throat',
    description: 'Increased likelihood of bacterial',
  },

  softFoods: {
    id: 'softFoods',
    label: 'Reject - Urgent Appointment',
    description: 'Unable to swallow soft foods',
    color: 'red',
  },

  persistentFever: {
    id: 'persistentFever',
    label: 'Persistent fever despite anti-pyretic',
    description: 'Urgent GP appointment',
    color: 'red',
  },

  earAche: {
    id: 'earAche',
    label: 'Earache - Possible acute otits media',
    description: 'Urgent GP appointment',
    color: 'red',
  },

  coryzalSymptoms: {
    id: 'coryzalSymptoms',
    label: 'Coryzal symptoms',
    description: 'Likely viral',
    color: 'black',
  },
};

Object.keys(decisionSupport).forEach(id => (decisionSupport[id].id = id));

export default decisionSupport;

import isEqual from 'lodash/isEqual';
import type { DecisionSupport } from '../../types';

// collates ds with the same labels into one ds with a multi line description.
// only those labels that are given as subsequent arguments are collated.
const collateDs = (decisionSupport: DecisionSupport[]) => {
  const labels = {};
  for (let i = 0; i < decisionSupport.length; i++) {
    const ds = decisionSupport[i];

    // check for full dupes first
    let dupe = false;
    for (let j = 0; j < i; j++) {
      if (isEqual(ds, decisionSupport[j])) {
        dupe = true;
        break;
      }
    }
    if (dupe) {
      // delete the current item and move back to accommodate the shorter array.
      decisionSupport.splice(i, 1);
      i--;
      continue;
    }

    const labelKey =
      ds && ds.label !== undefined
        ? `${ds.label} ${ds.color || ''}`
        : undefined;
    if (labelKey === undefined) {
      continue;
    }

    if (labels[labelKey] === undefined) {
      labels[labelKey] = { index: i, updated: false };
      continue;
    }
    const { index: matchedDsIndex, updated: matchedDsUpdated } = labels[
      labelKey
    ];
    const matchedDs = decisionSupport[matchedDsIndex];
    const existingDescription = matchedDsUpdated
      ? matchedDs.description || ''
      : `- ${matchedDs.description || ''}`;
    const newDs = {
      ...matchedDs,
      description: `${existingDescription} \n- ${ds.description || ''}`,
    };
    decisionSupport.splice(matchedDsIndex, 1, newDs);
    labels[labelKey].updated = true;

    // delete the current item and move back to accommodate the shorter array.
    decisionSupport.splice(i, 1);
    i--;
  }
};

export default collateDs;

// @flow

// Helper function that creates a multiple choice question with an option for
// a free text 'other' entry. This is represented in clinic as a single question and set of answers.
// This can work with either one or more multiple choice questions to allow splitting large
// numbers of options over multiple questions.
// In reality this asks one or more questions and rolls the answers up into a single clinic entry.

import type { Ask, RawAnswer, DecisionSupport, Export } from '../../types';
import { createQuestionToAsk, handleExtendedAnswer } from './extendedChoice';
import type { ExtendedChoiceOption } from './extendedChoice';

import { ensureArray } from './arrayUtils';

type MultipleChoiceQuestion = {
  id?: string,
  label?: string,
  description?: string,
  options: ExtendedChoiceOption[],
};

type ExtendedMultipleChoiceWithOther = {
  ask: Ask,
  significant: RawAnswer[],
  decisionSupport: DecisionSupport[],
  exports: Export[],
  multipleChoiceQuestions: MultipleChoiceQuestion | MultipleChoiceQuestion[],
  otherQuestion: {
    otherValue: string,
    id?: string,
    label?: string,
    description?: string,
    hide?: boolean,
    warn?: boolean,
  },
  clinicLabel: string,
  expires?: number,
  optional?: boolean,
};
const extendedMultipleChoiceWithOther = async ({
  ask,
  significant,
  decisionSupport,
  exports,
  multipleChoiceQuestions,
  otherQuestion,
  clinicLabel,
  expires,
  optional,
}: ExtendedMultipleChoiceWithOther): Promise<*> => {
  if (ensureArray(multipleChoiceQuestions).length > 1 && optional === false) {
    throw new Error(
      'optional must be true when multiple questions are provided'
    );
  }

  const questionsToAsk = ensureArray(multipleChoiceQuestions).map(q =>
    createQuestionToAsk({
      ...q,
      isMultipleChoice: true,
      optional,
      expires,
    })
  );

  let answers = [];
  for (let i = 0; i < questionsToAsk.length; i++) {
    answers = answers.concat(ensureArray(await ask(questionsToAsk[i])));
  }

  const askedOptions = questionsToAsk
    .map(q => q.options)
    .reduce((arr, opts) => arr.concat(ensureArray(opts)), [])
    .filter(o => o.value !== otherQuestion.otherValue);

  const extendedOptions = ensureArray(multipleChoiceQuestions)
    .map(eq => eq.options)
    .reduce((arr, opts) => arr.concat(opts), []);

  const otherIndex = answers.indexOf(otherQuestion.otherValue);
  if (otherIndex >= 0) {
    const otherAnswer = await ask({
      id: otherQuestion.id,
      type: 'text',
      label: otherQuestion.label,
      description: otherQuestion.description,
      expires,
    });
    answers.push(otherAnswer);
    answers.splice(otherIndex, 1);
    askedOptions.push({
      value: otherAnswer,
      label: otherAnswer,
    });
    extendedOptions.push({
      value: otherAnswer,
      label: otherAnswer,
      hide: otherQuestion.hide,
      warn: otherQuestion.warn,
    });
  }

  return handleExtendedAnswer({
    questionAsked: {
      ...questionsToAsk[0],
      options: askedOptions,
    },
    answer: answers,
    significant,
    decisionSupport,
    exports,
    isMultipleChoice: true,
    clinicLabel,
    extendedOptions,
  });
};

export default ({
  ask,
  significant,
  decisionSupport,
  exports,
}: {
  ask: Ask,
  significant: RawAnswer[],
  decisionSupport: DecisionSupport[],
  exports: Export[],
}) => ({
  multipleChoiceQuestions,
  otherQuestion,
  clinicLabel,
  expires,
  optional,
}: {
  multipleChoiceQuestions: MultipleChoiceQuestion | MultipleChoiceQuestion[],
  otherQuestion: {
    otherValue: string,
    id?: string,
    label?: string,
    description?: string,
    hide?: boolean,
    warn?: boolean,
  },
  clinicLabel: string,
  expires?: number,
  optional?: boolean,
}) =>
  extendedMultipleChoiceWithOther({
    ask,
    significant,
    decisionSupport,
    exports,
    multipleChoiceQuestions,
    otherQuestion,
    clinicLabel,
    expires,
    optional,
  });

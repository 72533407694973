import type { Guide } from '../../../types';
import createExtendedChoice from '../../common/extendedChoice';
import createExtendedText from '../../common/extendedText';

export default (async args => {
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  const areYou = await eChoice({
    id: 'moduleAlternateMedication.areYou',
    label:
      'Are you using any medications or natural remedies which have NOT been prescribed by your usual GP?',
    description:
      'For example, a medication prescribed by a hospital or online doctor',
    clinicLabel: 'Alternate medication',
    expires: 90,
    options: [
      {
        value: 'yes',
        hide: true,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (areYou === 'yes') {
    await eText({
      id: 'moduleAlternateMedication.listOf',
      label:
        'Please list any your medications or natural remedies which have not been prescribed by your usual GP.',
      description:
        'Include the name, strength and dose, and the reason you are taking it. E.g. Levothyroxine, 100 micrograms, 1 tablet once daily for an underactive thyroid.',
      clinicLabel: 'Medications or natural remedies not prescribed by GP',
      expires: 90,
    });
  }

  return {};
}: Guide);

import { type Action } from '../types';

import {
  type State,
  ADD_TEST_CASE_STARTED,
  ADD_TEST_CASE_SUCCESS,
  ADD_TEST_CASE_FAILURE,
} from './types';

export default (
  state: State = { current: { saving: false, saveError: undefined } },
  action: Action
): State => {
  const { type, payload = {} } = action;

  switch (type) {
    case ADD_TEST_CASE_STARTED:
      return {
        ...state,
        current: {
          ...state.current,
          saving: true,
          saveError: undefined,
        },
      };
    case ADD_TEST_CASE_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          id: payload.id,
          saving: false,
          saveError: undefined,
        },
      };
    case ADD_TEST_CASE_FAILURE:
      return {
        ...state,
        current: {
          ...state.current,
          saving: false,
          saveError: payload.error,
        },
      };

    default:
      return state;
  }
};

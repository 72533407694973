import * as React from 'react';

import { _t } from '../../i18n';

import Choice from './Choice';

const options = () => [
  {
    label: _t('input.yes'),
    value: 'yes',
  },
  {
    label: _t('input.no'),
    value: 'no',
  },
  {
    label: _t('input.unknown'),
    value: 'unknown',
  },
];

class Tertiary extends React.Component<{
  value?: *,
  update: (?*) => *,
  done: (?*) => *,
}> {
  render() {
    const { value, update, done } = this.props;

    return (
      <Choice value={value} options={options()} update={update} done={done} />
    );
  }
}

export default Tertiary;

import type { Guide } from '../../types';

import aborts from './aborts';

export default (async ({ patient: { age } }) => {
  if (age > 12) {
    return { abort: aborts.over12 };
  }

  return {};
}: Guide);

import * as React from 'react';

import { type Export as ExportType } from '../../../guides/types';

import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

import colors from '../../theme/colors';

import Markdown from '../../components/Markdown';

class Exports extends React.Component<{
  exports: ExportType[],
  classes: { [string]: string },
}> {
  render() {
    const { exports = [], classes } = this.props;

    if (exports.length === 0) return null;

    const grouped = {};
    exports.forEach(
      ({ id, value }) =>
        (grouped[id] = [
          ...(grouped[id] || []),
          ...(Array.isArray(value) ? value : [value]),
        ])
    );

    return (
      <div className={classes.root}>
        {Object.keys(grouped).map(id => (
          <div key={id} className={classes.export}>
            <Typography className={classes.id}>{id}</Typography>
            {grouped[id].map((v, i) =>
              id === 'prescriptionWarnings' ? (
                <Markdown key={i} className={classes.warning}>
                  {v}
                </Markdown>
              ) : (
                <Typography key={i}>{v}</Typography>
              )
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    backgroundColor: colors.grey_100,
    margin: `${spacing(3)}px -2000px ${spacing(3)}px -2000px`,
    padding: `${spacing(3)}px 2000px ${spacing(3)}px 2000px`,
    width: '100%',
    [down('xs')]: {
      margin: `${spacing(1.5)}px -${spacing(3)}px ${spacing(3)}px -${spacing(
        3
      )}px`,
      padding: spacing(3),
    },
  },

  export: {
    marginBottom: spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  id: {
    fontWeight: 'bold',
  },

  warning: {
    marginBottom: spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))(Exports);

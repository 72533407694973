const contraceptive = (label, description) => ({
  id: 'contraceptive',
  label,
  description,
  color: 'black',
});

export const supply = {
  '3Months': '3 months supply only',
  '6Months': '6 months supply only',
  '3To6Months': '3 - 6 months supply',
  '12Months': 'Up to 12 months supply',
  '3For12Repeat': '3/12 Repeat dispensing for up to 12 months',
};

const repeatContraceptive = (name: string, description?: string) =>
  contraceptive(`Repeat prescription - ${name}`, description);
const restartContraceptive = (name: string, description?: string) =>
  contraceptive(`Restart prescription - ${name}`, description);
const changeContraceptive = (name: string) =>
  contraceptive(`Change contraceptive - ${name}`, supply['3Months']);
const startContraceptive = (name: string) =>
  contraceptive(`New starter - ${name}`, supply['3Months']);
const swapContraceptive = (name: string, reason: string) =>
  contraceptive(
    `Start/change contraceptive - ${name}`,
    `${reason}, ${supply['3Months']}`
  );

const doctorChooseContraceptive = 'Dr to choose pill';
const sendInfoContraceptive = 'Send info about pill choices';
const skinPatchContraceptive = 'Evra';
const vaginalRingContraceptive = 'NuvaRing';
const lowDoseContraceptive = 'Loestrin 20';
const progesteroneContraceptive = 'Desogestrel';

const doctorToDecide = (id, description) => ({
  id,
  label: 'Doctor to decide',
  description,
  color: 'green',
});

export const decisionSupports = {
  breastCancer: {
    id: 'breastCancer',
    label: '? At risk of breast cancer',
    description: 'Check before prescribing',
    color: 'black',
  },
  vaginalBleeding: doctorToDecide('vaginalBleeding', 'Vaginal bleeding'),
  otherReason: doctorToDecide(
    'otherReason',
    'Check reason for wanting contraception'
  ),
  gestationalHypertension: doctorToDecide(
    'gestationalHypertension',
    'Gestational hypertension'
  ),
  migrainesWithAura: doctorToDecide(
    'migrainesWithAura',
    'Migraine aura symptoms'
  ),
  majorSurgery: doctorToDecide('majorSurgery', 'Major surgery'),
  mobility: doctorToDecide('mobility', 'Mobility problems'),
  bloodClot: doctorToDecide('bloodClot', 'FH Blood clot'),
  skinPatchContraindicated: {
    label: '≥ 90kg Evra contraindicated',
    color: 'red',
    priority: 20,
  },
  chcContraindicated: (reasons: string[]) => ({
    label: 'CHC Contraindicated - offer POP',
    description: reasons.join(', '),
    color: 'red',
    priority: 10,
  }),
  repeatContraceptive,
  repeatContraceptive12Months: (name: string) =>
    repeatContraceptive(name, supply['12Months']),
  repeatContraceptiveSkinPatch: repeatContraceptive(
    skinPatchContraceptive,
    supply['12Months']
  ),
  repeatContraceptiveVaginalRing: repeatContraceptive(
    vaginalRingContraceptive,
    supply['3For12Repeat']
  ),
  restartContraceptive,
  restartContraceptiveSkinPatch: (description?: string) =>
    restartContraceptive(skinPatchContraceptive, description),
  restartContraceptiveVaginalRing: (description?: string) =>
    restartContraceptive(vaginalRingContraceptive, description),
  changeContraceptive,
  changeContraceptiveDoctorChoose: changeContraceptive(
    doctorChooseContraceptive
  ),
  changeContraceptiveSkinPatch: changeContraceptive(skinPatchContraceptive),
  changeContraceptiveVaginalRing: changeContraceptive(vaginalRingContraceptive),
  startContraceptive,
  startContraceptiveSendInfo: startContraceptive(sendInfoContraceptive),
  startContraceptiveDoctorChoose: startContraceptive(doctorChooseContraceptive),
  startContraceptiveSkinPatch: startContraceptive(skinPatchContraceptive),
  startContraceptiveVaginalRing: startContraceptive(vaginalRingContraceptive),
  swapContraceptive,
  swapContraceptiveLowDose: (reason: string) =>
    swapContraceptive(lowDoseContraceptive, reason),
  swapContraceptiveProgesterone: (reason: string) =>
    swapContraceptive(progesteroneContraceptive, reason),
};

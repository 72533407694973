import type { GuideWithOut } from '../../types';

import aborts from './aborts';
import { decisionSupports } from './decisionSupport';
import triedPills from './triedPills';

export default (async args => {
  const { ask, significant, decisionSupport } = args;

  const wantQuestion = {
    type: 'choice',
    label: `We are unable to offer injections, implants, coils and some other contraceptives online. What would you like to do?`,
    options: {
      doctorChoose: 'Let the doctor choose a pill for me',
      skinPatch: 'Try the skin patch',
      vaginalRing: 'Try the vaginal ring',
      appointment:
        'Get an appointment for the injection, implant, coil (IUD) or Mirena (IUS)',
    },
  };
  const want = await ask(wantQuestion);
  significant.push({
    ...wantQuestion,
    value: want,
    warn: 'appointment',
  });

  let abort;

  if (want === 'appointment') {
    abort = aborts.appointment;
  } else if (want === 'doctorChoose') {
    await triedPills(args);

    decisionSupport.push(decisionSupports.changeContraceptiveDoctorChoose);
  } else if (want === 'skinPatch') {
    decisionSupport.push(decisionSupports.changeContraceptiveSkinPatch);
  } else if (want === 'vaginalRing') {
    decisionSupport.push(decisionSupports.changeContraceptiveVaginalRing);
  }

  return { abort, significant, want };
}: GuideWithOut<{ want?: string }>);

import { type Patient } from '../../guides/types';

import { type Action } from '../types';

import { PATIENTS_UPDATE } from './types';

export const updatePatient = (patient: Patient): Action => ({
  type: PATIENTS_UPDATE,
  payload: { patient },
});

import type { Guide } from '../../types';

import aborts from './aborts';
import ds from './decisionSupport';
import commonAborts from '../common/commonAborts';
import createExtendedChoice from '../common/extendedChoice';
import createExtendedText from '../common/extendedText';

export default (async args => {
  const {
    ask,
    patient: { gender, age },
    significant,
    decisionSupport,
    exports,
  } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  await ask.info({
    description:
      'Before we begin, we need to ask a few questions to make sure it is safe to treat you online...',
  });

  if (gender === 'female' && age < 55) {
    const pregnant = await ask('Are you pregnant?', {
      id: 'triage.pregnant',
    });
    if (pregnant === 'yes') {
      significant.push({
        label: 'Pregnant',
        value: pregnant,
        warn: true,
      });
      return { abort: commonAborts.adultPregnantOrBreastfeedingGp };
    }

    const breastfeeding = await ask('Are you breastfeeding?', {
      id: 'triage.breastfeeding',
    });
    if (breastfeeding === 'yes') {
      significant.push({
        label: 'Breastfeeding',
        value: breastfeeding,
        warn: true,
      });
      decisionSupport.push(ds.breastfeeding);
      exports.push({
        id: 'keyFindings',
        value: 'Breastfeeding',
      });

      await eText({
        id: 'triage.whenBaby',
        label: 'When did you have your baby?',
        clinicLabel: 'When did you have your baby?',
      });
    }
  }

  const immunosuppressed = await ask(
    'Do you have a condition, or are you taking medication, that affects your immune system?',
    {
      id: 'triage.immunosuppressed',
      description:
        "E.g. methotrexate, current cancer, chemotherapy, renal dialysis, a spleen that doesn't work, HIV, organ transplant",
    }
  );
  if (immunosuppressed === 'yes') {
    significant.push({
      label: 'Immunosuppressed',
      value: immunosuppressed,
      warn: true,
    });
    return { abort: commonAborts.adultImmunosuppressedGp };
  }

  const structuralAbnormalities = await eChoice({
    id: 'triage.structuralAbnormalities',
    label:
      'Have you been diagnosed with any structural abnormalities of your kidneys, ureters, or bladder?',
    options: [
      {
        value: 'yes',
        warn: true,
        abort: aborts.adultKubStructuralAbnormalitiesGp,
      },
      {
        value: 'no',
        hide: true,
      },
      {
        value: 'unknown',
        ds: ds.previousKubStructuralDisease,
      },
    ],
  });
  if (structuralAbnormalities.abort) {
    return { abort: structuralAbnormalities.abort };
  }

  if (structuralAbnormalities === 'unknown') {
    await eText({
      id: 'triage.structuralAbnormalitiesDetails',
      label:
        'What problems with your kidneys, ureters, or bladder have you been diagnosed with?',
      clinicLabel: 'Details',
      warn: true,
    });
  }

  const urinaryCatheter = await eChoice({
    id: 'triage.urinaryCatheter',
    label: 'Do you use a catheter to drain your urine?',
    clinicLabel: 'Urinary Catheter',
    options: [
      {
        value: 'yes',
        warn: true,
        abort: aborts.adultUrinaryCatheterGp,
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });
  if (urinaryCatheter.abort) {
    return { abort: urinaryCatheter.abort };
  }

  return {};
}: Guide);

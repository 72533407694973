import type { Export } from '../../types';
import addJournalData, {
  diagnosisOpts,
  investigationsRequestedOpts,
  prescribedOpts,
  outcomeOpts,
  actionNeededOpts,
} from '../common/addJournalData';

export default (exports: Export[]) =>
  addJournalData(exports, {
    presentingComplaint: 'Informed of test result',
    snomedCode: '165355002  Patient informed - test result ',
    diagnosisOptions: [
      diagnosisOpts.bloodResultsNormal,
      diagnosisOpts.bloodResultsAbnormal,
      diagnosisOpts.anaemia,
      diagnosisOpts.preDiabetes,
      diagnosisOpts.diabetes,
      diagnosisOpts.raisedCholesterol,
      diagnosisOpts.vitaminDDeficiency,
    ],
    investigationsRequestedOptions: [investigationsRequestedOpts.none],
    prescribedOptions: [prescribedOpts.medication],
    outcomeOptions: [
      outcomeOpts.selfCare,
      outcomeOpts.medicationAndAdvice,
      outcomeOpts.investigationsRequested,
      outcomeOpts.routineGpAppt,
      outcomeOpts.urgentGpAppt,
    ],
    actionNeededOptions: [actionNeededOpts.none],
  });

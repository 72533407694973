import type { Guide } from '../../../types';

export default (async args => {
  const { ask, exports, significant } = args;

  const allergyMedication = {
    type: 'choice',
    id: 'allergy.medication.any',
    label:
      'Förutom penicillin eller annan antibiotika, finns det andra läkemedel som du vet att du är allergisk mot, eller har fått biverkningar av?',
    options: [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nej',
      },
      {
        value: 'dontknow',
        label: 'Vet ej',
      },
    ],
    expires: 180,
  };

  const allergyMedicationAnswer = await ask(allergyMedication);

  significant.push({
    ...allergyMedication,
    label: 'Har övriga medicinallergier',
    value: allergyMedicationAnswer,
    warn: ['yes'],
  });

  if (allergyMedicationAnswer === 'yes') {
    exports.push({
      id: 'prescriptionWarnings',
      value: ['**Har övriga medicinallergier** \nJa'],
    });

    const allergyMedicationWhat = {
      type: 'text',
      id: 'allergy.medication.what',
      label:
        'Vilka andra läkemedel är du allergisk mot, eller har fått biverkningar av?',
      expires: 180,
    };

    const allergyMedicationWhatAnswer = await ask(allergyMedicationWhat);

    significant.push({
      ...allergyMedicationWhat,
      label: 'Beskrivning av övriga medicinallergier',
      value: allergyMedicationWhatAnswer,
    });

    exports.push({
      id: 'prescriptionWarnings',
      value: [
        `**Beskrivning av övriga medicinallergier** \n${allergyMedicationWhatAnswer}`,
      ],
    });
  }

  return {};
}: Guide);

import type { Guide } from '../../types';
import journalData from './journalData';
import createExtendedText from '../common/extendedText';
import createExtendedChoice from '../common/extendedChoice';
import moduleSmoking from '../modules/smoking';
import moduleAlcohol from '../modules/alcohol';
import moduleAlternateMedication from '../modules/alternateMedication';
import moduleBmi from '../modules/bmi';
import informedConsent from '../questions/informedConsent';

export default (async args => {
  const { exports, ask } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);

  journalData(exports);

  await ask.info({
    description:
      'Before we can go through your test results, we need to ask you some questions.',
  });

  await ask.choice({
    description: `
This service is for advice about your **test result only**.
If you need help with symptoms, or are unwell, please choose another questionnaire.

**Call 111 for urgent care, or 999 for emergencies.**
  `,
    options: ['I understand'],
  });

  await eText({
    id: 'testResults.whatWasTheTest',
    label: 'What test did you have?',
    description: 'e.g blood test, swab, x-ray, ultrasound',
    clinicLabel: 'Investigation done',
  });

  await eText({
    id: 'testResults.whenWasTheTest',
    label: 'When did you have the test?',
    description: '(Please tell us the date of your test if you can)',
    clinicLabel: 'Date of test',
  });

  await eText({
    id: 'testResults.whatWasTheTestFor',
    label: 'Do you know why you had this test?',
    clinicLabel: 'Reason for test',
  });

  await eChoice({
    id: 'testResults.regularTest',
    label: 'Is this a test that you have on a regular basis?',
    expires: 365,
    clinicLabel: 'Regular test',
    options: [
      {
        value: 'yes',
        keyFinding: 'Regular test',
      },
      {
        value: 'no',
        hide: true,
      },
    ],
  });

  await eText({
    id: 'testResults.questions',
    label: 'Did you have any questions about this test?',
    clinicLabel: 'Questions about test',
  });

  await moduleBmi(args);
  await moduleSmoking(args);
  await moduleAlcohol(args);
  await moduleAlternateMedication(args);

  await ask(informedConsent);

  await ask.choice({
    description: `
We aim to reply within 2 working days. 

**If your request is urgent, please contact your GP surgery in the normal way.**
`,
    options: ['I understand'],
  });

  return {};
}: Guide);

import { type RawQuestion } from '../types';

import { type Node, type Question, type Option } from './types';

export const isFormulary = (node?: Node): boolean =>
  !!node && node.type === 'formulary';
export const isSection = (node?: Node): boolean =>
  !!node && node.type === 'section';
export const isComposite = (node?: Node): boolean =>
  !!node && node.type === 'composite';
export const isVariable = (node?: Node): boolean =>
  !!node && node.type === 'variable';
export const isPredefined = (node?: Node): boolean =>
  !!node && node.type === 'predefined';
export const isView = (node?: Node): boolean => !!node && node.type === 'view';
export const isQuestion = (node?: Node): boolean =>
  !!node &&
  !!node.type &&
  !isFormulary(node) &&
  !isSection(node) &&
  !isComposite(node) &&
  !isVariable(node) &&
  !isView(node);

export const htmlToMd = (string?: string) => {
  return string !== undefined
    ? string
        .split('\n')
        .map(line => line.trim())
        .filter(line => !!line)
        .join(' ')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n\n')
        .replace(/<ul>/g, '')
        .replace(/<\/ul>/g, '\n\n')
        .replace(/<li>/g, '- ')
        .replace(/<\/li>/g, '')
        .replace(/<br\/?>/g, '\n\n')
        .replace(/<\/?i>/g, '*')
        .replace(/<\/?b>/g, '**')
        .split('\n')
        .map(line => line.trim())
        .join('\n')
    : undefined;
};

export const toGuideQuestion = (
  node: Question,
  options?: Option[]
): $Exact<RawQuestion> => {
  return {
    id: node.id,
    type: node.type,
    label: node.prologue === undefined ? node.label : undefined,
    description:
      node.prologue !== undefined
        ? `
${htmlToMd(node.prologue) || ''}

# ${node.label || ''}

${htmlToMd(node.description) || ''}
    `.trim()
        : htmlToMd(node.description),
    optional: !!(
      node.validations === undefined || node.validations.required !== true
    ),
    min: node.min,
    max: node.max,
    unit: node.unit,
    options:
      options &&
      options.map(option => ({
        label: String(option.label),
        value: option.value,
      })),
  };
};

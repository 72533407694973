import map from 'lodash/map';
import randomId from '../../common/randomId';

const imageRegex = /.(jpeg|jpg|png|gif)$/i;
const videoRegex = /.(webm|mp4|quicktime|mov)$/i;

export const hasImageExtension = (uri: string) => !!imageRegex.exec(uri);
export const hasVideoExtension = (uri: string) => !!videoRegex.exec(uri);

export const isVideo = (uri: string) =>
  !!hasVideoExtension(uri) || /^data:video\/.*;base64/.exec(uri);

const cachedFiles = {};

export const chooseFiles = (accept?: string, multiple?: boolean): Promise<*> =>
  new Promise(resolve => {
    // Some cleanup so we don't end up with multiple inputs (since it's outside react shadow dom)
    const existingElement = document.querySelector(
      '#fileUploadDynamicInputElement'
    );
    if (existingElement) {
      window.document.body.removeChild(existingElement);
    }

    const inputEl = document.createElement('input');

    // Hacky but for some reason 'video/*' doesn't catch mp4 in Safari.
    const processedAccept =
      accept !== undefined
        ? accept.replace('video/*', 'video/*,video/mp4')
        : 'image/*';

    inputEl.type = 'file';
    inputEl.accept = processedAccept;
    inputEl.multiple = !!multiple;
    inputEl.style.display = 'none';
    inputEl.id = 'fileUploadDynamicInputElement';

    inputEl.onchange = ({ target: { files } }) =>
      resolve(
        map(files, file => {
          const path = randomId() + '.' + file.name.split('.').pop();

          cachedFiles[path] = file;

          return path;
        })
      );

    window.document.body.appendChild(inputEl);

    inputEl.click();
  });

export const getFileData = (path: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const file = cachedFiles[path];

    if (!file) {
      reject(new Error(`File '${path}' is not in the list of cached files.`));
      return;
    }

    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => resolve(result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

import type { Guide } from '../../types';

import ds from './decisionSupport';
import aborts from './aborts';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const {
    ask,
    decisionSupport,
    exports,
    patient: { age },
    significant,
  } = args;

  const rashDuration = {
    type: 'choice',
    id: 'rashDuration',
    label: 'How long has your child had the rash?',
    options: [
      { label: 'Less than 24 hours ', value: 'lessThan24Hours' },
      { label: '1–2 days ', value: 'oneToTwoDays' },
      { label: '3–7 days ', value: 'threeToSevenDays' },
      { label: '1–2 weeks ', value: 'oneToTwoWeeks' },
      { label: 'More than 2 weeks ', value: 'moreThanTwoWeeks' },
    ],
  };

  const rashDurationAnswer = await ask(rashDuration);

  if (rashDurationAnswer !== 'moreThanTwoWeeks') {
    significant.push({
      ...rashDuration,
      label: 'Duration of rash',
      value: rashDurationAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      lessThan24Hours: 'Rash started less than 24 hours ago',
      oneToTwoDays: 'Rash started 1–2 days ago',
      threeToSevenDays: 'Rash started 3–7 days ago',
      oneToTwoWeeks: 'Rash started 1–2 weeks ago',
      moreThanTwoWeeks: 'Rash started more than 2 weeks ago',
    }[rashDurationAnswer],
  });

  if (rashDurationAnswer.includes('moreThanTwoWeeks')) {
    const rashLongDuration = {
      type: 'text',
      id: 'rashLongDuration',
      label: 'Please tell us exactly how long it has been.',
    };

    const rashLongDurationAnswer = await ask(rashLongDuration);

    significant.push({
      ...rashLongDuration,
      label: 'Duration of rash',
      value: rashLongDurationAnswer,
    });
  }

  const rashWorsenedOrChanged = {
    type: 'binary',
    id: 'rashWorsenedOrChanged',
    label: 'Has their rash worsened or changed?',
  };

  const rashWorsenedOrChangedAnswer = await ask(rashWorsenedOrChanged);

  if (rashWorsenedOrChangedAnswer === 'no') {
    significant.push({
      ...rashWorsenedOrChanged,
      label: 'Rash changed',
      value: rashWorsenedOrChangedAnswer,
    });

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Rash has worsened or changed',
        no: 'Rash has not worsened or changed',
      }[rashWorsenedOrChangedAnswer],
    });
  }

  if (rashWorsenedOrChangedAnswer === 'yes') {
    const rashWorsenedOrChangedDuration = {
      type: 'choice',
      id: 'rashWorsenedOrChangedDuration',
      label: 'When did their rash change or become worse?',
      options: [
        { label: 'Less than 24 hours ', value: 'lessThan24Hours' },
        { label: '1–2 days ', value: 'oneToTwoDays' },
        { label: '3–7 days ', value: 'threeToSevenDays' },
        { label: '1–2 weeks ', value: 'oneToTwoWeeks' },
        { label: 'More than 2 weeks ', value: 'moreThanTwoWeeks' },
      ],
    };

    const rashWorsenedOrChangedDurationAnswer = await ask(
      rashWorsenedOrChangedDuration
    );
    if (rashWorsenedOrChangedDurationAnswer !== 'moreThanTwoWeeks') {
      significant.push({
        ...rashWorsenedOrChangedDuration,
        label: 'Duration of changed rash',
        value: rashWorsenedOrChangedDurationAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        lessThan24Hours: 'Rash changed less than 24 hours',
        oneToTwoDays: 'Rash changed 1–2 days ago',
        threeToSevenDays: 'Rash changed 3-7 days ago',
        oneToTwoWeeks: 'Rash changed 1-2 weeks ago',
        moreThanTwoWeeks: 'Rash changed more than 2 weeks ago',
      }[rashWorsenedOrChangedDurationAnswer],
    });

    if (rashWorsenedOrChangedDurationAnswer.includes('moreThanTwoWeeks')) {
      const rashWorsenedOrChangedLongDuration = {
        type: 'text',
        id: 'rashWorsenedOrChangedLongDuration',
        label: 'Please tell us exactly how long it has been.',
      };

      const rashWorsenedOrChangedLongDurationAnswer = await ask(
        rashWorsenedOrChangedLongDuration
      );

      significant.push({
        ...rashWorsenedOrChangedLongDuration,
        label: 'When did rash change?',
        value: rashWorsenedOrChangedLongDurationAnswer,
      });
    }
  }

  const rashLocation = {
    type: 'choice',
    id: 'rashLocation',
    label: 'Where is the rash?',
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Face', value: 'face' },
      { label: 'Neck/scalp', value: 'neckOrScalp' },
      { label: 'Chest, stomach or back', value: 'chestStomachOrBack' },
      { label: 'Arms, hands, legs or feet', value: 'armsHandsLegsOrFeet' },
      { label: 'Genitals or anal region', value: 'genitalsOrAnalRegion' },
    ],
  };

  const rashLocationAnswer = await ask(rashLocation);

  significant.push({
    ...rashLocation,
    label: 'Location of rash',
    description: '',
    value: rashLocationAnswer,
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      face: 'Rash on face',
      neckOrScalp: 'Rash on neck/scalp',
      chestStomachOrBack: 'Rash on torso',
      armsHandsLegsOrFeet: 'Rash on arms, hands, legs or feet',
      genitalsOrAnalRegion: 'Rash on genitals or anal region',
    }[rashLocationAnswer],
  });

  const opts = [
    { label: 'Raised', value: 'raised' },
    { label: 'Itchy', value: 'itchy' },
    { label: 'Dry and flaky', value: 'dryOrFlaky' },
    { label: 'Swollen red bumps', value: 'swollenRedBumps' },
    { label: 'Yellow/ brown crust', value: 'yellowBrownCrust' },
    { label: 'Weeping or bleeding', value: 'weepingOrBleeding' },
    { label: 'Blisters or boils', value: 'blistersOrBoils' },
  ];

  const rashDescription = {
    type: 'multipleChoice',
    id: 'rashDescription',
    label: 'How would you describe the rash?',
    description: 'More than one answer can be selected.',
    options: [...opts, { label: 'Other', value: 'other' }],
  };

  const rashDescriptionAnswer = await ask(rashDescription);

  if (!rashDescriptionAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...rashDescription,
      label: 'Description of rash',
      description: '',
      value: rashDescriptionAnswer.filter(answer => answer !== 'other'),
      warn:
        rashDescriptionAnswer.includes('swollenRedBumps') ||
        rashDescriptionAnswer.includes('yellowBrownCrust') ||
        rashDescriptionAnswer.includes('weepingOrBleeding') ||
        rashDescriptionAnswer.includes('blistersOrBoils'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      raised: 'Raised',
      itchy: 'Itchy',
      dryOrFlaky: 'Dry and flaky',
      swollenRedBumps: 'Swollen red bumps',
      yellowBrownCrust: 'Yellow/brown crust',
      weepingOrBleeding: 'Weeping or bleeding',
      blistersOrBoils: 'Blisters or boils',
    }[rashDescriptionAnswer],
  });

  if (rashDescriptionAnswer.includes('yellowBrownCrust') && age < 3) {
    return { abort: aborts.potentialImpetigo };
  } else if (rashDescriptionAnswer.includes('yellowBrownCrust')) {
    decisionSupport.push(ds.impetigo);
  }

  if (rashDescriptionAnswer.includes('swollenRedBumps')) {
    decisionSupport.push(ds.wheal);
  }

  if (
    rashDescriptionAnswer.includes('weeping') ||
    rashDescriptionAnswer.includes('bleeding') ||
    rashDescriptionAnswer.includes('blistersOrBoils')
  ) {
    return { abort: aborts.potentialCellulitis };
  }

  if (rashDescriptionAnswer.includes('other')) {
    const rashDescriptionOther = {
      type: 'text',
      id: 'rashDescriptionOther',
      label: 'Please describe the rash.',
    };

    const rashDescriptionOtherAnswer = await ask(rashDescriptionOther);

    significant.push({
      ...rashDescriptionOther,
      label: 'Description of rash',
      value: rashDescriptionOtherAnswer,
    });
  }

  const rashAllergicCause = {
    type: 'choice',
    id: 'rashAllergicCause',
    label:
      "Do you think your child's skin rash is because of an allergic reaction?",
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
      { label: "I'm not sure", value: 'notSure' },
    ],
  };

  const rashAllergicCauseAnswer = await ask(rashAllergicCause);

  significant.push({
    ...rashAllergicCause,
    label: 'Concerned about allergic skin reaction',
    value: rashAllergicCauseAnswer,
    warn: ['yes'],
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Parent concerned about allergic reaction',
      no: 'Parents not concerned about allergic reaction',
    }[rashAllergicCauseAnswer],
  });

  if (
    rashAllergicCauseAnswer.includes('yes') ||
    rashAllergicCauseAnswer.includes('notSure')
  ) {
    const rashAllergyKnown = {
      type: 'choice',
      id: 'rashAllergyKnown',
      label: 'Do you know what they may have had an allergic reaction to?',
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: "I'm not sure", value: 'notSure' },
      ],
    };

    const rashAllergyKnownAnswer = await ask(rashAllergyKnown);

    if (rashAllergyKnownAnswer !== ['yes']) {
      significant.push({
        ...rashAllergyKnown,
        label: 'Potetial allergen known?',
        value: rashAllergyKnownAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Potential allergen known',
        no: 'Potential allergen not known',
        notSure: 'Uncertain about potential allergen',
      }[rashAllergyKnownAnswer],
    });

    if (
      rashAllergyKnownAnswer.includes('yes') ||
      rashAllergyKnownAnswer.includes('notSure')
    ) {
      const rashAllergyKnownWhat = {
        type: 'text',
        id: 'rashAllergyKnownWhat',
        label: 'What may have caused this allergic skin reaction?',
      };

      const rashAllergyKnownWhatAnswer = await ask(rashAllergyKnownWhat);

      significant.push({
        ...rashAllergyKnownWhat,
        label: 'Potential allergen',
        value: rashAllergyKnownWhatAnswer,
      });
    }
  }

  if (rashAllergicCauseAnswer.includes('yes')) {
    const rashAntihistamineTried = {
      type: 'binary',
      id: 'rashAntihistamineTried',
      label: 'Have you given your child any antihistamine medication?',
      description: 'Eg. Piriton, Benadryl, Clarityn',
    };

    const rashAntihistamineTriedAnswer = await ask(rashAntihistamineTried);

    if (rashAntihistamineTriedAnswer === 'no') {
      significant.push({
        ...rashAntihistamineTried,
        label: 'Has an antihistamine been tried?',
        description: '',
        value: rashAntihistamineTriedAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Has tried antihistamine',
        no: 'Not tried antihistamine',
      }[rashAntihistamineTriedAnswer],
    });

    if (rashAntihistamineTriedAnswer === 'yes') {
      const rashAntihistamineTriedWhat = {
        type: 'text',
        id: 'rashAntihistamineTriedWhat',
        label: 'Please tell us which antihistamine you gave your child.',
      };

      const rashAntihistamineTriedWhatAnswer = await ask(
        rashAntihistamineTriedWhat
      );

      significant.push({
        ...rashAntihistamineTriedWhat,
        label: 'Medication already tried',
        value: rashAntihistamineTriedWhatAnswer,
      });

      const rashAntihistaminTriedEvaluation = {
        type: 'binary',
        id: 'rashAntihistaminTriedEvaluation',
        label: "Did this antihistamine help your child's rash?",
      };

      const rashAntihistaminTriedEvaluationAnswer = await ask(
        rashAntihistaminTriedEvaluation
      );

      significant.push({
        ...rashAntihistaminTriedEvaluation,
        label: 'Did the medication help the skin rash?',
        value: rashAntihistaminTriedEvaluationAnswer,
      });

      addExportIfValueExists(exports, {
        id: 'keyFindings',
        value: {
          yes: 'Antihistamine helped on rash',
          no: 'Antistamine did not help on rash',
        }[rashAntihistaminTriedEvaluationAnswer],
      });
    }
  }

  if (rashAllergicCauseAnswer === 'no') {
    const rashMedicationTried = {
      type: 'binary',
      id: 'rashMedicationTried',
      label: "Have you tried using any medication to help your child's rash?",
    };
    const rashMedicationTriedAnswer = await ask(rashMedicationTried);

    if (rashMedicationTriedAnswer === 'no') {
      significant.push({
        ...rashMedicationTried,
        label: 'Medication tried so far',
        value: rashMedicationTriedAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        yes: 'Tried medication for rash',
        no: 'Not tried medication for rash',
      }[rashMedicationTriedAnswer],
    });

    if (rashMedicationTriedAnswer === 'yes') {
      const rashMedicationTriedWhat = {
        type: 'text',
        id: 'rashMedicationTriedWhat',
        label: 'What medication you have tried?',
      };

      const rashMedicationTriedWhatAnswer = await ask(rashMedicationTriedWhat);

      significant.push({
        ...rashMedicationTriedWhat,
        label: 'Medication tried for rash',
        value: rashMedicationTriedWhatAnswer,
      });

      const rashMedicationTriedEvaluation = {
        type: 'binary',
        id: 'rashMedicationTriedEvaluation',
        label: 'Did the medication help?',
      };

      const rashMedicationTriedEvaluationAnswer = await ask(
        rashMedicationTriedEvaluation
      );

      significant.push({
        ...rashMedicationTriedEvaluation,
        label: 'Did the medication help',
        value: rashMedicationTriedEvaluationAnswer,
      });
    }
  }

  const rashHelpWith = {
    type: 'choice',
    id: 'rashHelpWith',
    label: "What would you like from today's visit?",
    options: [
      { label: "Advice on how to help my child's rash", value: 'advice' },
      { label: "Treatment for my child's rash", value: 'treatment' },
      { label: 'Other', value: 'other' },
    ],
  };

  const rashHelpWithAnswer = await ask(rashHelpWith);

  if (!rashHelpWithAnswer.includes('other')) {
    significant.push({
      ...rashHelpWith,
      label: "Purpose of today's visit",
      value: rashHelpWithAnswer,
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      advice: 'Advice',
      treatment: 'Treatment',
      other: 'Other',
    }[rashHelpWithAnswer],
  });

  if (rashHelpWithAnswer.includes('other')) {
    const rashHelpWithOther = {
      type: 'text',
      id: 'rashHelpWithOther',
      label: "Please tell us what you would like from today's visit.",
    };

    const rashHelpWithOtherAnswer = await ask(rashHelpWithOther);

    significant.push({
      ...rashHelpWithOther,
      label: "Purpose of today's visit",
      value: rashHelpWithOtherAnswer,
    });
  }

  const photoGeneralRash = await ask.upload(
    `We'd like you to upload a photo of your child's skin rash to help the doctor with their diagnosis.`,
    {
      description: `
        You can use a mobile phone camera to take the photo.\n\nRest assured, your photos will be kept confidential and will only be added to your medical record.
      `,
      optional: true,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photoGeneralRash,
  });

  return {};
}: Guide);

import type { Guide } from '../../types';
import triage from './triage';
import aborts from './abort';
import createExtendedChoice from '../common/extendedChoice';
import ds from './decisionSupport';
import healthProfileChild from '../partials/healthProfileChild';
import informedConsentChild from '../partials/informedConsentChild';
import pharmacy from '../modules/pharmacy';
import createExtendedText from '../common/extendedText';

export default (async args => {
  const { ask, decisionSupport, scores, exports } = args;
  const eChoice = createExtendedChoice(args);
  const eText = createExtendedText(args);
  const bumpFeverPainScore = () => {
    scores.increase('feverPain');
  };

  const informedConsentChildResult = await informedConsentChild(args);
  if (informedConsentChildResult.abort) {
    return informedConsentChildResult;
  }

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  await ask.info(`We are now going to ask about your child's sore throat.`);

  const duration = await eChoice({
    id: 'soreThroatChild.duration',
    label: 'How long has your child had a sore throat?',
    clinicLabel: 'Duration of sore throat',
    options: [
      {
        label: '0–3 days',
        value: '0-3days',
        keyFinding: 'Symptoms for 0–3 days',
        warn: true,
        onSelected: bumpFeverPainScore,
      },
      {
        label: '4–7 days',
        value: '4-7days',
        keyFinding: 'Symptoms for 4–7 days',
      },
      {
        label: 'More than 7 days',
        value: 'moreThan7days',
        keyFinding: `Persistent sore throat >7/7`,
        hide: true,
        ds: ds.persistentSoreThroat,
      },
    ],
  });

  if (duration === 'moreThan7days') {
    await eText({
      clinicLabel: 'Sore throat duration',
      label: 'How long has your child had a sore throat?',
      warn: true,
    });
  }

  await eChoice({
    id: 'soreThroatChild.symptomsImproving',
    label: `Is your child's sore throat getting better?`,
    clinicLabel: 'Are symptoms improving or worsening?',
    options: [
      {
        label: `Yes, It's getting better`,
        value: 'improving',
        keyFinding: 'Improving',
        ds: ds.symptomsImproving,
      },
      {
        label: `No, It's staying the same`,
        value: 'noImprovement',
        keyFinding: 'No improvement',
      },
      {
        label: `No, It's getting worse`,
        value: 'worsening',
        keyFinding: `Worsening`,
        warn: true,
      },
    ],
  });

  await eChoice({
    id: 'soreThroatChild.UnilateralThroatPain',
    label: `Does it hurt on just one side of your child's throat?`,
    clinicLabel: 'Unilateral throat pain?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Unilateral throat pain',
        warn: true,
        ds: ds.unilateralThroat,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Pain not unilateral',
      },
    ],
  });

  const swallowWater = await eChoice({
    id: 'soreThroatChild.swallowWater',
    label: `Is your child able to swallow water?`,
    clinicLabel: 'Able to swallow water?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'Yes, but it hurts',
        value: 'YesButPainful',
        keyFinding: 'Yes but painful',
      },
      {
        label: 'No',
        value: 'no',
        warn: true,
        abort: aborts.unableToSwallowWater,
      },
    ],
  });
  if (swallowWater.abort) {
    return swallowWater;
  }

  await eChoice({
    id: 'soreThroatChild.softFoods',
    label: `Is your child able to eat soft foods?`,
    clinicLabel: 'Able to eat soft foods?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'Yes, but it hurts',
        value: 'YesButPainful',
        keyFinding: 'Yes but painful',
      },
      {
        label: 'No',
        value: 'no',
        warn: true,
        ds: ds.softFoods,
        urgent: true,
      },
    ],
  });

  const tonsils = await eChoice({
    id: 'soreThroatChild.redTonsils',
    label: `Are your child's tonsils red and swollen?`,
    clinicLabel: 'Red and swollen tonsils?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Red and swollen tonsils',
        warn: true,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Tonsils not red or swollen',
      },
      {
        label: 'My child does not have tonsils',
        value: 'noTonsils',
        keyFinding: 'No tonsils',
      },
      {
        label: `I am unable to check my child's throat`,
        value: 'ParentUnableToCheckThroat',
        keyFinding: 'Parent unable to check throat',
      },
    ],
  });

  if (tonsils === 'yes') {
    bumpFeverPainScore();
  }

  if (tonsils === 'yes' || tonsils === 'no' || tonsils === 'noTonsils') {
    const pustularTonsils = await eChoice({
      id: 'soreThroatChild.pustularTonsils',
      label: `Has your child got white / yellow spots at the back of their throat?`,
      type: 'choice',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          warn: true,
          keyFinding: 'Pustular tonsils',
        },
        {
          label: 'No',
          value: 'no',
          keyFinding: 'No pustular tonsils',
        },
      ],
    });

    if (pustularTonsils === 'yes') {
      bumpFeverPainScore();
    }
  }

  const fever24 = await eChoice({
    id: 'soreThroatChild.fever24',
    label: 'Has your child had a fever in the last 24 hours?',
    clinicLabel: 'Temperature in last 24 hours',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        warn: true,
        keyFinding: 'Fever in the last 24hours',
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'No pustular tonsils',
      },
    ],
  });

  if (fever24 === 'yes') {
    bumpFeverPainScore();

    const paracetamolorIbuprofenlast24h = await eChoice({
      id: 'soreThroatChild.paracetamolorIbuprofenlast24h',
      label:
        'Have you given your child any paracetamol (Calpol) or ibuprofen (Neurofen) in the last 24 hours?',
      clinicLabel: 'Paracetamol or Ibuprofen last 24h',
      options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
    });

    if (paracetamolorIbuprofenlast24h === 'yes') {
      await eChoice({
        id: 'soreThroatChild.medicinehelped',
        label: 'Did the medicine help with the fever?',
        clinicLabel: 'Medicine helped',
        options: [
          {
            label: 'Yes, it helped to settle the fever. ',
            value: 'Feversettledwithanti-pyretic',
            keyFinding: 'Fever settled with anti-pyretic',
          },
          {
            label: 'Yes, but the fever returned',
            value: 'Feverdidnotsettlewithanti-pyretic',
            keyFinding: 'Fever did not settle with anti-pyretic',
            warn: true,
          },
          {
            label: 'No, it did not help at all.',
            value: 'No',
            warn: true,
            ds: ds.persistentFever,
            urgent: true,
          },
        ],
      });
    }
  }

  const coldcoughtypesymptoms = await eChoice({
    id: 'soreThroatChild.coldcoughtypesymptoms',
    label: `Does your child have any cold symptoms, like a cough or runny/blocked nose?`,
    clinicLabel: 'Cold/cough type symptoms',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Has cough/coryzal symptom',
        ds: ds.coryzalSymptoms,
      },
      {
        label: 'No',
        value: 'no',
        keyFinding: 'Absence of cough/coryzal symptoms',
        warn: true,
      },
    ],
  });

  if (coldcoughtypesymptoms === 'no') {
    bumpFeverPainScore();
  }

  const urgentEarAche = {
    urgent: true,
    ds: ds.earAche,
  };

  await eChoice({
    id: 'soreThroatChild.pullingonears',
    label: 'Do you think your child has earache?',
    clinicLabel: 'Earache',
    options: [
      {
        label: 'Yes, they have complained of earache',
        value: 'complained',
        keyFinding: 'Earache',
        ...urgentEarAche,
      },
      {
        label: 'Yes, they have been pulling or holding both ears',
        value: 'Pullingonbothears',
        keyFinding: 'Pulling on both ears',
        ...urgentEarAche,
      },
      {
        label: 'Yes, they have been pulling or holding one ear',
        value: 'Pullingononeear',
        keyFinding: 'Pulling on one ear',
        ...urgentEarAche,
      },
      { label: 'No', value: 'no', keyFinding: 'No Earache' },
    ],
  });

  const otorrhoea = await eChoice({
    id: 'soreThroatChild.otorrhoea',
    label: "Is there any discharge coming out of your child's ear?",
    clinicLabel: 'Otorrhoea',
    options: [
      {
        label: 'Yes, it looks waxy',
        value: 'waxy',
        keyFinding: 'Waxy ear discharge',
      },
      {
        label: 'Yes, it is yellow and smelly.',
        value: 'waxysmelly',
        keyFinding: 'Yellow or smelly ear discharge',
        hide: true,
        abort: aborts.supprativeOtitisMedia,
      },
      { label: 'No', value: 'no', hide: true, keyFinding: 'No ear discharge' },
    ],
  });

  if (otorrhoea.abort) {
    return otorrhoea;
  }

  const hasvoicechanged = await eChoice({
    id: 'soreThroatChild.hasvoicechanged?',
    label: "Has your child's voice changed?",
    clinicLabel: 'Change in voice',
    options: [
      { label: 'Yes', value: 'yes', hide: true, keyFinding: 'Change in voice' },
      { label: 'No', value: 'no', keyFinding: 'No change in voice' },
    ],
  });

  if (hasvoicechanged === 'yes') {
    const whatdoesyourvoicesoundlike = await eChoice({
      id: 'soreThroatChild.whatdoesyourvoicesoundlike?',
      label: "What does your child's voice sound like?",
      clinicLabel: 'Change in voice',
      options: [
        {
          label: 'Nasal, like they have a cold',
          value: 'Coryzalsymptoms',
          keyFinding: 'Coryzal symptoms',
        },
        {
          label: 'Croaky',
          value: 'Hoarsevoice',
          keyFinding: 'Hoarse voice',
        },
        {
          label: 'They have lost their voice',
          value: 'Lostvoice',
          keyFinding: 'Lost voice',
        },
        {
          label:
            'Muffled or unclear, like they have a hot potato in their mouth',
          value: 'Muffled/hotpotatovoice',
          keyFinding: 'Muffled/hot potato voice',
          warn: true,
          abort: aborts.quinsy,
        },
      ],
    });
    if (whatdoesyourvoicesoundlike.abort) {
      return whatdoesyourvoicesoundlike;
    }
  }

  const symptomsotherthansorethroat = await eChoice({
    id: 'soreThroatChild.symptomsotherthansorethroat',
    label:
      "Since your child's sore throat started, have they experienced any of the following?",
    isMultipleChoice: true,
    optional: true,
    clinicLabel: 'Symptoms, other than sore throat',
    options: [
      {
        label: 'Swollen or tender glands in armpit or groin',
        value: 'Swollenortenderglandsinarmpitorgroin',
        warn: true,
        ds: ds.lymphadenopathy,
        urgent: true,
      },
      {
        label: 'Rash',
        value: 'Rash',
        keyFinding: 'Rash',
        warn: true,
        ds: fever24 === 'yes' ? ds.feverWithARash : undefined,
        urgent: true,
      },
      {
        label: 'Abdominal / tummy pain',
        value: 'Abdominal/tummypain',
        warn: true,
        ds: ds.tummyPain,
        urgent: true,
      },
      {
        label: 'Diarrhoea',
        value: 'Diarrhoea',
        warn: true,
        ds: ds.diarrhoea,
        urgent: true,
      },
      {
        label: 'Vomiting',
        value: 'vomiting',
        warn: true,
        ds: ds.vomiting,
        urgent: true,
      },
    ],
  });

  if (
    Array.isArray(symptomsotherthansorethroat) &&
    symptomsotherthansorethroat.includes('Rash')
  ) {
    await eChoice({
      id: 'soreThroatChild.vaccinationsuptodate?',
      label: 'Are your childs vaccinations up to date?',
      clinicLabel: 'Vaccinations up to date?',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          keyFinding: 'Immunisations up to date',
          hide: true,
        },
        {
          label: 'No',
          value: 'no',
          keyFinding: 'Immunisations NOT up to date',
          warn: true,
          ds: ds.rashIncompleteVaccination,
          urgent: true,
        },
        { label: "I don't know", value: 'idontknow', warn: true },
      ],
    });
  }

  await eChoice({
    id: 'soreThroatChild.antibioticsforsorethroatinthepastmonth',
    label:
      'In the past month, has your child needed antibiotics for their throat?',
    clinicLabel: 'Antibiotics for sore throat in the past month',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        keyFinding: 'Recent abx for sore throat in last 4 weeks',
        warn: true,
        ds: ds.antibiotics,
        urgent: true,
      },
      { label: 'No', value: 'no' },
    ],
  });

  const repeatedsorethroatsrequiringantibiotics = await eChoice({
    id: 'soreThroatChild.repeatedsorethroatsrequiringantibiotics',
    label:
      'In the last 12 months, how many times has your child needed antibiotics for their throat?',
    clinicLabel:
      'Number of sore throats requiring antibiotics in the last 12 months',
    options: [
      { label: 'None', value: 'None', hide: true },
      {
        label: '1-6',
        value: '1-6',
        keyFinding: '1-6 episodes of tonsillitis in the last year',
      },
      {
        label: '7 or more',
        value: '7ormore',
        keyFinding: 'More than 7 episodes of tonsillitis in the last year',
        ds: ds.RecurrentTonsillitis7,
      },
    ],
  });

  // TODO: "Make per year for the last 2 years" bold
  if (repeatedsorethroatsrequiringantibiotics === '1-6') {
    await eChoice({
      id:
        'soreThroatChild.numberofsorethroatsrequiringantibioticsinthelast12months',
      label:
        'In the last 2 years, has your child needed antibiotics more than 10 times for their throat?',
      clinicLabel:
        'More than 10 episodes of tonsillitis requiring antibiotics in the last 2 years',
      options: [
        {
          label: 'Yes',
          value: 'yes',
          keyFinding:
            'More than 10 episodes of tonsillitis in the last 2 years',
          ds: ds.RecurrentTonsillitis5,
        },
        { label: 'No', value: 'no', hide: true },
      ],
    });
  }

  await ask.upload(
    `We'd like you to upload a photo of your child's sore throat to help the doctor with their diagnosis.`,
    {
      optional: true,
      description: `
      You can use a mobile phone camera to take the photo — you can activate the selfie-mode and turn your phone upside down.

For best results we need to clearly see your child's throat.  Try not to get your child to lift their tongue too much — saying ‘aaar’ when taking the photo is a great way of achieving this.

Rest assured, your photos will be kept confidential and will only be added to your medical record.
    `,
    }
  );

  const healthProfileChildResult = await healthProfileChild(args);
  if (healthProfileChildResult.abort) {
    return healthProfileChildResult;
  }

  await pharmacy(args);

  if (scores.get('feverPain') <= 1) {
    decisionSupport.push(ds.FPS1orbelow);
  }
  if (scores.get('feverPain') >= 2 && scores.get('feverPain') <= 3) {
    decisionSupport.push(ds.FPS2or3);
  }
  if (scores.get('feverPain') >= 4) {
    decisionSupport.push(ds.FPSabove4);
  }

  // Summary settings
  exports.push({ id: 'presentingComplaint', value: 'Sore throat' });
  exports.push({ id: 'snomedCode', value: 'Sore throat symptom 267102003' });
  exports.push({
    id: 'diagnosis.options',
    value: ['Viral URTI', 'Laryngitis', 'Pharyngitis', 'Tonsillitis'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed antibiotics', 'Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Self-care advice',
      'Antibiotics prescribed and advice given',
      'For referral to ENT',
      'Routine appointment to see GP face to face',
      'Urgent appointment to see GP face to face',
      'Referred for emergency care (A&E)',
    ],
  });
  return {};
}: Guide);

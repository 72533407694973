import * as React from 'react';
import classNames from 'classnames';
import { Link } from '@reach/router';

import IconButton from '@material-ui/core/IconButton';

import withStyles from '@material-ui/core/styles/withStyles';

import ukFlagSource from '../../../images/flags/uk.svg';
import seFlagSource from '../../../images/flags/se.svg';

class CountryPicker extends React.Component<{
  country: string,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { country, classes, className } = this.props;

    return (
      <div className={classNames(classes.root, className)}>
        <Link to="/se/guides">
          <IconButton className={classes.flag}>
            <img
              alt="se"
              src={seFlagSource}
              className={classNames(
                classes.flagImage,
                country === 'se' && classes.flagImageSelected
              )}
            />
          </IconButton>
        </Link>

        <Link to="/uk/guides">
          <IconButton className={classes.flag}>
            <img
              alt="uk"
              src={ukFlagSource}
              className={classNames(
                classes.flagImage,
                country === 'uk' && classes.flagImageSelected
              )}
            />
          </IconButton>
        </Link>
      </div>
    );
  }
}

export default withStyles(() => ({
  root: {},

  flag: {
    height: 56,
    width: 56,
    padding: 0,
    margin: 0,
  },

  flagImage: {
    height: 28,
    borderRadius: 3,
    opacity: 0.33,
  },

  flagImageSelected: {
    opacity: 1,
  },
}))(CountryPicker);

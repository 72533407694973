import type { GuideWithIn } from '../../types';
import createExtendedChoice from '../common/extendedChoice';

export default (async args => {
  const {
    id,
    label,
    description = '**If you feel very unwell, and it is outside of normal working hours, or a weekend, please go to A&E.**',
  } = args;
  const eChoice = createExtendedChoice(args);

  await eChoice({
    id,
    label,
    description,
    options: [
      {
        label: 'I understand',
        value: 'understand',
        hide: true,
      },
    ],
  });
  return {};
}: GuideWithIn<{
  id: string,
  label: string,
  description?: string,
}>);

export const mainProblem = {
  id: 'mainProblem',
  type: 'text',
  label: 'Vilket är ditt huvudsakliga besvär?',
  clinicLabel: 'Huvudsakligt besvär',
};

export const desiredHelp = {
  id: 'desiredHelp',
  type: 'text',
  label: 'Beskriv kortfattat vad du vill ha hjälp med.',
  clinicLabel: 'Önskar hjälp med',
};

export const pregnant = async args => {
  const {
    ask,
    patient: { gender, age },
    significant,
    exports,
  } = args;
  if (gender === 'female' && age < 50) {
    const pregnant = {
      id: 'pregnant',
      type: 'binary',
      label: 'Är du gravid?',
      clinicLabel: 'Gravid',
    };
    const pregnantAnswer = await ask(pregnant);
    significant.push({
      ...pregnant,
      label: pregnant.clinicLabel,
      value: pregnantAnswer,
      warn: ['yes'],
    });
    if (pregnantAnswer === 'yes') {
      exports.push({
        id: 'prescriptionWarnings',
        value: 'Gravid  \n**Ja**',
      });
    }
  }
  return null;
};

export const breastfeeding = async args => {
  const {
    ask,
    patient: { gender, age },
    significant,
    exports,
  } = args;
  if (gender === 'female' && age < 50) {
    const breastfeeding = {
      id: 'breastfeeding',
      type: 'binary',
      label: 'Ammar du?',
      clinicLabel: 'Ammar',
    };
    const breastfeedingAnswer = await ask(breastfeeding);
    significant.push({
      ...breastfeeding,
      label: breastfeeding.clinicLabel,
      value: breastfeedingAnswer,
      warn: ['yes'],
    });
    if (breastfeedingAnswer === 'yes') {
      exports.push({
        id: 'prescriptionWarnings',
        value: 'Ammar  \n**Ja**',
      });
    }
  }
};

export default async args => {
  const { ask, significant } = args;

  const desiredHelpAnswer = await ask(desiredHelp);
  significant.unshift({
    ...desiredHelp,
    label: desiredHelp.clinicLabel,
    value: desiredHelpAnswer,
  });

  const mainProblemAnswer = await ask(mainProblem);
  significant.unshift({
    ...mainProblem,
    label: mainProblem.clinicLabel,
    value: mainProblemAnswer,
  });

  await pregnant(args);
  await breastfeeding(args);
};

import type { GuideWithOut } from '../../types';

import ds from './decisionSupport';

export default (async args => {
  const { ask, decisionSupport, significant, exports } = args;
  const keyFinding = value => exports.push({ id: 'keyFindings', value });

  const repeat = await ask(
    `Are you asking for medication that is on repeat prescription?`,
    {
      id: 'repeat.match',
      description:
        'We will ask you about medications that are not on repeat prescription later.',
    }
  );
  const want = repeat === 'yes';
  let needReview = false;
  if (want) {
    keyFinding('Repeat prescription requested');

    // TODO: a bit of a special hack to display a header. Be warned!
    significant.push({
      type: 'text',
      value: 'Repeat medication'.toUpperCase(),
    });

    const wantUpload = await ask.choice(
      `Do you want to upload a photo of the repeat prescription, with the requested medication marked?`,
      {
        id: 'repeat.wantUpload',
        options: {
          yes: 'Yes, I will upload a photo',
          no: 'No, I will type my medication list',
        },
      }
    );
    if (wantUpload === 'yes') {
      const photos = await ask.upload(
        `Upload one or more photos of the repeat prescription. Make sure you mark the medication needed.`,
        {
          id: 'repeat.photos',
          description: `
The medication needed want must be clearly marked, and details including your child's name, address and date of birth must be visible.

Rest assured, your child's photos will be kept confidential and will only be added to your child's medical record.
          `,
        }
      );
      significant.push({
        type: 'upload',
        label: 'Photos of repeat prescriptions',
        value: photos,
      });
    } else {
      const medications = await ask.text(
        'List the medication you are requesting today, including strength and dose.',
        {
          id: 'repeat.text',
          description:
            'E.g. Hydrocortisone 1% ointment, 30 grams, apply twice a day.',
        }
      );
      significant.push({
        type: 'text',
        label: 'Repeat medications needed',
        value: medications,
      });
    }

    const early = await ask(
      `Are you asking for any of your child's medications earlier than normal?`,
      { id: 'repeat.early' }
    );
    if (early === 'yes') {
      const options = {
        holiday: `Going on holiday`,
        lost: 'Lost medication',
        used: 'Ran out because we needed to use more than expected',
        sync: 'Want to get repeat medications in sync',
        other: 'Other',
      };
      const why = await ask.choice(
        `Please tell us why you are asking for the medications earlier than normal?`,
        { id: 'repeat.early.why', options }
      );
      if (why === 'holiday') {
        decisionSupport.push(ds.early);
        keyFinding('Early medication request: Going on holiday');
      } else if (why === 'lost') {
        decisionSupport.push(ds.lost);
        keyFinding('Early medication request: Lost medication');
      } else if (why === 'used') {
        decisionSupport.push(ds.ranOut);
        keyFinding('Early medication request: Increased usage');
      } else if (why === 'other') {
        keyFinding('Early medication request');
        decisionSupport.push(ds.early);
      }

      if (why === 'other') {
        significant.push({
          type: 'text',
          label: 'Reason for early request',
          value: await ask.text('Please give details'),
          warn: true,
        });
      } else {
        significant.push({
          type: 'choice',
          label: 'Reason for early request of repeat prescription',
          options,
          value: why,
          warn: ['lost', 'used'],
        });
      }
    }

    const overdue = await ask.tertiary(
      "Is a medication review overdue for any of your child's medications?",
      {
        id: 'repeat.review.overdue',
        description: `Don't worry if it is, in most cases we can do this online.`,
      }
    );
    if (overdue === 'yes') {
      significant.push({
        type: 'tertiary',
        label: 'Medication review overdue?',
        value: overdue,
      });
      needReview = true;
    } else if (overdue === 'unknown') {
      if (
        (await ask('Do you have a copy of the repeat order slip?', {
          id: 'repeat.review.slip',
          description: `This is the 'tear off slip' that can be found on the right hand side of the last prescription.`,
        })) === 'no'
      ) {
        needReview = true;
      } else {
        const options = {
          withinMonth: 'Within the next month',
          '1To12Months': '1-12 months',
          overdue: 'It is overdue',
          notFound: `I can't see a review date`,
        };
        const date = await ask.choice({
          id: 'repeat.review.due',
          description: `
# The review date can usually be found at the bottom of the repeat order slip.

# If you can see it, when is your medication review due?
          `,
          options,
        });
        if (date !== 'notFound') {
          significant.push({
            type: 'choice',
            label: 'When is medication review due?',
            options,
            value: date,
          });
        }

        if (date !== '1To12Months') {
          needReview = true;
        }
      }
    } else {
      significant.push({
        type: 'tertiary',
        label: 'Medication review overdue?',
        value: overdue,
        warn: true,
      });
    }
  }

  return { decisionSupport, significant, want, needReview };
}: GuideWithOut<{ want: boolean, needReview: boolean }>);

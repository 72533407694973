import type { Guide } from '../../../types';
import { maxExpiry } from '../../common/consts';

export default (async args => {
  const { ask, decisionSupport, significant } = args;

  const heightUnit = await ask.choice('How tall are you?', {
    id: 'moduleBmi.heightUnit',
    description: 'Please choose to tell us in either centimetres or feet',
    expires: maxExpiry,
    options: {
      metric: 'Centimetres',
      imperial: 'Feet and inches',
    },
  });

  let heightMetric = 0;

  if (heightUnit === 'metric') {
    heightMetric =
      (await ask.number('How tall are you?', {
        id: 'moduleBmi.heightCm',
        unit: 'centimetres',
        description: 'Enter in centimetres',
        min: 50,
        max: 300,
        expires: maxExpiry,
      })) || 0;
  } else {
    const heightFeet =
      (await ask.number('How tall are you?', {
        id: 'moduleBmi.heightFeet',
        description: `
We ask you to enter your height over two screens

Please enter whole feet on this screen`,
        unit: 'feet',
        min: 1,
        max: 9,
        expires: maxExpiry,
      })) || 0;
    const heightInches =
      (await ask.number('How tall are you?', {
        id: 'moduleBmi.heightInches',
        description: 'Please enter inches on this screen',
        unit: 'inches',
        min: 0,
        max: 11,
        expires: maxExpiry,
      })) || 0;

    heightMetric = 30.48 * heightFeet + 2.54 * heightInches;
  }

  const weightUnit = await ask.choice('How much do you weigh?', {
    id: 'moduleBmi.weightUnit',
    description:
      'Please choose to tell us in either kilograms or stones and pounds',
    expires: 180,
    options: {
      metric: 'Kilograms',
      imperial: 'Stones and pounds',
    },
  });

  let weightMetric = 0;

  if (weightUnit === 'metric') {
    weightMetric =
      (await ask.number('How much do you weigh?', {
        id: 'moduleBmi.weightKg',
        description: `Enter in kilograms`,
        expires: 180,
        unit: 'kilograms',
        min: 20,
        max: 700,
      })) || 0;
  } else {
    const weightStones =
      (await ask.number('How much do you weigh?', {
        id: 'moduleBmi.weightStone',
        description: `
We ask you to enter your weight over two screens

Please enter whole stones on this screen
`,
        expires: 180,
        min: 2,
        max: 80,
        unit: 'stones',
      })) || 0;
    const weightPounds =
      (await ask.number('How much do you weigh?', {
        id: 'moduleBmi.weightPounds',
        description: `Please enter pounds on this screen`,
        expires: 180,
        min: 0,
        max: 13,
        unit: 'pounds',
      })) || 0;

    weightMetric = 6.35029 * weightStones + 0.453592142840941 * weightPounds;
  }

  const bmi = (weightMetric * 10000) / (heightMetric * heightMetric);

  significant.push({
    type: 'number',
    label: 'BMI',
    value: Math.round(bmi * 100) / 100,
  });

  if (bmi <= 18.5) {
    decisionSupport.push({
      label: 'BMI ≤ 18.5',
      description: 'Ask patient re: Low BMI',
      color: 'black',
    });
  }

  return {};
}: Guide);

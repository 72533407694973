import type { Guide } from '../../types';

import informedConsent from '../questions/informedConsent';

import pharmacy from '../modules/pharmacy';

import triage from './triage';
import repeat from './repeat';
import restart from './restart';
import change from './change';
import start from './start';

export default (async args => {
  const { ask, decisionSupport, significant, exports } = args;

  const { abort: triageAbort } = await triage(args);
  if (triageAbort) {
    return { abort: triageAbort };
  }

  const helpWithQuestion = {
    id: 'helpWith',
    type: 'choice',
    options: {
      repeat: 'Repeat prescription of my usual contraceptive',
      restart: 'Restart my usual contraceptive after a break',
      change: 'I want to change contraceptive',
      start: 'I want to start using contraception',
    },
  };
  const helpWith = await ask({
    ...helpWithQuestion,
    label: 'What do you need help with today?',
  });
  significant.push({
    ...helpWithQuestion,
    label: 'What do you need?',
    value: helpWith,
  });
  exports.push({
    id: 'keyFindings',
    value: {
      repeat: 'Repeat prescription of contraceptive',
      restart: 'Restart contraception',
      change: 'Change contraceptive',
      start: 'Start using contraception',
    }[helpWith],
  });

  const { abort } = await { repeat, restart, change, start }[helpWith](args);
  if (abort) {
    return { abort };
  }

  await pharmacy(args);

  await ask(informedConsent);

  filterContraceptiveDecisionSupport(decisionSupport);

  exports.push({ id: 'presentingComplaint', value: 'Contraception' });
  exports.push({ id: 'snomedCode', value: '13197004 Contraception' });
  exports.push({ id: 'investigationsRequested', value: 'None' });
  exports.push({ id: 'prescribed', value: 'Prescribed medication' });
  exports.push({ id: 'actionNeeded', value: 'None' });

  return {};
}: Guide);

const filterContraceptiveDecisionSupport = items => {
  const temp = [];
  let found = false;
  while (items.length) {
    const item = items.pop();
    if (item.id === 'contraceptive') {
      if (found) continue;
      found = true;
    }
    temp.push(item);
  }
  for (let i = temp.length - 1; i >= 0; i--) items.push(temp[i]);
};

// @flow

import type { Export } from '../../types';

export default (exports: Export[]) => {
  const existingUrgentIdx = exports.findIndex(e => e.id === 'urgent');
  if (existingUrgentIdx >= 0) {
    if (!exports[existingUrgentIdx].value) {
      exports[existingUrgentIdx] = { id: 'urgent', value: true };
    }
  } else {
    exports.push({ id: 'urgent', value: true });
  }
};

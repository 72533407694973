import { type DecisionSupport } from '../../types';

const f2f = 'Consider F2F';
const appointmentNeeded = 'Doctor to decide if appointment needed';

const decisionSupport: { [string]: DecisionSupport } = {
  // Triage DS
  riskOfDehydrationFluidIntake: {
    color: 'red',
    label: f2f,
    description: 'Reduced fluid intake, risk of dehydration',
  },
  riskOfDehydrationFluidOutput: {
    color: 'red',
    label: f2f,
    description: 'Reduced fluid output, risk of dehydration',
  },
  diarrhoea: {
    color: 'yellow',
    label: 'Use dehydration template',
    description: 'Dehydration',
  },
  viralSymptoms: {
    color: 'black',
    label: 'Consider video consultation or F2F GP appointment',
    description: 'Viral symptoms',
  },
  // Cradle cap DS
  cradleCapAdvice: {
    color: 'yellow',
    label: 'Use cradle cap template',
    description: 'Use cradle cap advice',
  },
  infectedCradleCap: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Parent concerned cradle cap infected',
  },
  notCradleCap: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Parent concerned not cradle cap',
  },
  cradleCapLateOnset: {
    color: 'black',
    label: 'Consider altternative diagnosis',
    description: 'Late onset cradle cap',
  },
  // Nappy rash DS
  nappyRashAdvice: {
    color: 'black',
    label: 'Use nappy rash message template',
    description: 'Wants general advice on nappy rash',
  },
  infectedNappyRash: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Parent concerned rash infected',
  },
  notNappyRash: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Parent concened not nappy rash',
  },
  distressedBaby: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Baby distressed',
  },
  napkinCandidasis: {
    color: 'black',
    label: 'Antibiotics used in the past week',
    description: 'Potential risk factor for napkin candidaisis',
  },
  oralCandidasis: {
    color: 'black',
    label: 'White coating on tongue/ cheeks',
    description: 'Increased likelihood of napkin candidasis',
  },
  // Eczema DS
  infectedRash: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Parent concened rash infected',
  },
  eczemaFace: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Rash on face/ neck',
  },
  eczemaGenitalsOrButtocks: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Rash on genitals/ buttocks',
  },
  alreadyOnAntibiotics: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Currently on antibiotics',
  },
  chooseEmollient: {
    color: 'green',
    label: 'Doctor to choose emollient ',
    description: 'Please choose emollient',
  },
  prescribeEmollient: {
    color: 'green',
    label: 'Doctor to prescribe emollient patient has requested',
    description: 'Please prescribe requested emollient',
  },
  potentSteroidPresently: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Using potent steroid',
  },
  potentialSteroidOveruse: {
    color: 'yellow',
    label: 'Use steroid overuse template',
    description: 'Potential steroid overuse',
  },
  signsOfSteroidOveruse: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Signs of steroid overuse',
  },
  // Acne DS
  scarringAcne: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Scarring acne',
  },
  distressPsychological: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Psychological distress',
  },
  bloodTests: {
    color: 'black',
    label: 'Previous bloods',
    description: 'Review FBC, LFT and U&Es for Acne',
  },
  noBloodTests: {
    color: 'black',
    label: 'No previous bloods',
    description: 'Review FBC, LFT and U&Es for Acne',
  },
  COCP: {
    color: 'yellow',
    label: 'Use COCP template',
    description: 'Would like COCP for acne',
  },
  // Verruca DS
  facialVerruca: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Facial verucca',
  },
  bleedingVerruca: {
    color: 'green',
    label: appointmentNeeded,
    description: 'Bleeding verruca',
  },
  // General Rash DS
  impetigo: {
    color: 'red',
    label: 'Consider impetigo',
    description: 'Yellow/ brown crust',
  },
  wheal: {
    color: 'red',
    label: 'Consider wheal',
    description: 'Swollen red bumps',
  },
};

Object.keys(decisionSupport).forEach(id => (decisionSupport[id].id = id));

export default decisionSupport;

import * as React from 'react';
import classNames from 'classnames';

import { _t } from '../../i18n';

import colors from '../theme/colors';

import { type Option } from '../../guides/types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import withStyles from '@material-ui/core/styles/withStyles';

import Next from '../components/Next';

const checkboxIconOff = require('../../images/icons/ic_checkbox_off.svg');
const checkboxIconOn = require('../../images/icons/ic_checkbox_on.svg');

class MultipleChoice extends React.Component<{
  value?: *,
  error?: string,
  options: Option[],
  update: (?*) => *,
  done: (?*) => *,
  classes: { [string]: string },
}> {
  kp = event => {
    const { charCode } = event;
    const { options } = this.props;
    const number = charCode >= 49 && charCode <= 57 && charCode - 48;

    if (!!number && number <= options.length) {
      this.onChange(this.props.options[number - 1].value)();
    }

    if (charCode === 13) {
      this.props.done();
    }
  };

  componentDidMount() {
    window.document.addEventListener('keypress', this.kp);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keypress', this.kp);
  }

  onChange = value => () => {
    const { value: prevValues = [], update } = this.props;

    const values = prevValues.includes(value)
      ? prevValues.filter(v => v !== value)
      : [...prevValues, value];

    update(values);
  };

  done = () => {
    const { value: values, done } = this.props;

    done(values === undefined ? [] : undefined);
  };

  render() {
    const { value: values = [], error, options = [], classes } = this.props;

    const short = !options.find(o => o.label.length > 5);

    return (
      <div className={classes.root}>
        <div className={classes.options}>
          {options.map(({ value: v, label }, i) => {
            const selected = values.includes(v);

            return (
              <FormControlLabel
                key={v}
                control={
                  <Checkbox
                    checked={selected}
                    onChange={this.onChange(v)}
                    color="primary"
                    icon={
                      <img
                        alt="off"
                        src={checkboxIconOff}
                        className={classes.radioIcon}
                      />
                    }
                    checkedIcon={
                      <img
                        alt="on"
                        src={checkboxIconOn}
                        className={classes.radioIcon}
                      />
                    }
                    className={classes.checkbox}
                  />
                }
                label={label}
                className={classes.option}
                classes={{
                  label: classNames(
                    classes.label,
                    short && classes.labelShort,
                    i === options.length - 1 && classes.labelLast
                  ),
                }}
              />
            );
          })}
        </div>

        <div className={classes.buttons}>
          <Next
            label={
              !values.length && error === undefined
                ? _t('input.noneOfTheAbove')
                : undefined
            }
            disabled={!!error}
            onClick={this.done}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  options: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: -spacing(),
    marginBottom: -spacing(),
    marginRight: -spacing(3),
  },

  option: {
    width: `calc(100% - ${spacing(8) + 5}px)`,
    marginLeft: spacing(8),
    [down('xs')]: {
      width: `calc(100% - ${spacing(5) + 5}px)`,
      marginLeft: spacing(5),
    },
    paddingLeft: 5,
  },

  label: {
    flex: 1,
    paddingTop: spacing(2.5),
    paddingBottom: spacing(2.5),
    paddingRight: spacing(3),
    borderBottom: `1px solid ${colors.grey_300}`,
    fontWeight: 500,
    minWidth: 160,
  },

  labelShort: {
    minWidth: 80,
  },

  labelLast: {
    borderBottom: 'none',
  },

  checkbox: {
    marginRight: spacing(),
  },

  checkboxIcon: {
    width: 28,
    height: 28,
  },

  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: spacing(3),
  },
}))(MultipleChoice);

import * as React from 'react';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';

import withStyles from '@material-ui/core/styles/withStyles';

const arrowIconSource = require('../../images/icons/ic_arrow_right.svg');

class Back extends React.Component<{
  onClick: () => *,
  classes: { [string]: string },
  className?: string,
}> {
  render() {
    const { onClick, classes, className } = this.props;

    return (
      <IconButton
        color="secondary"
        onClick={onClick}
        className={classNames(classes.root, className)}
      >
        <img alt="icon" src={arrowIconSource} className={classes.icon} />
      </IconButton>
    );
  }
}

export default withStyles(() => ({
  root: {
    height: 56,
    width: 56,
    padding: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },

  icon: {
    transform: 'rotate(180deg)',
    height: 20,
  },
}))(Back);

import type { Guide } from '../../types';

export default (async ({ ask, significant }) => {
  const followedAdvice = {
    type: 'choice',
    id: 'dieticianRevisit.followedAdvice',
    label:
      'Du har nu påbörjat ett återbesök hos din dietist. Tycker du att du lyckats följa de råd du fick vid förra besöket?',
    options: [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'partly',
        label: 'Delvis',
      },
      {
        value: 'no',
        label: 'Nej',
      },
      {
        value: 'noAdvice',
        label: 'Jag har inte fått några råd ännu',
      },
    ],
  };

  const followedAdviceAnswer = await ask(followedAdvice);

  significant.push({
    ...followedAdvice,
    label: 'Tycker du att du lyckats följa de råd du fick vid förra besöket?',
    value: followedAdviceAnswer,
  });

  if (followedAdviceAnswer === 'partly' || followedAdviceAnswer === 'no') {
    const didntFollowAdviceWhy = {
      type: 'text',
      id: 'dieticianRevisit.didntFollowAdviceWhy',
      label: 'Beskriv vad som gjorde att du haft svårt att följa råden.',
    };
    const didntFollowAdviceWhyAnswer = await ask(didntFollowAdviceWhy);

    significant.push({
      ...didntFollowAdviceWhy,
      label: 'Beskriv vad som gjorde att du haft svårt att följa råden.',
      value: didntFollowAdviceWhyAnswer,
    });
  }

  const photos = await ask.upload({
    label:
      'Om du har dokument eller bilder av vad du ätit, testresultat, mätvärden eller liknande, kan du ladda upp dem här.',
    description:
      'Observera att även bilderna är en del av din journal och omfattas av sekretess.',
    optional: true,
  });

  significant.push({
    type: 'upload',
    label: 'Bilder',
    value: photos,
  });

  const other = {
    type: 'text',
    id: 'dieticianRevisit.other',
    label:
      'Om det finns något annat du skulle vilja förmedla till dietisten inför besöket går det bra att göra det här.',
    optional: true,
  };
  const otherAnswer = await ask(other);

  significant.push({
    ...other,
    label: 'Övrig information',
    value: otherAnswer,
  });

  return {};
}: Guide);

import type { Guide } from '../../types';

import aborts from './aborts';
import chc from './chc';
import { decisionSupports } from './decisionSupport';
import { lowDosePills, popPills } from './pills';
import pop from './pop';
import triedPills from './triedPills';
import usingContraceptive from './usingContraceptive';
import wantBrand from './wantBrand';

export default (async args => {
  const { ask, significant, decisionSupport } = args;

  let doctorChoose = false;
  let skinPatch = false;
  let lowDose = false;

  await usingContraceptive(args);

  const whyQuestion = {
    type: 'choice',
    label: 'Why do you want to change contraceptive?',
    options: {
      weightGain: 'Weight gain',
      moodChange: 'Mood change',
      headaches: 'Headaches',
      acneOrSpots: 'Acne or spots',
      other: 'Other',
    },
  };
  const why = await ask(whyQuestion);
  significant.push({
    ...whyQuestion,
    label: 'Reason for change',
    value: why,
    warn: true,
  });
  if (why === 'other') {
    significant.push({
      type: 'text',
      label: 'Details',
      value: await ask.text('Please explain your reasons for changing'),
    });
  }

  await triedPills(args);

  const wantQuestion = {
    type: 'choice',
    label: 'Do you know what kind of contraceptive you would like to try?',
    options: {
      combined:
        'Combined Pill (usually taken for 21 days followed a 7 day break)',
      progesterone:
        'Progesterone Only "Mini" pill (taken everyday without a break)',
      dontKnow: `I don't know - can the doctor choose a pill for me?`,
      knowName: 'I know the name of the pill, but not the type',
      skinPatch: 'Skin Patch (Evra)',
      vaginalRing: 'Vaginal Ring (NuvaRing)',
      injectionImplantCoil: 'Injection, Implant, Coil (IUD), or Mirena (IUS)',
    },
  };
  let want = await ask(wantQuestion);
  significant.push({ ...wantQuestion, value: want });
  if (want === 'injectionImplantCoil') {
    const question = {
      type: 'choice',
      description: `
We are unable to offer injections, implants, coils and some other contraceptives online.

What would you like to do?
        `,
      options: {
        doctorChoose: 'Let the doctor choose a pill for me',
        skinPatch: 'Try the skin patch',
        vaginalRing: 'Try the vaginal ring',
        appointment:
          'Get an appointment for the injection, implant, coil (IUD) or hormonal coil (Mirena / IUS)',
      },
    };
    want = await ask(question);
    significant.push({
      ...question,
      value: want,
      warn: 'appointment',
    });
    if (want === 'appointment') {
      return { abort: aborts.appointment };
    }
  }
  if (want === 'doctorChoose') {
    doctorChoose = true;
    decisionSupport.push(decisionSupports.changeContraceptiveDoctorChoose);
  } else if (want === 'skinPatch') {
    skinPatch = true;
    decisionSupport.push(decisionSupports.changeContraceptiveSkinPatch);
  } else if (want === 'vaginalRing') {
    decisionSupport.push(decisionSupports.changeContraceptiveVaginalRing);
  } else {
    const { brandName } = await wantBrand({
      ...args,
      progesterone: want === 'progesterone',
    });

    if (brandName === 'none') {
      decisionSupport.push(
        decisionSupports.changeContraceptive('Contraceptive pill')
      );
    } else if (brandName !== undefined) {
      decisionSupport.push(decisionSupports.changeContraceptive(brandName));
    } else {
      doctorChoose = true;
      decisionSupport.push(decisionSupports.changeContraceptiveDoctorChoose);
    }

    if (popPills.includes(brandName)) {
      return await pop(args);
    }

    lowDose = lowDosePills.includes(brandName);
  }

  return await chc({
    ...args,
    doctorChoose,
    skinPatch,
    lowDose,
  });
}: Guide);

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import parseGuide from '../../common/parseGuide';
import computeGuide from '../../common/computeGuide';

import { type AsyncAction } from '../types';

import { getApi, postApi } from '../api';

import { getPatient } from '../patients/selectors';

export const fetchGuide = (id: string): AsyncAction => async (
  dispatch,
  getState
) => {
  const patient = getPatient(getState());
  const { json = {}, error = false } = await getApi(`/guides/${id}`);

  if (!error) {
    const { name, script } = json;

    const guide = parseGuide(name, script);

    console.log(
      'api fetch guide',
      await computeGuide(guide, patient, [], {}, { randomize: true })
    );
  }

  return { json, error };
};

export const fetchSource = (id: string): AsyncAction => async () => {
  const { json: { source } = {}, error } = await getApi(
    `/guides/sources/${id}`
  );

  return { source, error };
};

export const postGuide = (id: string, result: *): AsyncAction => async (
  dispatch,
  getState
) => {
  const { json, error = false } = await postApi('/guides', {
    id,
    answers: result.answers,
    patient: getPatient(getState()),
  });

  if (!error) {
    const compareKeys = [
      'answers',
      'abort',
      'decisionSupport',
      'significant',
      'exports',
    ];
    console.log(
      'api post guide',
      json,
      isEqual(
        pick(json, ...compareKeys),
        pick(JSON.parse(JSON.stringify(result)), ...compareKeys)
      )
        ? 'match'
        : 'no match'
    );
  }

  return { json, error };
};

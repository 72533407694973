import React from 'react';
import { Router, Redirect } from '@reach/router';

import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import { getUser } from '../state/users/selectors';

import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';

import ThemeProvider from './theme/ThemeProvider';

import UnauthenticatedApp from './UnauthenticatedApp';

import Guides from './guides/Guides';
import Guide from './guides/Guide';
import Source from './guides/Source';
import Result from './guides/Result';
import Abort from './guides/Abort';
import NotFound from './guides/NotFound';

const AuthenticatedApp = () => {
  return (
    <ThemeProvider>
      <Router>
        <NotFound default />
        <Redirect from="/" to="/se/guides" noThrow />
        <Guides path="/:country/guides" />
        <Guide path="/:country/guide/:guideId" />
        <Result path="/:country/guide/:guideId/result" />
        <Source path="/:country/guide/:guideId/source" />
        <Abort path="/:country/guide/:guideId/abort" />
      </Router>
    </ThemeProvider>
  );
};

const UnauthApp = (props: *) => (
  <ThemeProvider>
    <UnauthenticatedApp {...props} />
  </ThemeProvider>
);

const Loading = styled(CircularProgress)({
  position: 'absolute',
  left: '50%',
  top: '50%',
});

function App() {
  let user = useSelector(getUser);
  const loading = !isLoaded(user);
  if (isEmpty(user)) {
    user = undefined;
  }

  let error = undefined;
  if (user) {
    if (
      !(
        user.email.endsWith('@docly.com') ||
        user.email.endsWith('@mindoktor.se')
      )
    ) {
      error = 'Please sign in with your Docly or Min Doktor Google account.';
      user = undefined;
    }
  }

  return loading ? (
    <Loading />
  ) : user ? (
    <AuthenticatedApp />
  ) : (
    <UnauthApp error={error} />
  );
}

export default App;

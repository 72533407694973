import type { Guide } from '../../types';

import aborts from './aborts';

import ds from './decisionSupport';
import { addExportIfValueExists } from './utils';

export default (async args => {
  const { ask, decisionSupport, exports, significant } = args;

  const cradleCapHelpWith = {
    type: 'choice',
    id: 'cradleCapHelpWith',
    label: "How can we help with your baby's cradle cap?",
    options: [
      {
        label: "Advice on how to help my baby's cradle cap",
        value: 'advice',
      },
      {
        label: "I'm worried that my baby's cradle cap is infected",
        value: 'infected',
      },
      {
        label:
          "I'm worried that my baby has got something other than cradle cap",
        value: 'notCradleCap',
      },
      { label: 'Other', value: 'other' },
    ],
  };

  const cradleCapHelpWithAnswer = await ask(cradleCapHelpWith);

  if (!cradleCapHelpWithAnswer.includes('other')) {
    significant.push({
      ...cradleCapHelpWith,
      label: 'Reason to seek help today',
      value: cradleCapHelpWithAnswer,
      warn:
        cradleCapHelpWithAnswer.includes('infected') ||
        cradleCapHelpWithAnswer.includes('notCradleCap'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      advice: 'Help to manage cradle cap',
      infected: 'Worried cradle cap infected',
      notCradleCap: 'Worried rash is not cradle cap',
    }[cradleCapHelpWithAnswer],
  });

  if (cradleCapHelpWithAnswer.includes('advice')) {
    decisionSupport.push(ds.cradleCapAdvice);
  }
  if (cradleCapHelpWithAnswer.includes('infected')) {
    decisionSupport.push(ds.infectedCradleCap);
  }
  if (cradleCapHelpWithAnswer.includes('notCradleCap')) {
    decisionSupport.push(ds.notCradleCap);
  }

  if (cradleCapHelpWithAnswer.includes('other')) {
    const cradleCapHelpWithOther = {
      type: 'text',
      id: 'cradleCapHelpWithOther',
      label: "Please tell us what you would like from today's visit.",
    };

    const cradleCapHelpWithOtherAnswer = await ask(cradleCapHelpWithOther);

    significant.push({
      ...cradleCapHelpWithOther,
      label: 'Reason to seek help today',
      value: cradleCapHelpWithOtherAnswer,
    });
  }

  const cradleCapSinceBirth = {
    type: 'binary',
    id: 'cradleCapSinceBirth',
    label: 'Has your child had cradle cap since birth?',
  };

  const cradleCapSinceBirthAnswer = await ask(cradleCapSinceBirth);

  significant.push({
    ...cradleCapSinceBirth,
    label: 'Cradle cap since birth',
    value: cradleCapSinceBirthAnswer,
  });

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yes: 'Cradle cap since birth',
      no: 'Not had cradle cap since birth',
    }[cradleCapSinceBirthAnswer],
  });

  if (cradleCapSinceBirthAnswer === 'no') {
    const cradleCapStarted = {
      type: 'choice',
      id: 'cradleCapStarted',
      label: 'How old was your child when their cradle cap first started?',
      options: [
        { label: 'A few weeks old', value: 'aFeewWeeks' },
        { label: '1 month old', value: 'oneMonth' },
        { label: '1-3 months old', value: 'oneToThreeMonths' },
        { label: 'Older than 3 months', value: 'olderThanThreeMonths' },
      ],
    };

    const cradleCapStartedAnswer = await ask(cradleCapStarted);

    if (!cradleCapStartedAnswer.includes('olderThanThreeMonths')) {
      significant.push({
        ...cradleCapStarted,
        label: 'Cradle cap onset',
        value: cradleCapStartedAnswer,
      });
    }

    addExportIfValueExists(exports, {
      id: 'keyFindings',
      value: {
        aFeewWeeks: 'Cradle cap from a few weeks old',
        oneMonth: 'Cradle cap from 1 month old',
        oneToThreeMonths: 'Cradle cap from 1-3 months old',
        olderThanThreeMonths: 'Cradle cap from older than 3 months',
      }[cradleCapStartedAnswer],
    });

    if (cradleCapStartedAnswer.includes('olderThanThreeMonths')) {
      const onsetCradleCapWhen = {
        type: 'text',
        id: 'onsetCradleCapWhen',
        label:
          'Please tell us how old your baby was when their cradle cap first started.',
      };

      const onsetCradleCapWhenAnswer = await ask(onsetCradleCapWhen);

      significant.push({
        ...onsetCradleCapWhen,
        label: 'Onset of cradle cap',
        value: onsetCradleCapWhenAnswer,
      });

      decisionSupport.push(ds.cradleCapLateOnset);
    }
  }

  const descriptionCradleCap = {
    type: 'multipleChoice',
    id: 'descriptionCradleCap',
    label: "What best describes your baby's cradle cap?",
    description: 'More than one answer can be selected.',
    options: [
      { label: 'Yellow, flaky, scaly', value: 'yellowFlakyScaly' },
      { label: 'Weeping or oozing', value: 'weepingOrOozing' },
      { label: 'Bleeding', value: 'bleeding' },
      { label: 'Boggy patch', value: 'boggyPatch' },
      { label: 'Other', value: 'other' },
    ],
  };

  const descriptionCradleCapAnswer = await ask(descriptionCradleCap);

  if (!descriptionCradleCapAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...descriptionCradleCap,
      label: 'Description of cradle cap',
      description: '',
      value: descriptionCradleCapAnswer.filter(answer => answer !== 'other'),
      warn:
        descriptionCradleCapAnswer.includes('weepingOrOozing') ||
        descriptionCradleCapAnswer.includes('bleeding') ||
        descriptionCradleCapAnswer.includes('boggyPatch'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      yellowFlakyScaly: 'Yellow, flaky, scaly cradle cap',
      weepingOrOozing: 'Weeping or oozing cradle cap',
      bleeding: 'Bleeding cradle cap',
      boggyPatch: 'Boggy patch cradle cap',
      other: 'Other cradle cap',
    }[descriptionCradleCapAnswer],
  });

  if (
    descriptionCradleCapAnswer.includes('weepingOrOozing') ||
    descriptionCradleCapAnswer.includes('bleeding') ||
    descriptionCradleCapAnswer.includes('boggyPatch')
  ) {
    return { abort: aborts.potentialCellulitis };
  }

  if (descriptionCradleCapAnswer.includes('other')) {
    const descriptionCradleCapOther = {
      type: 'text',
      id: 'descriptionCradleCapOther',
      label: "Please describe your baby's cradle cap rash.",
    };

    const descriptionCradleCapOtherAnswer = await ask(
      descriptionCradleCapOther
    );

    significant.push({
      ...descriptionCradleCapOther,
      label: 'Cradle cap rash details',
      value: descriptionCradleCapOtherAnswer,
      warn: true,
    });
  }

  const treatments = [
    {
      label: 'Brushing off the scales',
      value: 'brush',
    },
    { label: 'Baby shampoo', value: 'schampoo' },
    { label: 'Oil', value: 'oil' },
    { label: 'Other', value: 'other' },
  ];

  const cradleCapTreatment = {
    type: 'multipleChoice',
    id: 'cradleCapTreatment',
    label: "What have you tried for your baby's cradle cap?",
    description: 'More than one answer can be selected',
    options: treatments,
    optional: true,
  };

  const cradleCapTreatmentAnswer = await ask(cradleCapTreatment);

  if (!cradleCapTreatmentAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...cradleCapTreatment,
      label: 'Which treatment helped?',
      description: '',
      value: cradleCapTreatmentAnswer.filter(answer => answer !== 'other'),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      brush: 'Tried brushing off the scales of cradle cap',
      schampoo: 'Tried baby shampoo for cradle cap',
      oil: 'Tried oil for cradle cap',
      other: 'Other',
    }[cradleCapTreatmentAnswer],
  });

  if (cradleCapTreatmentAnswer.includes('other')) {
    const cradleCapOtherTreatment = {
      type: 'text',
      id: 'cradleCapOtherTreatment',
      label: 'Please tell us what you have tried',
    };

    const cradleCapOtherTreatmentAnswer = await ask(cradleCapOtherTreatment);

    significant.push({
      ...cradleCapOtherTreatment,
      label: 'Other tx used so far',
      value: cradleCapOtherTreatmentAnswer,
    });
  }

  const cradleCapTreatmentEvaluation = {
    type: 'multipleChoice',
    id: 'cradleCapTreatmentEvaluation',
    label: "What has helped your baby's cradle cap so far?",
    description: 'More than one answer can be selected',
    options: treatments,
    optional: true,
  };

  const cradleCapTreatmentEvaluationAnswer = await ask(
    cradleCapTreatmentEvaluation
  );

  if (!cradleCapTreatmentEvaluationAnswer.every(answer => answer === 'other')) {
    significant.push({
      ...cradleCapTreatmentEvaluation,
      label: 'Treatment helped cradle cap symptoms',
      description: '',
      value: cradleCapTreatmentEvaluationAnswer.filter(
        answer => answer !== 'other'
      ),
    });
  }

  addExportIfValueExists(exports, {
    id: 'keyFindings',
    value: {
      brush: 'Brushing the scales helped cradle cap',
      schampoo: 'Shampoo helped cradle cap',
      oil: 'Oil helped cradle cap',
    }[cradleCapTreatmentEvaluationAnswer],
  });

  if (cradleCapTreatmentEvaluationAnswer.includes('other')) {
    const cradleCapOtherEvaluation = {
      type: 'text',
      id: 'cradleCapOtherEvaluation',
      label: "Please tell us what has helped your baby's cradle cap.",
    };

    const cradleCapOtherEvaluationAnswer = await ask(cradleCapOtherEvaluation);

    significant.push({
      ...cradleCapOtherEvaluation,
      label: 'Treatment helped cradle cap symptoms',
      value: cradleCapOtherEvaluationAnswer,
    });
  }

  const photoCradleCap = await ask.upload(
    `We'd like you to upload a photo of your child's cradle cap rash to help the doctor with their diagnosis.`,
    {
      description: `
                You can use a mobile phone camera to take the photo.\n\nRest assured, your photos will be kept confidential and will only be added to your medical record.
              `,
      optional: true,
    }
  );
  significant.push({
    type: 'upload',
    label: 'Photo/s',
    value: photoCradleCap,
  });

  return {};
}: Guide);

import type { GuideWithOut } from '../../types';

import ds from './decisionSupport';

export default (async args => {
  const {
    ask,
    patient: { age },
    significant,
    decisionSupport,
  } = args;

  let score = 0;

  significant.push({
    type: 'text',
    value: 'Red flag symptoms'.toUpperCase(),
  });

  const swallowingQuestion = {
    type: 'choice',
    options: {
      normal: 'My swallowing is normal',
      stuck: 'It feels like food or drink gets stuck when I swallow',
    },
  };
  const swallowing = await ask({
    ...swallowingQuestion,
    label: 'How is your swallowing?',
  });
  significant.push({
    ...swallowingQuestion,
    label: 'Swallowing',
    value: swallowing,
    warn: 'stuck',
  });
  if (swallowing === 'stuck') {
    score++;
    decisionSupport.push(ds.twoWeekWait);
  }

  const energyQuestion = {
    type: 'choice',
    options: {
      normal: 'My energy levels feel normal',
      tired: 'I feel more tired than normal',
    },
  };
  const energy = await ask({
    ...energyQuestion,
    label: 'How have you energy levels been since your symptoms started?',
  });
  significant.push({
    ...energyQuestion,
    label: 'Energy levels',
    value: energy,
    warn: energy === 'tired',
  });
  if (energy === 'tired') {
    score++;
    decisionSupport.push(ds.tiredness);
  }

  const weightChangeQuestion = {
    type: 'choice',
    options: {
      yesNotIntentional: 'Yes, I have lost weight without meaning to',
      yesIntentional: 'Yes, I have lost weight but I have been trying to',
      yesGained: 'Yes, I have gained weight',
      no: 'No',
      notSure: `I'm not sure`,
    },
  };
  const weightChange = await ask({
    ...weightChangeQuestion,
    label: 'Has your weight changed in the last 6 months?',
  });
  if (weightChange !== 'notSure') {
    significant.push({
      ...weightChangeQuestion,
      label: 'Weight change',
      value: weightChange,
      warn: 'yesNotIntentional',
    });
  }
  if (weightChange === 'yesNotIntentional') {
    score++;
    if (age >= 55) {
      decisionSupport.push(ds.twoWeekWait);
    } else {
      decisionSupport.push(ds.weightLoss);
    }
  }

  const looseClothes = await ask('Do your clothes feel looser than normal?');
  significant.push({
    label: 'Loose clothes',
    value: looseClothes,
    warn: 'yes',
  });
  if (looseClothes === 'yes') {
    score++;
    if (age >= 55) {
      decisionSupport.push(ds.twoWeekWait);
    } else {
      decisionSupport.push(ds.weightLoss);
    }
  }

  const stoolsQuestion = {
    type: 'choice',
    options: {
      brown: 'My poo is brown',
      black: 'My poo is black',
      paleOrYellow: 'My poo is pale or yellow',
    },
  };
  const stools = await ask({
    ...stoolsQuestion,
    label: 'What colour is your poo?',
  });
  significant.push({
    ...stoolsQuestion,
    label: 'Stools',
    value: stools,
    warn: ['black', 'paleOrYellow'],
  });
  if (stools === 'paleOrYellow') {
    decisionSupport.push(ds.paleStools);
  }
  if (stools === 'black') {
    await ask.choice({
      description: `
**Black poo which is sticky, tar-like or runny can be a sign of a serious illness.**

You need urgent medical attention if you:

- Feel unwell
- Feel dizzy or light headed
- Feel weak or fatigued
- Have passed out recently

If it is outside of normal working hours, or it is the weekend, please go to to A&E or dial 999.
      `,
      options: ['I understand'],
    });

    const takingIron = await ask(
      'Are you taking iron tablets, or multi-vitamins which contain iron?'
    );
    significant.push({
      label: 'Taking Iron',
      value: takingIron,
      warn: 'no',
    });
    if (takingIron === 'yes') {
      decisionSupport.push(ds.blackStoolsTakingIron);
    } else {
      decisionSupport.push(ds.twoWeekWait);
    }
  }

  const appetiteQuestion = {
    type: 'multipleChoice',
    options: {
      same: 'It has stayed the same',
      increased: 'It has increased',
      decreased: 'It has decreased',
      // TODO: maybe ask fullEasily as a seperate question
      fullEasily: 'I get full easily',
    },
  };
  const appetite = await ask({
    ...appetiteQuestion,
    label: 'What is your appetite like?',
  });
  significant.push({
    ...appetiteQuestion,
    label: 'Appetite',
    value: appetite,
    warn: ['decreased', 'fullEasily'],
  });
  if (appetite === 'decreased') {
    decisionSupport.push(ds.decreasedAppetite);
  }
  if (appetite === 'fullEasily') {
    decisionSupport.push(ds.earlySatiety);
  }

  const massQuestion = {
    type: 'choice',
    options: {
      no: 'No',
      bloated: 'My stomach feels bloated',
      hardLump: 'I have felt a hard lump in the centre of my abdomen',
    },
  };
  const mass = await ask({
    ...massQuestion,
    label: 'Have you noticed a swelling in your stomach?',
  });
  significant.push({
    ...massQuestion,
    label: 'Stomach swelling',
    value: mass,
    warn: ['bloated', 'hardLump'],
  });
  if (mass === 'bloated') {
    decisionSupport.push(ds.bloating);
  }
  if (mass === 'hardLump') {
    decisionSupport.push(ds.twoWeekWait);
  }

  const moodRelatedIssues = await ask(
    'Do you struggle with mood related issues?'
  );
  if (moodRelatedIssues === 'yes') {
    const issuesQuestion = {
      type: 'multipleChoice',
      options: {
        veryStressed: 'I feel very stressed',
        low: 'I feel low',
        anxious: 'I get very anxious',
      },
    };
    const issues = await ask({
      ...issuesQuestion,
      label: 'What mood related issue do you struggle with?',
      description: 'More than one answer can be selected',
    });
    significant.push({
      ...issuesQuestion,
      label: 'Mood related issue',
      value: issues,
      warn: true,
    });
    if (issues.includes('low')) {
      await ask.choice({
        description: `
**Some people with low mood can sometimes reach dark places.**

You need urgent medical attention if you feel like you may hurt yourself or try to end things.

Please go to the nearest A&E or dial 999.

If you are struggling with your mood, this can also affect your symptoms. You should either make an appointment to talk to your GP or use the Docly "Feeling anxious" or "Feeling unhappy" options to get more help.
        `,
        options: ['I understand'],
      });
    }
  } else {
    significant.push({
      label: 'Mood related issue',
      value: moodRelatedIssues,
    });
  }

  return {
    score,
  };
}: GuideWithOut<{ score: number }>);

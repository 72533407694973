export default {
  id: ' conjunctivitis',
  type: 'formulary',
  version: '6',
  children: [
    {
      type: 'section',
      children: [
        {
          id: 'started',
          type: 'choice',
          version: '4',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'moreThan4days',
              label: 'För mer än 4 dagar sedan',
            },
          ],
          validations: {
            required: true,
          },
          label: 'När började besvären?',
          index: '0.0.0',
        },
        {
          id: 'eyes.conjunctivitis.location',
          version: '2',
          type: 'choice',
          options: [
            {
              value: 'left',
              label: 'I vänster öga',
            },
            {
              value: 'right',
              label: 'I höger öga',
            },
            {
              value: 'both',
              label: 'I båda ögonen',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Var har du besvär?',
          index: '0.0.1',
        },
      ],
      version: '1',
      index: '0.0',
    },
    {
      type: 'section',
      children: [
        {
          id: 'eyes.conjunctivitis.symptoms',
          version: '6',
          type: 'multipleChoice',
          options: [
            {
              value: 'stings',
              label: 'Det svider',
            },
            {
              value: 'itching',
              label: 'Det kliar',
            },
            {
              value: 'running',
              label: 'Det rinner',
            },
            {
              value: 'burning',
              label: 'Det bränner',
            },
            {
              value: 'sticky',
              label: 'Det är kletigt/varigt',
            },
            {
              value: 'pain',
              label: 'Det gör ont',
            },
            {
              value: 'sore',
              label: 'Det skaver',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
          validations: {
            required: true,
          },
          label: 'Vilka besvär har du?',
          description: 'Flera svarsalternativ möjliga.',
          index: '0.1.0',
        },
        {
          id: 'eyes.conjunctivitis.symptomsOther',
          version: '1',
          type: 'text',
          condition:
            "contains(get('answers.eyes.conjunctivitis.symptoms'), 'other')",
          validations: {
            required: true,
          },
          label: 'Vilka andra besvär har du?',
          index: '0.1.1',
        },
        {
          id: 'eyes.somethingInYourEye',
          type: 'binary',
          version: '1',
          validations: {
            required: true,
          },
          label: 'Misstänker du att något kommit in i ditt öga?',
          index: '0.1.2',
        },
        {
          id: 'eyes.physicalExam.info',
          version: '1',
          type: 'info',
          condition: "get('answers.eyes.somethingInYourEye') === 'yes'",
          validations: {
            required: false,
          },
          description:
            'Eftersom du misstänker att du fått in något i ditt öga, kan du behöva en fysisk undersökning. Du är självklart välkommen att söka vård hos oss, men det finns en viss risk att vi inte kan hjälpa dig.',
          index: '0.1.3',
        },
        {
          id: 'eyes.whatInYourEye',
          type: 'text',
          version: '1',
          condition: "get('answers.eyes.somethingInYourEye') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vad har du fått in i ditt öga?',
          index: '0.1.4',
        },
        {
          id: 'eyes.physicalTrauma.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Har du fått ett slag mot ditt öga?',
          index: '0.1.5',
        },
        {
          id: 'eyes.symptoms.light',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Upplever du smärta eller obehag i ögat när du tittar mot ljus, om du jämför med hur det brukar kännas?',
          index: '0.1.6',
        },
        {
          id: 'eyes.painEyeball.match',
          version: '1',
          type: 'binary',
          condition:
            "contains(get('answers.eyes.conjunctivitis.symptoms'), 'pain')",
          validations: {
            required: true,
          },
          label: 'Upplever du smärta inuti själva ögongloben?',
          index: '0.1.7',
        },
        {
          id: 'eyes.relatedToCold',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.eyes.somethingInYourEye') === 'no' && get('answers.eyes.physicalTrauma.match') === 'no'",
          validations: {
            required: true,
          },
          label: 'Uppstod ögonbesvären i samband med en förkylning?',
          index: '0.1.8',
        },
      ],
      version: '1',
      index: '0.1',
    },
    {
      type: 'section',
      children: [
        {
          id: 'eyes.affectedSight',
          type: 'binary',
          version: '2',
          validations: {
            required: true,
          },
          label: 'Upplever du att din syn är påverkad?',
          index: '0.2.0',
        },
        {
          id: 'eyes.affectedSightHow',
          version: '1',
          type: 'text',
          condition: "get('answers.eyes.affectedSight') === 'yes'",
          validations: {
            required: true,
          },
          label: 'På vilket sätt är din syn påverkad?',
          index: '0.2.1',
        },
        {
          id: 'eyes.contactLenses',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Använder du kontaktlinser?',
          index: '0.2.2',
        },
        {
          id: 'eyes.previousProblem.match',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label:
            'Har du tidigare haft något ögonbesvär eller någon ögonsjukdom?',
          index: '0.2.3',
        },
        {
          id: 'eyes.previousProblem.doctor.match',
          version: '1',
          type: 'binary',
          condition: "get('answers.eyes.previousProblem.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Har du sökt läkare för ögonbesväret eller ögonsjukdomen?',
          index: '0.2.4',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosis',
          version: '2-text',
          type: 'text',
          condition:
            "get('answers.eyes.previousProblem.doctor.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilken diagnos fick du då?',
          index: '0.2.5',
        },
      ],
      version: '1',
      index: '0.2',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.previousTreatment.visitWhen',
          version: '1',
          type: 'choice',
          options: [
            {
              value: '14days',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: '3months',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
          condition:
            "get('answers.eyes.previousProblem.doctor.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'När sökte du läkare?',
          index: '0.3.0',
        },
      ],
      version: '1',
      index: '0.3',
    },
    {
      type: 'section',
      children: [
        {
          id: 'eyes.previousProblem.treatment.match',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.eyes.previousProblem.doctor.match') === 'yes'",
          validations: {
            required: true,
          },
          label:
            'Fick du någon behandling för ögonbesväret eller ögonsjukdomen?',
          index: '0.4.0',
        },
        {
          id: 'eyes.previousProblem.treatment.what',
          version: '1',
          type: 'text',
          condition:
            "get('answers.eyes.previousProblem.treatment.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Vilken typ av behandling fick du?',
          index: '0.4.1',
        },
        {
          id: 'eyes.previousProblem.treatment.ongoing.match',
          version: '1',
          type: 'binary',
          condition:
            "get('answers.eyes.previousProblem.treatment.match') === 'yes'",
          validations: {
            required: true,
          },
          label: 'Pågår behandlingen fortfarande?',
          index: '0.4.2',
        },
      ],
      index: '0.4',
    },
    {
      type: 'section',
      children: [
        {
          id: 'eyes.pictures',
          version: '3',
          type: 'upload',
          validations: {
            required: true,
            min: 2,
          },
          extras: {
            instructions: ['closeup', 'lighting'],
          },
          label:
            'Bifoga minst två bilder på besvären, en närbild och en översiktsbild av ögat och området runt omkring.',
          description:
            '  <p>\n    Ta gärna hjälp av en spegel för att se hur du riktar din kamera.\n  </p>\n\n  <p>\n    Observera att även bilderna är en del av din journal och omfattas av sekretess.\n  </p>',
          index: '0.5.0',
        },
      ],
      version: '1',
      index: '0.5',
    },
    {
      type: 'section',
      children: [
        {
          id: 'problem.main',
          version: '1',
          type: 'text',
          validations: {
            required: true,
          },
          label: 'Vilket är ditt huvudsakliga besvär?',
          index: '0.6.0',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'text',
          version: '2',
          validations: {
            required: true,
            max: 250,
          },
          label: 'Beskriv kortfattat vad du vill ha hjälp med.',
          index: '0.6.1',
        },
      ],
      version: '1',
      index: '0.6',
    },
    {
      type: 'section',
      children: [
        {
          id: 'reproduction.pregnant',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Är du gravid?',
          index: '0.7.0',
        },
        {
          id: 'reproduction.breastfeeding',
          version: '1',
          type: 'binary',
          validations: {
            required: true,
          },
          label: 'Ammar du?',
          index: '0.7.1',
        },
      ],
      version: '1',
      condition:
        "get('answers.predefined.gender') === 'female' && get('answers.predefined.age') < 50",
      index: '0.7',
    },
    {
      type: 'section',
      children: [
        {
          id: 'other',
          type: 'text',
          validations: {
            required: false,
          },
          version: '1',
          label: 'Övrig medicinsk information',
          description:
            'Här kan du ange eventuell övrig information som du tror att läkaren kan behöva i sin bedömning.',
          index: '0.8.0',
        },
      ],
      version: '1',
      index: '0.8',
    },
    {
      id: 'predefined.gender',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.9',
    },
    {
      id: 'predefined.age',
      type: 'predefined',
      version: '1',
      validations: {
        required: true,
      },
      index: '0.10',
    },
  ],
  views: {
    clinic: {
      children: [
        {
          id: 'problem.main',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Huvudsakligt besvär',
          description: '',
        },
        {
          id: 'doctor.desiredHelp',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Önskar hjälp med',
          description: '',
        },
        {
          id: 'started',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Besvären började',
          description: '',
          options: [
            {
              value: 'within24hours',
              label: 'Senaste dygnet',
            },
            {
              value: 'last2to4days',
              label: 'För 2–4 dagar sedan',
            },
            {
              value: 'moreThan4days',
              label: 'För mer än 4 dagar sedan',
            },
          ],
        },
        {
          id: 'eyes.conjunctivitis.location',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har besvär i',
          description: '',
          options: [
            {
              value: 'left',
              label: 'I vänster öga',
            },
            {
              value: 'right',
              label: 'I höger öga',
            },
            {
              value: 'both',
              label: 'I båda ögonen',
            },
          ],
        },
        {
          id: 'eyes.conjunctivitis.symptoms',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Upplevda besvär',
          description: '',
          options: [
            {
              value: 'stings',
              label: 'Det svider',
            },
            {
              value: 'itching',
              label: 'Det kliar',
            },
            {
              value: 'running',
              label: 'Det rinner',
            },
            {
              value: 'burning',
              label: 'Det bränner',
            },
            {
              value: 'sticky',
              label: 'Det är kletigt/varigt',
            },
            {
              value: 'pain',
              label: 'Det gör ont',
            },
            {
              value: 'sore',
              label: 'Det skaver',
            },
            {
              value: 'other',
              label: 'Annat',
            },
          ],
        },
        {
          id: 'eyes.conjunctivitis.symptomsOther',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Andra besvär',
          description: '',
        },
        {
          id: 'eyes.somethingInYourEye',
          warning: "get('answers.eyes.somethingInYourEye') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Misstanke om att något kommit in i ögat',
          description: '',
        },
        {
          id: 'eyes.whatInYourEye',
          warning: 'true',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har fått vad i ögat',
          description: '',
        },
        {
          id: 'eyes.physicalTrauma.match',
          warning: "get('answers.eyes.physicalTrauma.match') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Fysiskt trauma mot ögat',
          description: '',
        },
        {
          id: 'eyes.symptoms.light',
          warning: "get('answers.eyes.symptoms.light') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärta/Obehag mot ljus',
          description: '',
        },
        {
          id: 'eyes.painEyeball.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Smärta i ögongloben',
          description: '',
        },
        {
          id: 'eyes.relatedToCold',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ögonbesvär uppstod i samband med förkylning',
          description: '',
        },
        {
          id: 'eyes.affectedSight',
          warning: "get('answers.eyes.affectedSight') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Påverkad syn',
          description: '',
        },
        {
          id: 'eyes.affectedSightHow',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Synen påverkad på detta sätt',
          description: '',
        },
        {
          id: 'eyes.contactLenses',
          warning: "get('answers.eyes.contactLenses') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Använder kontaktlinser',
          description: '',
        },
        {
          id: 'eyes.previousProblem.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Har/ har haft ögonbesvär sjukdomar',
          description: '',
        },
        {
          id: 'eyes.previousProblem.doctor.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sökt läkare',
          description: '',
        },
        {
          id: 'problem.previousTreatment.visitDiagnosis',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Tidigare diagnos',
          description: '',
        },
        {
          id: 'problem.previousTreatment.visitWhen',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Sökt läkare när',
          description: '',
          options: [
            {
              value: '14days',
              label: 'För mindre än 14 dagar sedan',
            },
            {
              value: '3months',
              label: 'För 14 dagar – 3 månader sedan',
            },
            {
              value: 'over3months',
              label: 'För mer än 3 månader sedan',
            },
          ],
        },
        {
          id: 'eyes.previousProblem.treatment.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Behandling av läkare för besvär',
          description: '',
        },
        {
          id: 'eyes.previousProblem.treatment.what',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Vilken behandling',
          description: '',
        },
        {
          id: 'eyes.previousProblem.treatment.ongoing.match',
          type: 'question',
          version: '1',
          extras: {},
          label: 'Pågående behandling',
          description: '',
        },
        {
          id: 'reproduction.pregnant',
          warning: "get('answers.reproduction.pregnant') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Gravid',
          description: '',
        },
        {
          id: 'reproduction.breastfeeding',
          warning: "get('answers.reproduction.breastfeeding') === 'yes'",
          type: 'question',
          version: '1',
          extras: {},
          label: 'Ammar',
          description: '',
        },
        {
          id: 'other',
          type: 'question',
          version: '1',
          extras: {},
          description: '',
          label: 'Övrig medicinsk information',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
    decisionSupport: {
      children: [
        {
          id: 'somethingInYourEye',
          type: 'comment',
          condition: "get('answers.eyes.somethingInYourEye') === 'yes'",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Misstanke om att något kommit in i ögat',
          description:
            'Patientens har svarat att något eventuellt kommit in i ögat. Videosamtal rekommenderas för förbättrad anamnes.',
        },
        {
          id: 'affectedSight',
          type: 'comment',
          condition:
            "( get('answers.eyes.symptoms.light') === 'yes' || (get('answers.eyes.affectedSight') === 'yes') ) && ( contains(get('answers.eyes.conjunctivitis.symptoms'),'pain') || contains(get('answers.eyes.conjunctivitis.symptoms'),'running') || contains(get('answers.eyes.conjunctivitis.symptoms'),'sore') )",
          version: '1',
          validations: {
            required: true,
          },
          label: 'Differentialdiagnoser',
          description:
            'Patientens symptom kan tala emot en okomplicerad konjunktivit. Överväg differential diagnoser såsom irit och keratit. Videosamtal rekommenderas för förbättrad anamnes.',
        },
      ],
      type: 'view',
      version: '1',
      validations: {
        required: true,
      },
    },
  },
  label: 'Ögoninflammation',
  index: '0',
};

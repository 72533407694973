import type { Guide } from '../../types';

import pharmacy from '../modules/pharmacy';

import informedConsentChild from '../partials/informedConsentChild';
// import innovationStudy from '../questions/innovationStudy';

import acute from './acute';
import ds from './decisionSupport';
import repeat from './repeat';
import review from './review';
import healthProfileChild from '../partials/healthProfileChild';

export default (async args => {
  const {
    ask,
    // patient: { domain },
    significant,
    decisionSupport,
    exports,
  } = args;

  const informedConsentChildResult = await informedConsentChild(args);
  if (informedConsentChildResult.abort) {
    return informedConsentChildResult;
  }

  await ask.choice({
    label:
      'Do not use this service if your child is unwell or needs their medication urgently.',
    description: `
  For urgent requests, please contact your GP. 
    
  For emergencies, please go to A&E or dial 999.`,
    options: ['I understand'],
  });

  await ask.choice({
    label: 'We cannot prescribe:',
    description:
      '- medications that can be bought over the counter.\n\n- controlled drugs e.g. pain killers, ADHD medication.',
    options: ['I understand'],
  });

  const repeatSignificant = [];

  const { abort: repeatAbort, want: wantRepeat, needReview } = await repeat({
    ...args,
    significant: repeatSignificant,
  });
  if (repeatAbort) return { abort: repeatAbort };

  const acuteSignificant = [];

  const { abort: acuteAbort } = await acute({
    ...args,
    significant: acuteSignificant,
    wantRepeat,
  });
  if (acuteAbort) return { abort: acuteAbort };

  const reviewSignificant = [];

  if (needReview) {
    const { abort: reviewAbort } = await review({
      ...args,
      significant: reviewSignificant,
    });
    if (reviewAbort) return { abort: reviewAbort };
  }

  significant.push(...acuteSignificant);
  significant.push(...repeatSignificant);
  significant.push(...reviewSignificant);

  // TODO: a bit of a special hack to display a header. Be warned!
  significant.push({
    type: 'text',
    value: 'Health profile'.toUpperCase(),
  });

  await healthProfileChild(args);

  await pharmacy(args);

  /*
  if (domain !== 'uk-theprojectsurgery') {
    await ask(innovationStudy);
  }
*/
  decisionSupport.push(ds.controlledDrugs);

  exports.push({ id: 'presentingComplaint', value: 'Medication requested' });
  exports.push({ id: 'snomedCode', value: '182918009 Medication requested' });
  exports.push({
    id: 'diagnosis.options',
    value: ['Repeat prescription issued', 'Medication requested'],
  });
  exports.push({
    id: 'prescribed.options',
    value: ['Prescribed medication'],
  });
  exports.push({
    id: 'outcome.options',
    value: [
      'Medication prescribed',
      'Medication prescribed and advice given',
      'Medication prescribed and investigations requested',
      'Investigations requested, no medication issued',
      'Self care',
      'Follow up consultation',
      'Referred to GP',
      'Urgent care recommended',
      'Emergency care recommended',
    ],
  });

  return {};
}: Guide);

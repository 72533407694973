import type { Guide } from '../../../types';

import { allDomains, leicesterDomains } from '../../common/domains';
import { maxExpiry } from '../../common/consts';

const significantLabel = 'Pharmacy';

const fusionText = 'Fusion Pharmacy (free home delivery, 1-3 working days)';
const otherText = 'Other';

const whichPharmacyQuestion = {
  label: 'Which pharmacy would you like to get your medications from?',
  description:
    'Please note, the pharmacy you choose will become your new nominated pharmacy for all prescriptions',
  expires: maxExpiry,
};

const askForPharmacyAddress = async args => {
  const { ask, significant } = args;

  significant.push({
    type: 'text',
    label: significantLabel,
    value: await ask({
      ...whichPharmacyQuestion,
      id: 'pharmacy.address',
      type: 'text',
    }),
  });
};

export default (async args => {
  const {
    ask,
    significant,
    patient: { domain },
  } = args;

  let options = allDomains[domain] && allDomains[domain].pharmacies;
  if (options && options.length > 0) {
    if (leicesterDomains[domain]) {
      options = [...options, fusionText];
    }
    const question = {
      id: 'pharmacy.whichPharmacy',
      type: 'choice',
      options: [...options, otherText],
    };

    let value = await ask({
      ...question,
      ...whichPharmacyQuestion,
    });

    if (value === otherText) {
      await askForPharmacyAddress(args);
    } else if (value === fusionText) {
      significant.push({
        ...question,
        label: significantLabel,
        value: 'Fusion',
      });
    } else {
      significant.push({
        ...question,
        label: significantLabel,
        value,
      });
    }
  } else {
    await askForPharmacyAddress(args);
  }

  return {};
}: Guide);
